import { Avatar, Box, Grid } from "@mui/material";
import { StudentsPagination } from "../pagination";

import { ClassDetailLineChart,ClassDetailBarChart, QuestionTimeBarChart } from "./";
import WooroTestLogoOnly from 'img/woorotest_logo_only.png';

const ClassDetailView = (props) => {
  const students = props.classDetail?.Students.filter( (item) => {return item.Connect === 2})
  const QuestionTimeBarChartData = [];
for (let i = 0; i < 12; i++) {
  const nowDate = new Date(new Date().setDate(1));
  const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
  QuestionTimeBarChartData.push({ x: `${month}월`, 시간: 0 ,y:0 });
};
if (students) {
    for (let i = 0; i < students.length; i++) {
      if (students[i]?.Student_id?.Questions.length > 0) {
        for (let j = 0; j < students[i].Student_id.Questions.length; j++) {
          const connectedAt = new Date(students[i].Student_id.Questions[j].CreatedAt);
          const month = connectedAt.getMonth() + 1;
          const time = students[i].Student_id.Questions[j].Time;
          // 해당 월의 x 값을 찾아서 누적 시간을 더해줍니다.
          const dataPoint = QuestionTimeBarChartData.find(data => data.x === `${month}월`);
          if (dataPoint) {
            dataPoint.시간 += time;
            dataPoint.y += time;
          };
        }
      };
    }

    for (let i = 0; i < QuestionTimeBarChartData.length; i++) {
        if (QuestionTimeBarChartData[i].시간 > 0) {
            QuestionTimeBarChartData[i].시간 = Math.floor(QuestionTimeBarChartData[i].시간 / 60)
            QuestionTimeBarChartData[i].y = Math.floor(QuestionTimeBarChartData[i].y)
        };
    };
  };
  return (
    <Grid container justifyContent="center">
      <Grid container lg={8} xl={7}>
        <Grid item={true}  lg={5.5} xl={5} mr={4}sx={{display: "flex",alignItems: "center",background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px",height:"304px",justifyContent:"center" }}>
          <Box sx={{display: "flex",flexFlow:"column",alignItems:"center"}}>
            <Avatar sx={{ height: "157px", width: "157px" }} src={props.classDetail?.ClassImage ?props.classDetail?.ClassImage : WooroTestLogoOnly}/>
            <h1 style={{fontSize:"32px"}}>{props.classDetail?.ClassName}</h1>
          </Box>
        </Grid>
      <Grid item lg={6} xl={6}>
        <Box sx={{ background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px", height: "294px"}}>           
          <h2 style={{fontSize:"31px",marginLeft:"30px"}}>담당자</h2>
          <Box  sx={{ display: "flex", alignItems: "center",margin:"23px 0px 10px 30px"}}>
            <Avatar  sx={{ marginRight: "25px",width:"62px",height:"62px" }} src={props.classDetail?.Manager_id.Profile }/>
            <span style={{fontSize:"17px",fontWeight:"500"}}>{props.classDetail?.ManagerName} &nbsp;선생님</span>
          </Box>
          <div style={{display:"flex",alignItems:"center",marginTop:"30px",marginLeft:"30px"}}>
            <h2 style={{fontSize:"21px",fontWeight:"500"}}>학생수</h2>
            <h2 style={{fontSize:"17px",fontWeight:"500",marginLeft:"35px"}}>{props?.students?.length}명</h2>
          </div>
        </Box>
      </Grid>
      <Grid item lg={11.8} xl={11.2} mt={5}  sx={{background:"#FFFFFF",borderRadius: "12.652px",padding:"30px"}}>
        <h2 style={{ margin: "20px 0 20px 40px" }}>
          {props.classDetail?.ClassName} 평균 풀이 시간
        </h2>
        <div style={{ height: '300px', whiteSpace: 'nowrap', overflow: 'hidden',}}>
            <QuestionTimeBarChart data={QuestionTimeBarChartData}  x={'x'} y={'시간'} ylabel={'풀이시간'}/>
        </div>
      </Grid>
      <Grid item lg={11.8} xl={11.2} mt={5} sx={{background:"#FFFFFF",borderRadius: "12.652px",padding:"30px"}}>
      <h2 style={{ margin: "20px 0 20px 40px" }}>
          {props.classDetail?.ClassName} 평균 등급
        </h2>
        <div style={{ height: "300px", width: "100%", whiteSpace: "nowrap",overflow: "hidden",}}>
          <ClassDetailLineChart data={props.lineChartDate}/>
        </div>
      </Grid>
      <Grid item lg={11.8} xl={11.2} mt={5} sx={{background:"#FFFFFF",borderRadius: "12.652px",padding:"30px"}}>
        <h2 style={{ margin: "20px 0 20px 40px" }}>
          {props.classDetail?.ClassName} 학생 등급
        </h2>
        <div
          style={{
            height: "400px",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <ClassDetailBarChart data={props.newData}/>
        </div>
      </Grid>
    </Grid>
    <Grid container lg={4} xl={3}>
      <Grid item lg={12} xl={9.5}>
        <StudentsPagination
          search={props.search}
          handleChangeSearch={props.handleChangeSearch}
          onPageChange={props.handleChangePage}
          student={props.studentFilter}
          title={props.classDetail?.ClassName}
        />
      </Grid>
    </Grid>
  </Grid>
  );
};

export default ClassDetailView;
