import {  Button,Grid } from "@mui/material";
import {  useNavigate } from "react-router-dom";

const TeacherCreateView = (props) => {
  const navigate = useNavigate();

  return (
  <Grid container justifyContent={"center"}>
    <Grid container lg={10} xl={5} justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"25px" ,padding:"30px 0"}}>
      <Grid item lg={12} xl={12} sx={{textAlign:"center"}} >
        <h1>교사 수정</h1>
      </Grid>
      <Grid item lg={11.3} xl={11}>
        <h2>이름</h2>
        <input 
          type="text"
          // className="academy_input"
          id="name" 
          name="name"
          disabled
          value={props.teacherInfo.name || ""}
          // onChange={props.handleChange}
          style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
      </Grid>
      <Grid item lg={11.3} xl={11} mt={2} mb={2}>
        <h2>전화번호</h2>
        <input 
          type="text"
          id="phone"
          name="phone"
          value={props.teacherInfo.phone || ""}
          onChange={props.handleChange}
          style={{borderRadius:"10px",background:"#F7F7F7", border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
      </Grid>
      <Grid item lg={11.3} xl={11}>
        <h2>이메일</h2>
          <input 
           type="text"
           id="email"
           name="email"
           value={props.teacherInfo.email || ""}
          //  onChange={props.handleChange}
           disabled
           style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
      </Grid>
      <Grid item lg={12} xl={12} mt={10} sx={{display:"flex" ,justifyContent:"space-between",padding:"0"}} >
        <div></div>
        <Button component='span'color='warning' sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",marginLeft:"100px" }} variant='contained' onClick={props.handleUpdate}>수정</Button>
        <Button  sx={{ width:"50px",fontSize:"15px",borderRadius:"10px" }} onClick={() => navigate(-1)}>취소</Button>
      </Grid>
    </Grid>
  </Grid>
  );
};

export default TeacherCreateView;
