import { useState,useEffect } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import axios from 'axios';

import { TeacherHomeworkDetailStudentView } from  'components/Academy/teacher/homework'
import { Layout } from "components/Academy/Layout";

const TeacherHomeworkDetailStudent = () => {
  const [cookies] = useCookies(['LoginKey']);
  const {id,homeworkNo,ClassNo,studentNo} = useParams();
  const loginKey = cookies.LoginKey;
  const teacherNo = id;

  const [question,setQuestion] = useState();
  const [homeworkList, setHomeworkList] = useState([{
   Question: '',
   HomeworkNo: '',
   Section: '',
   InfoStudent: { Check: '', Time: '', Memo: '' },
   Info: { Correct: '', Counting: '', Time: '' },
   InfoClass: {Correct: '', Counting: '', Time: ''}
  }]);
  const [filterStudent, setFilterStudent] = useState({
   Student_id: { Name: '' },
   StudentNo: '',
   Questions: []
  });


  const handleHomeworkData= async()=>{//클래스 평균 등급 그래프 
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${teacherNo}/${ClassNo}/${homeworkNo}/${loginKey}`, config).then((Response)=>{
      setQuestion(Response.data.Homework);
    }).catch((err)=>{})
  };
 
  useEffect(()=>{
    handleHomeworkData();
  },[]);
  
  useEffect(() => {
    const tempFilterStudent = question?.Students.filter((item) => { return item.StudentNo === Number(studentNo) })[0];
    let tempHomeworkList =question?.Question_ids && [...question?.Question_ids];
    for(let i = 0; i < tempHomeworkList?.length; i++){
       let tempCheck = 0;
       let tempTime = 0;
       let tempMemo = '';
       const studentSolveHomework = tempFilterStudent?.Questions.filter((item) => item.Question_id.toString() === tempHomeworkList[i]._id.toString())[0];
       if(studentSolveHomework){
          tempCheck = studentSolveHomework.Check;
          tempTime = studentSolveHomework.Time;
          tempMemo = studentSolveHomework.Memo ? studentSolveHomework.Memo : ''
       }
       tempHomeworkList[i].InfoStudent = { Check: tempCheck, Time: tempTime, Memo: tempMemo };
    }
    setFilterStudent(tempFilterStudent);
    setHomeworkList(tempHomeworkList);
 }, [question, studentNo]);


  return ( 
  <Layout >
    <TeacherHomeworkDetailStudentView 
      homeworkList={homeworkList}
      filterStudent={filterStudent}/> 
  </Layout>
  );
}
 
export default TeacherHomeworkDetailStudent;