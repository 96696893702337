import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { TeacherProposeList } from 'components/teacher/class/propose';

const Propose = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const LoginKey = cookies.LoginKey;
  const { ClassNo } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);
  const [classData, setClassData] = useState({
    ClassName: '',
    ClassNo: '',
    Students: [{
      Attending: '',
      Grade: '',
      Profile: '',
      Name: '',
      Student_id: { 
        School_id: { Name: '' }
      }
    }]
  });
  const [teacherData, setTeacherData] = useState({
    Email: '',
    Name: ''
  });

  const [searchTerm, setsearchTerm] = useState('');

  useEffect(() => {
    const loadClassData = async() => {
      await axios.get(`/api/teacher/class/${ClassNo}/${LoginKey}`).then((Response) => {
        if(!Response.data.ErrorMessage){
          let loadDatas = Response.data.Class;
          loadDatas.Students.sort(function (a, b) {
            return new Date(b.ProposedAt) - new Date(a.ProposedAt);
          });
          setClassData(loadDatas);
          setTeacherData(Response.data.TeacherInfo);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      })
    }
    loadClassData();
  }, []);

  /* 학생 신청 수락 */
  const handleClickStudentPermit = async(key) => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      studentNo: classData.Students[key].StudentNo,
      classNo: ClassNo,
      loginKey: LoginKey,
    });
    await axios.put('/api/teacher/class/permit', body, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        let permitClass = { ...classData };
        permitClass.Students[key].Connect = 2;
        setClassData(permitClass);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
      setTimeout(() => {
        setUpdating(false);
      }, 250);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }

  /* 학생 신청 거절 */
  const handleClickStudentReject = async(key) => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      studentNo: classData.Students[key].StudentNo,
      classNo: ClassNo,
      loginKey: LoginKey,
    });
    await axios.put('/api/teacher/class/reject', body, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        let rejectClass = { ...classData };
        rejectClass.Students.splice(key, 1);
        setClassData(rejectClass);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
        setTimeout(() => {
          setUpdating(false);
        }, 250);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }

  /* 학생 삭제 */
  const handleClickStudentDelete = async(key) => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      studentNo: classData.Students[key].StudentNo,
      classNo: ClassNo,
      loginKey: LoginKey,
    });
    await axios.put('/api/teacher/class/delete', body, config).then((Response) => {
      if(Response.data.Message){
        let deleteClass = { ...classData };
        deleteClass.Students.splice(key, 1);
        setClassData(deleteClass);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
        setTimeout(() => {
          setUpdating(false);
        }, 250);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }

  return (
    <Layout>
      <TeacherProposeList 
        classData={classData} 
        teacherData={teacherData}
        updating={updating}
        handleClickStudentPermit={handleClickStudentPermit}
        handleClickStudentReject={handleClickStudentReject}
        handleClickStudentDelete={handleClickStudentDelete}
      />
    </Layout>
  )
};

export default Propose;