import { useState,useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import { AcademyCreateView } from "components/Academy/main";
import { Layout } from "components/Academy/Layout";

const AcademyCreate = () => {
  const [ cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  
  const [loadAcademy,setLoadAcademy]=useState()
  const [academyData,setAcademyData]=useState({
    name:"",
    businessRegistation:"",
    phone:"",
    representative:"",
    address:""
  });

  const loadAcademyData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/${LoginKey}`, config).then((Response) => {
      setLoadAcademy(Response.data.Academy);
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(()=>{
    loadAcademyData()
  },[]);

  useEffect(()=>{
    if (loadAcademy) {
      enqueueSnackbar(`현재 학원이 존재합니다.`, { variant: 'error'});
      navigate('/academy/dashboard');
    };
  },[loadAcademy]);

  const condition =
  academyData.name.length !== 0 &&
  academyData.name !== undefined &&
  academyData.businessRegistation.length !== 0 &&
  academyData.businessRegistation !== undefined &&
  academyData.phone.length !== 0 &&
  academyData.phone !== undefined &&
  academyData.representative.length !== 0 &&
  academyData.representative !== undefined &&
  academyData.address.length !== 0 &&
  academyData.address !== undefined;

  const handleData = async () => {
    if (condition) {
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({...academyData ,loginKey:LoginKey});
      await axios.post(`/api/academy`, body, config).then((Response) => {
        navigate(`/academy/dashboard`)
      }) 
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    } else {
      alert("내용을 입력해주세요");
    }
};
  const handleChange = (event) => {
    let changeAcademyInfo = { ...academyData };
    changeAcademyInfo[event.target.name] = event.target.value;
    setAcademyData(changeAcademyInfo);
  };

  return ( 
    <Layout>
      <AcademyCreateView 
        handleChange={handleChange}
        academyData={academyData} 
        handleData={handleData}/> 
    </Layout>
  );
}
 
export default AcademyCreate;