import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, TextField, CircularProgress, FormControlLabel, Radio } from '@mui/material';
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const PasswordChangeModal = (props) => {
    const  { enqueueSnackbar } = useSnackbar();
    const [updating, setUpdating] = useState(false);
    const [password, setPassword] = useState({ pwd: '', pwdc: '', pwdn: '' });

    const handleClickSubmit = async() => {
        if(password.pwd && password.pwd === password.pwdc && password.pwdn){
            setUpdating(true);
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({ passwordNow: password.pwdn, password : password.pwd, user_id: props.user_id });
            await axios.put(`/api/student/password`, body, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                    handleClose();
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    setUpdating(false);
                }                
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
                setUpdating(false);
            });
        }else{
            if(!password.pwd || !password.pwdc || !password.pwdn){
                enqueueSnackbar(`모두 입력해 주세요.`, { variant: 'error' });
            }else{
                enqueueSnackbar(`비밀번호가 일치하지 않습니다..`, { variant: 'error' });
            }
        }
    };

    const handleClose = () => {
        if(!updating){
            props.setOpen(false);
            setUpdating(false);
            setPassword({ pwd: '', pwdc: '', pwdn: '' });
        }else{
            return false;
        }
    };
    return(
        <div>
            <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description-delete'
            fullWidth={true}
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={handleClose}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                    <TextField
                        required
                        fullWidth
                        id='outlined-basic'
                        label='현재 비밀번호'
                        type='password'
                        variant='outlined'
                        value={password.pwdn}
                        onChange={(e) => setPassword({...password, pwdn: e.target.value})}
                        disabled={updating}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                    <hr/>
                    <TextField
                        required
                        fullWidth
                        id='outlined-basic'
                        label='비밀번호'
                        type='password'
                        variant='outlined'
                        value={password.pwd}
                        onChange={(e) => setPassword({...password, pwd: e.target.value})}
                        disabled={updating}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                    <TextField
                        required
                        fullWidth
                        id='outlined-basic'
                        label='비밀번호 확인'
                        type='password'
                        variant='outlined'
                        value={password.pwdc}
                        onChange={(e) => setPassword({...password, pwdc: e.target.value})}
                        disabled={updating}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!updating ? 
                <>
                <Button onClick={handleClose}>취소</Button>
                <Button onClick={handleClickSubmit} color='warning' variant='contained' disableElevation disabled={updating}>확인</Button>
                </>:
                <div style={{ paddingRight: '20px' }}>
                    <CircularProgress color='warning'/>
                </div>}
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default PasswordChangeModal;