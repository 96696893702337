import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ClassMenu } from 'components/teacher/layout';
import { TeacherClassSelect } from 'components/teacher/class';
import { AcademyBoardModal } from 'components/modal';


const ClassSelect = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['LoginKey', 'Name', 'UserNo', 'Profile', 'ClassNo', 'ClassName', 'ClassImage','Academy_id','AcademyNo','AcademyName']);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [academyData, setAcademyData] = useState();
  const [classDatas, setClassDatas] = useState([
    {
      _id: '',
      State: '',
      ClassName: '',
      ClassNo: ''
    }
  ]);
  const [orderData, setOrderData] = useState({
    State: 1,
    MaxStudent: 15,
    createdAt: ''
  });
  const [academyOrderData, setAcademyOrderData] = useState({
    State: 1,
    MaxStudent: 100,
    createdAt: ''
  });
  const [studentDatas, setStudentDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [boardList, setBoardList] = useState();
  const [boardDetail, setBoardDetail] = useState({});
  const [academyStudentList, setAcademyStudentList] = useState();
  const [teacherInfo, setTeacherInfo] = useState();

  const loadClassData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/class/${LoginKey}`, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        let datas = Response.data.ClassList;
        datas = datas.filter((data) => {
          return data.Class_id.State === 1
        });
        let studentList = [];
        for(let i = 0; i < datas.length; i++){
          const studentTemp = datas[i].Class_id.Students.filter((item) => { return item.Connect === 2});
          studentList = studentList.concat(studentTemp);
        }
        studentList = studentList.filter((item, i) => { //문제풀이 정보의 중복을 제거하여 임시로 저장 => 가장 최근의 문제기록만 남게 됨.
          return (studentList.findIndex((item2, j) => {     
            return item.Student_id === item2.Student_id;
          }) === i);
        });
        setClassDatas(datas);
        setStudentDatas(studentList);
        setTimeout(() => {
          setLoading(false);
        }, [550]);  
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  const loadOrderData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/order/${LoginKey}`, config).then((Response) => {
      if(Response.data.Order){
        setOrderData(Response.data.Order);
        setAcademyOrderData(Response.data.Order);
      }
    }).catch((Error) => {
      // console.log(Error);
    });
  };
  const loadTeacherData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/teacher/${LoginKey}`, config).then((Response) => {
      if(Response.data){
        setTeacherInfo(Response.data.Teacher);
      }
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const loadAcademyData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/${LoginKey}`, config).then((Response) => {
      if(Response.data.Academy){
        setAcademyData(Response.data.Academy);
        loadStudentsData();
      }
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const loadBoardData = async () =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/notice/${LoginKey}`, config).then((Response) => {     
      let loadData= Response.data.Notices
      let boardData=[]
      for (let i = 0; i < loadData.length; i++) {
        let boardNo = i+1
        boardData.push({...loadData[i],No:boardNo})
      }
      setBoardList(boardData.slice().reverse())
  }).catch((Error) => {
    enqueueSnackbar(`Network Error`, { variant: 'error' });
  });
};

const loadStudentsData= async()=>{
  const config = { headers: { 'Content-type': 'application/json' }};
  await axios.get(`/api/academy/student/${LoginKey}`, config).then((Response)=>{
    if (Response.data.Students) {
      
      setAcademyStudentList(Response.data.Students)
    }else{
      console.log('err')
    }
  }).catch((err)=>{
    enqueueSnackbar(`Network Error`, { variant: 'error' });

  })
};

const openBoardModal = (board)=>{
  setOpen(true)
  setBoardDetail(board)
};


  useEffect(() => {
    loadClassData();
    loadOrderData();
    loadAcademyData();
    loadBoardData();
    loadTeacherData();
  }, []);

  const handleClickLogout = () => {
    removeCookie('LoginKey', { path: '/'});
    removeCookie('Name', { path: '/'});
    removeCookie('UserNo', { path: '/'});
    removeCookie('Profile', { path: '/'});
    removeCookie('ClassNo', { path: '/'});
    removeCookie('ClassName', { path: '/'});
    removeCookie('Academy_id', { path: '/'});
    removeCookie('AcademyName', { path: '/'});
    removeCookie('AcademyNo', { path: '/'});
    removeCookie('ClassImage', { path: '/'});
    navigate('/');
  };

  const handleClickClass = (key) => {
    if(editMode){
      navigate(`/teacher/${classDatas[key].Class_id.ClassNo}/edit`);
    }else{
      if (academyData && academyData.AcademyNo) {
        let date = new Date();
        date.setDate(date.getDate()+30);
        setCookie('ClassNo', classDatas[key].Class_id.ClassNo, { path: `/`, expires: date });
        setCookie('AcademyNo', academyData.AcademyNo, { path: `/`, expires: date });
        setCookie('Academy_id', academyData._id, { path: `/`, expires: date });
        setCookie('AcademyName', academyData.Name, { path: `/`, expires: date });
        setCookie('ClassName', classDatas[key].Class_id.ClassName, { path: `/`, expires: date });
        setCookie('ClassImage', classDatas[key].Class_id.ClassImage ? classDatas[key].Class_id.ClassImage : '', { path: `/`, expires: date });
        navigate(`/teacher/${classDatas[key].Class_id.ClassNo}`); 
      }else{
        let date = new Date();
        date.setDate(date.getDate()+30);
        setCookie('ClassNo', classDatas[key].Class_id.ClassNo, { path: `/`, expires: date });
        setCookie('ClassName', classDatas[key].Class_id.ClassName, { path: `/`, expires: date });
        setCookie('ClassImage', classDatas[key].Class_id.ClassImage ? classDatas[key].Class_id.ClassImage : '', { path: `/`, expires: date });
        navigate(`/teacher/${classDatas[key].Class_id.ClassNo}`); 
      }
    }
  };
  return (
    <ClassMenu>
      <TeacherClassSelect 
        classDatas={classDatas} 
        studentDatas={studentDatas}
        orderData={orderData}
        loading={loading} 
        handleClickClass={handleClickClass} 
        editMode={editMode}
        setEditMode={setEditMode}
        handleClickLogout={handleClickLogout}
        academyData={academyData}
        boardList={boardList}
        academyStudentList={academyStudentList}
        openBoardModal={openBoardModal}
        academyOrderData={academyOrderData}
        teacherInfo={teacherInfo}
      />
      <AcademyBoardModal
        open={open}
        handleClose={ ()=>setOpen(false) }
        boardDetail={boardDetail}
      >
      </AcademyBoardModal>
    </ClassMenu>
  )
};

export default ClassSelect;