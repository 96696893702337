import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import { LockClockOutlined, LockOpenOutlined, LockOutlined, ExpandMore } from '@mui/icons-material';

import { YyMmDd } from 'components/function/ChangeText';

const MockExamListView = (props) => {
    const lockDate = new Date();
    return(
        <div className='content_section_question'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} className='section_select_title'>
                    <h2>우로매쓰 <span>모의고사</span>로 시험을 대비해 보세요!</h2>
                </Grid>
                <Grid item xs={12} sm={10} md={8}>
                    {props.mockExamDatas.map((item, i) => (
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`panel1a-content_${item.MockExamNo}`}
                            id={`panel1a-header_${item.MockExamNo}`}
                            disabled={new Date(item.StartDate) > lockDate}
                        >
                            <Grid container alignItems={'center'} >
                                <Grid item xs={1}>
                                    {new Date(item.StartDate) > lockDate ?
                                    <LockClockOutlined />
                                    : item.UnLock ?
                                    <LockOpenOutlined />
                                    :<LockOutlined />}
                                </Grid>
                                <Grid item xs={9} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}><b>{item.Name}</b></Grid>
                                <Grid item xs={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', color: 'gray' }}>{YyMmDd(item.StartDate)}{new Date(item.StartDate) > lockDate ? ' OPEN' : null}</Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={5} style={{ padding: 0 }}>
                                    <table style={{ width: '100%', textAlign: 'left'}}>
                                        <tr>
                                            <th colSpan={2}>응시 학생</th>
                                        </tr>
                                        <tr>
                                            <th>확률과 통계</th><td style={{ border: 0 }}>{item.StudentCnt[0].toLocaleString()}명</td>
                                        </tr>
                                        <tr>
                                            <th>미적분</th><td style={{ border: 0 }}>{item.StudentCnt[1].toLocaleString()}명</td>
                                        </tr>
                                        <tr>
                                            <th>기하</th><td style={{ border: 0 }}>{item.StudentCnt[2].toLocaleString()}명</td>
                                        </tr>
                                    </table>
                                </Grid>
                                <Grid item xs={5}>
                                    <table style={{ width: '100%', textAlign: 'left' }}>
                                        <tr>
                                            <th colSpan={2}>{item.UnLock ? <b>응시 가능</b> : <b style={{ color: 'red' }}>응시 불가능</b>}</th>
                                        </tr>
                                        <tr>
                                            <th>시험 시작 일</th><td style={{ border: 0 }}>{YyMmDd(item.StartDate)}</td>
                                        </tr>
                                        <tr>
                                            <th>성적 공개 일</th><td style={{ border: 0 }}>{YyMmDd(new Date(item.StartDate).setDate(new Date(item.StartDate).getDate()+14))}</td>
                                        </tr>
                                    </table>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: 'center' }}>
                                    {item.UnLock ? 
                                    <Button color='warning' variant='contained' disableElevation onClick={() => props.handleClickOpen(i)}>문제 풀기</Button>:
                                    <Button color='error' variant='contained' disableElevation onClick={() => props.handleClickOpen(i)}>구매 하기</Button>}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

export default MockExamListView;