import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/admin/layout';
import { MockExamDetailUpdate } from 'components/admin/mockExam';
import * as ChangeText from 'components/function/ChangeText';

const MockExamUpdate = () => {
    const pages = [
        { title: '모의고사 관리'},
        { title: '모의고사 목록'},
        { title: '모의고사 정보 수정'},
    ];
    const title = { main: '모의고사 정보 수정', sub: 'mock exam'};
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const [mockExam, setMockExam] = useState({
        MockExamNo: '',
        Name: '',
        StartDate: '',
    });
    const [updating, setUpdating] = useState(false);

    const handleChangeName = (event) => {
        setMockExam({...mockExam, Name: event.target.value});
    };

    const handleChangeStartDate = (event) => {
        const today = new Date().setHours('0', '0', '0', '0');
        if(today < new Date(event.target.value)){
            setMockExam({...mockExam, StartDate: event.target.value});
        }else{
            enqueueSnackbar(`오늘 이전 날짜로 설정할 수 없습니다.`, { variant: 'error'});
        }
    };
    
    const handleClickUpdate = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            MockExamId: mockExam._id,
            startDate: mockExam.StartDate,
            name: mockExam.Name,
            loginKey: cookies.LoginKey
        });
        await axios.put(`/api/admin/mockexam`, body, config).then((Response) => {
            setTimeout(()=> {
                navigate(`/admin/mockexam/${mockExam._id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    useEffect(() => {
        const loadQuestion = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/mockexam/${id}/${cookies.LoginKey}`, config).then((Response) => {
                let loadData = Response.data;
                loadData.StartDate = ChangeText.YyyyMmDd(loadData.StartDate);
                setMockExam(Response.data);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadQuestion();
    }, []);
    return (
        <Layout pages={pages} title={title}>
            <MockExamDetailUpdate
                mockExam={mockExam}
                handleChangeName={handleChangeName}
                handleChangeStartDate={handleChangeStartDate}
                handleClickUpdate={handleClickUpdate}
                updating={updating}
            />
        </Layout>
    )
};

export default MockExamUpdate;