import React from 'react';
import { Search } from '@mui/icons-material';

const AcademyStudentSearchForm = (props) => {
  return (  
  <div style={{display:"flex",alignItems:"center",padding:" 5px",width:"90%"}}>
    <input
      style={{border:"1px solid #A4A4A4",borderRight:"0" ,outline:"none",width:"80%",height:"30px",padding:"0 5px"}}
      type='text'
      value={props.search}
      onChange={props.handleChange}
      placeholder={props.searchGuide}
      disabled={props.loading}
      />
      <Search sx={{color:"#A4A4A4",border:"1px solid #A4A4A4",width:"12px",height:"18px",padding:"6px 3px"}}/>
  </div>
  );
}
 
export default AcademyStudentSearchForm;