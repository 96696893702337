import React from 'react';
import AdminCouponPresenter from './AdminCouponPresenter';

const AdminCouponContainer = () => {
  return (
    <AdminCouponPresenter />
  );
};

export default AdminCouponContainer;
