import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from 'components/teacher/layout';
import { TeacherQuestionView } from 'components/teacher/class/question';

const QuestionView = () => {
    const [cookies] = useCookies(['LoginKey']);
    const navigate = useNavigate();
    const { ClassNo, Section } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const loginKey = cookies.LoginKey;

    const [questionDatas, setQuestionDatas] = useState([{
        Answer: '',
        Choices: ['','','','',''],
        Info: { Counting: '', Correct: '', Time: '' },
        InfoClass: { Counting: '', Correct: '', Time: '' },
        Content: [''],
        Question: '',
        _id: ''
    }]);
    
    useEffect(() => {
        const loadDatas = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/teacher/question/${ClassNo}/${Section}/${loginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    let loadQuestions = [...Response.data.Questions];
                    let loadStudents = [...Response.data.ClassStudents];
                    for(let i = 0; i < loadQuestions.length; i++){
                        let tempCounting = 0;
                        let tempCorrect = 0;
                        let tempTime = 0;
                        const QuestionId = loadQuestions[i]._id.toString();
                        for(let j = 0; j < loadStudents.length; j++){
                            const studentQuestion = loadStudents[j].Student_id.Questions.filter((item) => { return item.Question_id.toString() === QuestionId});
                            for(let k = 0; k < studentQuestion.length; k++){
                                tempCounting += 1;
                                if(studentQuestion[k].Check === 1){
                                    tempCorrect += 1;
                                    tempTime += studentQuestion[k].Time;
                                }
                            }
                        }
                        loadQuestions[i].InfoClass = { Counting: tempCounting, Correct: tempCorrect, Time: tempTime };
                    }
                    setQuestionDatas(loadQuestions);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                }
            }).catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadDatas();
    }, []);
    return(
        <Layout>
            <TeacherQuestionView 
                questionDatas={questionDatas}
            />
        </Layout>
    );
};

export default QuestionView;