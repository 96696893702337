import React from 'react';
import { Menu, Nav } from './';

const Layout = (props) => {
    return(
        <>
            <div className='admin_layout'>
                <Menu />
                <Nav pages={props.pages}/>
                <div className='admin_layout_content'>
                    <div>
                        <span>{props.title.sub}</span>
                        <h3>{props.title.main}</h3>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layout;