import React, { useEffect, useState } from 'react';
import { Grid, FormControl, Select, MenuItem, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';
import { QuestionMemoReview } from 'components/user/question';

const TeacherHomeworkDetailStudent = (props) => {
   const navigate = useNavigate();
   const { ClassNo, HomeworkNo, StudentNo } = useParams();
   
   const [homeworkList, setHomeworkList] = useState([{
      Question: '',
      HomeworkNo: '',
      Section: '',
      InfoStudent: { Check: '', Time: '', Memo: '' },
      Info: { Correct: '', Counting: '', Time: '' }
   }]);
   const [filterStudent, setFilterStudent] = useState({
      Student_id: { Name: '' },
      StudentNo: '',
      Questions: []
   });
   const [openMemoReview, setOpenMemoReview] = useState(false);
   const [memoReview, setMemoReview] = useState('');

   const handleClickMemo = (memo) => {
      setMemoReview(memo);
      setOpenMemoReview(true);
   };
   useEffect(() => {
      const tempFilterStudent = props.homeworkData.Students.filter((item) => { return item.StudentNo === Number(StudentNo) })[0];
      let tempHomeworkList = [...props.homeworkData.Question_ids];
      
      for(let i = 0; i < tempHomeworkList.length; i++){
         let tempCheck = 0;
         let tempTime = 0;
         let tempMemo = '';
         const studentSolveHomework = tempFilterStudent?.Questions.filter((item) => item.Question_id.toString() === tempHomeworkList[i]._id.toString())[0];
         if(studentSolveHomework){
            tempCheck = studentSolveHomework.Check;
            tempTime = studentSolveHomework.Time;
            tempMemo = studentSolveHomework.Memo ? studentSolveHomework.Memo : ''
         }
         tempHomeworkList[i].InfoStudent = { Check: tempCheck, Time: tempTime, Memo: tempMemo };
      }
      setFilterStudent(tempFilterStudent);
      setHomeworkList(tempHomeworkList);
   }, [props.homeworkData, StudentNo]);
   return(
      <div className='content_section'>
         <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} className='diagnosis_result_title'>
               <h2>{filterStudent?.Student_id.Name} 학생</h2>
               <FormControl variant='standard' sx={{ minWidth: 80, textAlign: 'left', marginTop: '50px', marginBottom: '30px', float: 'right' }}>
                  <Select
                     value={StudentNo}
                     onChange={(e) => navigate(`/teacher/${ClassNo}/homework/${HomeworkNo}/student/${e.target.value}`, { replace: true })}
                  >
                  {props.homeworkData.Students.map((student) => (
                     <MenuItem value={student.StudentNo}>{student.Student_id.Name}</MenuItem>
                  ))}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
               <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                  <table >
                     <tr>
                           <th rowSpan={2}>No.</th>
                           <th colSpan={2}>정답</th>
                           <th colSpan={2}>평균풀이시간</th>
                           <th rowSpan={2}>메모여부</th>
                           <th rowSpan={2}></th>
                     </tr> 
                     <tr>
                           <th>여부</th>
                           <th>전체정답률</th>
                           <th>학생</th>
                           <th>전체</th>
                     </tr>
                     {homeworkList.map((homework, i) => (
                     <tr>
                           <td>{homework.Question-(homework.Section*10000)}</td>
                           <td>{ChangeText.QuestionCheck(homework.InfoStudent.Check)}</td>
                           <td>{homework.Info.Counting !== 0 ? ChangeText.Percent(homework.Info.Correct/homework.Info.Counting) : '0%'}</td>
                           <td>{ChangeText.QuestionMinute(homework.InfoStudent.Time)}</td>
                           <td>{ChangeText.QuestionMinute(homework.Info.Time/homework.Info.Correct)}</td>
                           <td>{homework.InfoStudent.Memo ? <Link href='javascript:;' onClick={() => handleClickMemo(homework.InfoStudent.Memo)}>O</Link> : 'X'}</td>
                           <td><Link href={`/teacher/${ClassNo}/homework/${HomeworkNo}/question/${homework.Question}`}>상세보기</Link></td>
                     </tr> 
                     ))}
                  </table>
               </div>
            </Grid>
         </Grid>
         <QuestionMemoReview 
            open={openMemoReview}
            setOpen={setOpenMemoReview}
            memoReview={memoReview}
         />
      </div>
   );
};

export default TeacherHomeworkDetailStudent;