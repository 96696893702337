import AdminContainer from './AdminContainer';

export default AdminContainer;

export { default as Dashboard } from './Dashboard';
export { default as Student } from './Student';
export { default as Question } from './Question';
export { default as School } from './School';
export { default as Event } from './Event';
export { default as Manager } from './Manager';
export { default as MockExam } from './MockExam';
export { default as Coupon } from './Coupon';
export { default as Achieve } from './Achieve';
