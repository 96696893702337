import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { TeacherDashboardSection } from 'components/teacher/class/dashboard';

const DashboardSection = () => {
    const [cookies] = useCookies(['LoginKey']);
    const LoginKey = cookies.LoginKey;
    const navigate = useNavigate();
    const { ClassNo, StudentNo, Section } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [studentData, setStudentData] = useState({
        Name: '',
        Grade: '',
        Attending: '',
        School_id: {
            Name: '',
            WooroZscore: ''
        }
    });
    const [diagnosisDatas, setDiagnosisDatas] = useState([{
        ZScore: '',
        Questions: [],
        createdAt: ''
    }]);
    
    useEffect(() => {
        const loadStudentData = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/teacher/dashboard/${ClassNo}/${StudentNo}/${Section}/${LoginKey}`, config).then((Response) => {              
                if(!Response.data.ErrorMessage){
                    let loadDiagnosisList = Response.data.Diagnosis;
                    loadDiagnosisList.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    setStudentData(Response.data.Student.Student_id);
                    setDiagnosisDatas(loadDiagnosisList);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    navigate(-1);    
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });   
        }
        loadStudentData();
    }, []);
    return(
        <Layout>
            <TeacherDashboardSection studentData={studentData} diagnosisDatas={diagnosisDatas}/>
        </Layout>
    )
}

export default DashboardSection;