import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button, Link, Avatar, Menu, MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WooroMathLogo from 'img/wooromath_logo.png';
import NotoSansKRWoff from 'fonts/NotoSansKR-Regular.woff';
import { AppBanner} from '/';

const drawerWidth = 240;
const navItems = [
    { title: '문제풀기', link: 'solve'},
    { title: '진단하기', link: 'diagnosis'},
    { title: '숙제하기', link: 'homework'},
    { title: '복습하기', link: 'review'},
    { title: '수행평가', link: 'evaluation'},
    { title: '모의고사', link: 'mockexam'}
];

const theme = createTheme({
    typography: {
        fontFamily: NotoSansKRWoff
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root:{
                    backgroundColor: '#FFFFFF',
                    color: '#A4A4A4',
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#A4A4A4',
                    fontSize: '16px',
                    padding: '0px 3px 3px 3px',
                    margin: '0px 9px',
                    '&:hover': {
                        textDecoration: 'none',
                        borderBottom: '1px solid #F05D38',
                        color: '#F05D38'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '&:hover': {
                        backgroundColor: ''
                    }
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#000'
                }
            }
        }
    }
});

const MainMenu = (props) => {
    const { window } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies, setCookie, removeCookie] = useCookies(['LoginKey', 'Name', 'UserNo', 'Profile','Close']);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const container = window !== undefined ? () => window().document.body : undefined;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClickProfile = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const appBannerClose = ()=>{
        setCookie('Close' , true, { path: '/'})
    };

    const handleClose = () => {
        setAnchorEl(null);
        
    };
    const handleClickLogout = () => {
        removeCookie('LoginKey', { path: '/'});
        removeCookie('Name', { path: '/'});
        removeCookie('UserNo', { path: '/'});
        removeCookie('Profile', { path: '/'});
        setCookie('Close' , false, { path: '/'})
        navigate('/');
    };

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
 
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <AppBar component='nav' className='user_navbar'>
                    {/* <Grid>
                        <Grid>로고</Grid>
                        <Grid>앱으로보기</Grid>
                        <Grid>버튼</Grid>
                    </Grid> */}
                    { cookies.Close === 'true' ?
                     " "
                    :
                        <AppBanner appBannerClose={appBannerClose}/>
                    }
                    <Toolbar>
                        <Grid sx={{ mr: 2, display: { sm: 'none' } }}>
                            <IconButton
                                color='inherit'
                                aria-label='open drawer'
                                edge='start'
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                            <img src={WooroMathLogo} alt='wooromath logo' className='menu_mobile_logo' onClick={() => navigate(`/`)}/>
                        </Grid>
                        <Typography
                            variant='h6'
                            component='div'
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <img src={WooroMathLogo} alt='wooromath logo' className='menu_logo' onClick={() => navigate(`/`)} />
                            {navItems.map((item) => (
                            item.link !== 'mockexam' && item.link !== 'evaluation' ?
                            <Link href={`/question/${item.link}`} underline='hover' className={location.pathname.indexOf(`/question/${item.link}`) > -1 && item.link ? 'this_link' : item.title === '복습하기' && (location.pathname.indexOf(`/wrong`) > -1 || location.pathname.indexOf(`/save`) > -1) ? 'this_link' : null}>
                                {item.title}
                            </Link>:
                            <Link 
                                href={`/${item.link}`}
                                underline='hover'
                                className={location.pathname.indexOf(`/mockexam`) > -1 && item.link === 'mockexam' ? 'this_link' : location.pathname.indexOf(`/evaluation`) > -1 && item.link === 'evaluation' ? 'this_link' : null}>
                                {item.title}
                            </Link>
                            ))}
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            {cookies.LoginKey ?
                            <div className='menu_user_name' onClick={handleClickProfile}>
                                <IconButton
                                    size='small'
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 32, height: 32 }} src={cookies.Profile}/>
                                </IconButton>
                                <span>{cookies.Name} <ArrowDropDownIcon/></span>
                            </div>:
                            <>
                                <Button sx={{ color: '#000' }} onClick={() => navigate(`/login`)}>
                                    로그인
                                </Button>
                                <Button sx={{ color: '#fff', backgroundColor: '#F05D38' }} className='menu_button_register' onClick={() => navigate(`/register`)}>
                                    회원가입
                                </Button>
                            </>
                            }
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* 모바일 */}
                <Box component='nav'>
                    <Drawer
                        container={container}
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                            <Typography variant='h6' sx={{ my: 2 }}>
                                <img src={WooroMathLogo} alt='wooromath logo' onClick={() => navigate(`/`)} width='25%'/>
                            </Typography>
                            <Divider />
                            <List>
                                {navItems.map((item, i) => (
                                <ListItem key={i} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={item.link !== 'evaluation' && item.link !== 'mockexam' ? () => navigate(`/question/${item.link}`) : () => navigate(`/${item.link}`)}>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                                ))}
                                <Divider light variant='middle'/>
                                {cookies.LoginKey ?
                                <>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/user/dashboard`)}>
                                        <ListItemText primary='대시보드'/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/user/edit`)}>
                                        <ListItemText primary='개인 정보 수정' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/user/propose`)}>
                                        <ListItemText primary='클래스/학부모' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`user/ticket`)}>
                                        <ListItemText primary='모의고사 응시권' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={handleClickLogout}>
                                        <ListItemText primary='로그아웃' />
                                    </ListItemButton>
                                </ListItem>
                                </>
                                :
                                <>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/login`)}>
                                        <ListItemText primary='로그인' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/register`)}>
                                        <ListItemText primary='회원가입' />
                                    </ListItemButton>
                                </ListItem>
                                </>
                                }
                            </List>
                        </Box>
                    </Drawer>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id='account-menu'
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    className='profile_menu'
                >
                    <MenuItem onClick={() => navigate(`/user/dashboard`)}>대시보드</MenuItem>
                    <MenuItem onClick={() => navigate(`/user/edit`)}>개인 정보 수정</MenuItem>
                    <MenuItem onClick={() => navigate(`/user/ticket`)}>모의고사 응시권</MenuItem>
                    <MenuItem onClick={() => navigate(`/user/propose`)}>클래스/학부모</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleClickLogout}>로그아웃</MenuItem>
                </Menu>
            </Box>
            
        </ThemeProvider>
  );
}

MainMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainMenu;
