import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, Grid, Box, Tabs, Tab, Link } from '@mui/material';
import { useCookies } from 'react-cookie';

import { YyyyMmDdHhMmSs, MockExamQuestionKind, Grade, Attending, QuestionMinute, Percent, StaNine } from 'components/function/ChangeText';
import MockExamPieChart from './MockExamPieChart';

const MockExamDetailView = (props) => {
    const location = useLocation();
    const [cookies] = useCookies(['ClassName']);
    const [value, setValue] = useState(0);
    const handleChange = (e) => {
        setValue(Number(e.target.accessKey));
    };
    const mockExam = props.mockExam;
    const mockExamResults = props.mockExamResults;
    
    let pieChartDatas = [];
    
    const endMockExams = mockExamResults.filter((item) => { return item.State === 2 });
    if(endMockExams.length > 0){
        for(let i = 0; i < 9; i++){
            pieChartDatas.push({
                'id': `${i+1}등급`,
                'label': `${i+1}등급`,
                'value': endMockExams.filter((item) => { return StaNine(1-(item.Rank/mockExam.PartInfo[item.Part-2].Freq)) === i+1 }).length
            });
        }
    }

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>{mockExam.Name.replace('우로매쓰 ', '')}</h2>
                </Grid>
                <Grid item xs={12} md={5} className='diagnosis_result_table only_mock_exam_table'>
                    <table>
                        <tr><th>시험 공개</th><td>{YyyyMmDdHhMmSs(new Date(mockExam.StartDate))}</td></tr>
                        <tr><th>성적 공개</th><td>{YyyyMmDdHhMmSs(new Date(mockExam.StartDate).setDate(new Date(mockExam.StartDate).getDate()+14))}</td></tr>
                        {mockExam.PartInfo.map((item) => (
                            <tr><th>{MockExamQuestionKind(item.Part)}</th><td>{item.Freq}명</td></tr>
                        ))}
                    </table>
                </Grid>
                <Grid item xs={12} md={5} className='teacher_mock_exam_pie_chart'>
                    <div>
                        {new Date(new Date(mockExam.StartDate).setDate(new Date(mockExam.StartDate).getDate()+14)) < new Date() ?
                            endMockExams.length > 0 ?
                                <div style={{ height: '230px', whiteSpace: 'nowrap', overflow: 'hidden', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                                    <MockExamPieChart data={pieChartDatas}/>
                                </div>:
                                <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center' }}>
                                    {cookies.ClassName} 클래스에서 해당 모의고사 회차는 응시한 학생이 없습니다.
                                </div>:
                            <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', textAlign: 'center' }}>
                                성적 공개일에 함께 공개됩니다.
                            </div>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>    
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label='part tabs'>
                        {mockExam.PartInfo.map((item, i) => (
                            <Tab label={MockExamQuestionKind(item.Part)} accessKey={i} />
                        ))}
                        </Tabs>
                    </Box>
                    <Grid className='teacher_mock_exam_detail_table'>
                        <table>
                            <thead>
                                <tr>
                                    <th colSpan={2}>이름</th><th>학교</th><th>원점수</th><th>응시 시간</th><th>표준 점수</th><th>상위 %</th><th>등급</th><th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {mockExamResults.filter((item) => { return item.Part === value+2 }).length > 0 ?
                                mockExamResults.filter((item) => { return item.Part === value+2 }).map((item, i) => (
                                item.State === 1 ?
                                    // 시험 진행 중
                                    <tr>
                                        <td><Avatar src={item.Profile}/></td>
                                        <td>{item.Name}</td>
                                        <td>{item.SchoolName}<br/>{item.Attending === 1 ? <span>{Grade(item.Grade)} · {Attending(item.Attending)}</span> : <span>{Attending(item.Attending)}</span>}</td>
                                        <td colSpan={6}>시험 진행중 입니다.</td>
                                    </tr>:
                                    new Date(new Date(mockExam.StartDate).setDate(new Date(mockExam.StartDate).getDate()+14)) < new Date() ?
                                        // 시험 종료, 성적 공개
                                        <tr>
                                            <td><Avatar src={item.Profile}/></td>
                                            <td>{item.Name}</td>
                                            <td>{item.SchoolName}<br/>{item.Attending === 1 ? <span>{Grade(item.Grade)} · {Attending(item.Attending)}</span> : <span>{Attending(item.Attending)}</span>}</td>
                                            <td>{item.Point}점</td>
                                            <td>{QuestionMinute(item.Time)}</td>
                                            <td>{Math.round(20*((item.Point-mockExam.PartInfo[value].Mean)/mockExam.PartInfo[value].Sd)+100)}점</td>
                                            <td>{Percent(item.Rank/mockExam.PartInfo[value].Freq)}</td>
                                            <td>{StaNine(1-(item.Rank/mockExam.PartInfo[value].Freq))}등급</td>
                                            <td><Link href={`${location.pathname}/${item.UserNo}/${value+2}`}>상세보기</Link></td>
                                        </tr>
                                        :
                                        // 시험 종료, 성적 비공개
                                        <tr>
                                            <td><Avatar src={item.Profile}/></td>
                                            <td>{item.Name}</td>
                                            <td>{item.SchoolName}<br/>{item.Attending === 1 ? <span>{Grade(item.Grade)} · {Attending(item.Attending)}</span> : <span>{Attending(item.Attending)}</span>}</td>
                                            <td>{item.Point}점</td>
                                            <td>{QuestionMinute(item.Time)}</td>
                                            <td colSpan={3}>{YyyyMmDdHhMmSs(new Date(mockExam.StartDate).setDate(new Date(mockExam.StartDate).getDate()+14))}에 공개됩니다.</td>
                                            <td><Link href={`${location.pathname}/${item.UserNo}/${value+2}`}>상세보기</Link></td>
                                        </tr>
                                )):
                                <tr>
                                    <td colSpan={9}>해당 회차 모의고사는 <b>{MockExamQuestionKind(value+2)}</b>{value === 1 ? '을' : '를'} 선택한 학생이 없습니다.</td>
                                </tr>}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default MockExamDetailView;