import React from "react";
import { useNavigate } from "react-router-dom";
import {Grid,Button,} from "@mui/material";
import {East} from "@mui/icons-material";
import review_info from "img/review_info.png";
import review_info_desktop1 from "img/review_info_desktop1.png";
import review_info_desktop2 from "img/review_info_desktop2.png";
import review_info_question from "img/review_info_question.png";

const ReviewInfo = () => {
  const navigate = useNavigate();
  return (
    <Grid className="diagnosisInfo">
      <Grid container justifyContent="center" spcing={10}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={5.5}>
          <Grid container className="title" alignItems={"center"}>
            <Grid item xs={12} sm={12} md={6} className="teacher_img">
              <h1 style={{ fontSize: "40px" }}>복습하기</h1>
              <p style={{ fontSize: "16px", fontWeight: "700" }}>
                로그인 후 이용할 수 있는 서비스 입니다!
              </p>
              <p style={{ fontSize: "16px", fontWeight: "700" }}>
                문제풀기, 진단하기에서 틀린 문제를 확인하고,
              </p>
              <p style={{ fontSize: "16px", fontWeight: "700" }}>
                저장한 문제를 다시 풀어보세요.{" "}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} textAlign="center">
              <img src={review_info} width="250px" alt="img"></img>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h2>복습하기</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <h3 style={{ fontWeight:"500",height:"58px" }} className="info_text">1. 풀었던 문제 대단원 선택</h3>
              <img
                src={review_info_desktop1}
                width="100%"
                alt="img"
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">2.&nbsp;</h3>
                <h3 className="info_text">전체, 오답, 저장 중 원하는 메뉴를 선택 후 복습하기 </h3>
              </div>
              <img
                src={review_info_desktop2}
                width="100%"
                alt="img"
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} mt={12}>
              <h3 className="info_text">3. 복습 시작</h3>
              <img
                src={review_info_question}
                width="100%"
                alt="img"
              ></img>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            size="small"
            color="inherit"
            style={{ padding: "7px", width: "100%", marginTop: "24px" }}
            className="button-wooro"
            endIcon={<East />}
            onClick={() => {
              navigate(`/login`);
            }}
            target="_blank"
          >
            로그인 바로가기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewInfo;
