import {Button,Box} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const MockExamTicketModal = (props) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "20.9306px",
    p: 4,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    width:1000
  };

  return ( 
    <div>
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.name} 선생님께 전달합니다
        </Typography>
        <Box sx={{ display:"flex",alignItems:"center" }}>
          <KeyboardArrowUpIcon sx={{cursor:"pointer"}} onClick={ ()=>props.ticketUp() }/>
          <input type="number"value={props.count} onChange={props.onChangeTicket} name="count" style={{border:"0",background:"#F7F7F7",outline:"none",textAlign:"center",width:"140px",padding:"5px 0",borderRadius:"5px",margin:"0 5px" }}/>
          <KeyboardArrowDownIcon sx={{cursor:"pointer"}}onClick={props.ticketUpdate?()=>props.ticketDown2() : ()=>props.ticketDown()}/>
        </Box>
            {props.ticketUpdate ? 
            <Button disableElevation variant="contained" onClick={()=>props.handleTicketUpdate()} 
              sx={{background:"#F05D38",width:"179px",borderRadius:"6px",color:"#FFFFFF",fontSize:"16px", '&:hover': {backgroundColor: '#F05D38'}}}>{props.count >= 0 ? "추가 전달" : "응시권 회수"}</Button>
            :<Button disableElevation variant="contained" size="small" onClick={()=>props.deliverTicket()} sx={{background:"#F05D38",width:"179px",borderRadius:"6px",fontSize:"16px",'&:hover': {backgroundColor: '#F05D38'}}}>응시권 전달</Button>}
        
      </Box>
    </Modal>
  </div>
   );
}
 
export default MockExamTicketModal;
