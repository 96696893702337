import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/parent/layout';
import { ParentQuestion } from 'components/parent/child/question';

const Question = () => {
    const [cookie] = useCookies(['LoginKey']);
    const LoginKey = cookie.LoginKey;
    const { enqueueSnackbar } = useSnackbar();
    const { ChildNo } = useParams();
    const [childData, setChildData] = useState({
        Attending: '',
        Email: '',
        Grade: '',
        Name: '',
        Questions: [],
        School_id: { Name: '' }
    });
    const [questionCounting, setQuestionCounting] = useState([{
        _id: '',
        Counting: ''
    }]);
    const [questionSummary, setQuestionSummary] = useState({
        Counting: '',
        Correct: '',
        Time: '',
        User: ''
    });

    const loadChildData = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/parent/question/${ChildNo}/${LoginKey}`, config).then((Response) => {
            setChildData(Response.data.ChildInfo);
        }).catch((Error) => {
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    };

    const loadQuestionCounting = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/question/counting`, config).then((Response) => {
            setQuestionCounting(Response.data)
        }).catch((Error) => {
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    };

    const loadQuestionSummary = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/question/summary`, config).then((Response) => {
            const wrongQuestionCount = Response.data.QuestionSummary.filter((item) => { return item._id === 2 })[0];
            const correctQuestionCount = Response.data.QuestionSummary.filter((item) => { return item._id === 1 })[0];
            const loadSummary = {
                Counting: wrongQuestionCount.Counting+correctQuestionCount.Counting,
                Currect: correctQuestionCount.Counting,
                Time: correctQuestionCount.Time,
                User: Response.data.UserSummary.filter((item) => { return item._id === 1 })[0].Count
            };
            setQuestionSummary(loadSummary);
        }).catch((Error) => {
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    };

    useEffect(() => {
        loadChildData();
        loadQuestionCounting();
        loadQuestionSummary();
    }, []);

    return(
        <Layout>
            <ParentQuestion childData={childData} questionCounting={questionCounting} questionSummary={questionSummary}/>
        </Layout>
    );
};

export default Question;