import React, { useState, useEffect, useCallback, createRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/user/layout';
import { EvaluationDetailView } from 'components/user/evaluation';

const EvaluationDetail = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { EvaluationNo } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [evaluation, setEvaluation] = useState({
        Manager_id: { Name: '', Class: [{ ClassNo: '' }] },
        ClassNo: '',
        EvaluationElement: [],
        Students: [{ Student_id: '' }],
        Student: { Grade: '', Name: '', ClassNumber: '', StudentNumber: '', Student_id: { Name: '' }, Answer: [[[]]]},
        Standard: '',
        Score: {
            Labels: [],
            Scores: [],
            LabelsScores: []
        }
    });
    const [selectElement, setSelectElement] = useState(['', '']);

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/evaluation/${EvaluationNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                let loadEvaluation = { ...res.data.EvaluationData };
                loadEvaluation.Student = loadEvaluation.Students.filter((item) => (item.Student_id._id === res.data.User_id))[0];
                if(loadEvaluation.Student.Answer.length === 0){
                    for(let i = 0; i < loadEvaluation.EvaluationElement.length; i++){
                        loadEvaluation.Student.Answer.push([]);
                        for(let j = 0; j < loadEvaluation.EvaluationElement[i].Detail.length; j++){
                            loadEvaluation.Student.Answer[i].push('');
                        }
                    }
                }
                setEvaluation(loadEvaluation);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    });

    useEffect(() => {
        loadDatas();
    }, []);

    // 메모
    const Mobile = () => {return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);}
    let canvas;
    let canvasRef = createRef();

    let pos = {
        drawable: false,
        x: -1,
        y: -1
    };
    let ctx;
    useEffect(() => {
        ClearDraw();
        if(selectElement[0] !== ''){
            if(evaluation.Student.Answer[selectElement[0]][selectElement[1]]){
                let img = new Image();
                img.src = evaluation.Student.Answer[selectElement[0]][selectElement[1]];
                img.crossOrigin='anonymous';
                img.onload = () => {
                    ctx.drawImage(img, 0, 0);
                };                
            }
        }        
    }, [selectElement]);

    let [mode, setMode] = useState(1);
    
    useEffect(() => {
        canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        
        if(Mobile()){
        canvas.addEventListener('touchstart', initDraw);
        canvas.addEventListener('touchend', finishDraw);
        canvas.addEventListener('touchmove', draw);
        canvas.addEventListener('touchout', finishDraw);
        }else{
        canvas.addEventListener('mousedown', initDraw);
        canvas.addEventListener('mouseup', finishDraw);
        canvas.addEventListener('mousemove', draw);
        canvas.addEventListener('mouseout', finishDraw);
        } 
        return () => {
            if(Mobile()){
            canvas.removeEventListener('touchmove', draw);
            }else{
            canvas.removeEventListener('mousemove', draw);
            }
        };
    }, [mode]);

    const getPosition = (event) => {
        if(Mobile()){
            return { X: event.touches[0].clientX, Y: event.touches[0].clientY };
        }else{
            return { X: event.offsetX, Y: event.offsetY };
        } 
    };
    
    const initDraw = (event) => {    
        ctx.beginPath();
        ctx.lineWidth = 3;
        pos = { drawable: true, ...getPosition(event) };
        ctx.moveTo(pos.X, pos.Y);
    };

    const draw = (event) => {
        if(pos.drawable){
            if(mode === 1){
                pos = { ...pos, ...getPosition(event) };
                ctx.lineTo(pos.X, pos.Y);
                ctx.stroke();
            }else if(mode === 2){
                ctx.beginPath();
                if(Mobile()){
                    ctx.clearRect(event.touches[0].pageX-25, event.touches[0].pageY-25, 50, 50);
                }else{
                    ctx.clearRect(event.clientX-event.target.offsetLeft-25, event.clientY-event.target.offsetTop-25, 50, 50);
                }
            }
        }
    };

    const finishDraw = () => {
        pos = { drawable: false, X: -1, Y: -1 };
    };

    const ClearDraw = () => {
        canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const UploadData = async() => {
        canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        if(!isCanvasBlank(ctx)){
            ctx = canvas.getContext('2d');
            const base64 = canvas.toDataURL();
            const file = base64toFile(base64, `memo.png`);
            ClearDraw();
            const formData = new FormData();
            formData.append('imgFile', file);
            formData.append('selectElement[0]', selectElement[0]);
            formData.append('selectElement[1]', selectElement[1]);
            for(let i = 0; i < evaluation.Student.Answer.length; i++){
                for(let j = 0; j < evaluation.Student.Answer[i].length; j++){
                    formData.append(`answer[${i}][${j}]`, evaluation.Student.Answer[i][j]);
                }
            }
            const config = { headers: { 'content-type': 'multipart/form-data' }};
            await axios.post(`/api/evaluation/memo/${EvaluationNo}/${cookies.LoginKey}`, formData, config)
            .then((Response) => {
                let changeAnswer = { ...evaluation };
                changeAnswer.Student.Answer[selectElement[0]][selectElement[1]] = Response.data.memo;
                setEvaluation(changeAnswer);
            })
            .catch((error) => {
                console.log(error);
            })
        }else{
            console.log('x')
        }
    };

    const isCanvasBlank = (ctx) => {
        return !ctx
            .getImageData(0, 0, canvas.width, canvas.height).data
            .some(channel => channel !== 0);
    };
    const base64toFile = (base_data, filename) => {
        var arr = base_data.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type:mime });
    };

    return(
        <Layout>
            <EvaluationDetailView 
                evaluation={evaluation}
                canvasRef={canvasRef}
                mode={mode}
                setMode={setMode}
                ClearDraw={ClearDraw}
                UploadData={UploadData}
                selectElement={selectElement}
                setSelectElement={setSelectElement}
            />
        </Layout>
    );
};

export default EvaluationDetail;