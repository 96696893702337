import { ResponsiveBar } from "@nivo/bar";

const ClassDetailBarChart = (props) => {
  // const data = [
  //   {
  //     country: "1등급",
  //     학생수:2
  //   },
  //   {
  //     country: "2등급",
  //     학생수:2
  //   },
  //   {
  //     country: "3등급",
  //     학생수:6
  //   },
  //   {
  //     country: "4등급",
  //     학생수:5
  //   },
  //   {
  //     country: "5등급",
  //     학생수:10
  //   },
  //   {
  //     country: "6등급",
  //     학생수:7
  //   },
  //   {
  //     country: "7등급",
  //     학생수:3
  //   },
  //   {
  //     country: "8등급",
  //     학생수:3
  //   },
  //   {
  //     country: "9등급",
  //     학생수:4
  //   },
   
  // ];

  return (
    <ResponsiveBar
      data={props.data}
      keys={["학생수"]}
      indexBy="country"
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "pastel1" }}
      colorBy={"indexValue"}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      // enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        // legend: 'country',
        // legendPosition: 'middle',
        // legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: props.ylabel,
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default ClassDetailBarChart;
