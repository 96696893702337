import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ClassMenu } from 'components/teacher/layout';
import { TeacherClassCreate } from 'components/teacher/class';

const ClassCreate = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [className, setClassName] = useState('');
  const [creating, setCreating] = useState(false);
  const [imgBase64, setImgBase64] = useState('');
  const [imgFile, setImgFile] = useState('');

  const handleClickDeleteProfile = () => {
    setImgBase64('');
    setImgFile('');
  };

  const handleChangeProfile = (event) => {
    let reader = new FileReader();

    reader.onloadend = () => {
        // 2. 읽기가 완료되면 아래코드가 실행됩니다.
        const base64 = reader.result;
        if (base64) {
            setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
        }
    }
    if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
        setImgFile(event.target.files[0]); // 파일 상태 업데이트
    }
  };
  const uploadProfile = async(CNO) => {
    if(imgFile){
        const formData = new FormData();
        formData.append('classNo', CNO);
        formData.append('imgFile', imgFile);
        formData.append('loginKey', cookies.LoginKey);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        await axios.put(`/api/class/profile`, formData, config).then((Response) => {
            // console.log(Response);
        }).catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    }else{
        return false
    }
  };

  const handleClickCreate = async() => {
    setCreating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({ loginKey: LoginKey, className: className });
    await axios.post('/api/class', body, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        uploadProfile(Response.data.ClassNo);
        setTimeout(() => {
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          navigate(`/teacher`);
        }, [500]);
      }else{
        setClassName('');
        setCreating(false);
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
 };
  
  return (
    <ClassMenu>
      <TeacherClassCreate
        className={className}
        imgBase64={imgBase64}
        creating={creating}
        setClassName={setClassName}
        handleChangeProfile={handleChangeProfile}
        handleClickDeleteProfile={handleClickDeleteProfile}
        handleClickCreate={handleClickCreate}
      />
    </ClassMenu>
  )
};

export default ClassCreate;