import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useCookies } from 'react-cookie';

import { Menu } from './';

const DrawerAppBar = (props) => {
  const [cookies] = useCookies(['Close']);
  const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Menu />
        <Box component="main">
        {isMobileOrTablet ?
            <Toolbar sx={{pb: cookies.Close === "false" ? '90px' : '8px' }}/>
            :
            <Toolbar sx={{pb:'8px' }}/>
          }        
        </Box>
      </Box>
      {props.children}
    </>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
