import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { BoardCreateView } from "components/Academy/board";
import { Layout } from "components/Academy/Layout";

const BoardCreate = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const[boardInfo,setBoardInfo] = useState({
    title:"",
    text:"",
  });
  const [academyInfo,setAcademyInfo] = useState();

  const handleChange = (event) => {
    let changeBoardInfo = { ...boardInfo };
    changeBoardInfo[event.target.name] = event.target.value;
    setBoardInfo(changeBoardInfo);
  };

  const handleData = async () => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/academy/${LoginKey}`, config).then((Response) => {
        setAcademyInfo(Response.data.Academy)
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
  };

  const createBoard = async () =>{
    if (boardInfo.title.length !==0 && boardInfo.text.length !==0) {
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({loginKey:LoginKey,title:boardInfo.title,content:boardInfo.text,academyId:academyInfo._id});
      await axios.post(`/api/academy/notice`, body, config).then((Response) => {
        navigate(-1)     
        setBoardInfo({title:"",text:""})
    });
    }else{
      enqueueSnackbar(`내용을 입력해 주세요`, { variant: 'error' });
    };
  };

  useEffect(()=>{
    handleData()
  },[]);

  
  return (
  <Layout > 
    <BoardCreateView 
      handleChange={handleChange}
      createBoard={createBoard}
      boardInfo={boardInfo}
    />
  </Layout>
   );
}
 
export default BoardCreate;