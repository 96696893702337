import React from 'react';
import AdminDashboardPresenter from './AdminDashboardPresenter';

const AdminDashboardContainer = () => {
  return (
    <AdminDashboardPresenter />
  );
};

export default AdminDashboardContainer;
