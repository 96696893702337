import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, CircularProgress } from '@mui/material';


const MockExamDetailCreate = (props) => {
    const mockExam = props.mockExam;
    const navigate = useNavigate();

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>모의고사 등록</h3>
                    <TextField
                        name='Name'
                        label='모의고사 명'
                        value={mockExam.Name}
                        onChange={props.handleChangeName}
                        fullWidth={true}
                    />
                    <TextField
                        name='StartDate'
                        label='날짜'
                        value={mockExam.StartDate}
                        onChange={props.handleChangeStartDate}
                        fullWidth={true}
                        type='date'
                    />
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='success' onClick={props.handleClickCreate} disabled={props.creating}>등록</Button>
                    </Grid>
                    {props.creating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='success'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default MockExamDetailCreate;