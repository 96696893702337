import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import MainBannerImage from 'img/main_banner.png';

const Banner = () => {
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    
    const handleClickLivepage = () => {
        window.location.href='https://wooromath.com';
    };
    return(    
        <Grid container className='main_banner' justifyContent={'center'}>
            <span className='main_banner_circle1'/>
            <span className='main_banner_circle2'/>
            <span className='main_banner_circle3'/>
            <Grid item xs={12} sm={12} md={10}>
                {window.location.hostname === 'test.woorotest.com' ?
                <>
                <h1>해당 페이지는 우로매쓰<br/>테스트 페이지입니다.</h1>
                <button onClick={handleClickLivepage}>바로가기</button>
                </>:
                <>
                <h1>5문제로 나의 실력을<br/>진단해보기</h1>
                <button onClick={() => navigate(`/question/diagnosis`)}>진단하기</button>
                </>}
                {/* {cookies.LoginKey ?
                <>
                <h1>5문제로 나의 실력을<br/>진단해보기</h1>
                <button onClick={() => navigate(`/question/diagnosis`)}>진단하기</button>
                </>:
                <>
                <h1>3문제로 나의 실력을<br/>진단해보기</h1>
                <button onClick={() => navigate(`/event/diagnosis`)}>무료진단</button>
                </>} */}
                <img src={MainBannerImage} alt='main banner images'/>
            </Grid>
        </Grid>
    );
};

export default Banner;