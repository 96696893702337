import React, { useState } from 'react';
import { Grid, IconButton, Button } from '@mui/material';
import { Create } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { QuestionMemo } from 'components/user/question';

const EvaluationDetailView = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const evaluation = props.evaluation;
    const classData = props.evaluation.Manager_id.Class.filter((item) => (item.ClassNo === evaluation.ClassNo))[0];
    const [openMemo, setOpenMemo] = useState(false);
    const handleClickOpen = (i, j) => {
        props.setSelectElement([i, j]);
        setOpenMemo(true)
    };
    const handleClickClose = () => {
        props.UploadData();
        setOpenMemo(false);
    };
    return(
        <>
        <div className='content_section'>
        <Grid container justifyContent='center' alignItems='flex-end'>
            <Grid item xs={6} md={5} className='diagnosis_result_title'>
                <h2>{evaluation.Title}</h2>
            </Grid>
            <Grid item xs={6} md={5} sx={{ textAlign: 'right', marginTop: '50px', marginBottom: '30px' }}>
                <b>{classData.ClassName} - {evaluation.Manager_id.Name} 선생님</b>
            </Grid>
            <Grid item xs={12} md={10}>
                <div style={{ width: '100%', overflow: 'auto' }}>
                <table className='evaluation_table'>
                    <tbody>
                        <tr>
                            <th>소속</th>
                            <td colSpan={2}>{evaluation.Student.Grade}학년 {evaluation.Student.ClassNumber}반 {evaluation.Student.ClassNumber}번 {evaluation.Student.Name} ({evaluation.Student.Student_id.Name})</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th>성취기준</th>
                            <td colSpan={2}><div dangerouslySetInnerHTML={{ __html: evaluation.Standard?.replaceAll('\n', '<br/>')}}/></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th>활동</th>
                            <td colSpan={2}>{evaluation.Activity}</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th>체점기준</th>
                            <td colSpan={2}>
                                {evaluation.Score.LabelsScores.length > 0 ?
                                <table className='evaluation_table_score'>
                                    <tr>
                                        <td><b>평가항목</b></td>
                                        {evaluation.Score.Scores.map((score, i) => (
                                        <td key={i}><b>{score}</b></td>
                                        ))}
                                    </tr>
                                    {evaluation.Score.Labels.map((label, i) => (
                                    <tr key={i}>
                                        <td><b>{label}</b></td>
                                        {evaluation.Score.Scores.map((_, j) => (
                                        <td>{evaluation.Score.LabelsScores[i][j]}</td>
                                        ))}
                                    </tr>
                                    ))}
                                </table>:
                                '-'}
                            </td>
                        </tr>
                    </tbody>
                    {evaluation.EvaluationElement.map((item, i) => (
                    <tbody key={i}>
                        {item.Detail.map((item2, j) => (
                        <tr key={j}>
                            {j === 0 ?
                            <th rowSpan={item.Detail.length}>{item.Label}</th> :
                            null}
                            <td>{item2}</td>
                            <td>
                                {evaluation.Student.Answer[i][j] ?
                                <Grid container>
                                    <Grid item xs={11} style={{ padding: 0 }}>
                                        <img
                                            src={evaluation.Student.Answer[i][j]} alt='images'
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} style={{ padding: 0, textAlign: 'right' }}>
                                        <IconButton onClick={() => handleClickOpen(i, j)} disabled={evaluation.State === 2}>
                                            <Create />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                :
                                <IconButton onClick={() => handleClickOpen(i, j)} disabled={evaluation.State === 2}>
                                    <Create />
                                </IconButton>}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    ))}
                </table>
                </div>
            </Grid>
            <Grid item xs={12} md={10} style={{ textAlign: 'right', marginTop: '16px' }}>
                <div style={{ display: evaluation.State === 2 ? 'inline-block' : 'none', marginRight: '16px' }}>
                    * 종료된 수행평가 입니다.
                </div>
                <Button 
                    variant='contained' color='warning'
                    disabled={evaluation.State === 2}
                    onClick={() => { enqueueSnackbar('수고 하셨습니다, 수행평가를 종료합니다.', { variant: 'info' }); navigate(-1); }}
                >
                    제출
                </Button>
            </Grid>
        </Grid>
        </div>
        <QuestionMemo
            open={openMemo}
            handleClickClose={handleClickClose}
            canvasRef={props.canvasRef}
            mode={props.mode}
            setMode={props.setMode}
            ClearDraw={props.ClearDraw}
        />
        </>
    );
};

export default EvaluationDetailView;