import React from 'react';
import { useCookies } from 'react-cookie';
import { Grid, IconButton, Button, CircularProgress } from '@mui/material';
import { Add, Create } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import WooroTestLogoOnly from 'img/woorotest_logo_only.png'

const ParentChildSelect = (props) => {
    const [cookies] = useCookies(['Name']);
    const navigate = useNavigate();
    const childDatas = props.childDatas;

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='teacher_class_select_title'>
                    <h2>{cookies.Name} 학부모님,<br/>관리할 자녀를 선택해 주세요.</h2>
                </Grid>
                <Grid item xs={12} md={5} className='teacher_class_logout_button'>
                    <Button variant='outlined' color='warning' onClick={props.handleClickLogout} sx={{ marginRight: '10px'}}>로그아웃</Button>
                    <Button variant='outlined' color='warning' onClick={() => navigate(`/parent/edit`)}>회원정보수정</Button>
                </Grid>
                <Grid item xs={12} md={5} className='teacher_class_manage_button'>
                    <IconButton color='warning' onClick={() => props.setOpenSearch(true)}>
                        <Add />
                    </IconButton>
                    <IconButton color={props.editMode ? 'warning' : 'secondary'} onClick={() => props.setEditMode(!props.editMode)}>
                        <Create />
                    </IconButton>
                </Grid>
                <Grid item xs={12} md={10} className='teacher_class_list'>
                    {props.loading ? 
                    <Grid style={{textAlign: 'center'}}>
                        <CircularProgress color='warning' />
                    </Grid>:
                    childDatas[0].ChildNo ?
                    <Grid container justifyContent='center'>
                    {childDatas.map((childData, i) => (
                        <Grid item xs={6} sm={4} md={3} className='teacher_class' onClick={() => props.handleClickChild(i)}>
                            <div
                                style={{backgroundImage: childData.Child_id.Profile ? `url('${childData.Child_id.Profile}')`: `url('${WooroTestLogoOnly}')`}}
                                className={props.editMode ? 'teacher_class_edit_mode' : ''}
                            >
                                {props.editMode ?
                                <><div /><Create/></>:
                                null}
                            </div>
                            <p style={{ color: childData.Connect === 2 ? '#000' : '#A4A4A4' }}>{childData.NickName ? childData.NickName : childData.Child_id.Name}</p>
                        </Grid>
                    ))}
                    </Grid>:
                    <Grid style={{textAlign: 'center'}}>
                        <h4>등록된 자녀가 없습니다. 자녀를 등록하고 진도와 성적을 확인해 보세요!</h4>
                        <Button variant='outlined' color='warning' onClick={() => props.setOpenSearch(true)}>자녀 찾기</Button>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default ParentChildSelect;