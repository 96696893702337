import {  Grid, } from "@mui/material";
import { RadioButtonChecked, RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

import * as ChangeText from 'components/function/ChangeText';
import QuestionCheck1 from 'img/question_check1.png';
import QuestionCheck2 from 'img/question_check2.png';

const StudentDiagnosisView = (props) => {
  const config = {
    "fast-preview": { disabled: true },
    loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
    tex: {
        packages: { "[+]": ["html", "img"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
      ],
      displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
      ],
    },
    options: {
        enableMenu: false,
    }
};
  return ( 
     <Grid container justifyContent="center">
       <Grid container lg={11} xl={7} justifyContent={"space-between"} >
        <Grid item lg={5.5} xl={5.5} sx={{background:"#FFFFFF",borderRadius:"21px",paddingBottom:"25px"}}>
        <h1 style={{fontSize:"32px",marginTop:"62px",marginLeft:"73px"}}>진단 결과</h1>
          <p style={{fontSize:"24px",fontWeight:"700",margin:"34px 0 34px 73px"}}>학생 : {props.student?.Name}</p>
          <p style={{fontSize:"24px",fontWeight:"700",marginLeft:"73px"}}>학교 : {props.student?.SchoolName}</p>
        </Grid> 
        <Grid item lg={5.5} xl={5} sx={{background:"#FFFFFF",borderRadius:"21px"}}>
          <p style={{fontSize:"24px",fontWeight:"700",marginTop:"72px",marginLeft:"73px"}}>단위:{ChangeText.Part(props.diagnosis?.Section)}&nbsp;&gt;&nbsp;{ChangeText.MiddlePart(props.diagnosis?.Section)}&nbsp;&gt;&nbsp;{ChangeText.Section(props.diagnosis?.Section)}</p>
          <p style={{fontSize:"24px",fontWeight:"700",margin:"34px 0 34px 73px"}}>평균풀이시간:{ChangeText.QuestionMinute(props.diagnosis?.allTime/5)}</p>
          <p style={{fontSize:"24px",fontWeight:"700",marginLeft:"73px"}}>전국성적:{ChangeText.IntPercent(props.diagnosis?.Percent)}</p>
        </Grid> 
      </Grid>
      <Grid container lg={11} xl={7} sx={{marginTop:"56px"}}>
        <Grid item lg={12} xl={12} pb={2} sx={{background:"#FFFFFF",borderRadius:"21px"}} className='academy_diagnosis_result_questions'>
          <div>
            {props.diagnosis?.Questions?.map((question, i) => (
            <Grid container key={i}>
              <Grid item xs={12} md={1} className='academy_diagnosis_result_number'>
                <img src={question.Check === 1 ? QuestionCheck1 : QuestionCheck2} alt='check 1' />
             </Grid>
             <Grid  item lg={12} xl={12}  className='academy_diagnosis_title'>
             <h2>문제 {question ? question.Question_id.Question-question.Question_id.Section*10000 : null}</h2>
             </Grid>
          <Grid item xs={12} className='question_mathjax'>                 
            <MathJaxContext version={3} config={config}>
              {question.Question_id.Content.map((content ,i) => (
                <MathJax 
                  renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                  text={content.replace('`\\(','').replace('\\)`','')}
                  dynamic
                  className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                />
                ))}
            </MathJaxContext>
              <div style={{textAlign: 'center'}}>
                {question?.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
              </div>
                <div className='diagnosis_questions_answer'>
                  {question.Question_id.NewChoices.map((choice, i) => (
                    question.Question_id.Answer === question.Select ?
                      question.Select === i+1 ?
                    <div key={i}>
                      <RadioButtonChecked className='homework_question_answer'/>
                      <MathJaxContext version={3} config={config}>
                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                      </MathJaxContext>
                    </div>:
                    <div key={i}>
                      <RadioButtonUnchecked/>
                      <MathJaxContext version={3} config={config}>
                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                      </MathJaxContext>
                    </div>:
                      question.Question_id.Answer === i+1 ?
                    <div key={i}>
                      <RadioButtonChecked className='homework_question_answer'/>
                      <MathJaxContext version={3} config={config}>
                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                      </MathJaxContext>
                    </div>:
                      question.Select === i+1 ?
                    <div key={i}>
                      <TaskAlt/>
                      <MathJaxContext version={3} config={config}>
                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                      </MathJaxContext>
                    </div>:
                    <div key={i}>
                      <RadioButtonUnchecked/>
                      <MathJaxContext version={3} config={config}>
                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                      </MathJaxContext>
                    </div>
                    ))}
                 </div>
              </Grid>
            </Grid>
            ))}
          </div>
        </Grid>
      </Grid> 
    </Grid> 
  );
}
 
export default StudentDiagnosisView;