import { Pagination,Grid ,CircularProgress} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { TeacherListView } from "components/Academy/teacher";
import { Layout } from "components/Academy/Layout";
import { DeleteModal } from 'components/modal';
import { AcademySearchForm } from "components/form";
import * as ChangeText from 'components/function/ChangeText';

const TeacherList = () => {
  const navigate = useNavigate();
  const [ cookies] = useCookies(['LoginKey']);
  const loginKey = cookies.LoginKey
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState("");
  const [openDeleteStudent, setOpenDeleteStudent] = useState(false);
  const [teacherList,setTeacherList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getUpdateData = (event) => {
    navigate(`/academy/teacher/${event}/update`);
  };
  const handleClickDelete=()=>{

  };

  const handleClickOpenDeleteStudent = () => {
    setOpenDeleteStudent(true);
  };
  const handleClickCloseDeleteStudent = () => {
    setOpenDeleteStudent(false);
  };


  const loadData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${loginKey}`, config).then((Response) => {
      if (Response.data.Teachers) {
        setTeacherList(Response.data.Teachers)
      }
    }) 
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  useEffect(()=>{
    loadData()
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);
  },[]);

  const rows = [];
  for (let i = 0; i < teacherList?.length; i++) {
    rows.push({
      id: i + 1,
      name: teacherList[i]?.Name,
      phone: teacherList[i]?.Phone,
      email: teacherList[i]?.Email,
      userNo:teacherList[i]?.UserNo,
      profile:teacherList[i]?.Profile,
      birth: ChangeText.YyyyMmDd(teacherList[i].Birth),
    });
  };
//   const rows = [];

// for (let i = 0; i < 108; i++) {
//   rows.push({ name: `이름${i + 1}` });
// }
  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = rows?.slice(startIndex, endIndex);

  // 선생님 디테일 뷰
  const handleClickCell = (userNo) => {
      navigate(`/academy/teacher/${userNo}`);
  };

  //검색 함수
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  
  const filterTeachers = (datas) => {
    if (datas) {
      datas.sort();
      datas = datas.filter((data) => {
        return (
          data?.name?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          data?.email?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      });
      return datas;
    }else{}
  };


return (
  <Layout >
    { loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
      <>
        <h1 style={{width:"91.8%",margin:"0px auto 45px auto",fontSize:"32px",color:"#000000"}}>교사 목록</h1>
        <Grid container justifyContent={"center"}>
          <Grid container lg={11} xl={11}  sx={{  background:"#FFFFFF",borderRadius:'15px',padding:"30px" }}>
            <div>
            <AcademySearchForm
                search={search}
                handleChange={handleChangeSearch}
                searchGuide="이름 / 이메일로 검색"
            />
            </div>
            <TeacherListView
              rows={filterTeachers(visibleData)} 
              handleClickCell={handleClickCell}
              search={search}
              handleChange={handleChangeSearch}
              getUpdateData={getUpdateData}
              handleClickOpenDeleteStudent={handleClickOpenDeleteStudent}
            />
            <Pagination
              count={Math.ceil(rows?.length / pageSize)} // 전체 페이지 수
              page={currentPage} // 현재 페이지
              onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
              color='warning'
              sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
            />
            <DeleteModal 
              openDelete={openDeleteStudent} 
              handleClickOpenDelete={handleClickOpenDeleteStudent} 
              handleClickCloseDelete={handleClickCloseDeleteStudent}
              title='교사 정보 삭제'>
                준비중 입니다.
              {/* 교사 정보가 삭제됩니다.<br/>삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까? */}
            </DeleteModal>
          </Grid>
        </Grid>
      </>
      }
  </Layout>
  );
};

export default TeacherList;
