import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from 'components/parent/layout';
import { ParentQuestionViewDetail } from 'components/parent/child/question';

const QuestionViewDetail = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { ChildNo, Section, QuestionNo } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const loginKey = cookies.LoginKey;

    const [questionDatas, setQuestionDatas] = useState([{
        Check: '',
        CreatedAt: '',
        Select: '',
        Status: '',
        Time: ''
    }]);
    const [questionData, setQuestionData] = useState({
        Info: { Counting: '', Correct: '', Time: '' },
        Question: '',
        Section: '',
        Content: [' '],
        Choices: ['', '', '', '', ''],
        Commentary: [],
        Answer: '',
        Memo: ''
    });
    
    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/parent/question/${ChildNo}/${Section}/${loginKey}`, config).then((Response) => {
            if(!Response.data.ErrorMessage){
                let loadQuestions = [...Response.data.QuestionList];
                loadQuestions = loadQuestions.filter((item) => { return item.Status !== 5 && item.Question_id.Question === Number(QuestionNo) });
                if(loadQuestions[0]){
                    setQuestionDatas(loadQuestions);
                }
            }else{
                enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
            }
        }).catch((Error) => {
            console.log(Error)
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    };

    const loadQuestionData = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/question/${QuestionNo}/${loginKey}`, config).then((Response) => {
            if(!Response.data.ErrorMessage){
                setQuestionData(Response.data.Question);
            }else{
                enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
            }
        }).catch((Error) => {
            console.log(Error)
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    };


    useEffect(() => {
        loadDatas();
        loadQuestionData();
    }, []);
    return(
        <Layout>
            <ParentQuestionViewDetail 
                questionDatas={questionDatas}
                questionData={questionData}
            />
        </Layout>
    );
};

export default QuestionViewDetail;