import {  Button,Grid } from "@mui/material";
import {  useNavigate } from "react-router-dom";

import deleteIcon from 'img/academy_delete_icon.png';

const TeacherCreateView = (props) => {
  const navigate = useNavigate();
  return (
    <Grid container justifyContent={"center"}>
      <Grid container lg={10} xl={5} justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"25px" ,padding:"30px 0"}}>
        <Grid item lg={12} xl={12} sx={{textAlign:"center"}} >
          <h1>교사 생성</h1>
        </Grid>
        <Grid item lg={11.3} xl={5}>
          <h2>이름</h2>
          <input 
            type="text"
            className="academy_input"
            id="name" 
            name="name"
            value={props.teacherInfo.name || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"90%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={6}>
          <h2>생년월일</h2>
          <input type="text"
            id="date"
            name="birth"
            value={props.teacherInfo.birth || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={11} mt={2} mb={2}>
          <h2>전화번호</h2>
          <input 
            type="text"
            id="phone"
            name="phone"
            value={props.teacherInfo.phone || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7", border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={11}>
            <h2>이메일</h2>
            <div style={{display:"flex",alignItems:"center" ,marginBottom:"20px"}}>
              <img src={deleteIcon} alt="deleteIcon" style={{marginRight:"5px"}}/> 
              <span style={{fontWeight:"700"}}>작성하신 이메일로 메일이 전송됩니다. 전송된 메일을 통해 비밀번호 설정 후 로그인이 가능합니다. </span>
            </div>
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <input 
             type="text"
             id="email"
             name="email"
             value={props.teacherInfo.email || ""}
             onChange={props.handleChange}
             disabled={props.emailCheck}
             style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"72%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
            <Button onClick={()=>{(props.emailDoubleCheck())}} sx={{ width:"70px",fontSize:"15px",borderRadius:"10px",margin:"0 20px",background:"#FFFFFF",border:"1px solid #F05D38",color:"#F05D38",'&:hover': { background:"#FFFFFF" } }}>중복확인</Button>
            <Button onClick={()=>{(props.rewrite())}} component='span'color='warning' sx={{ width:"70px",fontSize:"15px",borderRadius:"10px",'&:hover': { background:"#F05D38" } }} variant='contained'>다시작성</Button>
          </div>

        </Grid>
        <Grid item lg={12} xl={12} mt={10} sx={{display:"flex" ,justifyContent:"space-between",padding:"0"}} >
          <div></div>
          <Button component='span'color='warning' sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",marginLeft:"100px",'&:hover': { background:"#EF4C23" } }} disableElevation variant='contained' onClick={props.handleData} disabled={!props.emailCheck}>등록</Button>
          <Button  sx={{ width:"50px",fontSize:"15px",borderRadius:"10px" }} onClick={() => navigate(-1)}>취소</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeacherCreateView;
