import React from "react";
import { Homework, HomeworkDetail, Diagnosis, Review, Solve, Question, DiagnosisResult, HomeworkResult } from "domain/question";
import { Routes, Route } from "react-router-dom";

import NotFound from 'routes/NotFound';

const QuestionPresenter = () => {
  return (
    <>
      <Routes>
        <Route path="/solve" element={<Solve/>} />
        <Route path="/homework" element={<Homework/>} />
        <Route path="/diagnosis" element={<Diagnosis/>} />
        <Route path="/review" element={<Review/>} />
        <Route path="/homework/:homeworkNo" element={<HomeworkDetail />} />
        <Route path="/:status/:section" element={<Question/>} />
        <Route path="/diagnosis/result/:diagnosisNo" element={<DiagnosisResult/>} />
        <Route path="/homework/result/:homeworkNo" element={<HomeworkResult />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default QuestionPresenter;