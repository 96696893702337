import React from 'react';
import { Search } from '@mui/icons-material';

const AcademySearchForm = (props) => {
    return(
        <div style={{border:"1px solid #DDDDDD",display:"flex",alignItems:"center",padding:" 5px",borderRadius:"7px",width:"514px"}}>
          <Search sx={{color:"#A4A4A4"}}/>
          <input
            style={{border:"0" ,outline:"none",width:"514px"}}
            type='text'
            value={props.search}
            onChange={props.handleChange}
            placeholder={props.searchGuide}
            disabled={props.loading}
            />
        </div>
    );
}

export default AcademySearchForm;