import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText, CircularProgress, ListItemIcon, Avatar, Button } from '@mui/material';

import WoorotestLogoOnly from 'img/woorotest_logo_only.png'

const StudentClassList = (props) => {
    
    return(
        <Box
            sx={{ width: '100%', height: 400,  bgcolor: 'background.paper' }}
        >
            <List>
                {props.loading ?
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <CircularProgress color='primary'/>
                </div>
                :
                props.searchClassDatas.map((classData, i) =>(
                classData.ClassName ?
                <ListItem
                    secondaryAction={
                        <Button color='warning' onClick={() => props.handleClickClassApply(i)}>신청</Button>
                  }
                >
                    {/* <Button color='error'>취소</Button>
                    <Button color='inherit' disabled={true}>수강</Button> */}
                    <ListItemIcon>
                        <Avatar sx={{ backgroundColor: '#FBFBFB' }} src={classData.ClassImage ? classData.ClassImage : WoorotestLogoOnly}/>
                    </ListItemIcon>
                    <ListItemText primary={classData.ClassName} secondary={`${classData.ManagerName} 선생님 · ${classData.ManagerEmail}`}/>
                </ListItem>:
                null
                ))}
            </List>
        </Box>
    );
};

export default StudentClassList;