import React from 'react';
import { Grid } from '@mui/material';

import { Layout } from 'components/parent/layout';
import { Banner, Category } from 'components/parent/child/main';
import { Footer } from 'components/user/layout';

const Main = () => {
  return (
    <>
      <Layout>
        <Banner />
        <Grid container justifyContent='center' className='content_section'>
          <Grid item xs={12} md={10} lg={6}>
            <Category />
          </Grid>
        </Grid>
      </Layout>
      <Footer/>
    </>
  );
};

export default Main;