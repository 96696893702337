import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {  CircularProgress } from '@mui/material';

import { Layout } from 'components/teacher/layout';
import { TeacherHomeworkAssign } from 'components/teacher/class/homework';
import * as AddValue from 'components/function/AddValue';
import * as ChangeText from 'components/function/ChangeText';
import kakaoJavascriptKey from 'config/kakaoJSKey';
import { HomeworkAssignInfo } from 'components/info';

const HomeworkAssign = () => {
  const [cookies] = useCookies(['LoginKey', 'Name', 'ClassName']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { ClassNo } = useParams();
  
  const [homeworkData, setHomeworkData] = useState({
    Section: '',
    QuestionsCnt: '',
    Difficulty: 3,
    DueDate: '',
    Notice: '',
  });
  const [parts, ] = useState(AddValue.AllPartsDashboard());
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(0);
  const [questionCntError, setQuestionCntError] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);
  const [homeworkDatas, setHomeworkDatas] = useState([{
    ClassNo: '',
    HomeworkNo: '',
    DueDate: '',
    Notice: '',
    Difficulty: '',
    Section: '',
    StartDate: '',
    Question_ids: [],
    Students: [{
      Name: '',
      Questions: [],
      MemoPictures: []
    }]    
  }]);
  const [studentDatas, setStudentDatas] = useState([
    {
      Connect: "",
      ConnectedAt: "",
      StudentNo: "",
      Student_id: {
        Attending: "",
        Grade: "",
        Name: "",
        School_id: { Name: "" },
        Diagnosis: [],
      },
    },
  ]);
  const handleClickSection = (section) => {
    setHomeworkData({ ...homeworkData, Section: section });
  };
  const handleChangeHomework = (event) => {
    let changeHomework = { ...homeworkData };
    if(event.target.name === 'QuestionsCnt'){
      setQuestionCntError(false);
      if(event.target.value <= ChangeText.SectionQuestions(homeworkData.Section)){
        changeHomework.QuestionsCnt = event.target.value;
      }else{
        setQuestionCntError(true);
        changeHomework.QuestionsCnt = ChangeText.SectionQuestions(homeworkData.Section);
      }
    }else if(event.target.name === 'DueDate'){
      setDueDateError(false);
      let today = new Date();
      today.setHours(9,0,0,0);
      if(new Date(event.target.value) < today){
        setDueDateError(true);
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth() + 1 < 10 ? '0'+(today.getMonth()+1) : today.getMonth() + 1;
        const todayDay = today.getDate();
        changeHomework.DueDate = `${todayYear}-${todayMonth}-${todayDay}`;
      }else{
        changeHomework.DueDate = event.target.value;
      }
    }else{
      changeHomework[event.target.name] = event.target.value;
    }
    setHomeworkData(changeHomework);
  };

  const handleClickHomeworkAssign = async() => {
    setSteps(2);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      section: homeworkData.Section,
      questionsCnt: homeworkData.QuestionsCnt,
      difficulty: homeworkData.Difficulty,
      dueDate: homeworkData.DueDate,
      notice: homeworkData.Notice,
      classNo: ClassNo,
      loginKey: LoginKey
    });
    await axios.post(`/api/homework`, body, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        setTimeout(() => {
          setSteps(3);
          setTimeout(() => {
            setSteps(4);
          }, [1000])
        }, [1000]);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const shareToKakao = () => {
    if(window.Kakao){
      const kakao = window.Kakao;

      if(!kakao.isInitialized()){
          kakao.init(kakaoJavascriptKey);
      }
      kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
              title: `우로매쓰:: ${cookies.Name}(${cookies.ClassName})선생님의 숙제`,
              description: `${ChangeText.Part(homeworkData.Section)} > ${ChangeText.MiddlePart(homeworkData.Section)} > ${ChangeText.Section(homeworkData.Section)}\n${homeworkData.Notice}`,
              imageUrl: `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/kakao_${homeworkData.Section}.png`,
              link: {
                  mobileWebUrl: `https://wooromath.com/question/homework`,
                  webUrl: `https://wooromath.com/question/homework`
              },
          }
      });
    }
  };

  const loadHomeworkDatas = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/teacher/homework/${ClassNo}/${LoginKey}`, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        if(Response.data.Homeworks.length > 0){
          let loadHomeworks = Response.data.Homeworks;
          setHomeworkDatas(loadHomeworks);
        }
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }
  useEffect(() => {
    const loadDashboardData = async () => {
      const config = { headers: { "Content-type": "application/json" } };
      await axios
        .get(`/api/teacher/dashboard/${ClassNo}/${LoginKey}`, config)
        .then((Response) => {
          if (!Response.data.ErrorMessage) {
          let loadStudents = Response.data.ClassInfo.Students
            setStudentDatas(loadStudents);
          } else {
            enqueueSnackbar(`${Response.data.ErrorMessage}`, {
              variant: "error",
            });
            navigate(-1);
          }
        })
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: "error" });
        });
    };
    loadDashboardData();
  }, []);

  useEffect(() => {
    loadHomeworkDatas();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, [500]);
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  return (
    <Layout>
      <div className='question_front_cover' style={{backgroundColor: loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',display:loading?"block":"none"}}>{ loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
      {studentDatas[0]?.StudentNo ?
      <TeacherHomeworkAssign
        parts={parts}
        homeworkData={homeworkData}
        steps={steps}
        setSteps={setSteps}
        questionCntError={questionCntError}
        setQuestionCntError={setQuestionCntError}
        dueDateError={dueDateError}
        setDueDateError={setDueDateError}
        handleClickSection={handleClickSection}
        handleChangeHomework={handleChangeHomework}
        handleClickHomeworkAssign={handleClickHomeworkAssign}
        shareToKakao={shareToKakao}
      />
      :
      <HomeworkAssignInfo/>
      
    }
    </Layout>
  )
};

export default HomeworkAssign;