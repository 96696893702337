import { ResponsiveRadar } from '@nivo/radar'

const  StudentDetailRadarChart= (props) => {
  // const data=[
  //   {"taste": "수학","백분율":10},
  //   {"taste": "수학|","백분율":20},
  //   {"taste": "수학||","백분율":30},
  //   {"taste": "확률과 통계","백분율":40},
  //   {"taste": "기하","백분율":10},
  //   {"taste": "미적분","백분율":50},
  // ]
const newData = props.parts.map(data => {
  if (data.name === "수학Ⅰ") {
    return { ...data, name: "수학1" };
  } else if (data.name === "수학Ⅱ") {
    return { ...data, name: "수학2" };
  } else {
    return data;
  }
});

const handleSliceClick = (slice) => {
    props.setStudentDiagnosis(true)

    setTimeout(() => {
        window.location.href='#partTitle'
      }, [100]);

    const part=newData.filter((data)=>data.name ===slice.id)
    props.setPart(part);
  };

  const adjustedData = props.data.map((d) => ({
    ...d,
    백분율: 100 - parseInt(d.백분율),
  }));

  const CustomTooltip = ( data ) => {
    const toolTip=props.data.filter((item)=>item.name === data.index)
    return (
      <div style={{ background: '#f05d38', padding: '10px', borderRadius: '4px',height:"20px",display:"flex",alignItems:"center" }}>
        <p style={{color:"#FFFFFF"}}>{toolTip[0].name}:{toolTip[0].tool}%</p>
      </div>
    );
  };

  const renderLabel = (id, position) => {
    return (
      <g key={id}>
        <text
          x={id.x}
          y={id.y}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            cursor: 'pointer',
            fill:"#1976d2"
          }}
          onClick={event => handleSliceClick(id)}
        >
         {id.id}
        </text>
      </g>
    );
  };
  const theme = {
    axis: {
      ticks: {
        text: {
          fill: "#1976d2",cursor: 'pointer',
        }
      }
    },
  };
  
  return ( 
    <ResponsiveRadar
    data={adjustedData}
    keys={[ '백분율' ]}
    indexBy="name"
    maxValue={100} 
    theme={theme}
    valueFormat=">-.2f"
    margin={{ top: 50, right: 80, bottom: 40, left: 80 }}
    borderColor={{ from: 'color' }}
    colors={{ scheme: 'dark2' }}
    enableDots={true}
    gridLabelOffset={26}
    defs={{cursor: 'pointer'}}
    dotBorderWidth={2}
    // colors={{ scheme: 'nivo' }}
    blendMode="multiply"
    motionConfig="wobbly"
    legends={[]}
    gridShape="linear"
    dotSize={6}
    dotColor={{ from: 'color', modifiers: [] }}
    // isInteractive={true} 
    gridLabel={renderLabel}
    sliceTooltip={CustomTooltip} 
    />
)
}
 
export default StudentDetailRadarChart;