import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { AchieveListView } from "components/admin/Achieve";
import { Layout } from "components/admin/layout";

const AchieveList = () => {
  const [cookies] = useCookies(['LoginKey']);

  const [achieves, setAchieves] = useState([]);
  const [search, setSearch] = useState('');

  const columns = [
    // { field: 'id', headerName: 'No.', width: 70, renderCell: (params)=>(<div className='list_click'>{params.row.id}</div>)  },
    { field: 'Name', headerName: '이름', width: 100 },
    { field: 'AchieveNo', headerName: '번호', width: 150 },
    { field: 'Level', headerName: '난이도', width: 100 },
    { field: 'CreatedAt', headerName: '생성일시', width: 100 },
];
const filterQuestions = (datas) => {
  datas.sort();
  datas = datas.filter((data) => {
      return data.Name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
              data.AchieveNo.toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
  });
  return datas;
};
// console.log(achieves)
// const handleClickCell = (event) => {
//   if(event.field === 'id'){
//       navigate(`/admin/question/${event.row.Link}`);
//   }else{
//       return false;
//   }
// }
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
}
  const loadAchieve = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/achieve/${cookies.LoginKey}`, config).then((Response) => {
      setAchieves(Response.data.Achieves)
    }).catch((Error) => {
      console.log(Error);
  });
  };

  useEffect(()=>{
    loadAchieve();
  },[]);
  const pages = [
    { title: '업적 관리'},
    { title: '업적 목록'}
  ];
  const title = { main: '업적 목록', sub: 'Achieve'};
  return ( 
    <Layout pages={pages} title={title}>
      <AchieveListView 
      achieves={achieves} 
      filterQuestions={filterQuestions} 
      handleChangeSearch={handleChangeSearch}
      // handleClickCell={handleClickCell}
      columns={columns}
      />
    </Layout>
   );
}
 
export default AchieveList;