import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, Stepper, Step, StepLabel, Accordion, Typography, AccordionDetails, AccordionSummary, CircularProgress, Button, FormControl, Input, TextField, FormHelperText } from '@mui/material';
import { ExpandMore, Check } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

import { TeacherCustomQuestionView } from '.';
import * as ChangeText from 'components/function/ChangeText';

const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: { 
          color: '#A4A4A4',
          '&.Mui-active': {
            color: '#F05D38'
          },
          '&.Mui-completed': {
            color: '#F05D38'
          }
        }
      }
    }
  },
  palette: {
      secondary: { main: '#A4A4A4'},
      warning: { main: '#F05D38'}
  },
});
const TeacherHomeworkCustomView = (props) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { ClassNo } = useParams();
  const stepList = ['단원 선택','문제 선택','숙제 옵션', '완료'];

  const config = {
    "fast-preview": { disabled: true },
    loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
    tex: {
        packages: { "[+]": ["html", "img"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
      ],
      displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
      ],
    },
    options: {
        enableMenu: false,
    }
};

  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };
  
  const handleClickSection = (section) => {
      props.handleClickSection(section);
  };
    const handleClickNext = () => {
      if(props.steps === 0){
        props.setSteps(1);
      }else{
        props.handleClickHomeworkAssign();
      }
  };
  const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


  return (
    <>
        {isMobileOrTablet ?
        <h1 style={{fontSize:"36px",textAlign:"center",marginTop:"300px"}}>PC에서 사용 가능한 기능입니다.</h1>
        : 
          <ThemeProvider theme={theme}>
          <div className='content_section' style={{marginTop: '60px',}}>
              <Grid container justifyContent='center'>
                  <Grid item xs={12} md={8}>
                      <Box sx={{ width: '100%' }}>
                          <Stepper activeStep={props.steps} alternativeLabel>
                              {stepList.map((label, i) => {
                              return (
                                  <Step key={label}>
                                      <StepLabel error={false}>{label}</StepLabel>
                                  </Step>
                              );
                              })}
                          </Stepper>
                      </Box>
                  </Grid>
                  <Grid container justifyContent='center' xs={12} md={10} lg={12}>
                    {props.steps === 0 ? 
                    <Grid item xs={12} md={10} lg={6} style={{marginTop: '30px'}}>
                      {props.parts.map((part) => (
                      <Accordion expanded={expanded === part.name} onChange={handleChange(part.name)}>
                          <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                          >
                              <Typography><b>{part.name}</b></Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                              <Grid container>
                              {part.subSections.map((subSection) => (
                                  subSection.sections.map((section) => (
                              <Grid item xs={6} sm={4} md={3}>
                                <div className={`teacher_homework_assign_section ${props.questionSection.some((data) => data === section.section) ? 'selected' : ''}`}onClick={() => handleClickSection(section.section)}>
                                      {subSection.name}<br/>&gt; <b>{section.name}</b>
                                </div>
                              </Grid>
                                  ))
                              ))}
                              </Grid>
                          </AccordionDetails>
                      </Accordion>
                      ))}
                      </Grid>
                      :
                      props.steps === 1 ? 
                        <TeacherCustomQuestionView 
                          filterQuestions={props.filterQuestions}
                          questions={props.questions}
                          questionSection={props.questionSection}
                          setSelectQuestions={props.setSelectQuestions}
                          selectQuestions={props.selectQuestions}
                          handleChangeSearch={props.handleChangeSearch}
                          search={props.search}
                          />
                      :
                      props.steps === 2 ?
                      <Grid container mt={8} justifyContent={'center'}>
                          <Grid container lg={5} xl={3.3} justifyContent={'center'} sx={{ boxShadow:"0px 7px 29px 0px rgba(100, 100, 111, 0.20)",padding:"60px 0"}}>
                              <Grid item xs={12} md={12} lg={12} xl={11}>
                                <Grid item xs={12} sx={{fontSize:"24px",fontWeight:"500",marginBottom:"15px"}} >제목</Grid>
                                <Grid item xs={12} mb={2} className='homework_form_data'>
                                  <FormControl variant='standard' sx={{ width: '100%' }}>
                                    <Input
                                      type='text'
                                      color='warning'
                                      name='Title'
                                      // onFocus={() => props.setQuestionCntError(false)}
                                      // placeholder={`최대 ${ChangeText.SectionQuestions(props.homeworkData.Section)}문제 까지 설정할 수 있습니다.`}
                                      value={props.homeworkData.Title}
                                      onChange={props.handleChangeHomework}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sx={{fontSize:"24px",fontWeight:"500",marginBottom:"15px",marginTop:"25px"}} >활동명</Grid>
                                <Grid item xs={12} mb={2} className='homework_form_data'>
                                  <FormControl variant='standard' sx={{ width: '100%' }}>
                                    <Input
                                      type='text'
                                      color='warning'
                                      name='activityName'
                                      // onFocus={() => props.setQuestionCntError(false)}
                                      // placeholder={`최대 ${ChangeText.SectionQuestions(props.homeworkData.Section)}문제 까지 설정할 수 있습니다.`}
                                      value={props.homeworkData.activityName}
                                      onChange={props.handleChangeHomework}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sx={{fontSize:"24px",fontWeight:"500",marginBottom:"18px",marginTop:"25px"}}>숙제 기한</Grid>
                                  <Grid item xs={12} mb={2} className='homework_form_data'>
          
                                    <FormControl variant='standard' sx={{ width: '100%' }}>
                                      <Input
                                        type='datetime-local'
                                        color='warning'
                                        name='DueDate'
                                        onFocus={() => props.setDueDateError(false)}
                                        value={props.homeworkData.DueDate}
                                        onChange={props.handleChangeHomework}
                                      />
                                      {props.dueDateError ? <FormHelperText error={true}>오늘 이전 날짜일 수 없습니다.</FormHelperText> : null}
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sx={{fontSize:"24px",fontWeight:"500",marginTop:"25px",marginBottom:"12px"}}>전달 사항</Grid>
                                    <Grid item xs={12} className='homework_form_data'>
                                      <FormControl variant='standard' sx={{ width: '100%'}}>
                                        <TextField
                                          multiline
                                          name='Notice'
                                          rows={8}
                                          color='warning'
                                          value={props.homeworkData.Notice}
                                          onChange={props.handleChangeHomework}
                                        />
                                    </FormControl>
                                </Grid>              

                              </Grid>
                          </Grid>
                      </Grid>
                      :
                      props.steps === 3 ?
                      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                          <CircularProgress color='warning'/>
                          <p>숙제 {props.steps === 2 ? '생성' : '전송'} 중... 잠시만 기다려 주세요.</p>
                      </div>:
                      <div style={{ textAlign: 'center', marginTop: '20px' }}>
                          <Check color='warning' fontSize='large' />
                          <p>숙제 전송 완료!</p>
                      </div>
                      }
                  </Grid>
                  {props.steps !== 4 ?
                  <Grid container justifyContent='center'>
                    {props.steps === 0 || props.steps === 1  ?
                      <Grid item xs={12} md={11} lg={11.2} xl={9.6} className='teacher_homework_assign_btn'>
                        <Button variant='outlined' color='secondary' disabled={props.steps === 0 ? true : false} onClick={() => props.setSteps(props.steps-1)}>이전</Button>
                        {props.steps === 0  ?
                          <Button variant='contained' color='warning' disabled={ !props.questionSection.length > 0 ? true : false} onClick={handleClickNext}>다음</Button>:
                          <Button variant='contained' color='warning' disabled={!props.selectQuestions.length > 0 ? true : false} onClick={handleClickNext}>다음</Button>
                        }
                      </Grid>:
                      <Grid item xs={12} md={10} lg={11.2} xl={9.6} className='teacher_homework_assign_btn'>
                          <Button variant='outlined' color='secondary'  onClick={() => props.setSteps(props.steps-1)}>이전</Button>
                          <Button variant='contained' color='warning'  disabled={!props.homeworkData.Title || !props.homeworkData.DueDate ? true : false} onClick={props.handleClickHomework}>완료</Button>   
                      </Grid>
                    }
                  </Grid>:
                  <Grid container justifyContent='center'>
                      <Grid item xs={12} md={10} lg={6} className='teacher_homework_assign_btn'>
                          <Button variant='outlined' color='warning' onClick={() => navigate(`/teacher/${ClassNo}/homework`)}>숙제 관리</Button>
                          <Button variant='contained' color='warning' onClick={props.shareToKakao}>카카오톡 공유</Button>
                      </Grid>
                  </Grid>}
              </Grid>
          </div>
      </ThemeProvider>
      }
    </>
    
    );
}
 
export default TeacherHomeworkCustomView;