import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { East } from "@mui/icons-material";
import { ArrowRightAlt } from "@mui/icons-material";
import IconBookmark from "img/icon_bookmark.png";
import mockexamlist from "img/mockexamlist.png";
const TeacherMockExamInfo = () => {
  const navigate = useNavigate();
  return (
    <Grid className="diagnosisInfo">
      <Grid container justifyContent="center" spcing={10}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={5.5}>
          <h1 style={{ fontSize: "40px", textAlign: "center" }}>
            현재 Free 요금제 사용중입니다.{" "}
          </h1>
          <h2 style={{ fontSize: "20px", textAlign: "center" }}>
            유료회원만 사용 가능한 서비스입니다.{" "}
          </h2>

          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h2>요금제 업그레이드</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">1.&nbsp;</h3>
                <h3 style={{ marginBottom: "5px", fontWeight: "500" }}>
                  결제하기, 문의하기를 통해 요금제 업그레이드가 가능합니다.
                </h3>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            className="teacher_price_kind"
          >
            <Grid item xs={12}>
              <h3>학생수에 따른 차등 요금제로 업그레이드</h3>
              <p>
                학생 수 15인 미만은 무료로 서비스를 제공합니다. Teacher Basic /
                Academy 서비스 구독 시{" "}
                <b
                  style={{
                    color: "#5093ff",
                    borderBottom: "1px solid #5093ff",
                  }}
                >
                  유료 이용약관
                </b>
                을 반드시 확인해 주세요!
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="teacher_price_content">
                <h4>Free</h4>
                <p>학생 수 15인 이하</p>
                <div>
                  <span>무료</span>
                </div>
                <p>* 현재 사용중인 서비스 입니다.</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div
                className="teacher_price_content"
                style={{
                  border: "5px solid #F05D38",
                  position: "relative",
                }}
              >
                <img
                  src={IconBookmark}
                  alt="icon Bookmark"
                  style={{
                    position: "absolute",
                    width: "100px",
                    right: "-6%",
                    top: "-10%",
                  }}
                />
                <h4>Teacher Basic</h4>
                <p>학생 수 16인 이상, 30인 이하</p>
                <div>
                  <span>₩ 165,000 </span>
                  <span>/ 1개월</span>
                </div>
                <Button
                  color="warning"
                  variant="contained"
                  disableElevation
                  endIcon={<ArrowRightAlt />}
                  onClick={() => {
                    navigate(`/teacher/price`);
                  }}
                >
                  결제하기
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div
                className="teacher_price_content"
                style={{ backgroundColor: "#F2F2F2" }}
              >
                <h4>Academy</h4>
                <p>학생 수 31인 이상은 Academy 요금제 사용</p>
                <div>
                  <span>요금 보기 </span>
                  <span>/ 개인 문의</span>
                </div>
                <p>
                  홈페이지 하단 <b>채널톡</b> 또는
                </p>
                <p>
                  {" "}
                  <b
                    style={{
                      color: "#5093ff",
                      borderBottom: "1px solid #5093ff",
                    }}
                  >
                    info@woorotest.com
                  </b>
                  로 메일 문의
                </p>
                <Button
                  color="warning"
                  variant="contained"
                  disableElevation
                  endIcon={<ArrowRightAlt />}
                  onClick={() => {
                    navigate(`/teacher/price`);
                  }}
                >
                  바로가기
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12} mt={6}>
              <h2>모의고사</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">1.&nbsp;</h3>
                <h3 style={{ marginBottom: "5px", fontWeight: "500" }}>
                  회차별 응시 학생, 상세보기를 통한 세부 데이터를 확인할 수
                  있습니다.
                </h3>
              </div>
              <img
                src={mockexamlist}
                width="100%"
                alt="img"
                style={{ margin: "20px 0" }}
                className="info-img"
              ></img>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            color="inherit"
            style={{ padding: "7px", width: "100%", marginTop: "24px" }}
            className="button-wooro"
            endIcon={<East />}
            onClick={() => {
              navigate(`/teacher/price`);
            }}
            target="_blank"
          >
            요금제 업그레이드 바로가기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeacherMockExamInfo;
