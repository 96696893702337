import React from 'react';
import { Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, InputAdornment, IconButton, Avatar, Button, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';

const TeacherUpdate = (props) => {
    const navigate = useNavigate();
    return (
        <div className='content_section'>
            <Grid container justifyContent='center' className='content_section'>
                <Grid xs={12} sm={10} md={8} lg={6} className='user_edit'>
                    <h2>개인 정보 수정</h2>
                    <div>
                        <FormControl fullWidth >
                            <Grid container justifyContent='space-between'>
                                <Grid xs={6} style={{padding: '0px'}}><Avatar className='upload_profile_preview'  src={props.imgBase64 ? props.imgBase64 : props.teacher.Profile} style={{width: '100px', height: '100px'}} /></Grid>
                                <Grid xs={6}  style={{padding: '0px', textAlign: 'right'}}>
                                    <Button color='inherit' onClick={props.handleClickDeleteProfile}>
                                        삭제
                                    </Button>
                                    <label htmlFor='Profile' className='upload_profile'>
                                        <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangeProfile}/>
                                        <Button component='span'>
                                            등록
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='user_name' color='warning' shrink>이름</InputLabel>
                            <OutlinedInput
                                id='user_name'
                                color='warning'
                                type='text'
                                label='이름'
                                name='Name'
                                value={props.teacher.Name}
                                onChange={props.handleChange}
                                placeholder='이름을 입력해 주세요'
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='user_phone' color='warning' shrink>전화번호</InputLabel>
                            <OutlinedInput
                            id='user_phone'
                            color='warning'
                            type='text'
                            name='Phone'
                            label='전화번호'
                            placeholder='전화번호를 입력해 주세요'
                            value={props.teacher.Phone}
                            onChange={props.handleChange}
                            />
                        </FormControl>
                        <hr/><br/>
                     
                        {/* <FormControl fullWidth>
                            <InputLabel id='user_gender' color='warning' shrink>성별</InputLabel>
                            <Select
                                labelId='user_gender'
                                color='warning'
                                name='Gender'
                                label='성별'
                                disabled={true}
                                value={props.teacher.Gender}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value='' disabled><span>성별</span></MenuItem>
                                <MenuItem value='1'>남</MenuItem>
                                <MenuItem value='2'>여</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='user_name' color='warning' shrink>생년월일</InputLabel>
                            <OutlinedInput
                                id='user_name'
                                color='warning'
                                type='date'
                                label='생년월일'
                                name='Name'
                                disabled={true}
                                value={ChangeText.YyyyMmDd(props.teacher.Birth)}
                                placeholder='이름을 입력해 주세요'
                            />
                        </FormControl> */}
                        <FormControl fullWidth>
                            <InputLabel htmlFor='user_email' shrink>{props.teacher.Login !== 1 ? props.teacher.Login === 2 ? '카카오 로그인' : '네이버 로그인' : '이메일'}</InputLabel>
                            <OutlinedInput
                                id='user_email'
                                color='warning'
                                type='text'
                                label={props.teacher.Login !== 1 ? props.teacher.Login === 2 ? '카카오 로그인' : '네이버 로그인' : '이메일'}
                                name='Email'
                                placeholder='이메일을 입력해 주세요'
                                disabled={true}
                                value={props.teacher.Email}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <Button variant='outlined' color='inherit' onClick={() => props.setOpenUserDelete(true)}>회원 탈퇴</Button>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <hr/><br/>
                        <div>
                            
                            {props.teacher.Login === 1 ? <Button variant="outlined" onClick={props.setOpenPasswordChange}>비밀번호 변경</Button> : null}
                            {props.updating ?
                            <CircularProgress className='user_edit_btn' color='warning'/>:
                            <Button className='user_edit_btn' variant="contained" color='warning' onClick={props.handleClickUpdate}>수정</Button>}
                            <Button className='user_edit_btn' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default TeacherUpdate;