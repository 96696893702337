import { Grid, Chip, Checkbox,Avatar,CircularProgress, Button } from '@mui/material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useState,useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { SearchForm } from 'components/form';

const TeacherCustomQuestionView = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [question, setQuestion] = useState();
  const [loading , setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [eee, setEEE] = useState(false);
  const config = {
    "fast-preview": { disabled: true },
    loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
    tex: {
        packages: { "[+]": ["html", "img"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
      ],
      displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
      ],
    },
    options: {
        enableMenu: false,
    }
};

  const handleSelectionClick = (params)=>{ //문제 보여주는 용
    const filterQuestion = props.selectQuestions.filter((item)=> item.id === params.id);
    const check = props.selectQuestions.some((question) => question.id === params.id)
    if (check) {
      setDisabled(false)
      setQuestion(...filterQuestion);
    }else{
      setDisabled(true)
      setQuestion(params);
    }
  };

  const handleSelectionChange = (selectedQuestion) => { 
    setQuestion(selectedQuestion);
    const check = props.selectQuestions.some((question) => question.id === selectedQuestion.id)
    if (check) {
      setDisabled(true)
    }else{
      setDisabled(false)
    };
    props.setSelectQuestions((prevQuestions) => {
      const questionExists = prevQuestions.some((question) => question.id === selectedQuestion.id);
        if (questionExists) {
        return prevQuestions.filter((question) => question.id !== selectedQuestion.id);
      } else {
        return [...prevQuestions, selectedQuestion ];
      }
    });
  };

  const handleDelete = (No) => {
    const deleteQuestions =  props.selectQuestions.filter((list) => list.id !== No);
    props.setSelectQuestions(deleteQuestions);
  };

  useEffect(()=>{
    if (props.questionSection.length === props.questions.length) {
      setLoading(true)
    };
  },[props.questions]);

  const handleChange = (event)=>{
    const filterQuestions =  props.selectQuestions.filter((list) => list.id !== question.id);
    let changeQuestion = { ...question };
    changeQuestion[event.target.name] = event.target.value;
    setQuestion(changeQuestion)
    props.setSelectQuestions([changeQuestion, ...filterQuestions])
  };

  // const handleClickStandard = () =>{
  //   if (question.Standard) {
  //     const filterQuestion = props.selectQuestions.filter((item)=> item.id !== question.id);
  //     props.setSelectQuestions([...filterQuestion , question])
  //     enqueueSnackbar(`등록되었습니다.`, { variant: 'info' });
  //   }else{
  //     enqueueSnackbar(`내용을 작성해 주세요.`, { variant: 'error' });
  //   }
  // };

  return (      
    <Grid container xl={10} sx={{ marginTop: "30px" }}>
       <Grid container lg={6} xl={6} sx={{height:300,'@media (max-width:1200px)': {height:'auto'},}} >
       <Grid item  lg={12} xl={12}>
         {question &&
         <div >
           <div style={{margin:"5px 0",width:"100%",borderBottom:"1px solid rgba(224, 224, 224, 1)",paddingBottom:"5px"}}>
             <table style={{ borderSpacing: 0 ,width:"100%"}}>
               <tr>
                 <td style={{ fontSize:"18px",fontWeight:"700" }}>단원</td>
                 <td style={{ fontSize:"18px",fontWeight:"700" }}>{`${question.Part} > ${question.MiddlePart} > ${question.Section}`}</td>
               </tr>
               <tr>
                 <td style={{ fontSize:"18px",fontWeight:"700" }}>문제번호</td>
                 <td style={{ fontSize:"18px",fontWeight:"700" }}>{question.id}</td>
               </tr>
               <tr>
                 <td style={{fontSize:"18px",fontWeight:"700" }}>난이도</td>
                 <td style={{fontSize:"18px" ,fontWeight:"700"}}>{question.Difficulty}</td>
               </tr>
             </table>
             <div style={{display:"flex",justifyContent:"space-between"}}>
              <h1>성취 기준</h1>
              {/* <Button variant='contained' color='warning' disabled={disabled} style={{marginTop:"30px",marginBottom:"10px"}} onClick={handleClickStandard}>추가</Button> */}
             </div>
             <textarea name="Standard" id="" cols="30" rows="10" style={{width:"99.5%", resize: "none",fontSize:"16px" ,border:"1px solid rgba(224, 224, 224, 1)",padding:"8px"}}  onChange={handleChange} value={question.Standard}></textarea>
             <h1 style={{marginBottom:"0px"}}>문제</h1>
             <MathJaxContext version={3} config={config}>
               {question.Content.map((content) => (
               <MathJax 
               renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
               text={
                 content.replace('`\\(','').replace('\\)`','')
               }
               dynamic
               className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
             />
               ))}
            </MathJaxContext>
           <div className='homework_questions_answer'>
             {question.Choices.map((choice,i)=>(
             question?.Answer === i+1 ?
             <div >
               <RadioButtonChecked className='homework_question_answer'/>
               <MathJaxContext version={3} config={config}>
                   <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                     choice.replace('`\\(','').replace('\\)`','')
                     } inline dynamic/>
               </MathJaxContext>
             </div>:
             <div>
               <RadioButtonUnchecked/>
               <MathJaxContext version={3} config={config}>
                   <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                     choice.replace('`\\(','').replace('\\)`','')
                     } inline dynamic/>
               </MathJaxContext>
             </div>
              ))}
           </div>
          </div>
          <h1 style={{marginBottom:"10px"}}>해설</h1>
          {question.Commentary.length > 0 ?
           <div style={{margin:"10px 0",width:"100%"}}>
               <MathJaxContext version={3} config={config} >
               {question.Commentary?.map((item) => (
                 <MathJax
                     renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                     text={
                         item.replace('`\\(','').replace('\\)`','')
                     }
                     dynamic
                     className={item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                 />
                 ))}
               </MathJaxContext>
           </div>:
           <p style={{fontSize:"16px"}}>해당 문제에 해설이 없습니다 빠른 시일 내 추가하겠습니다</p>
          }
         </div>
        }
       </Grid>
     </Grid>
     
     <Grid container lg={6} xl={6} >
       {loading ?
       <Grid item lg={12} xl={12} sx={{padding:"0 8px !important"}}>
         <SearchForm search={props.search} handleChange={props.handleChangeSearch} searchGuide='문제번호 / 대단원 / 소단원으로 검색' />
           <div style={{height:400,overflowY:'scroll',overflowX:"hidden",border:"1px solid rgba(224, 224, 224, 1)",marginTop:"10px"}}>
             {props.questions &&
             props.questions.map((item)=>(
               <>
                {props.filterQuestions(item.list).length > 0 ? 
                 <h2 style={{margin:"15px 0 5px 20px "}} className={`section_selected_color_${item.Section.toString()[0]}`}>{item.title}</h2>
                 :""
                }
                 <div style={{ display:"flex",flexWrap:"wrap" }}>    
                 {props.filterQuestions(item.list).map((item2)=>(
                   <div style={{width:"100px",display:"flex",height:"20px",alignItems:"center",margin:"8px" }}>
                     <Checkbox  onChange={()=>handleSelectionChange(item2)} checked={ props.selectQuestions.some((question) => question.id === item2.id)} />
                     <p style={{fontSize:"20px",cursor:"pointer"}} onClick={() => handleSelectionClick(item2)}>{item2.id}</p>
                   </div>
                 ))}
                 </div>
               </>
             ))}
           </div>
       </Grid>:
        <CircularProgress />
       }
       <Grid item lg={12} xl={12} sx={{ marginTop: "20px", padding: "0px !important"}}>
         { props.selectQuestions.map((item , i) => (
           <Chip label={item.id} key={i} className={`custom_homework_part_color_${item.id.toString()[0]}`} sx={{ margin: "0 5px 8px 5px" }} variant='outlined'                                 
           avatar={<Avatar src={`https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${item.SectionNo}.png`} />}
           onDelete={() => handleDelete(item.id)} 
           onClick={() => handleSelectionClick(item)}
           />
         ))}
       </Grid>
     </Grid>
    </Grid> 
  );
}

export default TeacherCustomQuestionView;
