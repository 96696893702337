import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Grid} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Layout, Footer } from 'components/user/layout';
import { Banner, Part, Category, Continue } from 'components/user/main';

/* http://localhost:8000/ */
const Main = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(['LoginKey']);

  const LoginKey = cookies.LoginKey;
  const [homeworkDatas, setHomeworkDatas] = useState([{
    DueDate: '',
    StartDate: '',
    ClassNo: '',
    Notice: '',
    Students: [{
      Name: '',
      StudentNo: '',
      Questions: []
    }],
    QuestionCnt: '',
    Seciton: '',
    Manager_id: { Name: '' },
    Class_id: { ClassName: '' }
  }]);
  const [firstContinue, setFirstContinue] = useState({});
  const [secondContinue, setSecondContinue] = useState({});

  const [student,setStudent]=useState();

  const [achieve,setAchieve]=useState([
    {name:"출석왕",achieveNo:1},{name:"복습왕",achieveNo:2},{name:"예습왕",achieveNo:3},{name:"예습왕",achieveNo:4},{name:"예습왕",achieveNo:5},{name:"예습왕",achieveNo:6}
  ]);
  /* 최근 문제풀이 정보를 가져오기 */       
  const getSolveInfo = async () => {
    let cnt = 0;
    let userQuestions = [];
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/info/${LoginKey}`, config).then((Response) => {
        if(Response.data.result.User_id !== undefined){
        for(let i =0; i < Response.data.result.User_id.Questions.length; i++){
          if(Response.data.result.User_id.Questions[i].Status === 1){ //[문제풀기]에서 유저가 풀이한 문제 정보만 저장
            userQuestions.push({
              Question_id: Response.data.result.User_id.Questions[i].Question_id._id,
              Check: Number(Response.data.result.User_id.Questions[i].Check),
              Section: Number(Response.data.result.User_id.Questions[i].Question_id.Section),
              CreatedAt: Response.data.result.User_id.Questions[i].CreatedAt,
            });
            cnt +=1;  //[문제풀기]에서 유저의 문제 풀이가 존재하면 cnt는 증가
          }
        }}
        
        if (cnt !== 0) {  //[문제풀기]에서 유저가 풀이한 문제가 존재하지 않는 경우
          userQuestions.sort(function (a, b) {
            return new Date(b.CreatedAt) - new Date(a.CreatedAt); //문제풀이 정보를 최신순으로 정렬
          });

          let first = { Section: userQuestions[0].Section };  //가장 최근의 문제를 첫번째로 저장

          let second;
          for (let i = 1; i < userQuestions.length; i++) {
            if (userQuestions[i].Section !== first.Section) { //첫번째로 저장한 단원과 다른 단원이 존재하는 경우
              second = { Section: userQuestions[i].Section }; //저장된 첫번째 단원과 다른 단원을 두번째로 저장
              break;
            }
          }

          first.questionsCount = 0; //첫번째 단원에서 유저가 풀이한 문제 수
          first.correct = 0;  //첫번째 단원에서 유저의 정답 수

          if(second){ //두번째 단원이 존재하는 경우
            second.questionsCount = 0;  //두번째 단원에서 유저가 풀이한 문제 수
            second.correct = 0; //두번째 단원에서 유저의 정답 수
          }

          for (let i = 0; i < userQuestions.length; i++) {
            if (first.Section === userQuestions[i].Section) { //유저의 문제풀이 정보 중 첫번째에 저장된 단원과 같은 경우
              first.questionsCount += 1;  
              if (userQuestions[i].Check === 1) {
                first.correct += 1;
              }
            } else if (second && (second.Section === userQuestions[i].Section) ) { //유저의 문제풀이 정보 중 두번째에 저장된 단원과 같은 경우
              second.questionsCount += 1;
              if (userQuestions[i].Check === 1) {
                second.correct += 1;
              }
            }
          }
          setFirstContinue(first);
          if(second){
            setSecondContinue(second);
          }
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
  };

  const loadHomeworks = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/student/homework/${LoginKey}`, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        let loadHomeworks = Response.data.Homeworks;
        loadHomeworks.sort(function (a, b) {
          return new Date(a.DueDate) - new Date(b.DueDate);
        });
        setHomeworkDatas(loadHomeworks);
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };


  useEffect(()=>{
    if(LoginKey){
      getSolveInfo();
      loadHomeworks(); 
    }
  },[]);

  return (
    <>
      <Layout>
        <Banner />
        <Grid container justifyContent='center' className='content_section'>
          <Grid item xs={12} md={10} lg={4}>
            <Part />
          </Grid>
          <Grid item xs={12} md={10} lg={3}>
            <Category 
              homeworkDatas={homeworkDatas}
            />
          </Grid>
          <Grid item xs={12} md={10} lg={3}>
            <Continue 
              firstContinue={firstContinue}
              secondContinue={secondContinue}
            />
          </Grid>
        </Grid>
      </Layout>
      <Footer/>
    </>
  );
};

export default Main;
