import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Grid, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { SearchForm } from 'components/form';
import * as ChangeText from 'components/function/ChangeText';

const QuestionList = () => {
    const pages = [
        { title: '모의고사 관리'},
        { title: '모의고사 목록'}
    ];
    const title = { main: '모의고사 목록', sub: 'mock exam'};
    const [mockExam, setMockExam] = useState([
        {
            id: '',
            Name: '',
            StartDate: ''
        }
    ]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);

    // 컬럼
    const columns = [
        { field: 'id', headerName: 'No.', width: 70, renderCell: (params)=>(<div className='list_click'>{params.row.id}</div>)  },
        { field: 'Name', headerName: '이름', width: 300 },
        { field: 'StartDate', headerName: '시작일', width: 150 },
        { field: 'CreatedAt', headerName: '생성일시', width: 150 }
    ];

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    }

    // 필터
    const filterMockExam = (datas) => {
        datas.sort();
        datas = datas.filter((data) => {
            return data.Name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
        });
        return datas;
    };

    const handleClickCell = (event) => {
        if(event.field === 'id'){
            navigate(`/admin/mockexam/${event.row.Link}`);
        }else{
            return false;
        }
    }

    // 데이터 로드
    useEffect(() => {
        const loadQuestions = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/mockexam/${cookies.LoginKey}`, config).then((Response) => {
                const loadData = Response.data;
                let mockExamData = [];
                for(let i = 0; i < loadData.length; i++){
                    mockExamData.push({ 
                        id: loadData[i].MockExamNo,
                        Name: loadData[i].Name,
                        StartDate: ChangeText.YyyyMmDd(loadData[i].StartDate),
                        CreatedAt: ChangeText.YyyyMmDdHhMmSs(loadData[i].createdAt),
                        Link: loadData[i]._id
                    });
                }
                setMockExam(mockExamData);
                setLoading(false);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadQuestions();
    },[]);
    
    return (
        <Layout pages={pages} title={title}>
            <Grid container justifyContent="space-between">
                <Grid xs={4}>
                    <SearchForm search={search} handleChange={handleChangeSearch} searchGuide='문제번호 / 대단원 / 소단원으로 검색' />
                </Grid>
                <Grid xs={8} textAlign={'right'}></Grid>
                <Grid xs={12} className="list_table">
                    <DataGrid
                        rows={filterMockExam(mockExam)}
                        columns={columns}
                        pageSize={15}
                        onCellClick={handleClickCell}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc'}]
                            }
                        }}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
};

export default QuestionList;