import React from 'react';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import IconPart1 from 'img/icon_part1.png';
import IconPart2 from 'img/icon_part2.png';
import IconPart3 from 'img/icon_part3.png';
import IconPart4 from 'img/icon_part4.png';
import IconPart5 from 'img/icon_part5.png';
import IconPart6 from 'img/icon_part6.png';
import { useSnackbar } from 'notistack';

const Part = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };

    return(
        <>
            <div className='main_div_title'>
                <p className='main_sub_title'>단원별 문제보기</p>
            </div>
            <Grid container className='main_div_part'>
                <Grid xs={6} sm={4}><img src={IconPart1} alt='icon part1' onClick={() => navigate(`${location.pathname}/question`, { state: { part: 0 }})}/></Grid>
                <Grid xs={6} sm={4}><img src={IconPart2} alt='icon part2' onClick={() => navigate(`${location.pathname}/question`, { state: { part: 2 }})}/></Grid>
                <Grid xs={6} sm={4}><img src={IconPart3} alt='icon part3' onClick={() => navigate(`${location.pathname}/question`, { state: { part: 3 }})}/></Grid>
                <Grid xs={6} sm={4}><img src={IconPart4} alt='icon part4' onClick={() => navigate(`${location.pathname}/question`, { state: { part: 4 }})}/></Grid>
                <Grid xs={6} sm={4}><img src={IconPart5} alt='icon part5' onClick={() => navigate(`${location.pathname}/question`, { state: { part: 5 }})}/></Grid>
                <Grid xs={6} sm={4}><img src={IconPart6} alt='icon part6' onClick={() => navigate(`${location.pathname}/question`, { state: { part: 6 }})}/></Grid>
            </Grid>
        </>
    );
};

export default Part;