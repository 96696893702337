import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { QuestionDetailCreate } from 'components/admin/question';

const QuestionCreate = () => { 
    const pages = [
        { title: '문제 관리'},
        { title: '문제 등록'}
    ];
    const title = { main: '문제 등록', sub: 'question'};
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [question, setQuestion] = useState({
        Part: '',
        MiddlePart: '',
        Section: '',
        Difficulty: '',
        WDifficulty: '',
        WDiscrimality: '',
        Answer: '',
        Content: [],
        Commentary: [],
        Choices: [],
        NewChoices: [],
        State: '',
        loginKey: cookies.LoginKey
    });
    
    const [creating, setCreating] = useState(false);
    const [choice, setChoice] = useState('');
    const [newChoice, setNewChoice] = useState('');
    const [content, setContent] = useState('');
    const [commentary, setCommentary] = useState('');
    
    // const [imgBase64, setImgBase64] = useState();
    // const [imgFile, setImgFile] = useState(null);
    
    const handleChange = (event) => {
        let changeQuestion = { ...question };
        changeQuestion[event.target.name] = event.target.value;
        setQuestion(changeQuestion);
    };
    
    const handleChangePart = (event) => {
        setQuestion({ ...question, Part: event.target.value, MiddlePart: '', Section: '' });
    };

    const handleChangeMiddlePart = (event) => {
        setQuestion({ ...question, MiddlePart: event.target.value, Section: '' });
    };
    
    const handleChangeContent = (event) => {
        setContent(event.target.value);
    };
    
    const handleClickAddContent = () => {
        if(content){
            let changeQuestion = { ...question };
            changeQuestion.Content.push(content);
            setContent('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteContent = (num) => {
        let changeQuestion = {...question};
        changeQuestion.Content.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleChangeCommentary = (event) => {
        setCommentary(event.target.value);
    };

    const handleClickAddCommentary = () => {
        if(commentary){
            let changeQuestion = {...question};
            changeQuestion.Commentary.push(commentary);
            setCommentary('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteCommentary = (num) => {
        let changeQuestion = {...question};
        changeQuestion.Commentary.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleChangeChoice = (event) => {
        setChoice(event.target.value);
    };

    const handleClickAddChoice = () => {
        if(question.Choices.length < 5 && choice){
            let changeQuestion = { ...question };
            changeQuestion.Answer = '';
            changeQuestion.Choices.push(choice);
            setChoice('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteChoice = (num) => {
        let changeQuestion = { ...question };
        changeQuestion.Answer = '';
        changeQuestion.Choices.splice(num, 1);
        setQuestion(changeQuestion);
    };
    const handleChangeNewChoice = (event) => {
        setNewChoice(event.target.value);
    };

    const handleClickAddNewChoice = () => {
        if(question.NewChoices.length < 5 && newChoice){
            let changeQuestion = { ...question };
            changeQuestion.Answer = '';
            changeQuestion.NewChoices.push(newChoice);
            changeQuestion.Choices.push(newChoice);
            setNewChoice('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteNewChoice = (num) => {
        let changeQuestion = { ...question };
        changeQuestion.Answer = '';
        changeQuestion.NewChoices.splice(num, 1);
        changeQuestion.Choices.splice(num, 1);
        setQuestion(changeQuestion);
    };

    // const handleChangePicture = (event) => { 이미지 업로드용
    //     let reader = new FileReader();

    //     reader.onloadend = () => {
    //         // 2. 읽기가 완료되면 아래코드가 실행됩니다.
    //         const base64 = reader.result;
    //         if (base64) {
    //             setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
    //         }
    //     }
    //     if (event.target.files[0]) {
    //         reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
    //         setImgFile(event.target.files[0]); // 파일 상태 업데이트
    //     }
    // };

    // const handleClickDeletePicture = () => {
    //     let DeletePicture = { ...question };
    //     DeletePicture.Picture = '';
    //     setQuestion(DeletePicture);
    //     setImgBase64('');
    //     setImgFile('');
    // };
    const handleClickCreate = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(question);
        await axios.post(`/api/admin/question`, body, config).then((Response) => {
            // uploadPicture(Response.data.result._id);
            setTimeout(()=> {
                navigate(`/admin/question/${Response.data.result._id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    // const uploadPicture = async(QID) => {
    //     if(imgFile){
    //         const formData = new FormData();
    //         formData.append('QuestionId', QID);
    //         formData.append('imgFile', imgFile);
    //         formData.append('loginKey', cookies.LoginKey);
    //         const config = {
    //             headers: {
    //                 'content-type': 'multipart/form-data'
    //             }
    //         };
    //         await axios.put(`/api/admin/question/image`, formData, config).then((Response) => {
    //             // console.log(Response);
    //         }).catch((Error) => {
    //             console.log(Error);
    //         });
    //     }else{
    //         return false
    //     }
    // };

    return (
        <Layout pages={pages} title={title}>
            <QuestionDetailCreate
                question={question}
                choice={choice}
                newChoice={newChoice}
                content={content}
                commentary={commentary}
                handleChange={handleChange}
                handleChangePart={handleChangePart}
                handleChangeMiddlePart={handleChangeMiddlePart}
                handleChangeContent={handleChangeContent}
                handleClickAddContent={handleClickAddContent}
                handleDeleteContent={handleDeleteContent}
                handleChangeCommentary={handleChangeCommentary}
                handleClickAddCommentary={handleClickAddCommentary}
                handleDeleteCommentary={handleDeleteCommentary}
                handleChangeChoice={handleChangeChoice}
                handleChangeNewChoice={handleChangeNewChoice}
                handleClickAddChoice={handleClickAddChoice}
                handleClickAddNewChoice={handleClickAddNewChoice}
                handleDeleteChoice={handleDeleteChoice}
                handleDeleteNewChoice={handleDeleteNewChoice}
                handleClickCreate={handleClickCreate}
                creating={creating}
                // imgBase64={imgBase64}
                // handleChangePicture={handleChangePicture}
                // handleClickDeletePicture={handleClickDeletePicture}
            />
        </Layout>
    )
};

export default QuestionCreate;