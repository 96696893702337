import React, { useState } from 'react';
import { Grid, ButtonGroup, Button, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress, Link } from '@mui/material';
import { AccessAlarm, Edit, StarBorder, Star, KeyboardDoubleArrowRight, Stop, ArrowRight, ArrowLeft } from '@mui/icons-material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useNavigate } from 'react-router-dom';
import { RadioButtonChecked, RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';
import { useSnackbar } from 'notistack';
import QuestionCheck1 from 'img/question_check1.png';
import QuestionCheck2 from 'img/question_check2.png';
import { QuestionMemoReview } from './';

const QuestionHomeworkResult = (props) => {
    const question = props.question;
    
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [openMemoReview, setOpenMemoReview] = useState(false);
    const [memoReview, setMemoReview] = useState('');

    const handleClickMemoReview = () => {
        if(question.Memo){
            setMemoReview(question.Memo);
            setOpenMemoReview(true);
        }else{
            return false;
        }
    };
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    return(
        <div className='content_section'>
            <Grid container justifyContent='center' className='question_detail'>
                <Grid item xs={12} md={10} className='question_title'>
                    <div>{ChangeText.Part(question.Section)}&nbsp;&gt;&nbsp;{ChangeText.MiddlePart(question.Section)}&nbsp;&gt;&nbsp;{ChangeText.Section(question.Section)}</div>
                    <div>
                        <div><AccessAlarm /></div>
                        <div>{ChangeText.QuestionOnlyMinute(Math.floor(question.Time))}</div>
                    </div>
                </Grid>
                <Grid container item xs={12} md={10} className='question_error_comment'>
                    <div>· 문제 또는 정답에 오류가 있을 경우 페이지 하단의 <b>채널톡</b> 또는 <b>이메일</b>(<Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link>)으로 문의주시면 감사하겠습니다.</div>
                </Grid>
                <Grid container item xs={12} md={10} style={{ position: 'relative'}}>
                    <Grid item xs={12} className='question_content_info' style={{ zIndex: '2'}}>
                        <div>
                            <ButtonGroup variant='outlined' color='warning' aria-label='outlined button group'>
                                <Button disabled={question.Memo ? false : true}><Edit onClick={handleClickMemoReview}/></Button>
                                <Button onClick={props.saveQuestion}>{question.IsSaved ? <Star /> : <StarBorder />}</Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                    <Grid item xs={12} className='question_content'>
                        <div className='question_front_cover' style={{backgroundColor: props.loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>{ props.loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
                        <p>
                            문제 {question.QuestionNo ? question.QuestionNo - question.Section*10000 : ''}
                        </p>
                        <MathJaxContext version={3} config={config}>
                            {question && typeof(question.Answer) !== 'string' &&
                             question.Content.map((content) => (
                                <MathJax 
                                renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                                text={
                                content.replace('`\\(','').replace('\\)`','')
                                }
                                dynamic
                                className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                                />
                            ))}
                        </MathJaxContext>
                        {/* <MathJaxContext >
                            {question?.Content.map((content) => (
                            <MathJax hideUntilTypeset={'first'} dynamic>
                                {content.replaceAll('`','')}
                            </MathJax>
                            ))}
                        </MathJaxContext> */}
                        {/* <div style={{textAlign: 'center'}}>
                            {question?.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                        </div> */}
                    </Grid>
                    <Grid item xs={12} style={{ position: 'absolute', zIndex: '1'}}>
                    {new Date() > new Date(question.DueDate).setHours(23,59,59,999) && !props.loading? 
                        question.Check === 1 ?
                            <img src={QuestionCheck1} alt='question check 1' className='homework_question_check'/>:
                            <img src={QuestionCheck2} alt='question check 2' className='homework_question_check'/>: 
                        null
                    }   
                    </Grid>
                    <Grid item xs={12} container className='question_checks' justifyContent='center'>
                        {props.loading ? null :
                            question.NewChoices.map((choice, i) => (
                            <Grid item style={{ padding: '0px' }}>
                                <Grid container justifyContent='center' alignItems='center' >
                                    <Grid item style={{ padding: '0px' }}>
                                        {new Date() > new Date(question.DueDate).setHours(23,59,59,999) ?
                                            question.Check === 1 ?
                                                question.Answer === i+1 ?
                                                    <RadioButtonChecked className='homework_question_answer'/>:
                                                    <RadioButtonUnchecked />:
                                                question.Answer === i+1 ?
                                                    <RadioButtonChecked className='homework_question_answer'/>:
                                                    question.Select === i+1 ?
                                                        <TaskAlt/>:
                                                        <RadioButtonUnchecked />:
                                            question.Select === i+1 ?
                                                <RadioButtonChecked />:
                                                <RadioButtonUnchecked />
                                        }
                                    </Grid>
                                    <Grid item>
                                    <MathJaxContext version={3} config={config}>
                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        choice.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic/>
                                    </MathJaxContext>
                                    </Grid>
                                </Grid>
                            </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={10} className='question_button_group'>
                    <Grid item xs={8}>
                        <Button onClick={props.BeforeQuestion} endIcon={<ArrowLeft/>} color='warning' variant='outlined'>이전문제</Button>
                        <Button onClick={() => navigate(-1)} endIcon={<Stop/>} color='warning' variant='outlined'>그만보기</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button disabled={props.loading} onClick={props.NextQuestion} endIcon={<ArrowRight/>} color='warning' variant='contained' disableElevation>다음문제</Button>
                    </Grid>
                </Grid>
            </Grid>
            <QuestionMemoReview 
                open={openMemoReview}
                setOpen={setOpenMemoReview}
                memoReview={memoReview}
            />
        </div>
    );
};

export default QuestionHomeworkResult;