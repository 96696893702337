import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { EventList } from 'domain/admin/event';

import NotFound from 'routes/NotFound';

const AdminEventPresenter = () => {
    return(
        <Routes>
            <Route path='' element={<EventList />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminEventPresenter;