import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionDiagnosisResult } from 'components/user/question';

const DiagnosisResult = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const [loading, setLoading] = useState(true);
  const [diagnosis, setDiagnosis] = useState({});
  const { diagnosisNo } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const loginKey = cookies.LoginKey;

  const getDiagnosisResult = async ()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/diagnosis/${diagnosisNo}/${loginKey}`, config).then((Response) => {
      if(!Response.data.Message){
        let data = Response.data.result;
        let checkTrue = 0;
        let allTime = 0;

        for(let i = 0; i < data.Questions.length; i++){
          if(data.Questions[i].UserCheck === 1){
            checkTrue++;
          }
          allTime = allTime + data.Questions[i].UserTime;
        }
        data.checkTrue = checkTrue;
        data.allTime = allTime;
        setDiagnosis(data);
      }else{
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'error'});
        navigate(`${Response.data.url}`, { replace: true });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }

  useEffect(() => {
    if(cookies.LoginKey){
      getDiagnosisResult();
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    }else{
      enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error'});
      navigate(`/login`);
    }
  }, []);
  

  return (
    <Layout>
      <QuestionDiagnosisResult diagnosis={diagnosis} loading={loading}/>
    </Layout>
  )
}

export default DiagnosisResult
