import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/admin/layout';
import { MockExamDetailCreate } from 'components/admin/mockExam';

const MockExamCreate = () => { 
    const pages = [
        { title: '모의고사 관리'},
        { title: '모의고사 등록'}
    ];
    const title = { main: '모의고사 등록', sub: 'mock exam'};
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    
    const [cookies] = useCookies(['LoginKey']);
    const [mockExam, setMockExam] = useState({
        Name: '',
        StartDate: `${new Date().getFullYear()}-${new Date().getMonth()+1 < 10 ? `0${new Date().getMonth()+1}` : new Date().getMonth()+1}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`,
    });
    const [creating, setCreating] = useState(false);    

    const handleChangeName = (event) => {
        setMockExam({...mockExam, Name: event.target.value});
    };

    const handleChangeStartDate = (event) => {
        const today = new Date().setHours('0', '0', '0', '0');
        if(today < new Date(event.target.value)){
            setMockExam({...mockExam, StartDate: event.target.value});
        }else{
            enqueueSnackbar(`오늘 이전 날짜로 설정할 수 없습니다.`, { variant: 'error'});
        }
    };
    
    const handleClickCreate = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            name: mockExam.Name,
            startDate: mockExam.StartDate,
            loginKey: cookies.LoginKey
        });
        await axios.post(`/api/admin/mockexam`, body, config).then((Response) => {
            setTimeout(()=> {
                navigate(`/admin/mockExam/${Response.data.result._id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    return (
        <Layout pages={pages} title={title}>
            <MockExamDetailCreate
                mockExam={mockExam}
                handleChangeName={handleChangeName}
                handleChangeStartDate={handleChangeStartDate}
                handleClickCreate={handleClickCreate}
                creating={creating}
            />
        </Layout>
    )
};

export default MockExamCreate;