import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import NormalDistribution from 'normal-distribution';

import IconPart1 from 'img/icon_part1.png';
import IconPart2 from 'img/icon_part2.png';
import IconPart3 from 'img/icon_part3.png';
import IconPart4 from 'img/icon_part4.png';
import IconPart5 from 'img/icon_part5.png';
import IconPart6 from 'img/icon_part6.png';
import OfUNI from 'img/ofuni.png';
import * as AddValue from 'components/function/AddValue';
import * as ChangeText from 'components/function/ChangeText';

const TeacherQuestionSection = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [part, setPart] = useState(null);
  const [selectSection, setSelectSection] = useState(null);
  const [mouseScroll, setMouseScroll] = useState(false);
  const [mouseX, setMouseX] = useState();
  const [scrollLeft, setScrollLeft] = useState();

  const parts = AddValue.AllParts();

  const handleClickPart = (part) => {
    props.setSection(null);
    setPart(part);
  }
  const handleMouseDown = (event) => {
    setMouseX(event.pageX);
    setScrollLeft(document.getElementsByClassName('part_select_mobile')[0].scrollLeft);
    setMouseScroll(true);
  }
  const handleMouseMove = (event) => {
      if(mouseScroll){
          const walk = (event.pageX - mouseX)/2;
          document.getElementsByClassName('part_select_mobile')[0].scrollLeft = scrollLeft - walk;
      }
  }
  const handleMouseUpOrLeave = () => {
      setMouseScroll(false);
      setMouseX();
  }

  useEffect(() => {
    if(state){
      setTimeout(() => {
        setPart(state.part);
      }, [200]);
    }
  },[]);

  return (
    <div className='content_section'>
      <Grid container justifyContent='center'>
        <Grid item xs={12} className='section_select_title'>
          <h2>학생들이 <span>풀어볼 문제</span>를 미리보세요!</h2>
        </Grid>
        <Grid
          item
          sx={{ display: {xs: 'none', md: 'block'}, zIndex: 3}}
          md={12} lg={10} xl={8}
          className='part_select'
        >
          <table>
            <tr>
              <td className={part === 0 || part === 1 ? 'part_selected' : ''}><img src={IconPart1} alt='part icon' onClick={() => handleClickPart(0)}/></td>
              <td className={part === 2 ? 'part_selected' : ''}><img src={IconPart2} alt='part icon' onClick={() => handleClickPart(2)}/></td>
              <td className={part === 3 ? 'part_selected' : ''}><img src={IconPart3} alt='part icon' onClick={() => handleClickPart(3)}/></td>
              <td className={part === 4 ? 'part_selected' : ''}><img src={IconPart4} alt='part icon' onClick={() => handleClickPart(4)}/></td>
              <td className={part === 5 ? 'part_selected' : ''}><img src={IconPart5} alt='part icon' onClick={() => handleClickPart(5)}/></td>
              <td className={part === 6 ? 'part_selected' : ''}><img src={IconPart6} alt='part icon' onClick={() => handleClickPart(6)}/></td>
            </tr>
            <tr>
              <td className={part === 0 || part === 1 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 2 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 3 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 4 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 5 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 6 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
            </tr>
          </table>
        </Grid>
        <Grid
          item
          sx={{ display: {xs: 'block', md: 'none'}, zIndex: 3}}
          className='part_select_mobile'
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
        >
          <table>
            <tr>
              <td className={part === 0 || part === 1 ? 'part_selected' : ''}><img src={IconPart1} alt='part icon' onClick={() => handleClickPart(0)}/></td>
              <td className={part === 2 ? 'part_selected' : ''}><img src={IconPart2} alt='part icon' onClick={() => handleClickPart(2)}/></td>
              <td className={part === 3 ? 'part_selected' : ''}><img src={IconPart3} alt='part icon' onClick={() => handleClickPart(3)}/></td>
              <td className={part === 4 ? 'part_selected' : ''}><img src={IconPart4} alt='part icon' onClick={() => handleClickPart(4)}/></td>
              <td className={part === 5 ? 'part_selected' : ''}><img src={IconPart5} alt='part icon' onClick={() => handleClickPart(5)}/></td>
              <td className={part === 6 ? 'part_selected' : ''}><img src={IconPart6} alt='part icon' onClick={() => handleClickPart(6)}/></td>
            </tr>
            <tr>
              <td className={part === 0 || part === 1 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 2 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 3 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 4 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 5 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 6 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
            </tr>
          </table>
        </Grid>
        {part !== null ?
        <Grid item xs={12} lg={10} xl={8} className='pm-0'>
          <Grid container className='section_select_div'>
            {part < 2 ?
            <Grid item xs={12} className='subpart_button'>
              <div className={part === 0 ? 'active_button': null} onClick={() => handleClickPart(0)}>수학 상</div>
              <div className={part === 1 ? 'active_button': null} onClick={() => handleClickPart(1)}>수학 하</div>
            </Grid>
            :null
            }
            <Grid container item xs={12} justifyContent='flex-end'>
              <Grid item>문제수</Grid>
              <Grid item></Grid>
            </Grid>
            {parts[part]?.subSections?.map((subSection) => (
            <>
              <Grid item xs={12} md={3} lg={3} className='section_group'>
                <div className={`part_background_color_${part}`}>{subSection.name}</div>
              </Grid>
              <Grid item xs={12} md={9} lg={9} className={`section_list part_left_border_color_${part}`}>
                {subSection.sections.map((section) => (
                <Grid container justifyContent='space-between' onClick={() => props.setSection(section.section)}>
                  <Grid item className={section.section === props.section ? 'section_selected' : null}>{section.name}</Grid>
                  <Grid item className='section_list_option'>
                    <div className={section.section === selectSection ? 'section_selected' : null}>{ChangeText.SectionQuestions(section.section)}</div>
                    <div>
                      <CheckCircle className={props.section === section.section ? `section_selected_color_${part}` : null} /> 
                    </div>
                  </Grid>
                </Grid>
                ))}
              </Grid>
            </>
            ))}
          </Grid>
        </Grid>:
        null
        }
      </Grid>
      <button disabled={props.section ? false : true} className='solve_question' onClick={() => navigate(`${location.pathname}/${props.section}`)}>문제보기 ▸</button>
    </div>
  );
}

export default TeacherQuestionSection;