import { Avatar ,Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow } from "@mui/material";

import WooroTestLogoOnly from 'img/woorotest_logo_only.png';

const ClassListView = (props) => {
  return (
    <Grid item={true}  lg={11.8} xl={12}>
    {props.rows.length > 0 ?
       <TableContainer>
       <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
         <TableHead className="academy_table_head">
           <TableRow>
             <TableCell width={'80px'}>No.</TableCell>
             <TableCell align="left" width={'50px'}>프로필</TableCell>
             <TableCell align="center">이름</TableCell>
             <TableCell align="center">인원</TableCell>
             <TableCell align="center"width={'150px'}>담당자</TableCell>
           </TableRow>
         </TableHead>
         <TableBody className="academy_table">
           {props.rows.map((row, i) => (
             <TableRow key={i} sx={{ 'th': {  },'td':{},marginBottom: '10px'  }}>
               <TableCell component="th" scope="row">
                 {i + 1}
               </TableCell>
               <TableCell align="center">
                 <Avatar src={row.Profile ? row.Profile : WooroTestLogoOnly} onClick={() => {props.handleClickCell(row.classNo) }} sx={{cursor:"pointer"}}/>
               </TableCell>
               <TableCell align="center" onClick={() => { props.handleClickCell(row.classNo) }} sx={{cursor:"pointer",color:"#1976d2"}}>{row.name}</TableCell>
               <TableCell align="center">{row.student}</TableCell>
               <TableCell align="center">{row.manager}</TableCell>
             </TableRow> 
           ))}
         </TableBody>
       </Table>
      </TableContainer> 
      :
      <h1 style={{textAlign:"center",marginTop:"50px"}}> 생성한 클래스가 없습니다.</h1>
    }
  
   </Grid>
  )
};

export default ClassListView;
