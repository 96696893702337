import React from 'react';

import { ResponsiveTimeRange } from '@nivo/calendar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth();
let minMonth, maxMonth;
if(month < 6){
    minMonth = 1;
    maxMonth = 6;
}else{
    minMonth = 7;
    maxMonth = 12;
}
let maxDay;
if(maxMonth === 6){
    maxDay = 30;
}else{
    maxDay = 31;
}
const MyResponsiveTimeRange = ({ data /* see data tab */ }) => (
    <ResponsiveTimeRange
        data={data}
        from={`${year}-${minMonth}-01`}
        to={`${year}-${maxMonth}-${maxDay}`}
        emptyColor="#eeeeee"
        colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
        margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
        dayBorderWidth={1}
        dayBorderColor="#ffffff"
        weekdayLegendOffset={window.innerWidth > 500 ? 45 : 0}
        weekdayTicks={window.innerWidth > 500 ? [1, 3, 5] : []}
        square={false}
        dayRadius={4}
        // legends={[
        //     {
        //         anchor: 'bottom',
        //         direction: 'row',
        //         justify: false,
        //         itemCount: 4,
        //         itemWidth: 42,
        //         itemHeight: 36,
        //         itemsSpacing: 14,
        //         itemDirection: 'right-to-left',
        //         // translateX: -60,
        //         // translateY: -60,
        //         symbolSize: 20
        //     }
        // ]}
    />
)

export default MyResponsiveTimeRange;