import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { SchoolDetailInfo, SchoolStudentInfo } from 'components/admin/school';
import { DeleteModal } from 'components/modal';

const QuestionInfo = () => {
    const pages = [
        { title: '학교 관리' },
        { title: '학교 목록' },
        { title: '학교 상세 정보' }
    ];
    const title = { main: '학교 상세 정보', sub: 'question' };
    const [school, setSchool] = useState({});
    const [students, setStudents] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [openDeleteSchool, setOpenDeleteSchool] = useState(false);

    const handleClickOpenDeleteSchool = () => {
        setOpenDeleteSchool(true);
    }
    const handleClickCloseDeleteSchool = () => {
        setOpenDeleteSchool(false);
    }
    const { id } = useParams();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);

    // 데이터 로드
    useEffect(() => {
        const loadStudents = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/school/${id}/student/${cookies.LoginKey}`, config).then((Response) => {
                setStudents(Response.data);
            })
        };
        const loadSchool = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/school/${id}/${cookies.LoginKey}`, config).then((Response) => {
                if(Response.data._id){
                    loadStudents();
                }
                setSchool(Response.data);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadSchool();
    }, [id]);

    // 학교 상태 변경
    const handleChangeState = async () => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            SchoolId: school._id,
            State: school.State === 1 ? 2 : 1,
            loginKey: cookies.LoginKey
        });
        await axios.patch(`/api/admin/school`, body, config).then((Response) => {
            setTimeout(() => {
                setSchool({ ...school, State: school.State === 1 ? 2 : 1 });
                setUpdating(false);
            }, 1000);
        }).catch((Error) => {
            console.log(Error);
        });
    }

    // 학교 삭제
    const handleClickDelete = async() => {
        setDeleting(true);
        const res = await axios({
            method: 'DELETE',
            url: '/api/admin/school',
            data: {
                SchoolId: school._id,
                loginKey: cookies.LoginKey    
            }
        });
        await res.json().then((Response) => {
            setTimeout(() => {
                navigate(`/admin/school`);
            }, 1000);
        }).catch((Error) => {
            console.log(Error);
        });
    }
    return (
        <>
            <Layout pages={pages} title={title}>
                <SchoolDetailInfo
                    school={school}
                    handleChangeState={handleChangeState}
                    updating={updating}
                    handleClickOpenDelete={handleClickOpenDeleteSchool}
                />
                <SchoolStudentInfo
                    students={students}
                />
            </Layout>
            <DeleteModal
                openDelete={openDeleteSchool}
                handleClickCloseDelete={handleClickCloseDeleteSchool}
                deleting={deleting}
                handleClickDelete={handleClickDelete}
                title='학교 정보 삭제'
            >
                학교 정보가 삭제됩니다.<br />삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
            </DeleteModal>
        </>
    )
};

export default QuestionInfo;