import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/parent/layout';
import { ParentDashboardDetail } from 'components/parent/child/dashboard';

const DashboardDetail = () => {
    const [cookies] = useCookies(['LoginKey']);
    const LoginKey = cookies.LoginKey;
    const navigate = useNavigate();
    const { ChildNo, Section } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [childData, setChildData] = useState({
        Name: '',
        Grade: '',
        Attending: '',
        School_id: {
            Name: '',
            WooroZscore: ''
        }
    });
    const [diagnosisDatas, setDiagnosisDatas] = useState([{
        ZScore: '',
        Questions: [],
        createdAt: ''
    }]);
    
    useEffect(() => {
        const loadChildData = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/parent/dashboard/${ChildNo}/${Section}/${LoginKey}`, config).then((Response) => {              
                if(!Response.data.ErrorMessage){
                    let loadDiagnosisList = Response.data.Diagnosis;
                    loadDiagnosisList.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    setChildData(Response.data.Child.Child_id);
                    setDiagnosisDatas(loadDiagnosisList);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    navigate(-1);    
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });   
        }
        loadChildData();
    }, []);

    return(
        <Layout>
            <ParentDashboardDetail childData={childData} diagnosisDatas={diagnosisDatas}/>
        </Layout>
    )
}

export default DashboardDetail;