import { Grid, Button, Link  } from '@mui/material';
import { useParams,useNavigate } from 'react-router-dom';

import CustomHomework from 'img/homework_custom_image.png'
import Homework from 'img/homework_auto_image.png'

const TeacherHomeworkSelectView = () => {
  const { ClassNo} = useParams();
  const navigate = useNavigate();

  const clickNav  =(select) =>{
    navigate(select)
  };
  return ( 
    <Grid container justifyContent={"center"} sx={{marginTop:"200px",'@media (max-width: 900px)': {marginTop:"50px"},marginBottom:"10px"}}>
        <Grid item xs={11}md={4} lg={3} xl={2} sx={{boxShadow:"0px 7px 29px 0px rgba(100, 100, 111, 0.20)",textAlign:"center",margin:"0 20px",padding:"30px 0",borderRadius:"8px",cursor:"pointer"}} onClick={()=>clickNav('create')}>
            <p style={{fontSize:"24px",fontWeight:"500",marginTop:"50px"}}> 손쉽게 숙제를 전달해 보세요</p>
            <div style={{display:'flex' ,justifyContent:"center",alignItems:'center',padding:"30px 0",marginTop:"60px"}}>
              <Link href={`/teacher/${ClassNo}/homework/select/create`} sx={{color:'#F05D38',fontWeight:"700",fontSize:"36px",  textDecoration:"none",marginRight:"20px"}}>자동 출제</Link>
              <img src={Homework} alt="" width={"105px"} />
            </div>
        </Grid>
        <Grid item xs={11}md={4} lg={3} xl={2} sx={{boxShadow:"0px 7px 29px 0px rgba(100, 100, 111, 0.20)" ,textAlign:"center",margin:"0 20px",padding:"30px 0",borderRadius:"8px",cursor:"pointer"}} onClick={()=>clickNav('custom')}>
          <p style={{fontSize:"24px",fontWeight:"500",marginTop:"50px"}}> 숙제를 선택하여 전달해 보세요</p>
          <div style={{display:'flex' ,justifyContent:"center",alignItems:'center',padding:"30px 0",marginTop:"60px"}}>
            <Link href={`/teacher/${ClassNo}/homework/select/custom`} sx={{color:'#F05D38',fontWeight:"700",fontSize:"36px",  textDecoration:"none" ,marginRight:"20px"}}>커스텀 출제</Link>
            <img src={CustomHomework} alt="" width={"105px"} />
          </div>
        </Grid>
    </Grid>
  );
}
 
export default TeacherHomeworkSelectView;