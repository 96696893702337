import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';


import IconPart1 from 'img/icon_part1.png';
import IconPart2 from 'img/icon_part2.png';
import IconPart3 from 'img/icon_part3.png';
import IconPart4 from 'img/icon_part4.png';
import IconPart5 from 'img/icon_part5.png';
import IconPart6 from 'img/icon_part6.png';
import OfUNI from 'img/ofuni.png';
import * as AddValue from 'components/function/AddValue';
import * as ChangeText from 'components/function/ChangeText';

const QuestionReview = (props) => {
  const navigate = useNavigate();
  const [part, setPart] = useState(null);
  const [selectSection, setSelectSection] = useState(null);
  const [selectKind, setSelectKind] = useState(null);
  const [mouseScroll, setMouseScroll] = useState(false);
  const [mouseX, setMouseX] = useState();
  const [scrollLeft, setScrollLeft] = useState();
  const parts = AddValue.AllParts();
  
  const handleClickPart = (part) => {
    setSelectSection(null);
    setSelectKind(null);
    setPart(part);
  }
  const handleMouseDown = (event) => {
    setMouseX(event.pageX);
    setScrollLeft(document.getElementsByClassName('part_select_mobile')[0].scrollLeft);
    setMouseScroll(true);
  }
  const handleMouseMove = (event) => {
      if(mouseScroll){
          const walk = (event.pageX - mouseX)/2;
          document.getElementsByClassName('part_select_mobile')[0].scrollLeft = scrollLeft - walk;
      }
  }
  const handleMouseUpOrLeave = () => {
      setMouseScroll(false);
      setMouseX();
  }
  const handleChangeSection = (section, kind) => {
    const thisPart = Math.floor(section/100);
    const thisMiddleSection = Math.floor(section/10)
    if(kind === 1 && props.reviewAll[thisPart][thisMiddleSection][section]?.length > 0){
      setSelectSection(section);
      setSelectKind(kind);
    }else if(kind === 2 && props.reviewWrong[thisPart][thisMiddleSection][section]?.length > 0){
      setSelectSection(section);
      setSelectKind(kind);
    }else if(kind === 3 && props.reviewSave[thisPart][thisMiddleSection][section]?.length > 0){
      setSelectSection(section);
      setSelectKind(kind);
    }else{
      return false
    }
  };

  return (
    <div className='content_section'>
      <Grid container justifyContent='center'>
        <Grid item xs={12} className='section_select_title'>
          <h2>풀었던 문제들을 <span>복습할 수 있어요!</span></h2>
        </Grid>
        <Grid
          item
          sx={{ display: {xs: 'none', md: 'block'}, zIndex: 3}}
          md={12} lg={10} xl={8}
          className='part_select'
        >
          <table>
            <tr>
              <td className={part === 0 || part === 1 ? 'part_selected' : ''}><img src={IconPart1} className={props.partDisabled[0] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(0)}/></td>
              <td className={part === 2 ? 'part_selected' : ''}><img src={IconPart2} className={props.partDisabled[1] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(2)}/></td>
              <td className={part === 3 ? 'part_selected' : ''}><img src={IconPart3} className={props.partDisabled[2] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(3)}/></td>
              <td className={part === 4 ? 'part_selected' : ''}><img src={IconPart4} className={props.partDisabled[3] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(4)}/></td>
              <td className={part === 5 ? 'part_selected' : ''}><img src={IconPart5} className={props.partDisabled[4] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(5)}/></td>
              <td className={part === 6 ? 'part_selected' : ''}><img src={IconPart6} className={props.partDisabled[5] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(6)}/></td>
            </tr>
            <tr>
              <td className={part === 0 || part === 1 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 2 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 3 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 4 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 5 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 6 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
            </tr>
          </table>
        </Grid>
        <Grid
          item
          sx={{ display: {xs: 'block', md: 'none'}, zIndex: 3}}
          className='part_select_mobile'
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
        >
          <table>
            <tr>
              <td className={part === 0 || part === 1 ? 'part_selected' : ''}><img src={IconPart1} className={props.partDisabled[0] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(0)}/></td>
              <td className={part === 2 ? 'part_selected' : ''}><img src={IconPart2} className={props.partDisabled[1] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(2)}/></td>
              <td className={part === 3 ? 'part_selected' : ''}><img src={IconPart3} className={props.partDisabled[2] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(3)}/></td>
              <td className={part === 4 ? 'part_selected' : ''}><img src={IconPart4} className={props.partDisabled[3] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(4)}/></td>
              <td className={part === 5 ? 'part_selected' : ''}><img src={IconPart5} className={props.partDisabled[4] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(5)}/></td>
              <td className={part === 6 ? 'part_selected' : ''}><img src={IconPart6} className={props.partDisabled[5] === 0 ? 'part_progress_zero': null} alt='part icon' onClick={() => handleClickPart(6)}/></td>
            </tr>
            <tr>
              <td className={part === 0 || part === 1 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 2 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 3 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 4 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 5 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
              <td className={part === 6 ? '' : 'gori_unselected'}><img className='gori_left' src={OfUNI} alt=''/><img className='gori_right' src={OfUNI} alt=''/></td>
            </tr>
          </table>
        </Grid>
        {part !== null ?
        <Grid item xs={12} lg={10} xl={8} className='pm-0'>
          <Grid container className='section_select_div'>
            {part < 2 ?
            <Grid item xs={12} className='subpart_button'>
              <div className={part === 0 ? 'active_button': null} onClick={() => handleClickPart(0)}>수학 상</div>
              <div className={part === 1 ? 'active_button': null} onClick={() => handleClickPart(1)}>수학 하</div>
            </Grid>
            :null
            }
            <Grid container item xs={12} justifyContent='flex-end' className='review_kind_title'>
              <Grid item>전체</Grid>
              <Grid item>오답</Grid>
              <Grid item>저장</Grid>
            </Grid>
            {parts[part]?.subSections?.map((subSection) => (
            <>
              <Grid item xs={12} md={3} lg={3} className='section_group'>
                <div className={`part_background_color_${part}`}>{subSection.name}</div>
              </Grid>
              <Grid item xs={12} md={9} lg={9} className={`section_list_review part_left_border_color_${part}`}>
                {subSection.sections.map((section) => (
                <Grid container justifyContent='space-between'>
                  <Grid item xs={12} sm={5} className={section.section === selectSection ? 'section_selected' : null}>{section.name}</Grid>
                  <Grid item xs={12} sm={7} className='section_list_option_review'>
                    <div 
                      className={
                        section.section === selectSection && selectKind === 1? 
                        `section_selected_color_${part}` : 
                        null
                      }
                      onClick={() => handleChangeSection(section.section, 1)}
                    >
                      {props.reviewAll[Math.floor(section.section/100)][Math.floor(section.section/10)][section.section]?.length > 0 ? props.reviewAll[Math.floor(section.section/100)][Math.floor(section.section/10)][section.section]?.length : '-'}
                    </div>
                    <div 
                      className={
                        section.section === selectSection && selectKind === 2 ? 
                        `section_selected_color_${part}` : 
                        null
                      }
                      onClick={() => handleChangeSection(section.section, 2)}
                    >
                      {props.reviewWrong[Math.floor(section.section/100)][Math.floor(section.section/10)][section.section]?.length > 0 ? props.reviewWrong[Math.floor(section.section/100)][Math.floor(section.section/10)][section.section]?.length : '-'}
                    </div>
                    <div 
                      className={
                        section.section === selectSection && selectKind === 3 ? 
                        `section_selected_color_${part}` : 
                        null
                      }
                      onClick={() => handleChangeSection(section.section, 3)}
                    >
                      {props.reviewSave[Math.floor(section.section/100)][Math.floor(section.section/10)][section.section]?.length > 0 ? props.reviewSave[Math.floor(section.section/100)][Math.floor(section.section/10)][section.section]?.length : '-'}
                    </div>
                  </Grid>
                </Grid>
                ))}
              </Grid>
            </>
            ))}
          </Grid>
        </Grid>:
        null
        }
      </Grid>
      <button disabled={selectSection ? false : true} className='solve_question' onClick={() => navigate(`/question/${ChangeText.ReviewType(selectKind)}/${selectSection}`)}>복습하기 ▸</button>
    </div>
  );
}

export default QuestionReview;