import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Hidden,Box } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

import WooroMathLogo from 'img/wooromath_logo.png';
import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';

const HomeworkPrint = () => {
     window.onafterprint = function(){
         window.close();
     };
    const [cookies] = useCookies(['LoginKey', 'Name', 'ClassName']);
    const { HomeworkNo } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [homeworkData, setHomeworkData] = useState({
        Question_ids: [],
        StudentNo: '',
        Students: [{
          Student_id: { Name: '' },
          MemoPictures: [],
          Questions: [{
            Check: '',
            Memo: '',
            Memo: '',
          }],
        }]
    });
    const config = {
      "fast-preview": { disabled: true },
      loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
      tex: {
          packages: { "[+]": ["html", "img"] },
          inlineMath: [
              ["$", "$"],
              ["\\(", "\\)"]
        ],
        displayMath: [
              ["$$", "$$"],
              ["\\[", "\\]"]
        ],
      },
      options: {
          enableMenu: false,
      }
  };
    const answer = ['①', '②', '③', '④', '⑤'];
    useEffect(() => {
        const loadHomework = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/teacher/homework/detail/${HomeworkNo}/${cookies.LoginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                let HomeworkData = Response.data.Homework
                  for (let i = 0; i < HomeworkData.Question_ids.length; i++) {
                    if (HomeworkData.Question_ids[i].Question === HomeworkData.Standard[i].Link) {
                      HomeworkData.Question_ids[i] = {...HomeworkData.Question_ids[i] , Standard:HomeworkData.Standard[i].Content}
                    }
                  }
                    setHomeworkData(HomeworkData);
                     setTimeout(() => {
                         setLoading(false);
                         window.print();
                    }, [3500]);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                     setTimeout(() => {
                         window.close();
                     },[1500]);
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadHomework();
    }, []);

    const ScoreAll = (student) =>{
        if (student.Questions.length > 0) {
          let score = 0
          for (let i = 0; i < student.Questions.length; i++) {
            score +=  student.Questions[i].Score
          }
          return score
        }else{
          return 0;
        }
    }

    // useEffect(() => {
    //     let questionDiv = document.getElementsByClassName('homework_print_question');
    //     let homeworkDiv = document.getElementById('homework_contents');
    //     console.log(homeworkDiv);
    //     for(let i = 0; i < questionDiv.length; i++){
    //         if(questionDiv[i].clientHeight !== 565){
    //             questionDiv[i].className += ' homework_overflow_front';
    //         }
    //     }
    // }, [document.getElementsByClassName('homework_print_question')]);
    return (
        <div className='question_custom_print'>
          { 
          homeworkData.Students.map((student,i)=>(
          <div className='question_custom_print_container'>
          <h1 style={{textAlign:"center"}}>{homeworkData?.Title}</h1>
            <table style={{ borderSpacing: 0 ,width:"100%",padding:"0 16px" , margin:"20px 0"}}>
              <tr>
                <td style={{ fontSize:"15px",fontWeight:"700",width:"80px",textAlign:"center",backgroundColor:"rgba(224, 224, 224, 1)" }}>소속</td>
                <td style={{ fontSize:"15px",fontWeight:"700", width:"180px"}}>{`${student?.Student_id?.Grade }학년 ${student?.Student_id?.SchoolClass}반 ${student?.Student_id?.SchoolNumber}번 ( ${student?.Student_id.Name} )`}</td>
                <td style={{ fontSize:"15px",fontWeight:"700",width:"50px",textAlign:"center",backgroundColor:"rgba(224, 224, 224, 1)" }}>점수</td>
                <td style={{ fontSize:"15px",fontWeight:"700", width:"60px" }}>{ScoreAll(student)}</td>
                <td style={{ fontSize:"15px",fontWeight:"700",width:"90px",textAlign:"center",backgroundColor:"rgba(224, 224, 224, 1)" }}>감독자 확인 서명</td>
                <td style={{ fontSize:"15px",fontWeight:"700" }}></td>
              </tr>
              <tr>
                <td style={{ fontSize:"15px",fontWeight:"700",textAlign:"center",backgroundColor:"rgba(224, 224, 224, 1)" }}>활동</td>
                <td colSpan="5" style={{ fontSize:"15px",fontWeight:"700",textAlign:"center" }}>{homeworkData?.ActivityName}</td>
              </tr>
            </table>
                <Grid container xs={12}>
                  {homeworkData.Question_ids.map((question, i) => {
                    const matchingQuestion = student.Questions.find((q) => q.Question_id === question._id)
                    return (
                    <Grid item xs={12}>
                       <div >
                        <div style={{display:'flex' ,justifyContent:"space-between"}}>
                          <p><b>문제 {i+1}</b>&nbsp;<span>(Q.{question.Question - question.Section*10000})</span></p>
                          <p style={{marginRight:"5px"}}> <b>{matchingQuestion?.Score ? matchingQuestion?.Score  : 0 }점</b></p>        
                        </div>
                          <p style={{marginTop:"0px"}}><b>성취기준</b> : {question.Standard}</p>
                            <div style={{ paddingBottom:"8px"}}>
                            <MathJaxContext version={3} config={config}>
                              {question.Content.map((content) => (
                                content.indexOf('\img') > -1 ?
                                  <div style={{display:'flex'}}>
                                      <div style={{width:'37.5%'}}></div>
                                      <div style={{width:'25%'}}>
                                        <MathJax
                                          renderMode={'pre'} typesettingOptions={{fn:'tex2chtml'}}
                                          text={
                                            content.replace('`\\(','').replace('\\)`','')
                                          }
                                          // dynamic
                                          className={content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                                        />
                                      </div>
                                      <div style={{width:'37.5%'}}></div>
                                   </div>:
                                  <MathJax
                                  renderMode={'pre'} typesettingOptions={{fn:'tex2chtml'}}
                                  text={
                                    content.replace('`\\(','').replace('\\)`','')
                                  }
                                  // dynamic
                                  // className={content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                                  />
                              ))}
                            </MathJaxContext>
                                <div >
                                    {question.NewChoices.map((choice, i) => (
                                    <div style={{display:'flex'}}>
                                      <div style={{position: 'relative'}}>
                                      {matchingQuestion?.Select === i+1 ? 
                                        <span style={{position: 'absolute',color:"red",fontSize:"20px",bottom:"-2px",left:"2px"}}><b>✓</b></span>:""
                                      }
                                        <span style={{paddingRight:"5px"}}>{answer[i]} </span>
                                      </div>
                                     {choice}
                                     </div>
                                    ))}
                                </div>
                            </div>
                                <div style={{margin:"10px 0",borderBottom:"1px solid rgba(224, 224, 224, 1)"}}>
                                  <img src={matchingQuestion?.Memo} alt="" width={'100%'} />
                                </div>
                            </div>
                          {/* </div> */}
                    </Grid>
                    )
                    })}
                </Grid>
          </div>
          ))
        }
        </div>    
    );
}

export default HomeworkPrint;