import { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { CircularProgress } from "@mui/material";
import axios from "axios";

import { Layout } from "components/Academy/Layout";
import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';
import { StudentDetailView } from "components/Academy/student";

const StudentDetail = () => {
  const [ cookies] = useCookies(['LoginKey']);
  const navigate = useNavigate();
  const loginKey = cookies.LoginKey;
  const {id} = useParams();
  const studentNo = id;
  const { enqueueSnackbar } = useSnackbar();

  const [studentDiagnosis,setStudentDiagnosis] = useState(false);
  const [student,setStudent] = useState();
  const [monthDiagnosis,setMonthDiagnosis] = useState();
  const [parts, setParts] = useState(AddValue.AllPartsDashboard());
  const [part,setPart] = useState();
  const [loading, setLoading] = useState(false);

  
  const handleStudentData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${studentNo}/${loginKey}`, config).then((Response)=>{
      setStudent(Response.data.Student)
      let loadStudent = Response.data.Student;
      let tempParts = [...parts]; 
      for (let i = 0; i < tempParts.length; i++) {
        for (let j = 0; j < tempParts[i].subSections.length; j++) {
          for (let k = 0; k < tempParts[i].subSections[j].sections.length; k++) {
            let tempResult = loadStudent.SectionDiagnosis.filter((item) => { return item.Section === tempParts[i].subSections[j].sections[k].section });
            if(tempResult[0]){
              tempParts[i].subSections[j].sections[k].percent =tempResult[0].Percent
            }else{
              tempParts[i].subSections[j].sections[k].percent = null;
            }
          }    
        }
      }
      setParts(tempParts)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  }; 

  const classDetail=(number)=>{
    navigate(`/academy/class/${number}`)
  };

  const handleDiagnosisData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${studentNo}/month/${loginKey}`, config).then((Response)=>{
      setMonthDiagnosis(Response.data.Student)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };

  useEffect(()=>{
    handleStudentData();
    handleDiagnosisData();
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);
  },[]);     

  const lineChartData=[{id:"전국상위",data:[]}];
  const studentBarData=[];
  for (let i = 0; i < 12; i++) {
    const nowDate = new Date(new Date().setDate(1));
    const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
    studentBarData.push({country:`${month}월`})
    lineChartData[0]?.data?.push({ 'x': `${month}월` });
  };

  monthDiagnosis?.Diagnosis.forEach(item => {
    const createdAt = new Date(item.CreatedAt);
    const month = createdAt.getMonth() + 1;
    const percent = item.Percent;
    const targetMonthData = lineChartData[0].data.find(entry => {
      const entryMonth = parseInt(entry.x.split("월")[0], 10);
      return entryMonth === month;
    });
  
    if (targetMonthData) {
      if (!targetMonthData.y || createdAt > new Date(targetMonthData.CreatedAt)) {
        targetMonthData.y = ChangeText.IntPercent(percent).split("%")[0];
      }
    } else {
      lineChartData[0].data.push({ x: `${month}월`, y: 100 });
    }
  });

  // 월별 데이터 중에 y 값이 없는 것을 찾아서 y 값을 100으로 설정
  lineChartData[0].data.forEach(item => {
    if (!item.y) {
      item.y = 100;
    }
  });


  const barData = studentBarData?.map((month) => {
    const monthData = student?.SectionDiagnosis?.filter((item) => {
      const createdAtMonth = new Date(item.CreatedAt).getMonth() + 1;
      const monthIndex = parseInt(month.country);
      return createdAtMonth === monthIndex;
    });
    return { ...month, 횟수: monthData?.length };
  });

  const studentRadarChartData=[]
  for (let i = 0; i < student?.PartDiagnosis.length; i++) {
    studentRadarChartData.push({name:ChangeText.Part(student.PartDiagnosis[i].Part),'백분율':ChangeText.IntPercent(student.PartDiagnosis[i].Percent).split("%")[0],tool:ChangeText.IntPercent(student.PartDiagnosis[i].Percent).split("%")[0]})
  };


  return (
  <Layout >
    {
      loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
        <StudentDetailView 
        student={student}
        setStudentDiagnosis={setStudentDiagnosis}
        studentDiagnosis={studentDiagnosis}
        setPart={setPart}
        barData={barData}
        lineChartData={lineChartData}
        part={part}
        studentRadarChartData={studentRadarChartData}
        parts={parts}
        classDetail={classDetail}
        />
    }
  </Layout>
   );
}
 
export default StudentDetail;