import { Button,Avatar ,Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow ,Box,Pagination } from "@mui/material";

import { AcademySearchForm } from "components/form";

const TicketView = (props) => {
  const targetDate = new Date(props.tickets[0]?.Order_id?.NextOrder); 
  const currentDate = new Date(); 
  const timeDiff = targetDate.getTime() - currentDate.getTime(); 
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
  const dDay = `D-${daysDiff}`; 

return ( 
<Grid container justifyContent={"center"}>
  <Grid container lg={11.4} xl={11.2} justifyContent={"space-between"}>
    <Grid item lg={12} xl={12} sx={{paddingLeft:"0px !important"}}>
       <h1 style={{fontSize:"32px",color:"#000000"}}>학원정보</h1>
    </Grid>
    <Grid item lg={3.5} xl={3.5} sx={{background:"#FFFFFF",borderRadius:"21px",padding:"20px 0"}}>
      <Box sx={{padding:"10px",display:"flex",justifyContent:"center"}}>
        <p style={{fontSize:"24px",fontWeight:"500",lineHeight:"35px"}}>교사 인원 &nbsp;</p>
        <p style={{fontSize:"24px",fontWeight:"500",lineHeight:"35px"}}>{props.teacherList?.length}</p>
      </Box>
    </Grid>
    <Grid item lg={3.5} xl={3.5} sx={{background:"#FFFFFF",borderRadius:"21px",padding:"20px 0",display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Box sx={{padding:"10px",display:"flex",justifyContent:"center"}}>
        <p style={{fontSize:"24px",fontWeight:"500",lineHeight:"35px"}}>모의고사 응시권 &nbsp;</p> 
        <p style={{fontSize:"24px",fontWeight:"500",lineHeight:"35px"}}>{props.ticketCount}</p>
      </Box>
    </Grid>
    <Grid item lg={3.5} xl={3.5} sx={{background:"#FFFFFF",borderRadius:"21px",padding:"20px 0",display:"flex",alignItems:"center",justifyContent:"center"}}>
      <Box sx={{padding:"10px",display:"flex",justifyContent:"center"}}>
        <p style={{fontSize:"24px",fontWeight:"500",lineHeight:"35px"}}>소멸 예정 &nbsp;</p >
        <p style={{fontSize:"24px",fontWeight:"500",lineHeight:"35px"}}>{dDay}</p>
      </Box>
    </Grid>
  </Grid>
  <Grid item lg={11.3} xl={11.2} mt={6}>
    <h1 style={{fontSize:"32px",color:"#000000"}}>교사 목록</h1>  
  </Grid>
   <Grid container lg={11} xl={11}  sx={{  background:"#FFFFFF",borderRadius:'15px',padding:"30px" }}>
    <AcademySearchForm
      search={props.search}
      handleChange={props.handleChangeSearch}
      searchGuide="이름 / 이메일로 검색"
      />
    <Grid item={true}  lg={11.8} xl={12}>
      {props.rows.length > 0 ?
        <TableContainer>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <TableHead className="academy_table_head">
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell align="left">프로필</TableCell>
                <TableCell align="center">이름</TableCell>
                <TableCell align="center">전화번호</TableCell>
                <TableCell align="center">이메일</TableCell>
                <TableCell align="center" >생년월일</TableCell>
                <TableCell align="center" width={'50px'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props.rows.map((row, i) => (
                <TableRow key={i} sx={{ marginBottom: '10px' }}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell align="center">
                    <Avatar src={row.Profile} onClick={() => {props.handleClickCell(row.teacherNo) }} sx={{cursor:"pointer"}}/>
                  </TableCell>
                  <TableCell align="center" onClick={() => { props.handleClickCell(row.teacherNo) }} sx={{cursor:"pointer",color:"#1976d2"}}>{row.name}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.birth}</TableCell>
                  <TableCell align="right" width={'50px'}>
                    <Button variant="contained"disableElevation size="small" style={{marginLeft: 16,width:"90px",height:"34px",color:"#FFFFFF",background:"#F05D38",borderRadius: "6px",fontSize: "15px" }} onClick={ () => props.handleTeacherDataChange(row)}>
                      응시권 전달
                    </Button>
                  </TableCell>
                </TableRow>
                
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        :
        <h1 style={{textAlign:"center",marginTop:"50px"}}>생성한 교사가 없습니다.</h1>
      }
    </Grid>
    <Pagination
      count={Math.ceil(props.row?.length / props.pageSize)} // 전체 페이지 수
      page={props.currentPage} // 현재 페이지
      onChange={props.handlePageChange} // 페이지 변경 이벤트 핸들러
      color='warning'
      sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
      />
   </Grid>
  </Grid>

    );
}
 
export default TicketView;