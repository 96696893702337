import { Grid, Box, Avatar, Chip, FormControl, Input, Button} from '@mui/material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

const TeacherHomeworkScoringView = (props) => {
  const selectedHomework = props.selectedHomework
  const config = {
    "fast-preview": { disabled: true },
    loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
    tex: {
        packages: { "[+]": ["html", "img"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
      ],
      displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
      ],
    },
    options: {
        enableMenu: false,
    }
};
  return ( 
  <Grid container sx={{marginTop:"20px"}} justifyContent="center">
    <Grid container lg={6} xl={4} justifyContent="center">
        {props.studentData ?
          props.questionData?
            props.studentData?.QuestionInfo?.Check ?
            <div style={{margin:"5px 0",width:"100%",borderBottom:"1px solid rgba(224, 224, 224, 1)",paddingBottom:"5px"}}>
              <h1 >점수</h1>
              <div>
                <FormControl variant='standard' sx={{ width: '85%' }}>
                  <Input
                    type='number'
                    color='warning'
                    name='Score'
                    value={props.studentData.QuestionInfo.Score || ""}
                    onChange={props.handleChangeScore}
                />
                </FormControl>
                <Button variant='contained' color='warning' sx={{marginLeft:"10px"}} onClick={()=>props.handleUpdateScore()}>채점하기</Button>
              </div>
              <h2>성취기준: {props.questionData.standard}</h2>
              <h1>메모</h1>
              <div style={{ borderBottom:"1px solid rgba(224, 224, 224, 1)",}}>
                {props.studentData.QuestionInfo.Memo ?
                <img src={props.studentData.QuestionInfo.Memo} alt="" style={{width:'100%'}} />:
                <h2>작성한 메모가 없습니다.</h2>
                }
              </div>
              <h1 style={{marginBottom:"0px"}}>문제</h1>
              <div style={{paddingBottom:"10px" }}>
                <MathJaxContext version={3} config={config}>
                  {props.questionData?.Content.map((content) => (
                  <MathJax 
                  renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                  text={
                    content.replace('`\\(','').replace('\\)`','')
                  }
                  dynamic
                  className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                />
                  ))}
                </MathJaxContext>

              <div className='homework_questions_answer'>
              {props.questionData?.NewChoices.map((choice,i)=>(
              props.questionData?.Answer === i+1 ?
              <div >
                <RadioButtonChecked className='homework_question_answer'/>
                <MathJaxContext version={3} config={config}>
                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                      choice.replace('`\\(','').replace('\\)`','')
                      } inline dynamic/>
                </MathJaxContext>
              </div>:
              <div>
                <RadioButtonUnchecked/>
                <MathJaxContext version={3} config={config}>
                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                      choice.replace('`\\(','').replace('\\)`','')
                      } inline dynamic/>
                </MathJaxContext>
              </div>
                ))}
              </div>
            </div>
            <h1 style={{marginBottom:"10px"}}>해설</h1>
              {props.questionData.Commentary.length > 0 ?
              <div style={{margin:"10px 0",width:"100%"}}>
                  <MathJaxContext version={3} config={config} >
                  {props.questionData.Commentary?.map((item) => (
                    <MathJax
                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                        text={
                            item.replace('`\\(','').replace('\\)`','')
                        }
                        dynamic
                        className={item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                    />
                    ))}
                  </MathJaxContext>
              </div>:
              <p style={{fontSize:"16px"}}>해당 문제에 해설이 없습니다 빠른 시일 내 추가하겠습니다</p>
              }
          </div>
        :
        <div style={{margin:"5px 0",width:"100%",borderBottom:"1px solid rgba(224, 224, 224, 1)",paddingBottom:"5px"}}>
          <h2>해당 문제를 풀지 않았습니다</h2>
        </div>
        :
        <div style={{margin:"5px 0",width:"100%",borderBottom:"1px solid rgba(224, 224, 224, 1)",paddingBottom:"5px"}}>
          <h2>문제를 클릭해 주세요</h2>
        </div>
      :
      <h1>채점할 학생과 문제 번호를 클릭해 주세요</h1>
        }
    </Grid> 

    <Grid container lg={6} xl={6}>
      <Grid item lg={12} xl={7.5}>
        <table style={{ borderSpacing: 0 ,width:"100%"}}>
          <tr>
            <td style={{ fontSize:"18px",fontWeight:"700" }}>이름</td>
            <td style={{ fontSize:"18px",fontWeight:"700" }}>{props.studentData?.Student_id.Name}</td>
          </tr>
          <tr>
            <td style={{ fontSize:"18px",fontWeight:"700" }}>문제 번호</td>
            <td style={{ fontSize:"18px",fontWeight:"700" }}>{props.questionData?.Question}</td>
          </tr>
          <tr>
            <td style={{ fontSize:"18px",fontWeight:"700" }}>선택한 답</td>
            {props.studentData && props.studentData?.QuestionInfo?.Check ?   
            <td style={{ fontSize:"18px",fontWeight:"700" }}>{props.studentData.QuestionInfo?.Select}번</td>:
            <td style={{ fontSize:"18px",fontWeight:"700" }}></td>}
          </tr>
          <tr>
            <td style={{fontSize:"18px",fontWeight:"700" }}>정답 유무</td>
            {props.studentData && props.studentData?.QuestionInfo?.Check ?   
            <td style={{fontSize:"18px" ,fontWeight:"700"}}>{props.studentData.QuestionInfo?.Check === 1 ? " 정답 ": " 오답 "}</td>:
            <td style={{ fontSize:"18px",fontWeight:"700" }}></td>}
          </tr>
        </table>
          <Box sx={{ marginTop:"20px",border:"1px solid" ,padding:"0 10px" , display:"flex",flexFlow:"wrap",padding:"10px",border:"1px solid rgba(224, 224, 224, 1)" }}>
            {selectedHomework?.Students.map((students)=>(
              <div style={{display:"flex",alignItems:"center",margin:"5px"}}>
                <Avatar sizes='small' src={students.Student_id.Profile}/>
                {students.Questions.length > 0 ?
                  students.Questions.every(item => item.Score > 0) ?
                    <p style={{fontSize:"17px",marginLeft:"5px",cursor:"pointer",color:"rgba(224, 224, 224, 1)" }} onClick={() => props.handleStudentData(students)}className={props.studentData && students.StudentNo === props.studentData.StudentNo ? "custom_homework_student_list":""} >{students.Student_id.Name}</p>:
                    <p style={{fontSize:"17px",marginLeft:"5px",cursor:"pointer" }} onClick={() => props.handleStudentData(students)} className={props.studentData && students.StudentNo === props.studentData.StudentNo ? "custom_homework_student_list":""}>{students.Student_id.Name}</p> 
                  :
                  <p style={{fontSize:"17px",marginLeft:"5px",cursor:"pointer",color:"rgba(224, 224, 224, 1)" }} onClick={() => props.handleStudentData(students)}>{students.Student_id.Name}</p> 
                }
              </div>
            ))}
          </Box>
            <Box sx={{ padding:"0 10px" , display:"flex",flexFlow:"wrap",padding:"10px"}}>
              {selectedHomework?.Question_ids.map((question ,i)=>{
                  const filterQuestion = props.studentData?.Questions.filter((item)=>{return item.Question_id === question._id })
                  return <Chip label={question.Question} onClick={()=>props.handleQuestionData(question)} className={props.studentData?.Questions.length > 0 && filterQuestion[0]?.Score > 0  ? `custom_homework_part_color_${question.Section.toString()[0]}`: " "} key={i}sx={{ margin: "0 5px 8px 5px" }} variant='outlined'/>
              })}
            </Box>
            <Box sx={{textAlign:"right"}}>
              <Button variant='contained' color='warning' onClick={() => window.open(`${window.location.origin}/teacher/${selectedHomework.ClassNo}/homework/${selectedHomework.HomeworkNo}/custom/print`, '_blank')}>출력 하기</Button>
            </Box>
      </Grid>
    </Grid> 
  </Grid> 
  );
}
 
export default TeacherHomeworkScoringView;