import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Rating, Button} from "@mui/material";
import {  useNavigate } from "react-router-dom";

const TeacherDetailHomework = (props) => {
  const subjects = ["수학", "수학1", "수학2", "확률과 통계", "미적분", "기하"];
  const navigate = useNavigate();
  const handleHomeworkData = (data) => {
    navigate(`${data.classNo}/homework/${data.homeworkNo}/`)
  };
  return (
    <div>
      <h2>{props.homework[0].country} 숙제 리스트</h2>
      <div style={{background:"#FFFFFF",padding:"20px 0",borderRadius:"20px"}}>
        <TableContainer sx={{width:"95%",margin:"0 auto"}}>
          <Table sx={{ minWidth: 650 , borderCollapse: 'separate', borderSpacing: '0 10px' }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow sx={{ 'td,th': { borderLeft: 0,borderRight:0,textAlign:"center"} }}>
                <TableCell></TableCell>
                <TableCell align="right">단원</TableCell>
                <TableCell align="right">숙제 기한</TableCell>
                <TableCell align="right">문제 수</TableCell>
                <TableCell align="right">난이도</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props.homework[0].data.map((list,i)=>
                list.date?
              <TableRow sx={{ 'td,th': { textAlign:"center"} }}key={i}>
                <TableCell component="th" scope="row">
                  {list.date}
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {list.section}
                </TableCell>
                <TableCell align="right">
                  {list.period}
                </TableCell>
                {subjects.map((subject,i) => (
               list[subject] >= 0 && (
                <TableCell key={i}>{list[subject]}</TableCell>
              )
               ))}
                <TableCell align="right">
                  <Rating value={list.difficulty} readOnly/>
                </TableCell>
                <TableCell align="right" >
                  <Button onClick={() => handleHomeworkData(list)}>상세보기</Button>
                </TableCell>
              </TableRow>:""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TeacherDetailHomework;
