import React from "react";
import { Routes, Route } from "react-router-dom";
import { AcademyDashboard, AcademyInfo,AcademyCreate } from "domain/Academy/main";
import { AcademyPriceFail, AcademyPriceSuccess,AcademyPriceList } from "domain/Academy/main/price";
import NotFound from "routes/NotFound";

const AcademyMainPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<AcademyInfo />} />
      <Route path="price/success" element={<AcademyPriceSuccess />} />
      <Route path="price/list" element={<AcademyPriceList />} />
      <Route path="price/fail" element={<AcademyPriceFail />} />
      <Route path="create" element={<AcademyCreate />} />
      <Route path="dashboard" element={<AcademyDashboard />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AcademyMainPresenter;
