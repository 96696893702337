import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import {StudentDiagnosisView} from 'components/Academy/student/Diagnosis'
import { Layout } from "components/Academy/Layout";

const StudentDiagnosis = () => {
  const [ cookies] = useCookies(['LoginKey']);
  const {id,diagnosisNo} = useParams();
  const loginKey = cookies.LoginKey;
  const studentNo = id;
  const { enqueueSnackbar } = useSnackbar();

  const [student,setStudent] = useState();
  const [diagnosis,setDiagnosis] = useState();

   const handleStudentData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${studentNo}/diagnosis/${diagnosisNo}/${loginKey}`, config).then((Response)=>{
      setStudent(Response.data.Student)
      const loadDiagnosis=Response.data.Diagnosis
      let allTime =0
      for (let i = 0; i < loadDiagnosis.Questions.length; i++) {
        allTime+=loadDiagnosis.Questions[i].Time
      }
      loadDiagnosis.allTime=allTime
      setDiagnosis(loadDiagnosis)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };
  
  useEffect(()=>{
    handleStudentData()
  },[]);

  return ( 
  <Layout>
    <StudentDiagnosisView student={student} diagnosis={diagnosis}/>
  </Layout>
   );
}
 
export default StudentDiagnosis;