import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { East } from "@mui/icons-material";
import mockexam_info from "img/mockexam_info.png";
import mockexam_ticket1 from "img/mockexam_ticket1.png";
import mockexam_ticket2 from "img/mockexam_ticket2.png";
import mocjexam_question from "img/mocjexam_question.png";
import mockexam1 from "img/mockexam1.png";
import mockexam2 from "img/mockexam2.png";
import NormalDistributionImgae from "img/normal_distribution.png";
const MockExamInfo = () => {
  const navigate = useNavigate();

  return (
    <Grid className="diagnosisInfo">
      <Grid container justifyContent="center" spcing={10}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={5.5}>
          <Grid container className="title" alignItems={"center"}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={7} className="teacher_img">
              <h1 style={{ fontSize: "40px" }}>모의고사</h1>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                로그인 후 이용할 수 있는 서비스 입니다!
              </p>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                우로매쓰 모의 고사로 시험을 대비해 보세요!
              </p>
            </Grid>
            <Grid
              item
              xs={12} sm={12}  md={6} lg={6} xl={5}
              textAlign="center"
            >
              <img
                src={mockexam_info}
                width="200px"
                alt={mockexam_info}
              ></img>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h2>응시권 구매</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">1.&nbsp;</h3>
                <h3 className="info_text">모이고사 응시권 페이지 접속, 응시권 구매 클릭 </h3>
              </div>
              <img
                src={mockexam_ticket1}
                width="100%"
                alt="img"
                className="info-img"
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <h3 className="info_text"> 2. 응시권 구매 </h3>
              <img
                src={mockexam_ticket2}
                width="100%"
                alt="img"
                className="info-img"
              ></img>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12} mt={10}>
              <h2>모의고사 문제풀기</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">1.&nbsp;</h3>
                <div>
                  <h3 style={{ marginBottom: "5px",fontWeight: "500" }} >
                    OPEN 된 모의고사 열고 문제풀기 클릭{" "}
                  </h3>
                  <span style={{ fontWeight: "500" }}>
                    {"(모의고사 3회차 까지는 무료)"}
                  </span>
                </div>
              </div>
              <img
                src={mockexam1}
                width="100%"
                alt="img"
                className="info-img"
                style={{ marginTop: "20px" }}
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }} >
                <h3 className="info_text" >2.&nbsp;</h3>
                <h3 className="info_text"> 세부 단원 선택 후 진단하기 클릭 </h3>
              </div>
              <img
                src={mockexam2}
                width="100%"
                alt="img"
                className="info-img"
                style={{ marginTop: "20px" }}
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} mt={6}>
              <h3 className="info_text">3. 문제풀기</h3>
              <img
                src={mocjexam_question}
                width="100%"
                alt="img"
                className="info-img"
              ></img>
            </Grid>
          </Grid>
          
        
          <Grid container className="title">
            <Grid item xs={12} md={6} lg={12} xl={12} mt={16}>
              <h2>모의고사 결과</h2>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="diagnosis_result_table"
            >
              <table>
                <tbody>
                  <tr>
                    <th>선택과목</th>
                    <td>확률과 통계 </td>
                  </tr>
                  <tr>
                    <th>총 풀이 시간</th>
                    <td>3분 15초</td>
                  </tr>
                  <tr>
                    <th>원 점수</th>
                    <td>80점</td>
                  </tr>
                  <tr>
                    <th>표준 점수</th>
                    <td>90점</td>
                  </tr>
                  <tr>
                    <th>전국 백분위</th>
                    <td>36.34%</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="diagnosis_result_graph"
            >
              <div>
                <img src={NormalDistributionImgae} alt="normal distribution" />
                <span>
                  수능 1등급
                  <br />
                  내신 4등급
                </span>
                <div className="diagnosis_result_legend">
                  <div className="school_avg">
                    <p />
                    학교평균
                  </div>
                  <div className="all_avg">
                    <p />
                    전국평균
                  </div>
                  <div className="my_grade">
                    <p />내 성적
                  </div>
                </div>
                <div className="diagnosis_result_bar">
                  <div className="school_avg" style={{ left: `80%` }} />
                  <div className="all_avg" style={{ left: `50%` }} />
                  <div className="my_grade" style={{ left: `70%` }} />
                </div>
              </div>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            color="inherit"
            style={{ padding: "7px", width: "100%", marginTop: "24px" }}
            className="button-wooro"
            endIcon={<East />}
            onClick={() => {
              navigate(`/login`);
            }}
            target="_blank"
          >
            로그인 바로가기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MockExamInfo;
