import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

const AcademyPriceSuccess = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);

    const [open, setOpen] = useState(true);
    const [academy,setAcademy] = useState();

    const loadAcademyData = async()=>{
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
          setAcademy(Response.data.Academy);
        }).catch((err) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
      };

    const PriceSuccess = async() => {
        if(location.search){
            const locationSplit = location.search.split('&');
            let key = [];
            for(let i = 0; i < locationSplit.length; i++){
                key.push(locationSplit[i].split('=')[1]);
            }
            
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({
                customerKey: key[0],
                authKey: key[1]
            });
            await axios.post(`/api/price/academy`, body, config).then((Response) => {
                if(Response.data.Message ){
                    if (academy) {
                        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                        navigate(`/academy/dashboard`);    
                    }else{
                        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                        navigate(`/academy/create`);
                    }
                }else{
                    navigate(`/academy/price/fail`);    
                }
            }).catch((Error) => {
                navigate(`/academy/price/fail`);
            });
        }else{
            navigate(`/academy/price/fail`);
        }
    };
    useEffect(() => {
        loadAcademyData();
    }, []);
    
    useEffect(()=>{
        PriceSuccess();
    },[academy])
    
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
            <div style={{ position: 'absolute', top: '55%', fontSize: '17px'}}>결제 진행중 입니다.</div>
        </Backdrop>
    );
};

export default AcademyPriceSuccess;