import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Stack, Breadcrumbs, Typography, FormControl, Input, InputAdornment, IconButton } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { NavigateNext, Search } from '@mui/icons-material'
import { useCookies } from 'react-cookie';

const Layout = (props) => {
    const [search, setSearch] = useState('');
    const [cookies] = useCookies(['Name', 'Profile'])
    const handleChange = (event) => {
        setSearch(event.target.value);
    };

    const handleKeyDown = (event) => {
        if(event.code === 'Enter'){
            handleClickSearch();
        }
    };

    const handleClickSearch = () => {

    };

    return(
        <div className='admin_layout_nav'>
            <div className='admin_layout_nav_profile'>
                <Avatar src={cookies.Profile} className='admin_boxshadow' />
            </div>
            <div className='admin_layout_nav_name'>
                {cookies.Name}
            </div>
            {/* <div className='admin_layout_nav_search'>
                <FormControl variant='standard' fullWidth >
                    <Input
                        type='text'
                        value={search}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        startAdornment={
                            <InputAdornment position='start'>
                                <IconButton onClick={handleClickSearch}>
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div> */}
            <div className='admin_layout_nav_page'>
                <Stack spacing={2}>
                    <Breadcrumbs
                        separator={<NavigateNext fontSize='small' />}
                        aria-label='breadcrumb'
                    >
                    {props.pages.map((page) => (
                        page.link ? <Link to={page.link}>{page.title}</Link> : <Typography>{page.title}</Typography>
                    ))}
                    </Breadcrumbs>
                </Stack>
            </div>
        </div>
    );
}

export default Layout;