import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, CircularProgress, Grid, Link } from '@mui/material';
import { Close, Lock, LockOpen } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { SubmitModal } from '.';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const MockExamPreviewModal = (props) => {
    const [cookies] = useCookies(['LoginKey']);
    const [open, setOpen] = useState(false);
    const handleClickSubmit = () => {
        setOpen(false);
        props.handleClickLock(props.mockExamData.MockExamNo);
    };
    return(
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.buying ?  () => { return false; }  : props.onClose}
                aria-describedby='alert-dialog-slide-description-delete'
                fullWidth={true}
            >
                <DialogTitle>
                    {props.title}
                    <IconButton
                        aria-label='close'
                        onClick={props.buying ?  () => { return false; }  : props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                    {props.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        {cookies.LoginKey ?
                            props.userTicketCnt > 0 ?
                                <Grid item xs={9}>
                                    <IconButton onClick={() => setOpen(true)} disabled={props.mockExamData.UnLock}>
                                        {props.buying ?
                                            <CircularProgress color='warning'/>:
                                            !props.mockExamData.UnLock ? 
                                                <Lock color='warning'/>:
                                                <LockOpen />
                                        }
                                    </IconButton>
                                    {props.buying ? <b>응시권으로 모의고사를 구매중입니다.</b> : !props.mockExamData.UnLock ? <b>사용 가능한 응시권이 {props.userTicketCnt}개 있습니다.</b> : <b style={{ color: '#5E5E5E' }}>응시 가능한 모의고사 입니다.</b>}
                                </Grid>:
                                <Grid item xs={9}>
                                    <IconButton disabled={true}>
                                        <Lock />
                                    </IconButton>
                                    {!props.mockExamData.UnLock ?
                                        <><b>응시권이 부족합니다.</b> 구매를 원하시면 <Link href='/user/ticket'>여기</Link>를 클릭해 주세요.</>:
                                        <b style={{ color: '#5E5E5E' }}>응시 가능한 모의고사 입니다.</b>
                                    }
                                </Grid>:
                            <Grid item xs={9}>
                                <IconButton disabled={true}>
                                    <Lock />
                                </IconButton>
                                <b>로그인이 필요합니다.</b> 로그인을 하시려면 <Link href='/login'>여기</Link>를 클릭해 주세요.
                            </Grid>
                        }
                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                            <Button onClick={props.onClose} color='primary' disabled={props.buying}>닫기</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <SubmitModal
                open={open}
                handleClose={() => setOpen(false)}
                title={'모의고사를 구매합니다.'}
                handleClickSubmit={handleClickSubmit}
            >
                모의고사 응시권 1개를 사용하여 모의고사를 구매합니다., 유효 기간이 짧은 순으로 모의고사 응시권이 소모되며, 모의고사 시작 시 응시권 환불이 불가능합니다.
            </SubmitModal>
        </div>
    );
};

export default MockExamPreviewModal;