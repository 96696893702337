import React, { useEffect } from 'react';
import { Backdrop, IconButton, ButtonGroup, Button } from '@mui/material';
import { Close, Create, Refresh, SmartButton } from '@mui/icons-material';

const QuestionMemoReview = (props) => {
    useEffect(() => {
        props.open ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
    }, [props.open]);
    return (
        <div >
            <Backdrop
                sx={{ color: '#fff', zIndex: '10000001', backgroundColor: 'rgba(255, 255, 255, 0.65)', overflow: 'auto' }}
                open={props.open}
                onClick={() => props.setOpen(false)}
            >   {typeof(props.memoReview) === 'string' ?
                <img src={props.memoReview} alt='memo' style={{ maxWidth: '100%' }}/>:
                typeof(props.memoReview) === 'object' ? 
                <div style={{ position: 'absolute', top: '0', left: '0'}}>
                {props.memoReview.map((memo) => (
                    <div style={{ width: '100%' }}>
                        <img src={memo} alt='memo' style={{ maxWidth: '100%' }}/>
                    </div>
                
                ))}
                </div>:
                null}
                <IconButton aria-label='close' style={{ position: 'absolute', top: '5%', right: '5%' }} onClick={() => props.setOpen(false)} >
                    <Close style={{ color: '#000' }}/>
                </IconButton>
            </Backdrop>
        </div>
    );
};

export default QuestionMemoReview;