import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import ChildMenu from 'components/parent/layout/ChildMenu';
import { ParentChildSelect, ParentChildList } from 'components/parent/child';
import { SearchChildModal } from 'components/modal';
import { SearchFormFind } from 'components/form';


const ChildSelect = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['LoginKey', 'ChildNo', 'ChildNickName', 'ChildName', 'ChildProfile']);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchChild, setSearchChild] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [childDatas, setChildDatas] = useState([
    {
      Child_id: {
        Name: '',
        Profile: ''
      },
      ChildNo: '',
      Connect: '',
      NickName: ''
    }
  ]);
  const [searchChildDatas, setSearchChildDatas] = useState([{
    Attending: '',
    Name: '',
    _id: '',
    Grade: '',
    Profile: '',
    Email: '',
    UserNo: '',
    School_id: { Name: '' }
  }]);

  const handleClickCloseSearch = () => {
    setOpenSearch(false);
    setSearchChild('');
    setSearchChildDatas([{
      Attending: '',
      Name: '',
      _id: '',
      Grade: '',
      Profile: '',
      Email: '',
      UserNo: '',
      School_id: { Name: '' }
    }]);
  };
  const handleChangeSearchChild = (event) => {
    setSearchChild(event.target.value);
  };

  const handleClickSearchChild = async() => {
    setLoadingSearch(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      searchChild: searchChild
    });
    await axios.post('/api/parent/child/search', body, config).then((Response) => {
      setTimeout(() => {
        setLoadingSearch(false);
      }, [500]);
      if(!Response.data.ErrorMessage){
        let temp = Response.data.Students;
        for (let i = 0; i < childDatas.length; i++) {
          temp = temp.filter((item) => item.UserNo.toString() !== childDatas[i].ChildNo.toString());
        }
        if(temp[0]){
          setSearchChildDatas(temp);
        }else{
          enqueueSnackbar(`검색결과가 없습니다.`, { variant: 'info' });  
        }
      }else{
        setSearchChildDatas([{
          Attending: '',
          Name: '',
          _id: '',
          Grade: '',
          Profile: '',
          Email: '',
          UserNo: '',
          School_id: { Name: '' }
        }]);
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'info' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const handleClickChildApply = async(key) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: LoginKey,
      studentNo: searchChildDatas[key].UserNo
    });
    await axios.post('/api/parent/child', body, config).then((Response) => {
      if(Response.data.Message){
        let applyChild = [...childDatas];
        applyChild.push({
          Child_id: {
            Name: searchChildDatas[key].Name,
            Profile: searchChildDatas[key].Profile ? searchChildDatas[key].Profile : ''
          },
          ChildNo: searchChildDatas[key].UserNo,
          Connect: 3
        });
        setChildDatas(applyChild);

        let applySearchChild = [...searchChildDatas];
        applySearchChild.splice(key, 1);
        setSearchChildDatas(applySearchChild);

        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const handleClickChild = (key) => {
    if(editMode){
      navigate(`/parent/${childDatas[key].ChildNo}/edit`);
    }else{
      if(childDatas[key].Connect === 2){
        let date = new Date();
        date.setDate(date.getDate()+30);
        setCookie('ChildNo', childDatas[key].ChildNo, { path: `/`, expires: date });
        setCookie('ChildName', childDatas[key].Child_id.Name, { path: `/`, expires: date });
        setCookie('ChildNickName', childDatas[key].NickName, { path: `/`, expires: date });
        setCookie('ChildProfile', childDatas[key].Child_id.Profile ? childDatas[key].Child_id.Profile : '', { path: `/`, expires: date });
        navigate(`/parent/${childDatas[key].ChildNo}`); 
      }else{
        enqueueSnackbar(`수락 대기중 입니다.`, { variant: 'info' });
      }
    }
  };

  const handleClickLogout = () => {
    removeCookie('LoginKey', { path: '/'});
    removeCookie('Name', { path: '/'});
    removeCookie('UserNo', { path: '/'});
    removeCookie('Profile', { path: '/'});
    removeCookie('ChildNo', { path: '/'});
    removeCookie('ChildName', { path: '/'});
    removeCookie('ChildNickName', { path: '/'});
    removeCookie('ChildProfile', { path: '/'});
    navigate('/');
  };

  useEffect(() => {
    setLoading(true);
    const loadChildDatas = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/parent/child/${LoginKey}`, config).then((Response) => {
        setTimeout(() => {
          setLoading(false);
        },[500]);
        if(!Response.data.ErrorMessage){
          setChildDatas(Response.data.ChildrenList);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });      
    }
    loadChildDatas();
  }, []);
  
  return (
    <ChildMenu>
      <ParentChildSelect
        childDatas={childDatas}
        loading={loading}
        setOpenSearch={setOpenSearch}
        editMode={editMode}
        setEditMode={setEditMode}
        handleClickChild={handleClickChild}
        handleClickLogout={handleClickLogout}
      />
      <SearchChildModal openSearch={openSearch} handleClickCloseSearch={handleClickCloseSearch} title='자녀 찾기'>
          <SearchFormFind 
            search={searchChild}
            handleChange={handleChangeSearchChild}
            searchGuide='자녀 이름/이메일으로 검색'
            loading={loadingSearch} 
            handleClick={handleClickSearchChild}
          />
          <ParentChildList 
            searchChildDatas={searchChildDatas}
            loading={loadingSearch}
            handleClickChildApply={handleClickChildApply}
          />
      </SearchChildModal>
    </ChildMenu>
  )
};

export default ChildSelect;