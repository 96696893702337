import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { Question, QuestionView, QuestionViewDetail } from 'domain/parent/question';

const QuestionPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<Question/>} />
      <Route path='/:Section' element={<QuestionView />} />
      <Route path='/:Section/:QuestionNo' element={<QuestionViewDetail/>} />
      <Route path='/*' element={<NotFound/>} />
    </Routes>
  )
};

export default QuestionPresenter;