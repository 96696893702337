import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { QuestionDetailUpdate } from 'components/admin/question';

const QuestionUpdate = () => {
    const pages = [
        { title: '문제 관리'},
        { title: '문제 목록'},
        { title: '문제 정보 수정'},
    ];
    const title = { main: '문제 정보 수정', sub: 'question'};
    const navigate = useNavigate();
    const { id } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const [question, setQuestion] = useState({
        Part: '',
        MiddlePart: '',
        Section: '',
        Difficulty: '',
        WDifficulty: '',
        WDiscrimality: '',
        Answer: '',
        Content: [],
        Commentary: [],
        Choices: [],
        NewChoices: [],
        State: ''
    });
    const [updating, setUpdating] = useState(false);
    const [choice, setChoice] = useState('');
    const [newChoice, setNewChoice] = useState('');
    const [content, setContent] = useState('');
    const [commentary, setCommentary] = useState('');
    const [edit, setEdit] = useState(null);
    const [editCommentary, setEditCommentary] = useState(null);
    // const [imgBase64, setImgBase64] = useState();
    // const [imgFile, setImgFile] = useState(null);
    // const [commentaryImgBase64, setCommentaryImgBase64] = useState();
    // const [commentaryImgFile, setCommentaryImgFile] = useState(null);

    const handleChange = (event) => {
        let changeQuestion = { ...question };
        changeQuestion[event.target.name] = event.target.value;
        setQuestion(changeQuestion);
    };

    const handleChangePart = (event) => {
        setQuestion({ ...question, Part: event.target.value, MiddlePart: '', Section: '' });
    };

    const handleChangeMiddlePart = (event) => {
        setQuestion({ ...question, MiddlePart: event.target.value, Section: '' });
    };
    
    const handleChangeContent = (event) => {
        setContent(event.target.value);
    };

    const handleClikcEditContent = (num) => {
        setEdit(num);
        setContent(question.Content[num]);
    };

    const handleClickAddContent = () => {
        if(content){
            let changeQuestion = { ...question };
            if(edit === null){        
                changeQuestion.Content.push(content);
            }else{
                changeQuestion.Content[edit] = content;
                setEdit(null);
            }
            setContent('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteContent = (num) => {
        let changeQuestion = { ...question };
        changeQuestion.Content.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleChangeCommentary = (event) => {
        setCommentary(event.target.value);
    };

    const handleClikcEditCommentary = (num) => {
        setEditCommentary(num);
        setCommentary(question.Commentary[num]);
    };

    const handleClickAddCommentary = () => {
        if(commentary){
            let changeQuestion = { ...question };
            if(editCommentary === null){        
                changeQuestion.Commentary.push(commentary);
            }else{
                changeQuestion.Commentary[editCommentary] = commentary;
                setEditCommentary(null);
            }
            setCommentary('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteCommentary = (num) => {
        let changeQuestion = { ...question };
        changeQuestion.Commentary.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleChangeChoice = (event) => {
        setNewChoice(event.target.value);
    };

    const handleClickAddChoice = () => {
        if(question.NewChoices.length < 5 && newChoice){
            let changeQuestion = { ...question };
            changeQuestion.Answer = '';
            changeQuestion.NewChoices.push(newChoice);
            setNewChoice('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteChoice = (num) => {
        let changeQuestion = { ...question };
        changeQuestion.Answer = '';
        changeQuestion.NewChoices.splice(num, 1);
        setQuestion(changeQuestion);
    };
    // const handleChangePicture = (event) => {
    //     let reader = new FileReader();

    //     reader.onloadend = () => {
    //         // 2. 읽기가 완료되면 아래코드가 실행됩니다.
    //         const base64 = reader.result;
    //         if (base64) {
    //             setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
    //         }
    //     }
    //     if (event.target.files[0]) {
    //         reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
    //         setImgFile(event.target.files[0]); // 파일 상태 업데이트
    //     }
    // };

    // const handleClickDeletePicture = () => {
    //     let DeletePicture = { ...question };
    //     DeletePicture.Picture = '';
    //     setQuestion(DeletePicture);
    //     setImgBase64('');
    //     setImgFile('');
    // };

    // const handleChangeCommentaryImage = (event) => {
    //     let reader = new FileReader();

    //     reader.onloadend = () => {
    //         // 2. 읽기가 완료되면 아래코드가 실행됩니다.
    //         const base64 = reader.result;
    //         if (base64) {
    //             setCommentaryImgBase64(base64.toString()); // 파일 base64 상태 업데이트
    //         }
    //     }
    //     if (event.target.files[0]) {
    //         reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
    //         setCommentaryImgFile(event.target.files[0]); // 파일 상태 업데이트
    //     }
    // };

    // const handleClickDeleteCommentaryImage = () => {
    //     let DeletePicture = { ...question };
    //     DeletePicture.CommentaryImage = '';
    //     setQuestion(DeletePicture);
    //     setCommentaryImgBase64('');
    //     setCommentaryImgFile('');
    // };

    const handleClickUpdate = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            QuestionId: question._id,
            Content: question.Content,
            Commentary: question.Commentary,
            Choices: question.Choices,
            NewChoices: question.NewChoices,
            Answer: question.Answer,
            Difficulty: question.Difficulty,
            WDifficulty: question.WDifficulty,
            WDiscrimality: question.WDiscrimality,
            loginKey: cookies.LoginKey
        });
        await axios.put(`/api/admin/question`, body, config).then((Response) => {
            console.log(Response)

            // uploadPicture();
            // uploadCommentaryImage();
            setTimeout(()=> {
                navigate(`/admin/question/${question._id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    // const uploadPicture = async() => {
    //     if(imgFile){
    //         const formData = new FormData();
    //         formData.append('QuestionId', question._id);
    //         formData.append('imgFile', imgFile);
    //         formData.append('loginKey', cookies.LoginKey);
    //         formData.append('state', '1');
    //         const config = {
    //             headers: {
    //                 'content-type': 'multipart/form-data'
    //             }
    //         };
    //         await axios.put(`/api/admin/question/image`, formData, config).then((Response) => {
    //             // console.log(Response);
    //         }).catch((Error) => {
    //             console.log(Error);
    //         });
    //     }else{
    //         return false
    //     }
    // };

    // const uploadCommentaryImage = async() => {
    //     if(commentaryImgFile){
    //         const formData = new FormData();
    //         formData.append('QuestionId', question._id);
    //         formData.append('imgFile', commentaryImgFile);
    //         formData.append('loginKey', cookies.LoginKey);
    //         formData.append('state', '2');
    //         const config = {
    //             headers: {
    //                 'content-type': 'multipart/form-data'
    //             }
    //         };
    //         await axios.put(`/api/admin/question/image`, formData, config).then((Response) => {
    //             // console.log(Response);
    //         }).catch((Error) => {
    //             console.log(Error);
    //         });
    //     }else{
    //         return false
    //     }
    // };

    useEffect(() => {
        const loadQuestion = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/question/${id}/${cookies.LoginKey}`, config).then((Response) => {
                let questionData = Response.data;
                setQuestion(questionData.question);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadQuestion();
    }, []);

    return (
        <Layout pages={pages} title={title}>
            <QuestionDetailUpdate
                question={question}
                newChoice={newChoice}
                content={content}
                commentary={commentary}
                handleChange={handleChange}
                handleChangePart={handleChangePart}
                handleChangeMiddlePart={handleChangeMiddlePart}
                handleChangeContent={handleChangeContent}
                handleClickAddContent={handleClickAddContent}
                handleDeleteContent={handleDeleteContent}
                handleChangeCommentary={handleChangeCommentary}
                handleClickAddCommentary={handleClickAddCommentary}
                handleDeleteCommentary={handleDeleteCommentary}
                handleChangeChoice={handleChangeChoice}
                handleClickAddChoice={handleClickAddChoice}
                handleDeleteChoice={handleDeleteChoice}
                handleClickUpdate={handleClickUpdate}
                updating={updating}
                edit={edit}
                editCommentary={editCommentary}
                handleClikcEditContent={handleClikcEditContent}
                handleClikcEditCommentary={handleClikcEditCommentary}
                // imgBase64={imgBase64}
                // handleChangePicture={handleChangePicture}
                // handleClickDeletePicture={handleClickDeletePicture}
                // commentaryImgBase64={commentaryImgBase64}
                // handleChangeCommentaryImage={handleChangeCommentaryImage}
                // handleClickDeleteCommentaryImage={handleClickDeleteCommentaryImage}
            />
        </Layout>
    )
};

export default QuestionUpdate;