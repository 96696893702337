import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Avatar, Link } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';

const StudentDetailInfo = (props) => {
    const navigate = useNavigate();

    const student = props.student;
    const questionCount = student.Questions.length;
    const answerCount = student.Questions.filter(q=>q.Check === 1).length;
    const answerPersent = questionCount !== 0 ? (answerCount / questionCount*100).toPrecision(4) : '0.00';
    const questionTime = student.Questions[0] ? student.Questions.map(q=> q.Time).reduce((a, b)=> (a ? a: 0) + (b ? b : 0)): 0;

    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 학생 상세 정보</h3>
                </Grid>
                <Grid className='info_button_group'>
                    <Button variant='contained' color='inherit' onClick={props.handleClickOpenResetPassword}>비밀번호 변경</Button>
                    <Button variant='contained' color='warning' onClick={() => navigate(`/admin/student/${student._id}/update`)}>수정</Button>
                    <Button variant='contained' color='error' onClick={props.handleClickOpenDelete}>삭제</Button>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>ID</th><td>{student._id}</td>
                            <th>이름</th><td className='info_table_button_cell'><div className='user_profile'><Avatar src={student.Profile}/></div><div className='user_profile'>{student.Name}</div></td>
                        </tr>
                        <tr>
                            <th>이메일</th><td>{student.Email}</td>
                            <th>전화번호</th><td>{student.Phone}</td>
                        </tr>
                        <tr>
                            <th>로그인</th><td>{ChangeText.Login(student.Login)}</td>
                            <th>현재 상태</th><td>{ChangeText.State(student.State)}</td>
                        </tr>
                        <tr>
                            <th>학교</th><td><Link href={`/admin/school/${student.School_id._id}`}>{student.School_id.Name}</Link></td>
                            <th>학년</th><td>{ChangeText.Grade(student.Grade)}</td>
                        </tr>
                        <tr>
                            <th>회원 구분</th><td>{ChangeText.Status(student.Status)}</td>
                            <th>재학 상태</th><td>{ChangeText.Attending(student.Attending)}</td>
                        </tr>
                        <tr>
                            <th>문제 풀이수</th><td>{questionCount} 문제</td>
                            <th>문제 풀이 시간</th><td>{ChangeText.QuestionMinute(questionTime)}</td>
                        </tr>
                        <tr>
                            <th>정답 수</th><td>{answerCount} 문제</td>
                            <th>정답률</th><td>{answerPersent}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default StudentDetailInfo;