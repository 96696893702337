import React from "react";
import { MockExamList, MockExamDetail, MockExamResult } from "domain/mockExam";
import { Routes, Route } from "react-router-dom";

import NotFound from 'routes/NotFound';

const MockExamPresenter = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<MockExamList />} />
        <Route path='/:mockExamNo/:kind' element={<MockExamDetail />}/>
        <Route path='/:mockExamNo/:kind/result' element={<MockExamResult />}/>
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MockExamPresenter;