import React, { useEffect, useState } from 'react';
import { Grid, Accordion, AccordionActions, Typography, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import { ExpandMore, Square } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';
import { ParentCalendar } from '.';

const ParentQuestion = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [cookie] = useCookies(['ChildNickName']);
    const location = useLocation();
    const [selectParts, setSelectParts] = useState([false, false, false, false, false, false]);

    // childData={childData} questionCounting={questionCounting} questionSummary={questionSummary}
    const child = props.childData;
    const questions = child.Questions.filter((item) => { return item.Status !== 5 });

    // TimeRangeGraph
    // 년월일 데이터 생성
    for(let i = 0; i < questions.length; i++){
        questions[i].YyyyMmDd = ChangeText.YyyyMmDd(questions[i].CreatedAt)
    }
    // 년월일 별로 그룹
    const groupBy = (xs, key) => {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    };
    const dateGroupQuestions = groupBy(questions, 'YyyyMmDd');
    
    // 현재 년도만 남김
    const dates = Object.keys(dateGroupQuestions).filter((item) => { return item.indexOf(new Date().getFullYear()) > -1 });
    // 데이터 생성
    const timeRangeData = [];
    for(let i = 0; i < dates.length; i++){
        const value = dateGroupQuestions[dates[i]].length;
        const day = dates[i];
        timeRangeData.push({ value: value, day: day });
    }

    // report barchart
    const questionWidth = questions.length/(props.questionSummary.Counting/props.questionSummary.User)*0.5*100 >= 100 ? 100 : questions.length/(props.questionSummary.Counting/props.questionSummary.User)*0.5*100;
    const childCurrect = questions.filter((item) => { return item.Check === 1 });
    const currectWidth = (childCurrect.length/questions.length)/(props.questionSummary.Currect/props.questionSummary.Counting)*0.5*100 >= 100 ? 100 : (childCurrect.length/questions.length)/(props.questionSummary.Currect/props.questionSummary.Counting)*0.5*100;
    let childTime = 0;
    for(let i = 0; i < childCurrect.length; i++){
        childTime += childCurrect[i].Time ? childCurrect[i].Time : 0;
    }
    const timeBalance = 118;
    const timeWidth = (childTime/childCurrect.length)/timeBalance*0.5*100 >= 100 ? 100 : (childTime/childCurrect.length)/timeBalance*0.5*100;

    // Part 별 진도
    const [allParts, setAllParts] = useState(AddValue.AllPartsDashboard());

    useEffect(() => {
        let loadAllParts = [...allParts];
        const solveQuestions = questions.filter((item) => { return item.Status === 1 });

        for(let i = 0; i < loadAllParts.length; i++){
            let tempPartCounter = 0;
            let tempPartAllCounter = 0;
            for(let j = 0; j < loadAllParts[i].subSections.length; j++){
                for(let k = 0; k < loadAllParts[i].subSections[j].sections.length; k++){
                    let tempSectionCounter = solveQuestions.filter((item) => { return item.Section === loadAllParts[i].subSections[j].sections[k].section });
                    loadAllParts[i].subSections[j].sections[k].Count = tempSectionCounter?.length;
                    tempPartCounter += tempSectionCounter?.length;
                    tempSectionCounter.sort(function (a, b) {
                        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                    });
                    loadAllParts[i].subSections[j].sections[k].LastSolve = tempSectionCounter[0]?.CreatedAt;

                    let tempSectionAllCounter = props.questionCounting.filter((item) => { return item._id === loadAllParts[i].subSections[j].sections[k].section })[0];
                    loadAllParts[i].subSections[j].sections[k].AllCount = tempSectionAllCounter?.Count;
                    tempPartAllCounter += tempSectionAllCounter?.Count;
                }
            }
            loadAllParts[i].Count = tempPartCounter;
            loadAllParts[i].AllCount = tempPartAllCounter;
        }
        setAllParts(loadAllParts);
    }, [props]);
    
    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };

    const handleClickPart = (key) => {
        const changeParts = [...selectParts];
        changeParts[key] = !selectParts[key];
        setSelectParts(changeParts);
    };

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>{cookie.ChildNickName !== 'undefined' ? cookie.ChildNickName : child.Name}의 리포트</h2>
                </Grid>
                <Grid item xs={12} md={3} className='dashboard_myreport'>
                    <div>
                        <p>{cookie.ChildNickName !== 'undefined' ? cookie.ChildNickName : child.Name}의 리포트</p>
                        <Grid item xs={12} className='dashboard_user_barchart'>
                            <table>
                                <tr><th rowSpan={2}>총 문제 풀이 수</th><td><div>{questions.length}문제</div><div className='myinfo' style={{width: `${questionWidth}%`}}></div></td></tr>
                                <tr><td><div>{Math.floor(props.questionSummary.Counting/props.questionSummary.User)}문제</div><div className='allinfo' style={{width: '50%'}}></div></td></tr> 
                                <tr><th rowSpan={2}>정답률</th><td><div>{questions.length > 0 ? ChangeText.Percent(childCurrect.length/questions.length) : '0%'}</div><div className='myinfo' style={{width: `${currectWidth}%`}}></div></td></tr>
                                <tr><td><div>{ChangeText.Percent(props.questionSummary.Currect/props.questionSummary.Counting)}</div><div className='allinfo' style={{width: '50%'}}></div></td></tr>
                                <tr><th rowSpan={2}>평균 풀이 시간</th><td><div>{ChangeText.QuestionMinute(childTime/childCurrect.length)}</div><div className='myinfo' style={{width: `${timeWidth}%`}}></div></td></tr>
                                <tr><td><div>{ChangeText.QuestionMinute(timeBalance)}</div><div className='allinfo' style={{width: '50%'}}></div></td></tr>
                                <tr><td colSpan={2} className='dashboard_user_barchart_legend'><div className='myinfo'/>자녀 정보<div className='allinfo'/>전체 평균</td></tr>
                            </table>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={7} className='dashboard_myreport'>
                    <div>
                        <p>{new Date().getFullYear()}년 {new Date().getMonth() < 6 ? '상반기' : '하반기'} 문제풀이 수</p>
                        <Grid style={{ padding: '16px !important'}}>
                            <div style={{ height: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }} className='parent_month_diagnosis_count'>
                                <ParentCalendar data={timeRangeData}/>
                            </div>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                {allParts.map((part, i) => (
                <Accordion expanded={selectParts[i]} onChange={() => handleClickPart(i)}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '50%', flexShrink: 0, overflow: 'hidden', fontWeight: 'bold'}}>
                            {part.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', textAlign: 'right', width: '50%'}}>{ChangeText.Percent(part.Count/part.AllCount)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <table className='parent_child_question_dashboard'>
                                {part.subSections.map((subSection) => (
                                    <>
                                    <tr>
                                        <th rowSpan={subSection.sections.length}>{subSection.name}</th>
                                        <td style={{ borderTop: '1px solid #A4A4A4' }}>
                                            <div className='parent_child_question_solve_section'>
                                                <div>{subSection.sections[0].Count !== 0 ? <Link style={{ textDecoration: 'none', zIndex: '3' }} href={`${location.pathname}/${subSection.sections[0].section}`}>{subSection.sections[0].name}</Link> : subSection.sections[0].name}</div>
                                                <div>{ChangeText.ApplyTime(subSection.sections[0].LastSolve)}</div>
                                                <div>{ChangeText.Percent(subSection.sections[0].Count/subSection.sections[0].AllCount)}</div>
                                            </div>
                                            <div style={{ backgroundColor: ChangeText.SolveTimeColor(subSection.sections[0].LastSolve), width: subSection.sections[0].Count === 0 ? '0.25%' : ChangeText.Percent(subSection.sections[0].Count/subSection.sections[0].AllCount) }} className='parent_child_question_solve_bar' />
                                        </td> 
                                    </tr>
                                    {subSection.sections.map((section, i) => (
                                        i !== 0 ?
                                    <tr>
                                        <td>
                                            <div className='parent_child_question_solve_section'>
                                                <div>{section.Count !== 0 ? <Link style={{ textDecoration: 'none', zIndex: '3' }} href={`${location.pathname}/${section.section}`}>{section.name}</Link> :section.name}</div>
                                                <div>{ChangeText.ApplyTime(section.LastSolve)}</div>
                                                <div>{ChangeText.Percent(section.Count/section.AllCount)}</div>
                                            </div>
                                            <div style={{ backgroundColor: ChangeText.SolveTimeColor(section.LastSolve), width: section.Count === 0 ? '0.25%' : ChangeText.Percent(section.Count/section.AllCount) }} className='parent_child_question_solve_bar' />
                                        </td>    
                                    </tr>:null
                                    ))}
                                    </>
                                ))}
                            </table>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                ))}
                </Grid>
                <Grid item xs={12} md={10} className='parent_child_question_dashboard_legend'>
                    <table>
                        <tr>
                            <td><Square style={{ color: '#DADADA' }} /></td><td>1달 이전</td>
                            <td><Square style={{ color: '#FAC5B8' }} /></td><td>1달 이내</td>
                            <td><Square style={{ color: '#FDEFA4' }} /></td><td>1주 이내</td>
                        </tr>
                    </table>
                </Grid>
            </Grid>
        </div>
    );
};

export default ParentQuestion;