import React, { useEffect } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { Main, Homework, Question, Dashboard, Ticket, MockExam, Order, Evaluation } from '.';
import { Propose } from 'domain/teacher/propose';
import NotFound from 'routes/NotFound';
import { useCookies } from 'react-cookie';

const ClassPresenter = () => {
  const navigate = useNavigate();
  const { ClassNo } = useParams();
  const [cookies] = useCookies(['ClassNo']);

  useEffect(() => {
    if(ClassNo !== cookies.ClassNo) navigate('/teacher', { replace: true });
  }, [cookies]);

  return (
    <Routes>
        <Route path='' element={<Main/>}/>
        <Route path='propose' element={<Propose/>} />
        <Route path='homework/*' element={<Homework/>}/>
        <Route path='question/*' element={<Question/>}/>
        <Route path='dashboard/*' element={<Dashboard/>}/>
        <Route path='evaluation/*' element={<Evaluation/>}/>
        <Route path='mockexam/*' element={<MockExam/>}/>
        <Route path='ticket/*' element={<Ticket/>} />
        <Route path='order/*' element={<Order/>} />
        <Route path='/*' element={<NotFound/>} />
    </Routes>
  );
};

export default ClassPresenter;
