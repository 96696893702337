import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionDiagnosis } from 'components/user/question';

import * as AddValue from 'components/function/AddValue';
import { useSnackbar } from 'notistack';
import { DiagnosisInfo } from 'components/info';


const Diagnosis = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(['LoginKey']);

  const loginKey = cookies.LoginKey;
  const parts = AddValue.AllParts();

  const getDiagnosisInfo = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/diagnosis/${loginKey}`, config).then((Response) => {
      let data = Response.data.result;
      for(let i = 0; i < data.length; i++){
        for(let j = 0; j < 7; j++){
          if(j === 0 || j === 1){
            if(Math.floor(data[i].Section/100) === 1){
              for(let k = 0; k < parts[j].subSections.length; k++){
                for(let t = 0; t < parts[j].subSections[k].sections.length; t++){
                  if(parts[j].subSections[k].sections[t].section === data[i].Section){
                    parts[j].subSections[k].sections[t].ZScore = data[i].ZScore;
                  }
                }
              }
            }
          }else if(Math.floor(data[i].Section/100) === j){
            for(let k = 0; k < parts[j].subSections.length; k++){
              for(let t = 0; t < parts[j].subSections[k].sections.length; t++){
                if(parts[j].subSections[k].sections[t].section === data[i].Section){
                  parts[j].subSections[k].sections[t].ZScore = data[i].ZScore;
                }
              }
            }
          }
        }
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }

  useEffect(()=>{
    if(loginKey){
      getDiagnosisInfo();
    }
  }, []);

  return (
    <Layout>
      {loginKey ? <QuestionDiagnosis parts={parts} loginKey={loginKey}/> : <DiagnosisInfo/>}
    </Layout>
  )
}

export default Diagnosis;