import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import NormalDistribution from "normal-distribution";
import { useSnackbar } from "notistack";
import { Layout } from "components/teacher/layout";
import { TeacherDashboard } from "components/teacher/class/dashboard";
import { CircularProgress } from "@mui/material";
import { TeacherDashboardInfo } from "components/info";
//1:문제풀기, 2:숙제풀기, 3:진단하기, 4:복습하기, 5:시험
const Dashboard = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { ClassNo } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [teacherData, setTeacherData] = useState({
    Name: "",
    Profile: "",
    ZscoreAll: [],
    ZscoreSchool: [],
    MonthZscoreSection: [[{ ZScore: "" }]],
  });
  const [classData, setClassDatas] = useState({
    ClassName: "",
    ClassImage: "",
    ClassNo: "",
  });
  const [studentDatas, setStudentDatas] = useState([
    {
      Connect: "",
      ConnectedAt: "",
      StudentNo: "",
      Student_id: {
        Attending: "",
        Grade: "",
        Name: "",
        School_id: { Name: "" },
        Diagnosis: [],
      },
    },
  ]);
  const [sortOption, setSortOption] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const handleChangeSort = (event) => {
    const option = event.target.value;
    let sortStudents = [...studentDatas];
    if (option === 0) {
      sortStudents.sort(function (a, b) {
        return new Date(b.ProposedAt) - new Date(a.ProposedAt);
      });
    } else if (option === 1) {
      sortStudents.sort(function (a, b) {
        return a.Student_id.Name.localeCompare(b.Student_id.Name);
      });
    } else if (option === 2) {
      sortStudents.sort(function (a, b) {
        return b.Student_id.Name.localeCompare(a.Student_id.Name);
      });
    } else if (option === 3) {
      sortStudents.sort(function (a, b) {
        return a.Zscore - b.Zscore;
      });
    } else if (option === 4) {
      sortStudents.sort(function (a, b) {
        return b.Zscore - a.Zscore;
      });
    } else if (option === 5) {
      sortStudents.sort(function (a, b) {
        return a.ZscoreSchool - b.ZscoreSchool;
      });
    } else if (option === 6) {
      sortStudents.sort(function (a, b) {
        return b.ZscoreSchool - a.ZscoreSchool;
      });
    }
    setSortOption(option);
    setStudentDatas(sortStudents);
  };
  const handleChangeSelect = (SNO) => {
    let changeStudents = [...studentDatas];
    for (let i = 0; i < changeStudents.length; i++) {
      if (changeStudents[i].StudentNo === SNO) {
        changeStudents[i].Selected = changeStudents[i].Selected ? false : true;
        break;
      }
    }
    setStudentDatas(changeStudents);
  };

  const handleChangeSelectAll = () => {
    let changeStudents = [...studentDatas];
    for (let i = 0; i < changeStudents.length; i++) {
      changeStudents[i].Selected = !selectAll;
    }
    setSelectAll(!selectAll);
    setStudentDatas(changeStudents);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  const studentsFilter = (datas) => {
    datas = datas.filter((data) => {
      return (
        data.Student_id.Name.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1 ||
        data.Student_id.Email.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1 ||
        data.Student_id.School_id.Name.toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
      );
    });
    return datas;
  };

  useEffect(() => {
    const loadDashboardData = async () => {
      const config = { headers: { "Content-type": "application/json" } };
      await axios
        .get(`/api/teacher/dashboard/${ClassNo}/${LoginKey}`, config)
        .then((Response) => {
          if (!Response.data.ErrorMessage) {
            const normDist = new NormalDistribution(0, 1);
            let schoolDist;
            let loadTeacher = Response.data.TeacherInfo;
            loadTeacher.ZscoreAll = [];
            loadTeacher.ZscoreSchool = [];
            // 최근에 수락된 순으로 학생 정렬.
            let loadStudents = Response.data.ClassInfo.Students.filter(
              (item) => {
                return item.Connect === 2;
              }
            );
            loadStudents.sort(function (a, b) {
              return new Date(b.ProposedAt) - new Date(a.ProposedAt);
            });
            loadTeacher.MonthZscoreSection = [];
            // 진단 결과 핸들링
            for (let i = 0; i < loadStudents.length; i++) {
              schoolDist = new NormalDistribution(
                loadStudents[i].Student_id.School_id.WooroZscore,
                1
              );
              // 학생 개인 전체 진단 결과
              loadStudents[i].ZscoreSection =
                loadStudents[i].Student_id.Diagnosis;
              // 최신순으로 정렬
              loadStudents[i].ZscoreSection.sort(function (a, b) {
                return new Date(b.CreatedAt) - new Date(a.CreatedAt);
              });
              // 중복 제거
              loadStudents[i].ZscoreSection = loadStudents[
                i
              ].ZscoreSection.filter((item1, a) => {
                return (
                  loadStudents[i].ZscoreSection.findIndex((item2, b) => {
                    return item1.Section === item2.Section;
                  }) === a
                );
              });
              for (let j = 0; j < loadStudents[i].ZscoreSection.length; j++) {
                if (loadStudents[i].Zscore === undefined) {
                  loadStudents[i].Zscore = normDist.cdf(
                    loadStudents[i].ZscoreSection[j].ZScore
                  );
                  loadStudents[i].ZscoreSchool = schoolDist.cdf(
                    loadStudents[i].ZscoreSection[j].ZScore
                  );
                } else {
                  loadStudents[i].Zscore += normDist.cdf(
                    loadStudents[i].ZscoreSection[j].ZScore
                  );
                  loadStudents[i].ZscoreSchool += schoolDist.cdf(
                    loadStudents[i].ZscoreSection[j].ZScore
                  );
                }
              }

              if (loadStudents[i].Zscore !== undefined) {
                loadStudents[i].Zscore =
                  Math.floor(
                    (1 -
                      loadStudents[i].Zscore /
                        loadStudents[i].ZscoreSection.length) *
                      10000
                  ) / 100;
                loadStudents[i].ZscoreSchool =
                  Math.floor(
                    (1 -
                      loadStudents[i].ZscoreSchool /
                        loadStudents[i].ZscoreSection.length) *
                      10000
                  ) / 100;
                loadTeacher.ZscoreAll.push(loadStudents[i].Zscore);
                loadTeacher.ZscoreSchool.push(loadStudents[i].ZscoreSchool);
              } else {
                loadStudents[i].Zscore = 100;
                loadStudents[i].ZscoreSchool = 100;
              }
              // 월 별 진단 결과
              loadStudents[i].MonthZscoreSection = [];
              // -11 개월 데이터
              let studentMonthDiagnosis = loadStudents[i].Student_id.Diagnosis;
              const nowDate = new Date();
              let cut = new Date();
              if (nowDate.getMonth === 11) {
                cut = new Date(`${new Date().getFullYear()}-01-01`);
              } else {
                cut = new Date(
                  `${new Date().getFullYear() - 1}-${
                    new Date().getMonth() + 2
                  }-01`
                );
              }
              studentMonthDiagnosis = studentMonthDiagnosis.filter((item) => {
                return new Date(item.CreatedAt) > cut;
              });
              // 1월 ~ 12월 까지
              for (let j = 0; j < 12; j++) {
                const tumpDate2 = new Date(
                  new Date(new Date().setDate(1)).setMonth(
                    new Date().getMonth() - 11 + j
                  )
                ).getMonth();

                // 진단 결과 월 별로 나눔
                loadStudents[i].MonthZscoreSection[j] =
                  studentMonthDiagnosis.filter((item) => {
                    return new Date(item.CreatedAt).getMonth() === tumpDate2;
                  });
                // 최신순으로 정렬
                loadStudents[i].MonthZscoreSection[j].sort(function (a, b) {
                  return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                });
                // 중복 제거
                loadStudents[i].MonthZscoreSection[j] = loadStudents[
                  i
                ].MonthZscoreSection[j].filter((item1, a) => {
                  return (
                    loadStudents[i].MonthZscoreSection[j].findIndex(
                      (item2, b) => {
                        return item1.Section === item2.Section;
                      }
                    ) === a
                  );
                });
                if (!loadTeacher.MonthZscoreSection[j]) {
                  loadTeacher.MonthZscoreSection[j] = [];
                }
                for (
                  let k = 0;
                  k < loadStudents[i].MonthZscoreSection[j].length;
                  k++
                ) {
                  loadTeacher.MonthZscoreSection[j].push(
                    loadStudents[i].MonthZscoreSection[j][k]
                  );
                }
              }
            }
            setTeacherData(Response.data.TeacherInfo);
            setClassDatas(Response.data.ClassInfo);
            setStudentDatas(loadStudents);
          } else {
            enqueueSnackbar(`${Response.data.ErrorMessage}`, {
              variant: "error",
            });
            navigate(-1);
          }
        })
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: "error" });
        });
    };
    loadDashboardData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, [500]);
  }, []);
  return (
    <Layout>
      <div className='question_front_cover' style={{backgroundColor: loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',display:loading?"block":"none"}}>{ loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
      {studentDatas[0] && studentDatas[0]?.Connect !== "" ? (
          <TeacherDashboard
            teacherData={teacherData}
            classData={classData}
            studentDatas={studentDatas}
            search={search}
            sortOption={sortOption}
            selectAll={selectAll}
            handleChangeSearch={handleChangeSearch}
            studentsFilter={studentsFilter}
            handleChangeSelect={handleChangeSelect}
            handleChangeSort={handleChangeSort}
            handleChangeSelectAll={handleChangeSelectAll}
          />
      ) : (
          <TeacherDashboardInfo/>
      )}
    </Layout>
  );
};

export default Dashboard;
