import { Avatar, Box, Grid } from "@mui/material";

import { StudentsPagination } from "../pagination";
import {TeacherDetailLineChart,TeacherDetailBarChart,TeacherDetailHomework,} from "./";

const TeacherDetailView = (props) => {

  return (
      <Grid container justifyContent="center">
        <Grid container lg={8} xl={7}>
          <Grid item={true}  lg={4} xl={5} mr={4}sx={{display: "flex",alignItems: "center",background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px",height:"304px",justifyContent:"center" }}>
            <Box>
              <Avatar sx={{ height: "157px", width: "157px" }} src={props.teacher?.Profile}/>   
              <h1 style={{fontSize:"32px",textAlign:"center"}}>{props.teacher?.Name}</h1>
            </Box>
          </Grid>
          <Grid item lg={7.5} xl={6}>
            <Box sx={{ background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px", height: "294px"}}>
              {props?.teacherDetailData?.length > 0 ? 
              <>
                <h1 style={{fontSize:"31px"}}>클래스</h1>
                <div className="academy_class_box">
                  {props?.teacherDetailData?.map((list, i) => (
                  <Box key={i} sx={{ display: "flex", alignItems: "center",margin:"23px 0px 10px 0px"}}>
                  <input
                    type="checkbox"
                    className="academy_checkbox"
                    name="test"
                    id={`check${i}`}
                    checked={list.checked || false}
                    onChange={(event) => props.handleCheckboxChange(event, i)}/>
                    <label htmlFor={`check${i}`}   className="academy_label"></label>
                    <Avatar className="list_click" sx={{ margin: "0 34px 0 16px",width:"62px",height:"62px" }} src={list.ClassImage}/>
                    <span onClick={()=>props.classDetail(list.ClassNo)} style={{cursor:"pointer",color:"#1976d2",fontSize:"19px",fontWeight:"500"}}>{list.ClassName}</span>
                  </Box>
                  ))}
                </div>
              </>:
                <h1 style={{textAlign:"center",marginTop:"110px"}}>생성한 클래스가 없습니다.</h1>
              }
            </Box>
          </Grid>

          <Grid item lg={11.8} xl={11.2} mt={5} sx={{background:"#FFFFFF",borderRadius: "12.652px",padding:"30px"}}>
            <h2 style={{ margin: "20px 0 20px 40px" }}>
              {props?.classData?.ClassName} 평균 등급
            </h2>
            <div style={{ height: "300px", width: "100%",whiteSpace: "nowrap", overflow: "hidden"}}>
              <TeacherDetailLineChart data={props.lineChartDate}/>
            </div>
          </Grid>
          <Grid item lg={11.8} xl={11.2} mt={6} sx={{background:"#FFFFFF",borderRadius: "12.652px",padding:"30px"}}>
            <h2 style={{ margin: "50px 0 0 20px" }}>
              {props?.classData?.ClassName} 숙제현황
            </h2>
            <div style={{ height: "400px",width: "100%",whiteSpace: "nowrap",overflow: "hidden",}}>
              <TeacherDetailBarChart 
              homework={props.homework} 
              setHomeWorkKey={props.setHomeWorkKey}
              setHomework={props.setHomework} 
              data={props.barChartData}
              />
            </div>
          </Grid>
         
        </Grid>
        
        <Grid container lg={4} xl={3}>
          <Grid item lg={12} xl={9.5}>
            <StudentsPagination
             limit={props.limit}
             page={props.page}
             totalPosts={props.totalPosts}
             setPage={props.setPage}
             handleChangeSearch={props.handleChangeSearch}
             search={props.search}
             onPageChange={props.handleChangePage}
             student={props.studentFilter}
             title={props?.classData?.ClassName}
            />
          </Grid>
        </Grid>
        <Grid container lg={12} xl={9.5} mr={14} sx={{'@media (max-width:1535px)':{marginRight:'0',marginLeft:"0"}}}> 
        {props.homeWorkKey ? (
            <Grid item lg={12} xl={12}>
              <TeacherDetailHomework homework={props.homework}classTitle={props.classTitle}test={props.test} />
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
  );
};

export default TeacherDetailView;
