import React from 'react';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import IconCategory0 from 'img/icon_category0.png';
import IconCategory1 from 'img/icon_category1.png';
import IconCategory2 from 'img/icon_category2.png';
import { useSnackbar } from 'notistack';

const Category = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    return(
        <>
            <div className='main_div_title'>
            </div>
            <Grid container>
                <Grid item className='main_div_category main_background_red main_div_category_only_parent' onClick={() => navigate(`${location.pathname}/dashboard`)}>
                    <span>과목 · 단원별 성적을 확인해 보세요!</span>
                    <p><span className='category_red'>성적관리</span></p>
                    <img src={IconCategory0} alt='icon category1' />
                </Grid>
                <Grid item className='main_div_category main_background_blue main_div_category_only_parent' onClick={() => navigate(`${location.pathname}/question`)}>
                    <span>과목 · 단원별 진도를 확인해 보세요!</span>
                    <p><span className='category_blue'>진도관리</span></p>
                    <img src={IconCategory1} alt='icon category1' />
                </Grid>
                <Grid item className='main_div_category main_background_yellow main_div_category_only_parent' onClick={() => navigate(`${location.pathname}/homework`)}>
                    <span>숙제 진행률과 완료 여부를 확인할 수 있어요!</span>
                    <p><span className='category_yellow'>숙제관리</span></p>
                    <img src={IconCategory2} alt='icon category2' />
                </Grid>
            </Grid>
        </>
    );
};

export default Category;