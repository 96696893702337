import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import NotFound from 'routes/NotFound';
import { MockExam, MockExamDetail, MockExamResult } from 'domain/teacher/mockExam';

const MockExamPresenter = () => {
  const [cookies] = useCookies(['LoginKey','AcademyNo']);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const [firstVisit, setFirstVisit] = useState(true);
  const [disabledDetail, setDisabledDetail] = useState(true);

  const loadOrderData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/order/${cookies.LoginKey}`, config).then((Response) => {

      if(!Response.data.Order){
        if (cookies.AcademyNo) {
          const isBack = location.pathname.split('mockexam');
          if(isBack[1] === '/' || !isBack[1]){
            return false;
          }else{
            navigate(-1);
          }
          if(firstVisit){
            enqueueSnackbar('Teacher Basic 회원만 사용이 가능합니다.', { variant: 'info' });
            setFirstVisit(false);
          }
        }else{
          setDisabledDetail(false);
        }
      }else{

        setDisabledDetail(false);
      }
    }).catch((Error) => {
      // console.log(Error);
    });
  };
  useEffect(() => {
    loadOrderData();
  }, [location.pathname]);
  
  return (
    <Routes>
      <Route path='' element={<MockExam disabledDetail={disabledDetail} />} />
      <Route path=':MockExamNo' element={<MockExamDetail />} />
      <Route path=':MockExamNo/:StudentNo/:Kind' element={<MockExamResult />} />
      <Route path='/*' element={<NotFound/>} />
    </Routes>
  )
};

export default MockExamPresenter;