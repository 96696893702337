import { Button, Grid,TableBody,Table,TableCell,TableContainer,TableHead,TableRow,Paper } from "@mui/material";
import * as ChangeText from 'components/function/ChangeText';
import { useNavigate } from "react-router-dom";

const DiagnosisDetailView = (props) => {
  const navigate=useNavigate()
  return ( 
  <Grid container justifyContent="center">
    <Grid container lg={11} xl={7} justifyContent={"space-between"} sx={{height:"300px"}}>
      <Grid item lg={5} xl={5.5} sx={{background:"#FFFFFF",borderRadius:"21px"}}>
       <h1 style={{fontSize:"32px",marginTop:"62px",marginLeft:"73px"}}>진단 결과</h1>
        <p style={{fontSize:"24px",fontWeight:"700",margin:"34px 0 34px 73px"}}>학생 : {props.student?.Name}</p>
        <p style={{fontSize:"24px",fontWeight:"700",marginLeft:"73px"}}>학교 : {props.student?.SchoolName}</p>
      </Grid> 
      <Grid item lg={5} xl={5} sx={{background:"#FFFFFF",borderRadius:"21px"}}>
        <p style={{fontSize:"24px",fontWeight:"700",marginTop:"72px",marginLeft:"73px"}}>{`${ChangeText.Part(props.student?.SectionDiagnosis[0].Section)} > ${ChangeText.MiddlePart(props.student?.SectionDiagnosis[0].Section)} > ${ChangeText.Section(props.student?.SectionDiagnosis[0].Section)}`}</p>
        <p style={{fontSize:"24px",fontWeight:"700",margin:"34px 0 34px 73px"}}>진단횟수 : {props.student?.SectionDiagnosis.length}회</p>
        <p style={{fontSize:"24px",fontWeight:"700",marginLeft:"73px"}}>전국 성적 : {ChangeText.IntPercent(props.student?.SectionDiagnosis[0].Percent)}</p>
      </Grid> 
    </Grid> 
    <Grid container lg={11} xl={7} sx={{marginTop:"56px"}}>
      <Grid item lg={12} xl={12} pb={2} sx={{background:"#FFFFFF",borderRadius:"21px"}}>
        <TableContainer >
          <Table sx={{ minWidth: 650 , borderCollapse: 'separate', borderSpacing: '0 10px' }} aria-label="simple table">
            <TableHead  className="academy_table_head">
              <TableRow>
                <TableCell>진단일</TableCell>
                <TableCell align="right">1</TableCell>
                <TableCell align="right">2</TableCell>
                <TableCell align="right">3</TableCell>
                <TableCell align="right">4</TableCell>
                <TableCell align="right">5</TableCell>
                <TableCell align="right">전국상위</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props.student?.SectionDiagnosis.map((diagnosis,i) => (
                <TableRow
                  key={i}
                >
                  <TableCell component="th" scope="row">
                    {ChangeText.YyyyMmDd(diagnosis.CreatedAt)}
                  </TableCell>
                  {diagnosis.DiagnosisQuestions.map((Diagnosis , i)=>(
                  <TableCell key={i} align="right">{Diagnosis.Check === 1 ? 'O' : 'X'}</TableCell>
                  ))}
                  <TableCell align="right">{ChangeText.IntPercent(diagnosis.Percent)}</TableCell>
                  <TableCell align="right"><Button onClick={()=>{navigate(`diagnosis/${diagnosis.DiagnosisNo}`)}}>상세보기</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid> 
    </Grid> 
  </Grid> 
  );
}
 
export default DiagnosisDetailView;