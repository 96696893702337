import React from "react";
import { Routes, Route } from "react-router-dom";
import { Ticket,TicketList } from "domain/Academy/mockExam";
import NotFound from "routes/NotFound";

const MockExamPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<Ticket />} />
      <Route path="/list" element={<TicketList />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default MockExamPresenter;
