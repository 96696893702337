import { Avatar, Box, Grid } from "@mui/material";
import {StudentDetailLineChart,StudentDetailBarChart,StudentDetailRadarChart,StudentDetailDiagnosis} from "./";

const StudentDetailView = (props) => {

  return (
    
  <Grid container justifyContent="center">
    <Grid container lg={12} xl={8}>
      <Grid item={true}  lg={3} xl={3} mr={2}sx={{display: "flex",alignItems: "center",background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px",height:"304px",justifyContent:"center" }}>
        <Box>
          <Avatar sx={{ height: "157px", width: "157px" }} src={props.student?.Profile}/>
          <h1 style={{fontSize:"32px",textAlign:"center"}}>{props.student?.Name}</h1>
        </Box>
      </Grid>
      <Grid item lg={4} xl={4}>
        <Box sx={{ background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px", height: "294px"}}>
          <h1 style={{fontSize:"31px",marginLeft:"0px"}}>클래스</h1>
          <div className="academy_class_box">
            {props.student?.Class?.map((list, i) => (
              <Box key={i} sx={{ display: "flex", alignItems: "center",margin:"23px 0px 10px 0px"}}>
                <Avatar className="list_click" sx={{ margin: "0 34px 0 0px",width:"62px",height:"62px" }} src={list.Class_id?.ClassImage}/>
                <span style={{cursor:"pointer",color:"#1976d2",fontSize:"19px",fontWeight:"500"}} onClick={()=>props.classDetail(list.ClassNo)}>{list.Class_id.ClassName}</span>
              </Box>
            ))}
          
          </div>
        </Box>
      </Grid>
      <Grid item lg={4} xl={4}>
        <Box sx={{ background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px", height: "294px"}}>
          <StudentDetailRadarChart 
            setStudentDiagnosis={props.setStudentDiagnosis}
            studentDiagnosis={props.studentDiagnosis}
            setPart={props.setPart}
            data={props.studentRadarChartData}
            parts={props.parts}
            />
        </Box>
      </Grid>

      <Grid item lg={11} xl={11} mt={6} sx={{ background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px"}}>
        <h2 style={{ margin: "20px 0 20px 40px" }}>클래스 리포트</h2>
        <div style={{ height: "300px", width: "100%", whiteSpace: "nowrap",overflow: "hidden",}}>    
          <StudentDetailLineChart data={props.lineChartData}/>
        </div>
      </Grid>
      
        <Grid item lg={11} xl={11} mt={6} sx={{ background:"#FFFFFF",padding: "10px 20px 0 20px",borderRadius: "38px"}}>
          <h2 style={{ margin: "50px 0 0 20px" }}>
          </h2>
          <div style={{ height: "300px",width: "100%", whiteSpace: "nowrap",overflow: "hidden" }}>
          <StudentDetailBarChart data={props.barData}/>
          </div>
        </Grid>
      
      <Grid id='partTitle'></Grid>
      <Grid item lg={12} xl={5} mt={4}>
      {props.studentDiagnosis ?
          <h1>{props.part[0].name}</h1>
        :""}
      </Grid>
      
    </Grid>
      {props.studentDiagnosis ?
         <StudentDetailDiagnosis part={props.part}/>
      :""}
  </Grid>
   );   
}
 
export default StudentDetailView;
