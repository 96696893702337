import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/user/layout';
import { StudentProposeList, StudentClassList } from 'components/user/student';
import { SearchClassModal } from 'components/modal';
import { SearchFormFind } from 'components/form';

const UserPropose = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();

  const [searchClass, setSearchClass] = useState('');
  const [openSearchClass, setOpenSearchClass] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [classList, setClassList] = useState([{
    Class_id: {
      ClassName: '',
      ClassNo: '',
      State: ''
    }
  }]);
  const [parentList, setParentList] = useState([{
    Parent_id: {
      Name: '',
      Email: '',
      Profile: ''
    },
    Connect: '',
    ProposedAt: ''
  }]);
  const [searchClassDatas, setSearchClassDatas] = useState([{
    ClassName: '',
    ManagerEmail: '',
    ManagerName: ''
  }]);
  const handleChangeSearchClass = (event) => {
    setSearchClass(event.target.value)
  };
  const handleClickOpenSearchClass = () => {
    setOpenSearchClass(true);
  }
  const handleClickCloseSearchClass = () => {
    setSearchClassDatas([{
      ClassName: '',
      ManagerEmail: '',
      ManagerName: ''
    }]);
    setSearchClass('');
    setOpenSearchClass(false);
  };
  

  useEffect(() => {
    const loadClassData = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/student/${LoginKey}`, config).then((Response) => {
        let loadClassDatas = Response.data.student.Class;
        loadClassDatas.sort(function (a, b) {
          return new Date(b.ProposedAt) - new Date(a.ProposedAt);
        });
        setClassList(loadClassDatas);
        let loadParentDatas = Response.data.student.Parent;
        loadParentDatas.sort(function (a, b) {
          return new Date(b.ProposedAt) - new Date(a.ProposedAt);
        });
        setParentList(loadParentDatas);
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });      
    }
    loadClassData();
  }, []);

  const handleClickSearchClass = async() => {
    setLoadingSearch(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      searchClass: searchClass
    });
    await axios.post('/api/student/class/search', body, config).then((Response) => {
      setTimeout(() => {
        setLoadingSearch(false);
      }, [500]);
      if(!Response.data.Message){
        let temp = Response.data;
        for (let i = 0; i < classList.length; i++) {
          temp = temp.filter((item) => item.ClassNo.toString() !== classList[i].ClassNo.toString());
        }
        if(temp.length > 0){
          setSearchClassDatas(temp);
        }else{
          enqueueSnackbar(`검색 결과가 없습니다.`, { variant: 'info' });
        }
      }else{
        setSearchClassDatas([{
          ClassName: '',
          ManagerEmail: '',
          ManagerName: ''
        }]);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  /* 클래스 신청 */
  const handleClickClassApply = async(key) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: LoginKey,
      teacherNo: searchClassDatas[key].ManagerNo,
      classNo: searchClassDatas[key].ClassNo,
    });
    await axios.post('/api/student/class', body, config).then((Response) => {
      if(Response.data.Message){
        let applyClass = [...classList];
        applyClass.unshift({
          ClassNo: searchClassDatas[key].ClassNo,
          Connect: 1,
          ProposedAt: new Date(),
          Class_id: {
            ClassName: searchClassDatas[key].ClassName,
            ManagerName: searchClassDatas[key].ManagerName,
            ManagerEmail: searchClassDatas[key].ManagerEmail,
            ClassImage: searchClassDatas[key].ClassImage ? searchClassDatas[key].ClassImage : '',
          }
        });
        setClassList(applyClass);

        let applySearchClass = [...searchClassDatas];
        applySearchClass.splice(key, 1);
        setSearchClassDatas(applySearchClass);

        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  
  /* 클래스 신청취소 */
  const handleClickClassCancel = async(key) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: LoginKey,
      classNo: classList[key].ClassNo
    });
    await axios.put('/api/student/class/cancel', body, config).then((Response) => {
      if(Response.data.Message){
        let cancelClass = [...classList];
        cancelClass.splice(key, 1);
        setClassList(cancelClass);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }

  /* 클래스 탈퇴 */
  const handleClickClassExit = async(key) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: LoginKey,
      classNo: classList[key].ClassNo
    });
    await axios.put('/api/student/class/exit', body, config).then((Response) => {
      if(Response.data.Message){
        let changeClass = [...classList];
        changeClass[key].ClassNo = 0;
        changeClass[key].Connect = 3;
        setClassList(changeClass);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  /* 학부모 수락 */
  const handleClickParentPermit = async(key) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: LoginKey,
      parentNo: parentList[key].ParentNo
    });
    await axios.put('/api/student/parent', body, config).then((Response) => {
      if(Response.data.Message){
        let permitParent = [...parentList];
        permitParent[key].Connect = 2;
        setParentList(permitParent);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  /* 학부모 거절 or 삭제 */
  const handleClickParentCancel = async(key) => {
    await axios({
      method: 'delete',
      url: `/api/student/parent`,
      data: {
        loginKey: LoginKey,
        parentNo: parentList[key].ParentNo
      }
    })
    .then((Response) => {
      if(Response.data.Message){
        let cancelParent = [...parentList];
        cancelParent.splice(key, 1);
        setParentList(cancelParent);
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  return (
    <Layout>
      <StudentProposeList
        classList={classList}
        handleClickOpenSearchClass={handleClickOpenSearchClass}
        handleClickClassCancel={handleClickClassCancel}
        handleClickClassExit={handleClickClassExit}
        parentList={parentList}
        handleClickParentPermit={handleClickParentPermit}
        handleClickParentCancel={handleClickParentCancel}
      />
      <SearchClassModal 
          openSearch={openSearchClass} 
          handleClickCloseSearch={handleClickCloseSearchClass}
          title='클래스 검색'
      >
        <SearchFormFind 
          search={searchClass}
          handleChange={handleChangeSearchClass}
          searchGuide='선생님 이름/이메일, 클래스 이름으로 검색'
          loading={loadingSearch} 
          handleClick={handleClickSearchClass}
        />
        <StudentClassList 
          searchClassDatas={searchClassDatas}
          loading={loadingSearch}
          handleClickClassApply={handleClickClassApply}
        />
      </SearchClassModal>
    </Layout>
  );
};

export default UserPropose;