import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { EvaluationList, EvaluationDetail } from 'domain/evaluation';

const EvaluationPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<EvaluationList/>}/>
      <Route path=':EvaluationNo' element={<EvaluationDetail/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
  );
};

export default EvaluationPresenter;