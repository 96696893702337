import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Avatar, FormControl, InputLabel, Select, MenuItem, OutlinedInput, InputAdornment, IconButton, Button, RadioGroup, Radio, FormControlLabel, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';

const StudentDetailUpdate = (props) => {
    const statusMapping = AddValue.StatusMapping();
    const stateMapping = AddValue.StateMapping();
    const attendingMapping = AddValue.AttendingMapping();
    const navigate = useNavigate();
    
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>학생 상세 정보 수정</h3>
                    <TextField
                        name='Name'
                        label='이름'
                        value={props.student.Name}
                        onChange={props.handleChange}
                        fullWidth={true}
                    />
                    <FormControl fullWidth className='update_profile'>
                        <InputLabel id='profile-label' shrink>프로필</InputLabel>
                        <Grid container justifyContent='space-between'>
                            <Grid xs={6} ><Avatar className='upload_profile_preview' src={props.imgBase64 ? props.imgBase64 : props.student.Profile}/></Grid>
                            <Grid xs={3} textAlign='right'>
                            <Button color='inherit' onClick={props.handleClickDeleteProfile}>
                                삭제
                            </Button>
                            <label htmlFor='Profile' className='upload_profile'>
                                <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangeProfile}/>
                                <Button component='span'>
                                    등록
                                </Button>
                            </label>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <TextField
                        disabled
                        id='Login'
                        label='로그인'
                        value={ChangeText.Login(props.student.Login)}
                        fullWidth={true}
                    />
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='gender-label' shrink>성별</InputLabel>
                        <RadioGroup
                            row
                            labelId='gender-label'
                            value={props.student.Gender}
                            name='Gender'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={1} control={<Radio />} label='남' />
                            <FormControlLabel value={2} control={<Radio />} label='여' />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel style={{backgroundColor: '#FFFFFF'}} id='birth-label' shrink>생년월일</InputLabel>
                        <OutlinedInput
                            labelId='birth-label'
                            name='Birth'
                            label='생년월일'
                            value={ChangeText.YyyyMmDd(props.student.Birth)}
                            onChange={props.handleChange}
                            type='date'
                        /> 
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='status-label'>회원구분</InputLabel>
                        <Select
                            labelId='status-label'
                            id='Status'
                            name='Status'
                            label='회원구분'
                            value={props.student.Status}
                            onChange={props.handleChange}
                            disabled
                        > 
                            {statusMapping.map((status) => (
                            <MenuItem value={status}>{ChangeText.Status(status)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='attending-label'>재학상태</InputLabel>
                        <Select
                            labelId='attending-label'
                            id='Attending'
                            name='Attending'
                            label='재학상태'
                            value={props.student.Attending}
                            onChange={props.handleChange}
                        > 
                            {attendingMapping.map((attending) => (
                            <MenuItem value={attending}>{ChangeText.Attending(attending)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='school-label'>학교</InputLabel>
                        <OutlinedInput
                            labelId='school-label'
                            name='School'
                            label='학교'
                            value={props.student.School_id.Name}
                            readOnly={true}
                            disabled={props.student.Status === 1 ? false : true}
                            endAdornment={
                                <InputAdornment position='end'>
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    edge='end'
                                    disabled={props.student.Status === 1 ? false : true}
                                    onClick={props.handleClickOpenSearch}
                                  >
                                    <Search />
                                  </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='grade-label' shrink>학년</InputLabel>
                        <RadioGroup
                            row
                            labelId='grade-label'
                            value={props.student.Grade}
                            name='Grade'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={1} control={<Radio />} label='1학년' />
                            <FormControlLabel value={2} control={<Radio />} label='2학년' />
                            <FormControlLabel value={3} control={<Radio />} label='3학년' />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='email-label'>이메일</InputLabel>
                        <OutlinedInput
                            labelId='email-label'
                            name='Email'
                            label='이메일'
                            value={props.student.Email}
                            onChange={props.handleChange}
                        /> 
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='phone-label'>전화번호</InputLabel>
                        <OutlinedInput
                            labelId='phone-label'
                            name='Phone'
                            label='전화번호'
                            value={props.student.Phone}
                            onChange={props.handleChange}
                        /> 
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='state-label'>현재상태</InputLabel>
                        <Select
                            labelId='state-label'
                            name='State'
                            label='현재상태'
                            value={props.student.State}
                            onChange={props.handleChange}
                        >
                            {stateMapping.map((state) => (
                            <MenuItem value={state}>{ChangeText.State(state)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='warning' onClick={props.handleClickUpdate} disabled={props.updating}>수정</Button>
                    </Grid>
                    {props.updating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='warning'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default StudentDetailUpdate;