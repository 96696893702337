import React, { useState } from 'react';
import { Grid, TableCell, TableRow, IconButton, TableHead, Table, Paper, TableContainer, TableBody, Link, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Pageview } from '@mui/icons-material';
import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';
import { FullNormalModal } from 'components/modal';

const EvaluationDetailView = (props) => {
    const evaluation = props.evaluation;
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [selectStudent, setSelectStudent] = useState({
        Name: '',
        Grade: '',
        StudentNumber: '',
        ClassNumber: '',
        Student_id: { Name: '' },
        Answer: [[]]
    });
    return (
        <>
        <div className='content_section'>
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} className='diagnosis_result_title'>
                <h2>{evaluation.Title}</h2>
            </Grid>
            <Grid item xs={12} md={10}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>학년</TableCell>
                        <TableCell>반</TableCell>
                        <TableCell>번호</TableCell>
                        <TableCell>이름</TableCell>
                        <TableCell>시작일시</TableCell>
                        <TableCell>종료일시</TableCell>
                        <TableCell align='center'>상세보기</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {evaluation.Students.length > 0 ? 
                        evaluation.Students.sort((a, b) => (a.Grade - b.Grade || a.ClassNumber - b.ClassNumber || a.StudentNumber - b.StudentNumber)).map((item, i) => (
                        <TableRow
                            sx={{ '& td, & th': { borderLeft: 0, borderRight: 0 } }}
                            key={i}
                        >
                            <TableCell component='th' scope='row'>
                                {i+1}
                            </TableCell>
                            <TableCell>{item.Grade}</TableCell>
                            <TableCell>{item.ClassNumber}</TableCell>
                            <TableCell>{item.StudentNumber}</TableCell>
                            <TableCell>{item.Name} ({item.Student_id.Name})</TableCell>
                            <TableCell>{YyyyMmDdHhMmSs(item.CreatedAt)}</TableCell>
                            <TableCell>{YyyyMmDdHhMmSs(item.UpdatedAt)}</TableCell>
                            <TableCell align='center'>
                                <IconButton size='small' disabled={!item.Answer[0]}>
                                    <Pageview onClick={() => {setSelectStudent(item);setOpen(true);}}/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        )):
                        <TableRow>
                            <TableCell colSpan={8} align='center'>
                                제출 내역이 없습니다.
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
            <Grid container item xs={12} md={10} sx={{ mt: '16px' }} justifyContent={'space-between'}>
                <Grid item style={{ padding: '0px' }}>
                    <Button variant='outlined' color='warning' disabled={props.updating} onClick={props.handleClickStateBtn}>
                        {evaluation.State === 1 ? '수행평가 종료' : '수행평가 종료 취소'}
                    </Button>
                </Grid>
                <Grid item style={{ padding: '0px' }}>
                    <Button variant='contained' color='warning' onClick={() => window.open(`${location.pathname}/print`)}>
                        출력
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        </div>
        {selectStudent.Answer[0][0] ?
        <FullNormalModal
            open={open}
            setOpen={setOpen}
            title={`${selectStudent.Grade}학년 ${selectStudent.ClassNumber}반 ${selectStudent.StudentNumber}번 ${selectStudent.Name} (${selectStudent.Student_id.Name})`}
        >
            <div style={{ width: '100%'}}>
                <table className='evaluation_table'>
                    <tbody>
                        <tr>
                            <th>성취기준</th>
                            <td colSpan={2}><div dangerouslySetInnerHTML={{ __html: evaluation.Standard?.replaceAll('\n', '<br/>')}}/></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th>활동</th>
                            <td colSpan={2}>{evaluation.Activity}</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <th>체점기준</th>
                            <td colSpan={2}>
                                {evaluation.Score.LabelsScores.length > 0 ?
                                <table className='evaluation_table_score'>
                                    <tr>
                                        <td><b>평가항목</b></td>
                                        {evaluation.Score.Scores.map((score, i) => (
                                        <td key={i}><b>{score}</b></td>
                                        ))}
                                    </tr>
                                    {evaluation.Score.Labels.map((label, i) => (
                                    <tr key={i}>
                                        <td><b>{label}</b></td>
                                        {evaluation.Score.Scores.map((_, j) => (
                                        <td>{evaluation.Score.LabelsScores[i][j]}</td>
                                        ))}
                                    </tr>
                                    ))}
                                </table>:
                                '-'}
                            </td>
                        </tr>
                    </tbody>
                    {evaluation.EvaluationElement.map((item, i) => (
                    <tbody key={i}>
                        {item.Detail.map((item2, j) => (
                        <tr key={j}>
                            {j === 0 ?
                            <th rowSpan={item.Detail.length}>{item.Label}</th> :
                            null}
                            <td>{item2}</td>
                            <td>
                                {selectStudent.Answer[i][j] ?
                                <Grid container>
                                    <Grid item xs={11} style={{ padding: 0 }}>
                                        <img
                                            src={selectStudent.Answer[i][j]} alt='images'
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                                :null}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    ))}
                </table>
            </div>
        </FullNormalModal>:null}
        </>
    );
};

export default EvaluationDetailView;