import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';

const StudentQuestionInfo = (props) => {
    const students = props.students;
    const [openStudent, setOpenStudent] = useState(false);
    const handleClickView = () => {
        setOpenStudent(!openStudent);
    };
    
    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 재학·졸업생 정보</h3>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>No.</th><th>학생 이름</th><th>학년</th><th>전화번호</th><th>이메일</th><th>문제 풀이 수</th>
                        </tr>
                        {students.map((student, i) => (
                            i < 5 || openStudent ?
                            <tr>
                                {console.log(student)}
                                <td>{student.UserNo}</td>
                                <td><Link href={`/admin/student/${student._id}`}>{student.Name}</Link></td>
                                <td>{student.Grade ? ChangeText.Grade(student.Grade) : ChangeText.Attending(student.Attending)}</td>
                                <td>{student.Phone}</td>
                                <td>{student.Email}</td>
                                <td>{student.Questions?.length}</td>
                            </tr>
                            : null
                        ))}
                        {students.length > 5 ? 
                            !openStudent ? 
                                <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>전체 보기 <ExpandMore fontSize='small'/></td></tr>
                            :   <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>닫기 <ExpandLess fontSize='small'/></td></tr>
                        :   students.length === 0 ?
                                <tr><td colSpan={8} className='info_table_all_line'>학생 정보가 없습니다.</td></tr>
                            : null}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default StudentQuestionInfo;