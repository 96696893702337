import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Grid, IconButton,Pagination, Button, CircularProgress, TableRow,TableHead,TableContainer,TableCell,TableBody,Table } from '@mui/material';
import { Add, Create } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import WooroTestLogoOnly from 'img/woorotest_logo_only.png';
import * as ChangeText from 'components/function/ChangeText';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
        theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EF4C23'
    }
}));
const TeacherClassSelect = (props) => {
    const [cookies] = useCookies(['Name']);
    const navigate = useNavigate();
    const classDatas = props.classDatas;
    const MaxStudent = props.orderData.MaxStudent ? props.orderData.MaxStudent : 15;
    const AcademyMaxStudent = props.academyOrderData.MaxStudent ? props.academyOrderData.MaxStudent : 100;

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5; // 한 페이지에 보여줄 데이터 개수
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const visibleData = props.boardList?.slice(startIndex, endIndex);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return(
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} className='teacher_class_select_title'>
                <h2>{cookies.Name} 선생님,<br/>관리할 클래스를 선택해 주세요.</h2>
            </Grid>
            <Grid item xs={12} md={5} className='teacher_class_logout_button'>
                <Button variant='outlined' color='warning' onClick={props.handleClickLogout} sx={{ marginRight: '10px'}}>로그아웃</Button>
                <Button variant='outlined' color='warning' onClick={() => navigate(`/teacher/edit`)}>회원정보수정</Button>
            </Grid>
            <Grid item xs={12} md={5} className='teacher_class_manage_button'>
                <IconButton color='warning' onClick={() => navigate(`/teacher/create`)}>
                    <Add />
                </IconButton>
                <IconButton color={props.editMode ? 'warning' : 'secondary'} onClick={() => props.setEditMode(!props.editMode)}>
                    <Create />
                </IconButton>
            </Grid>
            <Grid item xs={12} md={10} className='teacher_class_list'>
                {props.loading ? 
                <Grid style={{textAlign: 'center'}}>
                    <CircularProgress color='warning' />
                </Grid>:
                classDatas[0] ?
                <Grid container>
                {classDatas.map((classData, i) => (
                    <Grid item xs={6} sm={4} md={3} key={i} className='teacher_class' onClick={() => props.handleClickClass(i)}>
                        <div
                            style={{backgroundImage: classData.Class_id.ClassImage ? `url('${classData.Class_id.ClassImage}')`: `url('${WooroTestLogoOnly}')`}}
                            className={props.editMode ? 'teacher_class_edit_mode' : ''}
                        >
                            {props.editMode ?
                            <><div /><Create/></>:
                            null}
                        </div>
                        <p>{classData.Class_id.ClassName}</p>
                    </Grid>
                ))}
                </Grid>:
                <Grid style={{textAlign: 'center'}}>
                    <h4>클래스가 없습니다. 클래스를 생성하고 학생들을 편하게 관리해 보세요!</h4>
                    <Button variant='outlined' color='warning' onClick={() => navigate(`/teacher/create`)}>클래스 생성</Button>
                </Grid>
                }
            </Grid>
            <Grid item xs={12} md={5} className='teacher_class_list'>
                <Grid container justifyContent='center' alignItems='center' className='teacher_price_info'>
                { props.academyData ?
                    <Grid item xs={12}>* 현재 <b>{ChangeText.OrderState(3)}</b> 요금제를 사용하고 있습니다!</Grid>:
                    <Grid item xs={12}>* 현재 <b>{ChangeText.OrderState(props.orderData.State)}</b> 요금제를 사용하고 있습니다!</Grid>
                }
                {props.academyData ?
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <BorderLinearProgress variant='determinate' value={props.academyStudentList?.length/AcademyMaxStudent*100} style={{ width: '75%', margin: 'auto', display: 'inline-block'}} /><span style={{ float: 'right'}}>{ChangeText.IntPercent(props.academyStudentList?.length/AcademyMaxStudent)}</span>
                    </Grid>
                    :
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <BorderLinearProgress variant='determinate' value={props.studentDatas.length/MaxStudent*100} style={{ width: '75%', margin: 'auto', display: 'inline-block'}} /><span style={{ float: 'right'}}>{ChangeText.IntPercent(props.studentDatas.length/MaxStudent)}</span>
                    </Grid>
                
                }
                {props.academyData ?
                    <Grid item xs={12} style={{ textAlign: 'center'}}>{props.academyStudentList?.length}명 수강 중 <span>/ {AcademyMaxStudent}명 까지 수강 가능</span></Grid>
                    :
                    <Grid item xs={12} style={{ textAlign: 'center'}}>{props.studentDatas.length}명 수강 중 <span>/ {MaxStudent}명 까지 수강 가능</span></Grid>
                }
                    <Grid item xs={12}>
                    { props.academyData ?
                        <Button color='warning' variant='outlined' disabled>요금제 업그레이드</Button>:
                        <Button color='warning' variant='outlined' disableElevation onClick={() => navigate(`/teacher/price`)}>요금제 업그레이드</Button>
                    }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className='teacher_class_list'>
                <Grid container className='teacher_price_info'>
                    { props.academyData ?
                        (props.academyData.Manager_id === props.teacherInfo?._id ?
                        <Grid item xs={12}>{props.academyData.Name}</Grid>
                        : " "
                        )
                        :
                        <Grid item xs={12}>학생이 30명 이상이신가요?</Grid>
                    }
                    { props.academyData ?
                    (props.academyData.Manager_id === props.teacherInfo?._id ?
                        <Grid item xs={12}><span>안녕하세요 {props.academyData.Representative} 원장님</span></Grid>
                        :
                        <Grid  item xs={12} lg={12} xl={12} >
                            <Grid item xs={12}>
                                <p>
                                    {props.academyData.Name}&nbsp; 정보
                                </p>
                            </Grid>
                            <Grid item xs={12} sx={{display:"flex",justifyContent:"center"}}>
                                <Grid item  xs={1} >
                                    <p>대표</p>
                                    <p>생성일</p>
                                    <p>주소</p>
                                </Grid>
                                <Grid item  xs={2}>
                                    <p>{props.academyData.Representative} 원장님</p>
                                    <p>{ChangeText.YyyyMmDd(props.academyData?.createdAt)}</p>
                                    <p>{props.academyData.Address}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        )
                        :
                        <Grid item xs={12}><span>{"학원 만들기"}</span></Grid>
                    }
                    <Grid item xs={12}>
                     { props.academyData ?
                        (props.academyData.Manager_id === props.teacherInfo?._id ?
                            (props.orderData.State === 3 ?
                                <Button color='warning' variant='outlined' onClick={() => navigate(`/academy/dashboard`)}>관리자 페이지 바로가기</Button>
                                :
                                <Button color='warning' variant='outlined' onClick={() => navigate(`/academy`)}>학교 / 학원 전환</Button>
                            ) : ""
                        ):
                        ( props.orderData.State === 3 ?          
                          <Button color='warning' variant='outlined' onClick={() => navigate(`/academy/create`)}>학원 생성</Button>:
                          <Button color='warning' variant='outlined' onClick={() => navigate(`/academy`)}>학교 / 학원 전환</Button>
                        )
                    }
                    </Grid>
                </Grid> 
            </Grid> 

            { props.academyData ? 
              <Grid item xs={12} md={10} mb={4} className='teacher_class_list'>
              {props.academyData?.Manager_id === props.teacherInfo?._id ?  " " :
                (props.boardList ?
                <Grid container>
                    <Grid  item xs={12} lg={12} xl={12} >
                        <Grid item xs={12} sx={{fontWeight:"700",fontSize:"26px",textAlign:"center",marginBottom:"20px"}}>공지사항</Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                             <Table aria-label="simple table" >
                                <TableHead>
                                 <TableRow>
                                    <TableCell align="center" width={"40px"}> No </TableCell>
                                    <TableCell align="center" width={"200px"}> 제목 </TableCell>
                                    <TableCell align="center" width={"60px"}> 작성일 </TableCell>
                                 </TableRow>
                                </TableHead>
                                 <TableBody>
                                 {visibleData?.map((list,i) => (
                                    <TableRow
                                      key={i}
                                      sx={{ 'td,th': { border: 0 } }}
                                       >
                                       <TableCell align="center"  component="th" scope="row">{list.No}</TableCell>
                                       <TableCell align="center"  sx={{color:"#1976d2",cursor:"pointer"}} onClick={ () => props.openBoardModal(list) }>{list.Title}</TableCell>
                                        <TableCell align="center">{ChangeText.YyyyMmDd(list.createdAt)}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                              </Table>
                            </TableContainer>                       
                        </Grid>
                    </Grid>
                    <Pagination
                        count={Math.ceil(props.boardList?.length / pageSize)} // 전체 페이지 수
                        page={currentPage} // 현재 페이지
                        onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
                        color='warning'
                        sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
                    />
                </Grid>
                :
                <Grid style={{textAlign: 'center'}}>
                    <h4>공지사항이 없습니다.</h4>
                </Grid>)
                }
            </Grid> : ""
            }
          
        </Grid>
    );
};

export default TeacherClassSelect;