import { Grid,Button } from "@mui/material";
import {  useNavigate } from "react-router-dom";

const AcademyCreate = (props) => {
  const navigate = useNavigate();
  return ( 
     <Grid container justifyContent={"center"}>
      <Grid container lg={10} xl={5} justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"25px" ,padding:"30px 0"}}>
        <Grid item lg={12} xl={12} sx={{textAlign:"center"}} >
          <h1>학원 생성</h1>
        </Grid>
        <Grid item lg={11.3} xl={5}>
          <h2>학원 이름</h2>
          <input 
            type="text"
            className="academy_input"
            id="name" 
            name="name"
            value={props.academyData.name ||""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"90%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={6}>
          <h2>주소</h2>
          <input type="text"
            id="address"
            name="address"
            value={props.academyData.address || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={11} mt={2} mb={2}>
          <h2>사업자번호</h2>
          <input 
            type="text"
            id="businessRegistation"
            name="businessRegistation"
            value={props.academyData.businessRegistation || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7", border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={11} mt={2} mb={2}>
          <h2>휴대폰 번호</h2>
          <input 
            type="text"
            id="phone"
            name="phone"
            value={props.academyData.phone || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7", border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={11.3} xl={11} mt={2} mb={2}>
          <h2>대표 성함</h2>
          <input 
            type="text"
            id="representative"
            name="representative"
            value={props.academyData.representative || ""}
            onChange={props.handleChange}
            style={{borderRadius:"10px",background:"#F7F7F7", border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}  />
        </Grid>
        <Grid item lg={12} xl={12} mt={10} sx={{display:"flex" ,justifyContent:"space-between",padding:"0"}} >
          <div></div>
          <Button component='span'color='warning' sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",marginLeft:"100px",'&:hover': { background:"#EF4C23" } }} disableElevation variant='contained' onClick={()=>props.handleData()}>등록</Button>
          <Button  sx={{ width:"50px",fontSize:"15px",borderRadius:"10px" }} onClick={() => navigate(-1)}>취소</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
 
export default AcademyCreate;