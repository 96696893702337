import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText, CircularProgress, ListItemIcon, Avatar, Button } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';

const ParentChildList = (props) => {
    
    return(
        <Box
            sx={{ width: '100%', height: 400,  bgcolor: 'background.paper' }}
        >
            <List>
                {props.loading ?
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <CircularProgress color='primary'/>
                </div>
                :
                props.searchChildDatas.map((childData, i) =>(
                childData.Name ?
                <ListItem
                    secondaryAction={
                        <Button color='warning' onClick={() => props.handleClickChildApply(i)}>신청</Button>
                  }
                >
                    {/* <Button color='error'>취소</Button>
                    <Button color='inherit' disabled={true}>수강</Button> */}
                    <ListItemIcon>
                        <Avatar sx={{ backgroundColor: '#FBFBFB' }} src={childData.Profile ? childData.Profile : null}/>
                    </ListItemIcon>
                    <ListItemText primary={childData.Name} secondary={`${childData.School_id.Name} · ${childData.Attending === 1 ? ChangeText.Grade(childData.Grade)+' '+ChangeText.Attending(childData.Attending) : ChangeText.Attending(childData.Attending)}`}/>
                </ListItem>:
                null
                ))}
            </List>
        </Box>
    );
};

export default ParentChildList;