import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Dashboard } from 'domain/admin/dashboard';
import NotFound from 'routes/NotFound';

const AdminDashboardPresenter = () => { 
    return(
        <Routes>
            <Route path='' element={<Dashboard />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminDashboardPresenter;