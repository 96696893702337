import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, FormGroup, FormControlLabel, Switch, LinearProgress, Link } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';


const MockExamDetailInfo = (props) => {
    const navigate = useNavigate();
    const mockExam = props.mockExam;

    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 모의고사 상세 정보</h3>
                </Grid>
                <Grid className='info_button_group'>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch checked={mockExam.State === 1 ? true : false} disabled={props.updating} onChange={props.handleChangeState}/>}
                            label={ChangeText.QuestionState(mockExam.State)}
                        />
                    </FormGroup>
                    <Button variant='contained' color='warning' onClick={() => navigate(`/admin/mockexam/${mockExam._id}/update`)}>수정</Button>
                    <Button variant='contained' color='error' onClick={props.handleClickOpenDelete}>삭제</Button>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>ID</th><td>{mockExam._id}</td>
                            <th>모의고사 번호</th>
                            <td>{mockExam.MockExamNo}</td>
                        </tr>
                        <tr>
                            <th>모의고사 명</th>
                            <td colSpan={3}>
                                {mockExam.Name}
                            </td>
                        </tr>
                        <tr>
                            <th>시작일</th><td>{ChangeText.YyyyMmDd(mockExam.StartDate)}</td>
                            <th>생성일시</th>
                            <td>{ChangeText.YyyyMmDdHhMmSs(mockExam.createdAt)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default MockExamDetailInfo;