import React from 'react';

import { TeacherPriceKind } from 'components/teacher/price';

const TeacherPrice = () => {
  return (
    <TeacherPriceKind />
  );
};

export default TeacherPrice;