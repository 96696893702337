import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, InputAdornment, IconButton, Avatar, Button, CircularProgress } from '@mui/material';
import { Search } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';
import { DeleteModalForUser, PasswordChangeModal } from 'components/modal';

const StudentUpdate = (props) => {
    const [openPasswordChange, setOpenPasswordChange] = useState(false);
    const [openUserDelete, setOpenUserDelete] = useState(false);

    return (
        <Grid container justifyContent='center' className='content_section'>
            <Grid xs={12} sm={10} md={8} lg={6} className='user_edit'>
                <h2>개인 정보 수정</h2>
                <div>
                    <FormControl fullWidth >
                        <Grid container justifyContent='space-between'>
                            <Grid xs={6} style={{padding: '0px'}}><Avatar className='upload_profile_preview'  src={props.imgBase64 ? props.imgBase64 : props.student.Profile} style={{width: '100px', height: '100px'}} /></Grid>
                            <Grid xs={6}  style={{padding: '0px', textAlign: 'right'}}>
                                <Button color='inherit' onClick={props.handleClickDeleteProfile}>
                                    삭제
                                </Button>
                                <label htmlFor='Profile' className='upload_profile'>
                                    <input  name='Profile' id='Profile' type='file' onChange={props.handleChangeProfile}/>
                                    <Button component='span'>
                                        등록
                                    </Button>
                                </label>
                            </Grid>
                        </Grid>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='user_name' color='warning' shrink>이름</InputLabel>
                        <OutlinedInput
                            id='user_name'
                            color='warning'
                            type='text'
                            label='이름'
                            name='Name'
                            value={props.student.Name}
                            onChange={props.handleChange}
                            placeholder='이름을 입력해 주세요'
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='user_school' color='warning' shrink>학교 *</InputLabel>
                        <OutlinedInput
                        id='user_school'
                        color='warning'
                        type='text'
                        readOnly
                        value={props.student.School_id.Name}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='Search button'
                                    edge='end'
                                    onClick={props.handleClickOpenSearchSchool}
                                >
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                        label='학교 *'
                        placeholder='학교를 검색하여 선택해 주세요'
                        />
                    </FormControl>
                    <FormControl sx={{width: '47.5%', marginRight: '5%'}}>
                        <InputLabel id='user_attending' color='warning' shrink>재학 상태 *</InputLabel>
                        <Select
                            labelId='user_attending'
                            color='warning'
                            name='Attending'
                            label='재학 상태 *'
                            displayEmpty
                            value={props.student.Attending}
                            onChange={props.handleChangeAttending}
                            disabled={props.attendingDisabled}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem value='' disabled><span>재학 상태</span></MenuItem>
                        <MenuItem value='1'>재학</MenuItem>
                        <MenuItem value='2'>졸업</MenuItem>
                        <MenuItem value='3'>외국고</MenuItem>
                        {/* <MenuItem value='4'>중학생</MenuItem> */}
                        <MenuItem value='5'>검정고시</MenuItem>
                        <MenuItem value='6'>기타</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{width: '47.5%'}}>
                        <InputLabel id='user_grade' color='warning' shrink>학년</InputLabel>
                        <Select
                            labelId='user_grade'
                            color='warning'
                            name='Grade'
                            label='학년'
                            displayEmpty
                            disabled={props.student.Attending === '2' || props.student.Attending === '5' || props.student.Attending === '6' ? true : false}
                            value={props.student.Grade}
                            onChange={props.handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem value='' disabled><span>학년</span></MenuItem>
                        <MenuItem value='1'>1학년</MenuItem>
                        <MenuItem value='2'>2학년</MenuItem>
                        <MenuItem value='3'>3학년</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='user_phone' color='warning' shrink>전화번호</InputLabel>
                        <OutlinedInput
                        id='user_phone'
                        color='warning'
                        type='text'
                        name='Phone'
                        label='전화번호'
                        placeholder='전화번호를 입력해 주세요'
                        value={props.student.Phone}
                        onChange={props.handleChange}
                        />
                    </FormControl>
                    <hr/><br/>
                 
                    {/* <FormControl fullWidth>
                        <InputLabel id='user_gender' color='warning' shrink>성별</InputLabel>
                        <Select
                            labelId='user_gender'
                            color='warning'
                            name='Gender'
                            label='성별'
                            disabled={true}
                            value={props.student.Gender}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value='' disabled><span>성별</span></MenuItem>
                            <MenuItem value='1'>남</MenuItem>
                            <MenuItem value='2'>여</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor='user_name' color='warning' shrink>생년월일</InputLabel>
                        <OutlinedInput
                            id='user_name'
                            color='warning'
                            type='date'
                            label='생년월일'
                            name='Name'
                            disabled={true}
                            value={ChangeText.YyyyMmDd(props.student.Birth)}
                            placeholder='이름을 입력해 주세요'
                        />
                    </FormControl> */}
                    <FormControl fullWidth>
                        <InputLabel htmlFor='user_email' shrink>{props.student.Login === 1 ? props.student.Login === 2 ? '카카오 로그인' : '네이버 로그인' : '이메일'}</InputLabel>
                        <OutlinedInput
                            id='user_email'
                            color='warning'
                            type='text'
                            label={props.student.Login === 1 ? props.student.Login === 2 ? '카카오 로그인' : '네이버 로그인' : '이메일'}
                            name='Email'
                            placeholder='이메일을 입력해 주세요'
                            disabled={true}
                            value={props.student.Email}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <Button variant='outlined' color='inherit' onClick={() => setOpenUserDelete(true)}>회원 탈퇴</Button>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <hr/><br/>
                    <div>
                        {props.student.Login === 1 ? <Button variant='outlined' onClick={() => setOpenPasswordChange(true)}>비밀번호 변경</Button> : null}
                        {props.updating ?
                        <CircularProgress className='user_edit_btn' color='warning'/>:
                        <Button className='user_edit_btn' variant='contained' color='warning' disableElevation onClick={props.handleClickUpdate}>수정</Button>}
                    </div>
                </div>
            </Grid>
            <PasswordChangeModal 
                title={'비밀번호 변경'}
                open={openPasswordChange}
                setOpen={setOpenPasswordChange}
                user_id={props.student._id}
            />
            <DeleteModalForUser
                open={openUserDelete}
                setOpen={setOpenUserDelete}
                title={'회원 탈퇴'}
                deleting={props.deleting}
                setDeleting={props.setDeleting}
                submitBtn={'탈퇴'}
                handleClickDelete={props.handleClickDelete}
            >
                '탈퇴' 버튼 클릭 시 서비스 사용에 제한이 있을 수 있습니다.<br/>
                회원 탈퇴 시 20일 이내 복구가 가능하며, 20일 이후 삭제됩니다. 삭제 된 데이터는 복구 할 수 없습니다. 정말 탈퇴 하시겠습니까?
            </DeleteModalForUser>
        </Grid>
    );
};

export default StudentUpdate;