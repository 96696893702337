import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { Order } from 'domain/teacher/order';

const OrderPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<Order/>} />
      <Route path='/*' element={<NotFound/>} />
    </Routes>
  )
};

export default OrderPresenter;