import React, { useState } from 'react';
import { Grid, Avatar, Link, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import NormalDistribution from 'normal-distribution'; 
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import NormalDistributionImage from 'img/normal_distribution.png'
import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
        theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EF4C23'
    }
}));

const StudentDashboard = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectPart, setSelectPart] = useState(false);

    const student = props.student;
    const questionInfo = props.questionInfo;
    const zScore = props.zScore;
    const parts = props.parts;
    const sectionScore = props.sectionScore;
    const normDist = new NormalDistribution(0, 1);
    const schoolDist = new NormalDistribution(student.SchoolZScore, 1);

    // const meanTime = props.summary.Time / props.summary.Correct;
    const meanTime = 118;

    const solvePercent = questionInfo.Solve/(props.summary.Counting/props.summary.StudentCount)*0.5 < 1 ? questionInfo.Solve/(props.summary.Counting/props.summary.StudentCount)*0.5 : 1;
    const currentPercnet = !questionInfo.Solve ? 0 : questionInfo.Current/questionInfo.Solve;
    const currentPercnetWidth = currentPercnet/(props.summary.Correct/props.summary.Counting)*0.5 < 1 ? currentPercnet/(props.summary.Correct/props.summary.Counting)*0.5 : 1;
    const timePercent = !questionInfo.Current ? 0 : questionInfo.Time/questionInfo.Current;
    const timePercentWidth = timePercent/meanTime*0.5 < 1 ? timePercent/meanTime*0.5 : 1;
    const allGrade = zScore ? 1-normDist.cdf(zScore) : 0;
    const schoolGrade = zScore ? 1-schoolDist.cdf(zScore) : 0;
    const allMean = 0.5;
    const schoolMean = student.SchoolZScore ? normDist.cdf(student.SchoolZScore) : 0;
    const schoolBar = (0.15+((schoolMean)*0.7))*100 < 85 ? (0.15+((schoolMean)*0.7))*100 : 85;
    const studentBar = (0.15+((1-allGrade)*0.7))*100 < 79.5 ? (0.15+((1-allGrade)*0.7))*100 : allGrade === 0 ? 15.5 : 79.5;

    const handleClickPart = (part) => {
      setSelectPart(part);
      setTimeout(() => {
        window.location.href='#partTitle'
      }, [100]);
    };

    const ReturnProgressPart = (part) => {
        let partCount = 0;
        if(props.sectionQuestionCount){
            const partCounts = props.sectionQuestionCount.filter((item) => { return Math.floor(item._id/100) === part });
            for(let i = 0; i < partCounts.length; i++){
                partCount += partCounts[i].Count;
            }
        }
        return partCount;
    };
    
    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>나의 리포트</h2>
                </Grid>
                <Grid item xs={12} md={5} className='dashboard_myreport'>
                    <div>
                        <p>나의 리포트</p>
                        <Grid container>
                            <Grid item xs={4} className='dashboard_user_profile'>
                                <Avatar src={student.Profile} />
                                <p>{student.Name}</p>
                                <span>{student.SchoolName} {student.Grade ? ChangeText.Grade(student.Grade) : ChangeText.Attending(student.Attending)}</span>
                            </Grid>
                            <Grid item xs={8} className='dashboard_user_barchart'>
                                <table>
                                    <tr><th rowSpan={2}>총 문제 풀이 수</th><td><div>{questionInfo.Solve}문제</div><div className='myinfo' style={{width: `${solvePercent*100}%`}}></div></td></tr>
                                    <tr><td><div>{Math.floor(props.summary.Counting/props.summary.StudentCount)}문제</div><div className='allinfo' style={{width: '50%'}}></div></td></tr> 
                                    <tr><th rowSpan={2}>정답률</th><td><div>{ChangeText.IntPercent(currentPercnet)}</div><div className='myinfo' style={{width: `${currentPercnetWidth*100}%`}}></div></td></tr>
                                    <tr><td><div>{ChangeText.Percent(props.summary.Correct/props.summary.Counting)}</div><div className='allinfo' style={{width: '50%'}}></div></td></tr>
                                    <tr><th rowSpan={2}>평균 풀이 시간</th><td><div>{ChangeText.QuestionMinute(questionInfo.Time/questionInfo.Current)}</div><div className='myinfo' style={{width: `${timePercentWidth*100}%`}}></div></td></tr>
                                    <tr><td><div>{ChangeText.QuestionMinute(meanTime)}</div><div className='allinfo' style={{width: '50%'}}></div></td></tr>
                                    <tr><td colSpan={2} className='dashboard_user_barchart_legend'><div className='myinfo'/>내 정보<div className='allinfo'/>전체 평균</td></tr>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={5} className='dashboard_graph'>
                    <div>
                        <p>예상 등급 분석</p>
                        <img src={NormalDistributionImage} alt='normal distribution' />
                        <span>수능 {allGrade ? ChangeText.StaNine(1-allGrade) : '9'}등급<br/>내신 {schoolGrade ? ChangeText.StaNine(1-schoolGrade) : '9'}등급</span>
                        {/* <span>전국 상위 {allGrade ? ChangeText.IntPercent(allGrade) : '100%'}<br/>학교 상위 {schoolGrade ? ChangeText.IntPercent(schoolGrade) : '100%'}</span> */}
                        <div className='dashboard_graph_legend'>
                            <div className='school_avg'><p/>학교평균</div>
                            <div className='all_avg'><p/>전국평균</div>
                            <div className='my_grade'><p/>내 성적</div>
                        </div>
                        <div className='dashboard_graph_bar'>
                            <div className='school_avg' style={{left: `${schoolBar}%`}}/>
                            <div className='all_avg' style={{left: `${(0.15+((allMean)*0.7))*100}%`}}/>
                            <div className='my_grade' style={{left: `${studentBar}%`}}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <span>· 데이터가 부족하여 결과가 정확하지 않을 수 있습니다.<br/>· 학교 성적은 우로매쓰에 가입한 해당 학교 학생들의 분석 결과로 출력됩니다.</span>
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>진단 및 풀이 결과 상세보기</h2>
                </Grid>
                <Grid container item xs={12} md={10} className='dashboard_parts'>
                    {sectionScore.map((part, i) => (
                    <Grid item xs={12} sm={6} md={4}>
                    <div onClick={() => handleClickPart(i)}>
                    <p>{parts[i].name} <b>상위 {part.diagnosisCount ? ChangeText.IntPercent(1-normDist.cdf(part.zscore/part.diagnosisCount)) : '100%'}</b></p>
                        <div className='main_div_continue_graph'>
                        <p className='title'>정답률</p>
                        <div className='barchart'>
                            <BorderLinearProgress variant='determinate' value={part.solve ? part.current/part.solve*100 : '0'} />
                        </div>
                        <p className='percent'>{part.solve ? ChangeText.IntPercent(part.current/part.solve) : '0%'}</p>
                        <p className='title'>진도율</p>
                        <div className='barchart'>
                            <BorderLinearProgress variant='determinate' value={part.solve/ReturnProgressPart(i+1)*100} />
                        </div>
                            <p className='percent'>{ChangeText.IntPercent(part.solve/ReturnProgressPart(i+1))}</p>
                        </div>
                    </div>
                    </Grid>
                    ))}
                    <Grid id='partTitle'></Grid>
                </Grid>
                {selectPart !== false  ? 
                <>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>{selectPart !== false ? parts[selectPart].name : null}</h2>
                </Grid>

                <Grid container item xs={12} md={10} className='dashboard_part_sections'>
                    <Grid container item xs={12}>
                        {parts[selectPart].subSections.map((subSection) => (
                            subSection.sections.map((section) => (
                            <Grid item xs={6} sm={6} md={4} lg={3}>
                                <div style={{ textAlign: 'center', backgroundColor: `rgba(${AddValue.SectionColors(section.section)}, ${section.ZScore ? normDist.cdf(section.ZScore)*0.5 : 0})` }}>
                                    <p>
                                        {section.ZScore ? 
                                        <b>{`상위 ${ChangeText.IntPercent(1-normDist.cdf(section.ZScore))}`}</b>:
                                        '진단 결과 없음'}
                                    </p>
                                    <p className='dashboard_part_sections_name'>{subSection.name}<div>&nbsp;&gt;&nbsp;<b>{section.name}</b></div></p>
                                    <Button variant='contained' disableElevation color='warning' size='small' disabled={section.ZScore ? false : true} onClick={() => navigate(`${location.pathname}/${section.section}`)}>진단 결과 보기</Button>
                                </div>
                            </Grid>
                            ))
                        ))}
                    </Grid>
                </Grid>
                </>:null}
            </Grid>
        </div>
    );
};

export default StudentDashboard;