import React, { useState,useEffect } from 'react';
import { Grid } from '@mui/material';
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useNavigate } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';
import { HomeworkPreviewModal } from 'components/modal';
import WooroTestLogoOnly from 'img/woorotest_logo_only.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
        theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EF4C23'
    }
}));


const QuestionHomework = (props) => {
    const navigate = useNavigate();
    const homeworkDatas = props.homeworkDatas;
    const [openPreview, setOpenPreview] = useState(false);
    const [preview, setPreview] = useState({
        StartDate: '',
        DueDate: '',
        Section: '',
        Class_id: { ClassName: '' },
        Manager_id: { Name: '' },
        QuestionsCnt: '',
        Students: [{ Questions: [], MemoPictures: [] }]
    });
    const nowHomework = homeworkDatas.filter((item) => { return  item.Section !== 1000 && new Date() < new Date(item.DueDate).setHours(23,59,59,999) && item.Students[0].Questions.length < item.QuestionsCnt });
    const endHomework = homeworkDatas.filter((item) => { return  item.Section !== 1000 && new Date() < new Date(item.DueDate).setHours(23,59,59,999) && item.Students[0].Questions.length >= item.QuestionsCnt });
    const beforeHomework = homeworkDatas.filter((item) => { return  item.Section !== 1000 && new Date() >= new Date(item.DueDate).setHours(23,59,59,999)});
    const nowCustomHomework = homeworkDatas.filter((item) => { return  item.Section === 1000 && new Date() < new Date(item.DueDate) && item.Students[0].Questions.length < item.QuestionsCnt });
    const endCustomHomework = homeworkDatas.filter((item) => { return  item.Section === 1000 && ( new Date() < new Date(item.DueDate) && item.Students[0].Questions.length >= item.QuestionsCnt ) });
    const beforeCustomHomework = homeworkDatas.filter((item) => { return  item.Section === 1000 && new Date() >= new Date(item.DueDate)});
    
    // useEffect(() => {
    //     const timers = setInterval(() => {
    //         setCurrentTime(new Date());
    //     }, 1000);
    //     return () => clearInterval(timers);
    // }, [currentTime]);

    const handleClickHomework = (data) => {
        setPreview(data);
        setOpenPreview(true);
    };
    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='section_select_title' sx={{ textAlign: 'left' }}>
                    <h2>아직 하지 않은 <span>숙제가 {nowHomework.length}개</span> 있어요!</h2>
                </Grid>
                <Grid container item xs={12} md={10} sx={{ padding: '0px !important'}}>
                    {nowHomework.length === 0 && endHomework.length === 0 ? <div style={{textAlign: 'center', width: '100%'}}>진행중인 숙제가 없습니다.</div> : null}
                    {nowHomework.map((homework, i) => (
                        <Grid xs={6} sm={4} md={3} lg={2.4} className='question_homework_info' >
                             <div onClick={() => handleClickHomework(homework)}>
                                <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)` }} />
                                <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                <div className='homework_info_class'>{homework.Class_id.ClassName} <span>({homework.Manager_id.Name}선생님)</span></div>
                                <div className='homework_info_progress'>
                                    <BorderLinearProgress variant='determinate' value={homework.Students[0].Questions.length/homework.QuestionsCnt*100} /> 
                                </div>
                                <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.Students[0].Questions.length/homework.QuestionsCnt)}</div>
                            </div>
                        </Grid>
                    ))}
                    {nowCustomHomework.map((homework, i) => (
                        <Grid xs={6} sm={4} md={3} lg={2.4} className='question_homework_info' >
                            <div onClick={() => handleClickHomework(homework)}>
                                <div className='custom_homework_info_banner' style={{ backgroundImage: homework.Class_id.ClassImage ?`url(${homework.Class_id.ClassImage})` :`url(${WooroTestLogoOnly})`,backgroundSize: homework.Class_id.ClassImage ? "cover" : " 90px 90px" }} />
                                <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                <div className='homework_info_section' ><p style={{ overflow:"hidden",textOverflow:'ellipsis',width:"95%",whiteSpace:"nowrap",margin:" 8.5px 0"}}>{homework.Title}</p></div>
                                <div className='homework_info_class'>{homework.Class_id.ClassName} <span>({homework.Manager_id.Name}선생님)</span></div>
                                <div className='homework_info_progress'>
                                    <BorderLinearProgress variant='determinate' value={homework.Students[0].Questions.length/homework.QuestionsCnt*100} /> 
                                </div>
                                <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.Students[0].Questions.length/homework.QuestionsCnt)}</div>
                            </div>
                        </Grid>
                    ))}
                    {endHomework.map((homework, i) => (
                        <Grid xs={6} sm={4} md={3} lg={2.4} className='question_homework_info' >
                            <div onClick={() => handleClickHomework(homework)}>
                                <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)`, filter: 'grayscale(100%)' }} />
                                <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                <div className='homework_info_class'>{homework.Class_id.ClassName} <span>({homework.Manager_id.Name}선생님)</span></div>
                                <div className='homework_info_progress'>
                                    <BorderLinearProgress variant='determinate' value={100} /> 
                                </div>
                                <div className='homework_info_progress_percent'>100%</div>
                            </div>
                        </Grid>
                    ))}
                    {endCustomHomework.map((homework, i) => (
                        <Grid xs={6} sm={4} md={3} lg={2.4} className='question_homework_info' >
                            <div onClick={() => handleClickHomework(homework)}>
                                <div className='custom_homework_info_banner' style={{ backgroundImage: homework.Class_id.ClassImage ?`url(${homework.Class_id.ClassImage})` :`url(${WooroTestLogoOnly})`,backgroundSize: homework.Class_id.ClassImage ? "cover" : " 90px 90px" }} />  
                                <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                <div className='homework_info_section' ><p style={{ overflow:"hidden",textOverflow:'ellipsis',width:"95%",whiteSpace:"nowrap",margin:" 8.5px 0"}}>{homework.Title}</p></div>
                                <div className='homework_info_class'>{homework.Class_id.ClassName} <span>({homework.Manager_id.Name}선생님)</span></div>
                                <div className='homework_info_progress'>
                                    <BorderLinearProgress variant='determinate' value={100} /> 
                                </div>
                                <div className='homework_info_progress_percent'>100%</div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} md={10} className='section_select_title' sx={{ textAlign: 'left' }}>
                    <h2><span>지난 숙제</span></h2>
                </Grid>
                <Grid container item xs={12} md={10} sx={{ padding: '0px !important'}}>
                    {beforeHomework.length === 0 ? <div style={{textAlign: 'center', width: '100%'}}>지난 숙제가 없습니다.</div> : null}
                    {beforeHomework.map((homework, i) => (
                        <Grid xs={6} sm={4} md={3} lg={2.4} className='question_homework_info' >
                            <div onClick={() => handleClickHomework(homework)}>
                                <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)` }} />
                                <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                <div className='homework_info_class'>{homework.Class_id.ClassName} <span>({homework.Manager_id.Name}선생님)</span></div>
                                <div className='homework_info_progress'>
                                    <BorderLinearProgress variant='determinate' value={homework.Students[0].Questions.length/homework.QuestionsCnt*100} /> 
                                </div>
                                <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.Students[0].Questions.length/homework.QuestionsCnt)}</div>
                            </div>
                        </Grid>
                    ))}
                    {beforeCustomHomework.map((homework, i) => (
                        <Grid xs={6} sm={4} md={3} lg={2.4} className='question_homework_info' >
                            <div onClick={() => handleClickHomework(homework)}>
                                <div className='custom_homework_info_banner' style={{ backgroundImage: homework.Class_id.ClassImage ?`url(${homework.Class_id.ClassImage})` :`url(${WooroTestLogoOnly})`, backgroundSize: homework.Class_id.ClassImage ? "cover" : " 90px 90px" }} />
                                <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                <div className='homework_info_section' ><p style={{ overflow:"hidden",textOverflow:'ellipsis',width:"95%",whiteSpace:"nowrap",margin:" 8.5px 0"}}>{homework.Title}</p></div>
                                <div className='homework_info_class'>{homework.Class_id.ClassName} <span>({homework.Manager_id.Name}선생님)</span></div>
                                <div className='homework_info_progress'>
                                    <BorderLinearProgress variant='determinate' value={homework.Students[0].Questions.length/homework.QuestionsCnt*100} /> 
                                </div>
                                <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.Students[0].Questions.length/homework.QuestionsCnt)}</div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <HomeworkPreviewModal 
                open={openPreview}
                setOpen={setOpenPreview}
                title={'숙제 정보'}
                preview={preview}
                setPreview={setPreview}
            />
        </div>
    );
};

export default QuestionHomework;