import React, {  useState,useEffect } from 'react';
import { Avatar, Grid, FormControlLabel, Checkbox, FormControl, Select, MenuItem, Chip, Link ,CircularProgress} from '@mui/material';
import { AddCircleRounded, RemoveCircleRounded } from '@mui/icons-material';
import NormalDistribution from 'normal-distribution'; 
import { useLocation } from 'react-router-dom';

import { DashboardBarChart, DashboardLineChart, QuestionTimeBarChart } from './';
import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';
import { SearchForm } from 'components/form';

const TeacherDashboard = (props) => {
    const students = props.studentDatas
    const location = useLocation();
    const [classLineData, setClassLineData] = useState(true);
    const [loading, setLoading] = useState(false);
    const normDist = new NormalDistribution(0, 1); 
    let zscoreAll;
    let zscoreSchool;
    if(props.teacherData.ZscoreAll.length > 0){
        zscoreAll = props.teacherData.ZscoreAll.reduce((a, b) => { return a + b }, 0);
        zscoreAll = Math.floor(zscoreAll/props.teacherData.ZscoreAll.length*100)/100;
        zscoreSchool = props.teacherData.ZscoreSchool.reduce((a, b) => { return a + b }, 0);
        zscoreSchool = Math.floor(zscoreSchool/props.teacherData.ZscoreAll.length*100)/100;
    }else{
        zscoreAll = 100;
        zscoreSchool = 100;
    }
    const gradeCut = AddValue.GradeCut();
    const barChartData = [];
    for(let i = 0; i < 9; i++){
        barChartData[i] = {
            'Grade': `${i+1}등급`,
            '학생수': props.teacherData.ZscoreAll.filter((item) => { return item > gradeCut[i] && item <= gradeCut[i+1] }).length
        }
    }

    let selectStudents = props.studentDatas.filter((item) => { return item.Selected === true });
    const lineChartData = [];
    if(classLineData){
        lineChartData.push({ 'id': `${props.classData.ClassName}`, 'data': [] });
        if(props.teacherData.MonthZscoreSection[11]){
            for(let i = 0; i < 12; i++){
                let tempSum = 0;
                // for(let j = 0; j < props.teacherData.MonthZscoreSection[i].length; j++){
                //     tempSum += normDist.cdf(props.teacherData.MonthZscoreSection[i][j].ZScore);
                // }

                // if(props.teacherData.MonthZscoreSection[i].length > 0){
                //     tempSum = tempSum/props.teacherData.MonthZscoreSection[i].length;
                // }else{   
                //     tempSum = null;
                // }
                if (props.teacherData.MonthZscoreSection[i].length > 0) {
                    for(let j = 0; j < props.teacherData.MonthZscoreSection[i].length; j++){
                        tempSum += normDist.cdf(props.teacherData.MonthZscoreSection[i][j].ZScore);
                    }
                }else{
                    if (i === 0) {
                        tempSum = null;
                    }else{
                        for(let j = 0; j < props.teacherData.MonthZscoreSection[i-1].length; j++){
                            props.teacherData.MonthZscoreSection[i] = props.teacherData.MonthZscoreSection[i-1]
                            tempSum += normDist.cdf(props.teacherData.MonthZscoreSection[i-1][j].ZScore);
                        } 
                    }
                }
                if(props.teacherData.MonthZscoreSection[i].length > 0){
                    tempSum = tempSum/props.teacherData.MonthZscoreSection[i].length;
                }else{
                    tempSum = null;
                }
                const nowDate = new Date(new Date().setDate(1));
                const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
                lineChartData[0].data.push({ 'x': `${month}월`, 'y': ChangeText.StaNine(tempSum) });
            }
        }
    }

    for(let i = 0; i < selectStudents.length; i++){   
        lineChartData.push({ 'id': `${selectStudents[i].Student_id.Name}`, 'data': [] });
        for(let j = 0; j < 12; j++){
            let tempSum = 0;
            // if(selectStudents[i].MonthZscoreSection[j].length > 0){
            //     for(let k = 0; k < selectStudents[i].MonthZscoreSection[j].length; k++){
            //         tempSum += normDist.cdf(selectStudents[i].MonthZscoreSection[j][k].ZScore);
            //     }
            //     tempSum = tempSum/selectStudents[i].MonthZscoreSection[j].length;
            // }else{
            //     tempSum = null;
            // }               
                
            if(selectStudents[i].MonthZscoreSection[j].length > 0){
                for(let k = 0; k < selectStudents[i].MonthZscoreSection[j].length; k++){
                    tempSum += normDist.cdf(selectStudents[i].MonthZscoreSection[j][k].ZScore);
                }
                tempSum = tempSum/selectStudents[i].MonthZscoreSection[j].length;
            }else{
                if (j === 0) {
                    tempSum = null;
                }else{
                     for(let k = 0; k < selectStudents[i].MonthZscoreSection[j-1]?.length; k++){
                        if (selectStudents[i].MonthZscoreSection[j].length === 0) {
                            selectStudents[i].MonthZscoreSection[j] = selectStudents[i].MonthZscoreSection[j-1]
                            tempSum += normDist.cdf(selectStudents[i].MonthZscoreSection[j-1][k].ZScore); 
                        }
                    }
                    if (selectStudents[i].MonthZscoreSection[j].length > 0) {
                        tempSum = tempSum/selectStudents[i].MonthZscoreSection[j].length;     
                    }else{
                        tempSum=null
                    }  
                }
            }
            const nowDate = new Date(new Date().setDate(1));
            const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + j)).getMonth() + 1;
            if(classLineData){
                lineChartData[i+1].data.push({ 'x': `${month}월`, 'y': ChangeText.StaNine(tempSum) });
            }else{
                lineChartData[i].data.push({ 'x': `${month}월`, 'y': ChangeText.StaNine(tempSum) });
            }
        }
    }   

    
const QuestionTimeBarChartData = [];
for (let i = 0; i < 12; i++) {
  const nowDate = new Date(new Date().setDate(1));
  const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
  QuestionTimeBarChartData.push({ x: `${month}월`, 시간: 0 ,y:0 });
};
if (students) {
    for (let i = 0; i < students.length; i++) {
      if (students[i]?.Student_id?.Questions.length > 0) {
        for (let j = 0; j < students[i].Student_id.Questions.length; j++) {
          const connectedAt = new Date(students[i].Student_id.Questions[j].CreatedAt);
          const month = connectedAt.getMonth() + 1;
          const time = students[i].Student_id.Questions[j].Time;
          // 해당 월의 x 값을 찾아서 누적 시간을 더해줍니다.
          const dataPoint = QuestionTimeBarChartData.find(data => data.x === `${month}월`);
          if (dataPoint) {
            dataPoint.시간 += time;
            dataPoint.y += time;
          };
        }
      };
    }

    for (let i = 0; i < QuestionTimeBarChartData.length; i++) {
        if (QuestionTimeBarChartData[i].시간 > 0) {
            QuestionTimeBarChartData[i].시간 = Math.floor(QuestionTimeBarChartData[i].시간 / 60)
            QuestionTimeBarChartData[i].y = Math.floor(QuestionTimeBarChartData[i].y)
        };
    };
  };


    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
           setLoading(false);
        }, [500]);
     }, []);
    
    return (
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>나의 리포트</h2>
                </Grid>
                <Grid container item xs={12} md={10} justifyContent='space-between'>
                    <Grid container item xs={12} md={5.9} className='teacher_propose_class_info' alignItems='center' sx={{padding: '16px !important'}}>
                        <Grid item xs={5} sx={{textAlign: 'center'}}>
                            <Avatar sx={{ width: '75px', height: '75px', margin: 'auto' }} src={props.teacherData.Profile}/>
                            <h2 style={{ marginBottom: '0px' }}>{props.teacherData.Name}</h2>
                            <span style={{ color: '#A4A4A4' }}>{props.classData.ClassName}</span>
                        </Grid>
                        <Grid item xs={7} className='teacher_dashboard_myreport'>
                            <p><b>{props.teacherData.Name}</b> 선생님의</p>
                            <p><b>{props.classData.ClassName}</b> 학생들은 평균</p>
                            <p>전국에서 <b>상위 {zscoreAll}%</b>,</p>
                            <p>학교에서 <b>상위 {zscoreSchool}%</b>의</p>
                            <p>백분위를 보이고 있습니다.</p>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info' style={{ padding: '16px !important'}}>
                        <div style={{ height: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <DashboardBarChart monthDiagnosis={()=>{""}} data={barChartData} x={'Grade'} y={'학생수'} ylabel={'학생수(명)'}/>
                        </div>
                    </Grid>
                </Grid>
              
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>클래스 리포트</h2>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div style={{ height: '300px', whiteSpace: 'nowrap', overflow: 'hidden', boxShadow:' 0px 6px 13px rgb(75 75 75 / 13%)' }}>
                        <QuestionTimeBarChart data={QuestionTimeBarChartData}  x={'x'} y={'시간'} ylabel={'풀이시간'}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='teacher_dashboard_classreport'>
                        <div style={{ height: '300px', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            {lineChartData[0] ? 
                            <DashboardLineChart data={lineChartData}/>:
                            <p style={{textAlign: 'center', paddingTop: '30px', margin: '0px'}}>선택된 데이터가 없습니다.</p>}
                        </div>
                        <Grid container>
                            <Grid item xs={12} className='classreport_students_select'>
                                <Chip 
                                    label={props.classData.ClassName}
                                    variant='outlined'
                                    color={classLineData ? 'warning' : 'default'}
                                    onDelete={() => setClassLineData(!classLineData)}
                                    avatar={<Avatar src={props.classData.ClassImage} />}
                                    deleteIcon={classLineData ? <RemoveCircleRounded /> : <AddCircleRounded />}
                                />
                                {selectStudents.map((selectStudent, i) => (
                                    <Chip label={selectStudent.Student_id.Name} variant='outlined' color='warning' onDelete={() => props.handleChangeSelect(selectStudent.StudentNo)} avatar={<Avatar src={selectStudent.Student_id.Profile} />} />
                                ))}
                            </Grid>
                            <Grid item xs={7} md={6} >
                                <SearchForm search={props.search} handleChange={props.handleChangeSearch} searchGuide='이름 / 학교 / 이메일로 검색' />
                            </Grid>
                            <Grid item xs={3} md={6} sx={{textAlign: 'right' }}>
                                <FormControl variant='standard' sx={{ minWidth: 120, textAlign: 'left' }}>
                                    <Select
                                    id='studentsSort'
                                    value={props.sortOption}
                                    onChange={props.handleChangeSort}
                                    >
                                        <MenuItem value={0}>기본</MenuItem>
                                        <MenuItem value={1}>이름</MenuItem>
                                        {/* <MenuItem value={2}>이름 내림차순</MenuItem> */}
                                        <MenuItem value={3}>전국</MenuItem>
                                        {/* <MenuItem value={4}>전국 내림차순</MenuItem> */}
                                        <MenuItem value={5}>학교</MenuItem>
                                        {/* <MenuItem value={6}>학교 내림차순</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div className='teacher_dashboard_student_list'>
                            <table style={{ marginTop: '30px' }}>
                                <tr>
                                    <th>
                                        <FormControlLabel
                                            control={<Checkbox color='warning' onChange={props.handleChangeSelectAll} checked={props.selectAll}/>}
                                        />
                                    </th>
                                    <th>No.</th>
                                    <th>프로필</th>
                                    <th>학생 정보</th>
                                    <th>이메일</th>
                                    <th>전국 상위 </th>
                                    <th>학교 상위 </th>
                                    <th></th>
                                </tr> 
                                {props.studentsFilter(props.studentDatas).length > 0 ?
                                props.studentsFilter(props.studentDatas).map((student, i) => (
                                <tr>
                                    <td>
                                        <FormControlLabel
                                            control={<Checkbox color='warning' checked={student.Selected ? true : false} onChange={() => props.handleChangeSelect(student.StudentNo)} />}
                                        />
                                    </td>
                                    <td>{props.studentDatas.length - i}</td>
                                    <td>
                                        <Avatar src={student.Student_id.Profile} />
                                    </td>
                                    <td>
                                        <p>{student.Student_id.Name}</p>
                                        <span>{student.Student_id.School_id.Name} · {student.Student_id.Attending === 1 ? ChangeText.Grade(student.Student_id.Grade) : ''} {ChangeText.Attending(student.Student_id.Attending)}</span>
                                    </td>
                                    <td>{student.Student_id.Email}</td>
                                    <td>{student.Zscore}%</td>
                                    <td>{student.ZscoreSchool}%</td>
                                    <td><Link href={`${location.pathname}/${student.StudentNo}`}>상세보기</Link></td>
                                </tr> 
                                )):
                                <tr>
                                    <td colSpan={9}>해당 클래스에 조건에 맞는 학생이 없습니다.</td>
                                </tr> 
                                }
                            </table>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default TeacherDashboard;