import React, { useState } from 'react';
import { Button, Grid, Link } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';

const StudentQuestionInfo = (props) => {
    const [openQuestion, setOpenQuestion] = useState(false);
    const handleClickView = () => {
        setOpenQuestion(!openQuestion);
    };
    
    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 클래스 정보</h3>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>No.</th><th>클래스 이름</th><th>수강</th><th>대기</th><th>중단</th><th>숙제</th><th>생성일시</th><th>삭제</th>
                        </tr>
                        {props.classDatas.map((classData, i) => (
                            i < 5 || openQuestion ?
                            <tr>
                                {/* <td><Link href={`/admin/class/${classData.ClassNo}`}>{classData.ClassNo}</Link></td> */}
                                <td>{classData.ClassNo}</td>
                                <td>{classData.ClassName}</td>
                                <td>{classData.Students.filter((item) => {return item.Connect === 2}).length}</td>
                                <td>{classData.Students.filter((item) => {return item.Connect === 1}).length}</td>
                                <td>{classData.Students.filter((item) => {return item.Connect === 3}).length}</td>
                                <td>{classData.Homeworks.length}</td>
                                <td>{ChangeText.YyyyMmDdHhMmSs(classData.CreatedAt)}</td>
                                <td className='info_table_button_cell'><Button variant='contained' color='error'>삭제</Button></td>
                            </tr>
                            : null
                        ))}
                        {props.classDatas.length > 5 ? 
                            !openQuestion ? 
                                <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>전체 보기 <ExpandMore fontSize='small'/></td></tr>
                            :   <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>닫기 <ExpandLess fontSize='small'/></td></tr>
                        :   props.classDatas.length === 0 ?
                                <tr><td colSpan={8} className='info_table_all_line'>문제 풀이 정보가 없습니다.</td></tr>
                            : null}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default StudentQuestionInfo;