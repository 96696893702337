import React from 'react';
import AdminQuestionPresenter from './AdminQuestionPresenter';

const AdminQuestionContainer = () => {
  return (
    <AdminQuestionPresenter />
  );
};

export default AdminQuestionContainer;
