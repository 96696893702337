import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Avatar, Link } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';

const ManagerDetailInfo = (props) => {
    const navigate = useNavigate();

    const manager = props.manager;
    const questionCount = manager.Questions.length;
    const answerCount = manager.Questions.filter(q=>q.Check === 1).length;

    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 관리자 상세 정보</h3>
                </Grid>
                <Grid className='info_button_group'>
                    <Button variant='contained' color='inherit' onClick={props.handleClickOpenResetPassword} disabled={manager.Status === 7 ? true : false}>비밀번호 변경</Button>
                    <Button variant='contained' color='warning' onClick={() => navigate(`/admin/manager/${manager._id}/update`)} disabled={manager.Status === 7 ? true : false}>수정</Button>
                    <Button variant='contained' color='error' disabled={manager.Status === 7 ? true : false}>삭제</Button>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>ID</th><td>{manager._id}</td>
                            <th>이름</th>
                            <td className='info_table_button_cell'>
                                <div className='user_profile'>
                                    <Avatar src={manager.Profile}/>
                                </div>
                                <div className='user_profile'>{manager.Name}</div>
                            </td>
                        </tr>
                        <tr>
                            <th>이메일</th><td>{manager.Email}</td>
                            <th>전화번호</th><td>{manager.Phone}</td>
                        </tr>
                        <tr>
                            <th>로그인</th><td>{ChangeText.Login(manager.Login)}</td>
                            <th>현재 상태</th><td>{ChangeText.State(manager.State)}</td>
                        </tr>
                        <tr>
                            <th>회원 구분</th><td>{ChangeText.Status(manager.Status)}</td>
                            <th>회원 번호</th><td>{manager.UserNo}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ManagerDetailInfo;