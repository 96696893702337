import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const Term = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [term, setTerm] = useState([{
        _id: '',
        Content: '',
        CreatedAt: ''
    },{
        _id: '',
        Content: '',
        CreatedAt: ''
    }]);
    useEffect(() => {
        const loadNotices = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/etc/term`, config).then((Response) => {
                setTerm(Response.data);
            }).catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadNotices();
    }, []);
    return(
        <div className='agree' dangerouslySetInnerHTML={{ __html: term[1].Content }}></div>
    );
};

export default Term;