import React from 'react';
import AdminMockExamPresenter from './AdminMockExamPresenter';

const AdminMockExamContainer = () => {
  return (
    <AdminMockExamPresenter />
  );
};

export default AdminMockExamContainer;
