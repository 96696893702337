import { Box,Pagination,Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { AcademyStudentSearchForm } from "components/form";

const StudentsPagination = (props) => {
  const navigate=useNavigate()
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const pageSize = 15; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = props.student?.slice(startIndex, endIndex);
  const count =Math.ceil(props.student?.length / pageSize)

  return (
    <Box sx={{ height: "1350px",background:"#FFFFFF",borderRadius:"16px",display:"flex",flexFlow:"column",justifyContent:"space-between"}}>
      <div>
        <h1 style={{fontSize:"27px",marginLeft:"38px",marginTop:"30px"}}>{props.title}</h1>
        <div style={{marginLeft:"32px"}}>
         <AcademyStudentSearchForm
          search={props.search}
          handleChange={props.handleChangeSearch}
          searchGuide="이름 / 이메일로 검색"
         />
        </div>
       {visibleData ?
        (
          visibleData.length > 0 ?
            visibleData?.map((list, i) => (
              list.Connect === 2 ?
              <div style={{display: "flex", alignItems: "center",margin: "24px 0 24px 38px "}} key={i}>
                <Avatar className="list_click" src={list.Student_id.Profile} sx={{width:"46px",height:"46px"}} onClick={()=>navigate(`/academy/student/${list.StudentNo}`)}/>
                <div style={{ margin: "0 13px",height:"42px" }}>
                  <span onClick={()=>navigate(`/academy/student/${list.StudentNo}`)} style={{cursor:"pointer",color:"#1976d2",fontSize:"16px"}}>{list.Student_id.Name}</span>
                  <p style={{ margin: "5px 0",fontSize:"16px",color:"# #A4A4A4" }}>{list.Student_id.Email}</p>
                </div>
              </div>:""
              ))
              :
          <h3 style={{textAlign:"center",fontSize:"24px",marginTop:"300px"}}>검색한 학생이 없습니다.</h3>
        )
          :
          <h3 style={{textAlign:"center",fontSize:"24px",marginTop:"300px"}}>클래스에 참여한 학생이 없습니다.</h3>
          }
        </div>
      <Pagination
        sx={{marginBottom:"20px",width:"100%",'ul':{justifyContent:"center"}}}
        count={count} // 전체 페이지 수
        page={currentPage} // 현재 페이지
        onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
        color='warning'
      />
      </Box>
    
  );
};

export default StudentsPagination;
