import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, FormGroup, FormControlLabel, Switch, LinearProgress } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';

const SchoolDetailInfo = (props) => {
    const navigate = useNavigate();
    const school = props.school;

    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 학교 상세 정보</h3>
                </Grid>
                <Grid className='info_button_group'>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch checked={school.State === 1 ? true : false} disabled={props.updating} onChange={props.handleChangeState}/>}
                            label={ChangeText.SchoolState(school.State)}
                        />
                    </FormGroup>
                    <Button variant='contained' color='warning' onClick={() => navigate(`/admin/school/${school._id}/update`)}>수정</Button>
                    <Button variant='contained' color='error' onClick={props.handleClickOpenDelete}>삭제</Button>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>ID</th><td>{school._id}</td>
                            <th>학교 코드</th><td>{school.NEIS_code}</td>
                        </tr>
                        <tr>
                            <th>이름</th><td>{school.Name}</td>
                            <th>주소</th><td>{school.Local} {school.County}</td>
                        </tr>
                        <tr>
                            <th>순위</th><td>{school.WooroRank}</td>
                            <th>점수</th><td>{school.WooroZscore}</td>
                        </tr>
                        <tr>
                            <th>학교 상태</th><td>{props.updating ? <LinearProgress color='inherit'/> : ChangeText.SchoolState(school.State)}</td>
                            <th colSpan={2}></th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default SchoolDetailInfo;