import React from 'react';
import { Grid, FormControl, OutlinedInput, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, Remove, Close } from '@mui/icons-material';
import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';

const EvaluationUpdateView = (props) => {
    const navigate = useNavigate();
    const evaluation = props.evaluation;
    const setEvaluation = props.setEvaluation;

    const handleClickScoreAdd = () => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.Scores.push('');
        setEvaluation(changeEvaluation);
    };

    const handleClickLabelAdd = () => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.Labels.push('');
        changeEvaluation.Score.LabelsScores.push([]);
        setEvaluation(changeEvaluation);
    };

    const handleClickScoreRemove = (key) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.Scores.splice(key, 1);
        for(let i = 0; i < changeEvaluation.Score.LabelsScores.length; i++){
            changeEvaluation.Score.LabelsScores[i].splice(key, 1);
        }
        setEvaluation(changeEvaluation);
    };

    const handleClickLabelRemove = (key) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.Labels.splice(key, 1);
        changeEvaluation.Score.LabelsScores.splice(key, 1);
        setEvaluation(changeEvaluation);
    };

    const handleChangeScore = (e, key) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.Scores[key] = e.target.value;
        setEvaluation(changeEvaluation);
    };

    const handleChangeLabel = (e, key) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.Labels[key] = e.target.value;
        setEvaluation(changeEvaluation);
    };

    const handleChangeLabelsScores = (e, key1, key2) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.Score.LabelsScores[key1][key2] = e.target.value;
        setEvaluation(changeEvaluation);
    };

    const handleClickElementAdd = () => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.EvaluationElement.push({ Label: '', Detail: [''] });
        setEvaluation(changeEvaluation);
    };
    const handleClickElementRemove = () => {
        if(evaluation.EvaluationElement.length > 1){
            let changeEvaluation = { ...evaluation };
            changeEvaluation.EvaluationElement.pop();
            setEvaluation(changeEvaluation);
        }else{
            return false;
        }
    };
    const handleChangeElementLabel = (e, key) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.EvaluationElement[key].Label = e.target.value;
        setEvaluation(changeEvaluation);
    };
    const handleClickElementDetailAdd = (key) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.EvaluationElement[key].Detail.push('');
        setEvaluation(changeEvaluation);
    };
    const handleClickElementDetailRemove = (key, key2) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.EvaluationElement[key].Detail.splice(key2, 1);
        setEvaluation(changeEvaluation);
    };
    const handleChangeElementLabelDetail = (e, key, key2) => {
        let changeEvaluation = { ...evaluation };
        changeEvaluation.EvaluationElement[key].Detail[key2] = e.target.value;
        setEvaluation(changeEvaluation);
    };
    return (
        <div className='content_section'>
        <Grid container justifyContent='center'>
            <Grid xs={12} sm={9} md={6} className='teacher_class_create'>
                <h2>수행평가 생성</h2>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                        <p>수행평가 이름 *</p>
                    </Grid>
                </Grid>
                <FormControl fullWidth style={{ marginBottom: '16px'}}>
                    <OutlinedInput
                        color='warning'
                        type='text'
                        value={evaluation.Title}
                        onChange={(e) => setEvaluation({ ...evaluation, Title: e.target.value })}
                        placeholder='수행평가 이름을 입력해 주세요.'
                    />
                </FormControl>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                        <p>시작 일시 *</p>
                    </Grid>
                </Grid>
                <FormControl fullWidth style={{ marginBottom: '16px'}}>
                    <OutlinedInput
                        type='datetime-local'
                        color='warning'
                        value={YyyyMmDdHhMmSs(evaluation.StartDate)}
                        onChange={(e) => setEvaluation({ ...evaluation, StartDate: e.target.value })}
                    />
                </FormControl>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                        <p>성취 기준</p>
                    </Grid>
                </Grid>
                <FormControl fullWidth style={{ marginBottom: '16px'}}>
                    <OutlinedInput
                        color='warning'
                        type='text'
                        rows={3}
                        multiline
                        value={evaluation.Standard}
                        onChange={(e) => setEvaluation({ ...evaluation, Standard: e.target.value })}
                        placeholder='성취 기준을 입력해주세요.'
                    />
                </FormControl>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                        <p>활동</p>
                    </Grid>
                </Grid>
                <FormControl fullWidth style={{ marginBottom: '16px'}}>
                    <OutlinedInput
                        color='warning'
                        type='text'
                        value={evaluation.Activity}
                        onChange={(e) => setEvaluation({ ...evaluation, Activity: e.target.value })}
                        placeholder='활동을 입력해주세요.'
                    />
                </FormControl>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                        <p>평가 기준</p>
                    </Grid>
                </Grid>
                <div style={{ width: '100%', overflow: 'auto' }}>
                    <table style={{ width: '100%', whiteSpace: 'nowrap', tableLayout: 'fixed' }}>
                        <tr>
                            <td style={{ padding: 0 }}>평가항목</td>
                            {evaluation.Score.Scores.map((item, i) => (
                            <td key={i} style={{ padding: 0 }}>
                                <TextField variant='standard' value={item} fullWidth onChange={(e) => handleChangeScore(e, i)} className='evaluation_score_table_input'/>
                            </td>
                            ))}
                            <td style={{ padding: 0 }}>
                                <IconButton size='small' onClick={handleClickScoreAdd}>
                                    <Add />
                                </IconButton>
                            </td>
                        </tr>
                        {evaluation.Score.Labels.map((item, i) => (
                        <tr>
                            <td style={{ padding: 0, fontSize: '14px' }} key={i}>
                                <TextField variant='standard' value={item} fullWidth onChange={(e) => handleChangeLabel(e, i)} className='evaluation_score_table_input'/>
                            </td>
                            {evaluation.Score.Scores.map((_, j) => (
                            <td style={{ padding: 0 }} key={j}>
                                <TextField variant='standard' value={evaluation.Score.LabelsScores[i] ? evaluation.Score.LabelsScores[i][j] ? evaluation.Score.LabelsScores[i][j] : '' : ''} fullWidth onChange={(e) => handleChangeLabelsScores(e, i, j)} className='evaluation_score_table_input'/>
                            </td>
                            ))}
                            <td style={{ padding: 0 }}>
                                <IconButton size='small' onClick={() => handleClickLabelRemove(i)}>
                                    <Remove />
                                </IconButton>
                            </td>
                        </tr>
                        ))}
                        <tr>
                            <td style={{ padding: 0 }}>
                                <IconButton size='small' onClick={handleClickLabelAdd}>
                                    <Add />
                                </IconButton>
                            </td>
                            {evaluation.Score.Scores.map((_, i) => (
                                <td style={{ padding: 0 }} key={i}>
                                    <IconButton size='small' onClick={() => handleClickScoreRemove(i)}>
                                        <Remove />
                                    </IconButton>
                                </td>
                            ))}
                            <td style={{ padding: 0 }}>
                                <IconButton size='small' onClick={() => props.setEvaluation({ ...evaluation, Score: { Labels: [], Scores: [], LabelsScores: [] }})}>
                                    <Close />
                                </IconButton>
                            </td>
                        </tr>
                    </table>
                </div>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                        <p>평가 요소</p>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClickElementRemove} disabled={Boolean(evaluation.EvaluationElement.length < 2)} color='error'>
                            <Remove/>
                        </IconButton>
                        <IconButton onClick={handleClickElementAdd} color='info'>
                            <Add/>
                        </IconButton>
                    </Grid>
                </Grid>
                {evaluation.EvaluationElement.map((item, i) => (
                <Grid container alignItems={'center'} key={i}>
                    <Grid item xs={12} style={{ padding: 0 }}>
                        <FormControl fullWidth style={{ marginBottom: '16px'}}>
                            <OutlinedInput
                                color='warning'
                                type='text'
                                value={item.Label}
                                onChange={(e) => handleChangeElementLabel(e, i)}
                                placeholder={`평가요소${i+1}`}
                            />
                        </FormControl>
                    </Grid>
                    {item.Detail.length > 0 ?
                    item.Detail.map((item2, j) => (
                    <Grid container key={j} alignItems={'center'} style={{ padding: 0 }}>
                        <Grid item xs={1} style={{ padding: 0 }}>
                            {j === 0 ?
                            <IconButton style={{ marginBottom: '16px'}} color='info' onClick={() => handleClickElementDetailAdd(i)}>
                                <Add/>
                            </IconButton>:
                            <IconButton style={{ marginBottom: '16px'}} color='error' onClick={() => handleClickElementDetailRemove(i, j)}>
                                <Remove/>
                            </IconButton>
                            }
                        </Grid>
                        <Grid item xs={11} style={{ padding: 0 }}>
                            <FormControl fullWidth style={{ marginBottom: '16px'}}>
                                <OutlinedInput
                                    color='warning'
                                    type='text'
                                    value={item2}
                                    onChange={(e) => handleChangeElementLabelDetail(e, i, j)}
                                    placeholder={`평가 요소${i+1}의 세부 항목${j+1}`}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    )):
                    <Grid container alignItems={'center'} style={{ padding: 0 }}>
                        <Grid item xs={1} style={{ padding: 0 }}>
                            <IconButton style={{ marginBottom: '16px'}} onClick={() => handleClickElementDetailAdd(i)}>
                                <Add color='info'/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={11} style={{ padding: 0 }}>
                            <FormControl fullWidth style={{ marginBottom: '16px'}}>
                                <OutlinedInput
                                    color='warning'
                                    type='text'
                                    value={item.Detail[0]}
                                    onChange={(e) => handleChangeElementLabelDetail(e, i, 0)}
                                    placeholder={`평가 요소${i+1}의 세부 항목1`}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>}
                </Grid>
                ))}
                <Grid container justifyContent={'flex-end'} spacing={1} alignItems={'center'}>
                    <Grid item>
                        <Button color='secondary' onClick={() => navigate(-1)}>취소</Button>
                    </Grid>
                    <Grid item style={{ paddingRight: 0 }}>
                        {props.updating ?
                        <CircularProgress color='warning'/>
                        : <Button variant='contained' disableElevation color='warning' onClick={props.handleClickUpdate} disabled={props.updating || (!evaluation.Title || !evaluation.StartDate)}>수정</Button>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </div>
    );
};

export default EvaluationUpdateView;