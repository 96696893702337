import React, { useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { ExpandMore, LocalActivityOutlined, ConfirmationNumberOutlined } from '@mui/icons-material';
import { YyyyMmDd, YyMmDd, YyyyMmDdHhMmSs, Grade, Attending } from 'components/function/ChangeText';

const TicketListView = (props) => {
    const [panel, setPanel] = useState('panel1a-header_0');
    return (
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>클래스 정보</h2>
                </Grid>
                <Grid container item xs={12} md={10} justifyContent='space-between'>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info'>
                        <table>
                            <tr>
                                <th>클래스 이름</th><td>{props.classData.ClassName}</td>
                            </tr>
                            <tr>
                                <th>담당 선생님</th><td>{props.teacherData.Name}</td>
                            </tr>
                            <tr>
                                <th>이메일</th><td>{props.teacherData.Email}</td>
                            </tr>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info propose_student_count'>
                        <table>
                            <tr>
                                <th>보유 중</th>
                                <th>소멸 예정</th>
                            </tr>
                            <tr>
                                <td><p>{props.ticketDatas[0] && props.ticketDatas[0].Count ? (props.ticketDatas[0].Count - props.ticketDatas[0].Students.length) - props.teacherTicketCount : 0}</p></td>
                                <td><p>{props.ticketDatas[0] && props.ticketDatas[0].Count ? YyyyMmDd(props.ticketDatas[0].Order_id.NextOrder) : '-'}</p></td>
                            </tr>
                        </table>
                    </Grid>
                    <Grid item xs={12} className='diagnosis_result_title' style={{ padding: '0px'}}>
                        <h2>사용 내역</h2>
                    </Grid>
                    <Grid item xs={12} style={{ padding: '0px' }}>
                        {props.ticketDatas[0] && props.ticketDatas[0].Count ?
                            props.ticketDatas.map((item, i) => (
                            <Accordion
                                expanded={panel===`panel1a-header_${i}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls={`panel1a-content_${i}`}
                                    id={`panel1a-header_${i}`}
                                    onClick={() => setPanel(`panel1a-header_${i}`)}
                                >
                                    <Grid container alignItems={'center'} >
                                        <Grid item xs={1}>
                                            {new Date(item.Order_id.NextOrder) > new Date() ?
                                            <LocalActivityOutlined />:
                                            <ConfirmationNumberOutlined color='disabled'/>}
                                        </Grid>
                                        <Grid item xs={5} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                            {new Date(item.Order_id.NextOrder) > new Date() ?
                                            <><b>{YyMmDd(item.Order_id.NextOrder)}</b> 만료 예정</>:
                                            <><b>{YyMmDd(item.Order_id.NextOrder)}</b> 만료</>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {new Date(item.Order_id.NextOrder) > new Date() ?
                                            ( item.Count ? 
                                            `${(item.Count - item.Students.length) - props.teacherTicketCount}개 사용 가능`:
                                            '0개 사용 가능'
                                            ):
                                            `${(item.Count - item.Students.length) - props.teacherTicketCount}개 만료됨`
                                            }
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={5}>{YyMmDd(item.Order_id.createdAt)} 결제</Grid>
                                        <Grid item xs={6}>우로매쓰 Basic Teacher 구독</Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={10} >
                                            <Grid style={{ marginBottom: '8px' }}><b>사용 내역</b></Grid>
                                            {item.Students.length > 0 ?
                                            <Grid container>
                                                <Grid item xs={2} style={{ padding: 0 }}><b>번호</b></Grid>
                                                <Grid item xs={5} style={{ padding: 0 }}><b>이름</b></Grid>
                                                <Grid item xs={5} style={{ padding: 0 }}><b>날짜</b></Grid>
                                                {item.Students.map((student, i) => (
                                                <>
                                                <Grid item xs={2} style={{ padding: '4px 0px'}}>
                                                    {i+1}
                                                </Grid>
                                                <Grid item xs={5} style={{ padding: '4px 0px' }}>
                                                    {student.Student_id.Name}<br/>
                                                    <span style={{ color: '#A4A4A4' }}>{student.Student_id.School_id.Name} · {student.Student_id.Attending === 1 ? Grade(student.Student_id.Grade) : Attending(student.Student_id.Attending) }</span>
                                                </Grid>
                                                <Grid item xs={5} style={{ padding: '4px 0px' }}>
                                                    {YyyyMmDdHhMmSs(student.CreatedAt)}
                                                </Grid>
                                                </>
                                                ))}
                                            </Grid>:
                                            <Grid item xs={10}>
                                                사용 내역이 없습니다.
                                            </Grid>}
                                        </Grid>
                                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                                            {new Date(item.Order_id.NextOrder) > new Date() ? 
                                            <Button color='warning' variant='contained' disableElevation onClick={() => props.setOpen(true)}>응시권 배부</Button>:
                                            <Button color='warning' variant='contained' disableElevation disabled>응시권 만료</Button>}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            )):
                            <Grid>
                                사용 내역이 없습니다.
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TicketListView;