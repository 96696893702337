import { useNavigate, useParams } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { East } from "@mui/icons-material";
import class3 from 'img/class3.png'
import homeworkmanageinfo from 'img/homeworkmanageinfo.png'
import homework3 from 'img/homework3.png'
import homework4 from 'img/homework4.png'

const HomeworkAssignInfo = (props) => {
  const navigate = useNavigate();
  const { ClassNo } = useParams();
  return (
    <Grid className="diagnosisInfo">
      <Grid container justifyContent="center" spcing={10}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={5.5}>
            <>
              <h1 style={{ fontSize: "40px", textAlign: "center" }}>
                현재 클래스에 참여한 학생이 없습니다.
              </h1>
              <h2 style={{ fontSize: "20px", textAlign: "center" }}>
                클래스에 참여한 학생이 있어야 사용할 수 있는 서비스 입니다.
              </h2>
            </>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h2>학생 신청 관리</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">1.&nbsp;</h3>
                <div  style={{ marginBottom: "5px" }}c>
                  <h3 style={{ marginBottom: "5px",fontWeight:"500" }} >
                    학생 신청 관리 페이지 접속, 수락 버튼을 통해 대기 중인 학생
                    클래스에 추가!
                  </h3>
                  <span style={{ fontWeight: "500",paddingBottom:"10px"}}>
                    {
                      "(학생이 직접 클래스 참여 신청을 해야만 대기인원에 추가됩니다.)"
                    }
                  </span>
                </div>
              </div>
              <div>
              <img
                src={class3}
                width="100%"
                alt="img"
                style={{ margin: "20px 0 0 0" }}
                className="info-img"
              />
              </div>
              
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12} >
              <h2>숙제내기</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <h3 className="info_text">1. 단원 선택 후 클릭</h3>
              <img
                src={homework4}
                width="100%"
                alt="img"
                className="info-img"
                style={{ margin: "20px 0" }}
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">2.&nbsp;</h3>
                <h3 className="info_text"> 난이도, 문제 수, 숙제 기한 설정 후 완료 클릭</h3>
              </div>
              <img
                src={homework3}
                width="100%"
                alt="img"
                style={{ margin: "20px 0" }}
              ></img>
            </Grid>
          </Grid>

          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12} mt={6}>
              <h2>숙제관리</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">1.&nbsp; </h3>
                <h3 style={{ fontWeight: "500" }}>
                  출제한 숙제를 클릭하여 문제 보기, 숙제 출력 등 세부정보를
                  확인할 수 있습니다.
                </h3>
              </div>
              <img
                src={homeworkmanageinfo}
                width="100%"
                alt="img"
                className="info-img"
                style={{ margin: "20px 0" }}
              ></img>
            </Grid>
          </Grid>
            <Button
              variant="contained"
              size="small"
              color="inherit"
              style={{ padding: "7px", width: "100%", marginTop: "24px" }}
              className="button-wooro"
              endIcon={<East />}
              onClick={() => {
                navigate(`/teacher/${ClassNo}/propose`);
              }}
              target="_blank"
            >
              학생 신청 바로가기
            </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeworkAssignInfo;
