import React from 'react';
import { Grid, Button, Avatar } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';

const TeacherProposeList = (props) => {
    const studentApply = props.classData.Students.filter((item) => {return item.Connect === 1}).length;
    const studentPermit = props.classData.Students.filter((item) => {return item.Connect === 2}).length;
    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>클래스 정보</h2>
                </Grid>
                <Grid container item xs={12} md={10} justifyContent='space-between'>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info'>
                        <table>
                            <tr>
                                <th>클래스 이름</th><td>{props.classData.ClassName}</td>
                            </tr>
                            <tr>
                                <th>담당 선생님</th><td>{props.teacherData.Name}</td>
                            </tr>
                            <tr>
                                <th>이메일</th><td>{props.teacherData.Email}</td>
                            </tr>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info propose_student_count'>
                        <table>
                            <tr>
                                <th>수강</th>
                                <th>대기</th>
                            </tr>
                            <tr>
                                <td><p>{studentPermit}</p></td>
                                <td><p>{studentApply}</p></td>
                            </tr>
                        </table>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>학생 목록</h2>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='student_propose_list'>
                        <table>
                            {props.classData.Students.map((student, i) => (
                            <tr>
                                <td>
                                    {student.Connect === 1 ?
                                    <>
                                    <Button size='small' variant='outlined' disabled={props.updating} color='primary' onClick={() => props.handleClickStudentPermit(i)}>수락</Button>
                                    <Button size='small' variant='outlined' disabled={props.updating} color='warning' onClick={() => props.handleClickStudentReject(i)}>거절</Button>
                                    </>:
                                    student.Connect === 2 ?
                                    <Button size='small' variant='outlined' disabled={props.updating} color='error' onClick={() => props.handleClickStudentDelete(i)}>삭제</Button>:
                                    <Button size='small' variant='outlined' disabled={true}>-</Button>}
                                </td>
                                <td>
                                    <Avatar src={student.Student_id.Profile} sx={{ backgroundColor: '#FBFBFB' }}/>
                                </td>
                                <td>
                                    <p>{student.Student_id.Name}</p>
                                    {/* <span>{student.Student_id.School_id.Name} · {student.Student_id.Attending === 1 ? ChangeText.Grade(student.Student_id.Grade) : ''} {ChangeText.Attending(student.Student_id.Attending)}</span> */}
                                    <span>{student.Student_id.Email}</span>
                                </td>
                                <td>{student.ProposedAt ? ChangeText.ApplyTime(student.ProposedAt) : '-'}</td>
                                <td>
                                    {student.Connect === 1 ?
                                    '수업 대기':
                                    student.Connect === 2 ?
                                    '수업 진행':
                                    student.Connect === 3 ?
                                    '수업 중단':
                                    student.Connect === 4 ?
                                    '수업 종료':
                                    '-'
                                    }
                                </td>
                            </tr> 
                            ))}
                        </table>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default TeacherProposeList;