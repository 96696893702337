import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from 'components/teacher/layout';
import { TeacherQuestionViewDetail } from 'components/teacher/class/question';

const QuestionViewDetail = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { ClassNo, QuestionNo } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const loginKey = cookies.LoginKey;

    const [questionData, setQuestionData] = useState({
        Answer: '',
        Choices: ['','','','',''],
        Info: { Counting: '', Correct: '', Time: '' },
        InfoClass: { Counting: '', Correct: '', Time: '' },
        Content: [' '],
        Question: '',
        CommentaryPicture: [],
        _id: ''
    });
    const [studentDatas, setStudentDatas] = useState([{
        Name: '',
        Check: '',
        CreatedAt: '',
        Select: '',
        Time: '',
        Status: '',
        Memo: ''
    }]);
    useEffect(() => {
        const loadDatas = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/teacher/question/${ClassNo}/detail/${QuestionNo}/${loginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    const loadStudents = [...Response.data.ClassStudents];
                    let tempStudents = [];
                    let tempCounting = 0;
                    let tempCorrect = 0;
                    let tempTime = 0;
                    for(let i = 0; i < loadStudents.length; i++){
                        for(let j = 0; j < loadStudents[i].Student_id.Questions.length; j++){
                            const tempQuestion = loadStudents[i].Student_id.Questions[j];
                            tempStudents.push({
                                Name: loadStudents[i].Student_id.Name,
                                Check: tempQuestion.Check,
                                CreatedAt: tempQuestion.CreatedAt,
                                Select: tempQuestion.Select,
                                Time: tempQuestion.Time,
                                Status: tempQuestion.Status,
                                Memo: tempQuestion.Memo
                            });
                            tempCounting += 1;
                            if(tempQuestion.Check === 1){
                                tempCorrect += 1;
                                tempTime += tempQuestion.Time;
                            }
                        }
                    }
                    tempStudents.sort(function (a, b) {
                        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
                    });
                    let loadQuestion = Response.data.Question;
                    loadQuestion.InfoClass = { Counting: tempCounting, Correct: tempCorrect, Time: tempTime };
                    setStudentDatas(tempStudents);
                    setQuestionData(loadQuestion);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                }
            }).catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadDatas();
    }, []);
    return(
        <Layout>
            <TeacherQuestionViewDetail
                questionData={questionData}
                studentDatas={studentDatas}
            />
        </Layout>
    );
};

export default QuestionViewDetail;