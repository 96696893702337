import React from 'react';
import { Grid, Link } from '@mui/material';
import { useCookies } from 'react-cookie';

import * as ChangeText from 'components/function/ChangeText';
import { useParams } from 'react-router-dom';


const TeacherQuestionView = (props) => {
   const [cookies] = useCookies(['ClassName']);
   const { ClassNo, Section } = useParams();
   return(
      <div className='content_section'>
         <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} className='diagnosis_result_title'>
               <h2>{ChangeText.Part(Section)} &gt; {ChangeText.MiddlePart(Section)} &gt; {ChangeText.Section(Section)}</h2>
            </Grid>
            <Grid item xs={12} md={10}>
               <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                  <table >
                     <tr>
                        <th rowSpan={2}>No.</th>
                        <th rowSpan={2}>풀이수</th>
                        <th colSpan={2}>정답률</th>
                        <th colSpan={2}>평균풀이시간</th>
                        <th rowSpan={2}></th>
                     </tr>
                     <tr>
                        <th>{cookies.ClassName}</th>
                        <th>우로매쓰</th>
                        <th>{cookies.ClassName}</th>
                        <th>우로매쓰</th>
                     </tr> 
                     {props.questionDatas.map((questionData, i) => (
                     <tr>
                        <td>{(questionData.Question - questionData.Section*10000)}</td>
                        <td>{questionData.InfoClass.Counting}</td>
                        <td>{questionData.InfoClass.Counting ? ChangeText.Percent(questionData.InfoClass.Correct/questionData.InfoClass.Counting) : '0%'}</td>
                        <td>{questionData.Info.Counting ? ChangeText.Percent(questionData.Info.Correct/questionData.Info.Counting) : '0%'}</td>
                        <td>{ChangeText.QuestionMinute(questionData.InfoClass.Time/questionData.InfoClass.Correct)}</td>
                        <td>{ChangeText.QuestionMinute(questionData.Info.Time/questionData.Info.Correct)}</td>
                        <td><Link href={`/teacher/${ClassNo}/question/${Section}/${questionData.Question}`}>상세보기</Link></td>
                     </tr> 
                     ))}
                  </table>
               </div>
            </Grid>
         </Grid>
      </div>
   );
};

export default TeacherQuestionView;