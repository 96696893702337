import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CheckCircle } from '@mui/icons-material';

const TeacherPriceSuccessApp = (props) => {
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const PriceSuccess = async() => {
        if(location.search){
            const locationSplit = location.search.split('&');
            let key = [];
            for(let i = 0; i < locationSplit.length; i++){
                key.push(locationSplit[i].split('=')[1]);
            }
            
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({
                customerKey: key[0],
                authKey: key[1]
            });
            await axios.post(`/api/price`, body, config).then((Response) => {
                if(Response.data.Message){
                    setLoading(false);
                    enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                }else{
                    navigate(`/teacher/price/fail/app`);    
                }
            }).catch((Error) => {
                navigate(`/teacher/price/fail/app`);
            });
        }else{
            navigate(`/teacher/price/fail/app`);
        }
    };
    useEffect(() => {
        PriceSuccess();
    }, []);
    return (
        loading ?
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <CircularProgress color='inherit' />
            <div style={{ position: 'absolute', top: '55%', fontSize: '17px'}}>결제 진행중 입니다.</div>
        </Backdrop> :
        <div style={{ minHeight: '90vh', padding: '30% 0' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <CheckCircle sx={{ fontSize: 120, color: 'green' }} />
            </div>
            <div style={{ width: '100%', textAlign: 'center', fontSize: '17px'}}>결제에 성공했습니다</div>
        </div>
    );
};

export default TeacherPriceSuccessApp;