import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Avatar, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { Layout } from 'components/admin/layout';
import { SearchForm } from 'components/form';
import * as ChangeText from 'components/function/ChangeText';


const ManagerList = () => {
    const pages = [
        { title: '회원 관리'},
        { title: '관리자 목록'}
    ];
    const title = { main: '관리자 목록', sub: 'user'};
    const [search, setSearch] = useState('');
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cookies] = useCookies(['LoginKey']);
    
    const navigate = useNavigate();
    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    // 컬럼
    const columns = [
        { field: 'id', headerName: 'No.', width: 70 },
        { field: 'Profile', headerName: '프로필', width: 70, renderCell: (params)=>(<Avatar className='list_click' src={params.row.Profile}/>) } ,
        { field: 'Name', headerName: '이름', width: 100 , renderCell: (params)=>(<div className='list_click'>{params.row.Name}</div>) },
        { field: 'Status', headerName: '관리자 권한', width: 100 },
        { field: 'Email', headerName: '이메일', width: 200 },
        { field: 'Login', headerName: '로그인', width: 70 },
        { field: 'Phone', headerName: '전화번호', width: 140 },
        { field: 'CreatedAt', headerName: '가입일시', width: 230 }
    ];

    // 필터
    const filterManagers = (datas) => {
        datas.sort();
        datas = datas.filter((data) => {
            return data.Name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    data.Email.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
        });
        return datas
    };

    const handleClickCell = (event) => {
        if(event.field === 'Profile' || event.field === 'Name'){
            navigate(`/admin/manager/${managers[event.row.id-1].Link}`);
        }else{
            return false;
        }
    }

    // 데이터 로드
    useEffect(() => {
        const loadManagers = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/manager/${cookies.LoginKey}`, config).then((Response) => {
                console.log(Response)
                const loadData = Response.data;
                let managerDatas = [];
                for(let i = 0; i < loadData.length; i++){
                    managerDatas.push(
                        { 
                            id: i+1,
                            Profile: loadData[i].Profile,
                            Name: loadData[i].Name ? loadData[i].Name : loadData[i].Email.split('@')[0],
                            Status: ChangeText.Status(loadData[i].Status),
                            Email: loadData[i].Email ? loadData[i].Email : '',
                            Login: ChangeText.Login(loadData[i].Login),
                            Phone: loadData[i].Phone,
                            CreatedAt: ChangeText.YyyyMmDdHhMmSs(loadData[i].createdAt),
                            Link: loadData[i]._id
                        }
                    )
                }
                setLoading(false);
                setManagers(managerDatas);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadManagers();
    },[]);

    return(
        <Layout pages={pages} title={title}>
            <Grid container justifyContent="space-between">
                <Grid xs={4}>
                    <SearchForm search={search} handleChange={handleChangeSearch} searchGuide='이름 / 이메일로 검색' />
                </Grid>
                <Grid xs={8} textAlign={'right'}></Grid>
                <Grid xs={12} className="list_table">
                    <DataGrid
                        rows={filterManagers(managers)}
                        columns={columns}
                        pageSize={15}
                        onCellClick={handleClickCell}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc'}]
                            }
                        }}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Layout>
        
    );
};

export default ManagerList;