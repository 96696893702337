import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { Login } from 'components/user';
import { PasswordFindModal } from 'components/modal';

const UserLogin = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ cookie, setCookie] = useCookies(['LoginKey', 'Name', 'UserNo', 'Profile', 'ClassNo', 'ClassName', 'ClassImage', 'ChildNo', 'ChildName', 'ChildNickName', 'ChildProfile','AcademyName']);
  const [ , setKakao] = useState(false);
  const [ , setNaver] = useState(false);
  const [checking, setChecking] = useState(false);
  const [userInfo, setUserInfo] = useState({
    Email: '',
    Password: '',
  });
  const [openPasswordFind, setOpenPasswordFind] = useState(false);
  const [emailSearch, setEmailSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URL(window.location.href).searchParams; //현재 url의 파라미터를 가져옴
    const code = params.get('code'); //params에 저장된 파라미터 안에서 'code'의 값을 가져옴
    const state = params.get('state');

    const getNaverToken = async () => {
      setChecking(true);
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({ code: code });
      await axios.post(`/api/login/naver`, body, config).then((Response) => {
        if(Response.data.Login === 1){
          let date = new Date();
          date.setDate(date.getDate()+365); 

          let closeDate = new Date();
          closeDate.setDate(closeDate.getDate()+1); 

          setCookie('LoginKey', Response.data.LoginKey, { path: '/', expires: date });
          setCookie('UserNo', Response.data.UserNo, { path: '/', expires: date });
          setCookie('Name', Response.data.Name, { path: '/', expires: date });
          setCookie('Profile', Response.data.Profile, { path: '/', expires: date });
          setCookie('Close', false, { path: '/', expires: closeDate });
          if(Response.data.Status === 1){
            navigate('/', { replace: true });
          }else if(Response.data.Status === 2){
            setCookie('ChildNo', undefined, { path: '/', expires: date });
            setCookie('ChildName', undefined, { path: '/', expires: date });
            setCookie('ChildNickName', undefined, { path: '/', expires: date });
            setCookie('ChildProfile', undefined, { path: '/', expires: date });
            setCookie('Close', false, { path: '/', expires: closeDate });
            navigate('/parent', { replace: true });
          }else if(Response.data.Status === 3){
            setCookie('ClassNo', undefined, { path: '/', expires: date });
            setCookie('ClassName', undefined, { path: '/', expires: date });
            setCookie('ClassImage', undefined, { path: '/', expires: date });
            setCookie('Close',  false, { path: '/', expires: closeDate });
            navigate('/teacher', { replace: true });
          }else{
            navigate('/', { replace: true });
          }
        }else if(Response.data.Login === 2){
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          navigate('/register', { state : { userInfo: Response.data.userInfo, Login: 3 }});
        }else{
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };

    const getKakaoToken = async () => {
      setChecking(true);
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({ code: code });
      await axios.post(`/api/login/kakao`, body, config).then((Response) => {
        if(Response.data.Login === 1){
          let date = new Date();
          date.setDate(date.getDate()+30);

          let closeDate = new Date();
          closeDate.setDate(closeDate.getDate()+1); 

          setCookie('LoginKey', Response.data.LoginKey, { path: '/', expires: date });
          setCookie('UserNo', Response.data.UserNo, { path: '/', expires: date });
          setCookie('Name', Response.data.Name, { path: '/', expires: date });
          setCookie('Profile', Response.data.Profile, { path: '/', expires: date });
          setCookie('Close', false, { path: '/', expires: closeDate });
          if(Response.data.Status === 1){
            navigate('/', { replace: true });
          }else if(Response.data.Status === 2){
            setCookie('ChildNo', undefined, { path: '/', expires: date });
            setCookie('ChildName', undefined, { path: '/', expires: date });
            setCookie('ChildNickName', undefined, { path: '/', expires: date });
            setCookie('ChildProfile', undefined, { path: '/', expires: date });
            setCookie('Close', false, { path: '/', expires: closeDate });
            navigate('/parent', { replace: true });
          }else if(Response.data.Status === 3){
            setCookie('ClassNo', undefined, { path: '/', expires: date });
            setCookie('ClassName', undefined, { path: '/', expires: date });
            setCookie('ClassImage', undefined, { path: '/', expires: date });
            setCookie('Close', false, { path: '/', expires: closeDate });
            navigate('/teacher', { replace: true });
          }else{
            navigate('/', { replace: true });
          }
        }else if(Response.data.Login === 2){
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          navigate('/register', { state : { userInfo: Response.data.userInfo, Login: 2 }});
        }else{
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };
  
    if(code && state) {
      getNaverToken();
    }else if(code){
      getKakaoToken();
    }
  }, []);

  const handleChange = (e) => {
    let changeUserInfo = { ...userInfo };
    changeUserInfo[e.target.name] = e.target.value;
    setUserInfo(changeUserInfo);
  };

  /* 로그인 */
  const handleLogin = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      Email: userInfo.Email,
      Password: userInfo.Password
    });
    await axios.post(`/api/login`, body, config).then((Response) => {
      if(Response.data.State === 2){
        let date = new Date();
        date.setDate(date.getDate()+30);

        let closeDate = new Date();
        closeDate.setDate(closeDate.getDate()+1); 
        setCookie('LoginKey', Response.data.LoginKey, { path: '/', expires: date });
        setCookie('UserNo', Response.data.UserNo, { path: '/', expires: date });
        setCookie('Name', Response.data.Name, { path: '/', expires: date });
        setCookie('Profile', Response.data.Profile, { path: '/', expires: date });
        setCookie('Close', false, { path: '/', expires: closeDate });
        navigate('/restore', { replace: true });
      }else{
        if(Response.data.Login === 1){
          let date = new Date();
          date.setDate(date.getDate()+30);
          let closeDate = new Date();
          closeDate.setDate(closeDate.getDate()+1); 
          setCookie('LoginKey', Response.data.LoginKey, { path: '/', expires: date });
          setCookie('UserNo', Response.data.UserNo, { path: '/', expires: date });
          setCookie('Name', Response.data.Name, { path: '/', expires: date });
          setCookie('Profile', Response.data.Profile, { path: '/', expires: date });
          setCookie('Close', false, { path: '/', expires: closeDate });
          if(Response.data.Status === 1){
            navigate('/', { replace: true });
          }else if(Response.data.Status === 2){
            setCookie('ChildNo', undefined, { path: '/', expires: date });
            setCookie('ChildName', undefined, { path: '/', expires: date });
            setCookie('ChildNickName', undefined, { path: '/', expires: date });
            setCookie('ChildProfile', undefined, { path: '/', expires: date });
            setCookie('Close', false, { path: '/', expires: closeDate });
            navigate('/parent', { replace: true });
          }else if(Response.data.Status === 3){
            setCookie('ClassNo', undefined, { path: '/', expires: date });
            setCookie('AcademyName', undefined, { path: '/', expires: date });
            setCookie('AcademyNo', undefined, { path: '/', expires: date });
            setCookie('Academy_id', undefined, { path: '/', expires: date });
            setCookie('AcademyName', undefined, { path: '/', expires: date });
            setCookie('ClassName', undefined, { path: '/', expires: date });
            setCookie('ClassImage', undefined, { path: '/', expires: date });
            setCookie('Close', false, { path: '/', expires: closeDate });
            navigate('/teacher', { replace: true });
          }else{
            navigate('/', { replace: true });
          }
        }else{
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'error' });
        }
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  /* 카카오 로그인 페이지로 이동 */
  const kakaoLogin = () => {
    setKakao(true);
    const kakao = {
      clientID: '119188e529f2e3e1386856c951f9fb11',
      redirectUri: 'https://wooromath.com/login'
    };
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${kakao.clientID}&redirect_uri=${kakao.redirectUri}`;
  };

  /* 네이버 로그인 페이지로 이동 */
  const naverLogin = () => {
    setNaver(true)
    const naver = {
      clientID: 'JfdS2gBOWiKo9jP8rVzo',
      redirectUri: 'https://wooromath.com/login',
      state: 'wonpotato'
    };
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naver.clientID}&redirect_uri=${naver.redirectUri}&state=${naver.state}`;
  };

  return (
    <Layout>
      <Login 
        userInfo={userInfo}
        handleChange={handleChange}
        handleLogin={handleLogin}
        kakaoLogin={kakaoLogin}
        naverLogin={naverLogin}
        checking={checking}
        setOpenPasswordFind={setOpenPasswordFind}
      />
      <PasswordFindModal 
        title={'비밀번호 찾기'}
        open={openPasswordFind}
        setOpen={setOpenPasswordFind}
        emailSearch={emailSearch}
        setEmailSearch={setEmailSearch}
      />
    </Layout>
  );
};

export default UserLogin;
