import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, CircularProgress } from '@mui/material';

const MockExamDetailUpdate = (props) => {
    const mockExam = props.mockExam;

    const navigate = useNavigate();
   
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>모의고사 수정</h3>
                    <TextField
                        name='Name'
                        label='모의고사 명'
                        value={mockExam.Name}
                        onChange={props.handleChangeName}
                        fullWidth={true}
                    />
                    <TextField
                        name='StartDate'
                        label='날짜'
                        value={mockExam.StartDate}
                        onChange={props.handleChangeStartDate}
                        fullWidth={true}
                        type='date'
                    />
                    
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='warning' onClick={props.handleClickUpdate} disabled={props.updating}>수정</Button>
                    </Grid>
                    {props.updating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='warning'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default MockExamDetailUpdate;