import React, { useState, useEffect, createRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/user/layout';
import { MockExamDetailView } from 'components/user/mockExam';
import axios from 'axios';

const MockExamDetail = () => {
  const { mockExamNo, kind } = useParams();
  const [cookies] = useCookies(['LoginKey']);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [questions, setQuestions] = useState([{
      Answer: '',
      Choices: ['', '', '', '', ''],
      Kind: '',
      Point: '',
      Content: [' ']
  }]);

  const [mockExamName, setMockExamName] = useState();
  const [questionTimer, setQuestionTimer] = useState(0);
  const [questionNo, setQuestionNo] = useState(0);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(0);
  const [singleAnswer, setSingleAnswer] = useState('');
  const [startTime, setStartTime] = useState(new Date());

  const loadDatas = async() => {
      await axios.get(`/api/mockexam/${mockExamNo}/${kind}/${cookies.LoginKey}`).then((res) => {
        setLoading(false);
        if(!res.data.ErrorMessage){
          enqueueSnackbar(res.data.Message, { variant: 'info' });
          setQuestions(res.data.Questions);
          setMockExamName(res.data.MockExamName);
        }else{
          enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
          navigate(-1);
        }
      }).catch((err) => {
        enqueueSnackbar('Network Error', { variant: 'error' });
      });
  };

  useEffect(() => {
      loadDatas();
  }, []);

  useEffect(() => {
      const timers = setInterval(() => {
          setQuestionTimer(questionTimer => questionTimer+1);
      }, 1000);
      return () => clearInterval(timers);
  }, [questionTimer]);

  /* 문제 제출하기 */
  const submitQuestion = async(memo) => {
    setLoading(true);
    if(cookies.LoginKey){
      let check;
      let select;
      const endTime = new Date();
      const time = Number(((endTime-startTime)/1000).toFixed(2));
      if(questions[questionNo].SingleAnswer){
        check = questions[questionNo].Choices[0].toString() === singleAnswer.toString() ? 1 : 2;
        select = singleAnswer;
      }else{
        check = Number(questions[questionNo].Answer) === Number(checked) ? 1 : 2;
        select = checked;
      }
      const data = {
        question_id: questions[questionNo]._id,
        check: check,
        select: select,
        time: time,
        status: 6,
        section: 999,
        memo: memo,
        mockExamNo: mockExamNo,
        loginKey: cookies.LoginKey
      };

      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify(data);
      await axios.post(`/api/mockexam/question`, body, config).then((res) => {
        if(!res.data.ErrorMessage){
          setChecked(0);
          setSingleAnswer('');
          setQuestionTimer(0);
          setStartTime(new Date());
          if(questionNo+1 === questions.length){
            endMockExam();
            enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
          }else{
            setTimeout(() => {
              setLoading(false);
            }, [500]);
            setQuestionNo(questionNo => questionNo+1);
          }
        }else{
          enqueueSnackbar(`${res.data.ErrorMessage}`, { variant: 'error' });  
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error' });
      navigate(-1);
    }
  };

  const endMockExam = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const data = JSON.stringify({
      mockExamNo: mockExamNo,
      kind: kind,
      loginKey: cookies.LoginKey
    });
    await axios.put('/api/mockExam', data, config).then((res) => {
      enqueueSnackbar('문제를 모두 풀었습니다!', { variant: 'info' });
      navigate(`/mockexam/${mockExamNo}/${kind}/result`);
    }).catch((err) => {
      enqueueSnackbar('Network Error', { variant: 'error' });
    })
  };

  // 메모
  const Mobile = () => {return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);}
  let canvas;
  let canvasRef = createRef();

  let pos = {
      drawable: false,
      x: -1,
      y: -1
  };
  let ctx;
  let [mode, setMode] = useState(1);
  
  useEffect(() => {
    canvas = canvasRef.current;
    ctx = canvas.getContext('2d');
    
    if(Mobile()){
      canvas.addEventListener('touchstart', initDraw);
      canvas.addEventListener('touchend', finishDraw);
      canvas.addEventListener('touchmove', draw);
      canvas.addEventListener('touchout', finishDraw);
    }else{
      canvas.addEventListener('mousedown', initDraw);
      canvas.addEventListener('mouseup', finishDraw);
      canvas.addEventListener('mousemove', draw);
      canvas.addEventListener('mouseout', finishDraw);
    } 
    return () => {
        if(Mobile()){
          canvas.removeEventListener('touchmove', draw);
        }else{
          canvas.removeEventListener('mousemove', draw);
        }
    };
  }, [mode]);

  const getPosition = (event) => {
    if(Mobile()){
      return { X: event.touches[0].pageX, Y: event.touches[0].pageY };
    }else{
      return { X: event.offsetX, Y: event.offsetY };
    } 
  };
  
  const initDraw = (event) => {    
      ctx.beginPath();
      ctx.lineWidth = 3;
      pos = { drawable: true, ...getPosition(event) };
      ctx.moveTo(pos.X, pos.Y);
  };

  const draw = (event) => {
      if(pos.drawable){
          if(mode === 1){
              pos = { ...pos, ...getPosition(event) };
              ctx.lineTo(pos.X, pos.Y);
              ctx.stroke();
          }else if(mode === 2){
              ctx.beginPath();
              if(Mobile()){
                ctx.clearRect(event.touches[0].pageX-25, event.touches[0].pageY-25, 50, 50);
              }else{
                ctx.clearRect(event.clientX-event.target.offsetLeft-25, event.clientY-event.target.offsetTop-25, 50, 50);
              }
          }
      }
  };

  const finishDraw = () => {
      pos = { drawable: false, X: -1, Y: -1 };
  };

  const ClearDraw = () => {
      canvas = canvasRef.current;
      ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  const UploadData = async() => {
    canvas = canvasRef.current;
    ctx = canvas.getContext('2d');
    if(!isCanvasBlank(ctx)){
        ctx = canvas.getContext('2d');
        const base64 = canvas.toDataURL();
        const file = base64toFile(base64, `memo.png`);
        ClearDraw();
        const formData = new FormData();
        formData.append('loginKey', cookies.LoginKey);
        formData.append('imgFile', file);
        const config = { headers: { 'content-type': 'multipart/form-data' }};
        await axios.post(`/api/question/memo`, formData, config).then((Response) => {
          submitQuestion(Response.data.Memo);
        }).catch((err) => {
          enqueueSnackbar('Network Error', { variant: 'error' });
        });
    }else{
      submitQuestion('');
    }
  };
  const isCanvasBlank = (ctx) => {
      return !ctx
        .getImageData(0, 0, canvas.width, canvas.height).data
        .some(channel => channel !== 0);
  };
  const base64toFile = (base_data, filename) => {
      var arr = base_data.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type:mime });
  };

  const handleClickNext = () => {
    setQuestionNo(questionNo => questionNo+1);
  };

    return(
    <Layout>
        <MockExamDetailView
            question={questions[questionNo]}
            questionNumber={30-questions.length+questionNo+1}
            mockExamName={mockExamName}
            questionTimer={questionTimer}
            loading={loading}
            checked={checked}
            setChecked={setChecked}
            singleAnswer={singleAnswer}
            setSingleAnswer={setSingleAnswer}
            submitQuestion={UploadData}

            handleClickNext={handleClickNext}

            canvasRef={canvasRef}
            mode={mode}
            setMode={setMode}
            ClearDraw={ClearDraw}
        />
    </Layout>
    );
};

export default MockExamDetail;