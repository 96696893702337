import React from 'react';
import AdminManagerPresenter from './AdminManagerPresenter';

const AdminManagerContainer = () => {
  return (
    <AdminManagerPresenter />
  );
};

export default AdminManagerContainer;
