import React, { useState } from 'react';
import { Grid, Button, Link, Pagination, Stack } from '@mui/material';
import { LocalActivityOutlined, ConfirmationNumberOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';

const StudentTicketView = (props) => {
    const now = new Date();
    const liveTickets = props.tickets.filter((item) => { return item.Order_id ? new Date(item.Order_id.NextOrder) > now && !item.UsedAt : !item.UsedAt });
    const usedTickets = props.tickets.filter((item) => { return item.UsedAt });

    const maxPage = Math.ceil(props.tickets.length / 15);
    const [page, setPage] = useState(1);
    
    const handleChangePage = (_, v) => {
        setPage(v)
    };

    return (
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>나의 이용정보</h2>
                </Grid>
                <Grid item xs={4} md={5} style={{ marginBottom: '16px' }}>
                    <div className='student_propose_list'>
                        <Grid container alignItems='center' justifyContent='flex-start' style={{ padding: '8px'}}>
                            <Grid item><LocalActivityOutlined/></Grid>
                            <Grid item>보유 중인 응시권 <b>{liveTickets.length}</b>개</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={4} md={5} style={{ marginBottom: '16px' }}>
                    <div className='student_propose_list'>
                        <Grid container alignItems='center' justifyContent='flex-start' style={{ padding: '8px'}}>
                            <Grid item><ConfirmationNumberOutlined/></Grid>
                            <Grid item>사용한 응시권 <b>{usedTickets.length}</b>개</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={6} md={5} className='diagnosis_result_title'>
                    <h2>MY 보관함</h2>
                </Grid>
                <Grid item xs={6} md={5} sx={{ textAlign: 'right', paddingTop: '50px' }}>
                    <Button variant='outlined' color='warning' onClick={() => props.setOpen(true)}>응시권 구매</Button>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Stack>
                        <div className='student_propose_list'>
                            <table style={{ textAlign: 'center' }}>
                                <tr>
                                    <th style={{ padding: '8px 0px'}} colSpan={1}>No.</th>
                                    <th style={{ padding: '8px 0px'}} colSpan={1}>응시권</th>
                                    <th style={{ padding: '8px 0px'}} colSpan={2}>만료일시</th>
                                    <th style={{ padding: '8px 0px'}} colSpan={2}>생성일시</th>
                                </tr>
                                {props.tickets.length > 0 ?
                                    props.tickets.map((ticket, i) => (
                                        i >= (page-1)*15 && i < page*15 ?
                                    <tr style={{ color: ticket.Order_id ? new Date(ticket.Order_id.NextOrder) > now || ticket.UsedAt ? '#000' : '#A4A4A4' : '#000' }}>
                                        <td>{props.tickets.length - i}</td>
                                        <td>{ticket.Order_id ? new Date(ticket.Order_id.NextOrder) > now ? <LocalActivityOutlined/> : <ConfirmationNumberOutlined/> : <LocalActivityOutlined/>}</td>
                                        <td>{ticket.UsedAt ?
                                                YyyyMmDdHhMmSs(ticket.UsedAt) :
                                                ticket.Order_id ?
                                                YyyyMmDdHhMmSs(ticket.Order_id.NextOrder) :
                                                '-'}
                                        </td>
                                        <td>
                                            {ticket.UsedAt ?
                                                '사용' :
                                                ticket.Order_id ?
                                                    new Date(ticket.Order_id.NextOrder) > now ?
                                                    '만료 예정' :
                                                    '만료 됨' :
                                                '기한 없음'
                                            }
                                        </td>
                                        <td>{YyyyMmDdHhMmSs(ticket.CreatedAt)}</td>
                                        <td>
                                            {ticket.Teacher_id ? 
                                                `${ticket.Teacher_id.Name} 선생님`:
                                                ticket.Coupon_id ?
                                                    '쿠폰':    
                                                    '구매'
                                            }
                                        </td>
                                    </tr>:null
                                )):
                                <tr>
                                    <td colSpan={6}>응시권 보유 이력이 없습니다.</td>
                                </tr>}
                            </table>
                        </div>
                        <div style={{ width: '100%', position: 'relative'}}>
                            <Pagination onChange={handleChangePage} page={page} count={maxPage} color='warning' style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 25px)', height: '45px'}}/>
                        </div>
                    </Stack>
                    <div style={{ width: '100%', textAlign: 'right', paddingTop: '16px' }}>
                        <Link href='javascript:;' onClick={props.handleClickOpenRefund}>응시권 환불</Link>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default StudentTicketView;