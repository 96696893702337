import React from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const MockExamPreviewModalView = (props) => {
    const part_2 = props.userMockExam.filter((item) => { return Number(item.MockExamNo) === Number(props.mockExamData.MockExamNo) && item.Part === 2 });
    const part_3 = props.userMockExam.filter((item) => { return Number(item.MockExamNo) === Number(props.mockExamData.MockExamNo) && item.Part === 3 });
    const part_4 = props.userMockExam.filter((item) => { return Number(item.MockExamNo) === Number(props.mockExamData.MockExamNo) && item.Part === 4 });
    const navigate = useNavigate();
    return(
        <Grid container style={{ paddingTop: '16px' }}> 
            <table className='mock_exam_preview_modal_table'>
                <tr>
                    <th>확률과 통계</th>
                    <td>
                        {props.mockExamData.UnLock ?
                            part_2.length ? 
                                part_2[0].State === 1 ?
                                    '진행중':
                                    '완료':
                                '응시 가능':
                            '응시 불가'}
                    </td>
                    <td>
                        {props.mockExamData.UnLock ?
                            part_2.length ?
                                part_2[0].State === 1 ?
                                    <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => props.handleClickStart(2)}>{'이어하기'}</Button>:
                                    <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => navigate(`/mockexam/${props.mockExamData.MockExamNo}/${2}/result`)}>{'결과보기'}</Button>:
                                <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => props.handleClickStart(2)}>{'시작하기'}</Button>:
                            <Button size='small' variant='outlined' disableElevation color='warning' disabled>시작하기</Button>
                        }
                    </td>
                </tr>
                <tr>
                    <th>미적분</th>
                    <td>
                        {props.mockExamData.UnLock ?
                            part_3.length ? 
                                part_3[0].State === 1 ?
                                    '진행중':
                                    '완료':
                                '응시 가능':
                            '응시 불가'
                        }
                    </td>
                    <td>
                        {props.mockExamData.UnLock ?
                            part_3.length ?
                                part_3[0].State === 1 ?
                                    <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => props.handleClickStart(3)}>{'이어하기'}</Button>:
                                    <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => navigate(`/mockexam/${props.mockExamData.MockExamNo}/${3}/result`)}>{'결과보기'}</Button>:
                                <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => props.handleClickStart(3)}>{'시작하기'}</Button>:
                            <Button size='small' variant='outlined' disableElevation color='warning' disabled>시작하기</Button>
                        }
                    </td>
                </tr>
                <tr>
                    <th>기하</th>
                    <td>
                        {props.mockExamData.UnLock ?
                            part_4.length ? 
                                part_4[0].State === 1 ?
                                    '진행중':
                                    '완료':
                                '응시 가능':
                            '응시 불가'}
                    </td>
                    <td>
                        {props.mockExamData.UnLock ?
                            part_4.length ?
                                part_4[0].State === 1 ?
                                    <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => props.handleClickStart(4)}>{'이어하기'}</Button>:
                                    <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => navigate(`/mockexam/${props.mockExamData.MockExamNo}/${4}/result`)}>{'결과보기'}</Button>:
                                <Button size='small' variant='outlined' disableElevation color='warning' onClick={() => props.handleClickStart(4)}>{'시작하기'}</Button>:
                            <Button size='small' variant='outlined' disableElevation color='warning' disabled>시작하기</Button>
                        }
                    </td>
                </tr>
            </table>
        </Grid>
    );
};

export default MockExamPreviewModalView;