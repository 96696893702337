import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import NormalDistribution from 'normal-distribution'; 
import { useSnackbar } from 'notistack';

import { Layout } from 'components/parent/layout';
import { ParentDashboard } from 'components/parent/child/dashboard';
import * as AddValue from 'components/function/AddValue';

const DashboardDetail = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;  
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const normDist = new NormalDistribution(0, 1); 
  const { ChildNo } = useParams();
  const [childData, setChildData] = useState({
    ChildNo: '',
    MonthZscoreSection: [],
    MonthZscoreCount: [],
    DiagnosisResult: [],
    Child_id: {
      Diagnosis: [],
      Questions: [],
      School_id: { WooroZscore: '' }
    }
  });
  const [parts, setParts] = useState(AddValue.AllPartsDashboard());
  useEffect(() => {
    const loadChildData = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/parent/dashboard/${ChildNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          let loadChild = Response.data.ChildInfo;
          loadChild.MonthZscoreSection = [];
          loadChild.MonthZscoreCount = [];
          loadChild.DiagnosisResult = loadChild.Child_id.Diagnosis;
          let studentMonthDiagnosis = loadChild.Child_id.Diagnosis;
          const nowDate = new Date();
          let cut = new Date();
          if(nowDate.getMonth === 11){
            cut = new Date(`${new Date().getFullYear()}-01-01`);
          }else{
            cut = new Date(`${new Date().getFullYear()-1}-${new Date().getMonth()+1}-01`);
          }
          studentMonthDiagnosis = studentMonthDiagnosis.filter((item) => {
            return new Date(item.CreatedAt) > cut;
          });
          // let next12 = false;
          for(let i = 0; i < 12; i++){
            // 진단 결과 월 별로 나눔
            const tumpDate2 = new Date(new Date(new Date().setDate(1)).setMonth(new Date().getMonth() - 11 + i)).getMonth();
            loadChild.MonthZscoreSection[i] =
              studentMonthDiagnosis.filter((item) => {
                return new Date(item.CreatedAt).getMonth() === tumpDate2;
            });
            loadChild.DiagnosisResult.sort(function (a, b) {
              return new Date(b.CreatedAt) - new Date(a.CreatedAt);
            });
            loadChild.DiagnosisResult = loadChild.DiagnosisResult.filter((item1, a) => {
              return (loadChild.DiagnosisResult.findIndex((item2, b) => {     
                return item1.Section === item2.Section;
              }) === a);
            });
            let linkDate;
            if(tumpDate2 > new Date().getMonth()){
              linkDate = `${new Date().getFullYear()-1}${tumpDate2+1 < 10 ? `0${tumpDate2+1}` : tumpDate2+1}`;
            }else{
              linkDate = `${new Date().getFullYear()}${tumpDate2+1 < 10 ? `0${tumpDate2+1}` : tumpDate2+1}`;
            }
            loadChild.MonthZscoreCount.push({ Month: `${tumpDate2+1}월`, '진단 횟수': loadChild.MonthZscoreSection[i].length, Link: linkDate });
            // 최신순으로 정렬1
            loadChild.MonthZscoreSection[i].sort(function (a, b) {
              return new Date(b.CreatedAt) - new Date(a.CreatedAt);
            });
            // 중복 제거
            loadChild.MonthZscoreSection[i] = loadChild.MonthZscoreSection[i].filter((item1, a) => { 
              return (loadChild.MonthZscoreSection[i].findIndex((item2, b) => {     
                return item1.Section === item2.Section;
              }) === a);
            });
          }
          
          let tempParts = [...parts];
          for(let i = 0; i < tempParts.length; i++){
            let tempResult = loadChild.DiagnosisResult.filter((item) => { return Math.floor(item.Section/100) === i+1 });
            let tempZScore = 0;
            for(let j = 0; j < tempResult.length; j++){
              tempZScore += normDist.cdf(tempResult[j].ZScore);
            }
            if(tempResult.length > 0){
              tempZScore = 1 - tempZScore/tempResult.length;
            }else{
              tempZScore = null;
            }
            tempParts[i].percent = tempZScore;
            for(let j = 0; j < tempParts[i].subSections.length; j++){
              for(let k = 0; k < tempParts[i].subSections[j].sections.length; k++){
                let tempResult = loadChild.DiagnosisResult.filter((item) => { return item.Section === tempParts[i].subSections[j].sections[k].section });
                if(tempResult[0]){
                  tempParts[i].subSections[j].sections[k].percent = 1 - normDist.cdf(tempResult[0].ZScore);
                }else{
                  tempParts[i].subSections[j].sections[k].percent = null;
                }
              }
            }
          }
          setChildData(loadChild);
          setParts(tempParts);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
          navigate(-1);  
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });      
    }
    loadChildData();
  }, [ChildNo]);
  
  return (
    <Layout>
      <ParentDashboard childData={childData} parts={parts} />
    </Layout>
  )
};

export default DashboardDetail;