import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, InputLabel, Button, RadioGroup, Radio, FormControlLabel, CircularProgress, OutlinedInput, InputAdornment, IconButton, Chip } from '@mui/material';
import { AddCircle, Clear, Edit } from '@mui/icons-material';

import { MathJax, MathJaxContext } from 'better-react-mathjax';

const MockExamQuestionCreateView = (props) => {
    const question = props.question;

    const navigate = useNavigate();
    const handleKeyDownChoice = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddChoice();
        }
    };
    const handleKeyDownContent = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddContent();
        }
    };
    const handleKeyDownCommentary = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddCommentary();
        }
    };

    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>문제 등록</h3>
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='kind-label' shrink>선택 과목</InputLabel>
                        <RadioGroup
                            row
                            labelId='kind-label'
                            value={question.Kind}
                            name='Kind'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={1} control={<Radio />} label='공통' />
                            <FormControlLabel value={2} control={<Radio />} label='확률과 통계' />
                            <FormControlLabel value={3} control={<Radio />} label='미적분' />
                            <FormControlLabel value={4} control={<Radio />} label='기하' />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='point-label' shrink>배점</InputLabel>
                        <RadioGroup
                            row
                            labelId='point-label'
                            value={question.Point}
                            name='Point'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={2} control={<Radio />} label='2점' />
                            <FormControlLabel value={3} control={<Radio />} label='3점' />
                            <FormControlLabel value={4} control={<Radio />} label='4점' />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='single-answer-label' shrink>답안</InputLabel>
                        <RadioGroup
                            row
                            labelId='single-answer-label'
                            value={question.SingleAnswer}
                            name='SingleAnswer'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={null} checked={!question.SingleAnswer} control={<Radio />} label='객관식' />
                            <FormControlLabel value={1} control={<Radio />} label='주관식' />
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>문제</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='Content'
                            label='문제'
                            value={props.content}
                            onChange={props.handleChangeContent}
                            onKeyPress={handleKeyDownContent}
                            multiline={true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddContent}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        문제 미리보기<hr/>
                        {question.Content.map((content, i) => (
                        <Grid container justifyContent='space-between' style={{marginBottom: '10px', borderBottom: '1px #A4A4A4 solid', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteContent(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${content.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        content.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic
                                    />
                                </MathJaxContext>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleClikcEditContent(i)}>
                                    <Edit/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>보기</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='choice'
                            label='보기'
                            value={props.choice}
                            onChange={props.handleChangeChoice}
                            onKeyDown={handleKeyDownChoice}
                            disabled={question.NewChoices.length < 5 ? false : true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddContent}
                                    >
                                        {props.edit === null ?
                                        <AddCircle />:
                                        <Edit />}
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>

                    {/* {question.Choices.map((choice, i) => (
                        <Chip style={{marginRight: '8px'}} label={choice} variant="outlined" onDelete={() => props.handleDeleteChoice(i)}/>
                    ))} */}
                    { question.NewChoices.length > 0 ?
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        {question.NewChoices.map((choice, i) => (
                        <Grid container style={{marginBottom: '10px', borderBottom: '1px solid rgba(164, 164, 164, 0.3)', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteChoice(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${choice.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        choice.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic
                                    />
                                </MathJaxContext>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    :
                    ""
                    }
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='answer-label' shrink>정답</InputLabel>
                        <RadioGroup
                            row
                            labelId='answer-label'
                            value={question.Answer}
                            name='Answer'
                            onChange={props.handleChange}
                        >
                             {question.NewChoices.map((choice, i) => (
                                <FormControlLabel value={i+1} control={<Radio />} label={
                                    <MathJaxContext version={3} config={config}>
                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                            choice.replace('`\\(','').replace('\\)`','')
                                            } inline dynamic
                                        />
                                    </MathJaxContext>
                                }/>      
                            ))}
                            {/* {question.Choices.map((choice, i) => (
                                <FormControlLabel value={i+1} control={<Radio />} label={choice} />
                            ))} */}
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>해설</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='Commentary'
                            label='해설'
                            value={props.commentary}
                            onChange={props.handleChangeCommentary}
                            onKeyDown={handleKeyDownCommentary}
                            multiline={true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddCommentary}
                                    >
                                        {props.editCommentary === null ?
                                        <AddCircle />:
                                        <Edit />}
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        해설 미리보기<hr/>
                        {question.Commentary.map((commentary, i) => (
                        <Grid container justifyContent='space-between' style={{marginBottom: '10px', borderBottom: '1px #A4A4A4 solid', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteCommentary(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${commentary.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        commentary.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic
                                    />
                                </MathJaxContext>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleClikcEditCommentary(i)}>
                                    <Edit/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='success' onClick={props.handleClickCreate} disabled={props.creating}>등록</Button>
                    </Grid>
                    {props.creating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='success'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default MockExamQuestionCreateView;