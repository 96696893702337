import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import homework_info from "img/homework_info.png";
import teacher_info_class from 'img/teacher_info_class.png'
import class1 from 'img/class1.png'
import class2 from 'img/class2.png'
import homework1 from 'img/homework1.png'
import homework2 from 'img/homework2.png'
import teacher_question from 'img/teacher_question.png'
import {East} from "@mui/icons-material";

const HomeworkInfo = () => {
  const navigate = useNavigate();

  return (
    <Grid className="diagnosisInfo">
      <Grid container justifyContent="center" spcing={10}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={5.5}>
          <Grid container className="title" >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={7} className="teacher_img">
              <h1 style={{ fontSize: "40px" }}>숙제하기</h1>
              <p style={{ fontSize: "16px", fontWeight: "700" }}>
                로그인 및 선생님 매칭 후 사용할 수 있습니다.
              </p>
              <p style={{ fontSize: "16px", fontWeight: "700" }}>
                선생님이 내주신 숙제를 할 수 있어요{" "}
              </p>
            </Grid>
            <Grid item xs={12} sm={12}  md={6} lg={6} xl={5} textAlign={"right"} className="teacher_img">
              <img src={homework_info} width="280px" alt={homework_info}></img>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h2>선생님 찾기</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h3 style={{ marginBottom: "60px" }} className="info_text">1. 클래스/학부모</h3>
              <img
                src={teacher_info_class}
                width="100%"
                alt="img"
                style={{ marginBottom: "110px" }}
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6} className="info_margin">
              <div style={{ display: "flex" }}>
                <h3 className="info_text">2.&nbsp;</h3>
                <h3 className="info_text">클래스 검색 클릭 후 선생님, 클래스 이름으로 검색 </h3>
              </div>
              <img
                src={class1}
                width="100%"
                alt="img"
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6} >
              <div style={{ display: "flex" }}>
                <h3 className="info_text">3.&nbsp;</h3>
                <h3 className="info_text">찾는 클래스나 선생님이 맞는다면 신청하기 클릭!</h3>
              </div>
              <img
                src={class2}
                width="100%"
                alt="img"
              ></img>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={6} lg={12} xl={12} mt={10}>
              <h2>숙제하기</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6} className="info_margin">
              <h3 className="info_text">1. 숙제 클릭</h3>
              <img
                src={homework1}
                width="100%"
                alt="img"
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <h3 className="info_text">2. 문제보기를 클릭</h3>
              <img
                src={homework2}
                width="100%"
                alt="img"
              ></img>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} mt={10}>
              <h3 className="info_text">3. 숙제 시작!</h3>
              <img
                src={teacher_question}
                width="100%"
                alt="img"
              ></img>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            color="inherit"
            style={{ padding: "7px", width: "100%", marginTop: "24px" }}
            className="button-wooro"
            endIcon={<East />}
            onClick={() => {
              navigate(`/login`);
            }}
            target="_blank"
          >
            로그인 바로가기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeworkInfo;
