import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Grid , Card, CardContent, Typography, Button, CardActions, Avatar, Stack } from '@mui/material';
import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';

const MockExamView = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const now = new Date();
    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>모의고사</h2>
                </Grid>
                <Grid container xs={12} md={10} style={{ padding: 0 }} alignItems='stretch'>
                    {props.mockExams.map((mockExam, i) => (
                    <Grid xs={12} sm={6} md={4} lg={3} style={{ marginBottom: '16px', padding: '0 8px' }}>
                        <Card style={{ height: '100%', position: 'relative' }}>
                            <CardContent style={{ paddingBottom: 0 }}>
                                <Typography color={new Date(mockExam.StartDate) < now ? false : 'text.secondary'}>
                                    {mockExam.Name.replace('우로매쓰 ', '')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                                    {new Date(mockExam.StartDate) < now ?
                                        YyyyMmDdHhMmSs(mockExam.StartDate) :
                                        `${YyyyMmDdHhMmSs(mockExam.StartDate)} OPEN`
                                    }
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    <Stack direction='row' spacing={-2}>
                                        {mockExam.ClassStudents.slice(0, 4).map((classStudent, i) => (
                                            <Avatar 
                                                src={classStudent.Student_id.Profile ? classStudent.Student_id.Profile : false}
                                                style={{ backgroundColor: classStudent.Student_id.Profile ? '#FFF' : false }}
                                            />
                                        ))}
                                        {mockExam.ClassStudents.length > 4 ?
                                            <Avatar>+ {mockExam.ClassStudents.length - 4}</Avatar>:
                                            null
                                        }
                                    </Stack>
                                    {mockExam.ClassStudents.length ?
                                        mockExam.ClassStudents.length === 1 ?
                                            mockExam.ClassStudents[0].Student_id.Name:
                                            `${mockExam.ClassStudents[0].Student_id.Name} 외 ${mockExam.ClassStudents.length - 1}명`:
                                        new Date(mockExam.StartDate) < now ?
                                            '해당 회차 응시학생이 없습니다':
                                            ''
                                    }
                                </Typography>
                            </CardContent>
                            <CardActions>
                                    <Button size='small' color='warning' disabled={new Date(mockExam.StartDate) > now || props.disabledDetail } onClick={() => navigate(`${location.pathname}/${mockExam.MockExamNo}`)}>상세보기</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

export default MockExamView;