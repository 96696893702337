import React, { useState, useEffect } from 'react';
import { Grid, Box, Tabs, Tab, Avatar, Link, FormControl, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';
import { QuestionMemoReview } from 'components/user/question';
import WooroTestLogoOnly from 'img/woorotest_logo_only.png'


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
        theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EF4C23'
    }
}));

const TeacherHomeworkManage = (props) => {
    const [cookies] = useCookies(['ClassName','ClassImage']);
    const navigate = useNavigate();

    const homeworkDatas = props.homeworkDatas;
    const nowHomework = homeworkDatas.filter((item) => { return  new Date() < new Date(item.DueDate).setHours(23,59,59,999)});
    const beforeHomework = homeworkDatas.filter((item) => { return  new Date() >= new Date(item.DueDate).setHours(23,59,59,999)});
    const [selectedHomework, setSelectedHomework] = useState();
    const [tabValue, setTabValue] = useState(0);
    const [sortOption, setSortOption] = useState(0);
    const [openMemoReview, setOpenMemoReview] = useState(false);
    const [memoReview, setMemoReview] = useState('');
    
    const handleClickMemo = (memo) => {
        setMemoReview(memo);
        setOpenMemoReview(true);
    };
    const handleClickHomework = (HNO) => {
        let changeSelectedHomework = homeworkDatas.filter((item) => { return item.HomeworkNo === HNO });
        changeSelectedHomework = changeSelectedHomework[0];
        let answerQuestionsCnt = 0;
        let answerQuestionsTime = 0;
        let solveQuestionsCnt = 0; 
        for(let i = 0; i < changeSelectedHomework.Question_ids.length; i++){
            changeSelectedHomework.Question_ids[i].No = i;
            let tempAnswerCnt = 0;
            let tempAnswerTime = 0;
            let tempSolveCnt = 0;
            for(let j = 0; j < changeSelectedHomework.Students.length; j++){
                let student = changeSelectedHomework.Students[j];
                for(let k = 0; k < student.Questions.length; k++){    
                    if(changeSelectedHomework.Question_ids[i]._id === student.Questions[k].Question_id){
                        tempSolveCnt += 1;
                        solveQuestionsCnt += 1;
                        if(student.Questions[k].Check === 1){
                            tempAnswerCnt += 1;
                            tempAnswerTime += student.Questions[k].Time;
                            answerQuestionsCnt += 1;
                            answerQuestionsTime += student.Questions[k].Time;
                        }
                    }
                }
            }
            changeSelectedHomework.Question_ids[i].InfoClass = { Correct: tempAnswerCnt, Counting: tempSolveCnt, Time: tempAnswerTime };
        }
        
        changeSelectedHomework.solveQuestionsCnt = solveQuestionsCnt;
        changeSelectedHomework.answerQuestionsTime = answerQuestionsTime;
        changeSelectedHomework.answerQuestionsCnt = answerQuestionsCnt;
        for(let i = 0; i < changeSelectedHomework.Students.length; i++){
            let tempCorret = 0;
            let tempCounting = 0;
            let tempTime = 0;
            for(let j = 0; j < changeSelectedHomework.Students[i].Questions.length; j++){
                tempCounting += 1;
                if(changeSelectedHomework.Students[i].Questions[j].Check === 1){
                    tempCorret += 1;
                    tempTime += changeSelectedHomework.Students[i].Questions[j].Time;
                }
            }
            changeSelectedHomework.Students[i].QuestionInfo = { Correct: tempCorret, Counting: tempCounting, Time: tempTime };
        }
        setSelectedHomework(changeSelectedHomework);
    };
    const handleClick = (event) => {
        if(event.target.accessKey){
            setSortOption(0);
            setTabValue(Number(event.target.accessKey));
        }
    };
    const QuestionDatasSort = (datas) => {
        if(sortOption === 0){
            datas.sort(function (a, b) {
                return a.No - b.No;
            });
        }else if(sortOption === 1){
            datas.sort(function (a, b) {
                return a.Question - b.Question;
            });
        }else if(sortOption === 2){
            datas.sort(function (a, b) {
                return b.InfoClass.Correct - a.InfoClass.Correct;
            });
        }else if(sortOption === 3){
            datas.sort(function (a, b) {
                return a.InfoClass.Time - b.InfoClass.Time;
            });
        }
        return(datas);
        // datas.sort(function (a, b) {
        //     return b.Student_id.Name.localeCompare(a.Student_id.Name);
        // });
    };
    const StudentDatasSort = (datas) => {
        if(sortOption === 0){
            datas.sort(function (a, b) {
                return a.Student_id.Name.localeCompare(b.Student_id.Name);
            });
        }else if(sortOption === 1){
            datas.sort(function (a, b) {
                return b.QuestionInfo.Counting - a.QuestionInfo.Counting;
            });
        }else if(sortOption === 2){
            datas.sort(function (a, b) {
                return b.QuestionInfo.Correct - a.QuestionInfo.Correct;
            });
        }else if(sortOption === 3){
            datas.sort(function (a, b) {
                return b.QuestionInfo.Time - a.QuestionInfo.Time;
            });
        }
        return(datas);
    };
    
    const handleClickDelete = () => {
        props.handleClickHomeworkDelete(selectedHomework.HomeworkNo);
        setTimeout(() => {
            props.setDeleting(false);
            setSelectedHomework();
            setTabValue(0);
            setSortOption(0);
        }, [1500]);
    };
    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>숙제 이력</h2>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='teacher_homework_list'>
                        <table>
                            {nowHomework.map((homework, i) => (
                                homework.Section === 1000 ? 
                            <td>
                                <div className='teacher_homework_info' onClick={() => handleClickHomework(homework.HomeworkNo)}>
                                    <div className='custom_homework_info_banner' style={{ backgroundImage: cookies.ClassImage ? `url(${cookies.ClassImage})` : `url(${WooroTestLogoOnly})`,backgroundSize: cookies.ClassImage ? "cover" : " 90px 90px" }} />
                                    <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                    <div className='homework_info_section' ><p style={{ overflow:"hidden",textOverflow:'ellipsis',width:"95%",whiteSpace:"nowrap"}}>{homework.Title}</p></div>
                                    <div className='homework_info_progress'>
                                        <BorderLinearProgress variant='determinate' value={homework.CompletedCnt/homework.Students.length*100} /> 
                                    </div>
                                    <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.CompletedCnt/homework.Students.length)}</div>
                                </div>
                            </td>:
                             <td>
                                <div className='teacher_homework_info' onClick={() => handleClickHomework(homework.HomeworkNo)}>
                                    <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)` }} />
                                    <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                    <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                    <div className='homework_info_progress'>
                                        <BorderLinearProgress variant='determinate' value={homework.CompletedCnt/homework.Students.length*100} /> 
                                    </div>
                                    <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.CompletedCnt/homework.Students.length)}</div>
                                </div>
                            </td>
                            ))}
                            {beforeHomework.map((homework, i) => (
                                homework.Section === 1000 ? 
                            <td>
                                <div className='teacher_homework_info' onClick={() => handleClickHomework(homework.HomeworkNo)}>
                                    <div className='custom_homework_info_banner' style={{ backgroundImage: cookies.ClassImage ? `url(${cookies.ClassImage})` : `url(${WooroTestLogoOnly})`,backgroundSize: cookies.ClassImage ? "cover" : " 90px 90px" }} />
                                    <div className='homework_info_date'>{ChangeText.YyyyMmDd(homework.StartDate)} ~  {ChangeText.YyyyMmDd(homework.DueDate)}</div>
                                    <div className='homework_info_section' ><p style={{ overflow:"hidden",textOverflow:'ellipsis',width:"95%",whiteSpace:"nowrap"}}>{homework.Title}</p></div>
                                    <div className='homework_info_progress'>
                                        <BorderLinearProgress variant='determinate' value={homework.CompletedCnt/homework.Students.length*100} /> 
                                    </div>
                                    <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.CompletedCnt/homework.Students.length)}</div>
                                </div>
                            </td>:
                            <td>
                                <div className='teacher_homework_info' onClick={() => handleClickHomework(homework.HomeworkNo)}>
                                    <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)`, filter: 'grayscale(100%)' }} />
                                    <div className='homework_info_date'>{ChangeText.YyyyMmDd(homework.StartDate)} ~ {ChangeText.YyyyMmDd(homework.DueDate)}</div>
                                    <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                    <div className='homework_info_progress'>
                                        <BorderLinearProgress variant='determinate' value={homework.CompletedCnt/homework.Students.length*100} /> 
                                    </div>
                                    <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.CompletedCnt/homework.Students.length)}</div>
                                </div>
                            </td>
                            ))}
                        </table>
                    </div>
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>세부 정보</h2>
                </Grid>
                {selectedHomework ?
                <Grid container item xs={12} md={10} justifyContent='space-between'>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info' alignItems='center' sx={{padding: '16px !important'}}>
                        <table className='teacher_homework_manage_info'>
                            {
                                selectedHomework.Section === 1000 ?
                            <tr>     
                                <th>제목:</th><td>{selectedHomework.Title}</td>
                            </tr>
                            :
                            <tr>     
                                <th>단원:</th><td>{ChangeText.Part(selectedHomework.Section)} &gt; {ChangeText.MiddlePart(selectedHomework.Section)} &gt; {ChangeText.Section(selectedHomework.Section)}</td>
                            </tr>
                            }
                            <tr>
                                <th>문제수:</th><td>{selectedHomework.QuestionsCnt}문제</td>
                            </tr>
                            <tr>
                                <th>난이도:</th><td>{'★ '.repeat(selectedHomework.Difficulty)}{'☆ '.repeat(5-selectedHomework.Difficulty)}</td>
                            </tr>
                            <tr>
                                <th>숙제 기한:</th><td>{ChangeText.YyyyMmDd(selectedHomework.StartDate)} ~ {ChangeText.YyyyMmDd(selectedHomework.DueDate)}</td>
                            </tr>
                            <tr>
                                <th>전달 사항:</th><td>{selectedHomework.Notice}</td>
                            </tr>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info' style={{ padding: '16px !important'}}>
                        {selectedHomework.Section === 1000 ?
                        <div style={{textAlign:"right"}}>
                            <Button variant='contained' disableElevation color='warning' sx={{marginRight:"10px"}} disabled={new Date() < new Date(selectedHomework.DueDate)} onClick={()=>navigate(`${selectedHomework.HomeworkNo}/scoring`)}>채점하기</Button>
                        </div>:
                            ""
                        }
                        <div className='teacher_homework_manage_progress'>
                            <h3>숙제 진행 상황</h3>
                            <div>진행률: <span>{ChangeText.Percent(selectedHomework.CompletedCnt/selectedHomework.Students.length)}</span> ({selectedHomework.CompletedCnt}/{selectedHomework.Students.length}명)</div>
                            <div>정답률: <span>{selectedHomework.solveQuestionsCnt !== 0 ? ChangeText.Percent(selectedHomework.answerQuestionsCnt/selectedHomework.solveQuestionsCnt): '0%'}</span> ({selectedHomework.answerQuestionsCnt}/{selectedHomework.solveQuestionsCnt}문제)</div>
                            <div>평균 풀이 시간: <span>{ChangeText.QuestionMinute(selectedHomework.answerQuestionsTime/selectedHomework.solveQuestionsCnt)}</span></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '0px !important' }}>
                        <Grid container alignItems='flex-end'>
                            <Grid item xs={8} sx={{ padding: '0px !important' }}>
                                 <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onClick={handleClick} aria-label='basic tabs example'>
                                        <Tab label='문제 목록' accessKey={0} />
                                        <Tab label='학생 목록' accessKey={1} />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right', padding: '0px !important' }}>
                                <FormControl variant='standard' sx={{ minWidth: 120, textAlign: 'left' }}>
                                    {tabValue === 0 ?
                                    <Select
                                        value={sortOption}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    >
                                        <MenuItem value={0}>출제순</MenuItem>
                                        <MenuItem value={1}>번호</MenuItem>
                                        <MenuItem value={2}>정답률</MenuItem>
                                        <MenuItem value={3}>풀이시간</MenuItem>
                                    </Select>:
                                    <Select
                                        value={sortOption}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    >
                                        <MenuItem value={0}>이름</MenuItem>
                                        <MenuItem value={1}>진행률</MenuItem>
                                        <MenuItem value={2}>정답률</MenuItem>
                                        <MenuItem value={3}>풀이시간</MenuItem>
                                    </Select>}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div hidden={tabValue !== 0}>
                            <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                                <table >
                                    <tr>
                                        <th rowSpan={2}>No.</th>
                                        <th rowSpan={2}>진행률</th>
                                        <th colSpan={2}>정답률</th>
                                        <th colSpan={2}>평균풀이시간</th>
                                        <th rowSpan={2}></th>
                                    </tr> 
                                    <tr>
                                        <th>{cookies.ClassName}</th>
                                        <th>우로매쓰</th>
                                        <th>{cookies.ClassName}</th>
                                        <th>우로매쓰</th>
                                    </tr>
                                    {QuestionDatasSort(selectedHomework.Question_ids).map((question, i) => (
                                    <tr>
                                        <td>{question.Question-(question.Section*10000)}</td>
                                        <td>{ChangeText.Percent(question.InfoClass.Counting/selectedHomework.Students.length)}</td>
                                        <td>{question.InfoClass.Counting !== 0 ? ChangeText.Percent(question.InfoClass.Correct/question.InfoClass.Counting) : '0%'}</td>
                                        <td>{question.Info?.Counting !== 0 ? ChangeText.Percent(question.Info?.Correct/question.Info?.Counting) : '0%'}</td>
                                        <td>{ChangeText.QuestionMinute(question.InfoClass?.Time/question.InfoClass?.Correct)}</td>
                                        <td>{ChangeText.QuestionMinute(question.Info?.Time/question.Info?.Correct)}</td>
                                        <td><Link href={`/teacher/${selectedHomework.ClassNo}/homework/${selectedHomework.HomeworkNo}/question/${question.Question}`}>상세보기</Link></td>
                                    </tr> 
                                    ))}
                                </table>
                            </div>
                        </div>
                        <div hidden={tabValue !== 1}>
                            <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                                <table>
                                    <tr>
                                        <th>프로필</th>
                                        <th>이름</th>
                                        <th>학교</th>
                                        <th>진행률</th>
                                        <th>정답률</th>
                                        <th>전체풀이시간</th>
                                        <th>풀이여부</th>
                                        <th></th>
                                    </tr> 
                                    {StudentDatasSort(selectedHomework.Students).map((student, i) => (
                                    <tr>
                                        <td>
                                            <Avatar src={student.Student_id.Profile} sx={{ margin: 'auto' }}/>
                                        </td>
                                        <td>{student.Student_id.Name}</td>
                                        <td>{student.Student_id.School_id.Name} · {student.Student_id.Attending === 1 ? ChangeText.Grade(student.Student_id.Grade) : ''} {ChangeText.Attending(student.Student_id.Attending)}</td>
                                        <td>{ChangeText.Percent(student?.QuestionInfo?.Counting/selectedHomework?.QuestionsCnt)}</td>
                                        <td>{student?.QuestionInfo?.Counting !== 0 ? ChangeText.Percent(student.QuestionInfo.Correct/student.QuestionInfo.Counting) : '0%'}</td>
                                        <td>{ChangeText.QuestionMinute(student.QuestionInfo.Time)}</td>
                                        <td>{student.MemoPictures.length > 0 ? <Link href='javascript:;' onClick={() => handleClickMemo(student.MemoPictures)}>O</Link> : 'X'}</td>
                                        <td><Link href={`/teacher/${selectedHomework.ClassNo}/homework/${selectedHomework.HomeworkNo}/student/${student.StudentNo}`}>상세보기</Link></td>
                                    </tr> 
                                    ))}
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '0px !important', marginTop: '30px', textAlign: 'right' }}>
                        {props.deleting ?
                        <CircularProgress color='error'/>:
                        <>
                            <Button variant='outlined' color='error' onClick={handleClickDelete} style={{ float: 'left' }}>삭제</Button>
                            <Button variant='contained' color='warning' onClick={() => window.open(`${window.location.origin}/teacher/${selectedHomework.ClassNo}/homework/${selectedHomework.HomeworkNo}/print`, '_blank')}>숙제 출력</Button>
                            <p>* 문제 길이에 따라 일부분이 출력되지 않을 수 있습니다.</p>
                        </>}
                        
                    </Grid>
                </Grid>
                :
                <div style={{width: '100%', textAlign: 'center'}}>상단 숙제를 클릭하여 세부 정보를 확인하세요.</div>}
            </Grid>
            <QuestionMemoReview 
                open={openMemoReview}
                setOpen={setOpenMemoReview}
                memoReview={memoReview}
            />
        </div>
    );
};

export default TeacherHomeworkManage;