import { Avatar, } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useState,useEffect } from "react";
import axios from "axios";
import { useSnackbar } from 'notistack';

const Layout = () => {
    const [cookies] = useCookies(['AcademyName', 'LoginKey']);
    const { enqueueSnackbar } = useSnackbar();

    const [academy,setAcademy]=useState();

    const loadAcademyData = async()=>{
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
          setAcademy(Response.data.Academy);
        }).catch((err) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
      };

    useEffect(()=>{
        loadAcademyData()
    },[]);

    return(
        <div className='academy_layout_nav'>
            <div className='academy_layout_nav_profile'>
                <Avatar src={academy?.Logo} width="51px" height={"51px"}sx={{marginRight:"10px"}} />
                <p>{cookies.AcademyName}</p>
            </div>
        </div>
    );
}

export default Layout;