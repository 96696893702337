import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { Banner, Part, Category, Students } from 'components/teacher/class/main';
import { Footer } from 'components/user/layout';

const Main = () => {
  const [cookies] = useCookies(['LoginKey', 'ClassName', 'ClassImage']);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { ClassNo } = useParams();
  const [classData, setClassData] = useState({
    ClassName: '',
    Students: [{}],
    Homeworks: []
  });
  const [permitStudentCnt, setPermitStudentCnt] = useState(1);
  const [applyStudentCnt, setApplyStudentCnt] = useState(0);

  useEffect(() => {
    const loadClassData = async() => {
      await axios
      .get(`/api/teacher/class/${ClassNo}/${LoginKey}`)
      .then((Response) => {
        if(!Response.data.ErrorMessage){
          const connectStudentList = Response.data.Class.Students;
          const permitStudentList = connectStudentList.filter((item) => { return item.Connect === 2 });
          setPermitStudentCnt(permitStudentList.length);
          const applyStudentList = connectStudentList.filter((item) => { return item.Connect === 1 });
          setApplyStudentCnt(applyStudentList.length);
          setClassData(Response.data.Class);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      })
    }
    loadClassData();
  }, []);

  return (
    <>
      <Layout>
        <Banner classData={classData} permitStudentCnt={permitStudentCnt} />
        <Grid container justifyContent='center' className='content_section'>
          <Grid item xs={12} md={10} lg={4}>
            <Part />
          </Grid>
          <Grid item xs={12} md={10} lg={3}>
            <Category />
          </Grid>
          <Grid item xs={12} md={10} lg={3}>
            <Students applyStudentCnt={applyStudentCnt}/>
          </Grid>
        </Grid>
      </Layout>
      <Footer/>
    </>
  )
};

export default Main;