import { useCookies } from 'react-cookie';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { useEffect,useState } from "react";

import { Layout } from "components/Academy/Layout";
import { AcademyDashboardView } from "components/Academy/main";

const AcademyDashboard = () => {
  const [cookie,setCookie] = useCookies(['LoginKey']);
  const loginKey = cookie.LoginKey;
  const { enqueueSnackbar } = useSnackbar();

  const [teacherList,setTeacherList] = useState();
  const [studentList,setStudentList] = useState();
  const [classList,setClassList] = useState([]);
  const [classs,setClasss] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [order, setOrder] = useState([]);
  const [academy,setAcademy] = useState();


  const loadAcademyData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/${loginKey}`, config).then((Response) => {
      setAcademy(Response.data.Academy);
      let date = new Date();
      date.setDate(date.getDate()+30);
      setCookie('AcademyNo', Response.data.Academy.AcademyNo, { path: '/', expires: date });
      setCookie('Academy_id', Response.data.Academy._id, { path: '/', expires: date });
      setCookie('Logo', Response.data.Academy.Logo, { path: '/', expires: date });
      setCookie('AcademyName', Response.data.Academy.Name, { path: '/', expires: date });

    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const loadTeacherData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${loginKey}`, config).then((Response) => {
      if (Response.data.Teachers) {
        setTeacherList(Response.data.Teachers)
      }
    }) 
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  const loadClassData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/class/${loginKey}`, config).then((Response)=>{
      let loadData =Response.data.Classs
      let classData=[]
  
      if (loadData.length >= 6) {
        for (let i = 0; i < 6; i++) {
          classData.push(loadData[i])
        }
        setClassList(loadData)
        setClasss(classData)
      }else{
        setClassList(loadData)
      }
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });

    })
  };
  const loadStudentsData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${loginKey}`, config).then((Response)=>{
      setStudentList(Response.data.Students)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };

  const loadTicketData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/ticket/${loginKey}`, config).then((Response) => {
      let loadTickets = Response.data.Tickets;
      const TicketCount = loadTickets[loadTickets.length-1].Teachers.reduce((a, b) => (a + b.Count), 0);
      setTickets((loadTickets[loadTickets.length-1].Count - TicketCount)-loadTickets[loadTickets.length-1].Students.length);
      setTicket(loadTickets)
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  const loadOrderDatas= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/price/${loginKey}`, config).then((Response) => {
      let loadPriceDatas = Response.data.Prices;
      loadPriceDatas.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));

      setOrder(loadPriceDatas);
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  useEffect(()=>{
    loadClassData();
    loadTeacherData();
    loadStudentsData();
    loadTicketData();
    loadOrderDatas();
    loadAcademyData();
  },[]);

  return (
    <Layout >
      <AcademyDashboardView 
       classList={classList}
       classs={classs}
       studentList={studentList}
       tickets={tickets}
       ticket={ticket}
       teacherList={teacherList}
       order={order}
      />
    </Layout>
  );
};

export default AcademyDashboard;
