import React from 'react';
import AcademyPresenter from './AcademyPresenter';

const AcademyContainer = () => {
  return (
    <AcademyPresenter />
  );
};

export default AcademyContainer;
