import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { QuestionDetailInfo } from 'components/admin/question';
import { QuestionInspectionModal, DeleteModal } from 'components/modal';

const QuestionInfo = () => {
    const pages = [
        { title: '문제 관리' },
        { title: '문제 목록' },
        { title: '문제 상세 정보' }
    ];
    const title = { main: '문제 상세 정보', sub: 'question' };
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [question, setQuestion] = useState({
        _id: '',
        Info: { Counting: '', Correct: '', Time: '' },
        Inspection: { Commnet: '', NotError: 1 },
        Picture: '',
        Commentary: [],
        CommentaryPicture: [],
        CommentaryImage: ''
    });
    const [updating, setUpdating] = useState(false);
    const [openInspection, setOpenInspection] = useState(false);
    const [changeInspection, setChangeInspection] = useState(question.Inspection);
    const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
    const [beforeQuestionId, setBeforeQuestionId] = useState('');
    const [nextQuestionId, setNextQuestionId] = useState('');

    const handleClickOpenInspection = () => {
        setOpenInspection(true);
    };
    const handleClickCloseInspection = () => {
        setOpenInspection(false);
        setChangeInspection(question.Inspection);
    };
    const handleChangeInspection = (event) => {
        let updateInspection = { ...changeInspection };
        updateInspection[event.target.name] = event.target.value;
        setChangeInspection(updateInspection);
    };
    const handleClickOpenDeleteQuestion = () => {
        setOpenDeleteQuestion(true);
    };
    const handleClickCloseDeleteQuestion = () => {
        setOpenDeleteQuestion(false);
    };
    const { id } = useParams();
    // 데이터 로드
    useEffect(() => {
        const loadQuestion = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/question/${id}/${cookies.LoginKey}`, config).then((Response) => {
                let questionData = Response.data.question;
                questionData.Inspection.Comment = questionData.Inspection.Comment === 'undefined' ? null : questionData.Inspection.Comment;    
                if(Response.data.beforeQuestionId){
                    setBeforeQuestionId(Response.data.beforeQuestionId._id);
                }
                if(Response.data.nextQuestionId){
                    setNextQuestionId(Response.data.nextQuestionId._id);
                }
                setQuestion(questionData);

            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadQuestion();
    }, [id]);

    // 문제 상태 변경
    const handleChangeState = async () => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            QuestionId: question._id,
            State: question.State === 1 ? 2 : 1,
            loginKey: cookies.LoginKey
        });
        await axios.patch(`/api/admin/question`, body, config).then((Response) => {
            setOpenInspection(false);
            setTimeout(() => {
                setQuestion({ ...question, State: question.State === 1 ? 2 : 1 });
                setUpdating(false);
            }, 1000);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    // 검수 상태 변경
    const handleClickInspection = async () => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            QuestionId: question._id,
            Inspection: changeInspection,
            loginKey: cookies.LoginKey
        });
        await axios.patch(`/api/admin/question/inspection`, body, config).then((Response) => {
            setQuestion({ ...question, Inspection: changeInspection });
        }).catch((Error) => {
            console.log(Error);
        });
    };

    // 문제 삭ㅔ
    const handleClickDelete = async () => {
        const res = await axios({
            method: 'DELETE',
            url: '/api/admin/question',
            data: {
                QuestionId: question._id,
                loginKey: cookies.LoginKey    
            }
        });
        await res.json().then((Response) => {
            setTimeout(() => {
                navigate(`/admin/question`);
            }, 1000);
        }).catch((Error) => {
            console.log(Error);
        });
    };
    return (
        <>
            <Layout pages={pages} title={title}>
                <QuestionDetailInfo
                    question={question}
                    beforeQuestionId={beforeQuestionId}
                    nextQuestionId={nextQuestionId}
                    handleChangeState={handleChangeState}
                    updating={updating}
                    handleClickOpenInspection={handleClickOpenInspection}
                    handleClickOpenDelete={handleClickOpenDeleteQuestion}
                />
            </Layout>
            <QuestionInspectionModal
                title='검수 관리'
                openInspection={openInspection}
                handleClickCloseInspection={handleClickCloseInspection}
                question={question}
                changeInspection={changeInspection}
                handleChangeInspection={handleChangeInspection}
                handleClickInspection={handleClickInspection}
            />
            <DeleteModal
                openDelete={openDeleteQuestion}
                handleClickCloseDelete={handleClickCloseDeleteQuestion}
                handleClickDelete={handleClickDelete}
                title='문제 정보 삭제'
            >
                문제 정보가 삭제됩니다.<br />삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
            </DeleteModal>
        </>
    )
};

export default QuestionInfo;