import { Button,Avatar, Grid} from "@mui/material";

const UserUpdateView = (props) => {

  return ( 

  <Grid container justifyContent={"center"}>
    <Grid container lg={10} xl={5} justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"25px" ,padding:"30px 0"}}>
      <Grid item lg={12} xl={12}  >
        <h1 style={{textAlign:"center" ,fontSize:"32px"}}>정보 수정</h1>
      </Grid>
      <Grid item lg={12} xl={12} mb={2} sx={{display:"flex",justifyContent:"center"}}>
        <Avatar sx={{width:"160px",height:"160px"}} src={props.imgBase64 ? props.imgBase64 : props.academy?.Logo} />
      </Grid>
      <Grid item lg={12} xl={12} sx={{textAlign:"center"}}>
        <Button disableElevation sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",'&:hover':{ background:"#FFFFFF" },marginRight:"20px",background:"#FFFFFF",border:"1px solid #F05D38",color:"#F05D38" }}onClick={props.handleClickDeleteProfile}>삭제</Button>
        <label htmlFor='Profile' className='upload_profile'>
          <input  name='Profile' id='Profile' type='file' onChange={props.handleChangeProfile}/>
          <Button component='span' disableElevation color='warning' sx={{ width:"155px",'&:hover':{ background:"#F05D38" },fontSize:"15px",borderRadius:"10px" }} variant='contained'>
            등록
          </Button>
        </label>
      </Grid>
      <Grid item lg={8} xl={4.9} >
        <h1>학원</h1>
        <input type="text" name="Name"value={props.academy?.Name || ""}style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}} onChange={props.handleChange}/>
      </Grid>
      <Grid item lg={8} xl={5} sx={{ marginLeft:"10px" ,'@media (max-width:1535px)': {marginLeft:'0px'},}}>
        <h1>이름</h1>
        <input type="text"name="Representative"value={props.academy?.Representative || ""}  style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px" }}onChange={props.handleChange}/>
      </Grid>
      <Grid item lg={8} xl={10}>
        <h1>전화번호</h1>
        <input type="text"name="Phone"value={props.academy?.Phone || ""}  style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}onChange={props.handleChange}/>
      </Grid>
      <Grid item lg={8} xl={10}>
        <h1>주소</h1>
        <input type="text"name="Address"value={props.academy?.Address || ""}  style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}onChange={props.handleChange}/>
      </Grid>
      <Grid item lg={8} xl={10}>
        <h1>사업자 번호</h1>
        <input type="text"name="BusinessRegistation"value={props.academy?.BusinessRegistation || ""}  style={{borderRadius:"10px",background:"#F7F7F7",border:"0px",outline:"none",width:"100%",height:"36px",fontSize:"24px",padding:"6px 15px"}}onChange={props.handleChange}/>
      </Grid>
      <Grid item lg={8} xl={10} mt={6} sx={{textAlign:"center"}}>
        <Button disableElevation color='warning'onClick={()=>props.handleAcademyUpdate()} sx={{ width:"155px",'&:hover':{ background:"#F05D38" },fontSize:"15px",borderRadius:"10px" }} variant='contained'>수정</Button>
      </Grid>
    </Grid>
  </Grid> 
  );
}
 
export default UserUpdateView;