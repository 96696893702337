import { Avatar, Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Pagination } from "@mui/material";
import { useState } from "react";

import { AcademySearchForm } from "components/form";

const TicketListView = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  
  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = props.rows?.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
return (   
 <Grid container justifyContent={"center"}>
   <Grid container lg={11.5} xl={8}  justifyContent={"center"}>
    <Grid item={true}  lg={11.8} xl={12}  sx={{background:"#FFFFFF",borderRadius:'15px',padding:"30px" }}>
    <AcademySearchForm
      search={props.search}
      handleChange={props.handleChangeSearch}
      searchGuide="이름 / 이메일로 검색"
      />
      {visibleData.length > 0 ? 
        <TableContainer>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <TableHead className="academy_table_head">
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell align="left">프로필</TableCell>
                <TableCell align="center">이름</TableCell>
                <TableCell align="center">전화번호</TableCell>
                <TableCell align="center">이메일</TableCell>
                <TableCell align="center" >전달 일자</TableCell>
                <TableCell align="center" width={'50px'}>응시권</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {visibleData.map((row, i) => (
              <TableRow key={i} sx={{ marginBottom: '10px'  }}>
                <TableCell component="th" scope="row" width={'50px'}>
                  {i + 1}
                </TableCell>
                <TableCell align="center" width={'50px'}>
                  <Avatar src={row.Profile}/>
                </TableCell>
                <TableCell align="center" >{row.name}</TableCell>
                <TableCell align="center" >{row.phone}</TableCell>
                <TableCell align="center">{row.email}</TableCell>
                <TableCell align="center">{row.createdAt}</TableCell>
                <TableCell align="center" width={'50px'}>{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </TableContainer>
        :
        <h1 style={{textAlign:"center",marginTop:"50px"}}>전달한 응시권이 없습니다.</h1>
        }
        <Pagination
        count={Math.ceil(props.rows?.length / pageSize)} // 전체 페이지 수
        page={currentPage} // 현재 페이지
        onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
        color='warning'
        sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
      />
    </Grid>
   </Grid>
  </Grid>
  );
}
 
export default TicketListView;