import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination,Grid } from "@mui/material";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { CircularProgress } from "@mui/material";

import { ClassListView } from "components/Academy/class";
import { Layout } from "components/Academy/Layout";
import { AcademySearchForm } from "components/form";

const ClassList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [ cookies] = useCookies(['LoginKey']);
  const loginKey = cookies.LoginKey;
  const navigate = useNavigate();

  const [search, setSearch] = useState(""); 
  const [classList,setClassList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const rows = [];
  for (let i = 0; i < classList.length; i++) {
    rows.push({
      id: i + 1,
      name: classList[i].ClassName,
      student: classList[i].Students.length,
      manager: classList[i].ManagerName,
      Profile:classList[i].ClassImage,
      classNo:classList[i].ClassNo,
    });
  };
  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = rows?.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // 선생님 디테일 뷰
  const handleClickCell = (classNo) => {
      navigate(`/academy/class/${classNo}`);
  };

  const loadClassData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/class/${loginKey}`, config).then((Response)=>{
      setClassList(Response.data.Classs)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };  

  //검색 함수
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const filterClasss = (datas) => {
    datas.sort();
    datas = datas.filter((data) => {
      return (
        data.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        data.manager.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    });
    return datas;
  };

  useEffect(()=>{
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);
    loadClassData()
  },[]);

  return (
    <Layout >
        {
      loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
        <>
          <h1 style={{width:"91.8%",margin:"0px auto 45px auto",fontSize:"32px",color:"#000000"}}>클래스 목록</h1>
          <Grid container justifyContent={"center"}>
            <Grid container lg={11} xl={11}  sx={{  background:"#FFFFFF",borderRadius:'15px',padding:"30px" }}>
              <div>
                <AcademySearchForm
                  search={search}
                  handleChange={handleChangeSearch}
                  searchGuide="이름 / 담당자로 검색"
                />
              </div>
              <ClassListView
                rows={filterClasss(visibleData)} 
                handleClickCell={handleClickCell}
                search={search}
                handleChange={handleChangeSearch}
              />
              <Pagination
                count={Math.ceil(rows?.length / pageSize)} // 전체 페이지 수
                page={currentPage} // 현재 페이지
                onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
                color='warning'
                sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
              />
            </Grid>
          </Grid>
        </>
    }
  </Layout>
  );
};

export default ClassList;
