import React, { useEffect, useState } from 'react';
import { Button, Dialog,TextField, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, Grid, Link } from '@mui/material';
import { Close, AddAPhoto, FlipCameraIos } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import * as ChangeText from 'components/function/ChangeText';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const HomeworkPreviewModal = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const preview = props.preview;
    
    const [imgShow, setImgShow] = useState([]);
    const [imgFiles, setImgFiles] = useState([]);
    const [viewMemo, setViewMemo] = useState(false);
    const [studentData ,setStudentData] =useState({
        schoolClass:0,
        schoolNumber:0
    })

    const handleChange = (e) => {
        let changeUserInfo = { ...studentData };
        changeUserInfo[e.target.name] = e.target.value;
        setStudentData(changeUserInfo);
      };

    const handleChangeMemoPicture = (event) => {
        let addImgShow = [...imgShow];
        let addImg = [...imgFiles];
        addImgShow = [];
        addImg = [];
        if(event.target.files[0]){
            for(let i = 0; i < event.target.files.length; i++){
                addImg.push(event.target.files[i]);
                const currentImageUrl = URL.createObjectURL(event.target.files[i]);
                addImgShow.push(currentImageUrl);
            }
            setImgFiles(addImg);
            setImgShow(addImgShow);
        }
    };

    const handleClose = () => {
        setImgShow([]);
        setImgFiles([]);
        setViewMemo(false);
        props.setOpen(false);
    };

    const uploadMemo = async() => {
        if(imgFiles.length > 0){
            const formData = new FormData();
            formData.append('loginKey', cookies.LoginKey);
            formData.append('homeworkNo', preview.HomeworkNo);
            for(let i = 0; i < imgFiles.length; i++){
                formData.append('imgFiles', imgFiles[i]);
            }
            if(preview.Students[0].MemoPictures[0]){
                for(let i = 0; i < preview.Students[0].MemoPictures.length; i++){
                    formData.append('beforeMemo[]', preview.Students[0].MemoPictures[i]);
                }
            }else{
                formData.append('beforeMemo[]', '');
            }
            const config = { headers: { 'content-type': 'multipart/form-data' }};
            await axios.put(`/api/homework/memo/app`, formData, config).then((Response) => {
                let changePreview = { ...props.preview };
                changePreview.Students[0].MemoPictures = Response.data.MemoPictures;
                props.setPreview(changePreview);
                setViewMemo(true);
                setImgShow([]);
                setImgFiles([]);
            }).catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        }else{
          return false
        }
    };


    const handleClickBtn = async () => {
            if(new Date() < new Date(preview.DueDate).setHours(23,59,59,999)){
                if(preview.Students[0].Questions.length < preview.QuestionsCnt){
                    if (preview.Section === 1000) {
                        const body = JSON.stringify({
                            schoolClass: parseInt(studentData.schoolClass), //반
                            schoolNumber:parseInt(studentData.schoolNumber),//번호
                            loginKey: cookies.LoginKey,
                        })
                        const config = { headers: { 'Content-type': 'application/json' }};
                        await axios.put(`/api/student/custom/homework`, body, config).then((Response) => {
                            navigate(`/question/homework/${preview.HomeworkNo}`);
                        }).catch((Error) => {
                            enqueueSnackbar(`Network Error`, { variant: 'error' });
                        });
                    }else{
                        navigate(`/question/homework/${preview.HomeworkNo}`);
                    }
                }else{
                    navigate(`/question/homework/result/${preview.HomeworkNo}`);
                }
            }else{
                navigate(`/question/homework/result/${preview.HomeworkNo}`);
            }
    };
    return(
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby='alert-dialog-slide-description-ResetPassword'
                fullWidth={true}
            >
                <DialogTitle>
                    {props.title}
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description'>
                        <table className='homework_preview_table'>
                            {preview.Section === 1000 ? 
                            <tr><th>날짜</th><td colSpan={2}>{`${ChangeText.YyyyMmDdHhMmSs(preview.StartDate)} ~ ${ChangeText.YyyyMmDdHhMmSs(preview.DueDate)}`}</td></tr>:
                            <tr><th>날짜</th><td colSpan={2}>{`${ChangeText.YyyyMmDd(preview.StartDate)} ~ ${ChangeText.YyyyMmDd(preview.DueDate)}`}</td></tr>
                            }
                            {preview.Section === 1000 ? 
                            <tr><th>제목</th><td colSpan={2}>{preview.Title}</td></tr>
                             :                            
                            <tr><th>단원</th><td colSpan={2}>{ChangeText.Part(preview.Section)} &gt; {ChangeText.MiddlePart(preview.Section)} &gt; {ChangeText.Section(preview.Section)}</td></tr>
                            }
                            <tr><th>클래스</th><td colSpan={2}>{preview.Class_id.ClassName}</td></tr>
                            <tr><th>선생님</th><td colSpan={2}>{preview.Manager_id.Name}선생님</td></tr>
                            <tr><th>진행률</th><td colSpan={2}>{`${ChangeText.Percent(preview.Students[0].Questions.length/preview.QuestionsCnt)} (${preview.Students[0].Questions.length}/${preview.QuestionsCnt})`}</td></tr>
                            <tr>
                                <th>정답률</th>
                                {new Date() >= new Date(preview.DueDate).setHours(23,59,59,999) ?
                                <td colSpan={2}>
                                    {ChangeText.Percent(preview.Students[0].Questions.filter((item) => { return item.Check === 1 }).length/preview.QuestionsCnt)}
                                    &nbsp;({preview.Students[0].Questions.filter((item) => { return item.Check === 1 }).length}/{preview.QuestionsCnt})
                                </td> :
                                preview.Students[0].Questions.length < preview.QuestionsCnt ?
                                <td>문제 풀이중</td>:
                                <td>체점중</td>}
                            </tr>
                            <tr><th>전달사항</th><td colSpan={2}>{preview.Notice ? preview.Notice : '-'}</td></tr>
                            {preview.Section === 1000 ?
                   
                             <tr>
                                <th>작성란 </th>
                                <td >
                                    <TextField id="standard-basic"label='반' type={'number'}  variant="standard" name='schoolClass' onChange={handleChange} />
                                    <TextField id="standard-basic" label='번호'type={'number'}  variant="standard" name='schoolNumber'onChange={handleChange} sx={{marginLeft:"8px"}}/>
                                </td>
                            </tr>:" "
                            }
                           
                            <tr>
                                <th style={{ verticalAlign: 'top', paddingTop: '12px' }}>풀이</th>
                                <td className='memo_picture_images'>          
                                    {imgShow[0] ?
                                        imgShow.map((img) => (
                                            <div style={{ backgroundImage: `url('${img}')`}}/>
                                        ))
                                        :preview.Students[0].MemoPictures[0] ? 
                                            viewMemo ?
                                                <>
                                                <Link href='javascript:;' onClick={() => setViewMemo(false)}>풀이 숨기기</Link>
                                                {preview.Students[0].MemoPictures.map((img) => (
                                                    <div style={{ backgroundImage: `url('${img}')`}}/>
                                                ))}
                                                </>:
                                                <Link href='javascript:;' onClick={() => setViewMemo(true)}>풀이 보기 [{preview.Students[0].MemoPictures.length}]</Link>:
                                            <>등록된 풀이가 없습니다.</>}
                                </td>
                                <td style={{ width: '10%', textAlign: 'center', verticalAlign: 'top', paddingTop: '0px' }}>
                                    <label htmlFor='MemoPicture' className='upload_profile'>
                                        <input accept='image/png, image/jpeg, image/jpg' multiple='multiple' name='MemoPicture' id='MemoPicture' type='file' onChange={handleChangeMemoPicture}/>
                                        <IconButton component='span'>{imgShow.length || preview.Students[0].MemoPictures.length > 0 ? <FlipCameraIos/> : <AddAPhoto/>}</IconButton>
                                    </label>  
                                </td>
                            </tr>
                        </table>
                    </DialogContentText>
                </DialogContent>
                <Grid container>
                    <Grid xs={3} style={{ padding: '8px 16px'}}>
                        <Button onClick={uploadMemo} disabled={imgFiles.length > 0 ? false : true}>풀이저장</Button>
                    </Grid>
                    <Grid xs={9} style={{ padding: '8px 16px', textAlign: 'right' }}>
                        <Button onClick={handleClose}>닫기</Button>
                        {preview.Section === 1000 ? 
                        <Button onClick={handleClickBtn} color='warning' variant='contained' disableElevation disabled={ new Date() > new Date(preview.DueDate) || studentData.schoolClass === 0 && studentData.schoolNumber === 0}>문제보기</Button>:
                        <Button onClick={handleClickBtn} color='warning' variant='contained' disableElevation disabled={ new Date() > new Date(preview.DueDate)}>문제보기</Button>
                        }
                    </Grid>
                    <Grid xs={12} style={{ padding: '8px 16px',textAlign:"center"}}>
                    {preview.Section === 1000 && new Date() > new Date(preview.DueDate) ?
                        <span style={{fontWeight:"700",fontSize:"16px"}}>평가 시간이 종료되었습니다.</span>:
                        ""}
                    </Grid>

                </Grid>
            </Dialog>
        </div>
    );
};

export default HomeworkPreviewModal;