import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionSolve } from 'components/user/question';
import { QuestionSection } from 'components/function/SortQuestions';

const Solve = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const { enqueueSnackbar } = useSnackbar();
  const loginKey = cookies.LoginKey;
  const [questions, setQuestions] = useState({});
  const [sectionQuestionCount, setSectionQuestionCount] = useState([{ _id: '', Count: '' }]);

  /* 유저의 문제풀이정보를 가져오는 함수 */
  const getSolveInfo = async () => {
    let cnt = 0;
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/info/${loginKey}`, config).then((Response) => {
      let userQuestions = [];
      if(!Response.data.Message){
        for(let i = 0; i < Response.data.result.User_id.Questions.length; i++){
          if(Response.data.result.User_id.Questions[i].Status === 1){
            cnt += 1;
          }
        }
        if (cnt !== 0){
          for (let i = 0; i < Response.data.result.User_id.Questions.length; i++) {
            if(Response.data.result.User_id.Questions[i].Status === 1){
              userQuestions.push({
                Question_id: Response.data.result.User_id.Questions[i].Question_id._id,
                QuestionNo: Number(Response.data.result.User_id.Questions[i].Question_id.Question),
                Check: Number(Response.data.result.User_id.Questions[i].Check),
                Status: Number(Response.data.result.User_id.Questions[i].Status),
                Section: Number(Response.data.result.User_id.Questions[i].Question_id.Section),
                CreatedAt: Response.data.result.User_id.Questions[i].CreatedAt,
              });
            }
          }
        }
      }else{
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      }
      let obj = QuestionSection(userQuestions);
      setQuestions(obj);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  const getQuestionCounting = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/counting`, config).then((Response) => {
      setSectionQuestionCount(Response.data);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(() => {
    getSolveInfo(); //  처음 접속 시에 문제풀이 정보 저장
    getQuestionCounting()
  }, []);

  return (
    <Layout>
      <QuestionSolve questions={questions} LoginKey={cookies.LoginKey} sectionQuestionCount={sectionQuestionCount} />
    </Layout>
  );
};

export default Solve;
