import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ClassMenu } from 'components/teacher/layout';
import { ParentChildUpdate } from 'components/parent/child';

const ChildEdit = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { ChildNo } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [childData, setChildData] = useState({
    ChildNo: '',
    Child_id: { Name: '', Profile: '' },
    Connect: '',
    NickName: ''
  });
  const [updating, setUpdating] = useState(false);

  const handleChangeChild = (event) => {
    let changeChild = { ... childData };
    changeChild[event.target.name] = event.target.value;
    setChildData(changeChild);
  };

  const handleClickUpdate = async() => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      studentNo: ChildNo,
      nickName: childData.NickName,
      loginKey: LoginKey
    });
    await axios.put('/api/parent/child', body, config).then((Response) => {
      setTimeout(() => {
        navigate(`/parent`);
      }, 500);
      if(!Response.data.ErrorMessage){
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });  
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });  
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  /* 학부모 학생 취소 */
  const handleClickDelete = async() => {
    await axios({
      method: 'delete',
      url: `/api/parent/child`,
      data: {
        loginKey: LoginKey,
        studentNo: ChildNo
      }
    })
    .then((Response) => {
      if(Response.data.Message){
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
        navigate(-1);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(() => {
    const loadChildData = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/parent/child/${ChildNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          setChildData(Response.data.Children);
        }else{
          navigate(`/parent`, { replace: true });
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      }).catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }
    loadChildData();
  }, []);

  return (
    <ClassMenu>
      <ParentChildUpdate
        childData={childData}
        updating={updating}
        handleChangeChild={handleChangeChild}
        handleClickUpdate={handleClickUpdate}
        handleClickDelete={handleClickDelete}
      />
    </ClassMenu>
  )
};

export default ChildEdit;