import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import NormalDistribution from 'normal-distribution'; 
import { useCookies } from 'react-cookie';
import { ArrowRight, ArrowLeft } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';

const ParentDashboardDetailMonth = (props) => {
    const [cookie] = useCookies(['ChildNickName']);
    const navigate = useNavigate();
    const { ChildNo, Month } = useParams();
    const nowMonth = Number(Month.substring(Month.length, 4));
    const nowYear = Number(Month.substring(0, Month.length-2));
    let beforeMonth;
    let nextMonth;
    if(Number(Month.substring(Month.length, 4)) === 12){
        beforeMonth = `${nowYear}11`;
        nextMonth = `${nowYear+1}01`;
    }else if(Number(Month.substring(Month.length, 4)) === 1){
        beforeMonth = `${nowYear-1}12`;
        nextMonth = `${nowYear}02`;
    }else{
        beforeMonth = `${nowYear}${nowMonth-1 < 10 ? `0${nowMonth-1}` : nowMonth-1}`
        nextMonth = `${nowYear}${nowMonth+1 < 10 ? `0${nowMonth+1}` : nowMonth+1}`
    }
    const normDist = new NormalDistribution(0, 1);
    const schoolDist = new NormalDistribution(props.childData.School_id.WooroZscore, 1);

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={6}>
                    <div className='teacher_dashboard_diagnosis'>
                        <h2>
                            <IconButton aria-label='delete' onClick={() => navigate(`/parent/${ChildNo}/dashboard/month/${beforeMonth}`, { replace: true })}>
                                <ArrowLeft/>
                            </IconButton>
                            [{`${nowYear}년 ${nowMonth}월`}]
                            <IconButton aria-label='delete' onClick={() => navigate(`/parent/${ChildNo}/dashboard/month/${nextMonth}`, { replace: true })}>
                                <ArrowRight/>
                            </IconButton>
                        </h2>
                        <p>학생 : <b>{props.childData.Name}</b>{cookie.ChildNickName !== 'undefined' ? ` (${cookie.ChildNickName})` : ''}</p>
                        <p>학교 : <b>{props.childData.School_id.Name}</b> · <b>{props.childData.Attending === 1 ? ChangeText.Grade(props.childData.Grade) : ''} {ChangeText.Attending(props.childData.Attending)}</b></p>
                        <hr/>
                        <p>진단 횟수 : <b>{props.diagnosisDatas[0].ZScore ? props.diagnosisDatas.length : 0}회</b></p>
                        <p>
                            전국 성적 :&nbsp;
                            {props.lastDiagnosisData.AllGrade ?
                            `${ChangeText.Percent(1-normDist.cdf(props.lastDiagnosisData.AllGrade))} > `:
                            null}
                            {props.diagnosisDatas[0].ZScore ?
                            <>
                            <b>{ChangeText.Percent(1-normDist.cdf(props.diagnosisDatas[0].AllGrade))}</b>&nbsp;
                            {props.lastDiagnosisData.AllGrade ?
                            normDist.cdf(props.diagnosisDatas[0].AllGrade) - normDist.cdf(props.lastDiagnosisData.AllGrade) >= 0 ?
                            <span style={{ color: 'red' }}> (↑{ChangeText.Percent(normDist.cdf(props.diagnosisDatas[0].AllGrade) - normDist.cdf(props.lastDiagnosisData.AllGrade))}p)</span>:
                            <span style={{ color: 'blue' }}> (↓{ChangeText.Percent(normDist.cdf(props.lastDiagnosisData.AllGrade) - normDist.cdf(props.diagnosisDatas[0].AllGrade))}p)</span>:
                            <span style={{ color: 'green' }}> (New)</span>}
                            </>:
                            null}
                        </p>
                        <p>
                            학교 성적 :&nbsp;
                            {props.lastDiagnosisData.AllGrade ?
                            `${ChangeText.Percent(1-schoolDist.cdf(props.lastDiagnosisData.AllGrade))} > `:
                            null}
                            {props.diagnosisDatas[0].ZScore ?
                            <>
                            <b>{ChangeText.Percent(1-schoolDist.cdf(props.diagnosisDatas[0].AllGrade))}</b>&nbsp;
                            {props.lastDiagnosisData.AllGrade ?
                            schoolDist.cdf(props.diagnosisDatas[0].AllGrade) - schoolDist.cdf(props.lastDiagnosisData.AllGrade) >= 0 ?
                            <span style={{ color: 'red' }}> (↑ {ChangeText.Percent(schoolDist.cdf(props.diagnosisDatas[0].AllGrade) - schoolDist.cdf(props.lastDiagnosisData.AllGrade))}p)</span>:
                            <span style={{ color: 'blue' }}> (↓ {ChangeText.Percent(schoolDist.cdf(props.lastDiagnosisData.AllGrade) - schoolDist.cdf(props.diagnosisDatas[0].AllGrade))}p)</span>:
                            <span style={{ color: 'green' }}> (New)</span>}
                            </>:
                            null}
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='teacher_dashboard_diagnosis_table'>
                        <table>
                            <tr>
                                <th>진단일</th>
                                <th>단원</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th colSpan={2}>전국 상위</th>
                                <th colSpan={2}>학교 상위</th>
                            </tr>
                            {props.diagnosisDatas[0].ZScore ?
                            props.diagnosisDatas.map((diagnosis, i) => (
                                <tr>
                                    <td>{ChangeText.YyyyMmDd(diagnosis.createdAt)}</td>
                                    <td>{ChangeText.Part(diagnosis.Section)} &gt; {ChangeText.MiddlePart(diagnosis.Section)} &gt; {ChangeText.Section(diagnosis.Section)}</td>
                                    {diagnosis.Questions.map((Question) => (
                                    <td>{Question.Check === 1 ? 'O' : 'X'}</td>
                                    ))}
                                    <td>{ChangeText.Percent(1-normDist.cdf(diagnosis.AllGrade))}</td>
                                    <td>
                                        {i === props.diagnosisDatas.length-1 ?
                                        props.lastDiagnosisData.AllGrade ?
                                        (normDist.cdf(diagnosis.AllGrade) - normDist.cdf(props.lastDiagnosisData.AllGrade)) >= 0?
                                        <span style={{ color: 'red' }}>↑ {ChangeText.Percent(normDist.cdf(diagnosis.AllGrade) - normDist.cdf(props.lastDiagnosisData.AllGrade))}p</span>:
                                        <span style={{ color: 'blue' }}>↓ {ChangeText.Percent(normDist.cdf(props.lastDiagnosisData.AllGrade) - normDist.cdf(diagnosis.AllGrade))}p</span>:
                                        <span style={{ color: 'green' }}>New</span>:
                                        (normDist.cdf(diagnosis.AllGrade) - normDist.cdf(props.diagnosisDatas[(i+1)].AllGrade)) >= 0?
                                        <span style={{ color: 'red' }}>↑ {ChangeText.Percent(normDist.cdf(diagnosis.AllGrade) - normDist.cdf(props.diagnosisDatas[(i+1)].AllGrade))}p</span>:
                                        <span style={{ color: 'blue' }}>↓ {ChangeText.Percent(normDist.cdf(props.diagnosisDatas[(i+1)].AllGrade) - normDist.cdf(diagnosis.AllGrade))}p</span>
                                        }
                                    </td>
                                    <td>{ChangeText.Percent(1-schoolDist.cdf(diagnosis.AllGrade))}</td>
                                    <td>
                                        {i === props.diagnosisDatas.length-1 ?
                                        props.lastDiagnosisData.AllGrade ?
                                        (schoolDist.cdf(diagnosis.AllGrade) - schoolDist.cdf(props.lastDiagnosisData.AllGrade)) >= 0?
                                        <span style={{ color: 'red' }}>↑ {ChangeText.Percent(schoolDist.cdf(diagnosis.AllGrade) - schoolDist.cdf(props.lastDiagnosisData.AllGrade))}p</span>:
                                        <span style={{ color: 'blue' }}>↓ {schoolDist.cdf(props.lastDiagnosisData.AllGrade) - ChangeText.Percent(schoolDist.cdf(diagnosis.AllGrade))}p</span>:
                                        <span style={{ color: 'green' }}>New</span>:
                                        (schoolDist.cdf(diagnosis.AllGrade) - schoolDist.cdf(props.diagnosisDatas[(i+1)].AllGrade)) >= 0?
                                        <span style={{ color: 'red' }}>↑ {ChangeText.Percent(schoolDist.cdf(diagnosis.AllGrade) - schoolDist.cdf(props.diagnosisDatas[(i+1)].AllGrade))}p</span>:
                                        <span style={{ color: 'blue' }}>↓ {ChangeText.Percent(schoolDist.cdf(props.diagnosisDatas[(i+1)].AllGrade) - schoolDist.cdf(diagnosis.AllGrade))}p</span>
                                        }
                                    </td>
                                </tr>
                            )):
                            <tr>
                                <td colSpan={11}>진단 결과가 없습니다.</td>    
                            </tr>}
                        </table>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ParentDashboardDetailMonth;