import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import {DiagnosisDetailView} from 'components/Academy/student/Diagnosis'
import { Layout } from "components/Academy/Layout";

const DiagnosisDetail = () => {
  const [ cookies] = useCookies(['LoginKey']);
  const {id,section} = useParams();
  const loginKey = cookies.LoginKey;
  const studentNo = id;
  const { enqueueSnackbar } = useSnackbar();

  const [student,setStudent] = useState();

   const handleStudentData1 = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${studentNo}/section/${section}/${loginKey}`, config).then((Response)=>{
      setStudent(Response.data.Student)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };
 
  useEffect(()=>{
    handleStudentData1()
  },[]);


  return (    
  <Layout>
    <DiagnosisDetailView  student={student}/>
  </Layout>
   );
}
 
export default DiagnosisDetail;