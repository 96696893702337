import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

import { Layout } from 'components/parent/layout';
import { ParentQuestionView } from 'components/parent/child/question';

const QuestionView = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { ChildNo, Section } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const loginKey = cookies.LoginKey;

    const [questionDatas, setQuestionDatas] = useState([{
        Check: '',
        CreatedAt: '',
        Question_id: {
            Info: { Counting: '', Correct: '', Time: '' },
            Question: ''
        },
        Select: '',
        Status: '',
        Time: '',
        Memo: ''
    }]);
    
    useEffect(() => {
        const loadDatas = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/parent/question/${ChildNo}/${Section}/${loginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    let loadQuestions = [...Response.data.QuestionList];
                    loadQuestions = loadQuestions.filter((item) => { return item.Status === 1 });
                    setQuestionDatas(loadQuestions);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                }
            }).catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadDatas();
    }, []);
    return(
        <Layout>
            <ParentQuestionView 
                questionDatas={questionDatas}
            />
        </Layout>
    );
};

export default QuestionView;