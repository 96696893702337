import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { TeacherPrice, TeacherPriceSuccess, TeacherPriceFail, TeacherPriceSuccessApp, TeacherPriceFailApp } from 'domain/teacher/price';

const PricePresenter = () => {
  return (
    <Routes>
      <Route path='/' element={<TeacherPrice />}/>
      <Route path='/success' element={<TeacherPriceSuccess />}/>
      <Route path='/fail' element={<TeacherPriceFail />}/>
      <Route path='/success/app' element={<TeacherPriceSuccessApp />}/>
      <Route path='/fail/app' element={<TeacherPriceFailApp />}/>
      <Route path='/*' element={<NotFound />}/>
    </Routes>
  ); 
};

export default PricePresenter;