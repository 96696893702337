import React, { useEffect } from 'react';
import { Backdrop, IconButton, ButtonGroup, Button } from '@mui/material';
import { Close, Create, Refresh, SmartButton } from '@mui/icons-material';

const QuestionMemo = (props) => {
    useEffect(() => {
        if(props.open){
            const scrollDisable = () => {
                window.document.getElementsByTagName('body')[0].classList.add('hidden');
            };
            scrollDisable();
        }else{
            const scrollAble = () => {
                window.document.getElementsByTagName('body')[0].classList.remove('hidden');
            };
            scrollAble();
        }
    }, [props.open]);
    const handleClickClose = () => {
        if(props.handleClickClose){
            props.handleClickClose();
        }else{
            props.setOpen(false);
        }
    };

    return (
        <div>
    
            <Backdrop
                sx={{ color: '#fff', zIndex: '10000001', backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
                open={props.open}
            >
                <canvas
                    style={{ backgroundColor: '#FFF', position: 'absolute', top: '0', left: '0', opacity: '0.5' }}
                    ref={props.canvasRef}
                    width={document.body.offsetWidth}
                    height={'1000'}
                />
                <IconButton 
                    aria-label='close' 
                    style={{ position: 'absolute', top: '5%', right: '5%' }} 
                    onClick={handleClickClose}
                >
                    <Close style={{ color: '#000' }}/>
                </IconButton>
                <ButtonGroup variant='outlined' color='warning' aria-label='outlined button group' style={{ position: 'absolute', bottom: '5%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    <Button variant={props.mode === 1 ? 'contained' : 'outlined'} onClick={() => props.setMode(1)}><Create /></Button>
                    <Button variant={props.mode === 2 ? 'contained' : 'outlined'} onClick={() => props.setMode(2)}><SmartButton style={{transform: 'rotate(-45deg) scaleX(-1)' }} /></Button>
                    <Button onClick={props.ClearDraw}><Refresh /></Button>
                </ButtonGroup>
            </Backdrop>
        </div>
    );
};

export default QuestionMemo;