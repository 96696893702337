import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Dashboard, Student, Manager, Question, School, Event, MockExam, Coupon, Achieve } from '.';
import NotFound from 'routes/NotFound';


// https://designrevision.com/demo/shards-dashboard-lite-react/blog-overview

const AdminPresenter = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const CheckStatus = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/login/${cookies.LoginKey}`, config).then((Response) => {
                if(Response.data.Status === 5){
                    if(location.pathname.indexOf('/admin/question') > -1){
                        return false;
                    }else{
                        enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error'});
                        navigate('/admin/question');
                    }
                }else if(Response.data.Status > 5 ){
                    return false;
                }else{
                    enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error'});
                    navigate('/');
                }
            })
            .catch((Error) => {
                console.log(Error);
            });
        };
        if(cookies.LoginKey){
            CheckStatus();
        }else{
            enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error'});
            navigate('/');
        }
    }, []);
    return(
        <Routes>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='achieve/*' element={<Achieve />} />
            <Route path='student/*' element={<Student />} />
            <Route path='manager/*' element={<Manager />} />
            <Route path='question/*' element={<Question />} />
            <Route path='school/*' element={<School />} />
            <Route path='mockexam/*' element={<MockExam />} />
            <Route path='event/*' element={<Event />} />
            <Route path='coupon/*' element={<Coupon />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminPresenter;