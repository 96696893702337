import React from 'react';
import { UserLogin } from 'domain/user';

const LoginPresenter = () => {
  return (
    <>
      <UserLogin/>
    </>
  ); 
};

export default LoginPresenter;
