import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Link, Avatar, Menu, MenuItem } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WooroMathLogo from 'img/wooromath_logo.png';
import NotoSansKRWoff from 'fonts/NotoSansKR-Regular.woff';
import WooroTestLogoOnly from 'img/woorotest_logo_only.png'
import { AppBanner} from '/';

const drawerWidth = 240;
const navItems = [
    { title: '대시보드', link: 'dashboard'},
    { title: '숙제내기', link: 'homework/select'},
    // { title: '숙제내기', link: 'homework/create'},
    { title: '숙제관리', link: 'homework'},
    { title: '모의고사', link: 'mockexam'},
    { title: '수행평가', link: 'evaluation'},
    { title: '문제보기', link: 'question'}
];

const theme = createTheme({
    typography: {
        fontFamily: NotoSansKRWoff
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root:{
                    backgroundColor: '#FFFFFF',
                    color: '#A4A4A4'
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#A4A4A4',
                    fontSize: '16px',
                    padding: '0px 3px 3px 3px',
                    margin: '0px 9px',
                    '&:hover': {
                        textDecoration: 'none',
                        borderBottom: '1px solid #F05D38',
                        color: '#F05D38'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '&:hover': {
                        backgroundColor: ''
                    }
                },
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#000'
                }
            }
        }
    }
});

const MainMenu = (props) => {
    const { window } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [cookies,setCookie , removeCookie] = useCookies(['LoginKey', 'Name', 'UserNo', 'Profile', 'ClassNo', 'ClassName', 'ClassImage','Close','Academy_id','AcademyNo','AcademyName']);
    const { ClassNo } = useParams();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickProfile = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const appBannerClose = ()=>{
        setCookie('Close' , true, { path: '/'})
    };

    const handleClickLogout = () => {
        removeCookie('LoginKey', { path: '/'});
        removeCookie('Academy_id', { path: '/'});
        removeCookie('AcademyName', { path: '/'});
        removeCookie('AcademyNo', { path: '/'});
        removeCookie('Name', { path: '/'});
        removeCookie('UserNo', { path: '/'});
        removeCookie('Profile', { path: '/'});
        removeCookie('ClassNo', { path: `/`});
        removeCookie('ClassName', { path: `/`});
        removeCookie('ClassImage', { path: `/`});
        navigate('/');
    };

    const handleClickSelect = () => {
        removeCookie('ClassNo', { path: `/`});
        removeCookie('ClassName', { path: `/`});
        removeCookie('ClassImage', { path: `/`});
        navigate('/teacher');
    };

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <AppBar component='nav' className='user_navbar'>
                { cookies.Close === 'true' ?
                     " "
                    :
                        <AppBanner appBannerClose={appBannerClose}/>
                    }
                    <Toolbar>
                        <Grid sx={{ mr: 2, display: { sm: 'none' } }}>
                            <IconButton
                                color='inherit'
                                aria-label='open drawer'
                                edge='start'
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                            <img src={WooroMathLogo} alt='wooromath logo' className='menu_mobile_logo' onClick={() => navigate(`/teacher/${ClassNo}`)}/>
                        </Grid>
                        <Typography
                            variant='h6'
                            component='div'
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            <img src={WooroMathLogo} alt='wooromath logo' className='menu_logo' onClick={() => navigate(`/teacher/${ClassNo}`)} />
                            {navItems.map((item) => (
                            <Link 
                                href={item.link ? 
                                    `/teacher/${ClassNo}/${item.link}`:
                                    'javascript:;'
                                }
                                onClick={
                                    item.link ?
                                    null:
                                    NotReady
                                }
                                underline='hover'
                                className={
                                    location.pathname.indexOf(`/${item.link}`) > -1 && item.link ?
                                        (item.title === '숙제관리' && location.pathname.indexOf('/homework/select') > -1 ) || (item.title === '문제보기' && location.pathname.indexOf('/homework') > -1)?
                                        null:
                                        'this_link':
                                    null}
                                >
                                {item.title}
                            </Link>
                            ))}
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <div className='menu_user_name' onClick={handleClickProfile}>
                                <IconButton
                                    size='small'
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup='true'
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 32, height: 32, backgroundColor: '#FBFBFB' }} src={cookies.ClassImage ? cookies.ClassImage : WooroTestLogoOnly}/>
                                </IconButton>
                                <span>{cookies.ClassName !== 'undefined' ? cookies.ClassName : ''} <ArrowDropDownIcon/></span>
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* 모바일 */}
                <Box component='nav'>
                    <Drawer
                        container={container}
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
                            <Typography variant='h6' sx={{ my: 2 }}>
                                <img src={WooroMathLogo} alt='wooromath logo' onClick={() => navigate(`/`)} width='25%'/>
                            </Typography>
                            <Divider />
                            <List>
                                {navItems.map((item, i) => (
                                <ListItem key={i} disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={item.link ? () => navigate(`/teacher/${ClassNo}/${item.link}`) : NotReady}>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                                ))}
                                <Divider light variant='middle'/>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={handleClickSelect}>
                                        <ListItemText primary='클래스 선택하기'/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/teacher/${ClassNo}/propose`)}>
                                        <ListItemText primary='학생 신청 관리' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/teacher/${ClassNo}/ticket`)}>
                                        <ListItemText primary='모의고사 응시권' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={() => navigate(`/teacher/${ClassNo}/order`)}>
                                        <ListItemText primary='결제 내역' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{ textAlign: 'center' }} onClick={handleClickLogout}>
                                        <ListItemText primary='로그아웃' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id='account-menu'
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    className='profile_menu'
                >
                    <MenuItem onClick={handleClickSelect}>클래스 선택하기</MenuItem>
                    <MenuItem onClick={() => navigate(`/teacher/${ClassNo}/propose`)}>학생 신청 관리</MenuItem>
                    <MenuItem onClick={() => navigate(`/teacher/${ClassNo}/ticket`)}>모의고사 응시권</MenuItem>
                    <MenuItem onClick={() => navigate(`/teacher/${ClassNo}/order`)}>결제 내역</MenuItem>
                    <Divider />
                    <MenuItem onClick={handleClickLogout}>로그아웃</MenuItem>
                </Menu>
            </Box>
            
        </ThemeProvider>
  );
}

MainMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainMenu;
