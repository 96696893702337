import React from 'react';
import { Grid, Link } from '@mui/material';
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

import * as ChangeText from 'components/function/ChangeText';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
        theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EF4C23'
    }
}));

const Continue = (props) => {
    return(
        <>
            <div className='main_div_title'>
                <p className='main_sub_title'>이어서 문제풀기</p>
            </div>
            <Grid container>
                <Grid item xs={12} className='main_div_continue'>
                    {props.firstContinue.Section ? 
                    <div>
                        <span>{ChangeText.Part(props.firstContinue.Section)}&nbsp;&nbsp;&gt;&nbsp;&nbsp;{ChangeText.MiddlePart(props.firstContinue.Section)}&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
                        <p><Link href={`/qeustion/solve/${props.firstContinue.Section}`}>{ChangeText.Section(props.firstContinue.Section)}</Link></p>
                        <div className='main_div_continue_graph'>
                            <p className='title'>정답률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={props.firstContinue.correct/props.firstContinue.questionsCount*100} />
                            </div>
                            <p className='percent'>{ChangeText.IntPercent(props.firstContinue.correct/props.firstContinue.questionsCount)}</p>
                        </div>
                        <div className='main_div_continue_graph'>
                            <p className='title'>진행률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={props.firstContinue.questionsCount/ChangeText.SectionQuestions(props.firstContinue.Section)*100} />
                            </div>
                            <p className='percent'>{ChangeText.IntPercent(props.firstContinue.questionsCount/ChangeText.SectionQuestions(props.firstContinue.Section))}</p>
                        </div>
                    </div>
                    :
                    <div>
                        <span>수학&nbsp;&nbsp;&gt;&nbsp;&nbsp;다항식&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
                        <p><Link href='/question/solve/111'>다항식의 연산</Link></p>
                        <div className='main_div_continue_graph'>
                            <p className='title'>정답률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={0} />
                            </div>
                            <p className='percent'>0%</p>
                        </div>
                        <div className='main_div_continue_graph'>
                            <p className='title'>진행률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={0} />
                            </div>
                            <p className='percent'>0%</p>
                        </div>
                    </div>
                    }
                    {props.secondContinue.Section ? 
                    <div>
                        <span>{ChangeText.Part(props.secondContinue.Section)}&nbsp;&nbsp;&gt;&nbsp;&nbsp;{ChangeText.MiddlePart(props.secondContinue.Section)}&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
                        <p><Link href={`/question/solve/${props.secondContinue.Section}`}>{ChangeText.Section(props.secondContinue.Section)}</Link></p>
                        <div className='main_div_continue_graph'>
                            <p className='title'>정답률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={props.secondContinue.correct/props.secondContinue.questionsCount*100} />
                            </div>
                            <p className='percent'>{ChangeText.IntPercent(props.secondContinue.correct/props.secondContinue.questionsCount)}</p>
                        </div>
                        <div className='main_div_continue_graph'>
                            <p className='title'>진행률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={props.secondContinue.questionsCount/ChangeText.SectionQuestions(props.secondContinue.Section)*100} />
                            </div>
                            <p className='percent'>{ChangeText.IntPercent(props.secondContinue.questionsCount/ChangeText.SectionQuestions(props.secondContinue.Section))}</p>
                        </div>
                    </div>:
                    <div>
                        <span>수학&#8545;&nbsp;&nbsp;&gt;&nbsp;&nbsp;다항식&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
                        <p><Link href='/question/solve/112'>나머지 정리</Link></p>
                        <div className='main_div_continue_graph'>
                            <p className='title'>정답률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={0} />
                            </div>
                            <p className='percent'>0%</p>
                        </div>
                        <div className='main_div_continue_graph'>
                            <p className='title'>진행률</p>
                            <div className='barchart'>
                                <BorderLinearProgress variant="determinate" value={0} />
                            </div>
                            <p className='percent'>0%</p>
                        </div>
                    </div>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default Continue;