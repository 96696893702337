import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import * as ChangeText from 'components/function/ChangeText';
import { Grid, FormControl, Select, MenuItem, Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Button } from '@mui/material';
import { useParams,useNavigate } from "react-router-dom";

const TeacherHomeworkDetailQuestionView = (props) => {
  const {id,homeworkNo,ClassNo,questionNo}=useParams()
  const teacherNo=id
  const navigate = useNavigate();
  const config = {
    "fast-preview": { disabled: true },
    loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
    tex: {
        packages: { "[+]": ["html", "img"] },
        inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
      ],
      displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
      ],
    },
    options: {
        enableMenu: false,
    }
};
  return (
   <Grid container justifyContent={ "center"}>
    <Grid container lg={11} xl={10}justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"20.9306px"}}>
      <Grid item lg={11} xl={11} sx={{padding:"0px 0 60px 0"}}>
        <div className='academy_diagnosis_result_title'>
          <h2>문제 {props.filterQuestion ? props.filterQuestion.Question-props.filterQuestion.Section*10000 : null}</h2>
          <FormControl variant='standard' sx={{ minWidth: 80, textAlign: 'left', marginTop: '50px', marginBottom: '30px', float: 'right' }}>
              <Select
                value={questionNo}
                onChange={(e) => navigate(`/academy/teacher/${teacherNo}/${ClassNo}/homework/${homeworkNo}/${e.target.value}`, { replace: true })}
              >
              {props.question?.Question_ids.map((question ,i) => (
                  <MenuItem key={i} value={question.Question}>{question.Question-(question.Section*10000)}</MenuItem>
              ))}
              </Select>
          </FormControl>
        </div>
        <div>
          <MathJaxContext version={3} config={config}>
            {props.filterQuestion?.Content.map((content,i) => (
              <MathJax 
              renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
              text={content.replace('`\\(','').replace('\\)`','')}
              dynamic
              className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
              />
                ))}
          </MathJaxContext>
        </div>
        <div className='diagnosis_questions_answer'>
          {props.filterQuestion && props.filterQuestion.Answer &&
          props.filterQuestion?.NewChoices.map((choice, i) => (
            props.filterQuestion?.Answer === i+1 ?
            <div key={i}>
              <RadioButtonChecked className='homework_question_answer'/>
              <MathJaxContext version={3} config={config}>
                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                    choice.replace('`\\(','').replace('\\)`','')
                    } inline dynamic/>
              </MathJaxContext>
            </div>:
            <div key={i}>
              <RadioButtonUnchecked/>
              <MathJaxContext version={3} config={config}>
                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                    choice.replace('`\\(','').replace('\\)`','')
                    } inline dynamic/>
              </MathJaxContext>
            </div>))}
        </div>
      </Grid>
    </Grid>
    <Grid container lg={11} xl={10} mt={10} pb={4} pt={2} justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"20.9306px"}}>
      <Grid item lg={12} xl={12}>
        <TableContainer >
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px',width:"100%"  }} aria-label="simple table">
            <TableHead className="academy_table_head">
             <TableRow >
              <TableCell>No</TableCell>
              <TableCell align="center">이름</TableCell>
              <TableCell align="center">정답여부</TableCell>
              <TableCell align="center">풀이시간</TableCell>
              <TableCell align="center">메모여부</TableCell>
              <TableCell align="right"></TableCell>
             </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props?.students.map((student, i) => (
             <TableRow key={i}>
              <TableCell component="th" scope="row">{(i+1)}</TableCell>
              <TableCell align="center">{student.Name}</TableCell>
              <TableCell align="center">{ChangeText.QuestionCheck(student.Check)}</TableCell>
              <TableCell align="center">{ChangeText.QuestionMinute(student.Time)}</TableCell>
              <TableCell align="center" sx={{fontWeight:"700"}}>{student.Memo? 'O' : 'X'}</TableCell>
              <TableCell align="right"><Button onClick={()=>{navigate(`/academy/teacher/${teacherNo}/${ClassNo}/homework/${homeworkNo}/student/${student.StudentNo}`)}}>상세보기</Button></TableCell>
             </TableRow>
              ))}
            </TableBody>
          </Table>
       </TableContainer>
      </Grid>
    </Grid>
  </Grid>
   );
}
 
export default TeacherHomeworkDetailQuestionView;