import React, { useState } from 'react';

import { Layout } from 'components/teacher/layout';
import { TeacherQuestionSection } from 'components/teacher/class/question';

const Solve = () => {
  const [section, setSection] = useState();

  return (
    <Layout>
      <TeacherQuestionSection
        section={section}
        setSection={setSection}
      />
    </Layout>
  );
};

export default Solve;