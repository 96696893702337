import React from 'react';
import { UserCreate } from 'domain/user';

const RegisterPresenter = () => {
  return (
    <>
      <UserCreate/>
    </>
  ); 
};

export default RegisterPresenter;
