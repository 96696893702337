import { Grid, FormControl, InputLabel, Select, MenuItem, Button, OutlinedInput } from '@mui/material';

const AchieveCreateView = (props) => {
  const level =[1,2,3,4,5]
  return ( 
    <>
    <Grid container justifyContent='center'>
        <Grid xs={8} className='update_form'>
            <h3>업적 등록</h3>
            <FormControl fullWidth>
                <InputLabel id='part-label'>이름</InputLabel>
                <OutlinedInput
                    labelid='choice-label'
                    name='name'
                    label='문제'
                    value={props.achieveInfo.name}
                    onChange={props.handleChange}
                    multiline={true}
                /> 
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id='level-label'>난이도</InputLabel>
                <Select
                    labelid='level-label'
                    name='level'
                    label='대단원'
                    value={props.achieveInfo.level || 0}
                    onChange={props.handleChangeLevel}
                >
                    {level.map((middlePart, i) => (
                    <MenuItem value={i+1}>{i+1}</MenuItem>
                    ))}
                </Select>
            </FormControl>
             <FormControl fullWidth className='update_profile'>
                <InputLabel id='profile-label' shrink>이미지</InputLabel>
                <Grid container justifyContent='space-between'>
                    <Grid xs={6} >
                        {props.imgBase64 ? 
                        <img alt='question pictures' src={props.imgBase64} /> :
                        null}
                    </Grid>
                    <Grid xs={3} textAlign='right'>
                    <Button color='inherit' onClick={props.handleClickDeletePicture}>
                        삭제
                    </Button>
                    <label htmlFor='Profile' className='upload_profile'>
                        <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangePicture}/>
                        <Button component='span'>
                            등록
                        </Button>
                    </label>
                    </Grid>
                </Grid>
            </FormControl>
            <Button  variant='contained'  color='success' onClick={()=>{props.handleClickCreate()}}>
                업적 등록
            </Button>
          </Grid>  
        </Grid>  
      </>
  );
}
 
export default AchieveCreateView;