import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { MockExamQuestionCreateView } from 'components/admin/mockExam';

const MockExamQuestionCreate = () => { 
    const pages = [
        { title: '모의고사 관리'},
        { title: '모의고사 목록'},
        { title: '모의고사 상세 정보'},
        { title: '모의고사 문제 등록'}
    ];
    const title = { main: '모의고사 문제 등록', sub: 'Mock Exam'};
    const navigate = useNavigate();
    const { id } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const [question, setQuestion] = useState({
        Kind: 1,
        Point: 3,
        Answer: '',
        Content: [],
        Commentary: [],
        Choices: [],
        NewChoices:[],
        loginKey: cookies.LoginKey,
        SingleAnswer: null,
        MockExamId: id
    });
    
    const [creating, setCreating] = useState(false);
    const [choice, setChoice] = useState('');
    const [content, setContent] = useState('');
    const [commentary, setCommentary] = useState('');
    const [edit, setEdit] = useState(null);
    const [editCommentary, setEditCommentary] = useState(null);
    
    const handleChange = (event) => {
        let changeQuestion = { ...question };
        changeQuestion[event.target.name] = event.target.value;
        setQuestion(changeQuestion);
    };
    
    const handleChangeContent = (event) => {
        setContent(event.target.value);
    };

    const handleClikcEditContent = (num) => {
        setEdit(num);
        setContent(question.Content[num]);
    };

    const handleClickAddContent = () => {
        if(content){
            let changeQuestion = { ...question };
            if(edit === null){        
                changeQuestion.Content.push(content);
            }else{
                changeQuestion.Content[edit] = content;
                setEdit(null);
            }
            setContent('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteContent = (num) => {
        let changeQuestion = {...question};
        changeQuestion.Content.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleChangeCommentary = (event) => {
        setCommentary(event.target.value);
    };

    const handleClikcEditCommentary = (num) => {
        setEditCommentary(num);
        setCommentary(question.Commentary[num]);
    };

    const handleClickAddCommentary = () => {
        if(commentary){
            let changeQuestion = { ...question };
            if(editCommentary === null){        
                changeQuestion.Commentary.push(commentary);
            }else{
                changeQuestion.Commentary[editCommentary] = commentary;
                setEditCommentary(null);
            }
            setCommentary('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteCommentary = (num) => {
        let changeQuestion = {...question};
        changeQuestion.Commentary.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleChangeChoice = (event) => {
        setChoice(event.target.value);
    };

    const handleClickAddChoice = () => {
        if(question.Choices.length < 5 && choice){
            let changeQuestion = { ...question };
            changeQuestion.Answer = '';
            changeQuestion.Choices.push(choice);
            changeQuestion.NewChoices.push(choice);
            setChoice('');
            setQuestion(changeQuestion);
        }
    };

    const handleDeleteChoice = (num) => {
        let changeQuestion = { ...question };
        changeQuestion.Answer = '';
        changeQuestion.Choices.splice(num, 1);
        changeQuestion.NewChoices.splice(num, 1);
        setQuestion(changeQuestion);
    };

    const handleClickCreate = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(question);
        await axios.post(`/api/admin/mockexam/question`, body, config).then((Response) => {
            setTimeout(()=> {
                navigate(`/admin/mockexam/${id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };
    return (
        <Layout pages={pages} title={title}>
            <MockExamQuestionCreateView
                question={question}
                choice={choice}
                content={content}
                commentary={commentary}
                handleChange={handleChange}
                handleChangeContent={handleChangeContent}
                handleClickAddContent={handleClickAddContent}
                handleDeleteContent={handleDeleteContent}
                handleChangeCommentary={handleChangeCommentary}
                handleClickAddCommentary={handleClickAddCommentary}
                handleDeleteCommentary={handleDeleteCommentary}
                handleChangeChoice={handleChangeChoice}
                handleClickAddChoice={handleClickAddChoice}
                handleDeleteChoice={handleDeleteChoice}
                handleClickCreate={handleClickCreate}
                creating={creating}
                handleClikcEditContent={handleClikcEditContent}
                handleClikcEditCommentary={handleClikcEditCommentary}
            />
        </Layout>
    )
};

export default MockExamQuestionCreate;