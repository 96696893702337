import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { TextField } from '@mui/material';

import { Layout } from 'components/admin/layout';
import { ManagerClassInfo, ManagerDetailInfo, PriceInfoView } from 'components/admin/manager';
import { DeleteModal, ResetPasswordModal, SubmitModal } from 'components/modal';

const ManagerInfo = () => {
    const pages = [
        { title: '회원 관리'},
        { title: '관리자 목록', link: '/admin/manager' },
        { title: '관리자 상세 정보'},
    ];
    const title = { main: '관리자 상세 정보', sub: 'user'};
    const { enqueueSnackbar } = useSnackbar();
    const [manager, setManager] = useState({
        _id: '',
        Name: '',
        Birth: '',
        Gender: '',
        Email: '',
        Phone: '',
        Login: '',
        State: '',
        School_id: { Name: '' },
        Grade: '',
        Status: '',
        Questions: [{Question_id: [{ _id: '' }]}],
        Class: [{
            ClassName: '',
            ClassNo: '',
            Class_id: '',
            CreatedAt: '',
            Homeworks: [],
            Students: [{
                Connect: ''
            }]
        }]
    });

    const [prices, setPrices] = useState([{
        Order_id: {
            MaxStudent: 0,
            NextOrder: '1900-01-01',
            OrderCancel: true,
            State: 0,
            createdAt: '1900-01-01'
        },
        PriceNo: 0,
        data: {
            totalAmount: 0,
            method: '',
            orderName: '',
        }
    }]);
    const { id } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    // 데이터 로드
    const loadStudent = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/manager/${id}/${cookies.LoginKey}`, config).then((res) => {
            setManager(res.data);
            if(res.data.Status === 3){
                loadPrices();
            }
            console.log(res.data)
        }).catch((Error) => {
            console.log(Error);
        });
    };
    const loadPrices = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/manager/price/${id}/${cookies.LoginKey}`, config).then((res) => {
            res.data.sort((a, b) => (new Date(b.Order_id.NextOrder) - new Date(a.Order_id.NextOrder)));
            setPrices(res.data);
            console.log(res.data);
        }).catch((Error) => {
            console.log(Error);
        });
    };
    useEffect(() => {
        loadStudent();
    }, [id]);

    const [openDeleteStudent, setOpenDeleteStudent] = useState(false);
    const handleClickOpenDeleteStudent = () => {
        setOpenDeleteStudent(true);
    };
    const handleClickCloseDeleteStudent = () => {
        setOpenDeleteStudent(false);
    };
    const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
    const handleClickOpenDeleteQuestion = (key) => {
        setOpenDeleteQuestion(true);
    };
    const handleClickCloseDeleteQuestion = () => {
        setOpenDeleteQuestion(false);
    };
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const handleClickOpenResetPassword = () => {
        setOpenResetPassword(true);
    };
    const handleClickCloseResetPassword = () => {
        setOpenResetPassword(false);
    };
    const [openFreePrice, setOpenFreePrice] = useState(false);
    const [freePriceCreating, setFreePriceCreating] = useState(false);
    const [freePrice, setFreePrice] = useState({
        NextOrder: ' ',
        OrderName: '우로매쓰 무료체험 서비스',
        MaxStudent: ''
    });
    const handleCloseFreePrcie = () => {
        setOpenFreePrice(false);
        setFreePrice({ NextOrder: ' ', OrderName: '우로매쓰 무료체험 서비스', MaxStudent: '' });
    };
    const handleClickCreateFreePrice = async() => {
        setFreePriceCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify({ ...freePrice, managerId: id, loginKey: cookies.LoginKey });
        await axios.post(`/api/admin/manager/price`, data, config).then((res) => {
            loadPrices();
            setTimeout(() => {
                handleCloseFreePrcie();
                setFreePriceCreating(false);
            }, [1500]);
        }).catch((err) => {
            console.log(err);
            setTimeout(() => {
                handleCloseFreePrcie();
                setFreePriceCreating(false);
            }, [1500]);
        });
    };
    
    return(
        <Layout pages={pages} title={title}>
            <ManagerDetailInfo
                manager={manager}
                handleClickOpenDelete={handleClickOpenDeleteStudent}
                handleClickOpenResetPassword={handleClickOpenResetPassword} 
            />
            {manager.Status === 3 ?
            <>
                <ManagerClassInfo 
                    classDatas={manager.Class}
                />
                <PriceInfoView
                    priceDatas={prices}
                    handleClickPrice={() => setOpenFreePrice(true)}
                />
                <DeleteModal 
                    openDelete={openDeleteStudent} 
                    handleClickOpenDelete={handleClickOpenDeleteStudent} 
                    handleClickCloseDelete={handleClickCloseDeleteStudent}
                    title='학생 정보 삭제'
                >
                    유저 번호를 제외한 학생 정보가 삭제됩니다.<br/>삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
                </DeleteModal>
                <ResetPasswordModal 
                    openResetPassword={openResetPassword} 
                    handleClickCloseResetPassword={handleClickCloseResetPassword}
                    title='비밀번호 초기화'
                >
                    비밀번호가 초기화 됩니다.<br/>가입한 이메일 인증을 통해 비밀번호를 변경 할 수 있습니다.
                </ResetPasswordModal>
                <DeleteModal 
                    openDelete={openDeleteQuestion} 
                    handleClickCloseDelete={handleClickCloseDeleteQuestion}
                    title='문제 풀이 정보 삭제'
                >
                    문제 풀이 정보가 삭제됩니다.<br />삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
                </DeleteModal>
                <SubmitModal
                    open={openFreePrice}
                    handleClose={handleCloseFreePrcie}
                    title={'무료체험 서비스'}
                    updating={freePriceCreating}
                    handleClickSubmit={handleClickCreateFreePrice}
                >
                    <TextField 
                        label='상품명'
                        variant='outlined'
                        fullWidth
                        color='warning'
                        type={'text'}
                        value={freePrice.OrderName}
                        onChange={(e) => setFreePrice({ ...freePrice, OrderName: e.target.value })}
                        style={{ marginBottom: '16px', marginTop: '8px' }}
                    />
                    <TextField
                        label='모의고사 응시권 발행 개수'
                        variant='outlined'
                        fullWidth
                        color='warning'
                        type={'number'}
                        value={freePrice.MaxStudent}
                        onChange={(e) => setFreePrice({ ...freePrice, MaxStudent: e.target.value })}
                        style={{ marginBottom: '16px' }}
                    />
                    <TextField
                        label='만료일시'
                        variant='outlined'
                        fullWidth
                        color='warning'
                        type={'date'}
                        value={freePrice.NextOrder}
                        onChange={(e) => new Date(e.target.value) > new Date() ? setFreePrice({ ...freePrice, NextOrder: e.target.value }): enqueueSnackbar('오늘 이전 날짜는 선택할 수 없습니다.', { variant: 'error' })}
                    />
                </SubmitModal>
            </>
            :
            null}
        </Layout>
    );
}

export default ManagerInfo;