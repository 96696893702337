import React from "react";
import { Routes, Route } from "react-router-dom";
import { ClassList,ClassDetail } from "domain/Academy/class";
import NotFound from "routes/NotFound";

const AcademyClassPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<ClassList />} />
      <Route path=":id" element={<ClassDetail />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AcademyClassPresenter;
