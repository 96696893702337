import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { TicketListView, TicketStudentListView } from 'components/teacher/class/ticket';
import { SubmitModal } from 'components/modal';

const TicketList = () => {
    const { ClassNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [teacherTicketCount, setTeacherTicketCount] = useState(0);
    const [classData, setClassData] = useState({
        ClassName: '',
    });
    const [studentDatas, setStudentDatas] = useState([{
      Student_id: {
        Name: '',
        Grade: 0,
        Attending: 0,
        Profile: '',
        School_id: { Name: '' }
      }
    }]);
    const [teacherData, setTeacherData] = useState({
        Name: '',
        Email: '' 
    });
    const [ticketDatas, setTicketDatas] = useState([{
        Students: [],
        Order_id: {
            NextOrder: '2099-12-31',
            State: 0,
            createdAt: '2099-12-31'
        }
    },{
        Students: [],
        Order_id: {
            NextOrder: '2099-12-31',
            State: 0,
            createdAt: '2099-12-31'
        }
    }]);

    const handleClose = () => {
      if(!updating){
        let changeStudents = [...studentDatas];
        for(let i = 0; i < changeStudents.length; i++){
          changeStudents[i].Checked = false;
        }
        setOpen(false);
        setStudentDatas(changeStudents);
      }else{
        return false;
      }
    };

    const handleClickStudent = (key) => {
      let changeStudents = [...studentDatas];
      if(changeStudents[key].Checked){
        changeStudents[key].Checked = false;
      }else{
        changeStudents[key].Checked = true;
      }
      setStudentDatas(changeStudents);
    };

    const handleClickStudentAll = () => {
      const isChecked = studentDatas.filter((item) => { return item.Checked === true }).length === studentDatas.length;
      let changeStudents = [...studentDatas];
      for(let i = 0; i < changeStudents.length; i++){
        changeStudents[i].Checked = !isChecked;
      }
      setStudentDatas(changeStudents);
    };

    const handleClickSubmit = async() => {
      setUpdating(true);
      const config = { headers: { 'Content-type': 'application/json' }};
      const giveTicketList = studentDatas.filter((item) => { return item.Checked === true });
      const data = JSON.stringify({ 
        giveTicketList: giveTicketList,
        loginKey: cookies.LoginKey,
        orderId: ticketDatas[0].Order_id._id
      });
      await axios.post(`/api/teacher/ticket`, data, config).then((res) => {
        const now = new Date();
        let updateTickets = [...ticketDatas];
        for(let i = 0; i < giveTicketList.length; i++){
          updateTickets[0].Students.push({
            CreatedAt: now,
            Student_id: {
              _id: giveTicketList[i].Student_id._id,
              Name: giveTicketList[i].Student_id.Name,
              School_id: giveTicketList[i].Student_id.School_id,
              Grade: giveTicketList[i].Student_id.Grade,
              Attending: giveTicketList[i].Student_id.Attending
            }
          });
        }
        setTimeout(() => {
          setTicketDatas(updateTickets);
          setUpdating(false);
          enqueueSnackbar(`총 ${giveTicketList.length}명의 학생에게 배부하였습니다.`, { variant: 'success' });
          handleClose();
        }, [1500]);
      }).catch((err) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };

    const loadClassData = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/teacher/ticket/${ClassNo}/${cookies.LoginKey}`, config).then((res) => {
        if(!res.data.ErrorMessage){
          setClassData(res.data.Class);
          setTeacherData(res.data.Teacher);
          let loadTicketDatas = res.data.Teacher.Tickets;
          if(loadTicketDatas.length > 0){
            let teacherTicketCount = 0;
            loadTicketDatas.sort(function (a, b) {
              return new Date(b.Order_id.NextOrder) - new Date(a.Order_id.NextOrder);
            });
            for (let i = 0; i < loadTicketDatas[0].Teachers.length; i++) {
              teacherTicketCount += loadTicketDatas[0].Teachers[i].Count;
            }
            setTicketDatas(loadTicketDatas);
            setTeacherTicketCount(teacherTicketCount);
          }
          setStudentDatas(res.data.Class.Students);
        }else{
          enqueueSnackbar(`${res.data.ErrorMessage}`, { variant: 'error' });
        }
      }).catch((Error) => {
        console.log(Error)
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };

 



    useEffect(() => {
      loadClassData();
    }, []);

    return (
        <Layout>
            <TicketListView
              classData={classData}
              teacherData={teacherData}
              ticketDatas={ticketDatas}
              setOpen={setOpen}
              teacherTicketCount={teacherTicketCount}
            />
            <SubmitModal 
              title={'모의고사 응시권 배부'}
              open={open}
              handleClose={handleClose}
              updating={updating}
              handleClickSubmit={handleClickSubmit}
              disabledOption={studentDatas.filter((item) => { return item.Checked === true }).length}
            >
              <TicketStudentListView
                studentDatas={studentDatas}
                ticketData={ticketDatas[0]}
                handleClickStudent={handleClickStudent}
                handleClickStudentAll={handleClickStudentAll}
              />
            </SubmitModal>
        </Layout>
    );
};

export default TicketList;