import React from 'react';
import { HighlightOff } from '@mui/icons-material';

const TeacherPriceFailApp = (props) => {    
    return (
        <div style={{ minHeight: '90vh', padding: '30% 0' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <HighlightOff sx={{ fontSize: 120, color: 'red' }} />
            </div>
            <div style={{ width: '100%', textAlign: 'center', fontSize: '17px'}}>결제에 실패했습니다</div>
        </div>
    );
};

export default TeacherPriceFailApp;