import React, {  useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked, TaskAlt } from '@mui/icons-material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import NormalDistribution from 'normal-distribution';
import { useSnackbar } from 'notistack';

import NormalDistributionImgae from 'img/normal_distribution.png';
import QuestionCheck1 from 'img/question_check1.png';
import QuestionCheck2 from 'img/question_check2.png';
import { CommentaryModal } from 'components/modal';

import * as ChangeText from 'components/function/ChangeText';


const DiagnosisResult = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [commentary ,setCommentary]=useState([])

    const diagnosis = props.diagnosis;
    const normDist = new NormalDistribution(0, 1);
    const schoolDist = new NormalDistribution(diagnosis?.SchoolZScore ? diagnosis.SchoolZScore : 0, 1);
    const allGrade = diagnosis?.ZScore ? 1-normDist.cdf(diagnosis.ZScore) : 0;
    const schoolGrade = diagnosis?.ZScore ? 1-schoolDist.cdf(diagnosis.ZScore) : 0;
    const allMean = 0.5;
    const schoolMean = diagnosis?.SchoolZScore ? normDist.cdf(diagnosis?.SchoolZScore) : 0;
    const answerList = ['①', '②', '③', '④', '⑤'];
    const schoolBar = (0.1+((schoolMean)*0.8))*100 < 90 ? (0.1+((schoolMean)*0.8))*100 : 90;
    const studentBar = (0.1+((1-allGrade)*0.8))*100 < 88 ? (0.1+((1-allGrade)*0.8))*100 < 12.5 ? 12.5 : (0.1+((1-allGrade)*0.8))*100 : 88;
    // const config = {
    //     "fast-preview": {disabled: true},
    //     loader: { load: ["[tex]/html"] },
    //     tex: {
    //       packages: { "[+]": ["html"]},
    //       inlineMath: [
    //         ["$", "$"],
    //         ["\\(", "\\)"]
    //       ],
    //       displayMath: [
    //         ["$$", "$$"],
    //         ["\\[", "\\]"]
    //       ],
    //     },
    //     options: {
    //       enableMenu: false,
    //     }
    // };
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    const handleClickCommentary = (question) => {
        if(question.Commentary.length > 0){
            setCommentary(question.Commentary)
            setOpen(true);
        }else{
           enqueueSnackbar(`해설이 없습니다. 빠른 시일 내 추가하겠습니다.`, { variant: 'warning'});
        }
     };
    return(
        <div>
            <Grid container justifyContent='center' className='content_section'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>진단결과</h2>
                </Grid>
                <Grid item xs={12} sm={7} md={6} className='diagnosis_result_table'>
                    <table>
                        <tr><th>단원</th><td>{ChangeText.Part(diagnosis?.Section)}&nbsp;&gt;&nbsp;{ChangeText.MiddlePart(diagnosis?.Section)}&nbsp;&gt;&nbsp;{ChangeText.Section(diagnosis?.Section)}</td></tr>
                        <tr><th>정답률</th><td>{ChangeText.Percent(diagnosis?.checkTrue/5)}</td></tr>
                        <tr><th>평균 풀이 시간</th><td>{ChangeText.QuestionMinute(diagnosis?.allTime/5)}</td></tr>
                        <tr><th>전국 백분위</th><td>상위 {ChangeText.Percent(allGrade)}</td></tr>
                        <tr><th>학교 백분위</th><td>상위 {ChangeText.Percent(schoolGrade)}</td></tr>
                    </table>
                </Grid>
                <Grid item xs={12} sm={5} md={4} className='diagnosis_result_graph'>
                    <div>
                        <img src={NormalDistributionImgae} alt='normal distribution' />
                        <span>수능 {ChangeText.StaNine(1-allGrade)}등급<br/>내신 {ChangeText.StaNine(1-schoolGrade)}등급</span>
                        <div className='diagnosis_result_legend'>
                            <div className='school_avg'><p/>학교평균</div>
                            <div className='all_avg'><p/>전국평균</div>
                            <div className='my_grade'><p/>내 성적</div>
                        </div>
                        <div className='diagnosis_result_bar'>
                            <div className='school_avg' style={{left: `${schoolBar}%`}}/>
                            <div className='all_avg' style={{left: `${(0.1+((allMean)*0.8))*100}%`}}/>
                            <div className='my_grade' style={{left: `${studentBar}%`}}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <span>· 학교 성적은 우로매쓰에 가입한 해당 학교 학생들의 분석 결과로 출력됩니다.</span>
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_questions'>
                    <div>
                        {diagnosis?.Questions?.map((question, i) => (
                        <Grid container>
                            <Grid item xs={12} md={12} sx={{textAlign:"right"}}>
                                <div className={question.Commentary && question.Commentary.length > 0 ? 'teacher_homework_commentary' : 'teacher_homework_commentary teacher_homework_none_commentary'} onClick={() => handleClickCommentary(question)}>해설보기</div>
                            </Grid>
                            <Grid item xs={12} md={1} className='diagnosis_result_number'>
                                <p>문제 {question.QuestionNo-diagnosis.Section*10000}</p>
                                <img src={question.UserCheck === 1 ? QuestionCheck1 : QuestionCheck2} alt='check 1' />
                            </Grid>
                            <Grid item xs={12} className='question_mathjax'>
                            {/* <Grid item xs={12} md={8} className='question_mathjax'> */}
                                <div className='question_front_cover' style={{backgroundColor: props.loading? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>
                                    {props.loading ? <CircularProgress color='warning'/> : null}
                                </div>
                                <MathJaxContext version={3} config={config}>
                                    {question.Content.map((content) => (
                                      <MathJax 
                                      renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                                      text={
                                      content.replace('`\\(','').replace('\\)`','')
                                      }
                                      dynamic
                                      className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                                      />
                                    ))}
                                </MathJaxContext>
                                <div style={{textAlign: 'center'}}>
                                    {question?.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                                </div>
                                <div className='diagnosis_questions_answer'>
                                    {/* {new Date() > new Date(question.DueDate).setHours(23,59,59,999) ?
                                    i+1 === question.Answer ? // 1
                                        question.Answer === question.UserSelect ? // 2, 1 true 
                                            <RadioButtonChecked className='homework_question_answer'/>: // 2 ture
                                            <TaskAlt className='homework_question_answer'/>: // 2 false
                                        i+1 === question.Select ? // 3, 1 false
                                            <RadioButtonChecked />: // 3 true
                                            <RadioButtonUnchecked />: // 3 false
                                    i+1 === question.Select ?
                                        <RadioButtonChecked />:
                                            <RadioButtonUnchecked />
                                    } */}
                                    {question.NewChoices.map((choice, i) => (
                                        question.Answer === question.UserSelect ?
                                            question.UserSelect === i+1 ?
                                        <div>
                                            <RadioButtonChecked className='homework_question_answer'/>
                                            <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                choice.replace('`\\(','').replace('\\)`','')
                                                } inline dynamic/>
                                            </MathJaxContext>
                                        </div>:
                                        <div>
                                            <RadioButtonUnchecked/>
                                            <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                choice.replace('`\\(','').replace('\\)`','')
                                                } inline dynamic/>
                                            </MathJaxContext>
                                        </div>:
                                            question.Answer === i+1 ?
                                        <div>
                                            <RadioButtonChecked className='homework_question_answer'/>
                                            <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                choice.replace('`\\(','').replace('\\)`','')
                                                } inline dynamic/>
                                            </MathJaxContext>
                                        </div>:
                                            question.UserSelect === i+1 ?
                                        <div>
                                            <TaskAlt/>
                                            <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                choice.replace('`\\(','').replace('\\)`','')
                                                } inline dynamic/>
                                            </MathJaxContext>
                                        </div>:
                                        <div>
                                            <RadioButtonUnchecked/>
                                            <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                choice.replace('`\\(','').replace('\\)`','')
                                                } inline dynamic/>
                                            </MathJaxContext>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                            {/* <Grid item xs={12} md={3} className='diagnosis_question_info'>
                                <p>풀이 시간<span>{ChangeText.QuestionMinute(question.UserTime)}</span></p>
                                <hr/>
                                <p>평균 문제 풀이 시간<span>{ChangeText.QuestionMinute(question.TotalTime)}</span></p>
                                <p>정답률<span>{question.TotalCounting ? ChangeText.Percent(question.TotalCorrect/question.TotalCounting) : '0%'}</span></p>
                            </Grid> */}
                        </Grid>
                        ))}
                    </div>
                </Grid>
            </Grid>
            <CommentaryModal open={open} setOpen={setOpen} Commentary={commentary} config={config}/>
        </div>

    );
}

export default DiagnosisResult;