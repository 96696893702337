import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/user/layout';
import { StudentDashboardDetail } from 'components/user/student';

const UserDashboardDetail = () => {
    const [cookies] = useCookies(['LoginKey']);
    const LoginKey = cookies.LoginKey;
    const navigate = useNavigate();
    const { Section } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [studentData, setStudentData] = useState({
        Name: '',
        Grade: '',
        Attending: '',
        School_id: {
            Name: '',
            WooroZscore: ''
        }
    });
    const [diagnosisDatas, setDiagnosisDatas] = useState([{
        ZScore: '',
        Questions: [],
        createdAt: ''
    }]);
    const [sectionQuestionCount, setSectionQuestionCount] = useState({ _id: '', Count: '' });
    const [sectionQuestions, setSectionQuestions] = useState([]);

    const getQuestionCounting = async () => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/question/counting`, config).then((Response) => {
          setSectionQuestionCount(Response.data.filter((item) => { return item._id === Number(Section) })[0]);
        })
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    };

    useEffect(() => {
        const loadDiagnosisData = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/diagnosis/section/${Section}/${LoginKey}`, config).then((Response) => {              
                if(!Response.data.ErrorMessage){
                    let loadDiagnosisList = Response.data.Diagnosis;
                    loadDiagnosisList.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    setDiagnosisDatas(loadDiagnosisList);
                    setStudentData(Response.data.UserInfo);
                    setSectionQuestions(Response.data.UserInfo.Questions.filter((item) => { return item.Section === Number(Section) && item.Status === 1 }));
                    getQuestionCounting();
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });   
        }
        loadDiagnosisData();
    }, []);

    return(
        <Layout>
            <StudentDashboardDetail
                studentData={studentData}
                diagnosisDatas={diagnosisDatas}
                sectionQuestionCount={sectionQuestionCount}
                sectionQuestions={sectionQuestions}
            />
        </Layout>
    )
}

export default UserDashboardDetail;