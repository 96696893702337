import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { UserPropose, UserDashboard, UserEdit, UserDashboardDetail, StudentPrice, StudentTicket, StudentTicketSuccess, StudentTicketFail, StudentTicketSuccessApp, StudentTicketFailApp } from 'domain/user';
import NotFound from 'routes/NotFound';

const UserPresenter = () => {
  return (
    <Routes>
      <Route path='/dashboard' element={<UserDashboard/>} />
      <Route path='/dashboard/:Section' element={<UserDashboardDetail/>} />
      <Route path='/edit' element={<UserEdit/>} />
      <Route path='/propose' element={<UserPropose/>} />
      <Route path='/price' element={<StudentPrice/>} />
      <Route path='/ticket' element={<StudentTicket/>} />
      <Route path='/ticket/success' element={<StudentTicketSuccess/>} />
      <Route path='/ticket/fail' element={<StudentTicketFail/>} />
      <Route path='/ticket/success/app' element={<StudentTicketSuccessApp/>} />
      <Route path='/ticket/fail/app' element={<StudentTicketFailApp/>} />
      <Route path='/*' element={<NotFound/>} />
    </Routes>
  ); 
};

export default UserPresenter;