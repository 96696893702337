import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Link, Button } from '@mui/material';
import WooromathLogo from 'img/wooromath_logo.png';
import MainBanner from 'img/main_banner.png';
import { InfoOutlined } from '@mui/icons-material';

const NotFound = () => {
  const navigate = useNavigate();
    return (
      <Grid container justifyContent='center' alignItems='center' style={{paddingTop: '30px', paddingBottom: '30px'}}>
        <Grid item xs={12} md={10} style={{textAlign: 'center'}}>
          <img src={WooromathLogo} alt='wooromath logo' style={{width: '120px'}} />
        </Grid>
        <Grid item xs={12} md={5} style={{textAlign: 'center'}}>
          <img src={MainBanner} alt='studentImage' style={{width: '80%'}}/>
        </Grid>
        <Grid item xs={12} md={5} style={{fontWeight: 'bold'}}>
          <div style={{fontSize: '28px', color: '#EF4C23'}}><InfoOutlined /> 404 NotFound</div>
          <p>죄송합니다.</p>
          <p>요청하신 페이지를 찾을 수 없습니다.</p><br/>
          <p>관련 문의사항은 페이지 하단 <b style={{color: '#EF4C23'}}>채널톡</b> 또는 <Link style={{color: '#EF4C23', textDecoration: 'none'}} href='info@woorotest.com' target='_blank'>이메일(info@woorotest.com)</Link>에 문의 주시면 답변 드리겠습니다.</p>
          <p>감사합니다.</p>
        </Grid>
        <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
          <Button variant='contained' color='warning' onClick={() => navigate(`/`, { replace: true })}>메인으로 가기</Button>
        </Grid>
      </Grid>  
    );
}

export default NotFound;