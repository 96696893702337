import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { MockExamInfo } from "components/info";
import { Layout } from "components/user/layout";
import { MockExamListView } from "components/user/mockExam";
import { MockExamPreviewModal } from "components/modal";
import MockExamPreviewModalView from "components/user/mockExam/MockExamPreviewModalView";

const MockExamList = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["LoginKey", "UserNo"]);
  const LoginKey = cookies.LoginKey;

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [buying, setBuying] = useState(false);
  const [selectMockExam, setSelectMockExam] = useState({
    _id: "1",
    Name: "",
    StartDate: "2023-03-01",
    UnLock: false,
    StudentCnt: [0, 0, 0],
    Students: [
      {
        Student_id: "",
        Part: 2,
      },
    ],
  });
  const [mockExamDatas, setMockExamDatas] = useState([
    {
      _id: "1",
      Name: "우로매쓰 2023년 3월 1차 모의고사",
      StartDate: "2023-03-01",
      UnLock: true,
      StudentCnt: [0, 0, 0],
      Students: [
        {
          Student_id: "",
          Part: 2,
        },
      ],
    },
  ]);
  const [userMockExam, setUserMockExam] = useState([
    {
      MockExamNo: "",
      State: 1,
      Part: 2,
    },
  ]);
  const [userTicketCnt, setUserTicketCnt] = useState(0);

  const handleClickOpen = (i) => {
    setSelectMockExam(mockExamDatas[i]);
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectMockExam({
        _id: "1",
        Name: "",
        StartDate: "2023-03-01",
        Lock: false,
        Content: {
          StudentCnt: [0, 0, 0],
        },
      });
    }, [500]);
  };

  const handleClickStart = (state) => {
    navigate(`/mockexam/${selectMockExam.MockExamNo}/${state}`);
  };

  const handleClickLock = async (men) => {
    if (userTicketCnt > 0) {
      setBuying(true);
      const config = { headers: { "Content-type": "application/json" } };
      const data = JSON.stringify({
        loginKey: cookies.LoginKey,
        mockExamNo: men,
      });
      await axios
        .post(`/api/mockexam`, data, config)
        .then((res) => {
          if (!res.data.ErrorMessage) {
            let buyMockExamDatas = [...mockExamDatas];
            for (let i = 0; i < buyMockExamDatas.length; i++) {
              if (buyMockExamDatas[i].MockExamNo === Number(men)) {
                buyMockExamDatas[i].UnLock = true;
                break;
              }
            }
            let buySelectMockExam = { ...selectMockExam };
            buySelectMockExam.UnLock = true;
            setTimeout(() => {
              setMockExamDatas(buyMockExamDatas);
              setSelectMockExam(buySelectMockExam);
              enqueueSnackbar(`${res.data.Message}`, { variant: "success" });
              setBuying(false);
            }, [1500]);
          } else {
            enqueueSnackbar(`${res.data.ErrorMessage}`, { variant: "error" });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Network Error", { variant: "error" });
        });
    }
  };

  const loadDatas = async () => {
    const config = { headers: { "Content-type": "application/json" } };
    await axios
      .get(`/api/mockexam/${cookies.LoginKey}`, config)
      .then((res) => {
        if (!res.data.ErrorMessage) {
          const now = new Date();
          let loadMockExams = [...res.data.MockExams];
          for (let i = 0; i < loadMockExams.length; i++) {
            // 모의고사 각 과목 선택 수
            loadMockExams[i].StudentCnt = [];
            if (loadMockExams[i].Students.length) {
              loadMockExams[i].StudentCnt = [
                loadMockExams[i].Students.filter((item) => {
                  return item.Part === 2 && item.Point;
                }).length,
                loadMockExams[i].Students.filter((item) => {
                  return item.Part === 3 && item.Point;
                }).length,
                loadMockExams[i].Students.filter((item) => {
                  return item.Part === 4 && item.Point;
                }).length,
              ];
            } else {
              loadMockExams[i].StudentCnt = [0, 0, 0];
            }
            // 잠금 해제
            loadMockExams[i].UnLock = loadMockExams[i].Students.filter(
              (item) => {
                return (
                  Number(item.Student_id.UserNo) === Number(cookies.UserNo)
                );
              }
            ).length
              ? true
              : false;
            if (i < 3 && cookies.LoginKey) {
              loadMockExams[i].UnLock = true;
            }
          }
          loadMockExams.sort((a, b) => {
            return new Date(b.StartDate) - new Date(a.StartDate);
          });
          setMockExamDatas(loadMockExams);
          if (res.data.User) {
            let loadUser = { ...res.data.User.User_id };
            const ticketCnt = loadUser.Tickets.filter((item) => {
              return item.Order_id
                ? new Date(item.Order_id.NextOrder) > now && !item.UsedAt
                : !item.UsedAt;
            });

            setUserMockExam(loadUser.MockExam);
            setUserTicketCnt(ticketCnt.length);
          }
        } else {
          enqueueSnackbar(res.data.ErrorMessage, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Network Error", { variant: "error" });
      });
  };

  useEffect(() => {
    loadDatas();
  }, []);

  return (
    <>
      <Layout>
        {LoginKey ? (
          <MockExamListView
            mockExamDatas={mockExamDatas}
            handleClickOpen={handleClickOpen}
          />
        ) : (
          <MockExamInfo />
        )}
      </Layout>
      <MockExamPreviewModal
        title={selectMockExam.Name}
        open={open}
        mockExamData={selectMockExam}
        handleClickLock={handleClickLock}
        userTicketCnt={userTicketCnt}
        onClose={handleClickClose}
        buying={buying}
      >
        <MockExamPreviewModalView
          mockExamData={selectMockExam}
          userMockExam={userMockExam}
          handleClickStart={handleClickStart}
        />
      </MockExamPreviewModal>
    </>
  );
};

export default MockExamList;
