import React, { useState, useEffect, createRef } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionHomeworkDetail } from 'components/user/question';

const HomeworkDetail = () => {
    const { homeworkNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const LoginKey = cookies.LoginKey;

    const [currentNo, setCurrentNo] = useState(0);
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(0);
    const [questionTimer, setQuestionTimer] = useState(0);
    const [startTime, setStartTime] = useState(new Date());
    const [homeworkEndTime , setHomeworkEndTime] = useState();
    const [homeworkData, setHomeworkData] = useState([{
        Answer: '',
        Choices: ['', '', '', '', ''],
        Content: [' '],
        QuestionNo: '',
        Question_id: '',
        Section: '',
        IsSaved: ''
    }]);
    const dueDate = new Date(homeworkData[currentNo].DueDate);
    const toDay = new Date()
    const toDaySecond = (((toDay.getMonth()+1)*2592000) + (toDay.getDate()*86400) + (toDay.getHours()*3600) + (toDay.getMinutes() * 60 ))
    const dueDateSecond = (((dueDate.getMonth()+1)*2592000) + (dueDate.getDate()*86400) + (dueDate.getHours()*3600) + (dueDate.getMinutes() * 60 ))


    useEffect(() => {
        const loadHomeworks = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/student/homework/${homeworkNo}/${LoginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    let loadHomework = Response.data.Homework;
                    setTimeout(() => {
                        setLoading(false);
                    }, [500]);
                    setHomeworkData(loadHomework);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    navigate(-1);        
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            })
        }
        if(LoginKey){
            loadHomeworks(); 
        }else{
            enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error' });
            navigate(`/login`);
        }
    }, []);

    useEffect(() => {
        const timers = setInterval(() => {
            setQuestionTimer(questionTimer => questionTimer+1);
            setHomeworkEndTime(endTime => endTime - 1)
        }, 1000);

        if (homeworkEndTime <= 0 ) {
            enqueueSnackbar(`시간이 다 되었습니다.`, { variant: 'info'});
            setTimeout(() => {
                navigate( '/question/homework' );
            }, [1500]); 
        };
        return () => clearInterval(timers);
    }, [questionTimer]);

    useEffect(()=>{
        setHomeworkEndTime(dueDateSecond - toDaySecond)
    },[homeworkData]);

    const saveQuestion = async () => {
        if(LoginKey){
            let obj = {
                Question_id: homeworkData[currentNo].Question_id,
                loginKey: LoginKey,
                Status: 5,
                Section: homeworkData[currentNo].Section,
                IsSaved: homeworkData[currentNo].IsSaved 
            };
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify(obj);
            await axios.post(`/api/question`, body, config).then((Response) => {
                let temp = { ...homeworkData };
                temp[currentNo].IsSaved = Response.data.IsSaved;
                setHomeworkData(temp);
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        }else{
          enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error'});
        }
    };

    const NextQuestion = () => {
        setLoading(true);
        setChecked(0);
        setQuestionTimer(0);
        setStartTime(new Date());
        ClearDraw();
            if(currentNo+1 === homeworkData.length){
                enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
                setTimeout(() => {
                    navigate(-1);
                }, [1500]); 
            }else{
                setTimeout(() => {
                    setLoading(false);
                }, [500]);
                setCurrentNo(currentNo=>currentNo+1);
            }
    };

    /* 문제 제출하기 */
    const submitQuestion = async (memo) => {
        setLoading(true);
        let check;
        let obj = {};
        const endTime = new Date();
            if(homeworkData[currentNo].Answer === Number(checked)){
                enqueueSnackbar(`정답입니다.`, { variant: 'success'});
                check = 1;
            }else if(homeworkData[currentNo].Answer !== Number(checked)){
                enqueueSnackbar(`오답입니다.`, { variant: 'error'});
                check = 2;
            }
      
        obj = {
            Question_id: homeworkData[currentNo].Question_id,
            loginKey: LoginKey,
            Answer: homeworkData[currentNo].Answer,
            Status: 2,
            Select: checked,
            Section: homeworkData[currentNo].Section,
            Check: check,
            Time: Number(((endTime-startTime)/1000).toFixed(2)),
            HomeworkNo: homeworkNo,
            Score:null,
            Memo: memo
        };
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(obj);
        await axios.post(`/api/question`, body, config).then((Response) => {
            setChecked(0);
            setQuestionTimer(0);
            setStartTime(new Date());
                if(currentNo+1 === homeworkData.length){
                    enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
                    setTimeout(() => {
                        navigate(-1);
                    }, [1500]); 
                }else{
                    setCurrentNo(currentNo=>currentNo+1);
                    setTimeout(() => {
                        setLoading(false);
                    }, [500]);
                }
        })
        .catch((Error) => {
            enqueueSnackbar(`Network Error`, { variant: 'error' });
            console.log(Error)
        });
    };

    // 메모
    const Mobile = () => {return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);}
    let canvas;
    let canvasRef = createRef();

    let pos = {
        drawable: false,
        x: -1,
        y: -1
    };
    let ctx;
    let [mode, setMode] = useState(1);

    useEffect(() => {
        canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        if(Mobile()){
            canvas.addEventListener('touchstart', initDraw);
            canvas.addEventListener('touchend', finishDraw);
            canvas.addEventListener('touchmove', draw);
            canvas.addEventListener('touchout', finishDraw);
        }else{
            canvas.addEventListener('mousedown', initDraw);
            canvas.addEventListener('mouseup', finishDraw);
            canvas.addEventListener('mousemove', draw);
            canvas.addEventListener('mouseout', finishDraw);
        } 
        return () => {
            if(Mobile()){
            canvas.removeEventListener('touchmove', draw);
            }else{
            canvas.removeEventListener('mousemove', draw);
            }
        };
    }, [mode]);

    const getPosition = (event) => {
        if(Mobile()){
            return { X: event.touches[0].pageX, Y: event.touches[0].pageY };
        }else{
            return { X: event.offsetX, Y: event.offsetY };
        }
    };
    
    const initDraw = (event) => {    
        ctx.beginPath();
        ctx.lineWidth = 3;
        pos = { drawable: true, ...getPosition(event) };
        ctx.moveTo(pos.X, pos.Y);
    };

    const draw = (event) => {
        if(pos.drawable){
            if(mode === 1){
                pos = { ...pos, ...getPosition(event) };
                ctx.lineTo(pos.X, pos.Y);
                ctx.stroke();
            }else if(mode === 2){
                ctx.beginPath();
                if(Mobile()){
                    ctx.clearRect(event.touches[0].pageX-25, event.touches[0].pageY-25, 50, 50);
                }else{
                    ctx.clearRect(event.clientX-event.target.offsetLeft-25, event.clientY-event.target.offsetTop-25, 50, 50);
                }
            }
        }
    };

    const finishDraw = () => {
        pos = { drawable: false, X: -1, Y: -1 };
    };

    const ClearDraw = () => {
        canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const UploadData = async() => {
        canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        if(!isCanvasBlank(ctx)){
            ctx = canvas.getContext('2d');
            const base64 = canvas.toDataURL();
            const file = base64toFile(base64, `memo.png`);
            ClearDraw();
            const formData = new FormData();
            formData.append('loginKey', cookies.LoginKey);
            formData.append('imgFile', file);
            const config = { headers: { 'content-type': 'multipart/form-data' }};
            await axios.post(`/api/question/memo`, formData, config).then((Response) => {
                submitQuestion(Response.data.Memo);
            }).catch((error) => {
                console.log(error)
            })
        }else{
            submitQuestion('');
        }
    };
    const isCanvasBlank = (ctx) => {
        return !ctx
            .getImageData(0, 0, canvas.width, canvas.height).data
            .some(channel => channel !== 0);
    };
    const base64toFile = (base_data, filename) => {
        var arr = base_data.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type:mime });
    };

    return(
        <Layout>
            <QuestionHomeworkDetail
                question={homeworkData[currentNo]}
                currentNo={currentNo}
                NextQuestion={NextQuestion}
                loading={loading}
                questionTimer={questionTimer}
                checked={checked}
                setChecked={setChecked}
                saveQuestion={saveQuestion}
                submitQuestion={UploadData}
                canvasRef={canvasRef}
                mode={mode}
                setMode={setMode}
                ClearDraw={ClearDraw}
                homeworkEndTime={homeworkEndTime}
            />
        </Layout>
    )
};

export default HomeworkDetail;