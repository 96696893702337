import axios from "axios";
import { useCookies } from "react-cookie";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";

import { BoardDetailView } from "components/Academy/board";
import { Layout } from "components/Academy/Layout";

const BoardDetail = () => {

  const { noticesNo } = useParams();
  const [ cookies ] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;

  const [boardDetail,setBoardDetail] = useState();

  const loadBoardData = async () =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/notice/${LoginKey}`, config).then((Response) => {    
      let loadData = Response.data.Notices;
      const filteredData = loadData.filter((list) => list.AcademyNoticeNo === Number(noticesNo));
      setBoardDetail(filteredData[0])
  });
};


  useEffect(()=>{
    loadBoardData()
  },[]);


  return (
  <Layout> 
    <BoardDetailView boardDetail={boardDetail}/>
  </Layout>
   );
}
 
export default BoardDetail;