import axios from 'axios';
import { Layout } from 'components/user/layout';
import { QuestionHomework } from 'components/user/question';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { HomeworkInfo } from 'components/info';

const Homework = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();

  const [homeworkDatas, setHomeworkDatas] = useState([{
    DueDate: '',
    StartDate: '',
    ClassNo: '',
    Notice: '',
    Students: [{
      Name: '',
      StudentNo: '',
      Questions: [],
      MemoPicture: []
    }],
    QuestionCnt: '',
    Seciton: '',
    Manager_id: { Name: '' },
    Class_id: { ClassName: '' }
  }]);
  const loadHomeworks = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/student/homework/${LoginKey}`, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        let loadHomeworks = Response.data.Homeworks;
        loadHomeworks.sort(function (a, b) {
          return new Date(a.DueDate) - new Date(b.DueDate);
        });
        setHomeworkDatas(loadHomeworks);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  useEffect(()=>{
    if(LoginKey){
      loadHomeworks(); 
    }
  }, []);

  return (
    <Layout>
      {/* <QuestionHomework homeworkDatas={homeworkDatas} /> */}
      {LoginKey ? <QuestionHomework homeworkDatas={homeworkDatas} /> : <HomeworkInfo/>}
    </Layout>
  )
}

export default Homework