import React from 'react';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import IconCategory1 from 'img/icon_category1.png';
import IconCategory2 from 'img/icon_category2.png';
import { useSnackbar } from 'notistack';

const Category = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    return(
        <>
            <div className='main_div_title'>
                <p className='main_sub_title'>숙제 관리</p>
            </div>
            <Grid container>
                <Grid item className='main_div_category main_background_blue' onClick={() => navigate(`${location.pathname}/homework/create`)}>
                    <span>학생들에게 숙제를 낼 수 있어요!</span>
                    <p><span className='category_blue'>숙제내기</span></p>
                    <img src={IconCategory1} alt='icon category1' />
                </Grid>
                <Grid item className='main_div_category main_background_yellow' onClick={() => navigate(`${location.pathname}/homework`)}>
                    <span>클래스의 숙제 진행 상황을 확인해 보세요!</span>
                    <p><span className='category_yellow'>숙제관리</span></p>
                    <img src={IconCategory2} alt='icon category2' />
                </Grid>
            </Grid>
        </>
    );
};

export default Category;