import React, { useState } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import WooromathLogo from 'img/wooromath_logo.png';

const ChangePassword = () => {
    const { tumpCode } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [password, setPassword] = useState({
        pwd: '',
        pwdc: ''
    });
    const [updating, setUpdating] = useState(false);

    const handleClickUpdate = async() => {
        if(password.pwd && password.pwd === password.pwdc){
            setUpdating(true);
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({
                tumpCode: tumpCode,
                password: password.pwd
            });
            await axios.put(`/api/teacher/password`, body, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                    setTimeout(()=> {
                        navigate('/');
                    }, [1000]);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });      
                    setTimeout(()=> {
                        setPassword({ pwd: '', pwdc: '' });
                        setUpdating(false);
                    }, [1000]);
                }
            }).catch((Error) => {
              enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        }else{
            enqueueSnackbar(`비밀번호가 일치하지 않습니다.`, { variant: 'error' });
        }
    };

    return(
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                <div style={{ marginTop: '30px', boxShadow: '0px 6px 15.8px rgba(75, 75, 75, 0.23)', padding: '30px', textAlign: 'center'}}>
                    <img src={WooromathLogo} alt='wooromath logo' style={{ width: '15%' }}/>
                    <h3>비밀번호 변경</h3>
                    <Grid container justifyContent='center' >
                        <Grid item xs={12} sm={10} md={8} style={{ marginBottom: '15px' }}>
                            <TextField
                                required
                                fullWidth
                                id='outlined-basic'
                                label='비밀번호'
                                type='password'
                                variant='outlined'
                                value={password.pwd}
                                onChange={(e) => setPassword({...password, pwd: e.target.value})}
                                disabled={updating}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10} md={8} style={{ marginBottom: '15px' }}>
                            <TextField
                                required
                                fullWidth
                                id='outlined-basic'
                                label='비밀번호 확인'
                                type='password'
                                variant='outlined'
                                value={password.pwdc}
                                onChange={(e) => setPassword({...password, pwdc: e.target.value})}
                                disabled={updating}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10} md={8} style={{ textAlign: 'right' }}>
                            {!updating ?
                            <>
                                <Button onClick={() => navigate(`/`, { replace: true })}>취소</Button>
                                <Button color='warning' variant='contained' disableElevation onClick={handleClickUpdate} disabled={!password.pwd || !password.pwdc}>비밀번호 변경</Button>
                            </>:
                            <CircularProgress color='warning'/>}
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default ChangePassword;