import { Grid,Button,Link} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import WooroTestLogoOnly from 'img/woorotest_logo_only.png';

const AppBanner = (props) => {
  const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const apple= /iPhone|iPad|iPod/i.test(navigator.userAgent);

 

  return ( 
   <>
     {isMobileOrTablet ?
      <Grid container sx={{paddingTop:"5px",alignItems:"center",background:"#FAFAFC" }}>
        <Grid item  xs={12} sm={1} md={0.5} sx={{textAlign:{xs:"right",sm:"left",md:"left"},height:"20px"}}>
          <CloseIcon sx={{cursor:"pointer",width:"20px",height:"20px"}} onClick={() => props.appBannerClose()}/>
        </Grid>
        <Grid item xs={1.8} sm={1} md={1} >
          <div  style={{backgroundImage:`url('${WooroTestLogoOnly}')`,width:"42px",height:"42px",backgroundRepeat:"no-repeat",backgroundSize:"cover",borderRadius:"8px",backgroundColor:"#FFFFFF",boxShadow:"0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)" }}>
          </div> 
        </Grid>
        <Grid item xs={5.6}sm={3} md={3} sx={{padding:"0px !important",marginLeft:"10px",'@media (max-width: 280px)':{display:"none"}}}>
          <p>5문제로 빠른 진단은 우로매쓰</p>
          <p style={{fontWeight:"700",color:"black"}}>
            우로매쓰 앱으로 보기
          </p>
        </Grid>
        <Grid item xs={4.2}sm={6.8} md={7.3} textAlign="right" sx={{'@media (max-width: 280px)': {marginLeft:"140px"},}}>
            <Link sx={{padding:"7px",border:"1px solid #F05D38",fontSize:"14px",margin:"0px",textDecoration:"none" ,color:"#F05D38"}} href={apple ? "https://apps.apple.com/kr/app/%EC%9A%B0%EB%A1%9C%EB%A7%A4%EC%93%B0-%ED%95%99%EB%B6%80%EB%AA%A8%EC%9A%A9/id6450442172" : "https://play.google.com/store/apps/details?id=com.wooromath_parent&hl=ko-KR"} target="_blank">
              앱에서 보기
            </Link>
      
        </Grid>
      </Grid>
      :
      ""
    }
   </>


   );
}
 
export default AppBanner;