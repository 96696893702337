import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/parent/layout';
import { ParentHomeworkManage } from 'components/parent/child/homework';

const HomeworkManage = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const { ChildNo } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [homeworkDatas, setHomeworkDatas] = useState([{
    ClassNo: '',
    HomeworkNo: '',
    DueDate: '',
    Notice: '',
    Difficulty: '',
    Section: '',
    StartDate: '',
    Question_ids: [],
    Students: [{
      Name: '',
      Questions: [{ Memo: '' }],
      MemoPicture: [],
    }]    
  }]);

  useEffect(() => {
    const loadHomeworkDatas = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/parent/homework/${ChildNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          let loadHomeworks = Response.data.Homeworks;
          loadHomeworks.sort(function (a, b) {
            return new Date(a.DueDate) - new Date(b.DueDate);
          });
          for(let i = 0; i < loadHomeworks.length; i++){
            const completedCnt = loadHomeworks[i].Students.filter((item) => { return item.Questions.length === loadHomeworks[i].QuestionsCnt }).length;
            loadHomeworks[i].CompletedCnt = completedCnt;
          }
          setHomeworkDatas(loadHomeworks);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }
    loadHomeworkDatas();
  }, []);

  return (
    <Layout>
      <ParentHomeworkManage
        homeworkDatas={homeworkDatas}
      />
    </Layout>
  )
};

export default HomeworkManage;