import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSnackbar } from 'notistack';
import { CircularProgress } from "@mui/material";

import { Layout } from "components/Academy/Layout";
import { BoardListView } from "components/Academy/board";
import { DeleteAcademyModal } from 'components/modal';
import * as ChangeText from 'components/function/ChangeText';

const BoardList = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const [openDeleteBoard, setOpenDeleteBoard] = useState(false);
  const [boardList,setBoardList] = useState();
  const [boardNo,setBoardNo] = useState();
  const [loading, setLoading] = useState(false);
  
  const handleClickOpenDeleteBoard = (NO) => {
    setOpenDeleteBoard(true);
    setBoardNo(NO)
  };
  const handleClickCloseDeleteBoard = () => {
    setOpenDeleteBoard(false);
  };


const loadBoardData = async () =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/notice/${LoginKey}`, config).then((Response) => {    
      let loadData= Response.data.Notices
      loadData.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));  
      setBoardList(loadData)
  }).catch((Error) => {
    enqueueSnackbar(`Network Error`, { variant: 'error' });
  });

};

  const rows = [];
  for (let i = 0; i < boardList?.length; i++) {
    rows.push({
      id: i + 1,
      date: ChangeText.YyyyMmDd(boardList[i]?.createdAt),
      title: boardList[i]?.Title,
      text: boardList[i]?.Content,
      noticesNo:boardList[i]?.AcademyNoticeNo
    });
  };


  const handleClickCell = (noticesNo) => {
      navigate(`/academy/board/detail/${noticesNo}`);
  };


  const handleUpdateData =(noticesNo)=>{
    navigate(`update/${noticesNo}`)
  };

  const handleClickDelete = async ()=>{
    await axios({
      method: 'patch',
      url: `/api/academy/notice`,
      data: {
        academyNoticeNo: boardNo,
        loginKey: LoginKey,
      }
    })
    .then((Response) => {
      setOpenDeleteBoard(false);
      let deleteBoardDatas = [...boardList];
      deleteBoardDatas = deleteBoardDatas.filter((item) => { return item.AcademyNoticeNo !== boardNo });
      setBoardList(deleteBoardDatas);
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(()=>{
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);
    loadBoardData()
  },[]);



  return (
  <Layout > 
     {
      loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
    <BoardListView 
      rows={rows} 
      handleClickCell={handleClickCell}
      handleUpdateData={handleUpdateData}
      handleClickOpenDeleteBoard={handleClickOpenDeleteBoard}
      />
     }
    <DeleteAcademyModal 
      open={openDeleteBoard} 
      handleClickDelete={handleClickDelete}
      handleClickOpenDelete={handleClickOpenDeleteBoard} 
      handleClose={handleClickCloseDeleteBoard}
      text={"삭제"}
      title='공지사항 삭제'>
      '삭제' 버튼 클릭 시 즉시 삭제 되며
      삭제 된 데이터는 복구 할 수 없습니다.<br/>정말 삭제하겠습니까?        
    </DeleteAcademyModal>
  </Layout>
   );
};
 
export default BoardList;