import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSnackbar } from 'notistack';
import { CircularProgress } from "@mui/material";

import { TicketListView }from "components/Academy/mockExam"
import { Layout } from "components/Academy/Layout";
import * as ChangeText from 'components/function/ChangeText';

const TicketList = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();

  const [tickets, setTickets] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const loadTicketData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/ticket/${LoginKey}`, config).then((Response) => {
      setTickets(Response.data.Tickets);
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  const rows = [];
  for (let i = 0; i < tickets?.length; i++) {
    for (let j = 0; j < tickets[i].Teachers.length; j++) {
      const teachers = tickets[i].Teachers[j].Teacher_id
      rows.push({
        id: i + j + 1, 
        name: teachers.Name,
        phone: teachers.Phone,
        email: teachers.Email,
        Profile:teachers.Profile,
        createdAt: ChangeText.YyyyMmDd(tickets[i].Teachers[j].CreatedAt),
        count:tickets[i].Teachers[j].Count
      });
    }
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const filterTeachers = (datas) => {
    datas.sort();
    datas = datas.filter((data) => {
      return (
        data.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        data.email.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    });
    return datas;
  };

 

  useEffect(()=>{
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);
    loadTicketData()
  },[]);

  return ( 
    <Layout >
      {
      loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
      <TicketListView 
      handleChangeSearch={handleChangeSearch}
      rows={filterTeachers(rows)}
      />
      }
    </Layout>
   );
}
 
export default TicketList;