import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import MainBannerImage from 'img/main_banner_parent.png';

const Banner = (props) => {
    const [cookie] = useCookies(['ChildName']);
    const navigate = useNavigate();
    const location = useLocation();

    return(    
        <Grid container className='main_banner' justifyContent={'center'}>
            <span className='main_banner_circle1'/>
            <span className='main_banner_circle2'/>
            <span className='main_banner_circle3'/>
            <Grid item xs={12} sm={12} md={10}>
                <h1>
                    {cookie.ChildName} 학생의 성적을 관리해보세요.
                </h1> 
                <button onClick={() => navigate(`${location.pathname}/dashboard`)}>대시보드</button>
                <img src={MainBannerImage} alt='main banner images' className='parent_image' />
            </Grid>
        </Grid>
    );
};

export default Banner;