import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { StudentDashboard } from 'components/user/student';
import * as AddValue from 'components/function/AddValue';
import { QuestionSection } from 'components/function/SortQuestions';


const UserDashboard = ()=> {
  const [cookies] = useCookies(['LoginKey']);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [student, setStudent] = useState({});
  const [questionInfo, setQuestionInfo] = useState({});
  const [diagnosis, setDiagnosis] = useState([]);
  const [zScore, setZScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [summary, setSummary] = useState({ StudentCount: 1, Counting: 1, Time: 1, Correct: 1 });
  const [sectionScore, setSectionScore] = useState([
    { zscore: 0, current: 0, solve: 0, diagnosisCount: 0 },
    { zscore: 0, current: 0, solve: 0, diagnosisCount: 0 },
    { zscore: 0, current: 0, solve: 0, diagnosisCount: 0 },
    { zscore: 0, current: 0, solve: 0, diagnosisCount: 0 },
    { zscore: 0, current: 0, solve: 0, diagnosisCount: 0 },
    { zscore: 0, current: 0, solve: 0, diagnosisCount: 0 }
  ]);
  const [sectionQuestionCount, setSectionQuestionCount] = useState([{ _id: '', Count: '' }]);
  const [parts, setParts] = useState(AddValue.AllPartsDashboard());
  const loginKey = cookies.LoginKey;

  const getStudentInfo = async() => {;
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/student/${loginKey}`, config).then((Response) => {
      let questionTime = 0;
      let quesitonCurrent = 0;
      let questionSolve = 0;
      let temp = [...sectionScore];
      for(let i = 0; i < Response.data.student.Questions?.length; i++){
        if(Response.data.student.Questions[i].Status !== 5){
          questionSolve = questionSolve + 1;
        }
        if(Response.data.student.Questions[i].Status === 1){
          temp[Math.floor(Response.data.student.Questions[i].Section/100)-1].solve = temp[Math.floor(Response.data.student.Questions[i].Section/100)-1].solve + 1;
          if(Response.data.student.Questions[i].Check === 1){
            temp[Math.floor(Response.data.student.Questions[i].Section/100)-1].current = temp[Math.floor(Response.data.student.Questions[i].Section/100)-1].current + 1;
          }
        }
        if(Response.data.student.Questions[i].Check === 1){
          quesitonCurrent = quesitonCurrent + 1;
          let thisTime = Response.data.student.Questions[i].Time ? Response.data.student.Questions[i].Time : 0;
          questionTime = questionTime + thisTime;
        }
      };
      for(let i = 0; i < Response.data.student.Diagnosis.length; i++){
        temp[Math.floor(Response.data.student.Diagnosis[i].Section/100-1)].diagnosisCount = temp[Math.floor(Response.data.student.Diagnosis[i].Section/100-1)].diagnosisCount + 1;
        temp[Math.floor(Response.data.student.Diagnosis[i].Section/100-1)].zscore = temp[Math.floor(Response.data.student.Diagnosis[i].Section/100-1)].zscore + Response.data.student.Diagnosis[i].ZScore;
      }
      setSectionScore(temp);
      setQuestionInfo({ Time: questionTime, Solve: questionSolve, Current: quesitonCurrent });
      setStudent(Response.data.student);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const getDiagnosisInfo = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/diagnosis/${loginKey}`, config).then((Response) => {
      let data = Response.data.result;
      let tempParts = [...parts];
      for(let i = 0; i < data.length; i++){
        for(let j = 0; j < 6; j++){
          if(Math.floor(data[i].Section/100) === j+1){
            for(let k = 0; k < tempParts[j].subSections.length; k++){
              for(let t = 0; t < tempParts[j].subSections[k].sections.length; t++){
                if(tempParts[j].subSections[k].sections[t].section === data[i].Section){
                  tempParts[j].subSections[k].sections[t].ZScore = data[i].ZScore;
                }
              }
            }
          }
        }
      }
      let tempZScore = 0;
      for(let i = 0; i < Response.data.result.length; i++){
        tempZScore = tempZScore + Response.data.result[i].ZScore;
      }
      setZScore(tempZScore/Response.data.result.length);
      setDiagnosis(Response.data.result);
      setParts(tempParts);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const getSolveInfo = async () => {
    let cnt = 0;
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/info/${loginKey}`, config).then((Response) => {
      let userQuestions = [];
      if(!Response.data.Message){
        for(let i =0; i < Response.data.result.User_id.Questions.length; i++){
          if(Response.data.result.User_id.Questions[i].Status === 1){
            cnt += 1;
          }
        }
        if (cnt !== 0){
          for(let i = 0; i < Response.data.result.User_id.Questions.length; i++){
            if(Response.data.result.User_id.Questions[i].Status === 1){
              userQuestions.push({
                Question_id: Response.data.result.User_id.Questions[i].Question_id._id,
                QuestionNo: Number(Response.data.result.User_id.Questions[i].Question_id.Question),
                Check: Number(Response.data.result.User_id.Questions[i].Check),
                Status: Number(Response.data.result.User_id.Questions[i].Status),
                Section: Number(Response.data.result.User_id.Questions[i].Question_id.Section),
                CreatedAt: Response.data.result.User_id.Questions[i].CreatedAt,
              });
            }
          }
        }
      }
      let obj = QuestionSection(userQuestions);
      setQuestions(obj);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const getQuestionSummary = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/summary`, config).then((Response) => {
      const studentCount = Response.data.UserSummary.filter((item) => { return item._id === 1 });
      let tempQuestionCount = 0;
      let tempQuestionCorrect = 0;
      let tempQuestionTime = 0;
      for(let i = 0; i < Response.data.QuestionSummary.length; i++){
        if(Response.data.QuestionSummary[i]._id === 1){
          tempQuestionCount += Response.data.QuestionSummary[i].Counting;
          tempQuestionCorrect += Response.data.QuestionSummary[i].Counting;
          tempQuestionTime += Response.data.QuestionSummary[i].Time;
        }else if(Response.data.QuestionSummary[i]._id === 2){
          tempQuestionCount += Response.data.QuestionSummary[i].Counting;
        }
      }
      setSummary({ StudentCount: studentCount[0].Count, Counting: tempQuestionCount, Correct: tempQuestionCorrect, Time: tempQuestionTime });
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const getQuestionCounting = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/counting`, config).then((Response) => {
      setSectionQuestionCount(Response.data);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(()=>{
    if(cookies.LoginKey){
      getStudentInfo();
      getDiagnosisInfo();
      getSolveInfo();
      getQuestionSummary();
      getQuestionCounting();
    }else{
      enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error' });
      navigate(`/login`);
    }
  }, []);

  return (
    <Layout>
      <StudentDashboard
        student={student}
        questionInfo={questionInfo}
        zScore={zScore}
        questions={questions}
        diagnosis={diagnosis}
        parts={parts}
        sectionScore={sectionScore}
        summary={summary}
        sectionQuestionCount={sectionQuestionCount}
      />
    </Layout>
  )
}

export default UserDashboard