import { Grid,Avatar,AvatarGroup,CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import card from 'img/card.png';
import teacher from 'img/academy_dashboard.png';
import WooroTestLogoOnly from 'img/woorotest_logo_only.png';
import * as ChangeText from 'components/function/ChangeText';

const AcademyDashboardView = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pages = pathname.split("/");

  const handleClickMenu = (link) => {
    if (link !== pathname) {
      navigate(`/${pages[1]}/${link}`);
    }
  };
  
  const progress = (props.tickets/props.ticket[props.ticket.length-1]?.Count)*100
  return (
     <Grid container justifyContent={"center"}>
      <Grid container lg={12} xl={10} justifyContent={"space-between"}>
        <Grid lg={3.8} xl={3.5} pb={2} sx={{background:"#FFFFFF",borderRadius:"14px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.16)",height:"181px",marginBottom:"30px"}}>
          <div
            style={{ cursor:"pointer",marginTop:"40px" }}
            onClick={() => handleClickMenu("teacher")}
          >
            <p
              style={{
                marginRight: "20px",
                fontSize:"24px",
                fontWeight:"500",
              }}
            >
              교사인원
            </p>
            <div
            style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>              
            <span 
              style={{
                fontSize:"51px",
                fontWeight:"700", 
              }}>
                {props.teacherList?.length}
              </span>
            <AvatarGroup total={props.teacherList?.length}>
              {props.teacherList?.map((teacher,i)=>
                <Avatar alt="Remy Sharp" key={i} src={teacher.Profile} sx={{ width:45, height:45}}/>
              )}
            </AvatarGroup>
            </div>
          </div>
        </Grid>
        <Grid lg={3.8} xl={3.5} sx={{background:"#FFFFFF",borderRadius:"14px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.16)",height:"181px"}}>
          <div 
            style={{ cursor:"pointer" ,marginTop:"40px"}}
            onClick={() => handleClickMenu("student")}>
           <p
            style={{
              marginRight: "20px",
              fontSize:"24px",
              fontWeight:"500",
              }}
            >
              학생인원
            </p>
            <div
            style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <span
              style={{
                fontSize:"51px",
                fontWeight:"700", 
               }}>
                {props.studentList?.length}
              </span>
              <AvatarGroup total={props.studentList?.length} >
                {props.studentList?.map((student,i)=>
                <Avatar alt="Remy Sharp" src={student.Profile} key={i} sx={{ width:45, height:45}}
                />
                )}
              </AvatarGroup>
            </div>
            
          </div>
          <div></div>
        </Grid>
        <Grid lg={3.8}  xl={3.5} p={2} sx={{background:"#FFFFFF",borderRadius:"14px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.16)",height:"181px"}}>
          <div
            style={{ cursor:"pointer" }}
            onClick={() => handleClickMenu("teacher/create")}
          >
            <p
             style={{
              marginRight: "20px",
              fontSize:"24px",
              fontWeight:"500",
              }}
            >
              교사생성
            </p>
            <div
            style={{display:"flex",justifyContent:"right",position:"relative"}}
            >
              <Avatar
                alt=""
                src={WooroTestLogoOnly}
                sx={{ width:81, height: 81,marginRight:"10px",backgroundColor:"#f5f6f8" }}
                
              />
              <img src={teacher} alt="" width={"44px"}height={"44px"} style={{position:"absolute",bottom:"0px",}}/>            
            </div>
          </div>
        </Grid>
        <Grid lg={3.8} xl={3.5}p={4} mt={4} sx={{background:"#FFFFFF",borderRadius:"14px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.16)"}}>
          <div>
            <span 
            style={{
              marginRight: "20px",
              fontSize:"24px",
              fontWeight:"500",
              }}
            > 
            관리클래스
            </span>
           <span
             style={{
              fontSize:"32px",
              fontWeight:"700", 
            }}>
              {props.classList?.length}
            </span>
            {props.classList?.length > 0 ?
              (props.classList?.length >= 6 ?
                <div style={{ display:"flex",alignItems:"end" }}>
                  <div style={{ display:"flex",justifyContent:"space-between",flexWrap:"wrap",marginTop:"40px" }}>
                  {props.classs.map((classData,i)=>
                      i !== props.classs.length - 1 ?
                      <div
                        className="dashboard_class_box"
                        onClick={() => handleClickMenu(`class/${classData.ClassNo}`)}
                        key={i}
                        style={{backgroundImage: classData.ClassImage ? `url('${classData.ClassImage}')`: `url('${WooroTestLogoOnly}')`,width:"92px",height:"92px",backgroundRepeat:"no-repeat",backgroundSize:"cover",borderRadius:"20px",backgroundColor:"#f5f6f8",cursor:"pointer" }}
                        >
                      </div>:
                      <div 
                      key={i}
                      className="dashboard_class_box"
                      onClick={() => handleClickMenu("class")}
                      style={{fontSize:"40px",fontWeight:"700",width:"92px",height:"92px",backgroundRepeat:"no-repeat",backgroundSize:"cover",borderRadius:"20px",backgroundColor:"#f5f6f8" ,display:"flex",alignItems:"center",justifyContent:"center"}}
                      >
                       +{props.classList?.length-5}
                      </div>
                    )}
                  </div>
                </div>
                  :
                  <div style={{display:"flex",alignItems:"end"}}>
                    <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",marginTop:"40px"}}>
                      {props.classList.map((classData,i)=>
                      <div
                      key={i}
                      onClick={() => handleClickMenu(`class/${classData.ClassNo}`)}
                      className="dashboard_class_box"
                        style={{backgroundImage: classData.ClassImage ? `url('${classData.ClassImage}')`: `url('${WooroTestLogoOnly}')`,width:"92px",height:"92px",backgroundRepeat:"no-repeat",backgroundSize:"cover",borderRadius:"20px",backgroundColor:"#f5f6f8",cursor:"pointer" }}
                        >
                      </div>
                      )}
                    </div>
                  </div>
                )
                :
              <h1 style={{marginTop:"120px",textAlign:"center"}}> 생성한 클래스가 없습니다.</h1>
            }
          
          </div>
        </Grid>
        <Grid lg={3.8} xl={3.5} p={4}  sx={{background:"#FFFFFF",borderRadius:"14px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.16)",marginTop:"32px"}}>
          <div 
            style={{ cursor:"pointer" }}
            onClick={() => handleClickMenu("mockexam")}
          >
            <span 
              style={{
                marginRight: "20px",
                fontSize:"24px",
                fontWeight:"500",
                }}
            > 모의고사 응시권
            </span>
            <span   
             style={{
              fontSize:"32px",
              fontWeight:"700", 
            }}>
              {props.tickets}장
            </span>
            <div className="academy_ticket">
              <CircularProgress variant="determinate" value={progress}  sx={{color:"#F05D38",width:"211px !important",height:"211px !important",}}/>
              <p 
              style={{
                fontSize:"36px",
                fontWeight:"700",
                position:"absolute" 
            }}>{props.tickets}장</p>
            </div>
          </div>
        </Grid>
        <Grid lg={3.8} xl={3.5}p={4} mt={4} sx={{background:"#FFFFFF",borderRadius:"14px",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.16)"}}>
          <div 
          style={{ cursor:"pointer" }}
          onClick={() => handleClickMenu("price/list")}
          >
            <span
              style={{
                marginRight: "20px",
                fontSize:"24px",
                fontWeight:"500",
                }}
            > 결제 관리</span>
            <p style={{fontWeight:"500",fontSize:"16px"}}> 다음 결제일 {ChangeText.YyyyMmDd(props.order[0]?.Order_id.NextOrder)} </p>
            <div style={{textAlign:"center",marginTop:"40px"}}>
              <img src={card} alt="card" />
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default AcademyDashboardView;
