import { TeacherHomeworkSelectView } from "components/teacher/class/homework";
import { Layout } from 'components/teacher/layout';

const TeacherHomeworkSelect = () => {
  return ( 
    <Layout>
      <TeacherHomeworkSelectView/>
    </Layout>
   );
}
 
export default TeacherHomeworkSelect;