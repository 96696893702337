import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Grid , Card, CardContent, Typography, Button, CardActions, Avatar, Stack } from '@mui/material';
import { MockExamQuestionKind } from 'components/function/ChangeText';

const MockExamView = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const now = new Date();
    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>모의고사</h2>
                </Grid>
                <Grid container xs={12} md={10} style={{ padding: 0 }} alignItems='stretch'>
                    {props.mockExams.map((mockExam, i) => (
                    <Grid xs={12} sm={6} md={4} lg={3} style={{ marginBottom: '16px', padding: '0 8px' }}>
                        <Card style={{ height: '100%', position: 'relative' }}>
                            <CardContent style={{ paddingBottom: 0 }}>
                                <Typography>
                                    {mockExam.Name.replace('우로매쓰 ', '')}
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                                    {mockExam.State === 1 ? 
                                    '진행중':
                                    '제출 완료'}
                                </Typography>
                                <Typography>
                                    {MockExamQuestionKind(mockExam.Part)}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size='small' color='warning' disabled={mockExam.State === 1} onClick={() => navigate(`${location.pathname}/${mockExam.MockExamNo}/${mockExam.Part}`)}>상세보기</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

export default MockExamView;