import { ResponsiveBar } from "@nivo/bar";

const StudentDetailBarChart = (props) => {
  // const data = [
  //   {
  //     country: "1월",
  //     횟수:3 
  //   },
  //   {
  //     country: "2월",
  //     횟수:2
  //   },
  //   {
  //     country: "3월",
  //     횟수:10
  //   },
  //   {
  //     country: "4월",
  //     횟수:4
  //   },
  //   {
  //     country: "6월",
  //     횟수:2
  //   },
  //   {
  //     country: "7월",
  //     횟수:6
  //   },
  //   {
  //     country: "8월",
  //     횟수:1
  //   },
  //   {
  //     country: "9월",
  //     횟수:8
  //   },
  //   {
  //     country: "10월",
  //     횟수:4
  //   },
  //   {
  //     country: "11월",
  //     횟수:9
  //   },
  //   {
  //     country: "12월",
  //     횟수:10
  //   },
  // ];
  return (
    <ResponsiveBar
      data={props.data}
      keys={["횟수"]}
      indexBy="country"
      margin={{ top:10, right: 30, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "진단횟수(월)",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      // axisLeft={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "country",
        // legendPosition: "middle",
        // legendOffset: 32,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[]}

      role="application"
      onClick={(data) => {
        props.test(data.data);
      }}
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default StudentDetailBarChart;
