import { useState,useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import { Layout } from "components/Academy/Layout";
import { UserUpdateView } from "components/Academy/user";
import { CircularProgress } from '@mui/material';

const UserUpdate = () => {
  const [cookies,setCookie] = useCookies(['LoginKey','Profile','UserNo']);
  const { enqueueSnackbar } = useSnackbar();
  const navigate=useNavigate();

  const [imgBase64, setImgBase64] = useState();
  const [beforeImage, setBeforeImage] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [academy,setAcademy] = useState();
  const [updating, setUpdating] = useState(false);

  const loadAcademyData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
      setAcademy(Response.data.Academy);
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

const condition =
  academy?.Name?.length !== 0 &&
  academy?.Name !== undefined &&
  academy?.Phone?.length !== 0 &&
  academy?.Phone !== undefined &&
  academy?.Address?.length !== 0 &&
  academy?.Address !== undefined &&
  academy?.BusinessRegistation?.length !== 0 &&
  academy?.BusinessRegistation !== undefined &&
  academy?.Representative?.length !== 0 &&
  academy?.Representative !== undefined ;

  const handleAcademyUpdate = async () => {
    setUpdating(true);
    if (condition) {
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({logo:academy.Logo,name:academy.Name,businessRegistation:academy.BusinessRegistation,phone:academy.Phone,representative:academy.Representative,address:academy.Address,loginKey:cookies.LoginKey})
      await axios.put('/api/academy', body, config).then((Response) => {
        enqueueSnackbar(`수정이 완료돠었습니다`, { variant: 'info' });
        uploadProfile()
        setTimeout(()=> {
          setUpdating(false);
          navigate(-1)
        }, [1000]);
      }).catch((err)=>{
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      })
    } else {
        enqueueSnackbar(`내용을 입력해 주세요.`, { variant: 'error' });
    }
  };

  const handleChange = (event) => {
    let changeAcademyInfo = { ...academy };
    changeAcademyInfo[event.target.name] = event.target.value;
    setAcademy(changeAcademyInfo);
  };

  

  const handleClickDeleteProfile = () => {
    setImgBase64('');
    setImgFile('');
    let DeleteProfile = { ...academy };
    DeleteProfile.Logo = '';
    setAcademy(DeleteProfile);
};

  const handleChangeProfile = (event) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if ( base64 ) {
        setImgBase64(base64.toString()); 
      }
    }
    if(event.target.files[0]){
      reader.readAsDataURL(event.target.files[0]); 
      setImgFile(event.target.files[0]);
    }
  };


  const uploadProfile = async() => {
    if(imgFile){
      const formData = new FormData();
      formData.append('loginKey', cookies.LoginKey);
      formData.append('academyNo', academy.AcademyNo);
      formData.append('imgFile', imgFile);
      formData.append('beforeImage', beforeImage);
      const config = { headers: { 'content-type': 'multipart/form-data' }};
      await axios.put(`/api/academy/logo`,formData, config).then((Response) => {
        let date = new Date();
        date.setDate(date.getDate()+365);
        setCookie('Logo', Response.data.Logo, { path: '/', expires: date });
        setAcademy({ ...academy, Logo: Response.data.Logo });
        setImgBase64();
        setImgFile(null);
        setBeforeImage(Response.data.Logo);
      }).catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      return false;
    }
  };


  useEffect(()=>{
    loadAcademyData()
  },[]);


  return (
  <Layout >
    {updating ?
    <div style={{textAlign:"center",marginTop:"300px"}}>
      <CircularProgress/>
    </div>
    :
    <UserUpdateView 
    handleChangeProfile={handleChangeProfile}
    handleClickDeleteProfile={handleClickDeleteProfile}
    imgBase64={imgBase64}
    handleChange={handleChange}
    academy={academy}
    handleAcademyUpdate={handleAcademyUpdate}
    />
    }
  </Layout>
   );
}
 
export default UserUpdate;