import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { Layout } from 'components/admin/layout';
import * as ChangeText from 'components/function/ChangeText';


const EventList = () => {
    const pages = [
        { title: '이벤트 관리'},
        { title: '참여 목록'}
    ];
    const title = { main: '참여 목록', sub: 'event'};

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cookies] = useCookies(['LoginKey']);


    // 컬럼
    const columns = [
        { field: 'id', headerName: 'No.', width: 70 },
        { field: 'ZScore', headerName: 'ZScore', width: 140 },
        { field: 'PhoneNumber', headerName: '전화번호', width: 140 },
        { field: 'CreatedAt', headerName: '참여일시', width: 230 }
    ];

    // 데이터 로드
    useEffect(() => {
        const loadEvent = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/event/list/${cookies.LoginKey}`, config).then((Response) => {
                const loadData = Response.data.filter((item) => { return item.PhoneNumber.indexOf('-') > -1 });
                let eventDatas = [];
                for(let i = 0; i < loadData.length; i++){
                    eventDatas.push({
                        id: i+1,
                        ZScore: loadData[i].ZScore,
                        PhoneNumber: loadData[i].PhoneNumber,
                        CreatedAt: loadData[i].createdAt ? ChangeText.YyyyMmDdHhMmSs(loadData[i].createdAt) : '-'
                    });
                }
                setLoading(false);
                setEvents(eventDatas);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadEvent();
    },[]);

    return(
        <Layout pages={pages} title={title}>
            <Grid container justifyContent="space-between">
                <Grid xs={12} className="list_table">
                    <DataGrid
                        rows={events}
                        columns={columns}
                        pageSize={15}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc'}]
                            }
                        }}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Layout>
        
    );
};

export default EventList;