import React from 'react'
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { Dashboard, DashboardDetail, DashboardSection,DashboardMonth } from 'domain/teacher/dashboard';

const DashboardPresenter = () => {
  return (
    <Routes>
        <Route path='' element={<Dashboard/>} />
        <Route path='/:StudentNo' element={<DashboardDetail/>} />
        <Route path='/:StudentNo/:Section' element={<DashboardSection/>} />
        <Route path='/:StudentNo/month/:Month' element={<DashboardMonth/>} />
        <Route path='/*' element={<NotFound/>} />
    </Routes>
  );
};

export default DashboardPresenter;