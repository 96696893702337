import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import { East } from "@mui/icons-material";
import diagnosis_info from "img/diagnosis_info.png";
import diagnosis_question from "img/diagnosis_question.png";
import diagnosis_info_desktop2 from "img/diagnosis_info_desktop2.png";
import diagnosis_info_desktop1 from "img/diagnosis_info_desktop1.png";
import NormalDistributionImgae from "img/normal_distribution.png";

const DiagonosisInfo = () => {
  const navigate = useNavigate();

  return (
    <Grid className="diagnosisInfo">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={10} lg={8} xl={5.5}>
          <Grid container alignItems={"center"} className="diagnosisInfo_title">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
              <h1 style={{ fontSize: "40px"}}>
                5문제로 진단하기
              </h1>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                로그인 후 이용할 수 있는 서비스 입니다!
              </p>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                숙제하기, 대시보드 등 다양한 기능을 활용해보세요!
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.5} 
              lg={5.5}
              xl={5.5}
              sx={{ display: "flex", alignItems: "center" ,justifyContent:"center"}}
            >
              <img src={diagnosis_info} alt={diagnosis_info} ></img>
              <div  style={{
                    marginLeft:"30px"
                  }}>
                <h2
                  style={{
                    fontSize: "30px",
                    marginBottom: "0",
                    color: "#ef4c23",
                  }}
                >
                  정확도
                </h2>
                <h1
                  style={{ fontSize: "40px", marginTop: "0", color: "#ef4c23" }}
                >
                  96.9%
                </h1>
              </div>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <h2>진단하기</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <h3 className="info_text">1. 단원 선택</h3>
              <img src={diagnosis_info_desktop1} width="100%" alt="img"></img>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <div style={{ display: "flex" }}>
                <h3 className="info_text">2.&nbsp;</h3>
                <h3 className="info_text">세부 단원 선택 후 진단하기 클릭 </h3>
              </div>
              <img src={diagnosis_info_desktop2} width="100%" alt="img"></img>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} mt={6}>
              <h3 className="info_text">3. 5문제로 진단하기</h3>
              <img src={diagnosis_question} width="100%" alt="img"></img>
            </Grid>
          </Grid>
          <Grid container className="title">
            <Grid item xs={12} md={6} lg={12} xl={12}>
              <h2>진단결과</h2>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="diagnosis_result_table"
            >
              <table>
                <tbody>
                  <tr>
                    <th>단원</th>
                    <td>수학1 &gt; 수열 &gt; 수열의 합 </td>
                  </tr>
                  <tr>
                    <th>정답률</th>
                    <td>60%</td>
                  </tr>
                  <tr>
                    <th>평균 풀이 시간</th>
                    <td>3분 15초</td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="diagnosis_result_graph"
            >
              <div>
                <img src={NormalDistributionImgae} alt="normal distribution" />
                <span>
                  수능 1등급
                  <br />
                  내신 4등급
                </span>
                <div className="diagnosis_result_legend">
                  <div className="school_avg">
                    <p />
                    학교평균
                  </div>
                  <div className="all_avg">
                    <p />
                    전국평균
                  </div>
                  <div className="my_grade">
                    <p />내 성적
                  </div>
                </div>
                <div className="diagnosis_result_bar">
                  <div className="school_avg" style={{ left: `80%` }} />
                  <div className="all_avg" style={{ left: `50%` }} />
                  <div className="my_grade" style={{ left: `70%` }} />
                </div>
              </div>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="small"
            color="inherit"
            style={{ padding: "7px", width: "100%", marginTop: "24px" }}
            className="button-wooro"
            endIcon={<East />}
            onClick={() => {
              navigate(`/login`);
            }}
            target="_blank"
          >
            로그인 바로가기
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DiagonosisInfo;
