import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { HomeworkAssign, HomeworkManage, HomeworkPrint, HomeworkDetailStudent, HomeworkDetailQuestion, TeacherHomeworkSelect, TeacherHomeworkCustom, TeacherHomeworkScoring,HomeworkCustomPrint } from 'domain/teacher/homework';

const HomeworkPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<HomeworkManage/>}/>
      <Route path=':HomeworkNo/scoring' element={<TeacherHomeworkScoring/>}/>
      {/* <Route path='create' element={<HomeworkAssign/>}/> */}
      <Route path='select/create' element={<HomeworkAssign/>}/>
      <Route path='select' element={<TeacherHomeworkSelect/>}/>
      <Route path='select/custom' element={<TeacherHomeworkCustom/>}/>
      <Route path=':HomeworkNo/custom/print' element={<HomeworkCustomPrint/>}/>
      <Route path=':HomeworkNo/print' element={<HomeworkPrint/>}/>
      <Route path=':HomeworkNo/student/:StudentNo' element={<HomeworkDetailStudent/>}/>
      <Route path=':HomeworkNo/question/:QuestionNo' element={<HomeworkDetailQuestion/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
  );
};

export default HomeworkPresenter;