import axios from "axios";
import { useState,useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useCookies } from "react-cookie";

import { Layout } from "components/Academy/Layout";
import { AcademyPriceListView } from 'components/Academy/main/price'
import * as ChangeText from 'components/function/ChangeText';
import { DeleteAcademyModal } from 'components/modal';
import { AcademyOrderUpdateModal } from 'components/modal';

const AcademyPriceList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(['LoginKey']);

  const [priceList,setPriceList] = useState();
  const [studentList,setStudentList] = useState();
  const [cancelText, setCancelText] = useState('');
  const [open, setOpen] = useState(false);
  const [orderModelOpen, setOrderModelOpen] = useState(false);
  const [updating, setUpdating] = useState(false);

  const loadOrderDatas= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/price/${cookies.LoginKey}`, config).then((Response) => {
      let loadPriceDatas = Response.data.Prices;
      loadPriceDatas.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));

      if(loadPriceDatas[0].Order_id.OrderCancel){
          setCancelText(`서비스 해지 신청을 취소합니다.`);
      }else{
          setCancelText(`${loadPriceDatas[0].data.orderName}을 해지합니다. ${ChangeText.YyyyMmDdHhMmSs(new Date(loadPriceDatas[0].Order_id.NextOrder).setHours(new Date(loadPriceDatas[0].Order_id.NextOrder).getHours(loadPriceDatas[0].Order_id.NextOrder)+1, 0, 0))}까지 사용가능하며, 이후 자동 결제되지 않습니다.`);
      }
      setPriceList(loadPriceDatas);
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const loadStudentsData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${cookies.LoginKey}`, config).then((Response)=>{
      setStudentList(Response.data.Students)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };

  const handleClickCancel = async() => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const data = JSON.stringify({
        orderId: priceList[0].Order_id._id,
        orderCancel: priceList[0].Order_id.OrderCancel ? false : true,
        loginKey: cookies.LoginKey
    });
    await axios.put(`/api/order`, data, config).then((res) => {
        setTimeout(() => {
            const changePrices = [...priceList];
            changePrices[0].Order_id.OrderCancel = res.data.OrderCancel;
            setPriceList(changePrices);
            setOpen(false);
            setUpdating(false);
            setTimeout(() => {
                if(res.data.OrderCancel){
                    setCancelText(`서비스 해지 신청을 취소합니다.`);
                }else{
                    setCancelText(`${changePrices[0].data.orderName}을 해지합니다. 
                    ${ChangeText.YyyyMmDdHhMmSs(new Date(changePrices[0].Order_id.NextOrder).setHours(new Date(changePrices[0].Order_id.NextOrder).getHours(changePrices[0].Order_id.NextOrder)+1, 0, 0))}까지 사용가능하며, 이후 자동 결제되지 않습니다.`);
                }
            }, [1000]);
        }, [1500]);
        if(res.data.orderCancel){
            enqueueSnackbar('서비스 해지 신청이 취소되었습니다.', { variant: 'info' });
        }else{
            enqueueSnackbar('서비스 해지 신청이 완료되었습니다.', { variant: 'info' });
        }
    }).catch((err) => {
        console.log(err);
    });
};

  useEffect(()=>{
    loadOrderDatas();
    loadStudentsData();
  },[]);

  const rows = [];
  for (let i = 0; i < priceList?.length; i++) {
    rows.push({
      id:i+1,
      orderName:priceList[i]?.data.orderName,
      createdAt:ChangeText.YyyyMmDd(priceList[i]?.createdAt),
      amount:priceList[i]?.data.suppliedAmount,
      period:`${ChangeText.YyyyMmDd(priceList[i]?.createdAt)} ~ ${ChangeText.YyyyMmDd(priceList[i]?.Order_id.NextOrder)}`
    })
  };
  
  return ( 
  <Layout >
    <AcademyPriceListView 
    rows={rows}
    priceList={priceList}
    setOpen={setOpen}
    studentList={studentList}
    setOrderModelOpen={setOrderModelOpen}
    /> 
    <AcademyOrderUpdateModal
    open={orderModelOpen}
    handleClose={()=>setOrderModelOpen(false)}
    >

    </AcademyOrderUpdateModal>
    <DeleteAcademyModal 
      open={open} 
      handleClickDelete={handleClickCancel}
      handleClose={() => setOpen(false)}
      updating={updating}
      text={priceList && priceList[0].Order_id.OrderCancel ? "해지 취소" : "해지"}
      title={'서비스 해지'}
      >
      {cancelText}       
    </DeleteAcademyModal>

  </Layout>

  );
}
 
export default AcademyPriceList;