import React from 'react';
import AdminSchoolPresenter from './AdminSchoolPresenter';

const AdminSchoolContainer = () => {
  return (
    <AdminSchoolPresenter />
  );
};

export default AdminSchoolContainer;
