import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { StudentDetailInfo, StudentQuestionInfo } from 'components/admin/student';
import { DeleteModal, ResetPasswordModal } from 'components/modal';

const StudentInfo = () => {
    const pages = [
        { title: '회원 관리'},
        { title: '학생 목록', link: '/admin/student' },
        { title: '학생 상세 정보'},
    ];
    const title = { main: '학생 상세 정보', sub: 'user'};
    const [student, setStudent] = useState({
        _id: '',
        Name: '',
        Birth: '',
        Gender: '',
        Email: '',
        Phone: '',
        Login: '',
        State: '',
        School_id: { Name: '' },
        Grade: '',
        Status: '',
        Questions: [{Question_id: [{ _id: '', CreatedAt: '' }]}],
    });
    const { id } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    // 데이터 로드
    useEffect(() => {
        const loadStudent = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/student/${id}/${cookies.LoginKey}`, config).then((Response) => {
                setStudent(Response.data);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadStudent();
    },[id]);

    const [openDeleteStudent, setOpenDeleteStudent] = useState(false);
    const handleClickOpenDeleteStudent = () => {
        setOpenDeleteStudent(true);
    }
    const handleClickCloseDeleteStudent = () => {
        setOpenDeleteStudent(false);
    }
    const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
    const handleClickOpenDeleteQuestion = (key) => {
        setOpenDeleteQuestion(true);
    }
    const handleClickCloseDeleteQuestion = () => {
        setOpenDeleteQuestion(false);
    }
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const handleClickOpenResetPassword = () => {
        setOpenResetPassword(true);
    }
    const handleClickCloseResetPassword = () => {
        setOpenResetPassword(false);
    }
    return(
        <Layout pages={pages} title={title}>
            <StudentDetailInfo
                student={student}
                handleClickOpenDelete={handleClickOpenDeleteStudent}
                handleClickOpenResetPassword={handleClickOpenResetPassword} 
            />
            <StudentQuestionInfo
                questions={student.Questions}
                handleClickOpenDeleteQuestion={handleClickOpenDeleteQuestion}
            />
            <DeleteModal 
                openDelete={openDeleteStudent} 
                handleClickOpenDelete={handleClickOpenDeleteStudent} 
                handleClickCloseDelete={handleClickCloseDeleteStudent}
                title='학생 정보 삭제'
            >
                유저 번호를 제외한 학생 정보가 삭제됩니다.<br/>삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
            </DeleteModal>
            <ResetPasswordModal 
                openResetPassword={openResetPassword} 
                handleClickCloseResetPassword={handleClickCloseResetPassword}
                title='비밀번호 초기화'
            >
                비밀번호가 초기화 됩니다.<br/>가입한 이메일 인증을 통해 비밀번호를 변경 할 수 있습니다.
            </ResetPasswordModal>
            <DeleteModal 
                openDelete={openDeleteQuestion} 
                handleClickCloseDelete={handleClickCloseDeleteQuestion}
                title='문제 풀이 정보 삭제'
            >
                문제 풀이 정보가 삭제됩니다.<br />삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
            </DeleteModal>
        </Layout>
    );
}

export default StudentInfo;