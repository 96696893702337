import React from "react";
import { Routes, Route } from "react-router-dom";
import { StudentList,StudentDetail} from "domain/Academy/student";
import { DiagnosisDetail,StudentDiagnosis} from "domain/Academy/student/Diagnosis";
import NotFound from "routes/NotFound";

const AcademyStudentPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<StudentList />} />
      <Route path=":id" element={<StudentDetail />} />
      <Route path=":id/:section" element={<DiagnosisDetail />} />
      <Route path=":id/:section/diagnosis/:diagnosisNo" element={<StudentDiagnosis />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AcademyStudentPresenter;
