import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/user/layout';
import { Register } from 'components/user';
import { SearchSchoolModal } from 'components/modal';
import { SearchFormFind } from 'components/form';
import { StudentSchoolList } from 'components/user/student';


const UserCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = useState({
    Name: '',
    Gender: '',
    BirthYear: '',
    BirthMonth: '',
    BirthDay: '',
    School: { _id: '', Name: '', Local: '', County: '' },
    SchoolName: '',
    State: 1,
    Attending: '',
    Grade: '',
    Email: '',
    Password: '',
    Phone: '',                
    PasswordConfirm: '',
    Token: '',
    Code: '',
    Login: 1,
    Status: 0,
  });

  const navigate = useNavigate();
  const [ , setCookie] = useCookies(['LoginKey', 'Name', 'UserNo', 'Profile', 'ClassNo', 'ClassName', 'ClassImage', 'ChildNo', 'ChildName', 'ChildNickName', 'ChildProfile']);
  const { state } = useLocation();
  const location = useLocation();
  const [emailCode, setEmailCode] = useState(null);
  const [emailCheck, setEmailCheck] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [agree, setAgree] = useState([false, false, false]);
  const [agreeAll, setAgreeAll] = useState(false);
  const [selected, setSelected] = useState(0);
  const [searchSchool, setSearchSchool] = useState('');
  const [openSearchSchool, setOpenSearchSchool] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [schools, setSchools] = useState([]);
  const [firstSearch, setFirstSearch] = useState(false);
  const [attendingDisabled, setAttendingDisabled] = useState(false);
  const [registerDisabled, setRegisterDisabled] = useState(true);
  const [registerUploading, setRegisterUploading] = useState(false);
  const [snsLogin, setSnsLogin] = useState(false);
  const [age14, setAge14] = useState(false);
  
  useEffect(() => {
    if(state){
      let changeUserInfo = { ...userInfo };
      changeUserInfo.Email = state.userInfo.Email;
      changeUserInfo.Name = state.userInfo.Name;
      changeUserInfo.Token = state.userInfo.Token;
      changeUserInfo.Login = state.Login;
      changeUserInfo.Gender = state.userInfo.Gender;
      changeUserInfo.Profile = state.userInfo.Profile;
      setUserInfo(changeUserInfo);
      setEmailCheck(true);
      setSnsLogin(true);
    }
  },[])
  useEffect(() => {
    if(agree[0] && agree[1] && agree[2]){
      setAgreeAll(true);
    }else{
      setAgreeAll(false);
    }
  },[agree]);

  useEffect(() => {
    if(selected === 1){
      if(userInfo.Name && userInfo.School._id && userInfo.Attending){
        if(snsLogin){
          setRegisterDisabled(false);
        }else if(userInfo.Password && userInfo.Password === userInfo.PasswordConfirm && emailCheck){
          setRegisterDisabled(false);
        }else{
          setRegisterDisabled(true);
        }
      }else{
        setRegisterDisabled(true);
      }
    }else{
      if(userInfo.Status === '2'){
        if(userInfo.Name){
          if(snsLogin){
            setRegisterDisabled(false);
          }else if(userInfo.Password && userInfo.Password === userInfo.PasswordConfirm && emailCheck){
            setRegisterDisabled(false);
          }else{
            setRegisterDisabled(true);  
          }
        }else{
          setRegisterDisabled(true);
        }
      }else if(userInfo.Status === '3'){
        if(userInfo.Name){
          if(snsLogin){
            setRegisterDisabled(false);
          }else if(userInfo.Password && userInfo.Password === userInfo.PasswordConfirm && emailCheck){
            setRegisterDisabled(false);
          }else{
            setRegisterDisabled(true);
          }
        }else{
          setRegisterDisabled(true);
        }
      }else{
        setRegisterDisabled(true);
      }
    }
  }, [userInfo, emailCheck]);

  const handleChangeAgreeAll = (event) => {
    if(event.target.checked){
      setAgree([true, true, true]);
    }else{
      setAgree([false, false, false]);
    }
    setAgreeAll(event.target.checked);
  };
  const handleChangeAgree = (event) => {
    let changeAgree = [...agree];
    changeAgree[event.target.name] = event.target.checked;
    setAgree(changeAgree);
  };
  const handleClickSelect = (status) => {
    if(status === 1){
      setUserInfo({ ...userInfo, Status: 1 });
    }else{
      setUserInfo({ ...userInfo, Status: 0 });
    }
    setSelected(status);
  };
  
  const handleChange = (event) => {
    let changeUserInfo = { ...userInfo };
    changeUserInfo[event.target.name] = event.target.value;
    setUserInfo(changeUserInfo);
  };

  const handleClickOpenSearchSchool = () => {
    setOpenSearchSchool(true);
  }
  const handleClickCloseSearchSchool = () => {
    setOpenSearchSchool(false);
  };
  const handleChangeSearchSchool = (event) => {
    setSearchSchool(event.target.value);
  };
  
  const handleClickSearchSchool = async() => {
    if(searchSchool){
      setLoadingSearch(true);
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/school/${searchSchool}`, config).then((Response) => {
        setSchools(Response.data);
        setFirstSearch(true);
        setLoadingSearch(false);
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      return false;
    }
  };
  const handleClickSchool = (event) => {
    if(event.Name === '외국고'){
      setUserInfo({...userInfo, School: event, Attending: 3});
      setAttendingDisabled(true);
    // }else if(event.Name === '중학생'){
    //   setUserInfo({...userInfo, School: event, Attending: 4});
    //   setAttendingDisabled(true);
    }else if(event.Name === '검정고시'){
      setUserInfo({...userInfo, School: event, Attending: 5});
      setAttendingDisabled(true);
    }else if(event.Name === '기타'){
      setUserInfo({...userInfo, School: event, Attending: 6});
      setAttendingDisabled(true);
    }else{
      setUserInfo({ ...userInfo, School: event });
      setAttendingDisabled(false);
    }
    setOpenSearchSchool(false);
    setSearchSchool('');
    setSchools([]);
    setFirstSearch(false);
  };

  const handleChangeAttending = (event) => {
    const value = event.target.value;
    let changeUserInfo = { ...userInfo };
    changeUserInfo.Attending = value;
    if(value === '2'){
      changeUserInfo.Grade = '';
    }else if(value === '3'){
      changeUserInfo.School = { _id: '61ac75a330a84ae5abd659d0', Name: '외국고', Local: '기타', County: '' };
    // }else if(value === '4'){
    //   changeUserInfo.School = { _id: '61ac75a330a84ae5abd65b59', Name: '중학생', Local: '기타', County: '' };
    }else if(value === '5'){
      changeUserInfo.Grade = '';
      changeUserInfo.School = { _id: '61ac75a330a84ae5abd6535e', Name: '검정고시', Local: '기타', County: '' };
    }else if(value === '6'){
      changeUserInfo.Grade = '';
      changeUserInfo.School = { _id: '61ac75a330a84ae5abd654b4', Name: '기타', Local: '기타', County: '' };
    }
    setUserInfo(changeUserInfo);
  }
  
  /* 회원 가입 */
  const handleClickRegister = async () => {
    setRegisterUploading(true);
    if(!registerDisabled){
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({ userInfo: userInfo, agree: agree });
      await axios.post(`/api/register`, body, config).then((Response) => {
        if(location.search.indexOf('click_key') > -1){
          PostBack(location.search.split('click_key=')[1]);
        }
        setTimeout(() => {
          window.fbq('track', 'CompleteRegistration', { currency: 'KRW', value: 3000 });
          let date = new Date();
          date.setDate(date.getDate()+365); 
          setCookie('LoginKey', Response.data.LoginKey, { path: '/', expires: date });
          setCookie('Name', Response.data.Name, { path: '/', expires: date });
          setCookie('UserNo', Response.data.UserNo, { path: '/', expires: date });
          setCookie('Profile', undefined, { path: '/', expires: date });
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          if(Response.data.Status === 1){
            navigate('/', { replace: true });
          }else if(Response.data.Status === 2){
            setCookie('ChildNo', undefined, { path: '/', expires: date });
            setCookie('ChildName', undefined, { path: '/', expires: date });
            setCookie('ChildNickName', undefined, { path: '/', expires: date });
            setCookie('ChildProfile', undefined, { path: '/', expires: date });
            navigate('/parent', { replace: true });
          }else if(Response.data.Status === 3){
            setCookie('ClassNo', undefined, { path: '/', expires: date });
            setCookie('ClassName', undefined, { path: '/', expires: date });
            setCookie('ClassImage', undefined, { path: '/', expires: date });
            navigate('/teacher', { replace: true });
          }else{
            navigate('/', { replace: true });
          }
        }, [1200])
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      return false
    }
  };

  /* 이메일 인증 */
  const emailAuth = async () => {
    const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if(reg_email.test(userInfo.Email)){
      setLoadingCode(true);
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({ Email : userInfo.Email });
      await axios.post(`/api/emailAuth`, body, config).then((Response) => {
        if(Response.data.Success){
          setEmailCode(Response.data.Code); 
          enqueueSnackbar(`인증번호 전송에 성공하였습니다.`, { variant: 'info' });
        }else{
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'error' });
        }
        setLoadingCode(false);
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      enqueueSnackbar(`이메일 형식이 올바르지 않습니다.`, { variant: 'error' });
    }
  };

  const PostBack = async(key) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/event/postback/${key}`, config).then((Response) => { console.log(Response) });
  };

  const checkEmailCode = () => {
    if(userInfo.Code === emailCode){
      setEmailCheck(true);
      enqueueSnackbar(`이메일 인증이 완료 되었습니다.`, { variant: 'info' });
    }else{
      enqueueSnackbar(`인증번호가 일치하지 않습니다.`, { variant: 'error' });
    }
  }

  return (
    <Layout>
      <Register
        agree={agree}
        agreeAll={agreeAll}
        selected={selected}
        userInfo={userInfo}
        emailCode={emailCode}
        loadingCode={loadingCode}
        attendingDisabled={attendingDisabled}
        emailCheck={emailCheck}
        registerDisabled={registerDisabled}
        registerUploading={registerUploading}
        snsLogin={snsLogin}
        age14={age14}
        handleChangeAgreeAll={handleChangeAgreeAll}
        handleChangeAgree={handleChangeAgree}
        handleClickSelect={handleClickSelect}
        handleChange={handleChange}
        setUserInfo={setUserInfo}
        handleClickOpenSearch={handleClickOpenSearchSchool}
        emailAuth={emailAuth}
        checkEmailCode={checkEmailCode}
        handleClickRegister={handleClickRegister}
        handleChangeAttending={handleChangeAttending}
        setAge14={setAge14}
      />
      <SearchSchoolModal 
          openSearch={openSearchSchool} 
          handleClickCloseSearch={handleClickCloseSearchSchool}
          title='학교 검색'
      >
          <SearchFormFind 
            search={searchSchool}
            handleChange={handleChangeSearchSchool}
            searchGuide='학교 이름으로 검색'
            loading={loadingSearch} 
            handleClick={handleClickSearchSchool}
          />
          <StudentSchoolList 
            schools={schools} 
            loading={loadingSearch} 
            search={searchSchool} 
            handleClickSchool={handleClickSchool}
            firstSearch={firstSearch}
          />
      </SearchSchoolModal>
    </Layout>
  );
};

export default UserCreate;
