import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, InputLabel, OutlinedInput, IconButton, Button, CircularProgress, FormHelperText } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import WooroTestLogoOnly from 'img/woorotest_logo_only.png'

const ParentChildUpdate = (props) => {
    const navigate = useNavigate();
    return (
        <Grid container justifyContent='center'>
            <Grid xs={12} sm={9} md={6} lg={4.8} xl={3.5} className='teacher_class_create'>
                <div 
                    className='teacher_class_create_image'
                    style={{ backgroundImage:
                        props.childData.Child_id.Profile ? `url('${props.childData.Child_id.Profile}')` : `url('${WooroTestLogoOnly}')`
                    }}
                >
                </div>
                <div className='teacher_class_create_name'>
                    <FormControl>
                        <InputLabel htmlFor='child_nickname' color='warning'>{props.childData.Connect === 2 ? '자녀 별명' : props.childData.Child_id.Name}</InputLabel>
                        <OutlinedInput
                            id='child_nickname'
                            name='NickName'
                            color='warning'
                            type='text'
                            value={props.childData.NickName}
                            onChange={props.handleChangeChild}
                            disabled={props.childData.Connect !== 2}
                            label='자녀 별명'
                            placeholder={`자녀 별명을 붙여주세요.`}
                        />
                        <FormHelperText style={{ marginLeft: '0px'}}>{props.childData.Connect === 2 ? `등록된 자녀 이름: ${props.childData.Child_id.Name}` : '해당 학생은 수락 대기중 입니다.'}</FormHelperText>
                    </FormControl>
                </div>
                <div className='teacher_class_button_group'>
                    <Button variant='outlined' color='error' style={{ float: 'left' }} onClick={props.handleClickDelete} disabled={props.updating}>삭제</Button>
                    <Button color='secondary' onClick={() => navigate(-1)}>취소</Button>
                    <Button variant='outlined' color='warning' onClick={props.handleClickUpdate} disabled={props.updating || props.childData.Connect !== 2}>수정</Button>
                </div>
                {props.updating ?
                <div className='teacher_class_button_group'>
                    <CircularProgress color='warning'/>
                </div> : null}
            </Grid>
        </Grid>
    );
};

export default ParentChildUpdate;