import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SchoolList, SchoolCreate, SchoolInfo, SchoolUpdate } from 'domain/admin/school';
import NotFound from 'routes/NotFound';

// https://designrevision.com/demo/shards-dashboard-lite-react/blog-overview

const AdminSchoolPresenter = () => {
    return(
        <Routes>
            <Route path='' element={<SchoolList />} />
            <Route path='create' element={<SchoolCreate />} />
            <Route path=':id' element={<SchoolInfo />} />
            <Route path=':id/update' element={<SchoolUpdate />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminSchoolPresenter;