import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { SchoolDetailCreate } from 'components/admin/school';

const SchoolCreate = () => {
    const pages = [
        { title: '학교 관리'},
        { title: '학교 등록'}
    ];
    const title = { main: '학교 등록', sub: 'school'};
    const [school, setSchool] = useState({
        Name: ''
    });
    const [creating, setCreating] = useState(false);
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);

    const handleChange = (event) => {
        let changeSchool = {...school};
        changeSchool[event.target.name] = event.target.value;
        setSchool(changeSchool);
    };
    const handleClickCreate = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            NEIS_code: school.NEIS_code,
            Name: school.Name,
            Local: school.Local,
            County: school.County,
            WooroRank: school.WooroRank,
            WooroZscore: school.WooroZscore,
            State: school.State,
            loginKey: cookies.LoginKey
        });
        await axios.post(`/api/admin/school`, body, config).then((Response) => {
            setTimeout(()=> {
                navigate(`/admin/school/${Response.data.result._id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    return (
        <Layout pages={pages} title={title}>
            <SchoolDetailCreate
                school={school}
                handleChange={handleChange}
                creating={creating}
                handleClickCreate={handleClickCreate}
            />
        </Layout>
    )
};

export default SchoolCreate;