import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { HomeworkManage } from 'domain/parent/homework';
import { QuestionViewDetail } from 'domain/parent/question';

const HomeworkPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<HomeworkManage/>}/>
      <Route path=':Section/:QuestionNo' element={<QuestionViewDetail/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
  );
};

export default HomeworkPresenter;