import React, { useState } from 'react';
import { Grid, Link, CircularProgress, IconButton } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked, TaskAlt, ModeEdit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

import NormalDistributionImgae from 'img/normal_distribution.png';
import { QuestionMinute, MockExamQuestionKind, YyyyMmDdHkr, StaNine, Percent } from 'components/function/ChangeText';
import QuestionCheck1 from 'img/question_check1.png';
import QuestionCheck2 from 'img/question_check2.png';
import { QuestionMemoReview } from 'components/user/question';

const MockExamResultView = (props) => {
    const { Kind } = useParams();
    const answerList = ['①', '②', '③', '④', '⑤'];
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    const [selectQuestion, setSelectQuestion] = useState({
        Answer: 1,
        Choices: ['', '', '', '', ''],
        Kind: 1,
        Point: 1,
        SolveInfo: { Check: 2, Select: 1, Memo: '', Time: 0 },
        Content: [' '],
        Commentary: [' '],
        SingleAnswer: 0
    });
    const [select, setSelect] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const mockExam = props.mockExam;
    const questions = props.mockExam.Question_ids;

    const openGrade = new Date(mockExam.StartDate).setDate(new Date(mockExam.StartDate).getDate()+14); // 성적 오픈 일 (시작 + 15일)
    const isOpenGrade = new Date() > openGrade; // 성적 오픈 가능 : true
    const standardPoint = 20*((mockExam.Point-mockExam.Mean)/mockExam.Sd)+100; // 표준 점수
    
    const percent = mockExam.Rank / mockExam.Freq;
    const percentBar = (0.15+((1-percent)*0.85))*100 < 85 ? (0.15+((1-percent)*0.85))*100 : 85;

    const handleClickQuestion = (key) => {
        document.querySelector('#question_commentary').scrollIntoView({ behavior : 'smooth' });
        setLoading(true);
        setSelectQuestion(questions[key]);
        setSelect(key);
        setTimeout(() => {
            setLoading(false);
        }, [1000]);
    };
    return (
        <Grid container justifyContent='center' className='content_section'>
            <Grid item xs={12} md={10} className='diagnosis_result_title'>
                <h2>{mockExam.Name.replace('우로매쓰 ', '')}</h2>
            </Grid>
            <Grid item xs={12} sm={7} md={6} className='diagnosis_result_table only_mock_exam_table'>
                <table>
                    <tr><th>선택 과목</th><td>{MockExamQuestionKind(Kind)}</td></tr>
                    <tr><th>총 풀이 시간</th><td>{QuestionMinute(mockExam.Time)}</td></tr>
                    <tr><th>원 점수</th><td>{mockExam.Point}점</td></tr>
                    <tr><th>표준 점수</th>{isOpenGrade ? <td>{Math.round(standardPoint)}점</td> : <td rowSpan={2} style={{ color: '#A4A4A4' }}>{YyyyMmDdHkr(openGrade)} 공개</td>}</tr>
                    <tr><th>전국 백분위</th>{isOpenGrade ? <td>{Percent(percent)}</td>: null}</tr>
                </table>
            </Grid>
            <Grid item xs={12} sm={5} md={4} className='diagnosis_result_graph'>
                <div>
                    <img src={NormalDistributionImgae} alt='normal distribution' />
                    <span style={{ color: isOpenGrade ? false : '#A4A4A4'}}>
                        수능 {isOpenGrade ? `${StaNine(1-percent)}등급` : ' · '}
                    </span>
                    <div className='diagnosis_result_legend'>
                        <div className='all_avg'><p/>전국평균</div>
                        <div className='my_grade'><p/>내 성적</div>
                    </div>
                    <div className='diagnosis_result_bar'>
                        <div className='my_grade' style={{left: `${percentBar}%`}}/>
                    </div>
                </div>
            </Grid>
            <Grid container item xs={12} md={10} className='mock_exam_answer_list'>
                {questions.map((question, i) => (
                <Grid item xs={2.4} style={{ 
                    borderTop: i < 5 ? '1px solid #000' : '0',
                    borderRight: '1px solid #000',
                    borderBottom: '1px solid #000',
                    borderLeft: i%5 === 0 ? '1px solid #000': '0',
                    color: question.SolveInfo.Check === 1 ? '#A4A4A4' : '#000',
                    padding: '8px'
                }}>
                    <Grid style={{ paddingBottom: '8px', borderBottom: '1px dashed #A4A4A4' }}>
                        {i+1}번
                    </Grid>
                    <Grid container style={{ paddingTop: '8px'}}>
                        <Grid item xs={6} style={{ marginBottom: '4px' }}>
                            {question.SingleAnswer === 1 ? 
                                question.SolveInfo.Select:
                                answerList[question.SolveInfo.Select-1]
                            }
                        </Grid>
                        <Grid item xs={6} style={{ marginBottom: '4px' }}>
                            {QuestionMinute(question.SolveInfo.Time)}
                        </Grid>
                        <Grid item xs={6}>
                            {question.SolveInfo.Check === 1 ? '정답' : '오답'}
                        </Grid>
                        <Grid item xs={6} style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#A4A4A4' }}>
                            <Link onClick={() => handleClickQuestion(i)}>문제 및 해설</Link>
                        </Grid>
                        
                    </Grid>
                </Grid>
                ))}
            </Grid>
            <Grid item xs={12} id='question_commentary' />
            {select !== -1 ? 
            loading ?
            <Grid item xs={12} md={10} style={{ textAlign: 'center', padding: '30px' }}>
                <CircularProgress color='warning' />
            </Grid> :
            <Grid container item xs={12} md={10}>
                <Grid item xs={12} style={{ padding: '0 0 16px 0', borderBottom: '1px dashed #A4A4A4' }} >
                    <div style={{ position: 'relative', height: '30px' }}>
                        <h3 style={{ position: 'absolute' }}>[{MockExamQuestionKind(selectQuestion.Kind)}] 문제 {select+1} <span style={{ color: '#A4A4A4', fontWeight: 'normal'}}>({selectQuestion.Point}점)</span></h3>
                        <img style={{ position: 'absolute', opacity: '0.65' }} src={selectQuestion.SolveInfo.Check === 1 ? QuestionCheck1 : QuestionCheck2} alt='check 1' />
                        <IconButton onClick={() => setOpen(true)} disabled={selectQuestion.SolveInfo.Memo === ''} edge='start' color='warning' style={{ position: 'absolute', right: 0, top: '25%' }}><ModeEdit /></IconButton>
                    </div>
                    <MathJaxContext version={3} config={config}>
                        {selectQuestion.Content.map((item) => (
                        <MathJax 
                            renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                            text={
                                item.replace('`\\(','').replace('\\)`','')
                            }
                            dynamic
                            className={item.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                        />
                        ))}
                    </MathJaxContext>
                    <div className='diagnosis_questions_answer'>
                        {isOpenGrade ?
                            selectQuestion.SingleAnswer ?
                                <div>
                                    {/* 답: <b style={{ color: '#EF4C23' }}>{selectQuestion.Choices[0]}</b> */}
                                    답: <b style={{ color: '#EF4C23' }}>
                                        <MathJaxContext version={3} config={config}>
                                            <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                               selectQuestion.NewChoices[0].replace('`\\(','').replace('\\)`','')
                                               } inline dynamic/>
                                         </MathJaxContext>
                                         </b>
                                    {selectQuestion.SolveInfo.Check === 2 ? 
                                        // <span style={{ color: '#A4A4A4' }}> / 제출: {selectQuestion.SolveInfo.Select}</span> :
                                        <span style={{ color: '#A4A4A4' }}> / 제출: 
                                         <MathJaxContext version={3} config={config}>
                                            <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                               selectQuestion.SolveInfo.Select.toString().replace('`\\(','').replace('\\)`','')
                                               } inline dynamic/>
                                         </MathJaxContext>
                                        </span> :
                                        null
                                    }
                                </div>
                                :
                                selectQuestion.NewChoices.map((choice, i) => (
                                    selectQuestion.SolveInfo.Check === 1 ?
                                        selectQuestion.Answer === i+1 ?
                                            <div>
                                                <RadioButtonChecked className='homework_question_answer'/>
                                                <MathJaxContext version={3} config={config}>
                                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                        choice.replace('`\\(','').replace('\\)`','')
                                                        } inline dynamic/>
                                                </MathJaxContext>
                                            </div>:
                                            <div>
                                               <RadioButtonUnchecked/>
                                               <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                    choice.replace('`\\(','').replace('\\)`','')
                                                    } inline dynamic/>
                                               </MathJaxContext>
                                            </div>:
                                        selectQuestion.Answer === i+1 ?
                                            <div>
                                                <RadioButtonChecked className='homework_question_answer'/>
                                                   <MathJaxContext version={3} config={config}>
                                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                            choice.replace('`\\(','').replace('\\)`','')
                                                            } inline dynamic/>
                                                    </MathJaxContext>
                                            </div>:
                                            selectQuestion.SolveInfo.Select === i+1 ? 
                                                <div>
                                                    <TaskAlt />
                                                    <MathJaxContext version={3} config={config}>
                                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                            choice.replace('`\\(','').replace('\\)`','')
                                                            } inline dynamic/>
                                                    </MathJaxContext>
                                                </div>:
                                                <div>
                                                    <RadioButtonUnchecked/>
                                                       <MathJaxContext version={3} config={config}>
                                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                            choice.replace('`\\(','').replace('\\)`','')
                                                            } inline dynamic/>
                                                    </MathJaxContext>
                                                </div>
                                )):
                                selectQuestion.SingleAnswer ?
                                <div>
                                    답: <b>
                                        <MathJaxContext version={3} config={config}>
                                            <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                               selectQuestion.SolveInfo.Select.toString().replace('`\\(','').replace('\\)`','')
                                               } inline dynamic/>
                                         </MathJaxContext>
                                     </b>
                                </div>
                                :
                                selectQuestion.NewChoices.map((choice, i) => (
                                    selectQuestion.SolveInfo.Select === i+1 ? 
                                        <div>
                                            <RadioButtonChecked />
                                               <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                    choice.replace('`\\(','').replace('\\)`','')
                                                    } inline dynamic/>
                                            </MathJaxContext>
                                        </div>:
                                        <div>
                                            <RadioButtonUnchecked />
                                               <MathJaxContext version={3} config={config}>
                                                <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                                    choice.replace('`\\(','').replace('\\)`','')
                                                     } inline dynamic/>
                                            </MathJaxContext>
                                        </div>
                                ))
                        }
                    </div>
                </Grid>
                {isOpenGrade ?
                <Grid item xs={12} style={{ padding: 0 }}>
                    <h3>해설</h3>
                    <MathJaxContext version={3} config={config}>
                        {selectQuestion.Commentary.map((item) => (
                        <MathJax
                            renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                            text={
                                item.replace('`\\(','').replace('\\)`','')
                            }
                            dynamic
                            className={item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                        />
                        ))}
                    </MathJaxContext>
                </Grid>:
                <Grid item xs={12} style={{ padding: 0 }}>
                    <h3>· 성적, 답안, 해설은 {YyyyMmDdHkr(openGrade)}에 공개됩니다.</h3>
                </Grid>}
            </Grid>:
            null}
            <QuestionMemoReview 
                open={open}
                setOpen={setOpen}
                memoReview={selectQuestion.SolveInfo.Memo}
            />
        </Grid>
    );
};

export default MockExamResultView;