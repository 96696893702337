import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, FormGroup, FormControlLabel, Switch, LinearProgress, Link } from '@mui/material';

import * as ChangeText from 'components/function/ChangeText';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const QuestionDetailInfo = (props) => {
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    const navigate = useNavigate();
    const question = props.question;
    const choicesNumber = ['①', '②', '③', '④', '⑤'];
    const avg = question.Info?.Counting > 0 ? question.Info.Correct / question.Info.Counting : '-';
    const avgTime = question.Info?.Correct > 0 ? question.Info.Time / question.Info.Correct : 0;
    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 문제 상세 정보</h3>
                </Grid>
                <Grid className='info_button_group'>
                    <FormGroup>
                        <FormControlLabel 
                            control={<Switch checked={question.State === 1 ? true : false} disabled={props.updating} onChange={props.handleChangeState}/>}
                            label={ChangeText.QuestionState(question.State)}
                        />
                    </FormGroup>
                    <Button variant='contained' color='warning' onClick={() => navigate(`/admin/question/${question._id}/update`)}>수정</Button>
                    <Button variant='contained' color='error' onClick={props.handleClickOpenDelete}>삭제</Button>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>ID</th><td>{question._id}</td>
                            <th>문제 번호</th>
                            <td>
                                {question.Question}
                                {props.nextQuestionId ? <Link className='other_question' href={`/admin/question/${props.nextQuestionId}`}>다음</Link> : null}
                                {props.beforeQuestionId ? <Link className='other_question' href={`/admin/question/${props.beforeQuestionId}`}>이전</Link> : null}
                            </td>
                        </tr>
                        <tr>
                            <td colspan={4}>
                                문제
                                {question.Content &&
                                    <MathJaxContext version={3} config={config}>
                                        {question.Content.map((content) => (
                                        <MathJax 
                                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                                        text={
                                        content.replace('`\\(','').replace('\\)`','')
                                        }
                                        dynamic
                                        className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                                        />
                                        ))}
                                    </MathJaxContext>
                                }
                                {/* <div style={{textAlign: 'center'}}>
                                    {question.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                                </div> */}
                            </td>
                        </tr>
                        <tr>
                            <td colspan={4}>
                                해설
                                {question.Commentary[0] ? 
                                <MathJaxContext version={3} config={config}>
                                    {question.Commentary.map((item) => (
                                    <MathJax
                                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                                        text={
                                            item.replace('`\\(','').replace('\\)`','')
                                        }
                                        dynamic
                                        className={item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                                    />
                                    ))}
                                </MathJaxContext>:
                                // question.CommentaryPicture ?
                                // question.CommentaryPicture.map((commentary) => (
                                //     <img
                                //         alt='Commentary Picture'
                                //         src={commentary?.includes('https://') ?
                                //             commentary :
                                //             `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${commentary}`
                                //         }
                                //         style={{ maxWidth: '100%', display: 'block' }}
                                //     />
                                // )):
                                 <div>해설이 없습니다.</div>}
                                {/* <div style={{textAlign: 'center'}}>
                                    {question.CommentaryImage ? <img alt='question pictures' src={question.CommentaryImage}/> : null}
                                </div> */}
                            </td>
                        </tr>
                        <tr>
                            <th>대단원</th><td>{ChangeText.Part(question.Section)}</td>
                            <th>소단원</th><td>{ChangeText.Section(question.Section)}</td>
                        </tr>
                        <tr>
                            <th>난이도</th><td>{ChangeText.Difficulty(question.Difficulty)}</td>
                            <th>문제 풀이 수</th><td>{question.Info?.Counting ? question.Info.Counting : 0}</td>
                        </tr>
                        <tr>
                            <th>정답률</th><td>{ChangeText.Percent(avg)}</td>
                            <th>평균 풀이 시간</th><td>{ChangeText.QuestionMinute(avgTime)}</td>
                        </tr>
                        <tr>
                            <th>변별도(W)</th><td>{question.WDiscrimality}</td>
                            <th>난이도(W)</th><td>{question.WDifficulty}</td>
                        </tr>
                        <tr>
                            <th>보기</th>
                            <td colSpan={3} className='question_choices'>
                                {question.NewChoices?.map((choice, i) => (
                                    <span className={i === question.Answer-1 ? 'question_answer' : null}>
                                    {choicesNumber[i]}
                                    <MathJaxContext version={3} config={config}>
                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                            choice.replace('`\\(','').replace('\\)`','')
                                            } inline dynamic
                                        />
                                    </MathJaxContext>
                                    </span>
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <th>문제 상태</th><td>{props.updating ? <LinearProgress color='inherit'/> : ChangeText.QuestionState(question.State)}</td>
                            <th>생성 일시</th><td>{question.createdAt}</td>
                        </tr>
                        <tr>
                            <th>검수 내용</th>
                            <td colSpan={2}>
                                {ChangeText.NotError(question.Inspection.NotError)}
                                {question.Inspection.Comment ? `, ${question.Inspection.Comment}` : null}
                            </td>
                            <td className='table_in_button'><Button variant='contained' color='inherit' onClick={props.handleClickOpenInspection}>검수 관리</Button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default QuestionDetailInfo;