import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { Main } from 'domain';

const MainPresenter = () => {
  return (
    <Routes>
      <Route path='/' element={<Main />}/>
      <Route path='/*' element={<NotFound />}/>
    </Routes>
  ); 
};

export default MainPresenter;