import { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";

import { TeacherDetailView } from "components/Academy/teacher";
import { Layout } from "components/Academy/Layout";
import * as ChangeText from 'components/function/ChangeText';

const TeacherDetail = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(['LoginKey']);
  const {id} = useParams();

  const [teacherDetailData,setTeacherDetailData] = useState();
  const [teacher,setTeacher] = useState();
  const [teacherHomework,setTeacherHomework] = useState();
  const [classData,setClassData] = useState();
  const [search, setSearch] = useState("");
  const [homework, setHomework] = useState();
  const [homeWorkKey, setHomeWorkKey] = useState(false);
  const [students,setStudents] = useState();
  const [loading, setLoading] = useState(false);

  const teacherNo = id;
  const classNo = classData?.ClassNo;
  const loginKey = cookies.LoginKey;

  const handleTeacherData = async () => {//클래스 데이터 호출
    const config = { headers: { 'Content-type': 'application/json' }};
    try {
      const response = await axios.get(`/api/academy/teacher/${teacherNo}/${loginKey}`, config);
      const teacherData = response.data;
      const updatedTeacherData = teacherData.Classs.map((item, index) => {
        return {
          ...item,
          checked: index === 0, // 첫 번째 항목인 경우 true, 그 외에는 false
        };
      });
      setTeacherDetailData(updatedTeacherData);
      setTeacher(teacherData.Teahcer)
      setClassData(updatedTeacherData[0])
    } catch (error) {
      console.log(error);
    }
  };


  const handleHomeworkData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${teacherNo}/${classNo}/${loginKey}`, config).then((Response)=>{
      setTeacherHomework(Response.data.Homeworks)
    }).catch((err)=>{})
  };

  useEffect(()=>{
    handleHomeworkData();
  },[classData]);

  const handleClassData= async()=>{//클래스 평균 등급 그래프 
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/class/${classNo}/${loginKey}`, config).then((Response)=>{
      let loadStudents = Response.data.Class.Students.filter(
        (item) => {return item.Connect === 2}
        );
        setStudents(loadStudents)
    }).catch((err)=>{})
  };

  useEffect(()=>{
    handleClassData();
  },[classNo]);

const lineChartDate = [{id:"class",data:[]}]
const barChartData = []

for (let i = 0; i < 12; i++) {
  const nowDate = new Date(new Date().setDate(1));
  const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
  lineChartDate[0]?.data?.push({ 'x': `${month}월` });
  barChartData.push({ 'country': `${month}월`,data:[] });
};

const monthlyData = {};
if (students) {
  students.forEach(item => {
    const diagnosis = item.Student_id.Diagnosis;
    diagnosis.forEach(diag => {
      const createdAt = new Date(diag.CreatedAt);
      const month = createdAt.getMonth(); 
      if (!monthlyData[month]) {
        monthlyData[month] = { sum: 0, count: 0 };
      }
      monthlyData[month].sum += diag.CDFSum;//월 별로 쪼개서 CDFSum을 다 더함
      monthlyData[month].count += diag.CDFCount; //월 별로 쪼개서 CDFCount를 다 더함
    });
  });
  lineChartDate[0]?.data?.forEach((month, index) => {
    const monthIndex = parseInt(month.x) - 1;
    if (monthlyData[monthIndex]) {//monthlyData 안에 1~12 월 중 맞는 월 에 데이터를 넣어준다
      const { sum, count } = monthlyData[monthIndex];
      const average = count > 0 ? sum / count : 0;
      month.y = ChangeText.StaNine(average);
    } else {
      month.y =  ChangeText.StaNine(0.03);//월 에 데이터가 없다면 9등급을 넣어준다
    }
  });
};

for (let i = 0; i < lineChartDate[0].data.length; i++) {
  let tempSum = 0
  if (lineChartDate[0].data[i].y <= 8) {
    tempSum = lineChartDate[0].data[i].y 
  }else{
    if (i === 0 ) {
      tempSum = 9
    }else {
      tempSum = lineChartDate[0].data[i - 1].y 
    }
  }
  lineChartDate[0].data[i].y = tempSum;
}


teacherHomework?.forEach(item => {
  const createdAt = new Date(item.createdAt);
  const dueDate= new Date(item.DueDate)
  const year = createdAt.getFullYear();
  const month = createdAt.getMonth() + 1;
  const day = createdAt.getDate();
  const dueMonth =dueDate.getMonth()+1
  const dueDay =dueDate.getDate();
  const formattedDate = `${year} - ${month.toString().padStart(2, "0")} -${day.toString().padStart(2, "0")}`;
  const section =`${ChangeText.Part(item.Section)} > ${ChangeText.MiddlePart(item.Section)} > ${ChangeText.Section(item.Section)}`
  const period=`${month < 10 ? "0" + month : month} - ${day < 10 ? "0" + day : day} ~ ${dueMonth < 10 ? "0" + dueMonth : dueMonth} - ${dueDay < 10 ? "0" + dueDay : dueDay}`

  barChartData?.forEach(monthData => {
    const { country } = monthData 
    const monthValue = parseInt(country.split("월")[0]); //1~12 월
    if (monthValue === month) {
      if (!monthData.data) {
        monthData.data = [];
      }
      monthData.data.push({ date:formattedDate, name: ChangeText.Part(item.Section),difficulty:item.Difficulty,period:period,section:section,homeworkNo:item.HomeworkNo,classNo:classNo,QuestionsCnt:item.QuestionsCnt});
    }
  });
});

barChartData.forEach(item => {
  item.data.forEach(item2 => {
    item2[item2.name] = item2.QuestionsCnt;//문제 수
  });
});

const classDetail=(classNo)=>{
  navigate(`/academy/class/${classNo}`)
};

const handleCheckboxChange = (event, i) => {
  const updatedTeacherDetailData = teacherDetailData.map((item, index) => {
    return {
      ...item,
      checked: index === i && event.target.checked,
    };
    });
    setTeacherDetailData(updatedTeacherDetailData);
    setHomeWorkKey(false)
    if (event.target.checked) {
      setClassData(teacherDetailData[i])
    } else {}
  };



  useEffect(()=>{
    handleTeacherData();
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);

  },[])

  // 검색
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const studentFilter = classData?.Students.filter((data) => {
    return (
      data?.Student_id?.Name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      data?.Student_id?.Email.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  });

  return (
    <Layout >
      {
        loading ? 
        <div style={{textAlign: 'center',marginTop:"400px"}}>
          <CircularProgress color='warning'/>
        </div>
          :
        <TeacherDetailView
          teacher={teacher}
          teacherDetailData={teacherDetailData}
          classData={classData}
          handleChangeSearch={handleChangeSearch}
          search={search}
          studentFilter={studentFilter}
          setHomeWorkKey={setHomeWorkKey}
          setHomework={setHomework}
          homeWorkKey={homeWorkKey}
          homework={homework}
          classDetail={classDetail}
          barChartData={barChartData}
          lineChartDate={lineChartDate}
          handleCheckboxChange={handleCheckboxChange}
        />
      }
    </Layout>
  );
};

export default TeacherDetail;

