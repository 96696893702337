import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { AchieveCreate,AchieveList } from 'domain/admin/Achieve';
import NotFound from 'routes/NotFound';
const AchievePresenter = () => {
  return ( 
    <Routes>
        <Route path='' element={<AchieveList />} />
        <Route path='create' element={<AchieveCreate />} />
        <Route path='/*' element={<NotFound />} />
    </Routes>
   );
}
 
export default AchievePresenter;