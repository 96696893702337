import {  Box, Grid,Button } from "@mui/material";

import { useNavigate } from "react-router-dom";

const BoardCreateView = (props) => {
  const navigate = useNavigate();

  return ( 
    <Grid container justifyContent="center">
      <Grid container lg={8} xl={7} sx={{flexFlow:"column",background:"#FFFFFF",borderRadius:"25px",padding:"0 20px"}}>
        <div style={{borderBottom:"1px solid #A4A4A4",marginTop:"30px",marginBottom:"48px"}}>
          <input type="text"placeholder="제목"value={props.boardInfo.title} name="title" onChange={props.handleChange} style={{padding:"5px",outline:"none",margin:"10px 0",border:"0",height:"79px",fontSize:"32px",fontWeight:"700",width:"100%"}}/>
        </div>
        <textarea  placeholder="내용" rows="30" name="text" value={props.boardInfo.text} onChange={props.handleChange}  style={{padding:"10px",outline:"none",resize: "none",border:"0",fontSize:"20px"}}></textarea>
        <Box sx={{margin:"30px 0",textAlign:"center"}}>
          <Button disableElevation  onClick={()=>navigate(-1)} sx={{border:"2px solid #F05D38" ,color:"#F05D38" ,width:"183px", fontSize:"20px",marginRight:"32px",borderRadius:"6px",'&:hover': { background:"#FFFFFF" }}}>취소</Button>
          <Button disableElevation  variant="contained" onClick={()=>{props.createBoard()}} sx={{ width:"183px", fontSize:"20px" ,background:"#F05D38",borderRadius:"6px",'&:hover': { background:"#F05D38" }}}>등록</Button>
        </Box>
    </Grid>
  </Grid>
   );
}
 
export default BoardCreateView;