import React from 'react';
import { FormControl, Input, InputAdornment, Button } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchFormFind = (props) => {
    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            props.handleClick();
        }
    };
    return(
        <FormControl variant='standard' fullWidth >
            <Input
                type='text'
                value={props.search}
                onChange={props.handleChange}
                placeholder={props.searchGuide}
                onKeyPress={handleKeyPress}
                startAdornment={
                    <InputAdornment position='start'>
                        <Search />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position='end'>
                        <Button variant='outlined' color='primary' size="small" style={{padding: '0px'}} onClick={props.handleClick}>
                        검색
                        </Button>
                    </InputAdornment>
                }
                disabled={props.loading}
            />
        </FormControl>
    );
}

export default SearchFormFind;