import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Main } from 'domain/parent/main';
import { Dashboard, Question, Homework, MockExam } from './';
import NotFound from 'routes/NotFound';

const ChildPresenter = () => {
  const navigate = useNavigate();
  const { ChildNo } = useParams();
  const [cookies] = useCookies(['ChildNo']);

  useEffect(() => {
    if(ChildNo !== cookies.ChildNo) navigate('/parent', { replace: true });
  }, [cookies]);

  return (
    <Routes>
        <Route path='' element={<Main/>} />
        <Route path='dashboard/*' element={<Dashboard />} />
        <Route path='question/*' element={<Question />} />
        <Route path='homework/*' element={<Homework />} />
        <Route path='mockexam/*' element={<MockExam />} />
        <Route path='/*' element={<NotFound/>} />
    </Routes>
  );
};

export default ChildPresenter;