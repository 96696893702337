import React from "react";
import { Routes, Route } from "react-router-dom";
import {TeacherCreate,TeacherList,TeacherDetail,TeacherInfoUpdate} from "domain/Academy/teacher";
import {HomeworkDetail,TeacherHomeworkDetailQuestion,TeacherHomeworkDetailStudent} from 'domain/Academy/teacher/homework'

import NotFound from "routes/NotFound";

const AcademyTeacherPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<TeacherList />} />
      <Route path=":id" element={<TeacherDetail />} />
      <Route path=":id/:ClassNo/homework/:homeworkNo" element={<HomeworkDetail />} />
      <Route path=":id/:ClassNo/homework/:homeworkNo/:questionNo" element={<TeacherHomeworkDetailQuestion />} />
      <Route path=":id/:ClassNo/homework/:homeworkNo/student/:studentNo" element={<TeacherHomeworkDetailStudent />} />
      <Route path=":id/update" element={<TeacherInfoUpdate />} />
      <Route path="/create" element={<TeacherCreate />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AcademyTeacherPresenter;
