import {  Box,Button,CircularProgress } from "@mui/material";
import Modal from '@mui/material/Modal';

import deleteIcon from 'img/academy_delete_icon.png';

const DeleteAcademyModal = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    borderRadius:"10px",
    boxShadow: 24,
    p: 4,
  };
  return ( 
  <Modal 
   open={props.open}
   onClose={props.handleClose}
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <p style={{whiteSpace:'pre',display:"flex",alignItems:"center"}}>
        <img src={deleteIcon} alt="deleteIcon" style={{marginRight:"10px"}}/>
        <span style={{whiteSpace:"pre-wrap"}}>{props.children}</span>
      </p>
   
      <div style={{textAlign:"right"}}>
        <Button sx={{ width:"58px",fontSize:"15px",borderRadius:"10px",marginRight:"20px",background:"#FFFFFF",border:"1px solid #A4A4A4",color:"#A4A4A4" }} onClick={props.handleClose}>취소</Button>
        <Button color='warning' sx={{ width:"100px",fontSize:"15px",borderRadius:"10px" }} variant='contained' onClick={props.handleClickDelete} >{props.updating ? <CircularProgress color='error'/> :props.text}</Button>
      </div>
    </Box>
  </Modal>
   );
}
 
export default DeleteAcademyModal;