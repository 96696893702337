import { Grid,Box } from "@mui/material";

import * as ChangeText from 'components/function/ChangeText';

const BoardDetailView = (props) => {
  return ( 
    
  <Grid container justifyContent="center">
    <Grid container lg={8} xl={7} sx={{flexFlow:"column",background:"#FFFFFF",borderRadius:"25px",padding:"0 20px",minHeight:"1024px"}}>
      <Box style={{borderBottom:"1px solid #A4A4A4",marginTop:"30px",marginBottom:"48px",display:"flex",justifyContent:"space-between"}}>
        <h1 style={{fontSize:"32px"}}>{props.boardDetail?.Title}</h1>
        <h3 style={{padding:"10px 0",fontSize:"20px"}}>{ChangeText.YyyyMmDd(props.boardDetail?.createdAt)}</h3>
      </Box>
      <Box >
        <p style={{whiteSpace:"pre-wrap",fontSize:"20px",fontWeight:"500"}}>{props.boardDetail?.Content}</p>
      </Box>
    </Grid>
  </Grid> 
    
  );
}
 
export default BoardDetailView;