import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, RadioGroup, Radio, FormControlLabel, CircularProgress, OutlinedInput, InputAdornment, IconButton, Chip } from '@mui/material';
import { AddCircle, Clear, Edit } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const QuestionDetailUpdate = (props) => {
    const question = props.question;
    const difficulty = [1,2,3,4,5];

    const navigate = useNavigate();
    const handleKeyDownChoice = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddChoice();
        }
    };
    const handleKeyDownContent = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddContent();
        }
    };
    const handleKeyDownCommentary = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddCommentary();
        }
    };
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };

    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>문제 수정</h3>
                    <TextField
                        label='단원'
                        value={`${ChangeText.Part(question.Section)} > ${ChangeText.MiddlePart(question.Section)} > ${ChangeText.Section(question.Section)}`}
                        fullWidth={true}
                        disabled={true}                        
                    />
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>문제</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='Content'
                            label='문제'
                            value={props.content}
                            onChange={props.handleChangeContent}
                            onKeyDown={handleKeyDownContent}
                            multiline={true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddContent}
                                    >
                                        {props.edit === null ?
                                        <AddCircle />:
                                        <Edit />}
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        문제 미리보기<hr/>
                        {question.Content.map((content, i) => (
                        <Grid container justifyContent='space-between' style={{marginBottom: '10px', borderBottom: '1px solid rgba(164, 164, 164, 0.3)', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteContent(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${content.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        content.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic
                                    />
                                </MathJaxContext>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleClikcEditContent(i)}>
                                    <Edit/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    {/* <FormControl fullWidth className='update_profile'>
                        <InputLabel id='profile-label' shrink>추가 도형 및 그래프 등</InputLabel>
                        <Grid container justifyContent='space-between'>
                            <Grid xs={6} >
                                {props.imgBase64 ? 
                                <img src={props.imgBase64} alt='question pictures'/> :
                                    props.question.Picture ? 
                                <img  alt='question pictures' src={props.question.Picture.includes('https://') ?
                                          props.question.Picture : 
                                          `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}
                                />:
                                null}
                            </Grid>
                            <Grid xs={3} textAlign='right'>
                            <Button color='inherit' onClick={props.handleClickDeletePicture}>
                                삭제
                            </Button>
                            <label htmlFor='Profile' className='upload_profile'>
                                <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangePicture}/>
                                <Button component='span'>
                                    수정
                                </Button>
                            </label>
                            </Grid>
                        </Grid>
                    </FormControl> */}
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>보기</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='choice'
                            label='보기'
                            value={props.newChoice}
                            onChange={props.handleChangeChoice}
                            onKeyDown={handleKeyDownChoice}
                            disabled={question?.NewChoices.length < 5 ? false : true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    {question.NewChoices.length < 5 ?
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddChoice}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                    :
                                    null}
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    {/* {question.Choices.map((choice, i) => (
                        <Chip style={{marginRight: '8px'}} label={choice} variant="outlined" onDelete={() => props.handleDeleteChoice(i)}/>
                    ))} */}
                   { question.NewChoices.length > 0 ?
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        {question.NewChoices.map((choice, i) => (
                        <Grid container style={{marginBottom: '10px', borderBottom: '1px solid rgba(164, 164, 164, 0.3)', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteChoice(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${choice.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        choice.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic
                                    />
                                </MathJaxContext>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    :
                    ""
                   }


                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='answer-label' shrink>정답</InputLabel>
                        <RadioGroup
                            row
                            labelId='answer-label'
                            value={question.Answer}
                            name='Answer'
                            onChange={props.handleChange}
                        >
                            {question.NewChoices.map((choice, i) => (
                                <FormControlLabel value={i+1} control={<Radio />} label={
                                    <MathJaxContext version={3} config={config}>
                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                            choice.replace('`\\(','').replace('\\)`','')
                                            } inline dynamic
                                        />
                                    </MathJaxContext>
                                }/>      
                            ))}
                            {/* {question.Choices.map((choice, i) => (
                                <FormControlLabel value={i+1} control={<Radio />} label={choice} />
                            ))} */}
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>해설</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='Commentary'
                            label='해설'
                            value={props.commentary}
                            onChange={props.handleChangeCommentary}
                            onKeyDown={handleKeyDownCommentary}
                            multiline={true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddCommentary}
                                    >
                                        {props.editCommentary === null ?
                                        <AddCircle />:
                                        <Edit />}
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        해설 미리보기<hr/>
                        {question.Commentary.map((commentary, i) => (
                        <Grid container justifyContent='space-between' style={{marginBottom: '10px', borderBottom: '1px #A4A4A4 solid', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteCommentary(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${commentary.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax
                                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                                        text={
                                            commentary.replace('`\\(','').replace('\\)`','')
                                        }
                                        dynamic
                                        // eslint-disable-next-line
                                        className={commentary.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                                    />
                                </MathJaxContext>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleClikcEditCommentary(i)}>
                                    <Edit/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    <FormControl fullWidth className='update_profile'>
                        <InputLabel id='profile-label' shrink>추가 도형 및 그래프 등</InputLabel>
                        <Grid container justifyContent='space-between'>
                            {/* <Grid xs={6} >
                                {props.commentaryImgBase64 ? 
                                <img src={props.commentaryImgBase64} alt='question pictures'/> :
                                    props.question.CommentaryImage ? 
                                <img  alt='question pictures' src={question.CommentaryImage}
                                />:
                                null}
                            </Grid> */}
                            {/* <Grid xs={3} textAlign='right'>
                            <Button color='inherit' onClick={props.handleClickDeleteCommentaryImage}>
                                삭제
                            </Button>
                            <label htmlFor='CommentaryImage' className='upload_profile'>
                                <input accept='image/png, image/jpeg, image/jpg' name='CommentaryImage' id='CommentaryImage' type='file' onChange={props.handleChangeCommentaryImage}/>
                                <Button component='span'>
                                    수정
                                </Button>
                            </label>
                            </Grid> */}
                        </Grid>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='difficulty-label'>난이도</InputLabel>
                        <Select
                            labelId='difficulty-label'
                            name='Difficulty'
                            label='난이도'
                            value={question.Difficulty}
                            onChange={props.handleChange}
                        >
                            {difficulty.map((dif) => (
                            <MenuItem value={dif}>{ChangeText.Difficulty(dif)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        name='WDiscrimality'
                        label='변별도(W)'
                        value={question.WDiscrimality}
                        onChange={props.handleChange}
                        fullWidth={true}
                        type='number'
                    />
                    <TextField
                        name='WDifficulty'
                        label='난이도(W)'
                        value={question.WDifficulty}
                        onChange={props.handleChange}
                        fullWidth={true}
                        type='number'
                    />
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='warning' onClick={props.handleClickUpdate} disabled={props.updating}>수정</Button>
                    </Grid>
                    {props.updating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='warning'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default QuestionDetailUpdate;