import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { ClassMenu } from 'components/teacher/layout';
import { TeacherUpdate } from 'components/teacher';
import { DeleteModalForUser, PasswordChangeModal } from 'components/modal';

const TeacherEdit = ()=> {
  const [cookies, setCookie] = useCookies(['LoginKey', 'Profile']);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [teacher, setTeacher] = useState({
    Profile: '',
    Phone: '',
    Name: '',
    Gender: '',
    Email: '',
    Login: 1
  });
  const [beforeImage, setBeforeImage] = useState('');
  const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openUserDelete, setOpenUserDelete] = useState(false);
  const [openPasswordChange, setOpenPasswordChange] = useState(false);

  const handleClickDeleteProfile = () => {
      let DeleteProfile = { ...teacher };
      DeleteProfile.Profile = '';
      setTeacher(DeleteProfile);
      setImgBase64('');
      setImgFile('');
  };

  const handleChangeProfile = (event) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) {
        setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
      }
    }
    if(event.target.files[0]){
      reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
      setImgFile(event.target.files[0]); // 파일 상태 업데이트
    }
  };
  const handleChange = (event) => {
    let changeTeacher = { ...teacher };
    changeTeacher[event.target.name] = event.target.value;
    setTeacher(changeTeacher);
  };

  const getTeacherInfo = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/teacher/${cookies.LoginKey}`, config).then((Response) => {
      if(!Response.data.ErrorMessage){
        setTeacher(Response.data.Teacher);
        setBeforeImage(Response.data.Teacher.Profile);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(() => {
    if(cookies.LoginKey){
      getTeacherInfo();
    }
  }, []);
  const handleClickUpdate = async() => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      phone: teacher.Phone,
      profile: teacher.Profile,
      loginKey: cookies.LoginKey,
      name:teacher.Name
    });
    await axios.put(`/api/teacher`, body, config).then((Response) => {
      let date = new Date();
      date.setDate(date.getDate()+365);
      if(!Response.data.ErrorMessage){
        enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
        uploadProfile();
        setCookie('Name', teacher.Name, { path: '/', expires: date });
        setTimeout(()=> {
          setUpdating(false);
        }, [1000]);
      }else{
        enqueueSnackbar(`${!Response.data.ErrorMessage}`, { variant: 'error' });
      }
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const uploadProfile = async() => {
    if(imgFile){
      const formData = new FormData();
      formData.append('loginKey', cookies.LoginKey);
      formData.append('imgFile', imgFile);
      formData.append('beforeImage', beforeImage);
      const config = { headers: { 'content-type': 'multipart/form-data' }};
      await axios.put(`/api/student/profile`, formData, config).then((Response) => {
        let date = new Date();
        date.setDate(date.getDate()+30);
        setCookie('Profile', Response.data.Profile, { path: '/', expires: date });
        setImgFile(null);
        setImgBase64('');
        setBeforeImage(Response.data.Profile);
      }).catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      return false
    }
  };
  const handleClickDelete = async() => {
    setDeleting(true);
    await axios({
      method: 'DELETE',
      url: `/api/register`,
      data: {
        loginKey: cookies.LoginKey
      }
    })
    .then((Response) => {
      if(!Response.data.ErrorMessage){
        setTimeout(() => {
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          navigate('/restore', { replace: true });
        }, [2500]);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  return (
    <ClassMenu>
      <TeacherUpdate 
        teacher={teacher}
        imgBase64={imgBase64}
        updating={updating}
        handleChange={handleChange}
        handleClickDeleteProfile={handleClickDeleteProfile}
        handleChangeProfile={handleChangeProfile}
        handleClickUpdate={handleClickUpdate}
        setOpenUserDelete={setOpenUserDelete}
        setOpenPasswordChange={setOpenPasswordChange}
      /> 
      <PasswordChangeModal 
        title={'비밀번호 변경'}
        open={openPasswordChange}
        setOpen={setOpenPasswordChange}
        user_id={teacher._id}
      />
      <DeleteModalForUser
        open={openUserDelete}
        setOpen={setOpenUserDelete}
        title={'회원 탈퇴'}
        deleting={deleting}
        setDeleting={setDeleting}
        submitBtn={'탈퇴'}
        handleClickDelete={handleClickDelete}
      >
        '탈퇴' 버튼 클릭 시 서비스 사용에 제한이 있을 수 있습니다.<br/>
        회원 탈퇴 시 20일 이내 복구가 가능하며, 20일 이후 삭제됩니다. 삭제 된 데이터는 복구 할 수 없습니다. 정말 탈퇴 하시겠습니까?
      </DeleteModalForUser>
    </ClassMenu>
  )
}

export default TeacherEdit;