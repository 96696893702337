import React from 'react';
import { FormControl, Input, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchForm = (props) => {
    return(
        <FormControl variant='standard' fullWidth >
            <Input
                type='text'
                value={props.search}
                onChange={props.handleChange}
                placeholder={props.searchGuide}
                startAdornment={
                    <InputAdornment position='start'>
                        <Search />
                    </InputAdornment>
                }
                disabled={props.loading}
            />
        </FormControl>
    );
}

export default SearchForm;