import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import IconCategory1 from 'img/icon_category1.png';
import IconCategory2 from 'img/icon_category2.png';
import { useSnackbar } from 'notistack';

const Category = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const nowHomework = props.homeworkDatas.filter((item) => { return  new Date() < new Date(item.DueDate).setHours(23,59,59,999) && item.Students[0].Questions.length < item.QuestionsCnt });
    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    return(
        <>
            <div className='main_div_title'>
                <p className='main_sub_title'>실력 UP!</p>
            </div>
            <Grid container>
                <Grid item className='main_div_category main_background_blue' onClick={() => navigate(`/question/homework`)}>
                {/* <Grid item className='main_div_category main_background_blue' onClick={NotReady}> */}
                    <span>아직 하지 않은 숙제가 <b>{nowHomework.length}개</b> 있어요!</span>
                    {/* <span>준비중 입니다.</span> */}
                    <p><span className='category_blue'>숙제하기</span></p>
                    <img src={IconCategory1} alt='icon category1' />
                </Grid>
                <Grid item className='main_div_category main_background_yellow' onClick={() => navigate(`/question/review`)}>
                    <span>틀렸던 문제를 다시 풀어보세요!</span>
                    <p><span className='category_yellow'>복습하기</span></p>
                    <img src={IconCategory2} alt='icon category2' />
                </Grid>
            </Grid>
        </>
    );
};

export default Category;