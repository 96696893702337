import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Collapse } from '@mui/material';
import { Dashboard, Person, Widgets, School, ExpandLess, ExpandMore, Payment, Apartment, Logout, Redeem, Quiz, LocalActivity } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

import loog from 'img/woorotest_logo.png';

const Layout = (props) => {
    const [openMenu, setOpenMenu] = useState({ user: false, question: false, school: false, academy: false, mockexam: false, achieve:false });
    const [page, setPage] = useState('');
    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    // 상세 메뉴 여닫기
    const handleClickOpenMenu = (kind) => {
        let changeOpenMenu = { ...openMenu };
        changeOpenMenu[kind] = !changeOpenMenu[kind];
        setOpenMenu(changeOpenMenu);
    };

    useEffect(() => {
        const pages = pathname.split('/');
        if(pages[2] === 'student' || pages[2] === 'manager'){
            handleClickOpenMenu('user');
        }else{
            handleClickOpenMenu(pages[2]);
        }
        setPage(pages[2]);
    }, [pathname]);

    // 메뉴 이동
    const handleClickMenu = (link) => {
        if(link !== pathname){
            navigate(link);
        }
    };
    return(
        <div className='admin_layout_menu'>
            <List>
                <ListItem>
                    <ListItemButton>
                        <img src={loog} alt='woorotest_logo' />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem className={page === 'dashboard' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickMenu('/admin/dashboard')}>
                        <ListItemIcon>
                            <Dashboard />
                        </ListItemIcon>
                        <ListItemText>
                            Dashboard
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={page === 'student' || page === 'manager' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickOpenMenu('user')}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText>
                            회원 관리
                        </ListItemText>
                        {openMenu.user ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.user} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/student')}>
                            <ListItemText primary='학생 목록' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/manager')}>
                            <ListItemText primary='관리자 목록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className={page === 'question' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickOpenMenu('question')}>
                        <ListItemIcon>
                            <Widgets />
                        </ListItemIcon>
                        <ListItemText>
                            문제 관리
                        </ListItemText>
                        {openMenu.question ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.question} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/question')}>
                            <ListItemText primary='문제 목록' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/question/create')}>
                            <ListItemText primary='문제 등록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className={page === 'school' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickOpenMenu('school')}>
                        <ListItemIcon>
                            <School />
                        </ListItemIcon>
                        <ListItemText>
                            학교 관리
                        </ListItemText>
                        {openMenu.school ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.school} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/school')}>
                            <ListItemText primary='학교 목록' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/school/create')}>
                            <ListItemText primary='학교 등록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className={page === 'mockexam' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickOpenMenu('mockexam')}>
                        <ListItemIcon>
                            <Quiz />
                        </ListItemIcon>
                        <ListItemText>
                            모의고사 관리
                        </ListItemText>
                        {openMenu.mockexam ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.mockexam} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/mockexam')}>
                            <ListItemText primary='모의고사 목록' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/mockexam/create')}>
                            <ListItemText primary='모의고사 등록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                {/* <ListItem className={page === 'event' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickMenu('/admin/event')}>
                        <ListItemIcon>
                            <Redeem />
                        </ListItemIcon>
                        <ListItemText>
                            이벤트 관리
                        </ListItemText>
                    </ListItemButton>
                </ListItem> */}
                <ListItem className={page === 'payment' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton>
                        <ListItemIcon>
                            <Payment />
                        </ListItemIcon>
                        <ListItemText>
                            결제 관리
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={page === 'coupon' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickMenu('/admin/coupon')}>
                        <ListItemIcon>
                            <LocalActivity />
                        </ListItemIcon>
                        <ListItemText>
                            쿠폰 관리
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={page === 'academy' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickOpenMenu('academy')}>
                        <ListItemIcon>
                            <Apartment />
                        </ListItemIcon>
                        <ListItemText>
                            학원 관리
                        </ListItemText>
                        {openMenu.academy ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.academy} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 6 }} >
                            <ListItemText primary='학원 목록' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 6 }} >
                            <ListItemText primary='학원 등록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className={page === 'achieve' ? 'admin_layout_menu_check' : 'admin_layout_menu_ischeck'}>
                    <ListItemButton onClick={() => handleClickOpenMenu('achieve')}>
                        <ListItemIcon>
                            <Apartment />
                        </ListItemIcon>
                        <ListItemText>
                            업적 관리
                        </ListItemText>
                        {openMenu.achieve ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.achieve} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/achieve')}>
                            <ListItemText primary='업적 목록' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 6 }} onClick={() => handleClickMenu('/admin/achieve/create')}>
                            <ListItemText primary='업적 등록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className='admin_layout_menu_ischeck'>
                    <ListItemButton>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText>
                            로그아웃
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );
}

export default Layout;