import { Button, Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Pagination } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const BoardListView = (props) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  
  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = props.rows?.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (   
  <Grid container justifyContent={"center"}>
   <Grid container lg={12} xl={8}  justifyContent={"center"}>
    <Grid item lg={11.8} xl={12} sx={{textAlign:"right"}}>
      <Button variant="contained" disableElevation size="small" sx={{width:"157px",height:"45px",background:"#F05D38",fontSize:"20px",'&:hover': { background:"#EF4C23" },}} onClick={()=>{navigate("create")}}>글쓰기</Button>
    </Grid>
    <Grid item={true}  lg={11.5} xl={11.8} mt={4}  sx={{background:"#FFFFFF",borderRadius:'15px',padding:"30px" }}>
      {visibleData.length > 0 ? 
        <TableContainer>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <TableHead className="academy_table_head">
              <TableRow>
                <TableCell align="center" width={'300px'}>작성일</TableCell>
                <TableCell align="left">제목</TableCell>
                <TableCell align="center">수정/삭제</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
            {visibleData.map((row,i) => (
              <TableRow
                key={i}>
                  <TableCell align="center" component="th" scope="row">{row.date}</TableCell>
                  <TableCell align="left" onClick={()=>props.handleClickCell(row.noticesNo)} sx={{cursor:"pointer",color:"#1976d2"}}>{row.title}</TableCell>
                  <TableCell align="right" width={'50px'}>
                    <Button
                    disableElevation
                    onClick={() => props.handleUpdateData(row.noticesNo)}
                    style={{ marginLeft: 16,width:"90px",height:"34px",color:"#F05D38",border:"1px solid #F05D38",borderRadius: "6px",background:"#FFFFFF",fontSize: "15px",'&:hover': { background:"#FFFFFF" },}}>
                    수정
                    </Button>
                    <Button variant="contained" disableElevation size="small" style={{marginLeft: 16,width:"90px",height:"34px",color:"#FFFFFF",background:"#F05D38",borderRadius: "6px",fontSize: "15px" }} onClick={() => props.handleClickOpenDeleteBoard(row.noticesNo)}>
                    삭제
                    </Button>
                  </TableCell>              
              </TableRow>
              ))}
          </TableBody>
          </Table>
        </TableContainer>
        :
        <h1 style={{textAlign:"center",marginTop:"50px"}}>작성한 공지사항이 없습니다.</h1>
      }
        <Pagination
        count={Math.ceil(props.rows?.length / pageSize)} // 전체 페이지 수
        page={currentPage} // 현재 페이지
        onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
        color='warning'
        sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
      />
    </Grid>
   </Grid>
  </Grid>
  );
}
 
export default BoardListView;