import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ClassMenu } from 'components/teacher/layout';
import { TeacherClassUpdate } from 'components/teacher/class';

const ClassEdit = () => {
  const [cookies] = useCookies(["LoginKey"]);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { ClassNo } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [classData, setClassData] = useState({
    ClassName: '',
    ClassImage: ''
  });
  const [beforeImage, setBeforeImage] = useState('');
  const [updating, setUpdating] = useState(false);
  const [imgBase64, setImgBase64] = useState('');
  const [imgFile, setImgFile] = useState('');

  const handleChangeClass = (event) => {
    let changeClass = { ... classData };
    changeClass[event.target.name] = event.target.value;
    setClassData(changeClass);
  };
  
  const handleClickDeleteProfile = () => {
    setImgBase64('');
    setImgFile('');
    setClassData({ ...classData, ClassImage: '' });
  };

  const handleChangeProfile = (event) => {
    let reader = new FileReader();

    reader.onloadend = () => {
        // 2. 읽기가 완료되면 아래코드가 실행됩니다.
        const base64 = reader.result;
        if (base64) {
            setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
        }
    }
    if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
        setImgFile(event.target.files[0]); // 파일 상태 업데이트
    }
  };
  const uploadProfile = async(CNO) => {
    if(imgFile){
        const formData = new FormData();
        formData.append('classNo', CNO);
        formData.append('imgFile', imgFile);
        formData.append('loginKey', LoginKey);
        formData.append('beforeImage', beforeImage);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        await axios.put(`/api/class/profile`, formData, config).then((Response) => {
          setImgBase64();
          setImgFile(null);
          setBeforeImage(Response.data.ClassImage);
        }).catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    }else{
        return false
    }
  };

  const handleClickUpdate = async() => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      classNo: classData.ClassNo,
      className: classData.ClassName ? classData.ClassName : '',
      classImage: classData.ClassImage,
      loginKey: LoginKey,
      beforeImage: beforeImage
    });
    await axios.put('/api/class', body, config).then((Response) => {
      setTimeout(() => {
        navigate(`/teacher`);
      }, 500);
      if(!Response.data.ErrorMessage){
        uploadProfile(Response.data.ClassNo);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });  
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  
  const handleClickDelete = async() => {
    setUpdating(true);
    await axios({
      method: 'DELETE',
      url: `/api/class`,
      data: {
        classNo: classData.ClassNo,
        loginKey: LoginKey
      }
    })
    .then((Response) => {
      if(!Response.data.ErrorMessage){
        setTimeout(() => {
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          navigate(`/teacher`);
        }, 500);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(() => {
    const loadClassData = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/teacher/class/${ClassNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          setClassData(Response.data.Class);
          setBeforeImage(Response.data.Class.ClassImage);
        }else{
          navigate(`/teacher`, { replace: true });
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      }).catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }
    loadClassData();
  }, []);

  return (
    <ClassMenu>
      <TeacherClassUpdate
        classData={classData}
        imgBase64={imgBase64}
        updating={updating}
        handleChangeClass={handleChangeClass}
        handleChangeProfile={handleChangeProfile}
        handleClickDeleteProfile={handleClickDeleteProfile}
        handleClickUpdate={handleClickUpdate}
        handleClickDelete={handleClickDelete}
      />
    </ClassMenu>
  )
};

export default ClassEdit;