import React from 'react';
import { Box, List, ListItemButton, ListItemText, CircularProgress } from '@mui/material';
import { OtherSchools } from 'components/function/AddValue';

const StudentSchoolList = (props) => {
    const otherSchools = OtherSchools();
    return(
        <Box
            sx={{ width: '100%', height: 400,  bgcolor: 'background.paper' }}
        >
            <List>
                {props.loading ?
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <CircularProgress color='primary'/>
                </div>
                :
                props.schools.map((school) =>(
                school.Name ?
                <ListItemButton onClick={() => props.handleClickSchool(school)} value={school}>
                    <ListItemText primary={school.Name} secondary={`${school.Local} ${school.County}`}/>
                </ListItemButton>:
                null
                ))}
                {props.firstSearch ? 
                <>
                    <div style={{textAlign: 'center', marginTop: '10px', borderTop: props.schools[0]?.Name ? '1px solid #A4A4A4' : null, paddingTop: '10px'}}>
                        찾는 학교가 없으신가요?
                    </div>
                    {otherSchools.map((school) => (
                        <ListItemButton onClick={() => props.handleClickSchool(school)} value={school}>
                            <ListItemText primary={school.Name} secondary={`${school.Local} ${school.County}`}/>
                        </ListItemButton>
                    ))}
                </>
                :
                null}
            </List>
        </Box>
    );
};

export default StudentSchoolList;