import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import * as ChangeText from 'components/function/ChangeText';

const QuestionTimeBarChart = (props) => {
  return (
    <ResponsiveBar
        data={props.data}
        label={d => ChangeText.QuestionTimeAll(d.data.y)}
        keys={[props.y]}
        tooltip={(data)=>(
          <div style={{background:'#FFFFFF',padding:"7px",display:"flex",alignItems:'center',fontWeight:"700",boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
            <div style={{background:data.color,height:"12px",width:"12px",marginRight:"5px"}}>
            </div>
            풀이시간:{ChangeText.QuestionTimeAll(data.data.y)}
          </div>
          )}

        padding={0.2}
        // keys={[
        //     'hot dog',
        //     'burger',
        //     'sandwich',
        //     'kebab',
        //     'fries',
        //     'donut'
        // ]}
        indexBy={props.x}
        margin={{ top: 5, right: 10, bottom: 30, left: 60 }}
        // markers={[
        //     {
        //         axis: 'y',
        //         value: 300,
        //         lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
        //         legend: 'y marker at 300',
        //         legendOrientation: 'vertical',
        //     },
        // ]}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'pastel1' }}
        colorBy={'indexValue'}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            // legend: 'country',
            // legendPosition: 'middle',
            // legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: props.ylabel,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
       
     
        role='application'
    />
  );
};

export default QuestionTimeBarChart;
