import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import { Instagram, Facebook, YouTube } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import NaverLogoOver from 'img/naver_logo_over.png';
import KakaoLogoOver from 'img/kakao_logo_over.png';
import NaverLogoLeave from 'img/naver_logo_leave.png';
import KakaoLogoLeave from 'img/kakao_logo_leave.png';

const Footer = () => {
    const { enqueueSnackbar } = useSnackbar();
    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    const [naverLogo, setNaverLogo] = useState(NaverLogoLeave);
    const [kakaoLogo, setKakaoLogo] = useState(KakaoLogoLeave);

    const handleMouseOver = (event) => {
        if(event.target.accessKey === 'naver'){
            setNaverLogo(NaverLogoOver);
        }else{
            setKakaoLogo(KakaoLogoOver);
        }
    }
    const handleMouseLeave = (event) => {
        if(event.target.accessKey === 'naver'){
            setNaverLogo(NaverLogoLeave);
        }else{
            setKakaoLogo(KakaoLogoLeave);
        }
    };
    return (
        <Grid container justifyContent='center' className='footer'>
            <Grid item xs={12} md={10}>
                <b>(주)우로테스트</b><br/><br/>
                경상북도 포항시 남구 청암로87, 체인지업그라운드 537<br/>
                사업자등록번호: 493-86-02261<br/>
                통신판매업신고번호: 제 2023-경북포항-0036<br/>
                문의사항 : <Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link> | 대표 번호 : <Link href='tel:010-5932-6024'>054-277-0724</Link><br/>
                대표자 : 김남걸 | 이메일 : <Link href='mailto:hijeff@woorotest.com' target='_blank'>hijeff@woorotest.com</Link> | 전화 번호 : <Link href='tel:010-5932-6024'>010-5932-6024</Link><br/><br/>
                <Link href='https://woorotest.com' target='_blank'>회사소개</Link>
                &nbsp;|&nbsp;<Link href='/term' target='_blank'>이용약관</Link>
                &nbsp;|&nbsp;<Link href='/payterm' target='_blank'>유료 이용약관</Link>
                &nbsp;|&nbsp;<Link href='/privacy' target='_blank'>개인정보처리방침</Link>
                &nbsp;|&nbsp;<Link href='/notice' target='_blank'>공지사항</Link>
                {/* &nbsp;|&nbsp;<Link href='/update' target='_blank'>업데이트</Link> */}
                <br/><br/>
                <Link href='https://www.instagram.com/woorotest/' target='_blank'><Instagram/></Link>
                &nbsp;&nbsp;&nbsp;<Link href='https://www.facebook.com/%EC%9A%B0%EB%A1%9C%EB%A7%A4%EC%93%B0-100324396147311/' target='_blank'><Facebook/></Link>
                &nbsp;&nbsp;&nbsp;<Link href='https://www.youtube.com/channel/UC3jSusK1NevpTVNVhhjz7yg' target='_blank'><YouTube/></Link>
                &nbsp;&nbsp;&nbsp;<Link href='https://blog.naver.com/woorotest' target='_blank'><img src={naverLogo} alt='naver logo' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} accessKey='naver'/></Link>
                &nbsp;&nbsp;&nbsp;<Link href='javascript:;' onClick={NotReady}><img src={kakaoLogo} alt='kakao logo' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} accessKey='kakao'/></Link>
                <br/><br/>
                COPYRIGHT © WOOROTEST co., Ltd. ALL RIGHTS RESERVED
            </Grid>
        </Grid>
    )
}

export default Footer;