import React from 'react';
import ReactDOM from 'react-dom';
import 'fonts/font.css';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ChannelService from 'components/function/ChannelService';
import pluginKey from 'config/channelTalk';
import ReactGA from 'react-ga4';
import gaTrackingId from 'config/googleAnalytics';

const theme = createTheme({  
  palette: {
      secondary: { main: '#A4A4A4'},
      warning: { main: '#EF4C23'}
  },
});

ChannelService.boot({
  'pluginKey': pluginKey,
  'memberId': '',
  'profile': {
    'name': '',
    'email': '', 
    'id': ''
  }
});

ReactGA.initialize(gaTrackingId); // react-ga 초기화 및 debug 사용

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CookiesProvider>
        <SnackbarProvider 
          maxSnack={1} 
          anchorOrigin={
            {
              vertical: 'bottom',
              horizontal: 'left',
            }
          }
          >
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </SnackbarProvider>
      </CookiesProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
