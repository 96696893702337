import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ManagerList, ManagerInfo, ManagerUpdate } from 'domain/admin/manager';

import NotFound from 'routes/NotFound';

const AdminManagerPresenter = () => {
    return(
        <Routes>
            <Route path='' element={<ManagerList />} />
            <Route path=':id' element={<ManagerInfo />} />
            <Route path=':id/update' element={<ManagerUpdate />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminManagerPresenter;