import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { MockExamDetailView } from 'components/teacher/class/mockExam';
import { Layout } from 'components/teacher/layout';

const MockExamDetail = () => {
    const { ClassNo, MockExamNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [mockExam, setMockExam] = useState({
        Name: '',
        PartInfo: [{
            Freq: 0,
            Mean: 0,
            Part: 0,
            Sd: 0,
            Students: [{
                Part: 0,
                Point: 0,
                Rank: 0
            }],
            Sum: 0,
            Variance: 0
        }]
    });
    const [mockExamResults, setMockExamResults] = useState([{
        Name: '',
        Profile: '',
        SchoolName: '',
        Grade: 0,
        Attending: 0,
        Part: 0,
        State: 0,
        Point: 0,
        Rank: 0,
        Time: 0
    }]);

    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/teacher/mockexam/${ClassNo}/${MockExamNo}/${cookies.LoginKey}`, config).then((res) => {
            if(!res.data.ErrorMessage){
                setMockExam(res.data.mockExam);
                setMockExamResults(res.data.mockExamResults);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                navigate(-1);
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };

    useEffect(() => {
        loadDatas();
    }, []);

    return (
        <Layout>
            <MockExamDetailView
                mockExam={mockExam}
                mockExamResults={mockExamResults}
            />
        </Layout>
    );
};

export default MockExamDetail;