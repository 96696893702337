import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Link, CircularProgress } from '@mui/material';

import WooroMathLogo from 'img/wooromath_logo.png';
import KakaoLogo from 'img/kakao_logo.png';
import NaverLogo from 'img/naver_logo.png';

const Login = (props) => {
  const navigate = useNavigate();

  const handleKeyDownPassword = (event) => {
    if(event.key === 'Enter'){
      props.handleLogin();
    }
  }

  return (
    <Grid container justifyContent='center'>
      {!props.checking ?
      <Grid xs={12} sm={9} md={6} lg={4.8} xl={3.5} className='login_div' >
        <img src={WooroMathLogo} alt='wooromath logo'/>
        <p>로그인</p>
        <input type='email' name='Email' placeholder='이메일을 입력해 주세요' value={props.userInfo.Email} onChange={props.handleChange}/>
        <input type='password' name='Password' placeholder='비밀번호를 입력해 주세요' value={props.userInfo.Password} onChange={props.handleChange} onKeyDown={handleKeyDownPassword}/>
        <button onClick={props.handleLogin}>로그인</button>
        <div className='login_password_forget'>
          <div>비밀번호를 잊으셨나요? <Link href='javascript:;' onClick={() => props.setOpenPasswordFind(true)}>비밀번호 찾기</Link></div>
        </div>
        {/* <div className='sns_login'>
          <p>SNS 계정으로 간편하게 로그인하세요</p>
          <div className='kakao_login' onClick={props.kakaoLogin}>
            <img src={KakaoLogo} alt='kakao logo' />
            <span>카카오톡으로 로그인</span>
          </div>
          <div className='naver_login' onClick={props.naverLogin}>
          <img src={NaverLogo} alt='naver logo' />
            <span>네이버 아이디로 로그인</span>
          </div>
          <p>아직 회원이 아니신가요? <Link onClick={() => navigate(`/register`)}>회원가입</Link></p>
        </div> */}
        <div className='sns_login'>
          <p>아직 회원이 아니신가요? <Link onClick={() => navigate(`/register`)}>회원가입</Link></p>
        </div>
      </Grid>:
      <Grid xs={12} sm={9} md={6} lg={4.8} xl={3.5} className='login_div'>
        <img src={WooroMathLogo} alt='wooromath logo'/>
        <p>로그인</p>
        <CircularProgress color='warning'/><br/><br/>
        <span>잠시만 기다려주세요</span>
      </Grid>}
    </Grid>
  );
}

export default Login;