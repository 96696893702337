import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/user/layout';
import { EvaluationListView } from 'components/user/evaluation';

const EvaluationList = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [evaluations, setEvaluations] = useState([{ Students: [] }]);
    const [selectEvaluation, setSelectEvaluation] = useState();
    const [userId, setUserId] = useState('');

    const handleClickStart = async() => {
        if(selectEvaluation.Students.filter((item) => (item.Student_id === userId)).length === 0){
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify(selectEvaluation);
            await axios.post(`/api/evaluation/${cookies.LoginKey}`, body, config)
            .then((res) => {
                if(!res.data.ErrorMessage){
                    enqueueSnackbar(res.data.Message, { variant: 'info' });
                    navigate(`/evaluation/${selectEvaluation.EvaluationNo}`);
                }else{
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar('Network Error!', { variant: 'error' });
            });
        }else{
            enqueueSnackbar('이어서 수행평가를 진행합니다.', { variant: 'info' });
            navigate(`/evaluation/${selectEvaluation.EvaluationNo}`);
        }
    };

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/evaluation/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setUserId(res.data.User_id);
                setEvaluations(res.data.EvaluationDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    });

    useEffect(() => {
        if(cookies.LoginKey){
            loadDatas();
        }
    }, []);

    return(
        <Layout>
            <EvaluationListView 
                evaluations={evaluations}
                userId={userId}
                handleClickStart={handleClickStart}
                selectEvaluation={selectEvaluation}
                setSelectEvaluation={setSelectEvaluation}
            />
        </Layout>
    );
};

export default EvaluationList;