import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import IconCategory1 from 'img/main_banner.png';
import { useSnackbar } from 'notistack';

const Category = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };

    return(
        <>
            <div className='main_div_title'>
                <p className='main_sub_title'>신청 관리</p>
            </div>
            <Grid container>
                <Grid item className='main_div_students main_background_green' onClick={() => navigate(`${location.pathname}/propose`)}>
                    <span>{props.applyStudentCnt}명의 클래스 신청이 있어요!</span>
                    <p><span className='category_green'>학생 신청 관리</span></p>
                    <img src={IconCategory1} alt='icon category1' />
                </Grid>
            </Grid>
        </>
    );
};

export default Category;