import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {  useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { TeacherHomeworkCustomView } from 'components/teacher/class/homework';
import * as AddValue from 'components/function/AddValue';
import * as ChangeText from 'components/function/ChangeText';
import kakaoJavascriptKey from 'config/kakaoJSKey';

const HomeworkAssign = () => {
  const [cookies] = useCookies(['LoginKey', 'Name', 'ClassName']);
  const LoginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();
  const { ClassNo } = useParams();
  
  const [ questionSection,setQuestionSection] = useState([]);
  const [homeworkData, setHomeworkData] = useState(
    {
      Section:1000,
      QuestionsCnt:"",
      Notice:"",
      DueDate:"",
      Difficulty:3,
      Title:"",
      activityName:"",
      Standard:[],
      questions:[]
    });
  const [parts, ] = useState(AddValue.AllPartsDashboard());
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState(0);
  const [questionCntError, setQuestionCntError] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);
  const [search, setSearch] = useState('');
  const [questionAll, setQuestionAll] = useState([]);
  const [selectQuestions, setSelectQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  }

  const handleClickSection = (section) => {
    setQuestionSection((SectionNo)=>{
      if (SectionNo.some((data) => data === section)) {
        const filterSection = SectionNo.filter((data) => data !== section);
         return filterSection
      } else {
        const newData = [...questionSection ,section ]
        return newData
      }
    });
   };
   const handleChangeHomework = (event) => {
     let changeHomework = { ...homeworkData };
     if(event.target.name === 'DueDate'){
       setDueDateError(false);
       let today = new Date();
       today.setHours(9,0,0,0);
       if(new Date(event.target.value) < today){
         setDueDateError(true);
         const todayYear = today.getFullYear();
         const todayMonth = today.getMonth() + 1 < 10 ? '0'+(today.getMonth()+1) : today.getMonth() + 1;
         const todayDay = today.getDate();
         changeHomework.DueDate = `${todayYear}-${todayMonth}-${todayDay}`;
        }else{
          changeHomework.DueDate = event.target.value;
        }
      }else{
        changeHomework[event.target.name] = event.target.value;
      }
      setHomeworkData(changeHomework);
    };
  
    useEffect(() => {
      const loadQuestions = async() => {
          const config = { headers: { 'Content-type': 'application/json' }};
          await axios.get(`/api/question`, config).then((Response) => {
              const loadData = Response.data;
              setQuestionAll(loadData);
          }).catch((Error) => {
              console.log(Error);
          });
      };
      loadQuestions();
  },[]);

  useEffect(()=>{
    if (questionAll.length > 0) {
      const filterData = questionAll.filter(item => questionSection.includes(item.Section));
      let questionData = [];
      for(let i = 0; i < filterData.length; i++){
          questionData.push({
                id: filterData[i].Question,
                Answer:filterData[i].Answer,
                SectionNo:filterData[i].Section,
                Part: ChangeText.Part(filterData[i].Section),
                MiddlePart: ChangeText.MiddlePart(filterData[i].Section),
                Section: ChangeText.Section(filterData[i].Section),
                Difficulty: ChangeText.Difficulty(filterData[i].Difficulty),
                State: ChangeText.QuestionState(filterData[i].State),
                Inspection: filterData[i].Inspection ? 'T' : 'F',
                CreatedAt: filterData[i].CreatedAt,
                Commentary:filterData[i].Commentary,
                Content:filterData[i].Content,
                // Choices:filterData[i].Choices,
                Choices:filterData[i].NewChoices,
                Link: filterData[i]._id,
                Standard:"",
                StandardChecked:1
          });
      }
      const questionDatas = questionData.reduce((acc, item) => {
        const sectionIndex = acc.findIndex((section) => section.title === item.Section);
        if (sectionIndex !== -1) {
          acc[sectionIndex].list.push(item);
        } else {
          acc.push({ title: item.Section, list: [item], Section:item.SectionNo });
        }
        return acc;
      },[]);

      setQuestions(questionDatas);
    };
  },[questionAll,questionSection])


const filterQuestions = (datas) => {
  // datas.sort();
  datas = datas.filter((data) => {
      return data.Part.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            data.Section.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            data.id.toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
  });
  return datas;
};
const handleClickHomeworkAssign = () => {
  setSteps(2);
  if (selectQuestions.length > 0) {
    const QuestionIds = [];
    const Standards = [];
    let QuestionDifficulty = 0
      for (let i = 0; i < selectQuestions.length; i++) {
        let difficulty = selectQuestions[i].Difficulty.split('★').length - 1;
        if (difficulty > QuestionDifficulty) {
          QuestionDifficulty = difficulty;
        }
        QuestionIds.push(selectQuestions[i].Link )     
        Standards.push({Content:selectQuestions[i].Standard ,Link:selectQuestions[i].id  })     
      };
      setHomeworkData((data) => {
        return {...data,Standard:Standards, questions: QuestionIds, QuestionsCnt:selectQuestions.length, Difficulty:QuestionDifficulty};
      }); 
    }
  };
  const handleClickHomework = async ()=>{
    setSteps(3);
     const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      section: homeworkData.Section,
      questionsCnt: homeworkData.QuestionsCnt,
      difficulty: homeworkData.Difficulty,
      dueDate: homeworkData.DueDate,
      notice: homeworkData.Notice,
      title:homeworkData.Title,
      activityName:homeworkData.activityName,
      standard:homeworkData.Standard,
      questionIds:homeworkData.questions,
      classNo: ClassNo,
      loginKey: LoginKey
    });
    await axios.post(`/api/homework/custom`, body, config).then((Response) => {
      if(!Response.data.ErrorMessage){
          setTimeout(() => {
            setSteps(4);
          }, [1000])
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const shareToKakao = () => {
    if(window.Kakao){
      const kakao = window.Kakao;
      if(!kakao.isInitialized()){
          kakao.init(kakaoJavascriptKey);
      }
      kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
              title: `우로매쓰:: ${cookies.Name}(${cookies.ClassName})선생님의 숙제`,
              description: `${homeworkData.Title}\n${homeworkData.Notice}`,
              // imageUrl: `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/kakao_${homeworkData.Section}.png`,
              link: {
                  mobileWebUrl: `https://wooromath.com/question/homework`,
                  webUrl: `https://wooromath.com/question/homework`
              },
          }
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, [500]);
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);


  return (
    <Layout>
      <TeacherHomeworkCustomView
        parts={parts}
        handleChangeHomework={handleChangeHomework}
        homeworkData={homeworkData}
        questionSection={questionSection}
        filterQuestions={filterQuestions}
        steps={steps}
        setSteps={setSteps}
        questions={questions}
        questionCntError={questionCntError}
        setQuestionCntError={setQuestionCntError}
        dueDateError={dueDateError}
        setDueDateError={setDueDateError}
        handleClickSection={handleClickSection}
        handleChangeSearch={handleChangeSearch}
        search={search}
        selectQuestions={selectQuestions}
        setSelectQuestions={setSelectQuestions}
        handleClickHomeworkAssign={handleClickHomeworkAssign}
        shareToKakao={shareToKakao}
        handleClickHomework={handleClickHomework}
      />
    </Layout>
  )
};

export default HomeworkAssign;