import axios from "axios";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";

import { TeacherHomeworkDetailQuestionView } from 'components/Academy/teacher/homework'
import { Layout } from "components/Academy/Layout";

const TeacherHomeworkDetailQuestion = () => {
  const [cookies] = useCookies(['LoginKey']);
  const {id,homeworkNo,ClassNo,questionNo} = useParams();
  const loginKey = cookies.LoginKey;
  const teacherNo = id;

  const [filterQuestion, setFilterQuestion] = useState({
    Answer: '',
    Choices: ['','','','',''],
    Content: [' '],
    Section: '',
    Question: '',
    Info: { Counting: '', Correct: '', Time: '' },
    Picture: '',
    Commentary: ''
 });
 const [students, setStudents] = useState([{
    StudentNo: '',
    Name: '',
    Check: '',
    Time: '',
    Memo: ''
 }]);
  const [question,setQuestion] = useState();

  const handleHomeworkData= async()=>{//클래스 평균 등급 그래프 
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${teacherNo}/${ClassNo}/${homeworkNo}/${loginKey}`, config).then((Response)=>{
      setQuestion(Response.data.Homework);
    }).catch((err)=>{})
  };

  useEffect(()=>{
    handleHomeworkData()
  },[]);
  
  useEffect(() => {
    // setLoading(true);
    const tempFilterQuestion = question?.Question_ids.filter((item) => { return item.Question === Number(questionNo) })[0];
    let tempStudent = [];
    if(tempFilterQuestion){
       for(let i = 0; i < question.Students.length; i++){
          let tempNo = question.Students[i].StudentNo;
          let tempName = question.Students[i].Student_id.Name;
          let tempCheck = 0;
          let tempTime = 0;
          let tempMemo = '';
          for(let j = 0; j < question.Students[i].Questions.length; j++){
             const tempQuestion = question.Students[i].Questions[j];
             if(tempQuestion.Question_id === tempFilterQuestion._id){
                tempCheck = tempQuestion.Check;
                tempTime = tempQuestion.Time;
                tempMemo = tempQuestion.Memo ? tempQuestion.Memo : ''
             }
          }
          tempStudent.push({ StudentNo: tempNo, Name: tempName, Check: tempCheck, Time: tempTime, Memo: tempMemo });
       }
    }
    // setTimeout(() => {
    //    setLoading(false);
    // }, [500]);
    setFilterQuestion(tempFilterQuestion);
    setStudents(tempStudent);
 }, [question, questionNo]);

  return ( 
  <Layout>
    <TeacherHomeworkDetailQuestionView filterQuestion={filterQuestion} students={students} question={question}/> 
  </Layout>

  );
}
 
export default TeacherHomeworkDetailQuestion;