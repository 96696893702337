import React, { useEffect, useState } from 'react';
import { Grid, ButtonGroup, Button, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress, Link } from '@mui/material';
import { AccessAlarm, Edit, StarBorder, Star, KeyboardDoubleArrowRight, Stop, ArrowRight } from '@mui/icons-material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useNavigate } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';
import { useSnackbar } from 'notistack';
import QuestionMemo from './QuestionMemo';
import QuestionMemoReview from './QuestionMemoReview';
import { CommentaryModal } from 'components/modal';

const QuestionDetail = (props) => {
    const question = props.question;
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    // const config = {
    //     "fast-preview": {disabled: true},
    //     loader: { load: ["[tex]/html"] },
    //     tex: {
    //       packages: { "[+]": ["html"]},
    //       inlineMath: [
    //         ["$", "$"],
    //         ["\\(", "\\)"]
    //       ],
    //       displayMath: [
    //         ["$$", "$$"],
    //         ["\\[", "\\]"]
    //       ],
    //     },
    //     options: {
    //       enableMenu: false,
    //     }
    // };
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [openMemo, setOpenMemo] = useState(false);
    const [openMemoReview, setOpenMemoReview] = useState(false);
    const [memoReview, setMemoReview] = useState('');

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };

    const filterBeforeQuestions = (datas) => {
        datas.sort();
        datas = datas.filter((data) => {
            return data.Question_id.toString().toLowerCase().indexOf(question.Question_id) > -1
        });
        return datas;
    };
    const handleClickMemoReview = (memo) => {
        if(memo){
            setMemoReview(memo);
            setOpenMemoReview(true);
        }else{
            return false;
        }
    };
    const handleClickCommentary = () => {
        if(question.Commentary.length > 0){
           setOpen(true);
        }else{
           enqueueSnackbar(`해설이 없습니다. 빠른 시일 내 추가하겠습니다.`, { variant: 'warning'});
        }
     };
    return(
        <div className='content_section_question'>
            <Grid container justifyContent='center' className='question_detail'>
                <Grid item xs={12} md={10} className='question_title'>
                    <div>{ChangeText.Part(question?.Section)}&nbsp;&gt;&nbsp;{ChangeText.MiddlePart(question?.Section)}&nbsp;&gt;&nbsp;{ChangeText.Section(question?.Section)}</div>
                    {/* <div/> */}
                    <div>
                        <div><AccessAlarm /></div>
                        <div>{ChangeText.QuestionOnlyMinute(props.questionTimer)}</div>
                    </div>
                </Grid>
                <Grid container item xs={12} md={10} className='question_error_comment'>
                    <div>· 문제 또는 정답에 오류가 있을 경우 페이지 하단의 <b>채널톡</b> 또는 <b>이메일</b>(<Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link>)으로 문의주시면 감사하겠습니다.</div>
                </Grid>
                <Grid container item xs={12} md={10}>
                    <Grid item xs={12} className='question_content_info'>
                        <div>
                            <ButtonGroup variant='outlined' color='warning' aria-label='outlined button group'>
                                <Button onClick={() => setOpenMemo(true)}><Edit /></Button>
                                <Button onClick={props.saveQuestion}>{question?.IsSaved ? <Star /> : <StarBorder />}</Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                    <Grid item xs={12} className='question_content'>
                        <div className='question_front_cover' style={{backgroundColor: props.loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>{ props.loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
                        <p>문제 {props.question?.QuestionNo ? props.question.QuestionNo-props.question.Section*10000 : ''}</p>
                        <MathJaxContext version={3} config={config}>
                        {question?.Content.map((content) => (
                            <MathJax 
                            renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                            text={
                            content.replace('`\\(','').replace('\\)`','')
                            }
                            dynamic
                            className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                            />
                        ))}
                     </MathJaxContext>
                        {/* <div style={{textAlign: 'center'}}>
                            {question?.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                        </div> */}
                    </Grid>
                    <Grid item xs={12} className='question_checks'>
                        {props.loading ? null :
                        <FormControl>
                            <RadioGroup
                                row
                                name='row-radio-buttons-group'
                                onChange={(event) => props.setChecked(event.target.value)}
                                value={props.checked}
                            >
                                {question?.NewChoices.map((choice, i) => (
                                <FormControlLabel value={(i+1)} control={<Radio color='warning'/>} label={
                                  <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                       choice.replace('`\\(','').replace('\\)`','')
                                       } inline dynamic/>
                                  </MathJaxContext>
                                } />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        }
                    </Grid>
                    {props.status === 4 || props.status === 5 || props.status === 6 ?
                    <Grid item xs={12} md={10}>
                        <div className={question?.Commentary && question?.Commentary.length > 0 ? 'teacher_homework_commentary' : 'teacher_homework_commentary teacher_homework_none_commentary'} onClick={handleClickCommentary}>해설보기</div>
                    </Grid> : " "
                    }

                </Grid>
                <Grid container item xs={12} md={10} className='question_button_group'>
                    <Grid item xs={8}>
                        {props.status !== 3 ?
                        <>
                            <Button onClick={props.NextQuestion} endIcon={<KeyboardDoubleArrowRight/>} color='warning' variant='outlined'>건너뛰기</Button>
                            <Button onClick={() => navigate(-1)} endIcon={<Stop/>} color='warning' variant='outlined'>그만풀기</Button>
                        </>:null}
                    </Grid>
                    <Grid item xs={4}>
                        <Button disabled={props.checked === 0 ? true : false || props.loading} onClick={props.submitQuestion} endIcon={<ArrowRight/>} color='warning' variant='contained' disableElevation>다음문제</Button>
                    </Grid>
                </Grid>
                {props.status === 4 || props.status === 5 || props.status === 6 ?
                <Grid container item xs={12} md={10} className='question_review_list'>
                    <Grid item xs={12}>
                        <table>
                            <tr>
                                <th>문제 풀이 날짜</th><th>문제 풀이</th><th>선택한 답</th><th>정답 여부</th><th>문제 풀이 시간</th><th></th>
                            </tr>
                            {filterBeforeQuestions(props.beforeQuestions).map((beforeQuestion, i) => (
                            <tr>
                                <td>{new Date(beforeQuestion.CreatedAt).getMonth()+1}월 {new Date(beforeQuestion.CreatedAt).getDate()}일</td>
                                <td>{ChangeText.QuestionStatusKR(beforeQuestion.Status)}</td>
                                <td>{beforeQuestion.Select}번</td>
                                <td>{ChangeText.QuestionCheck(beforeQuestion.Check)}</td>
                                <td>{ChangeText.QuestionMinute(beforeQuestion.Time)}</td>
                                <td><button disabled={beforeQuestion.Memo ? false : true} onClick={() => handleClickMemoReview(beforeQuestion.Memo)}>메모보기</button></td>
                            </tr>
                            ))}
                        </table>
                    </Grid>
                </Grid>
                :null}
            </Grid>
            <CommentaryModal open={open} setOpen={setOpen} Commentary={question?.Commentary} config={config}/>
            <QuestionMemo 
                open={openMemo}
                setOpen={setOpenMemo}
                canvasRef={props.canvasRef}
                mode={props.mode}
                setMode={props.setMode}
                ClearDraw={props.ClearDraw}
            />
            <QuestionMemoReview
                open={openMemoReview}
                setOpen={setOpenMemoReview}
                memoReview={memoReview}
            />
        </div>
    );
};

export default QuestionDetail;