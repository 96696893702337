import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';

const PriceInfoView = (props) => {
    const [openPrice, setOpenPrice] = useState(false);
    const handleClickView = () => {
        setOpenPrice(!openPrice);
    };

    return(
        <>
        <Grid container justifyContent='space-between'>
            <Grid>
                <h3 className='info_title'> · 결제 정보</h3>
            </Grid>
            <Grid>
                <Button variant='contained' color='success' onClick={props.handleClickPrice} disabled={props.priceDatas[0] ? new Date(props.priceDatas[0].Order_id.NextOrder) > new Date() : false}>무료 체험</Button>
            </Grid>
        </Grid>
        <div className='info_table'>
            <table>
                <tbody>                    
                    <tr>
                        <th>No.</th><th>상품명</th><th>관리 가능</th><th>상태</th><th>결제금액</th><th>결제수단</th><th>결제일시</th><th>만료일시</th><th>삭제</th>
                    </tr>
                    {props.priceDatas.map((price, i) => (
                        i < 5 || openPrice ?
                        <tr>
                            {/* <td><Link href={`/admin/class/${classData.ClassNo}`}>{classData.ClassNo}</Link></td> */}
                            <td>{price.PriceNo}</td>
                            <td>{price.data.orderName.replace('우로매쓰 ', '')}</td>
                            <td>{price.Order_id.MaxStudent} 명</td>
                            <td>{price.Order_id.OrderCancel ? new Date(price.Order_id.NextOrder) > new Date() ? '자동 해지' : '만료' : '사용중'}</td>
                            <td>{price.data.totalAmount.toLocaleString()} 원</td>
                            <td>{price.data.method}</td>
                            <td>{YyyyMmDdHhMmSs(price.createdAt)}</td>
                            <td>{YyyyMmDdHhMmSs((new Date(price.Order_id.NextOrder).setHours(new Date(price.Order_id.NextOrder).getHours()+1, 0, 0)))}</td>
                            <td className='info_table_button_cell'><Button variant='contained' color='error'>삭제</Button></td>
                        </tr>
                        : null
                    ))}
                    {props.priceDatas.length > 5 ? 
                        !openPrice ? 
                            <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>전체 보기 <ExpandMore fontSize='small'/></td></tr>
                        :   <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>닫기 <ExpandLess fontSize='small'/></td></tr>
                    :   props.priceDatas.length === 0 ?
                            <tr><td colSpan={8} className='info_table_all_line'>결제 정보가 없습니다.</td></tr>
                        : null}
                </tbody>
            </table>
        </div>
        </>
    );
};

export default PriceInfoView;