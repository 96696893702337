export const LocalList = () => {
    return ['강원', '경기', '경남', '경북', '광주', '대구', '대전', '부산', '서울', '세종', '울산', '인천', '전남', '전북', '제주', '충남', '충북', '기타']
};

export const StatusMapping = () => {
    return [1,2,3,4,5,6,7]
};

export const StateMapping = () => {
    return [1,2,3]
};

export const AttendingMapping = () => {
    return [1,2,3,4,5]
};

export const RegisterBirthYear = () => {
    let years = [];
    const nowYear = new Date().getFullYear();
    for(let i = nowYear; i > 1900; i--){
        years.push(i);
    }
    return years
};

export const RegisterBirthMonth = () => {
    let months = [];
    for(let i = 1; i < 13; i ++){
        months.push(i);
    }
    return months
};

export const RegisterBirthDay = (year, month) => {
    let lastDay = 0;
    if(month === 2){
        if(year%4 === 0){
            lastDay = 29;
        }else{
            lastDay = 28;
        }
    }else if(month === 4 || month === 6 || month === 9 || month === 11){
        lastDay = 30;
    }else{
        lastDay = 31;
    }
    let days = [];
    for(let i = 1; i <= lastDay; i++){
        days.push(i);
    }
    return days
};

export const SectionColors = (section) => {
  const part = Math.floor(section/100);
  switch(part){
    case 1:
      return '213, 19, 92'
    case 2:
      return '255, 199, 29'
    case 3:
      return '78, 198, 210'
    case 4: 
      return '255, 132, 33'
    case 5:
      return '89, 116, 244'
    case 6:
      return '165, 87, 231'
    default:
      return '255, 255, 255'
  }
};

export const OtherSchools = () => {
    return [
        {_id: '61ac75a330a84ae5abd659d0', Name: '외국고', Local: '기타', County: ''},
        // {_id: '61ac75a330a84ae5abd65b59', Name: '중학생', Local: '기타', County: ''},
        {_id: '61ac75a330a84ae5abd6535e', Name: '검정고시', Local: '기타', County: ''},
        {_id: '61ac75a330a84ae5abd654b4', Name: '기타', Local: '기타', County: ''}
    ]
};

export const AllParts = () => {
    return [
        {
          name: '수학',
          subSections: [
            {
              name: '다항식',
              sections: [
                {
                  name: '다항식의 연산',
                  section: 111
                },{
                  name: '나머지 정리',
                  section: 112
                },{
                  name: '인수분해',
                  section: 113
                }
              ]
            },{
              name: '방정식과 부등식',
              sections: [
                {
                  name: '복소수와 이차방정식',
                  section: 121
                },{
                  name: '이차방정식과 이차함수',
                  section: 122
                },{
                  name: '여러가지 방정식과 부등식',
                  section: 123
                }
              ]
            },{
              name: '도형의 방정식',
              sections: [
                {
                  name: '평면좌표',
                  section: 131
                },{
                  name: '직선의 방정식',
                  section: 132
                },{
                  name: '원의 방정식',
                  section: 133
                },{
                  name: '도형의 방정식(이동)',
                  section: 134
                }
              ]
            }
          ]
        },{
          name: '수학',
          subSections: [
            {
              name: '집합과 명제',
              sections: [
                {
                  name: '집합',
                  section: 141
                },{
                  name: '명제',
                  section: 142
                }
              ]
            },{
              name: '함수',
              sections: [
                {
                  name: '함수',
                  section: 151
                },{
                  name: '유리함수와 무리함수',
                  section: 152
                }
              ]
            },{
              name: '경우의 수',
              sections: [
                {
                  name: '경우의 수',
                  section: 161
                },{
                  name: '순열과 조합',
                  section: 162
                }
              ]
            }
          ]
        },{
          name: '수학Ⅰ',
          subSections: [
            {
              name: '지수함수와 로그함수',
              sections: [
                {
                  name: '지수와 로그',
                  section: 211
                },{
                  name: '지수함수와 로그함수',
                  section: 212
                }
              ]
            },{
              name: '삼각함수',
              sections: [
                {
                  name: '삼각함수',
                  section: 221
                }
              ]
            },{
              name: '수열',
              sections: [
                {
                  name: '등차수열과 등비수열',
                  section: 231
                },{
                  name: '수열의 합',
                  section: 232
                },{
                  name: '수학적 귀납법',
                  section: 233
                }
              ]
            }
          ]
        },{
          name: '수학Ⅱ',
          subSections: [
            {
              name: '함수의 극한과 연속',
              sections: [
                {
                  name: '함수의 극한',
                  section: 311
                },{
                  name: '함수의 연속',
                  section: 312
                }
              ]
            },{
              name: '미분',
              sections: [
                {
                  name: '미분계수',
                  section: 321
                },{
                  name: '도함수',
                  section: 322
                },{
                  name: '도함수의 활용',
                  section: 323
                }
              ]
            },{
              name: '적분',
              sections: [
                {
                  name: '부정적분',
                  section: 331
                },{
                  name: '정적분',
                  section: 332
                },{
                  name: '정적분의 활용',
                  section: 333
                }
              ]
            }
          ]
        },{
          name: '확률과 통계',
          subSections: [
            {
              name: '경우의 수',
              sections: [
                {
                  name: '순열과 조합',
                  section: 411
                },{
                  name: '이항정리',
                  section: 412
                }
              ]
            },{
              name: '확률',
              sections: [
                {
                  name: '확률의 뜻과 활용',
                  section: 421
                },{
                  name: '조건부 확률',
                  section: 422
                }
              ]
            },{
              name: '통계',
              sections: [
                {
                  name: '확률분포',
                  section: 431
                },{
                  name: '통계적 추정',
                  section: 432
                }
              ]
            }
          ]
        },{
          name: '미적분',
          subSections: [
            {
              name: '수열의 극한',
              sections: [
                {
                  name: '수열의 극한',
                  section: 511
                },{
                  name: '급수',
                  section: 512
                }
              ]
            },{
              name: '미분법',
              sections: [
                {
                  name: '여러가지 함수의 미분',
                  section: 521
                },{
                  name: '여러가지 미분법',
                  section: 522
                },{
                  name: '도함수의 활용',
                  section: 523
                }
              ]
            },{
              name: '적분법',
              sections: [
                {
                  name: '여러가지 적분법',
                  section: 531
                },{
                  name: '정적분의 활용',
                  section: 532
                }
              ]
            }
          ]
        },{
          name: '기하',
          subSections: [
            {
              name: '이차곡선',
              sections: [
                {
                  name: '이차곡선',
                  section: 611
                },{
                  name: '이차곡선과 직선',
                  section: 612
                }
              ]
            },{
              name: '평면벡터',
              sections: [
                {
                  name: '벡터의 연산',
                  section: 621
                },{
                  name: '평면벡터의 성분과 내적',
                  section: 622
                }
              ]
            },{
              name: '공간도형과 공간좌표',
              sections: [
                {
                  name: '공간도형',
                  section: 631
                },{
                  name: '공간좌표',
                  section: 632
                }
              ]
            }
          ]
        }       
    ]
};

export const AllPartsDashboard = () => {
  return [
      {
        name: '수학',
        subSections: [
          {
            name: '다항식',
            sections: [
              {
                name: '다항식의 연산',
                section: 111
              },{
                name: '나머지 정리',
                section: 112
              },{
                name: '인수분해',
                section: 113
              }
            ]
          },{
            name: '방정식과 부등식',
            sections: [
              {
                name: '복소수와 이차방정식',
                section: 121
              },{
                name: '이차방정식과 이차함수',
                section: 122
              },{
                name: '여러가지 방정식과 부등식',
                section: 123
              }
            ]
          },{
            name: '도형의 방정식',
            sections: [
              {
                name: '평면좌표',
                section: 131
              },{
                name: '직선의 방정식',
                section: 132
              },{
                name: '원의 방정식',
                section: 133
              },{
                name: '도형의 방정식(이동)',
                section: 134
              }
            ]
          },{
            name: '집합과 명제',
            sections: [
              {
                name: '집합',
                section: 141
              },{
                name: '명제',
                section: 142
              }
            ]
          },{
            name: '함수',
            sections: [
              {
                name: '함수',
                section: 151
              },{
                name: '유리함수와 무리함수',
                section: 152
              }
            ]
          },{
            name: '경우의 수',
            sections: [
              {
                name: '경우의 수',
                section: 161
              },{
                name: '순열과 조합',
                section: 162
              }
            ]
          }
        ]
      },{
        name: '수학Ⅰ',
        subSections: [
          {
            name: '지수함수와 로그함수',
            sections: [
              {
                name: '지수와 로그',
                section: 211
              },{
                name: '지수함수와 로그함수',
                section: 212
              }
            ]
          },{
            name: '삼각함수',
            sections: [
              {
                name: '삼각함수',
                section: 221
              }
            ]
          },{
            name: '수열',
            sections: [
              {
                name: '등차수열과 등비수열',
                section: 231
              },{
                name: '수열의 합',
                section: 232
              },{
                name: '수학적 귀납법',
                section: 233
              }
            ]
          }
        ]
      },{
        name: '수학Ⅱ',
        subSections: [
          {
            name: '함수의 극한과 연속',
            sections: [
              {
                name: '함수의 극한',
                section: 311
              },{
                name: '함수의 연속',
                section: 312
              }
            ]
          },{
            name: '미분',
            sections: [
              {
                name: '미분계수',
                section: 321
              },{
                name: '도함수',
                section: 322
              },{
                name: '도함수의 활용',
                section: 323
              }
            ]
          },{
            name: '적분',
            sections: [
              {
                name: '부정적분',
                section: 331
              },{
                name: '정적분',
                section: 332
              },{
                name: '정적분의 활용',
                section: 333
              }
            ]
          }
        ]
      },{
        name: '확률과 통계',
        subSections: [
          {
            name: '경우의 수',
            sections: [
              {
                name: '순열과 조합',
                section: 411
              },{
                name: '이항정리',
                section: 412
              }
            ]
          },{
            name: '확률',
            sections: [
              {
                name: '확률의 뜻과 활용',
                section: 421
              },{
                name: '조건부 확률',
                section: 422
              }
            ]
          },{
            name: '통계',
            sections: [
              {
                name: '확률분포',
                section: 431
              },{
                name: '통계적 추정',
                section: 432
              }
            ]
          }
        ]
      },{
        name: '미적분',
        subSections: [
          {
            name: '수열의 극한',
            sections: [
              {
                name: '수열의 극한',
                section: 511
              },{
                name: '급수',
                section: 512
              }
            ]
          },{
            name: '미분법',
            sections: [
              {
                name: '여러가지 함수의 미분',
                section: 521
              },{
                name: '여러가지 미분법',
                section: 522
              },{
                name: '도함수의 활용',
                section: 523
              }
            ]
          },{
            name: '적분법',
            sections: [
              {
                name: '여러가지 적분법',
                section: 531
              },{
                name: '정적분의 활용',
                section: 532
              }
            ]
          }
        ]
      },{
        name: '기하',
        subSections: [
          {
            name: '이차곡선',
            sections: [
              {
                name: '이차곡선',
                section: 611
              },{
                name: '이차곡선과 직선',
                section: 612
              }
            ]
          },{
            name: '평면벡터',
            sections: [
              {
                name: '벡터의 연산',
                section: 621
              },{
                name: '평면벡터의 성분과 내적',
                section: 622
              }
            ]
          },{
            name: '공간도형과 공간좌표',
            sections: [
              {
                name: '공간도형',
                section: 631
              },{
                name: '공간좌표',
                section: 632
              }
            ]
          }
        ]
      }       
  ]
};

export const GradeCut = () => {
  return [0, 4, 11, 23, 40, 60, 77, 89, 96, 100];
};

export const AnswerMapping = () => { return ['①', '②', '③', '④', '⑤']; };