import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { MockExamResultView } from 'components/teacher/class/mockExam';
import { Layout } from 'components/teacher/layout';

const MockExamStudentDetail = () => {
    const { ClassNo, MockExamNo, StudentNo, Kind } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [mockExam, setMockExam] = useState({
        Name: '',
        SchoolName: '',
        Profile: '',
        Attending: 0,
        Grade: 0,
        Questions: [{
            Check: 0,
            Memo: '',
            Time: 0,
            Question_id: {
                Answer: 0,
                Choices: ['', '', '', '', ''],
                Commentary: [' '],
                Content: [' '],
                Kind: 0,
                Point: 0,
            }
        }],
        StartDate: '1900-01-01',
        MockExamName: '',
        MockExamPartInfo: {
            Freq: 0,
            Mean: 0,
            Part: 0,
            Sd: 0,
            Student:{
                Point: 0,
                Rank: 0,
                Time: 0                
            },
            Sum: 0,
            Variance: 1
        }
    });
    
    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/teacher/mockexam/${ClassNo}/${MockExamNo}/${StudentNo}/${Kind}/${cookies.LoginKey}`, config).then((res) => {
            if(!res.data.ErrorMessage){
                setMockExam(res.data.mockExam);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                navigate(-1);
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };

    useEffect(() => {
        loadDatas();
    }, []);
    return(
        <Layout>
            <MockExamResultView
                mockExam={mockExam}
            />

        </Layout>
    );
};

export default MockExamStudentDetail;