import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { Layout } from 'components/admin/layout';
import { AdminDashboard } from 'components/admin/main';

const Dashboard = () => {
    const pages = [
        { title: '대시 보드'}
    ];
    const title = { main: '대시보드', sub: 'dashboard'};
    const [cookies] = useCookies(['LoginKey']);
    
     
    const loadUserDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/dashboard/${cookies.LoginKey}`, config).then((Response) => {
            console.log(Response);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    useEffect(() => {
        // loadUserDatas();
    }, []);

    return(
        <Layout pages={pages} title={title}>
            <AdminDashboard />
        </Layout>
    );
}

export default Dashboard;