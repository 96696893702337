import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Rating,Avatar, Button,Grid,Tabs,Tab,LinearProgress} from "@mui/material";
import { useState } from "react";
import * as ChangeText from 'components/function/ChangeText';
import { useNavigate } from "react-router-dom";

const HomeworkDetailView = (props) => {
  const navigate=useNavigate()
  const [value, setValue] =useState(0);
  const [key,setKey]=useState(true)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const progress=ChangeText.Percent(props.homework?.CompletedCnt/props.homework?.Students.length)
  const checkProgress=props.homework?.solveQuestionsCnt !== 0 ? ChangeText.Percent(props.homework?.answerQuestionsCnt/props.homework?.solveQuestionsCnt): '0%'

  return (
  <Grid container justifyContent={"center"}>
    <Grid container lg={12} xl={7} justifyContent={"space-between"}>
      <Grid item lg={5} xl={6} sx={{display:"flex",background:"#FFFFFF",justifyContent:"space-around",borderRadius:"21px",paddingBottom:"10px" }}>
        <div>
          <p style={{fontSize:"20px",fontWeight:"700",margin:"39px 0 25px 0"}}>단원</p> 
          <p style={{fontSize:"20px",fontWeight:"700",}}>문제수</p>
          <p style={{fontSize:"20px",fontWeight:"700",height:"29px",display:"flex",alignItems:"center",margin:"25px 0"}}>난이도</p> 
          <p style={{fontSize:"20px",fontWeight:"700",margin:"25px 0"}}>숙제 기한</p> 
          <p style={{fontSize:"20px",fontWeight:"700"}}>전달 사항</p>
        </div>
        <div>
          <p style={{marginLeft:"10px",fontSize:"20px",fontWeight:"500",margin:"39px 0 25px 0"}}>{props.section}</p>
          <p style={{marginLeft:"10px",fontSize:"20px",fontWeight:"500"}}>{props.homework?.Question_ids.length}</p>
          <p style={{marginLeft:"10px",margin:"25px 0"}}><Rating value={props.difficulty ? props.difficulty : ""} readOnly/></p>
          <p style={{marginLeft:"10px",fontSize:"20px",fontWeight:"500",margin:"25px 0"}}>{ChangeText.YyyyMmDd(props.homework?.StartDate)} ~ {ChangeText.YyyyMmDd(props.homework?.DueDate)}</p>
          <p style={{marginLeft:"10px",fontSize:"20px",fontWeight:"500"}}>{props.homework?.Notice}</p>
        </div>
      </Grid>
      <Grid item lg={5} xl={5} sx={{background:"#FFFFFF",borderRadius:"21px"}}>
        <h3 style={{fontSize:"20px",margin:"49px 0 27px 43px"}}>숙제 진행 상황</h3>
        <div style={{fontSize:"17px",fontWeight:"500",margin:"0 0 13px 43px "}}>진행률: <span>{progress}</span> ({props.homework?.CompletedCnt}/{props.homework?.Students.length}명)</div>
        <LinearProgress variant="determinate" value={parseInt( progress.split("%")[0])}  color="success" sx={{width:"80%",borderRadius:"49px",height:"9px",marginLeft:"43px",background:"#DDDDDD",'& .MuiLinearProgress-bar': {backgroundColor: '#F05D38',borderRadius:"49px"}}} />
        <div style={{fontSize:"17px",fontWeight:"500",margin:"27px 0 13px 43px"}}>정답률: <span>{checkProgress}</span> ({props.homework?.answerQuestionsCnt}/{props.homework?.solveQuestionsCnt}문제)</div>
        <LinearProgress variant="determinate" value={parseInt(checkProgress.split("%")[0])} color="success" sx={{width:"80%",borderRadius:"49px",height:"9px",marginLeft:"43px",background:"#DDDDDD",'& .MuiLinearProgress-bar': {backgroundColor: '#F05D38',borderRadius:"49px"}}} />
        <div style={{fontSize:"17px",fontWeight:"500",margin:"27px 0 13px 43px"}}>평균 풀이 시간: <span>{ChangeText.QuestionMinute(props.homework?.answerQuestionsTime/props.homework?.solveQuestionsCnt)}</span></div>
      </Grid>
      <div style={{width:"100%",background:"#FFFFFF",padding:"30px",borderRadius:"20px",marginTop:"69px"}}> 
        <Tabs value={value} onChange={handleChange} centered sx={{margin:"0px 0 15px 0"}} className={"academy_taps"}>
          <Tab label="문제 목록" onClick={()=>{setKey(true)}}/>
          <Tab label="학생목록" onClick={()=>{setKey(false)}}/>
        </Tabs>
      {key ? 
      <Grid item lg={12} xl={12}>
        <TableContainer >
          <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0 10px'  }} aria-label="simple table">
            <TableHead className="academy_table_head">
             <TableRow >
              <TableCell>No</TableCell>
              <TableCell align="center" >진행률</TableCell>
              <TableCell align="center">정답률</TableCell>
              <TableCell align="center">평균 풀이 시간</TableCell>
              <TableCell align="right"></TableCell>
             </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props.homework?.Question_ids.map((question,i) => (
             <TableRow key={i}>
              <TableCell component="th" scope="row">
              {question.Question-(question.Section*10000)}
              </TableCell>
              <TableCell align="center">{ChangeText.Percent(question.InfoClass.Counting/props.homework?.Students.length)}</TableCell>
              <TableCell align="center">{question.InfoClass.Counting !== 0 ? ChangeText.Percent(question.InfoClass.Correct/question.InfoClass.Counting) : '0%'}</TableCell>
              <TableCell align="center">{ChangeText.QuestionMinute(question.InfoClass.Time/question.InfoClass.Correct)}</TableCell>
              <TableCell align="right"><Button  onClick={()=>navigate(`${question.Question}`)}>문제보기</Button></TableCell>
             </TableRow>
              ))}
            </TableBody>
          </Table>
       </TableContainer>
      </Grid>: 
      <Grid item lg={12} xl={12}>
        <TableContainer >
         <Table sx={{ minWidth: 650 ,borderCollapse: 'separate', borderSpacing: '0 10px' }} aria-label="simple table">
          <TableHead>
           <TableRow>
            <TableCell>프로필</TableCell>
            <TableCell align="center">이름</TableCell>
            <TableCell align="center">학교</TableCell>
            <TableCell align="center">진행률</TableCell>
            <TableCell align="center">정답률</TableCell>
            <TableCell align="right"></TableCell>
           </TableRow>
          </TableHead>
          <TableBody className="academy_table">
          {props.homework?.Students.map((list,i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row"><Avatar src={list.Student_id.Profile}/></TableCell>
              <TableCell align="center">{list.Student_id.Name}</TableCell>
              <TableCell align="center">{list.Student_id.School_id.Name}</TableCell>
              <TableCell align="center">{ChangeText.Percent(list.QuestionInfo.Counting/props.homework?.Question_ids.length)}</TableCell>
              <TableCell align="center">{list?.QuestionInfo.Counting !== 0 ? ChangeText.Percent(list?.QuestionInfo.Correct/list.QuestionInfo.Counting) : '0%'}</TableCell>
              <TableCell align="right" ><Button onClick={()=>navigate(`student/${list.StudentNo}`)}>상세보기</Button></TableCell>
            </TableRow>
          ))}
          </TableBody>
         </Table>
        </TableContainer>
      </Grid>}
      </div>
    </Grid>
  </Grid>
   );
}
 
export default HomeworkDetailView;