import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { TeacherEdit } from 'domain/teacher';
import { ChildSelect, ChildEdit } from 'domain/parent/child';
import { Child } from '.';

const ParentPresenter = () => {
  return (
    <Routes>
        <Route path='' element={<ChildSelect/>} />
        <Route path='edit' element={<TeacherEdit/>} />
        <Route path=':ChildNo/edit' element={<ChildEdit />} />
        <Route path=':ChildNo/*' element={<Child/>} />
        <Route path='/*' element={<NotFound/>} />
    </Routes>
  )
};

export default ParentPresenter;