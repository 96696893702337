import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/parent/layout';
import { MockExamResultView } from 'components/parent/child/mockExam';

const MockExamResult = () => {
    const [cookies] = useCookies(['LoginKey', 'ChildNo']);
    const { MockExamNo, Kind } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [mockExam, setMockExam] = useState({
        Freq: 0,
        Mean: 0,
        Sd: 0,
        Sum: 0,
        Variance: 0,
        StartDate: '1900-01-01',
        Name: '',
        Question_ids: [{
          Answer: 0,
          Choices: ['', '', '', '', ''],
          Commentary: [' '],
          Content: [' '],
          Kind: 0,
          Point: 0,
          SolveInfo: {
            Check: 0,
            Memo: '',
            Select: 0,
            Time: 0
          }
        }],
        Student: {
            Part: 2,
            Point: 0,
            Rank: 0,
            Time: 0
        }
    });

    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/parent/mockexam/${cookies.ChildNo}/${MockExamNo}/${Kind}/${cookies.LoginKey}`, config).then((res) => {
            if(!res.data.ErrorMessage){
                setMockExam(res.data.mockExam);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                navigate(-1);
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        })
    };

    useEffect(() => {
        loadDatas();
    }, []);

    return(
        <Layout>
            <MockExamResultView 
                mockExam={mockExam}
            />
        </Layout>
    );
};

export default MockExamResult;