import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Hidden } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import WooroMathLogo from 'img/wooromath_logo.png';
import * as ChangeText from 'components/function/ChangeText';
import * as AddValue from 'components/function/AddValue';

const HomeworkPrint = () => {
    window.onafterprint = function(){
        window.close();
    };
    const [cookies] = useCookies(['LoginKey', 'Name', 'ClassName']);
    const { HomeworkNo } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [homeworkData, setHomeworkData] = useState({
        Question_ids: [],
        StudentNo: '',
        Students: [{
          Student_id: { Name: '' },
          MemoPictures: [],
          Questions: [{
            Check: '',
            Memo: '',
            Memo: '',
          }],
        }]
    });
    const config = {
        "fast-preview": {disabled: true},
        loader: { load: ["[tex]/html"] },
        tex: {
          packages: { "[+]": ["html"]},
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
          ],
        },
        options: {
          enableMenu: false,
        }
    };
    const answer = ['①', '②', '③', '④', '⑤'];
    useEffect(() => {
        const loadHomework = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/teacher/homework/detail/${HomeworkNo}/${cookies.LoginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    setHomeworkData(Response.data.Homework);
                    setTimeout(() => {
                        setLoading(false);
                        window.print();
                    }, [3500]);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    setTimeout(() => {
                        window.close();
                    },[1500]);
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadHomework();
    }, []);

    // useEffect(() => {
    //     let questionDiv = document.getElementsByClassName('homework_print_question');
    //     let homeworkDiv = document.getElementById('homework_contents');
    //     console.log(homeworkDiv);
    //     for(let i = 0; i < questionDiv.length; i++){
    //         if(questionDiv[i].clientHeight !== 565){
    //             questionDiv[i].className += ' homework_overflow_front';
    //         }
    //     }
    // }, [document.getElementsByClassName('homework_print_question')]);

    return (
        <div className='question_print'>
            <Grid container>
                <Grid xs={12} style={{ border: '0px'}} >
                    {!loading ?
                    <Grid className='question_print_cover'>
                        <div className='questions_print_cover_info'>
                            <h1>HOMEWORK</h1>
                            {homeworkData.Section === 1000 ? 
                            <h2>{homeworkData.Title}</h2>:
                            <h2>{ChangeText.Part(homeworkData.Section)} &gt; {ChangeText.MiddlePart(homeworkData.Section)} &gt; {ChangeText.Section(homeworkData.Section)}</h2>
                            }
                            <div>
                                <h3>{cookies.Name} 선생님 ({cookies.ClassName})</h3>
                                <h3>{ChangeText.MmDd(homeworkData.createdAt)} ~ {ChangeText.MmDd(homeworkData.DueDate)}</h3>
                                <h3><div>이름 : </div><div> </div></h3>
                            </div>
                        </div>
                        <div className='background_logo' style={{ backgroundImage: `url('${WooroMathLogo}')`}} />
                    </Grid>:
                    <Grid className='question_print_cover'>
                        <div className='questions_print_cover_info'>
                            <h1>HOMEWORK</h1>
                            <h2><CircularProgress color='warning'/></h2>
                            <p>문제 읽어오는 중...</p>
                        </div>
                        <div className='background_logo' style={{ backgroundImage: `url('${WooroMathLogo}')`}} />
                    </Grid>}
                </Grid>
            </Grid>
            <Grid container id='homework_contents'>
                <Hidden/>
                {homeworkData.Question_ids.map((question, i) => (
                    i % 2 === 0 ?
                    <Grid xs={6}>
                        <Grid className='homework_print_question' >
                            <div>
                                <p><b>문제 {i+1}</b>&nbsp;<span>(Q.{question.Question - question.Section*10000})</span></p>
                                <MathJaxContext version={3} config={config}>
                                    {question.Content.map((content) => (
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                    content.replace('`\\(','').replace('\\)`','')
                                        } dynamic 
                                    />
                                    ))}
                                </MathJaxContext>
                                <div style={{textAlign: 'center'}}>
                                    {question.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                                </div>
                                <div>
                                    {question.Choices.map((choice, i) => (
                                    <div>{answer[i]} {choice}</div>
                                    ))}
                                </div>
                            </div>
                        </Grid>
                        {homeworkData.Question_ids.length > i+1 ?
                        <Grid className='homework_print_question'>
                            <div>
                                <p><b>문제 {i+2}</b>&nbsp;<span>(Q.{homeworkData.Question_ids[i+1].Question - homeworkData.Question_ids[i+1].Section*10000})</span></p>
                                <MathJaxContext version={3} config={config}>
                                    {homeworkData.Question_ids[i+1].Content.map((content) => (
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                    content.replace('`\\(','').replace('\\)`','')
                                        } dynamic 
                                    />
                                    ))}
                                </MathJaxContext>
                                <div style={{textAlign: 'center'}}>
                                    {homeworkData.Question_ids[i+1].Picture ? <img alt='question pictures' src={homeworkData.Question_ids[i+1].Picture.includes('https://') ?  homeworkData.Question_ids[i+1].Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${homeworkData.Question_ids[i+1].Picture}`}/> : null}
                                </div>
                                <div>
                                    {homeworkData.Question_ids[i+1].Choices.map((choice, i) => (
                                    <div>{answer[i]} {choice}</div>
                                    ))}
                                </div>
                            </div>
                        </Grid>:
                        <Grid className='homework_print_question'></Grid>}
                    </Grid>:
                    null
                ))}
            </Grid>
        </div>    
    );
}

export default HomeworkPrint;