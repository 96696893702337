import axios from "axios";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";

import * as ChangeText from 'components/function/ChangeText';
import { Layout } from "components/Academy/Layout";
import { HomeworkDetailView } from 'components/Academy/teacher/homework'

const HomeworkDetail = () => {
  const [cookies] = useCookies(['LoginKey']);
  const loginKey = cookies.LoginKey;
  const {id,homeworkNo,ClassNo} = useParams();
  const [homework,setHomework] = useState();

  const teacherNo = id;
  const difficulty = homework?.Difficulty;
  const section =`${ChangeText.Part(homework?.Section)} > ${ChangeText.MiddlePart(homework?.Section)} > ${ChangeText.Section(homework?.Section)}`;



  const handleHomeworkData= async()=>{//클래스 평균 등급 그래프 
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${teacherNo}/${ClassNo}/${homeworkNo}/${loginKey}`, config).then((Response)=>{
        let changeSelectedHomework=Response.data.Homework
        changeSelectedHomework.CompletedCnt=0
        changeSelectedHomework.solveQuestionsCnt=0
        changeSelectedHomework.answerQuestionsCnt=0
        changeSelectedHomework.answerQuestionsTime=0
        changeSelectedHomework?.Question_ids?.forEach(item => {
          if (item) {
            item.InfoClass={}
          }else{}
      });
      for(let i = 0; i < changeSelectedHomework.Question_ids.length; i++){
        changeSelectedHomework.Question_ids[i].No = i;
        let tempAnswerCnt = 0;
        let tempAnswerTime = 0;
        let tempSolveCnt = 0;
        for(let j = 0; j < changeSelectedHomework.Students.length; j++){
            let student = changeSelectedHomework.Students[j];
            for(let k = 0; k < student.Questions.length; k++){    
                if(changeSelectedHomework.Question_ids[i]._id === student.Questions[k].Question_id){
                    tempSolveCnt += 1;
                    changeSelectedHomework.solveQuestionsCnt += 1;
                    if(student.Questions[k].Check === 1){
                        tempAnswerCnt += 1;
                        tempAnswerTime += student.Questions[k].Time;
                        changeSelectedHomework.answerQuestionsCnt += 1;
                        changeSelectedHomework.answerQuestionsTime += student.Questions[k].Time;
                    }
                }
            }
        }
        changeSelectedHomework.Question_ids[i].InfoClass = { Correct: tempAnswerCnt, Counting: tempSolveCnt, Time: tempAnswerTime };
    }
        changeSelectedHomework?.Students?.forEach(item => {
          if (item) {
            item.QuestionInfo={}
          }else{}
      });
      for(let i = 0; i < changeSelectedHomework.Students.length; i++){
        let tempCorret = 0;
        let tempCounting = 0;
        let tempTime = 0;
        if (changeSelectedHomework.Students[i].Questions.length === changeSelectedHomework.Question_ids.length) {
          changeSelectedHomework.CompletedCnt +=1
        }
        for(let j = 0; j < changeSelectedHomework.Students[i].Questions.length; j++){
            tempCounting += 1;
            if(changeSelectedHomework.Students[i].Questions[j].Check === 1){
                tempCorret += 1;
                tempTime += changeSelectedHomework.Students[i].Questions[j].Time;
            }
        }
        changeSelectedHomework.Students[i].QuestionInfo = { Correct: tempCorret, Counting: tempCounting, Time: tempTime };
    }
    setHomework(changeSelectedHomework);

    }).catch((err)=>{})
  };

  useEffect(()=>{
    handleHomeworkData();
  },[]);

  return ( 
  <Layout>
    <HomeworkDetailView homework={homework}section={section}difficulty={difficulty}/>
  </Layout>
   );
}
 
export default HomeworkDetail;