import React from 'react';

import WooroMathLogo from 'img/wooromath_logo.png';

const ChildMenu = (props) => {
    return(
        <>
            <div style={{width: '100%', textAlign: 'center', paddingTop: '20px'}}>
                <img src={WooroMathLogo} alt='wooro math logo' style={{width: '100px'}} />
            </div>
            {props.children}
        </>
    );
};

export default ChildMenu;