import React from 'react';
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';

import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';
import { SubmitModal } from 'components/modal';


const EvaluationListView = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const handleClickUpdateBtn = (data) => {
        if(new Date(data.StartDate) > new Date()){
            navigate(`${location.pathname}/${data.EvaluationNo}/update`);
        }else{
            enqueueSnackbar('시작일시 이후 수행평가는 수정할 수 없습니다.', { variant: 'warning' });
        }
    };
    return (
        <>
        <div className='content_section'>
        <Grid container justifyContent='center'>
            <Grid item xs={6} md={5} className='diagnosis_result_title'>
                <h2>수행평가</h2>
            </Grid>
            <Grid item xs={6} md={5} style={{ paddingTop: '50px', paddingBottom: '30px', textAlign: 'right'}}>
                <Button 
                    variant='contained' 
                    disableElevation 
                    color='warning' 
                    onClick={() => navigate(`${location.pathname}/create`)}>
                    생성하기
                </Button>
            </Grid>
            <Grid item xs={12} md={10}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>이름</TableCell>
                        <TableCell>시작일시</TableCell>
                        <TableCell>생성일시</TableCell>
                        <TableCell align='center'>수정</TableCell>
                        <TableCell align='center'>삭제</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.evaluations[0] ? 
                        props.evaluations.map((item, i) => (
                        <TableRow
                            sx={{ '& td, & th': { borderLeft: 0, borderRight: 0 } }}
                        >
                            <TableCell component='th' scope='row'>
                                {item.EvaluationNo}
                            </TableCell>
                            <TableCell><Link className='cursor_pointer' onClick={() => navigate(`/teacher/${item.ClassNo}/evaluation/${item.EvaluationNo}`)}>{item.Title}</Link></TableCell>
                            <TableCell>{YyyyMmDdHhMmSs(item.StartDate)}</TableCell>
                            <TableCell>{YyyyMmDdHhMmSs(item.createdAt)}</TableCell>
                            <TableCell align='center'><IconButton size='small' onClick={() => handleClickUpdateBtn(item)}><Edit/></IconButton></TableCell>
                            <TableCell align='center'><IconButton size='small'><Delete onClick={() => {props.setSelectEvaluation(item);props.setOpen(true);}}/></IconButton></TableCell>
                        </TableRow>
                        )):
                        <TableRow>
                            <TableCell colSpan={6} align='center'>
                                수행평가 내역이 없습니다.
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
        </Grid>
        </div>
        <SubmitModal
            title={'수행평가 삭제'}
            open={props.open}
            handleClose={() => props.setOpen(false)}
            handleClickSubmit={props.handleClickDeleteBtn}
        >
            수행평가 삭제 시 복구가 불가능합니다. 정말 삭제하시겠습니까?
        </SubmitModal>
        </>
    );
};

export default EvaluationListView;