import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Layout } from 'components/teacher/layout';
import { MockExamView } from 'components/teacher/class/mockExam';
import {TeacherMockExamInfo}from 'components/info'
import { CircularProgress } from "@mui/material";
const MockExam = (props) => {
    const { ClassNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [mockExams, setMockExams] = useState([{
        Name: '',
        MockExamNo: 1,
        ClassStudents: [{ 
            Part: 0,
            Student_id: { Name: '' }
            // Point: 0
        }],
        StartDate: '1900-01-01'
    }]);
    const [academy,setAcademy] = useState();

    const loadAcademyData = async()=>{
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
        setAcademy(Response.data.Academy);
      }).catch((err) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };
    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/teacher/mockexam/${ClassNo}/${cookies.LoginKey}`, config).then((res) => {
            if(!res.data.ErrorMessage){
                let loadMockExamDatas = [...res.data.mockExamDatas];
                loadMockExamDatas.sort((a, b) => (new Date(a.StartDate) - new Date(b.StartDate)));
                setMockExams(loadMockExamDatas);
            }else{
                // enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };

    useEffect(() => {
        loadDatas();
        loadAcademyData();
    }, []);  

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
         setLoading(false);
        }, [500]);
    }, []);
    return(
        <Layout>
             <div className='question_front_cover' style={{backgroundColor: loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',display:loading?"block":"none"}}>{ loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
            {academy ?
              <MockExamView 
              mockExams={mockExams}
              academy={academy}
              disabledDetail={props.disabledDetail}//결제
             />
             :
             (props.disabledDetail ? 
                <TeacherMockExamInfo/>
                : 
                <MockExamView 
                  mockExams={mockExams}
                  disabledDetail={props.disabledDetail}//결제
                />
             ) 
        }   
        </Layout>
    );
};

export default MockExam;