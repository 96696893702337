import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const TeacherPriceSuccess = (props) => {
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const PriceSuccess = async() => {
        if(location.search){
            const locationSplit = location.search.split('&');
            let key = [];
            for(let i = 0; i < locationSplit.length; i++){
                key.push(locationSplit[i].split('=')[1]);
            }
            
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({
                customerKey: key[0],
                authKey: key[1]
            });
            await axios.post(`/api/price`, body, config).then((Response) => {
                if(Response.data.Message){
                    enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                    navigate(`/teacher`);
                }else{
                    navigate(`/teacher/price/fail`);    
                }
            }).catch((Error) => {
                navigate(`/teacher/price/fail`);
            });
        }else{
            navigate(`/teacher/price/fail`);
        }
    };
    useEffect(() => {
        PriceSuccess();
    }, []);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
            <div style={{ position: 'absolute', top: '55%', fontSize: '17px'}}>결제 진행중 입니다.</div>
        </Backdrop>
    );
};

export default TeacherPriceSuccess;