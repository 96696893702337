import { LinearProgress,Button,Avatar,Link, Box, Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrowRightAlt } from '@mui/icons-material';

import loog from 'img/wooromath_logo.png';
import academyChart from 'img/academy_chart.png';
import academyChart2 from 'img/academy_chart2.png';
import academyRadar from 'img/academy_radar.png';
import academyRadar2 from 'img/academy_radar2.png';
import academyBar from 'img/academy_bar.png';
import IconBookmark from 'img/icon_bookmark.png';


const AcademyInfoView = (props) => {

  return (
    <Grid container justifyContent="center">
      <Grid container lg={12} xl={12} >
        <Grid item={true}  lg={5} xl={12} p={2}>
          <img src={loog} alt='wooromath_logo'width={'80px'}/>
        </Grid>
      </Grid>
      <Grid container lg={12} xl={12} sx={{height:"577px", background: 'linear-gradient(270deg, #F48166 0%, #FAC5B8 49.74%, #FFF9F6 100%)',alignItems:"center"}}justifyContent="center">
        <div style={{borderRadius:"100%",background:"#FFFFFF",width:'300px',height:'300px',position: 'absolute',left:"1396px",top:'-3px',opacity:'0.2',clipPath: 'circle(150px at center)'}}>233</div>
        <div style={{borderRadius:"100%",background:"#FFFFFF",width:'300px',height:'300px',position: 'absolute',left:"703px",top:'431px',opacity:'0.2',clipPath: 'circle(150px at center)'}}>233</div>
        <Grid item={true}  lg={6} xl={3}>
          <h1 style={{fontSize:"40px", lineHeight:"60px"}}>오직 원장님만을 위한 서비스 <br/>우로매쓰에서 선생님,학생을 <br/>관리해 보세요.</h1>
          <h3  style={{fontSize:"20px", lineHeight:"29px",fontWeight:"500" ,marginBottom:"60px"}}>숙제 진행 상황, 학생 개인에 단원별 능력치,클래스 평균 등급까지.<br/> 편안한 관리를 통해 하루하루 더 빠르게 성장합니다!</h3>
          <Button variant="contained" disableElevation size="large" sx={{ backgroundColor: '#F05D38', '&:hover': {background: '#F05D38',},width:"232px",fontSize:"20px",fontWeight:"500",borderRadius:"10px"}} onClick={props.handleButtonClick}>시작하기</Button>
        </Grid>
        <Grid item={true} lg={5.6} xl={3.2} >
          <div style={{display:"flex" ,width:"100%" ,justifyContent:"space-between" ,marginBottom:"38px" }}>
            <div style={{width:"333px"  ,background:"#FFFFFF",borderRadius:"10px",padding:"0 10px" ,marginLeft:"2px" }}>
              <p>진행률:50%{'(1/2)명'}</p>
              <div style={{width:"80%",display:"flex",alignItems:"center"}}>
              <LinearProgress variant="determinate" value={50}  sx={{width:"100%" ,backgroundColor: '#F5F5F5','span':{backgroundColor:"#F05D38"}}}/><span style={{marginLeft:"10px"}}>50%</span>
              </div>
              <p>정답률:35%{'(1/2)문제'}</p>
              <div style={{width:"80%" ,display:"flex",alignItems:"center"}}>
              <LinearProgress variant="determinate" value={35} sx={{width:"100%" ,backgroundColor: '#F5F5F5','span':{backgroundColor:"#F05D38"}}}/> <span style={{marginLeft:"10px"}}>35%</span>
              </div>
              <p>평균 풀이시간:3초</p>
            </div>
            <img src={academyRadar} alt="radar_chart" />
          </div>
            <img src={academyChart} alt="line_chart" width={'100%'}/>
        </Grid>
      </Grid>
      <Grid container lg={11} xl={7} >
        <Grid item={true} lg={12} xl={12}>
          <h1 style={{fontSize:"48px",marginTop:"147px"}}> 선생님</h1>
          <h3 style={{fontWeight:"500",fontSize:"24px"}}> 선생님 한분 한분의 데이터를 한눈에 확인할 수 있습니다.</h3>
        </Grid>
      </Grid>

      <Grid container lg={11} xl={7} sx={{border:"3px solid #DDDDDD" ,borderRadius:"25px",justifyContent:"space-around" ,padding:"50px 0"}}>
        <Grid container lg={8} xl={9} justifyContent="space-between">
          <Grid item={true}  lg={4} xl={4} sx={{ borderRadius:'40px',border:"1px solid #A4A4A4" ,display:"flex",flexFlow:"column",alignItems:"center",marginLeft:"10px"}}>
            <Avatar sx={{width:'162px',height:"162px", marginTop:"10px"}} />
            <h1>선생님</h1>
          </Grid>
          <Grid item={true}  lg={5} xl={5} sx={{borderRadius:'40px',border:"1px solid #A4A4A4" , padding:"0 20px",marginRight:"20px"}}>
            <h1>클래스</h1>
            <div style={{display:"flex", alignItems:"center"}}><input type="checkbox" defaultChecked={true}/> <Avatar className="list_click" sx={{margin:"0 10px"}}/> <span style={{fontSize:"20px"}}>이상한 수학반</span></div>
            <div style={{display:"flex", alignItems:"center",margin:"10px 0"}}><input type="checkbox" defaultChecked={true}/> <Avatar className="list_click" sx={{margin:"0 10px"}}/> <span style={{fontSize:"20px"}}>우로 수학반</span></div>
            <div style={{display:"flex", alignItems:"center"}}><input type="checkbox" defaultChecked={true}/> <Avatar className="list_click" sx={{margin:"0 10px"}}/> <span style={{fontSize:"20px"}}>우로 클래스</span></div>
          </Grid>
          <Grid item={true}  lg={12} xl={12}> <img src={academyChart} alt="line_chart" width={"100%"} style={{marginBottom:"30px"}}/></Grid>
          <Grid item={true}  lg={12} xl={12}> <img src={academyBar} alt="line_chart" width={"100%"}  style={{marginBottom:"30px"}}/></Grid>
        </Grid>
        <Grid container lg={3} xl={3} sx={{border:"3px solid #DDDDDD",borderRadius:"20px"}}> 
          <Grid item={true}  lg={12} xl={12}> 
            <h1>이상한 수학반 학생</h1>
            <div style={{display:"flex", alignItems:"center"}}>
              <input type="text" placeholder="이름/이메일" disabled style={{border:"1px solid #DDDDDD",outline:"none",padding:" 5px 3px" ,width:"80%"}}/>
              <SearchIcon sx={{border:"1px solid #DDDDDD" ,color:"#DDDDDD"}}/>
            </div>
        
            {props.students.map((students,i)=>(
            <div style={{display:"flex", alignItems:"center"}}  key={i}>   
              <Avatar className="list_click" sx={{margin:"0 10px"}}/> 
              <p>
               <p style={{fontSize:"16px" ,marginBottom:"3px"}}>{students.name}</p>
               <p style={{fontSize:"10px",marginTop:"0",color:"#A4A4A4"}}>{students.email}</p>
              </p>
             </div>
            ))}
          </Grid>         
        </Grid>
      </Grid>
      <Grid container lg={11.5} xl={7} >
        <Grid item={true}  lg={12} xl={12} ml={1} mt={16} mb={3}> 
          <h1  style={{fontSize:"48px",marginTop:"147px"}}>학생</h1>
          <h3 style={{fontWeight:"500",fontSize:"24px"}}> 학생 개인의 등급, 진단 횟수, 단원별 능력치를 확인할 수 있습니다.</h3>
        </Grid>
        <Grid item={true}  lg={8} xl={8}><img src={academyChart2} alt="line_chart2" width={"100%"} /> </Grid>
        <Grid item={true}  lg={4} xl={4}><img src={academyRadar2} alt="" width={"100%"} /> </Grid>
      </Grid>

      <Grid container lg={11.5} xl={6.3} >
        <Grid item={true}  lg={12} xl={12} ml={1} mt={16} mb={3}> 
          <h1  style={{fontSize:"48px",marginTop:"147px"}}>모의고사</h1>
          <h3 style={{fontWeight:"500",fontSize:"24px"}}>모의고사 응시권 전달도 손쉽게 전달</h3>
        </Grid>
        <Grid item={true}  lg={11.8} xl={12}>
         <TableContainer sx={{border:"3px solid #DDDDDD",borderRadius:'25px',padding:"5px"}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell >
                <TableCell align="left">프로필</TableCell>
                <TableCell align="center">이름</TableCell>
                <TableCell align="center">전화번호</TableCell>
                <TableCell align="center">이메일</TableCell>
                <TableCell align="center">생년월일</TableCell>
                <TableCell align="center">클래스 수</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {props.rows.map((row,i) => (
              <TableRow
                key={i}
                sx={{ ' td,th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {i+1}
                  </TableCell>
                  <TableCell align="center">{row.Profile}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.birth}</TableCell>
                  <TableCell align="center">{row.class}</TableCell>
                  <TableCell align="right"><Button variant="contained" disableRipple disableElevation size="small" sx={{background:"#F05D38","&:hover": {backgroundColor: "#F05D38" },cursor:"auto"}}>응시권 전달</Button></TableCell>
              </TableRow>))}
          </TableBody>
          </Table>
        </TableContainer>
        </Grid>
        <Grid item={true}  lg={11.7} xl={12} ml={2} mt={8} sx={{border:"3px solid #DDDDDD",borderRadius:"25px",display:"flex",justifyContent:"space-around",alignItems:"center",height:"162px"}}>
            <p style={{fontSize:"24px",fontWeight:"500"}}>정명석 선생님께 전달합니다</p>
            <div style={{display:"flex",alignItems:"center"}}>
              <KeyboardArrowUpIcon/>
              <div style={{background:"#F7F7F7",padding:"10px 40px",borderRadius:'5px',textAlign:"center"}}>3</div>
              <KeyboardArrowDownIcon/>
            </div>
            <Button variant="contained" size="small" disableRipple sx={{width:"150px",background:"#F05D38","&:hover": {backgroundColor: "#F05D38" },cursor:"auto"}} disableElevation>응시권 전달</Button>
        </Grid>
      </Grid>

      <Grid container lg={11} xl={6.3} mt={16} mb={16} justifyContent="space-around">
        <Grid item={true}  lg={12} xl={12}>
          <h1 style={{fontSize:"48px"}}>가격 안내</h1>
        </Grid>
        <Grid item={true}  lg={5} xl={5.5} mr={5.5}>
          <Box className='teacher_price_content' style={{ border: '5px solid #F05D38', position: 'relative' }}>
            <img src={IconBookmark} alt='icon Bookmark' style={{ position: 'absolute', width: '100px', right: '-6%', top: '-10%' }}/>
            <h2 style={{fontSize:"40px"}}>BASIC ACADEMY</h2>
            <p style={{fontSize:"20px"}}>학생 수 31이상 100명 이상</p>
            <div style={{display:"flex",alignItems:"center"}}>
              <h3 style={{fontSize:"30px"}}>₩ 396,000</h3><span style={{fontSize:"20px",fontWeight:"600",marginLeft:"5px"}}>/ 1개월{'(VAT포함)'}</span>
            </div>
            <div  style={{textAlign:"right"}}>
              <Button onClick={()=>props.handleClickPrice()}  disableElevation color='warning' variant='contained' sx={{ width:"170px",fontSize:"17px",borderRadius:"10px",'&:hover': { background:"#EF4C23" } }} endIcon={<ArrowRightAlt />}>결제하기</Button>
            </div>
          </Box>
        </Grid>
        <Grid item={true}  lg={5} xl={5.5}>
          <Box className='teacher_price_content' style={{ backgroundColor: '#F2F2F2' }}>
            <h2 style={{fontSize:"40px"}}>PREMIUM</h2>
            <p style={{fontSize:"20px"}}>인원 제한 없음</p>
            <h3 style={{fontSize:"30px"}}>별도문의</h3>
            <p style={{fontSize:"17px"}}>홈페이지 하단 <b>채널톡</b> 또는 <b><Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link></b>로 메일 문의</p>
            <div  style={{textAlign:"right"}}>
              <Button color='warning' disableRipple sx={{ width:"170px",fontSize:"17px",borderRadius:"10px",'&:hover': { background:"#EF4C23" },cursor:"auto" }}variant='contained' disableElevation endIcon={<ArrowRightAlt />}>문의하기</Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
        )
};

export default AcademyInfoView;
