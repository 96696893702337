import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, CircularProgress } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const DeleteModal = (props) => {
    return(
        <div>
            <Dialog
            open={props.openDelete}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClickCloseDelete}
            aria-describedby='alert-dialog-slide-description-delete'
            fullWidth={true}
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    onClick={props.handleClickCloseDelete}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClickCloseDelete}>취소</Button>
                <Button onClick={props.handleClickDelete} disabled={props.deleting} color='error'>{props.deleting ? <CircularProgress color='error'/> : '삭제'}</Button>
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteModal;