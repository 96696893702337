import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/user/layout';
import { StudentTicketView, StudentTicketPriceView, StudentTicketRefund } from 'components/user/student';
import { NormalModal } from 'components/modal';

const UserTicket = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const ticketPrice = [
        {
            Count: 1,
            Price: 1100
        },{
            Count: 7,
            Price: 5500
        },{
            Count: 15,
            Price: 11000
        }
    ];
    const [open, setOpen] = useState(false);
    const [openRefund, setOpenRefund] = useState(false);
    const [tickets, setTickets] = useState([{
        Order_id: {
            NextOrder: '2099-12-31'
        },
        Teacher_id: {
            Name: ''
        },
        CreatedAt: '2099-12-31'
    }]);
    const [coupon, setCoupon] = useState('');

    const loadData = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/student/ticket/${cookies.LoginKey}`, config).then((res) => {
            let loadTicketDatas = [...res.data.tickets];
            loadTicketDatas.sort((a, b) => (new Date(b.CreatedAt) - new Date(a.CreatedAt)));
            setTickets(loadTicketDatas);
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };

    const handleClickOpenRefund = () => {
        const buyTickets = tickets.filter((item) => { return item.TicketOrder_id });
        if(buyTickets.length > 0){
            setOpenRefund(true);
        }else{
            enqueueSnackbar('응시권 구매 이력이 없습니다.', { variant: 'info' });
        }
    };

    const handleClickCoupon = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify({
            loginKey: cookies.LoginKey,
            couponNumber: coupon
        });
        await axios.post('/api/student/ticket/coupon', data, config).then((res) => {
            if(res.data.ErrorMessage){
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }else{
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                let addTicket = [...tickets];
                addTicket.unshift({
                    CreatedAt: res.data.Ticket.CreatedAt,
                    Coupon_id: res.data.Ticket.Coupon_id
                });
                setTickets(addTicket);
            }
            setCoupon('');
            setOpen(false);
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };

    useEffect(() => {
        loadData();
    }, []);
    return (
    <Layout>
        <StudentTicketView 
            tickets={tickets}
            setOpen={setOpen}
            handleClickOpenRefund={handleClickOpenRefund}
        />
        <NormalModal 
            title={'응시권 구매'}
            open={open}
            setOpen={setOpen}
        >
            <StudentTicketPriceView 
                ticketPrice={ticketPrice}
                coupon={coupon}
                setCoupon={setCoupon}
                handleClickCoupon={handleClickCoupon}
            />
        </NormalModal>
        <NormalModal 
            title={'응시권 환불'}
            open={openRefund}
            setOpen={setOpenRefund}
        >
            <StudentTicketRefund
                tickets={tickets}
                setTickets={setTickets}
                setOpenRefund={setOpenRefund}
                buyTickets={tickets.filter((item) => { return item.TicketOrder_id })}
            />
        </NormalModal>
    </Layout>
    );
};

export default UserTicket;