import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import NormalDistribution from 'normal-distribution'; 
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { TeacherDashboardDetail } from 'components/teacher/class/dashboard';
import * as AddValue from 'components/function/AddValue';

const DashboardDetail = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;  
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const normDist = new NormalDistribution(0, 1); 
  const { ClassNo, StudentNo } = useParams();
  const [studentData, setStudentData] = useState({
    StudentNo: '',
    MonthZscoreSection: [],
    MonthZscoreCount: [],
    DiagnosisResult: [],
    Student_id: {
      Diagnosis: [],
      Questions: [],
      School_id: { WooroZscore: '' }
    }
  });
  const [studentList, setStudentList] = useState([]);
  const [parts, setParts] = useState(AddValue.AllPartsDashboard());
  useEffect(() => {
    const loadStudentData = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/teacher/dashboard/${ClassNo}/${StudentNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          let loadStudent = Response.data.Student;
          loadStudent.MonthZscoreSection = [];
          loadStudent.MonthZscoreCount = [];
          loadStudent.DiagnosisResult = loadStudent.Student_id.Diagnosis;

          for(let i = 0; i < 12; i++){
            const nowDate = new Date(new Date().setDate(1));
            const month = new Date(nowDate.setMonth(nowDate.getMonth() - 12 - i)).getMonth() + 1;
            // 진단 결과 월 별로 나눔
            loadStudent.MonthZscoreSection[i] = loadStudent.Student_id.Diagnosis.filter((item) => {
              const diagnosisDate = new Date(item.CreatedAt);
              return new Date().getMonth() - diagnosisDate.getMonth() === i;
            });
            loadStudent.DiagnosisResult.sort(function (a, b) {
              return new Date(b.CreatedAt) - new Date(a.CreatedAt);
            });
            loadStudent.DiagnosisResult = loadStudent.DiagnosisResult.filter((item1, a) => {
              return (loadStudent.DiagnosisResult.findIndex((item2, b) => {     
                return item1.Section === item2.Section;
              }) === a);
            });
            // 월별 진단 횟수
            if(loadStudent.MonthZscoreSection[i].length > 0){
              const diagnosisDate = new Date(loadStudent.MonthZscoreSection[i][0].CreatedAt);
              const diagnosisMonth = diagnosisDate.getMonth()+1
              const Link = diagnosisMonth < 10 ? diagnosisDate.getFullYear().toString()+0+diagnosisMonth : diagnosisDate.getFullYear().toString()+diagnosisMonth
              loadStudent.MonthZscoreCount.push({ Month: `${month}월`, '진단 횟수': loadStudent.MonthZscoreSection[i].length,Link:Link });
            }else{
              loadStudent.MonthZscoreCount.push({ Month: `${month}월`, '진단 횟수': loadStudent.MonthZscoreSection[i].length });
            }
            // 최신순으로 정렬
            loadStudent.MonthZscoreSection[i].sort(function (a, b) {
              return new Date(b.CreatedAt) - new Date(a.CreatedAt);
            });
            // 중복 제거
            loadStudent.MonthZscoreSection[i] = loadStudent.MonthZscoreSection[i].filter((item1, a) => { 
              return (loadStudent.MonthZscoreSection[i].findIndex((item2, b) => {     
                return item1.Section === item2.Section;
              }) === a);
            });
          }
          loadStudent.MonthZscoreCount = loadStudent.MonthZscoreCount.reverse();
          
          let tempParts = [...parts];
          for(let i = 0; i < tempParts.length; i++){
            let tempResult = loadStudent.DiagnosisResult.filter((item) => { return Math.floor(item.Section/100) === i+1 });
            let tempZScore = 0;
            for(let j = 0; j < tempResult.length; j++){
              tempZScore += normDist.cdf(tempResult[j].ZScore);
            }
            if(tempResult.length > 0){
              tempZScore = 1 - tempZScore/tempResult.length;
            }else{
              tempZScore = null;
            }
            tempParts[i].percent = tempZScore;
            for(let j = 0; j < tempParts[i].subSections.length; j++){
              for(let k = 0; k < tempParts[i].subSections[j].sections.length; k++){
                let tempResult = loadStudent.DiagnosisResult.filter((item) => { return item.Section === tempParts[i].subSections[j].sections[k].section });
                if(tempResult[0]){
                  tempParts[i].subSections[j].sections[k].percent = 1 - normDist.cdf(tempResult[0].ZScore);
                }else{
                  tempParts[i].subSections[j].sections[k].percent = null;
                }
              }
            }
          }
          let loadStudentList = Response.data.StudentList;
          loadStudentList.sort(function (a, b) {
            return a.Name.localeCompare(b.Name);
          });
          setStudentData(loadStudent);
          setParts(tempParts);
          setStudentList(loadStudentList);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
          navigate(-1);  
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });      
    }
    loadStudentData();
  }, [StudentNo]);

  return (
    <Layout>
      <TeacherDashboardDetail studentData={studentData} parts={parts} studentList={studentList} />
    </Layout>
  )
};

export default DashboardDetail;