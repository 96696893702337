import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { QuestionList, QuestionCreate, QuestionInfo, QuestionUpdate } from 'domain/admin/question';
import NotFound from 'routes/NotFound';

// https://designrevision.com/demo/shards-dashboard-lite-react/blog-overview

const AdminQuestionPresenter = () => {
    return(
        <Routes>
            <Route path='' element={<QuestionList />} />
            <Route path='create' element={<QuestionCreate />} />
            <Route path=':id' element={<QuestionInfo />} />
            <Route path=':id/update' element={<QuestionUpdate />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminQuestionPresenter;