import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, InputLabel, OutlinedInput, IconButton, Button, CircularProgress } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import WooroTestLogoOnly from 'img/woorotest_logo_only.png'

const TeacherClassCreate = (props) => {
    const navigate = useNavigate();
    return (
        <Grid container justifyContent='center'>
            <Grid xs={12} sm={9} md={6} lg={4.8} xl={3.5} className='teacher_class_create'>
                <div className='teacher_class_create_image' style={{backgroundImage: props.imgBase64 ? `url('${props.imgBase64}')`: `url('${WooroTestLogoOnly}')`}}>
                    <div>
                        <IconButton color='error' onClick={props.handleClickDeleteProfile}>
                            <Remove />
                        </IconButton>
                        <label htmlFor='Profile' className='upload_profile'>
                            <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangeProfile}/>
                            <IconButton color='primary' component='span'>
                                <Add />
                            </IconButton>
                        </label>
                    </div>
                </div>
                <div className='teacher_class_create_name'>
                    <FormControl>
                        <InputLabel htmlFor='class_name' color='warning'>클래스 이름 *</InputLabel>
                        <OutlinedInput
                            id='class_name'
                            name='className'
                            color='warning'
                            type='text'
                            value={props.className}
                            onChange={(e) => props.setClassName(e.target.value)}
                            label='클래스 이름 *'
                            placeholder='클래스 이름을 입력해 주세요.'
                        />
                    </FormControl>
                </div>
                <div className='teacher_class_button_group'>
                    <Button color='secondary' onClick={() => navigate(-1)}>취소</Button>
                    <Button variant='outlined' color='warning' onClick={props.handleClickCreate} disabled={props.creating}>등록</Button>
                </div>
                {props.creating ?
                <div className='teacher_class_button_group'>
                    <CircularProgress color='warning'/>
                </div> : null}
            </Grid>
        </Grid>
    );
};

export default TeacherClassCreate;