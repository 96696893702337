import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { MockExamList, MockExamCreate, MockExamInfo, MockExamUpdate, MockExamQuestionCreate, MockExamQuestionUpdate } from 'domain/admin/mockExam';
import NotFound from 'routes/NotFound';

// https://designrevision.com/demo/shards-dashboard-lite-react/blog-overview

const AdminMockExamPresenter = () => {
    return(
        <Routes>
            <Route path='' element={<MockExamList />} />
            <Route path='create' element={<MockExamCreate />} />
            <Route path=':id' element={<MockExamInfo />} />
            <Route path=':id/update' element={<MockExamUpdate />} />
            <Route path=':id/create' element={<MockExamQuestionCreate />} />
            <Route path=':id/:Question_id/update' element={<MockExamQuestionUpdate />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminMockExamPresenter;