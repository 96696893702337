import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import MainBannerImage from 'img/main_banner_teacher.png';

const Banner = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return(    
        <Grid container className='main_banner' justifyContent={'center'}>
            <span className='main_banner_circle1'/>
            <span className='main_banner_circle2'/>
            <span className='main_banner_circle3'/>
            <Grid item xs={12} sm={12} md={10}>
                <h1>
                    {props.classData.ClassName}
                    {props.permitStudentCnt > 0 ? '': '에서'}
                    <br/>
                    {props.permitStudentCnt > 0 ? `학생들의 성적을 관리해 보세요.` : `함께할 학생들을 모집해주세요!`}
                </h1> 
                <button onClick={() => navigate(props.permitStudentCnt > 0 ? `${location.pathname}/dashboard` : `${location.pathname}/propose`)}>{props.permitStudentCnt > 0 ? '학생 대시보드': '학생 모집하기'}</button>
                <img src={MainBannerImage} alt='main banner images' className='teacher_image'/>
            </Grid>
        </Grid>
    );
};

export default Banner;