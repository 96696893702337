import React, { useState, useEffect } from 'react';
import { Grid, Box, Tabs, Tab, Avatar, Link, FormControl, Select, MenuItem, Button, CircularProgress } from '@mui/material';
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import * as ChangeText from 'components/function/ChangeText';
import { QuestionMemoReview } from 'components/user/question';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
        theme.palette.grey[200]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#EF4C23'
    }
}));

const ParentHomeworkManage = (props) => {
    const { ChildNo } = useParams();
    
    const homeworkDatas = props.homeworkDatas;
    const nowHomework = homeworkDatas.filter((item) => { return  new Date() < new Date(item.DueDate).setHours(23,59,59,999)});
    const beforeHomework = homeworkDatas.filter((item) => { return  new Date() >= new Date(item.DueDate).setHours(23,59,59,999)});
    
    const [selectedHomework, setSelectedHomework] = useState();
    const [sortOption, setSortOption] = useState(0);
    const [openMemoReview, setOpenMemoReview] = useState(false);
    const [memoReview, setMemoReview] = useState('');
    
    const handleClickMemo = (memo) => {
       setMemoReview(memo);
       setOpenMemoReview(true);
    };
    const handleClickHomework = (HNO) => {
        let changeSelectedHomework = homeworkDatas.filter((item) => { return item.HomeworkNo === HNO });
        changeSelectedHomework = changeSelectedHomework[0];
        let tempTime = 0;
        let tempCorrect = 0;
        for(let i = 0; i < changeSelectedHomework.Question_ids.length; i++){
            const tempQuestion = changeSelectedHomework.Students[0].Questions.filter((item) => { return item.Question_id === changeSelectedHomework.Question_ids[i]._id })[0];
            changeSelectedHomework.Question_ids[i].No = i;
            if(tempQuestion){
                changeSelectedHomework.Question_ids[i].Check = tempQuestion.Check;
                changeSelectedHomework.Question_ids[i].Time = tempQuestion.Time;
                changeSelectedHomework.Question_ids[i].Memo = tempQuestion.Memo;
                if(tempQuestion.Check === 1){
                    tempCorrect += 1;
                    tempTime += tempQuestion.Time;
                }
            }
        }
        changeSelectedHomework.Correct = tempCorrect;
        changeSelectedHomework.Time = tempTime;
        setSelectedHomework(changeSelectedHomework);
    };

    const QuestionDatasSort = (datas) => {
        if(sortOption === 0){
            datas.sort(function (a, b) {
                return a.No - b.No;
            });
        }else if(sortOption === 1){
            datas.sort(function (a, b) {
                return a.Question - b.Question;
            });
        }else if(sortOption === 2){
            datas.sort(function (a, b) {
                return b.Info.Correct/b.Info.Counting - a.Info.Correct/a.Info.Counting;
            });
        }else if(sortOption === 3){
            datas.sort(function (a, b) {
                return a.Info.Time/a.Info.Correct - b.Info.Time/b.Info.Correct;
            });
        }
        return(datas);
        // datas.sort(function (a, b) {
        //     return b.Student_id.Name.localeCompare(a.Student_id.Name);
        // });
    };
    
    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>숙제 이력</h2>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='teacher_homework_list'>
                        <table>
                            {nowHomework.map((homework, i) => (
                            <td>
                                <div className='teacher_homework_info' onClick={() => handleClickHomework(homework.HomeworkNo)}>
                                    <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)` }} />
                                    <div className='homework_info_date'><span>D-{ChangeText.DDay(homework.DueDate)}</span>{ChangeText.MmDd(homework.StartDate)} ~ {ChangeText.MmDd(homework.DueDate)}</div>
                                    <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                    <div className='homework_info_section'>{homework.Class_id.ClassName}<span>({homework.Manager_id.Name})</span></div>
                                    <div className='homework_info_progress'>
                                        <BorderLinearProgress variant='determinate' value={homework.CompletedCnt/homework.Students.length*100} /> 
                                    </div>
                                    <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.CompletedCnt/homework.Students.length)}</div>
                                </div>
                            </td>
                            ))}
                            {beforeHomework.map((homework, i) => (
                            <td>
                                <div className='teacher_homework_info' onClick={() => handleClickHomework(homework.HomeworkNo)}>
                                    <div className='homework_info_banner' style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${homework.Section}.png)`, filter: 'grayscale(100%)' }} />
                                    <div className='homework_info_date'>{ChangeText.YyyyMmDd(homework.StartDate)} ~ {ChangeText.YyyyMmDd(homework.DueDate)}</div>
                                    <div className='homework_info_section'><span>{ChangeText.Part(homework.Section)} &gt; {ChangeText.MiddlePart(homework.Section)}</span><br/>&gt; {ChangeText.Section(homework.Section)}</div>
                                    <div className='homework_info_section'>{homework.Class_id.ClassName}<span>({homework.Manager_id.Name})</span></div>
                                    <div className='homework_info_progress'>
                                        <BorderLinearProgress variant='determinate' value={homework.CompletedCnt/homework.Students.length*100} /> 
                                    </div>
                                    <div className='homework_info_progress_percent'>{ChangeText.Percent(homework.CompletedCnt/homework.Students.length)}</div>
                                </div>
                            </td>
                            ))}
                        </table>
                    </div>
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>세부 정보</h2>
                </Grid>
                {selectedHomework ?
                <Grid container item xs={12} md={10} justifyContent='space-between'>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info' alignItems='center' sx={{padding: '16px !important'}}>
                        <table className='teacher_homework_manage_info'>
                            <tr>
                                <th>단원:</th><td>{ChangeText.Part(selectedHomework.Section)} &gt; {ChangeText.MiddlePart(selectedHomework.Section)} &gt; {ChangeText.Section(selectedHomework.Section)}</td>
                            </tr>
                            <tr>
                                <th>문제수:</th><td>{selectedHomework.QuestionsCnt}문제</td>
                            </tr>
                            <tr>
                                <th>난이도:</th><td>{'★ '.repeat(selectedHomework.Difficulty)}{'☆ '.repeat(5-selectedHomework.Difficulty)}</td>
                            </tr>
                            <tr>
                                <th>숙제 기한:</th><td>{ChangeText.YyyyMmDd(selectedHomework.StartDate)} ~ {ChangeText.YyyyMmDd(selectedHomework.DueDate)}</td>
                            </tr>
                            <tr>
                                <th>전달 사항:</th><td>{selectedHomework.Notice}</td>
                            </tr>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={5.9} className='teacher_propose_class_info' style={{ padding: '16px !important'}}>
                        <div className='teacher_homework_manage_progress'>
                            <h3>숙제 진행 상황</h3>
                            <div>진행률: <span>{ChangeText.Percent(selectedHomework.Students[0].Questions.length/selectedHomework.QuestionsCnt)}</span> ({selectedHomework.Students[0].Questions.length} / {selectedHomework.QuestionsCnt})</div>
                            <div>정답률: <span>{selectedHomework.Students[0].Questions.length > 0 ? ChangeText.Percent(selectedHomework.Correct/selectedHomework.Students[0].Questions.length): '0%'}</span> ({selectedHomework.Correct} / {selectedHomework.Students[0].Questions.length})</div>
                            <div>평균 풀이 시간: <span>{selectedHomework.Correct > 0 ? ChangeText.QuestionMinute(selectedHomework.Time/selectedHomework.Correct) : '-'}</span></div>
                            <div>풀이: {selectedHomework.Students[0].MemoPictures.length > 0 ? <Link href='javascript:;' onClick={() => handleClickMemo(selectedHomework.Students[0].MemoPictures)}>풀이 보기 [{selectedHomework.Students[0].MemoPictures.length}]</Link> : '등록 풀이 없음'}</div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '0px !important' }}>
                        <Grid container alignItems='flex-end'>
                            <Grid item xs={8} className='diagnosis_result_title' style={{ padding: '0px'}}>
                                <h2>문제 정보</h2>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right', padding: '0px !important' }}>
                                <FormControl variant='standard' sx={{ minWidth: 120, textAlign: 'left' }}>
                                    <Select
                                        value={sortOption}
                                        onChange={(e) => setSortOption(e.target.value)}
                                    >
                                        <MenuItem value={0}>출제순</MenuItem>
                                        <MenuItem value={1}>번호</MenuItem>
                                        <MenuItem value={2}>정답률</MenuItem>
                                        <MenuItem value={3}>풀이시간</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                            <table >
                                <tr>
                                    <td>No.</td>
                                    <td>정답여부</td>
                                    <td>정답률</td>
                                    <td>풀이시간</td>
                                    <td>평균풀이시간</td>
                                    <td>메모여부</td>
                                    <td></td>
                                </tr>
                                {QuestionDatasSort(selectedHomework.Question_ids).map((question, i) => (
                                <tr>
                                    <td>{question.Question-question.Section*10000}</td>
                                    <td>{ChangeText.QuestionCheck(question.Check)}</td>
                                    <td>{question.Info.Counting > 0 ?ChangeText.Percent(question.Info.Correct/question.Info.Counting) : '0%'}</td>
                                    <td>{ChangeText.QuestionMinute(question.Time)}</td>
                                    <td>{question.Info.Correct > 0 ? ChangeText.QuestionMinute(question.Info.Time/question.Info.Correct) : '-'}</td>
                                    <td>{question.Memo ? <Link href='javascript:;' onClick={() => handleClickMemo(question.Memo)}>O</Link> : 'X'}</td>
                                    <td><Link href={`/parent/${ChildNo}/homework/${selectedHomework.Section}/${question.Question}`}>상세보기</Link></td>
                                </tr> 
                                ))}
                            </table>
                        </div>
                    </Grid>
                </Grid>
                :<div style={{width: '100%', textAlign: 'center'}}>상단 숙제를 클릭하여 세부 정보를 확인하세요.</div>}
            </Grid>
            <QuestionMemoReview 
                open={openMemoReview}
                setOpen={setOpenMemoReview}
                memoReview={memoReview}
            />
        </div>
    );
};

export default ParentHomeworkManage;