import { useState } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { TeacherCreateView } from "components/Academy/teacher";
import { Layout } from "components/Academy/Layout";

const TeacherCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [ cookies] = useCookies(['LoginKey']);
  const loginKey = cookies.LoginKey;

  const [teacherInfo, setTeacherInfo] = useState({
    name: "",
    phone: "",
    email: "",
    birth: "",
    State: 1,
    Status: 3,
    Login: 1,
  });
  const [emailCheck,setEmailCheck] = useState(false);
  const [loading,setLoading] = useState(false);

  const handleChange = (event) => {
    let changeTeacherInfo = { ...teacherInfo };
    changeTeacherInfo[event.target.name] = event.target.value;
    setTeacherInfo(changeTeacherInfo);
  };

  const condition =
    teacherInfo.name.length !== 0 &&
    teacherInfo.name !== undefined &&
    teacherInfo.email.length !== 0 &&
    teacherInfo.email !== undefined;

  const passwordData = async () => {
    setLoading(true);
    if ( teacherInfo.email.length !== 0 && teacherInfo.email !== undefined ) {
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({Email:teacherInfo.email});
      await axios.post(`/api/emailAuth/teacher`, body, config).then((Response) => {
        setTeacherInfo({
          name: "",
          phone: "",
          email: "",
          birth: "",
        });
        enqueueSnackbar(`메일이 전송되었습니다.`, { variant: 'info' });
        setLoading(false)
        navigate(-1);
      }) 
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
      }
    };

  const handleData = async () => {
    if (condition) {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({...teacherInfo ,loginKey:loginKey});
        await axios.post(`/api/academy/teacher`, body, config).then((Response) => {
          passwordData()
        }) 
        .catch((Error) => {
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
    } else {
      enqueueSnackbar(`내용을 입력해 주세요`, { variant: 'error' });
    }
  };
  
 

  const emailDoubleCheck = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    const email =teacherInfo.email
    const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    if (teacherInfo.email.length !== 0 && teacherInfo.email !== undefined) {
      if(reg_email.test(teacherInfo.email)){
        await axios.get(`/api/teacher/email/${email}`, config).then((Response) => {
         if (Response.data.Message) {
           setEmailCheck(true)
           enqueueSnackbar('사용 가능한 이메일 입니다',{ variant: 'info' })
         }else{
          setEmailCheck(false)
          enqueueSnackbar('이미 사용된 이메일 입니다',{ variant: 'error' })
         }
        })
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
      }
      else{
        enqueueSnackbar(`이메일 형식으로 작성해 주세요.`, { variant: 'error' });
    }
    }  
  };
const rewrite =() =>{
  setEmailCheck(false)
}

  return (
    <Layout>
      {
        loading ? 
        <div style={{textAlign: 'center',marginTop:"400px"}}>
          <CircularProgress color='warning'/>
        </div>:
        <TeacherCreateView
          handleChange={handleChange}
          handleData={handleData}
          teacherInfo={teacherInfo}
          emailDoubleCheck={emailDoubleCheck}
          emailCheck={emailCheck}
          rewrite={rewrite}
        />
      }
    </Layout>
  );
};

export default TeacherCreate;
