import React, { createRef, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionDetail } from 'components/user/question';

const Question = () => {
  const [cookies] = useCookies(['LoginKey']);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [questionInfo, setQuestionInfo] = useState({
    Questions: [],  //출제문제 목록 리스트
    DiagnosisQuestions: [], //풀고 있는 진단 문제 리스트
    SubmitDiagnosisInfo: [],  //제출할 진단 문제 리스트 5문제
    BeforeQuestions: []
  });
  const [currentNo, setCurrentNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(0);
  const [checked ,setChecked] = useState(0);
  const [questionTimer, setQuestionTimer] = useState(0);
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    const timers = setInterval(() => {
        setQuestionTimer(questionTimer => questionTimer+1);
    }, 1000);
    return () => clearInterval(timers);
}, [questionTimer]);

  const LoginKey = cookies.LoginKey;
  const path = location.pathname.split('/');

  const loadStatus = (status) => {
    switch(status){
        case 'solve':
            return 1
        case 'homework':
            return 2
        case 'diagnosis':
            return 3
        case 'review':
            return 4
        case 'save':
            return 5
        case 'wrong':
            return 6
        default:
            return 0
    }
  };

  let status = loadStatus(path[2]);
  let section = path[3];

  /* 상세 문제 불러오기 */
  const getQuestion = async (num) => {
    if(status === 3){
      if(questionInfo.DiagnosisQuestions.length === 5){
        enqueueSnackbar(`진단이 완료되었습니다. 잠시만 기다려주세요.`, { variant: 'info' });
        saveDiagnosisResult(num);
      }else{
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/question/diagnosis/${section}/${LoginKey}/${num}`, config).then((Response) => {
          let tmp = 0;
          for(let i = 0; i < questionInfo.DiagnosisQuestions.length; i++){
            if(Response.data.question._id.toString() === questionInfo.DiagnosisQuestions[i].Question_id.toString()){
              tmp = 1;
            }
          }
          if(tmp !== 0){
            getQuestion(num);
          }else{
            let Zscore;
            if(num === 0){
              Zscore = Response.data.score;
            }else{
              Zscore = num;
            }
            setScore(Zscore);
            let temp = { ...questionInfo };
            temp['DiagnosisQuestions'].push({
              Question_id: Response.data.question._id, 
              Answer: Response.data.question.Answer,
              Choices: Response.data.question.Choices,
              NewChoices: Response.data.question. NewChoices,
              QuestionNo: Response.data.question.Question,
              Section: Response.data.question.Section,
              Content: Response.data.question.Content,
              Picture: Response.data.question.Picture,
              IsSaved: Response.data.isSaved
            });
            setQuestionInfo(temp);

            setTimeout(() => {
              setLoading(false);
            }, [500]);
            
            if(currentNo+1 < questionInfo.DiagnosisQuestions.length){
              setCurrentNo(currentNo+1);
            }
          }
        })
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
      }
    }else{
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/question/${status}/${section}/${LoginKey}`, config).then((Response) => {
        if(Response.data.QuestionList === false){  //풀이할 문제가 존재하지 않은 경우
          if(status === 1){
            enqueueSnackbar(`단원의 문제를 모두 풀었습니다. 복습하기 메뉴를 이용해 주세요.`, { variant: 'info' });
          }else if(status === 4){
            enqueueSnackbar(`복습할 문제가 없습니다.`, { variant: 'error' });
          }else if(status === 5){
            enqueueSnackbar(`저장된 문제가 없습니다.`, { variant: 'error' });
          }else if(status === 6){
            enqueueSnackbar(`복습할 오답 문제가 없습니다.`, { variant: 'error' });
          }
        }else{  //풀이할 문제가 존재하는 경우
          if(status === 3){
            setScore(Response.data.score);
          }

          let temp = { ...questionInfo };
          for(let i = 0; i < Response.data.question.length; i++){
            temp['Questions'].push({
              Question_id: Response.data.question[i]._id, 
              Answer: Response.data.question[i].Answer,
              Choices: Response.data.question[i].Choices,
              NewChoices: Response.data.question[i].NewChoices,
              QuestionNo: Response.data.question[i].Question,
              Section: Response.data.question[i].Section,
              Content: Response.data.question[i].Content,
              Commentary: Response.data.question[i].Commentary,
              Picture: Response.data.question[i].Picture,
              IsSaved: LoginKey ? Response.data.isSaved[i] : false
            })
          }
          setQuestionInfo(temp);
          if(status === 4 || status === 5 || status === 6){
            getSolveSectionInfo();
          }
          setLoading(true);
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }
  };
  useEffect(()=>{
    if(LoginKey || status === 1){
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, [500]);
      getQuestion(0);
    }else{
      enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error' });
      navigate(`/login`);
    }
  },[]);

  /* 상세 문제 저장하기/취소하기 */
  const saveQuestion = async () => {
    if(LoginKey){
      let obj = {};
      if(status === 3){
        obj = { 
          Question_id: questionInfo.DiagnosisQuestions[currentNo].Question_id,
          loginKey: LoginKey,
          Status: 5,
          Section: questionInfo.DiagnosisQuestions[currentNo].Section,
          IsSaved: questionInfo.DiagnosisQuestions[currentNo].IsSaved 
        };
      }else{
        obj = {
          Question_id: questionInfo.Questions[currentNo].Question_id,
          loginKey: LoginKey,
          Status: 5,
          Section: questionInfo.Questions[currentNo].Section,
          IsSaved: questionInfo.Questions[currentNo].IsSaved 
        };
      }
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify(obj);
      await axios.post(`/api/question`, body, config).then((Response) => {
        let temp = {...questionInfo};
        if(status === 3){
          temp.DiagnosisQuestions[currentNo].IsSaved = Response.data.isSaved;
        }else{
          temp.Questions[currentNo].IsSaved = Response.data.isSaved;
        }
        setQuestionInfo(temp);
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error'});
    }
  };

  /* 문제 제출하기 */
  const submitQuestion = async(memo) => {
    setLoading(true);
    if(LoginKey){
      let check;
      let obj = {};
      let num;
      const endTime = new Date();
      if(status === 3){
        let alpha = 1/(2**currentNo);
        if(questionInfo.DiagnosisQuestions[currentNo].Answer === Number(checked)){ //정답인 경우
          check = 1;
          num = score+alpha;
          setScore(num);
        }else if(questionInfo.DiagnosisQuestions[currentNo].Answer !== Number(checked)){ //오답인 경우
          check = 2;
          num = score-alpha;
          setScore(num);
        }
        obj = { //풀이한 진단문제를 제출
          Question_id: questionInfo.DiagnosisQuestions[currentNo].Question_id,
          loginKey: LoginKey,
          Status: status,
          Select: Number(checked),
          Section: questionInfo.DiagnosisQuestions[currentNo].Section,
          Check: check,
          Time: Number(((endTime-startTime)/1000).toFixed(2)),
          Memo: memo
        };
        let temp = {...questionInfo};
        temp['SubmitDiagnosisInfo'].push({
          Question_id: obj.Question_id,
          Select: Number(checked),
          Check: check,
          Time: Number(obj.Time),
          Memo: memo
        });
        setQuestionInfo(temp) //제출한 진단문제를 업데이트
      }else{
        if(status === 5 || status === 6){
          status = 4;
        }
        if(questionInfo.Questions[currentNo].Answer === Number(checked)){
          enqueueSnackbar(`정답입니다.`, { variant: 'success'});
          check = 1;
        }else if(questionInfo.Questions[currentNo].Answer !== Number(checked)){
          enqueueSnackbar(`오답입니다.`, { variant: 'error'});
          check = 2;
        }
        obj = {
          Question_id: questionInfo.Questions[currentNo].Question_id,
          loginKey: LoginKey,
          Answer: questionInfo.Questions[currentNo].Answer,
          Status: status,
          Select: checked,
          Section: questionInfo.Questions[currentNo].Section,
          Check: check,
          Time: Number(((endTime-startTime)/1000).toFixed(2)),
          Memo: memo
        };
      }
      
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify(obj);
      await axios.post(`/api/question`, body, config).then((Response) => {
        setChecked(0);
        setQuestionTimer(0);
        setStartTime(new Date());
        if(status === 3){
          getQuestion(num);
        }else{
          if(currentNo+1 === questionInfo.Questions.length){
            enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
            navigate(-1);
          }else{
            setTimeout(() => {
              setLoading(false);
            }, [500]);
            setCurrentNo(currentNo=>currentNo+1);
          }
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      if(questionInfo.Questions[currentNo].Answer === Number(checked)){
        enqueueSnackbar(`정답입니다.`, { variant: 'success'});
      }else if(questionInfo.Questions[currentNo].Answer !== Number(checked)){
        enqueueSnackbar(`오답입니다.`, { variant: 'error'});
      }
      setChecked(0);
      setQuestionTimer(0);
      setStartTime(new Date());
      ClearDraw();
      if(currentNo+1 === questionInfo.Questions.length){
        enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
        return navigate(-1)
      }else{
        setTimeout(() => {
          setLoading(false);
        }, [500]);
        setCurrentNo(currentNo=>currentNo+1);
      }
    }
  };
  /* 진단하기 세트 저장 */
  const saveDiagnosisResult = async (grade) =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: LoginKey,
      Section: section,
      Grade: grade,
      Questions: questionInfo.SubmitDiagnosisInfo
    });
    await axios.post(`/api/question/diagnosis`, body, config).then((Response) => {
      let diagnosisNo = Response.data.DiagnosisNo;
      navigate(`/question/diagnosis/result/${diagnosisNo}`)
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  /* 유저의 문제풀이정보를 가져오는 함수 */
  const getSolveSectionInfo = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/info/${LoginKey}`, config).then((Response) => {
      let userQuestions = [];
      for(let i = 0; i < Response.data.result.User_id.Questions.length; i++){
        if(Response.data.result.User_id.Questions[i].Status !== 5){
          userQuestions.push({
            Question_id: Response.data.result.User_id.Questions[i].Question_id._id,
            QuestionNo: Number(Response.data.result.User_id.Questions[i].Question_id.Question),
            Check: Number(Response.data.result.User_id.Questions[i].Check),
            Time: Number(Response.data.result.User_id.Questions[i].Time),
            Select: Number(Response.data.result.User_id.Questions[i].Select),
            Status: Number(Response.data.result.User_id.Questions[i].Status),
            Section: Number(Response.data.result.User_id.Questions[i].Question_id.Section),
            Memo: Response.data.result.User_id.Questions[i].Memo,
            CreatedAt: Response.data.result.User_id.Questions[i].CreatedAt,
          });
        }
      }
      userQuestions.sort(function (a, b) {
        return new Date(b.CreatedAt) - new Date(a.CreatedAt);
      });
      let temp = {...questionInfo}
      temp['BeforeQuestions'] = userQuestions;
      setQuestionInfo(temp);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const NextQuestion = () => {
    setLoading(true);
    setChecked(0);
    setQuestionTimer(0);
    setStartTime(new Date());
    ClearDraw();
    if(currentNo+1 === questionInfo.Questions.length){
      enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
      return navigate(-1)
    }else{
      setTimeout(() => {
        setLoading(false);
      }, [500]);
      setCurrentNo(currentNo=>currentNo+1);
    }
  };
  // 메모
  const Mobile = () => {return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);}
  let canvas;
  let canvasRef = createRef();

  let pos = {
      drawable: false,
      x: -1,
      y: -1
  };
  let ctx;
  let [mode, setMode] = useState(1);
  
  useEffect(() => {
    canvas = canvasRef.current;
    ctx = canvas.getContext('2d');
    
    if(Mobile()){
      canvas.addEventListener('touchstart', initDraw);
      canvas.addEventListener('touchend', finishDraw);
      canvas.addEventListener('touchmove', draw);
      canvas.addEventListener('touchout', finishDraw);
    }else{
      canvas.addEventListener('mousedown', initDraw);
      canvas.addEventListener('mouseup', finishDraw);
      canvas.addEventListener('mousemove', draw);
      canvas.addEventListener('mouseout', finishDraw);
    } 
    return () => {
        if(Mobile()){
          canvas.removeEventListener('touchmove', draw);
        }else{
          canvas.removeEventListener('mousemove', draw);
        }
    };
  }, [mode]);

  const getPosition = (event) => {
    if(Mobile()){
      return { X: event.touches[0].pageX, Y: event.touches[0].pageY };
    }else{
      return { X: event.offsetX, Y: event.offsetY };
    } 
  };
  
  const initDraw = (event) => {    
      ctx.beginPath();
      ctx.lineWidth = 3;
      pos = { drawable: true, ...getPosition(event) };
      ctx.moveTo(pos.X, pos.Y);
  };

  const draw = (event) => {
      if(pos.drawable){
          if(mode === 1){
              pos = { ...pos, ...getPosition(event) };
              ctx.lineTo(pos.X, pos.Y);
              ctx.stroke();
          }else if(mode === 2){
              ctx.beginPath();
              if(Mobile()){
                ctx.clearRect(event.touches[0].pageX-25, event.touches[0].pageY-25, 50, 50);
              }else{
                ctx.clearRect(event.clientX-event.target.offsetLeft-25, event.clientY-event.target.offsetTop-25, 50, 50);
              }
          }
      }
  };

  const finishDraw = () => {
      pos = { drawable: false, X: -1, Y: -1 };
  };

  const ClearDraw = () => {
      canvas = canvasRef.current;
      ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const UploadData = async() => {
      canvas = canvasRef.current;
      ctx = canvas.getContext('2d');
      if(!isCanvasBlank(ctx)){
          ctx = canvas.getContext('2d');
          const base64 = canvas.toDataURL();
          const file = base64toFile(base64, `memo.png`);
          ClearDraw();
          const formData = new FormData();
          formData.append('loginKey', cookies.LoginKey);
          formData.append('imgFile', file);
          const config = { headers: { 'content-type': 'multipart/form-data' }};
          await axios.post(`/api/question/memo`, formData, config).then((Response) => {
          submitQuestion(Response.data.Memo);
          }).catch((error) => {
          console.log(error)
          })
      }else{
        submitQuestion('');
      }
  };
  const isCanvasBlank = (ctx) => {
      return !ctx
        .getImageData(0, 0, canvas.width, canvas.height).data
        .some(channel => channel !== 0);
  };
  const base64toFile = (base_data, filename) => {
      var arr = base_data.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
  
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type:mime });
  };
  // console.log(questionInfo)
  return (
    <Layout>
      <QuestionDetail 
        question={status === 3 ? questionInfo.DiagnosisQuestions[currentNo] : questionInfo.Questions[currentNo]}
        beforeQuestions={questionInfo.BeforeQuestions}
        currentNo={currentNo}
        NextQuestion={NextQuestion}
        status={status}
        questionTimer={questionTimer}
        checked={checked}
        setChecked={setChecked}
        saveQuestion={saveQuestion}
        submitQuestion={UploadData}
        loading={loading}
        canvasRef={canvasRef}
        mode={mode}
        setMode={setMode}
        ClearDraw={ClearDraw}
      />
    </Layout>
  );
};

export default Question;
