import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText,  Collapse } from '@mui/material';
import { Dashboard, ExpandLess, ExpandMore,Class  } from '@mui/icons-material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import loog from 'img/wooromath_logo.png';
import mockexam from 'img/academy_mockexam_icon.png';
import logout from 'img/academy_logout_icon.png';
import user from 'img/academy_user_icon.png';
import teacherList from 'img/academy_teacherList_icon.png';
import classList from 'img/academy_class_icon.png';
import board from 'img/academy_board_icon.png';
import card from 'img/academy_card_icon.png';
import correction from 'img/academy_ correction_icon.png';

const Layout = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['LoginKey','AcademyName','AcademyNo','Academy_id', 'Name', 'UserNo', 'Profile', 'ClassNo', 'ClassName', 'ClassImage']);
    const { enqueueSnackbar } = useSnackbar();

    const [openMenu, setOpenMenu] = useState({ teacher: false, mockexam: false });
    const [page, setPage] = useState('');
    const [academyData,setAcademyData] = useState()
    const [Menu ,setMenu] = useState( true )

    // 상세 메뉴 여닫기
    const handleClickOpenMenu = (kind) => {
        let changeOpenMenu = { ...openMenu };
        changeOpenMenu[kind] = !changeOpenMenu[kind];
        setOpenMenu(changeOpenMenu);
    };

    const loadAcademyData= async()=>{
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
          setAcademyData(Response.data.Academy);
          if (Response.data.Academy) {
            setMenu(false)
          }
        }).catch((Error) => {
            enqueueSnackbar(`Network Error`, { variant: 'error' });
        });
      }

    useEffect(() => {
        const pages = pathname.split('/');
        if(pages[2] === 'student' || pages[2] === 'manager'){
            handleClickOpenMenu('user');
        }else{
            handleClickOpenMenu(pages[2]);
        }
        setPage(pages[2]);
    }, [pathname]);

    // 메뉴 이동
    const handleClickMenu = (link) => {
        if(link !== pathname){
            navigate(link);
        }
    };

    useEffect(()=>{
        loadAcademyData()
    },[]);
    
    const handleClickLogout = () => {
        removeCookie('LoginKey', { path: '/'});
        removeCookie('Name', { path: '/'});
        removeCookie('UserNo', { path: '/'});
        removeCookie('Profile', { path: '/'});
        removeCookie('ClassNo', { path: '/'});
        removeCookie('ClassName', { path: '/'});
        removeCookie('ClassImage', { path: '/'});
        removeCookie('AcademyName', { path: '/'});
        removeCookie('AcademyNo', { path: '/'});
        removeCookie('Logo', { path: '/'});
        removeCookie('Academy_id', { path: '/'});
        navigate('/');
      };
    
    return(
        <div className='academy_layout_menu'>
            <List>
                <div style={{ display:"flex",justifyContent:"center",alignItems:"center",paddingBottom:"28px",paddingTop:"10px" }}>
                    <img src={loog} alt='woorotest_logo' width={"126px"} height="72px" />
                </div>
                <ListItem className={'academy_layout_menu_check'} disabled={Menu} >
                    <ListItemButton onClick={() => handleClickMenu('/academy/dashboard')} disabled={Menu}>
                        <ListItemIcon>
                            <Dashboard/>
                        </ListItemIcon>
                        <ListItemText>
                            Dashboard
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={'academy_layout_menu_check' }disabled={Menu}>
                    <ListItemButton onClick={() => handleClickOpenMenu('teacher')}disabled={Menu}>
                        <ListItemIcon>
                         <img src={teacherList} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            교사 관리
                        </ListItemText>
                        {openMenu.teacher ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.teacher} timeout='auto' unmountOnExit disabled={Menu}>
                    <List component='div' disablePadding>
                        <ListItemButton sx={[ {'&:hover': { color: '#F05D38',backgroundColor: '#FFEEEE',},},{ pl: 6  }]} onClick={() => handleClickMenu('/academy/teacher/create')}>
                            <ListItemText primary='교사 생성' />
                        </ListItemButton>
                        <ListItemButton sx={[ {'&:hover': { color: '#F05D38',backgroundColor: '#FFEEEE',},},{ pl: 6  }]} onClick={() => handleClickMenu('/academy/teacher')}>
                            <ListItemText primary='교사 목록' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                <ListItemButton onClick={() => handleClickMenu('/academy/class')}disabled={Menu}>
                        <ListItemIcon>
                        <img src={classList} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            클래스 정보
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                <ListItemButton onClick={() => handleClickMenu('/academy/student')}disabled={Menu}>
                        <ListItemIcon>
                        <img src={user} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            학생 정보
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                    <ListItemButton onClick={() => handleClickOpenMenu('mockexam')}disabled={Menu}>
                        <ListItemIcon>
                            <img src={mockexam} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            모의고사 
                        </ListItemText>
                        {openMenu.mockexam ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openMenu.mockexam} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        <ListItemButton sx={[ {'&:hover': { color: '#F05D38',backgroundColor: '#FFEEEE',},},{ pl: 6 }]}  onClick={() => handleClickMenu('/academy/mockexam')}>
                            <ListItemText primary='응시권 배분' />
                        </ListItemButton>
                        <ListItemButton sx={[ {'&:hover': { color: '#F05D38',backgroundColor: '#FFEEEE',},},{ pl: 6 }]}  onClick={() => handleClickMenu('/academy/mockexam/list')}>
                            <ListItemText primary='사용 내역' />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                <ListItemButton onClick={() => handleClickMenu('/academy/board')}disabled={Menu}>
                        <ListItemIcon>
                        <img src={board} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            공지 사항 
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                    <ListItemButton onClick={() => handleClickMenu('/academy/price/list')}disabled={Menu}>
                        <ListItemIcon>
                        <img src={card} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            결제 관리
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                </List>
                
                <List>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                    <ListItemButton onClick={() => handleClickMenu('/teacher')}disabled={Menu}>
                        <ListItemIcon>
                            <Class/>
                        </ListItemIcon>
                        <ListItemText>
                            클래스 선택 
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={'academy_layout_menu_check'}disabled={Menu}>
                    <ListItemButton onClick={() => handleClickMenu('/academy/user')}disabled={Menu}>
                        <ListItemIcon>
                            <img src={correction} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            정보 수정
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem className={'academy_layout_menu_check'}>
                    <ListItemButton onClick={()=>handleClickLogout()}>
                        <ListItemIcon>
                        <img src={logout} alt="" />
                        </ListItemIcon>
                        <ListItemText>
                            로그아웃
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );
}

export default Layout;