import React from 'react';
import AcademyMainPresenter from './AcademyMainPresenter';

const AcademyMainContainer = () => {
  return (
    <AcademyMainPresenter />
  );
};

export default AcademyMainContainer;
