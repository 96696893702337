import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useSnackbar } from "notistack";

import { ClassDetailView } from "components/Academy/class";
import { Layout } from "components/Academy/Layout";
import * as ChangeText from 'components/function/ChangeText';

const ClassDetail = () => {
  const [ cookies ] = useCookies(['LoginKey']);
  const loginKey = cookies.LoginKey;
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const ClassNo = id;

  const [search, setSearch] = useState("");
  const [students,setStudents] = useState([]);
  const [classDetail,setClassDetail] = useState();//class info
  const [classStudent,setClassStudent] =useState([])

  
  const handleClassData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/class/${ClassNo}/${loginKey}`, config).then((Response)=>{
      setClassDetail(Response.data.Class)
      let loadStudents = Response.data.Class.Students.filter(
        (item) => {return item.Connect === 2}
        );
        setStudents(loadStudents)
    }).catch((err)=>{})
  };

const lineChartDate =[{ id:"class",data:[] }]
for (let i = 0; i < 12; i++) {
  const nowDate = new Date(new Date().setDate(1));
  const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
  lineChartDate[0]?.data?.push({ 'x': `${month}월` });
};

const monthlyData = {};
if (students) {
  students.forEach(item => {
    const diagnosis = item.Student_id.Diagnosis;
    diagnosis.forEach(diag => {
      const createdAt = new Date(diag.CreatedAt);
      const month = createdAt.getMonth(); 
        if (!monthlyData[month]) {
        monthlyData[month] = { sum: 0, count: 0 };
      }
       monthlyData[month].sum += diag.CDFSum;//월 별로 쪼개서 CDFSum을 다 더함
      monthlyData[month].count += diag.CDFCount; //월 별로 쪼개서 CDFCount를 다 더함
    });
  });
  lineChartDate[0]?.data?.forEach((month, index) => {
    const monthIndex = parseInt(month.x) - 1;
    if (monthlyData[monthIndex]) { //monthlyData 안에 1~12 월 중 맞는 월 에 데이터를 넣어준다
      const { sum, count } = monthlyData[monthIndex];
      const average = count > 0 ? (sum / count) : 0;
      month.y = ChangeText.StaNine(average);
    } else {
      month.y =  ChangeText.StaNine(0.03);//월 에 데이터가 없다면 9등급을 넣어준다
    }
  })
};

for (let i = 0; i < lineChartDate[0].data.length; i++) { //등급이 없는 달  이전 달  등급 유지
  let tempSum = 0
  if (lineChartDate[0].data[i].y <= 8) {
    tempSum = lineChartDate[0].data[i].y 
  }else{
    if (i === 0 ) {
      tempSum = 9
    }else {
      tempSum = lineChartDate[0].data[i - 1].y 
    }
  }
  lineChartDate[0].data[i].y = tempSum;
}

  const barChartData=[]
  const barChart=[]
  for (let i = 0; i < students?.length; i++) { //학생을 평균 등급을 구함
    barChart.push({"학생수":ChangeText.StaNine(students[i].Student_id.Diagnosis[0]?.CDFMean)})    
  };
  for (let i = 0; i < 9; i++) { // 1~9등급 
    barChartData.push({"country":`${i+1}등급`})
  };

  const newData = barChartData.map(item => { // 각 등급에 해당하는 학생수를 넣어줌
    const count = barChart.filter(dataItem => dataItem["학생수"] && item["country"].includes(dataItem["학생수"])).length;
    return { ...item, "학생수": count };
  });


  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const studentFilter = classDetail?.Students.filter((data) => {
    return (
      data.Student_id.Name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
      data.Student_id.Email.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  });



  useEffect(()=>{
    handleClassData();
  },[]);


  return (  
  <Layout>
   <ClassDetailView 
   classDetail={classDetail}
   classStudent={classStudent}
   newData={newData}
   studentFilter={studentFilter}
   handleChangeSearch={handleChangeSearch}
   search={search}
   lineChartDate={lineChartDate}
   students={students}
  />  
  </Layout>
);
}
 
export default ClassDetail;