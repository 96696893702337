import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { EvaluationCreateView } from 'components/teacher/class/evaluation';


const EvaluationCreate = () => {
    const { ClassNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [evaluation, setEvaluation] = useState({
        Title: '',
        StartDate: '',
        EvaluationElement: [{
            Label: '',
            Detail: ['']
        }],
        Standard: '',
        Activity: '',
        Score: {
            Labels: [],
            Scores: [],
            LabelsScores: [],
        }
    });
    const [creating, setCreating] = useState(false);

    const handleClickCreate = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(evaluation);
        await axios.post(`/api/teacher/evaluation/${ClassNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setTimeout(() => {
                    navigate(`/teacher/${ClassNo}/evaluation`);
                }, [1000]);
            }else{
                setTimeout(() => {
                    enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                    setCreating(false);
                }, [1000]);
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
            setCreating(false);
        });
    };

    return(
        <Layout>
            <EvaluationCreateView 
                evaluation={evaluation}
                setEvaluation={setEvaluation}
                handleClickCreate={handleClickCreate}
                creating={creating}
            />
        </Layout>
    );
};

export default EvaluationCreate;