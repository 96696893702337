import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, FormControl, Input, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const QuestionInspectionModal = (props) => {
    return(
        <div>
            <Dialog
            open={props.openInspection}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClickCloseInspection}
            aria-describedby='alert-dialog-slide-description-delete'
            fullWidth={true}
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={props.handleClickCloseInspection}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                    <RadioGroup
                        row
                        labelId='notError-label'
                        value={props.changeInspection.NotError}
                        name='NotError'
                        onChange={props.handleChangeInspection}
                    >
                        <FormControlLabel value={1} control={<Radio />} label='정답' />
                        <FormControlLabel value={2} control={<Radio />} label='오답' />
                    </RadioGroup>
                    <FormControl variant='standard' fullWidth >
                        <Input
                            type='text'
                            value={props.changeInspection.Comment}
                            onChange={props.handleChangeInspection}
                            placeholder={props.question.Inspection.Comment ? props.question.Inspection.Comment : '검수내용을 입력해 주세요.'}
                            name='Comment'
                        />
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClickCloseInspection}>취소</Button>
                <Button onClick={props.handleClickInspection}>완료</Button>
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuestionInspectionModal;