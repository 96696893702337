import React, { useState } from 'react';
import { Grid, Box, Stepper, Step, StepLabel, Accordion, Typography, AccordionDetails, AccordionSummary, CircularProgress, Button, FormControl, Input, InputAdornment, Rating, TextField, FormHelperText } from '@mui/material';
import { ExpandMore, Check } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';

const theme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: { 
            color: '#A4A4A4',
            '&.Mui-active': {
              color: '#F05D38'
            },
            '&.Mui-completed': {
              color: '#F05D38'
            }
          }
        }
      }
    },
    palette: {
        secondary: { main: '#A4A4A4'},
        warning: { main: '#F05D38'}
    },
});
const TeacherHomeworkAssign = (props) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const { ClassNo } = useParams();
    const stepList = ['단원 선택', '숙제 옵션', '완료'];

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClickSection = (section) => {
        props.setSteps(1);
        props.handleClickSection(section);
    };

    const handleClickNext = () => {
        if(props.steps === 0){
            props.setSteps(1);
        }else{
            props.handleClickHomeworkAssign();
        }
    };
    
    return(
        <ThemeProvider theme={theme}>
            <div className='content_section' style={{marginTop: '60px'}}>
                <Grid container justifyContent='center'>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={props.steps} alternativeLabel>
                                {stepList.map((label, i) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel error={false}>{props.homeworkData.Section && i === 0 ? ChangeText.Section(props.homeworkData.Section) : label}</StepLabel>
                                    </Step>
                                );
                                })}
                            </Stepper>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={10} lg={6} style={{marginTop: '30px'}}>
                        {props.steps === 0 ? 
                        props.parts.map((part) => (
                        <Accordion expanded={expanded === part.name} onChange={handleChange(part.name)}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><b>{part.name}</b></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                {part.subSections.map((subSection) => (
                                    subSection.sections.map((section) => (
                                <Grid item xs={6} sm={4} md={3}>
                                    <div className='teacher_homework_assign_section' onClick={() => handleClickSection(section.section)}>
                                        {subSection.name}<br/>&gt; <b>{section.name}</b>
                                    </div>
                                </Grid>
                                    ))
                                ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        )):
                        props.steps === 1 ? 
                        <Grid container justifyContent='center'>
                            <Grid item xs={12} sm={6} md={6} lg={5} className='homework_banner_image' style={{padding: '0px'}}>
                                <div>
                                    <div style={{ backgroundImage: `url(https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/homework/${props.homeworkData.Section}.png)` }} />
                                    <Grid container className='teacher_homework_form'>
                                        <Grid item xs={12}>단원</Grid>
                                        <Grid item xs={12} className='homework_form_data'>
                                            <b>{ChangeText.Part(props.homeworkData.Section)}</b> &gt; <b>{ChangeText.MiddlePart(props.homeworkData.Section)}</b> &gt; <b>{ChangeText.Section(props.homeworkData.Section)}</b>
                                        </Grid>
                                        <Grid item xs={12}>난이도</Grid>
                                        <Grid item xs={12} className='homework_form_data'>
                                            <Rating
                                                name='Difficulty'
                                                size='large'
                                                value={props.homeworkData.Difficulty}
                                                onChange={props.handleChangeHomework}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>문제수</Grid>
                                        <Grid item xs={12} className='homework_form_data'>
                                            <FormControl variant='standard' sx={{ width: '90%' }}>
                                                <Input
                                                    type='number'
                                                    color='warning'
                                                    name='QuestionsCnt'
                                                    onFocus={() => props.setQuestionCntError(false)}
                                                    placeholder={`최대 ${ChangeText.SectionQuestions(props.homeworkData.Section)}문제 까지 설정할 수 있습니다.`}
                                                    value={props.homeworkData.QuestionsCnt}
                                                    onChange={props.handleChangeHomework}
                                                    endAdornment={<InputAdornment position='end'>문제</InputAdornment>}
                                                />
                                                {props.questionCntError ? <FormHelperText error={true}>100문제 까지 설정할 수 있습니다.</FormHelperText> : null}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>숙제 기한</Grid>
                                        <Grid item xs={12} className='homework_form_data'>
                                            <FormControl variant='standard' sx={{ width: '90%' }}>
                                                <Input
                                                    type='date'
                                                    color='warning'
                                                    name='DueDate'
                                                    onFocus={() => props.setDueDateError(false)}
                                                    value={props.homeworkData.DueDate}
                                                    onChange={props.handleChangeHomework}
                                                />
                                                {props.dueDateError ? <FormHelperText error={true}>오늘 이전 날짜일 수 없습니다.</FormHelperText> : null}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>전달 사항</Grid>
                                        <Grid item xs={12} className='homework_form_data'>
                                            <FormControl variant='standard' sx={{ width: '90%' }}>
                                                <TextField
                                                    multiline
                                                    name='Notice'
                                                    rows={3}
                                                    color='warning'
                                                    value={props.homeworkData.Notice}
                                                    onChange={props.handleChangeHomework}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>:
                        props.steps === 2 || props.steps === 3 ?
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <CircularProgress color='warning'/>
                            <p>숙제 {props.steps === 2 ? '생성' : '전송'} 중... 잠시만 기다려 주세요.</p>
                        </div>:
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Check color='warning' fontSize='large' />
                            <p>숙제 전송 완료!</p>
                        </div>}
                    </Grid>
                    {props.steps !== 4 ?
                    <Grid container justifyContent='center'>
                        <Grid item xs={12} md={10} lg={6} className='teacher_homework_assign_btn'>
                            <Button variant='outlined' color='secondary' disabled={props.steps === 0 || props.steps === 2 ? true : false} onClick={() => props.setSteps(props.steps-1)}>이전</Button>
                            <Button variant='contained' color='warning' disabled={props.steps > 1 || !props.homeworkData.Section || !props.homeworkData.QuestionsCnt || !props.homeworkData.DueDate ? true : false} onClick={handleClickNext}>{props.steps === 0 ? '다음' : '완료'}</Button>
                        </Grid>
                    </Grid>:
                    <Grid container justifyContent='center'>
                        <Grid item xs={12} md={10} lg={6} className='teacher_homework_assign_btn'>
                            <Button variant='outlined' color='warning' onClick={() => navigate(`/teacher/${ClassNo}/homework`)}>숙제 관리</Button>
                            <Button variant='contained' color='warning' onClick={props.shareToKakao}>카카오톡 공유</Button>
                        </Grid>
                    </Grid>}
                </Grid>
            </div>
        </ThemeProvider>
    );
};

export default TeacherHomeworkAssign;