import React, { useEffect, useState } from 'react';
import { Grid, Button, Link } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import IconCheck from 'img/icon_check.png';
import MainBannerTeacher from 'img/main_banner_teacher.png';
import IconBookmark from 'img/icon_bookmark.png';
import tossKey from 'config/toss';


const TeacherPriceKind = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const navigate = useNavigate();
    const location = useLocation();
    
    const clientKey = tossKey;
    const tossPayments = window.TossPayments(clientKey);
    const [orderData, setOrderData] = useState({
        State: '',
        createdAt: ''
    });
    const today = new Date();
    const notOrder = today > new Date('2023-01-01') ? false : true;
    const handleClickPrice = () => {
        tossPayments.requestBillingAuth('카드', { // 결제 수단 파라미터
            // 빌링키 발급 요청을 위한 파라미터
            customerKey: cookies.LoginKey,
            successUrl: location.search.indexOf('loginKey') > -1 ? `${window.location.origin}/teacher/price/success/app` : `${window.location.origin}/teacher/price/success`,
            failUrl: location.search.indexOf('loginKey') > -1 ? `${window.location.origin}/teacher/price/fail/app` : `${window.location.origin}/teacher/price/fail`
        })
        .catch(function (error) {
            if (error.code === 'USER_CANCEL') {
                // 결제 고객이 결제창을 닫았을 때 에러 처리
                enqueueSnackbar('결제에 실패하였습니다.', { variant: 'error'});
            } else if (error.code === 'INVALID_CARD_COMPANY') {
                // 유효하지 않은 카드 코드에 대한 에러 처리
                enqueueSnackbar('유효하지 않은 카드입니다.', { variant: 'error'});
            }
        })
    };

    const loadOrderData = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/order/${cookies.LoginKey}`, config).then((Response) => {
            if(Response.data.Order){
                setOrderData(Response.data.Order);
            }
        }).catch((Error) => {
          // console.log(Error);
        });
    };

    const NotReady = () => {
        enqueueSnackbar('준비중 입니다.', { variant: 'warning' });
    };

    useEffect(() => {
        loadOrderData();
    }, []);
    return (
        <div className='content_section'>
            <Grid container justifyContent={'center'} className='teacher_price_banner'>
                <Grid item xs={12} sm={7} md={7} lg={5} xl={6}>
                    <div>
                        <h1>선생님만의 숙제관리 &#38;<br/>성적예측 솔루션</h1>
                        <div>
                            <img src={IconCheck} alt={'icon check'} />
                            클래스별 성적/진도 통합 관리
                        </div>
                        <div>
                            <img src={IconCheck} alt={'icon check'} />
                            단원별 자동 숙제출제/채점
                        </div>
                        <div>
                            <img src={IconCheck} alt={'icon check'} />
                            AI로 형성되는 학생 리포트
                        </div>
                    </div>
                </Grid>
                <Grid item xs={0} sm={5} md={4} lg={3} xl={2.5}>
                    <img src={MainBannerTeacher} alt={'Main Banner Teacher'} style={{ width: '100%' }}/>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} className='teacher_price_kind'>
                <Grid item xs={12}>
                    <h3>학생수에 따른 차등 요금제로 업그레이드</h3>
                    <p>학생 수 15인 미만은 무료로 서비스를 제공합니다. Teacher Basic / Academy 서비스 구독 시 <b><Link href='/payterm' target='_blank'>유료 이용약관</Link></b>을 반드시 확인해 주세요!</p>
                </Grid>
                <Grid item xs={12} sm={12} md={11} lg={9} container justifyContent={'center'}>
                    <Grid item xs={12} sm={4}>
                        <div className='teacher_price_content'>
                            <h4>Free</h4>
                            <p>학생 수 15인 이하</p>
                            <div>
                                <span>무료</span>
                            </div>
                            {!orderData.State?
                            <p>* 현재 사용중인 서비스 입니다.</p>:
                            null}
                            <p></p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className='teacher_price_content' style={{ border: '5px solid #F05D38', position: 'relative' }}>
                            <img src={IconBookmark} alt='icon Bookmark' style={{ position: 'absolute', width: '100px', right: '-6%', top: '-10%' }}/>
                            <h4>Teacher Basic</h4>
                            <p>학생 수 16인 이상, 30인 이하</p>
                            <div>
                                <span>₩ 165,000 </span><span>/ 1개월</span>
                            </div>
                            {orderData.State === 2?
                            <p>* 현재 사용중인 서비스 입니다.</p>:
                            null}
                            {notOrder ? <p>2023년 1월 1일 부터 결제가 가능합니다.</p> : <></>}
                            <Button color='warning' variant='contained' disabled={orderData.State || notOrder} disableElevation endIcon={<ArrowRightAlt />} onClick={handleClickPrice}>결제하기</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className='teacher_price_content' style={{ backgroundColor: '#F2F2F2' }}>
                            <h4>Academy</h4>
                            <p>학생 수 31인 이상은 Academy 요금제 사용</p>
                            <div>
                                <span>요금 보기 </span><span>/ 개인 문의</span>
                            </div>
                            {orderData.State === 3?
                            <p>* 현재 사용중인 서비스 입니다.</p>:
                            null}
                            <p>홈페이지 하단 <b>채널톡</b> 또는 <b><Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link></b>로 메일 문의</p>
                            <Button color='warning' variant='contained' disableElevation endIcon={<ArrowRightAlt />} onClick={NotReady}>바로가기</Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default TeacherPriceKind;