import React from "react";
import { ResponsiveLine } from "@nivo/line";
const StudentDetailLineChart = (props) => {
  // const data = [
  //   {
  //     id: "전국상위",
  //     color: "hsl(331, 70%, 50%)",
  //     line: {
  //       style: "solid",
  //     },
  //     point: {
  //       size: 10,
  //       strokeWidth: 2,
  //       stroke: "#fff",
  //       fill: "transparent",
  //     },
  //     data: [
  //       {
  //         x: "1월",
  //         y: 222,
  //       },
  //       {
  //         x: "2월",
  //         y: 207,
  //       },
  //       {
  //         x: "3월",
  //         y: 119,
  //       },
  //       {
  //         x: "4월",
  //         y: 172,
  //       },
  //       {
  //         x:"5월",
  //         y: 218,
  //       },
  //       {
  //         x: "6월",
  //         y: 130,
  //       },
  //       {
  //         x: "7월",
  //         y: 244,
  //       },
  //       {
  //         x: "8월",
  //         y: 169,
  //       },
  //       {
  //         x: "9월",
  //         y: 41,
  //       },
  //       {
  //         x: "10월",
  //         y: 28,
  //       },
  //       {
  //         x: "11월",
  //         y: 282,
  //       },
  //       {
  //         x: "12월",
  //         y: 199,
  //       },
  //     ],
  //   }]
   
  return (
    <ResponsiveLine
      data={props.data}
      curve="linear"
      margin={{ top: 30, right: 80, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: 0,
        max: 100,
        stacked: true,
        reverse: true,
      }}
 
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "월",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "백분율",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={8}
      // pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      enableSlices="x"
      enableCrosshair={false}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      // layers={["axes"]}
      legends={[
        {
          anchor: "top-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />)
}
 
export default StudentDetailLineChart;