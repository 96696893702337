import React from "react";
import { ResponsiveLine } from "@nivo/line";

const TeacherDetailLineChart = (props) => {

  return (
    <ResponsiveLine
      data={props.data}
      curve="monotoneX"
      margin={{ top: 20, right: 60, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: 'linear',
        min: 1,
        max: 9,
        reverse: true, // y축 높은 수가 아래로
        stacked: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "학생 등급 추이",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "등급",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      // pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      tooltip={({ point }) => (
        <div style={{background:"#f05d38",padding:"5px 20px",borderRadius:"8px"}}>
           <span style={{fontWeight:"700",color:"#FFFFFF"}}>{point.data.x}:</span>
          <span style={{fontWeight:"700",color:"#FFFFFF"}}>{point.data.y}등급</span>
        </div>
      )}
    
      // legends={[
      //   {
      //     anchor: "bottom-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 100,
      //     translateY: 0,
      //     itemsSpacing: 0,
      //     itemDirection: "left-to-right",
      //     itemWidth: 80,
      //     itemHeight: 20,
      //     itemOpacity: 0.75,
      //     symbolSize: 12,
      //     symbolShape: "circle",
      //     symbolBorderColor: "rgba(0, 0, 0, .5)",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemBackground: "rgba(0, 0, 0, .03)",
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
    />
  );
};

export default TeacherDetailLineChart;
