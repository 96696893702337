import React from 'react';
import { Grid, Button, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';

const MockExamQuestionInfo = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const questions = props.questions;
    return(
        questions ? 
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 모의고사 문제 정보</h3>
                </Grid>
                <Grid className='info_button_group'>
                    <Button variant='contained' color='success' onClick={() => navigate(`${location.pathname}/create`)}>등록</Button>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>문제 번호</th><th>선택 과목</th><th>배점</th><th>답안</th><th>수정</th><th>삭제</th>
                        </tr>
                        {questions.map((question, i) => (
                        <tr>
                            <td><Link className='cursor_pointer' onClick={() => props.handleClickQuestionModal(i)}>{question.Question}</Link></td>
                            <td>{ChangeText.MockExamQuestionKind(question.Kind)}</td>
                            <td>{question.Point}점</td>
                            <td>{question.SingleAnswer ? '주관식' : '객관식'}</td>
                            <td><Button variant='contained' color='warning' disableElevation onClick={() => navigate(`${location.pathname}/${question._id}/update`)}>수정</Button></td>
                            <td><Button variant='contained' color='error' disabled={props.questionDeleting} disableElevation onClick={() => props.handleClickOpenDeleteQuestion(i)}>삭제</Button></td>
                        </tr> 
                        ))}
                    </tbody>
                </table>
            </div>
        </>:
        <></>
    );
};

export default MockExamQuestionInfo;