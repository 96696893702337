import React, { useState } from 'react';
import { Grid, Button, Link, Stack, Pagination } from '@mui/material';
import { YyyyMmDd, YyMmDd, YyyyMmDdHhMmSs, Grade, Attending, OrderState } from 'components/function/ChangeText';
import { useNavigate } from 'react-router-dom';

const OrderView = (props) => {
    const maxPage = Math.ceil(props.prices.length / 15);
    const now = new Date();
    const freeService = new Date(props.prices[0].Order_id.NextOrder).setHours(new Date(props.prices[0].Order_id.NextOrder).getHours(props.prices[0].Order_id.NextOrder)+1, 0, 0) < new Date();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    
    const handleChangePage = (_, v) => {
        setPage(v)
    };
    // {props.academy ? 
    //     (props.prices[0].User_id && props.academy.Manager_id === props.prices[0].User_id ? 
    return (
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>나의 이용정보</h2>
                </Grid>
                <Grid item xs={12} md={5} style={{ marginBottom: '16px' }}>
                    <div className='student_propose_list'>
                        <div className='teacher_order_table'>
                            {freeService ?
                                (props.academy ? 
                                    <table>
                                    <tr>
                                        <th>사용 중인 서비스</th>
                                        <td>"우로매쓰 Academy Basic 구독"</td>
                                    </tr>
                                    <tr>
                                        <th>사용 기한</th>
                                        <td>회원 탈퇴 시까지</td>
                                    </tr>
                                    <tr>
                                        <th>서비스 구독 신청</th>
                                        <td style={{ paddingTop: '8px', paddingBottom: '8px' }}><Button size='small' variant='outlined' color='warning' onClick={() => navigate(`/teacher/price`)}disabled>신청</Button></td>
                                    </tr>
                                    </table>
                                    :
                                    <table>
                                    <tr>
                                        <th>사용 중인 서비스</th>
                                        <td>우로매쓰 Teacher Free</td>
                                    </tr>
                                    <tr>
                                        <th>사용 기한</th>
                                        <td>회원 탈퇴 시까지</td>
                                    </tr>
                                    <tr>
                                        <th>서비스 구독 신청</th>
                                        <td style={{ paddingTop: '8px', paddingBottom: '8px' }}><Button size='small' variant='outlined' color='warning' onClick={() => navigate(`/teacher/price`)}>신청</Button></td>
                                    </tr>
                                    </table>
                                )
                            :
                            <table>
                                <tr>
                                    <th>사용 중인 서비스</th>
                                    <td>{props.prices[0].data.orderName}</td>
                                </tr>
                                <tr>
                                    <th>사용 기한</th>
                                    <td>{YyyyMmDdHhMmSs(new Date(props.prices[0].Order_id.NextOrder).setHours(new Date(props.prices[0].Order_id.NextOrder).getHours(props.prices[0].Order_id.NextOrder)+1, 0, 0))}</td>
                                </tr>
                                {props.prices[0].Order_id.State === 9 ?
                                    <tr>
                                        <th>서비스 신청</th>
                                        <td style={{ paddingTop: '8px', paddingBottom: '8px' }}><Button size='small' variant='outlined' color='warning' disabled={true}>신청</Button></td>
                                    </tr>:
                                    props.prices[0].Order_id.OrderCancel ?
                                        <tr>
                                            <th>서비스 해지 취소</th>
                                            <td style={{ paddingTop: '8px', paddingBottom: '8px' }}><Button size='small' variant='outlined' color='warning' onClick={() => props.setOpen(true)}>취소</Button></td>
                                        </tr>:
                                        <tr>
                                            <th>서비스 해지 신청</th>
                                            <td style={{ paddingTop: '8px', paddingBottom: '8px' }}><Button size='small' variant='outlined' color='warning' onClick={() => props.setOpen(true)}>해지</Button></td>
                                        </tr>
                                }
                            </table>}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>결제 내역</h2>
                </Grid>
                <Grid item xs={12} md={10}>
                    <Stack>
                        <div className='student_propose_list'>
                            <table style={{ textAlign: 'center' }}>
                                <tr>
                                    <th style={{ padding: '8px 4px' }} colSpan={1}>상태</th>
                                    <th style={{ padding: '8px 4px' }} colSpan={1}>상품명</th>
                                    <th style={{ padding: '8px 4px' }} colSpan={1}>결제 금액</th>
                                    <th style={{ padding: '8px 4px' }} colSpan={1}>결제 수단</th>
                                    <th style={{ padding: '8px 4px' }} colSpan={1}>결제 일시</th>
                                    <th style={{ padding: '8px 4px' }} colSpan={1}>이용기간</th>
                                </tr>
                                {props.prices[0].Order_id.State ?
                                props.prices.map((price, i) => (
                                <tr style={{ color: new Date(price.Order_id.NextOrder) > now ? '#000' : '#A4A4A4' }}>
                                    <td style={{ padding: '8px 4px' }} >{new Date(price.Order_id.NextOrder) > now ? '구독중' : '만료'}</td>
                                    <td style={{ padding: '8px 4px' }} >{price.data.orderName}</td>
                                    <td style={{ padding: '8px 4px' }} >{price.data.totalAmount.toLocaleString()} 원</td>
                                    <td style={{ padding: '8px 4px' }} >{price.data.method}</td>
                                    <td style={{ padding: '8px 4px' }} >{YyyyMmDdHhMmSs(price.Order_id.createdAt)}</td>
                                    <td style={{ padding: '8px 4px' }} >{YyyyMmDdHhMmSs(new Date(price.Order_id.NextOrder).setHours(new Date(price.Order_id.NextOrder).getHours(price.Order_id.NextOrder)+1, 0, 0))} 까지</td>
                                </tr>
                                )):
                                <tr>
                                    <td colSpan={6} style={{ color: '#A4A4A4' }}>결제 내역이 없습니다.</td>    
                                </tr>}
                            </table>
                        </div>
                        <div style={{ width: '100%', position: 'relative'}}>
                            <Pagination onChange={handleChangePage} page={page} count={maxPage} color='warning' style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 25px)', height: '45px'}}/>
                        </div>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
};

export default OrderView;