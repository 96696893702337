import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { Layout } from "components/Academy/Layout";
import { TicketView } from "components/Academy/mockExam";
import { MockExamTicketModal } from 'components/modal';
import * as ChangeText from 'components/function/ChangeText';

const Ticket = () => {
  const [cookies] = useCookies(['LoginKey','UserNo']);
  const LoginKey = cookies.LoginKey;
  const UserNo = parseInt(cookies.UserNo) 
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [ticketUpdate, setTicketUpdate] = useState(false);
  const [teacherList,setTeacherList] = useState()
  const [teacher, setTeacher] = useState({ teacher: '', count: 0 });
  const [search, setSearch] = useState('');
  const [tickets, setTickets] = useState([]);
  const [ticketCount, setTicketCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTeacher({ count: 0 });
  };
  
  const loadTicketData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/ticket/${LoginKey}`, config).then((Response) => {
      let loadTickets = Response.data.Tickets;
      const useTicketCount = loadTickets[loadTickets.length-1].Teachers.reduce((a, b) => (a + b.Count), 0);
      setTicketCount((loadTickets[loadTickets.length-1].Count - useTicketCount) - loadTickets[loadTickets.length-1].Students.length);  
      setTickets(loadTickets);
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const loadTeacherData = async () =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${LoginKey}`, config).then((Response) => {
      setTeacherList(Response.data.Teachers)
    });
  };


  const deliverTicket = async () => {
    const count = parseInt(teacher.count)
    if (tickets.length > 0 && tickets[0].Count > 0) {
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify({teacherNo:teacher.teacherNo,count:count,loginKey:LoginKey,orderId:tickets[0].Order_id._id});
      await axios.post(`/api/academy/ticket`, body, config).then((Response) => {
      setTeacher({ count: 0 });
      handleClose()
      enqueueSnackbar(`응시권을 전달하였습니다`, { variant: 'info' });
    }) 
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    } else {
      alert("응시권이 없습니다");
    }
  };

  const handleTeacherDataChange = (data) => {
    handleOpen()
    let updatedTeacher = {...data ,count:teacher.count};
    setTeacher(updatedTeacher)
};


const onChangeTicket = (event) => {
  let updatedTeacher = {...teacher};
  let { value } = event.target;
  const parsedValue = parseInt(value);
  updatedTeacher.count = parsedValue;
  setTeacher(updatedTeacher)
};

const ticketUp = () => {
  let updatedTeacher = {...teacher};
  updatedTeacher.count = updatedTeacher.count + 1; // 1씩 증가
  setTeacher(updatedTeacher)
};

const ticketDown = () => {
  let updatedTeacher = {...teacher};
  if ( updatedTeacher.count > 0) {
    updatedTeacher.count = updatedTeacher.count - 1; 
  }
  setTeacher(updatedTeacher)
};  

const ticketDown2 = () => { // 회수 용 
  const ticketCount = tickets[tickets.length-1]?.Teachers.filter(
    (ticket) => ticket?.Teacher_id?._id === teacher._id
  );
  let updatedTeacher = {...teacher};
  const limit = (ticketCount[0].Teacher_id.Tickets[ticketCount[0].Teacher_id.Tickets.length-1].Count - ticketCount[0].Teacher_id.Tickets[ticketCount[0].Teacher_id.Tickets.length-1].Students.length) + updatedTeacher.count
  if (  limit === 0 ) {
    enqueueSnackbar(`회수 가능한 응시권이 없습니다`, { variant: 'error' });
  }else{
    updatedTeacher.count = updatedTeacher.count - 1; // 1씩 증가
  }
  setTeacher(updatedTeacher)
};  


  const handleTicketUpdate = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({teacherNo:teacher.teacherNo,count:teacher.count,loginKey:LoginKey,orderId:tickets[0].Order_id._id})
    await axios.put('/api/academy/ticket', body, config).then((Response) => {
      handleClose()
      teacher.count >= 0 ?
      enqueueSnackbar(`응시권을 전달 하였습니다`, { variant: 'info' }):
      enqueueSnackbar(`응시권을 회수 하였습니다`, { variant: 'info' })
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
};

const checkData=()=> { // 티켓 전달 내역 확인
  if (teacher._id) {
    for (let i = 0; i < tickets?.length; i++) {
      const teachers = tickets[i].Teachers;
     for (let j = 0; j < teachers?.length; j++) {
      const teacherId = teachers[j].Teacher_id._id;
      if (teacher._id === teacherId) {
        setTicketUpdate(true) 
      }else{
        setTicketUpdate(false)
        }
      }
    }
  }
};

  const rows = [];
  for (let i = 0; i < teacherList?.length; i++) {
    rows.push({
      id: i + 1, 
      name: teacherList[i].Name,
      phone: teacherList[i].Phone,
      email: teacherList[i].Email,
      Profile:teacherList[i].Profile,
      birth: ChangeText.YyyyMmDd(teacherList[i].Birth),
      teacherNo:teacherList[i].UserNo,
      _id:teacherList[i]._id
    });
  };

  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const filterData=rows.filter(row => row.teacherNo !== UserNo)
  const visibleData = filterData?.slice(startIndex, endIndex);

  const handleClickCell = (teacherNo) => {
      navigate(`/academy/teacher/${teacherNo}`);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const filterTeachers = (datas) => {
    datas.sort();
    datas = datas.filter((data) => {
      return (
        data.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        data.email.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    });
    return datas;
  };

  useEffect(()=>{
    checkData()
  },[teacher]);

  useEffect(()=>{
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);
    loadTeacherData()
  },[]);

  useEffect(()=>{
    loadTicketData()
  },[teacher]);

  return (
    <Layout >
     {
      loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
      <TicketView 
        rows={filterTeachers(visibleData)}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        search={search} 
        teacherList={teacherList}
        handleChangeSearch={handleChangeSearch}
        tickets={tickets}
        ticketCount={ticketCount}
        ticketUp={ticketUp}
        handleClickCell={handleClickCell}
        row={rows}
        pageSize={pageSize}
        handleTeacherDataChange={handleTeacherDataChange}
        ticketDown={ticketDown}/>
        }
  

     <MockExamTicketModal 
      handleOpen={handleTeacherDataChange}
      ticketUpdate={ticketUpdate}
      open={open}
      name={teacher.name}
      count={teacher.count}
      ticketUp={ticketUp}
      ticketDown={ticketDown}
      ticketDown2={ticketDown2}
      deliverTicket={deliverTicket}
      handleTicketUpdate={handleTicketUpdate}
      onChangeTicket={onChangeTicket}
      handleClose={handleClose}>
     </MockExamTicketModal>
    </Layout>
   );
}
 
export default Ticket;