import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useParams,useNavigate } from "react-router-dom";

import { Layout } from "components/Academy/Layout";
import { BoardUpdateView } from "components/Academy/board";

const BoardUpdate = () => {
  const { noticesNo }=useParams();
  const [ cookies ] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const[boardInfo,setBoardInfo] = useState({
    title:"",
    content:"",
  });

  const loadBoardData = async () =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/notice/${LoginKey}`, config).then((Response) => {    
      let loadData = Response.data.Notices;
      const filteredData = loadData.filter((list) => list.AcademyNoticeNo === Number(noticesNo));
      setBoardInfo({
       title:filteredData[0].Title,
       content:filteredData[0].Content,
    })
  });
};

  const handleChange = (event) => {
    let changeBoardInfo = { ...boardInfo };
    changeBoardInfo[event.target.name] = event.target.value;
    setBoardInfo(changeBoardInfo);
  };
  
  const condition =
   boardInfo.title.length !== 0 &&
   boardInfo.title !== undefined &&
   boardInfo.content.length !== 0 &&
   boardInfo.content !== undefined;

  const updateBoard =async ()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({...boardInfo,loginKey:LoginKey,academyNoticeNo:noticesNo});
    if (condition) {
      await axios.put('/api/academy/notice', body, config).then((Response) => {
        navigate(-1)
      }).catch((err)=>{})
    }else{
      alert("내용을 입력해주세요");
    }
  };

  useEffect(()=>{
    loadBoardData()
  },[]);


  return (
  <Layout> 
    <BoardUpdateView 
    handleChange={handleChange} 
    boardInfo={boardInfo}
    updateBoard={updateBoard}
    />
  </Layout> 
  );
}
 
export default BoardUpdate;