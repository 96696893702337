import React, { useState, useEffect, useCallback } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const EvaluationPrint = () => {
     window.onafterprint = function(){
         window.close();
     };
    const [cookies] = useCookies(['LoginKey']);
    const { ClassNo, EvaluationNo } = useParams();
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [evaluation, setEvaluation] = useState({
        EvaluationElement: [],
        Students: [{
          Student_id: { Name: '' },
          Name: '',
          Grade: '',
          ClassNumber: '',
          StudentNumber: ''
        }],
        Score: {
            Labels: [],
            Scores: [],
            LabelsScores: []
        }
    });

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/teacher/evaluation/${ClassNo}/${EvaluationNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                console.log(res.data)
                setEvaluation(res.data.EvaluationData);
                setTimeout(() => {
                    setLoading(false);
                    window.print();
               }, [3500]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [enqueueSnackbar, setEvaluation]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className='question_custom_print'>
            {loading ? 
            <div style={{ textAlign: 'center', marginTop: '32px' }}>
                <CircularProgress color='warning'/>
                <div>문서 읽는 중 ...</div>
            </div>:null}
            { 
            evaluation.Students.map((student, i)=>(
            <div className='question_custom_print_container' key={i}>
                <h2 style={{ textAlign: 'center' }}>{evaluation.Title}</h2>
                <div style={{ width: '100%'}}>
                    <table style={{ marginTop: '-2px' }} className='evaluation_table'>
                        <tbody>
                            <tr>
                                <th style={{ width: '10%'}}>소속</th>
                                <td style={{ width: '10%'}}>{student.Grade}학년 {student.ClassNumber}반 {student.ClassNumber}번</td>
                                <th style={{ width: '10%'}}>이름</th>
                                <td style={{ width: '20%'}}>{student.Name} ({student.Student_id.Name})</td>
                                <th style={{ width: '10%'}}>점수</th>
                                <td style={{ width: '15%'}}></td>
                                <th style={{ width: '10%'}}>감독자 확인서명</th>
                                <td style={{ width: '15%'}}></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th>성취기준</th>
                                <td colSpan={7}><div dangerouslySetInnerHTML={{ __html: evaluation.Standard?.replaceAll('\n', '<br/>')}}/></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th>활동</th>
                                <td colSpan={7}>{evaluation.Activity}</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th>체점기준</th>
                                <td colSpan={7}>
                                    {evaluation.Score.LabelsScores.length > 0 ?
                                    <table className='evaluation_table_score'>
                                        <tr>
                                            <td><b>평가항목</b></td>
                                            {evaluation.Score.Scores.map((score, i) => (
                                            <td key={i}><b>{score}</b></td>
                                            ))}
                                        </tr>
                                        {evaluation.Score.Labels.map((label, i) => (
                                        <tr key={i}>
                                            <td><b>{label}</b></td>
                                            {evaluation.Score.Scores.map((_, j) => (
                                            <td>{evaluation.Score.LabelsScores[i][j]}</td>
                                            ))}
                                        </tr>
                                        ))}
                                    </table>:
                                    '-'}
                                </td>
                            </tr>
                        </tbody>
                        {evaluation.EvaluationElement.map((item, j) => (
                        <tbody key={j}>
                            {item.Detail.map((item2, k) => (
                            <tr key={k} style={{ pageBreakInside: 'avoid'}}>
                                {k === 0 ?
                                <th rowSpan={item.Detail.length}>{item.Label}</th> :
                                null}
                                <td >{item2}</td>
                                <td colSpan={6} style={{ textAlign: 'left' }}>
                                    {student.Answer[j][k] ?
                                    <img
                                        src={student.Answer[j][k]} alt='images'
                                        style={{ maxHeight: '310px' }}
                                    />
                                    :<div style={{ height: '310px' }}/>}
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        ))}
                    </table>
                </div>
            </div>
            ))
            }
        </div>    
    );
}

export default EvaluationPrint;