import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import { useCookies } from 'react-cookie';

import * as ChangeText from 'components/function/ChangeText';
import { useParams } from 'react-router-dom';
import { QuestionMemoReview } from 'components/user/question';

const ParentQuestionView = (props) => {
   const [cookies] = useCookies(['ChildNickName']);
   const { ChildNo, Section } = useParams();
   const [openMemoReview, setOpenMemoReview] = useState(false);
   const [memoReview, setMemoReview] = useState('');
   
   const handleClickMemo = (memo) => {
      setMemoReview(memo);
      setOpenMemoReview(true);
   };
   return(
      <div className='content_section'>
         <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} className='diagnosis_result_title'>
               <h2>{ChangeText.Part(Section)} &gt; {ChangeText.MiddlePart(Section)} &gt; {ChangeText.Section(Section)}</h2>
            </Grid>
            <Grid item xs={12} md={10}>
               <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                  <table >
                     <tr>
                        <th>No.</th>
                        <th>정답여부</th>
                        <th>정답률</th>
                        <th>풀이시간</th>
                        <th>평균풀이시간</th>
                        <th>풀이일시</th>
                        <th>메모여부</th>
                        <th></th>
                     </tr>
                     {props.questionDatas[0].Section ? 
                     props.questionDatas.map((questionData, i) => (
                     <tr>
                        <td>{(questionData.Question_id.Question - questionData.Question_id.Section*10000)}</td>
                        <td>{ChangeText.QuestionCheck(questionData.Check)}</td>
                        <td>{ChangeText.Percent(questionData.Question_id.Info.Correct/questionData.Question_id.Info.Counting)}</td>
                        <td>{ChangeText.QuestionMinute(questionData.Time)}</td>
                        <td>{ChangeText.QuestionMinute(questionData.Question_id.Info.Time/questionData.Question_id.Info.Correct)}</td>
                        <td>{ChangeText.YyyyMmDdHhMmSs(questionData.CreatedAt)}</td>
                        <td>{questionData.Memo ? <Link href='javascript:;' onClick={() => handleClickMemo(questionData.Memo)}>O</Link> : 'X'}</td>
                        <td><Link href={`/parent/${ChildNo}/question/${Section}/${questionData.Question_id.Question}`}>상세보기</Link></td>
                     </tr> 
                     )):
                     <tr>
                        <td colSpan={7}>해당 단원은 문제를 풀지 않았습니다.</td>
                     </tr>}
                  </table>
               </div>
            </Grid>
         </Grid>
         <QuestionMemoReview 
            open={openMemoReview}
            setOpen={setOpenMemoReview}
            memoReview={memoReview}
         />
      </div>
   );
};

export default ParentQuestionView;