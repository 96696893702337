import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, TextField, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';

import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';
import { SubmitModal } from 'components/modal';

const EvaluationListView = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [textDisabled, setTextDisabled] = useState(false);

    useEffect(() => {
        if(props.selectEvaluation){
            if(props.selectEvaluation.Students.filter((item) => (item.Student_id === props.userId)).length > 0){
                setTextDisabled(true);
            }else{
                setTextDisabled(false);
            }
        }else{
            setTextDisabled(false);
        }
    }, [props.selectEvaluation]);

    return(
        <>
        <div className='content_section'>
        <Grid container justifyContent='center'>
            <Grid item xs={12} md={10} className='diagnosis_result_title'>
                <h2>수행평가</h2>
            </Grid>
            <Grid item xs={12} md={10}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>이름</TableCell>
                        <TableCell>시작일시</TableCell>
                        <TableCell>생성일시</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.evaluations[0]?.Title ? 
                        props.evaluations.map((item, i) => (
                        <TableRow
                            sx={{ '& td, & th': { borderLeft: 0, borderRight: 0 } }}
                            key={i}
                        >
                            <TableCell component='th' scope='row'>
                                {item.EvaluationNo}
                            </TableCell>
                            {item.State === 1 ?
                            <TableCell><Link className='cursor_pointer' onClick={() => {props.setSelectEvaluation(item);setOpen(true);}}>{item.Title}</Link></TableCell>:
                            <TableCell><Link className='cursor_pointer' onClick={() => enqueueSnackbar('이미 종료된 수행평가입니다.', { variant: 'warning' })} style={{ color: '#5E5E5E'}}>{item.Title}</Link></TableCell>}
                            <TableCell>{YyyyMmDdHhMmSs(item.StartDate)}</TableCell>
                            <TableCell>{YyyyMmDdHhMmSs(item.createdAt)}</TableCell>
                        </TableRow>
                        )):
                        <TableRow>
                            <TableCell colSpan={6} align='center'>
                                수행평가 내역이 없습니다.
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                </TableContainer>
            </Grid>
        </Grid>
        </div>
        {props.selectEvaluation ?
        <SubmitModal
            title={`${props.selectEvaluation.Title} 시작하기`}
            open={open}
            handleClose={() => {setOpen(false);props.setSelectEvaluation();}}
            disabledOption={!Boolean(props.selectEvaluation.Grade && props.selectEvaluation.ClassNumber && props.selectEvaluation.StudentNumber && props.selectEvaluation.Name) && !Boolean(textDisabled) ? 0 : 1}
            handleClickSubmit={props.handleClickStart}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} stlye={{ padding: 0 }}>
                    <p style={{ margin: 0 }}>* 학년, 반, 번호, 이름을 작성해주세요. <b>입력 후 수정이 불가능합니다.</b></p>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 0 }}>
                    <TextField 
                        variant='standard'
                        sx={{ mt: 1, mb: 1 }}
                        fullWidth
                        value={!textDisabled ? props.selectEvaluation.Grade : props.selectEvaluation.Students.filter((item) => (item.Student_id === props.userId))[0].Grade}
                        disabled={textDisabled}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>학년</InputAdornment>,
                        }}
                        onChange={(e) => props.setSelectEvaluation({ ...props.selectEvaluation, Grade: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 0 }}>
                    <TextField 
                        variant='standard'
                        sx={{ mt: 1, mb: 1 }}
                        fullWidth
                        value={!textDisabled ? props.selectEvaluation.ClassNumber : props.selectEvaluation.Students.filter((item) => (item.Student_id === props.userId))[0].ClassNumber}
                        disabled={textDisabled}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>반</InputAdornment>,
                        }}
                        onChange={(e) => props.setSelectEvaluation({ ...props.selectEvaluation, ClassNumber: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 0 }}>
                    <TextField 
                        variant='standard'
                        sx={{ mt: 1, mb: 1 }}
                        fullWidth
                        value={!textDisabled ? props.selectEvaluation.StudentNumber : props.selectEvaluation.Students.filter((item) => (item.Student_id === props.userId))[0].StudentNumber}
                        disabled={textDisabled}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>번</InputAdornment>,
                        }}
                        onChange={(e) => props.setSelectEvaluation({ ...props.selectEvaluation, StudentNumber: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 0 }}>
                    <TextField 
                        variant='standard'
                        sx={{ mt: 1, mb: 1 }}
                        fullWidth
                        placeholder='이름'
                        disabled={textDisabled}
                        value={!textDisabled ? props.selectEvaluation.Name : props.selectEvaluation.Students.filter((item) => (item.Student_id === props.userId))[0].Name}
                        onChange={(e) => props.setSelectEvaluation({ ...props.selectEvaluation, Name: e.target.value })}
                    />
                </Grid>
            </Grid>
        </SubmitModal>:null}
        </>
    );
};

export default EvaluationListView;