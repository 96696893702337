import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';
import { SubmitModal } from 'components/modal';

const StudentTicketRefund = (props) => {
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    let buyTickets = [...props.buyTickets];

    for(let i = 0; i < buyTickets.length; i++){
        buyTickets[i].UsedCount = buyTickets.filter((item) => { return item.TicketOrder_id._id.toString() === buyTickets[i].TicketOrder_id._id.toString() && item.UsedAt }).length;
    }
    buyTickets = buyTickets.filter((item, i) => { //문제풀이 정보의 중복을 제거 => 가장 최근의 문제기록만 남게 됨.
            return (buyTickets.findIndex((item2, j) => {     
                return item.TicketOrder_id._id.toString() === item2.TicketOrder_id._id.toString();
            }) === i
        );
    });

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectKey, setSelectkey] = useState(-1);

    const handleClickOpen = (key) => {
        setSelectkey(key);
        setOpen(true);
    };

    const handleClickRefund = async() => {
        setLoading(true);
        setOpen(false);
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify({
            ticketOrderId: buyTickets[selectKey].TicketOrder_id._id,
            loginKey: cookies.LoginKey
        });
        await axios.put(`/api/student/ticket/refund`, data, config).then((res) => {
            props.setTickets([...props.tickets.filter((item) => { return item.TicketOrder_id ? item.TicketOrder_id._id.toString() !== res.data.ticketOrderId.toString() : true })]);
            enqueueSnackbar('환불이 완료되었습니다.', { variant: 'info' });
            setSelectkey(-1);
            props.setOpenRefund(false);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        });
    };
    
    return(
        <>
        <table className='ticket_refund_table'>
            <thead>
                <tr>
                    <th>No.</th><th>구매 일시</th><th>구매 금액</th><th>구매 / 사용 개수</th><th></th>
                </tr>
            </thead>
            <tbody>
                {buyTickets.map((ticket, i) => (
                <tr>
                    <td>{buyTickets.length - i}</td>
                    <td>{YyyyMmDdHhMmSs(ticket.TicketOrder_id.CreatedAt)}</td>
                    <td>{ticket.TicketOrder_id.Price.toLocaleString()} 원</td>
                    <td>{ticket.TicketOrder_id.Count} / {ticket.UsedCount} 개</td>
                    <td>
                        <LoadingButton
                            color='error'
                            loading={loading}
                            disabled={
                                ticket.UsedCount ||
                                new Date(new Date(ticket.TicketOrder_id.CreatedAt).setDate(new Date(ticket.TicketOrder_id.CreatedAt).getDate()+7)) < new Date() ||
                                loading
                            }
                            onClick={() => handleClickOpen(i)}
                        >
                            환불
                        </LoadingButton>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
        <div>
            * 구매 후 7일 이상 경과하거나, 사용 이력이 있는 응시권은 환불이 불가능합니다.
        </div>
        <SubmitModal
            open={open}
            handleClose={() => setOpen(false)}
            title={'응시권 환불 확인'}
            handleClickSubmit={handleClickRefund}
        >
            응시권 환불을 이어서 진행합니다.
        </SubmitModal>
        </>
    );
};

export default StudentTicketRefund;