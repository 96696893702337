import React, { useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';

const StudentTicketSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [cookies] = useCookies(['LoginKey']);
    const PriceSuccess = async() => {
        if(location.search){
            const locationSplit = location.search.split('&');
            let key = [];
            for(let i = 0; i < locationSplit.length; i++){
                key.push(locationSplit[i].split('=')[1]);
            }
            
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({
                orderId: key[0],
                paymentKey: key[1],
                price: key[2],
                loginKey: cookies.LoginKey
            });
            await axios.put(`/api/student/ticket`, body, config).then((Response) => {
                if(Response.data.Message){
                    enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                    navigate(`/user/ticket`, { replace: true });
                }else{
                    navigate(`/user/ticket/fail`, { replace: true });    
                }
            }).catch((Error) => {
                navigate(`/user/ticket/fail`, { replace: true });
            });
        }else{
            navigate(`/user/ticket/fail`, { replace: true });
        }
    };
    useEffect(() => {
        PriceSuccess();
    }, []);
    return (
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        <CircularProgress color="inherit" />
        <div style={{ position: 'absolute', top: '55%', fontSize: '17px'}}>결제 진행중 입니다.</div>
    </Backdrop>
    );
};

export default StudentTicketSuccess;