import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { EvaluationList, EvaluationDetail, EvaluationCreate, EvaluationUpdate, EvaluationPrint } from 'domain/teacher/evaluation';

const EvaluationPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<EvaluationList/>}/>
      <Route path=':EvaluationNo' element={<EvaluationDetail/>}/>
      <Route path='create' element={<EvaluationCreate/>}/>
      <Route path=':EvaluationNo/print' element={<EvaluationPrint/>}/>
      <Route path=':EvaluationNo/update' element={<EvaluationUpdate/>}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
  );
};

export default EvaluationPresenter;