import React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText, Avatar, ListItemAvatar, Checkbox } from '@mui/material';

import WoorotestLogoOnly from 'img/woorotest_logo_only.png'
import { Attending, Grade } from 'components/function/ChangeText';

const TicketStudentListView = (props) => {
    const studentCheckedCnt = props.studentDatas.filter((item) => { return item.Checked === true }).length;

    return(
        <Box
            sx={{ width: '100%' }}
        >
            <List>
                <ListItem
                    secondaryAction={
                        <Checkbox
                            edge='end'
                            color='warning'
                            onChange={props.handleClickStudentAll}
                            checked={studentCheckedCnt === props.studentDatas.length}
                            disabled={props.ticketData?.Count - props.ticketData?.Students.length < props.studentDatas?.length}
                        />
                    }
                    disablePadding
                >
                    <ListItemButton
                        onClick={props.handleClickStudentAll}
                        disabled={props.ticketData?.Count - props.ticketData?.Students.length  < props.studentDatas?.length}
                    >
                        <ListItemAvatar>
                            
                        </ListItemAvatar>
                        <ListItemText
                            primary={'전체 선택'}
                        />
                    </ListItemButton>
                </ListItem>
                <hr/>
                {props.studentDatas.map((item, i) => (
                <ListItem
                    key={i}
                    secondaryAction={
                        <Checkbox
                            edge='end'
                            color='warning'
                            onChange={() => props.handleClickStudent(i)}
                            checked={item.Checked ? true : false}
                            disabled={props.ticketData.Count - props.ticketData.Students.length - studentCheckedCnt <= 0 && !item.Checked}
                        />
                    }
                    disablePadding
                >
                    <ListItemButton
                        onClick={() => props.handleClickStudent(i)}
                        disabled={props.ticketData.Count - props.ticketData.Students.length - studentCheckedCnt <= 0 && !item.Checked}
                        >
                        <ListItemAvatar>
                            <Avatar
                                alt={`Profile Image ${i}`}
                                src={item.Student_id.Profile ? item.Student_id.Profile : WoorotestLogoOnly}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={item.Student_id.Name}
                            secondary={`${item.Student_id.School_id.Name} · ${item.Student_id.Attending > 1 ? Attending(item.Student_id.Attending) : Grade(item.Student_id.Grade)}`}
                        />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
            <hr/>
            <table className='teacher_ticket_count_table'>
                <tbody>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'right', padding: '0px' }}>· 배부 후 응시권 회수가 불가능합니다.</td>
                    </tr>
                    <tr>
                        <th>현재 보유중인 응시권</th><td>{props.ticketData ? props.ticketData.Count - props.ticketData.Students.length : 0} 개</td>
                    </tr>
                    <tr>
                        <th>응시권 배부 수</th><td>{studentCheckedCnt} 개</td>
                    </tr>
                    <tr>
                        <th>배부 후 응시권</th><td>{props.ticketData ? props.ticketData.Count - props.ticketData.Students.length - studentCheckedCnt : 0} 개</td>
                    </tr>
                </tbody>
            </table>
        </Box>
    );
};

export default TicketStudentListView;