import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { SchoolDetailUpdate } from 'components/admin/school';


const SchoolUpdate = () => {
    const pages = [
        { title: '학교 관리'},
        { title: '학교 목록'},
        { title: '학교 정보 수정'},
    ];
    const title = { main: '학교 정보 수정', sub: 'school'};
    const [school, setSchool] = useState({
        NEIS_code: '',
        Name: '',
        Local: '',
        County: '',
        WooroRank: '',
        WooroZscore: ''
    });
    const [updating, setUpdating] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);

    // 데이터 로드
    useEffect(() => {
        const loadSchool = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/school/${id}/${cookies.LoginKey}`, config).then((Response) => {
                setSchool(Response.data);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadSchool();
    },[]);

    const handleChange = (event) => {
        let changeSchool = {...school};
        changeSchool[event.target.name] = event.target.value;
        setSchool(changeSchool);
    };
    const handleClickUpdate = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            SchoolId: school._id,
            NEIS_code: school.NEIS_code,
            Name: school.Name,
            Local: school.Local,
            County: school.County,
            WooroRank: school.WooroRank,
            WooroZscore: school.WooroZscore,
            State: school.State,
            loginKey: cookies.LoginKey
        });
        await axios.put(`/api/admin/school`, body, config).then((Response) => {
            setTimeout(()=> {
                navigate(`/admin/school/${id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };
    return (
        <Layout pages={pages} title={title}>
            <SchoolDetailUpdate
                school={school}
                handleChange={handleChange}
                updating={updating}
                handleClickUpdate={handleClickUpdate}
            />
        </Layout>
    )
};

export default SchoolUpdate;