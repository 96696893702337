import React from 'react';
import { Box, List, ListItemButton, ListItemText, CircularProgress } from '@mui/material';

const StudentSchoolList = (props) => {
    // 필터
    const filterSchools = (datas) => {
        if(props.search){
            datas.sort();
            datas = datas.filter((data) => {
                return data.Name.toString().toLowerCase().indexOf(props.search.toLowerCase()) > -1
            });
            return datas
        }else{
            return [{
                Name: false,
                Local: false,
                County: false
            }]
        }
    };
    return(
        <Box
            sx={{ width: '100%', height: 400,  bgcolor: 'background.paper' }}
        >
            <List>
                {props.loading ?
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <CircularProgress color='primary'/>
                </div>
                :
                filterSchools(props.schools).map((school) =>(
                school.Name ?
                <ListItemButton onClick={() => props.handleClickSchool(school)} value={school}>
                    <ListItemText primary={school.Name} secondary={`${school.Local} ${school.County}`}/>
                </ListItemButton>:
                <div style={{textAlign: 'center', marginTop: '30px'}}>
                    학교 이름으로 검색해 주세요.
                </div>
                ))}
            </List>
        </Box>
    );
};

export default StudentSchoolList;