import React from 'react';
import AdminStudentPresenter from './AdminStudentPresenter';

const AdminStudentContainer = () => {
  return (
    <AdminStudentPresenter />
  );
};

export default AdminStudentContainer;
