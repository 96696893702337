import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalActivityOutlined } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import tossKey from 'config/toss';

const StudentPrice = () => {
    const location = useLocation();
    const ticketPrice = [
        {
            Count: 1,
            Price: 1100
        },{
            Count: 7,
            Price: 5500
        },{
            Count: 15,
            Price: 11000
        }
    ];
    const clientKey = tossKey;
    const [cookies] = useCookies(['LoginKey']);
    const [creating, setCreating] = useState(false);
    const tossPayments = window.TossPayments(clientKey) // 클라이언트 키로 초기화하기

    const handleClickPrice = async(key) => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify({
            price: ticketPrice[key].Price,
            count: ticketPrice[key].Count,
            orderName: `모의고사 응시권 ${ticketPrice[key].Count}개`,
            loginKey: cookies.LoginKey
        });
        await axios.post(`/api/student/ticket`, data, config).then((res) => {
            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: res.data.result.Price,
                orderId: res.data.result._id,
                orderName: res.data.result.OrderName,
                customerName: res.data.userName,
                successUrl: location.search.indexOf('loginKey') > -1 ? `${window.location.origin}/user/ticket/success/app` : `${window.location.origin}/user/ticket/success`,
                failUrl: location.search.indexOf('loginKey') > -1 ? `${window.location.origin}/user/ticket/fail/app` : `${window.location.origin}/user/ticket/fail`,
            })
            .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    console.log('결제창 닫음');
                    // 결제 고객이 결제창을 닫았을 때 에러 처리
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    console.log('유효하지 않은 카드');
                    // 유효하지 않은 카드 코드에 대한 에러 처리
                }
                console.log(error)
            });
        }).catch((err) => {
            console.log(err)
        });
    };

    return(
        <>
        <h4 style={{ textAlign: 'center' }}>모의고사 응시권 구매</h4>
        {ticketPrice.map((item, i) => (
        <Grid container justifyContent='center' alignItems='center' style={{ marginBottom: '16px' }}>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <LocalActivityOutlined/>
            </Grid>
            <Grid item xs={7}>응시권 <b>{item.Count}</b>개</Grid>
            <Grid item xs={3}>
                <LoadingButton loading={creating} disableElevation color='warning' variant='contained' fullWidth size='small' onClick={() => handleClickPrice(i)}>{item.Price.toLocaleString('ko-KR')}원</LoadingButton>
            </Grid>
        </Grid>
        ))}
        </>
    );
};

export default StudentPrice;