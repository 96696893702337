import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { AchieveCreateView } from "components/admin/Achieve";
import { Layout } from "components/admin/layout";
const AchieveCreate = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();

    const [imgBase64, setImgBase64] = useState();
    const [imgFile, setImgFile] = useState(null);
    const [achieveInfo, setAchieveInfo] = useState({
      name: '',
      terms : 100,
      level : 0,
      loginKey: cookies.LoginKey
  });

    const handleChange = (event) => {
        setAchieveInfo({ ...achieveInfo, name: event.target.value});
    };
    const handleChangeLevel = (event) => {
      setAchieveInfo({ ...achieveInfo, level: event.target.value});
    };  

    const handleClickDeletePicture = () => {
      let DeletePicture = { ...achieveInfo };
      DeletePicture.Picture = '';
      setAchieveInfo(DeletePicture);
      setImgBase64('');
      setImgFile('');
    };

    const handleClickCreate = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      const body = JSON.stringify(achieveInfo);
      if(achieveInfo.level > 0 && achieveInfo.name){
        await axios.post(`/api/achieve`, body, config).then((Response) => {
            uploadPicture(Response.data.result._id);
            handleClickDeletePicture();
            setAchieveInfo({name:"",terms : 100,level : 0,loginKey: cookies.LoginKey})
            enqueueSnackbar(`등록되었습니다`, { variant: 'info' });
      }).catch((Error) => {
          console.log(Error);
      });
    }else{
      enqueueSnackbar(`내용을 입력해 주세요`, { variant: 'error' });
    }
  };

  

  const handleChangePicture = (event) => { //이미지 업로드용
    let reader = new FileReader();
    reader.onloadend = () => {
        // 2. 읽기가 완료되면 아래코드가 실행됩니다.
        const base64 = reader.result;
        if (base64) {
            setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
        }
    }
    if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
        setImgFile(event.target.files[0]); // 파일 상태 업데이트
    }
};

  const uploadPicture = async(ID) => {
      if(imgFile){
          const formData = new FormData();
          formData.append('AchieveId', ID);
          formData.append('imgFile', imgFile);
          formData.append('loginKey', cookies.LoginKey);
          const config = {headers: {'content-type': 'multipart/form-data'}};
          await axios.put(`/api/achieve/image`, formData, config).then((Response) => {
              // console.log(Response);
          }).catch((Error) => {
              console.log(Error);
          });
      }else{
          return false
      }
  };

 

  const pages = [
    { title: '업적 관리'},
    { title: '업적 생성'}
];
const title = { main: '업적 생성', sub: 'Achieve'};

  return ( 
    <Layout pages={pages} title={title}>
      <AchieveCreateView 
      handleChangePicture={handleChangePicture}
      handleClickDeletePicture={handleClickDeletePicture}
      handleChangeLevel={handleChangeLevel}
      uploadPicture={uploadPicture} 
      handleClickCreate={handleClickCreate} 
      imgBase64={imgBase64} 
      handleChange={handleChange}
      achieveInfo={achieveInfo}
      /> 
  </Layout> 
  );
}
export default AchieveCreate;