import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const ResetPasswordModal = (props) => {
    return(
        <div>
            <Dialog
            open={props.openResetPassword}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.handleClickCloseResetPassword}
            aria-describedby='alert-dialog-slide-description-ResetPassword'
            fullWidth={true}
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    onClick={props.handleClickCloseResetPassword}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClickCloseResetPassword}>취소</Button>
                <Button onClick={props.handleClickCloseResetPassword}>확인</Button>
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default ResetPasswordModal;