import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { StudentList, StudentInfo, StudentUpdate } from 'domain/admin/student';

import NotFound from 'routes/NotFound';

const AdminStudentPresenter = () => {
    return(
        <Routes>
            <Route path='' element={<StudentList />} />
            <Route path=':id' element={<StudentInfo />} />
            <Route path=':id/update' element={<StudentUpdate />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}

export default AdminStudentPresenter;