/* 각 단원별 분류 */
export const QuestionSection = (userQuestions) => {
    let a111 = []; let a112 = []; let a113 = [];
    let a121 = []; let a122 = []; let a123 = [];
    let a131 = []; let a132 = []; let a133 = []; let a134 = [];
    let a141 = []; let a142 = [];
    let a151 = []; let a152 = [];
    let a161 = []; let a162 = [];
    let a211 = []; let a212 = [];
    let a221 = [];
    let a231 = []; let a232 = []; let a233 = [];
    let a311 = []; let a312 = [];
    let a321 = []; let a322 = []; let a323 = [];
    let a331 = []; let a332 = []; let a333 = [];
    let a411 = []; let a412 = [];
    let a421 = []; let a422 = [];
    let a431 = []; let a432 = [];
    let a511 = []; let a512 = [];
    let a521 = []; let a522 = []; let a523 = [];
    let a531 = []; let a532 = [];
    let a611 = []; let a612 = [];
    let a621 = []; let a622 = [];
    let a631 = []; let a632 = [];

    for(let i=0; i<userQuestions.length; i++){
        if(userQuestions[i].Section===111){
            a111.push(userQuestions[i]);
        }else if(userQuestions[i].Section===112){
            a112.push(userQuestions[i]);
        }else if(userQuestions[i].Section===113){
            a113.push(userQuestions[i]);
        }else if(userQuestions[i].Section===121){
            a121.push(userQuestions[i]);
        }else if(userQuestions[i].Section===122){
            a122.push(userQuestions[i]);
        }else if(userQuestions[i].Section===123){
            a123.push(userQuestions[i]);
        }else if(userQuestions[i].Section===131){
            a131.push(userQuestions[i]);
        }else if(userQuestions[i].Section===132){
            a132.push(userQuestions[i]);
        }else if(userQuestions[i].Section===133){
            a133.push(userQuestions[i]);
        }else if(userQuestions[i].Section===134){
            a134.push(userQuestions[i]);
        }else if(userQuestions[i].Section===141){
            a141.push(userQuestions[i]);
        }else if(userQuestions[i].Section===142){
            a142.push(userQuestions[i]);
        }else if(userQuestions[i].Section===151){
            a151.push(userQuestions[i]);
        }else if(userQuestions[i].Section===152){
            a152.push(userQuestions[i]);
        }else if(userQuestions[i].Section===161){
            a161.push(userQuestions[i]);
        }else if(userQuestions[i].Section===162){
            a162.push(userQuestions[i]);
        }else if(userQuestions[i].Section===211){
            a211.push(userQuestions[i]);
        }else if(userQuestions[i].Section===212){
            a212.push(userQuestions[i]);
        }else if(userQuestions[i].Section===221){
            a221.push(userQuestions[i]);
        }else if(userQuestions[i].Section===231){
            a231.push(userQuestions[i]);
        }else if(userQuestions[i].Section===232){
            a232.push(userQuestions[i]);
        }else if(userQuestions[i].Section===233){
            a233.push(userQuestions[i]);
        }else if(userQuestions[i].Section===311){
            a311.push(userQuestions[i]);
        }else if(userQuestions[i].Section===312){
            a312.push(userQuestions[i]);
        }else if(userQuestions[i].Section===321){
            a321.push(userQuestions[i]);
        }else if(userQuestions[i].Section===322){
            a322.push(userQuestions[i]);
        }else if(userQuestions[i].Section===323){
            a323.push(userQuestions[i]);
        }else if(userQuestions[i].Section===331){
            a331.push(userQuestions[i]);
        }else if(userQuestions[i].Section===332){
            a332.push(userQuestions[i]);
        }else if(userQuestions[i].Section===333){
            a333.push(userQuestions[i]);
        }else if(userQuestions[i].Section===411){
            a411.push(userQuestions[i]);
        }else if(userQuestions[i].Section===412){
            a412.push(userQuestions[i]);
        }else if(userQuestions[i].Section===421){
            a421.push(userQuestions[i]);
        }else if(userQuestions[i].Section===422){
            a422.push(userQuestions[i]);
        }else if(userQuestions[i].Section===431){
            a431.push(userQuestions[i]);
        }else if(userQuestions[i].Section===432){
            a432.push(userQuestions[i]);
        }else if(userQuestions[i].Section===511){
            a511.push(userQuestions[i]);
        }else if(userQuestions[i].Section===512){
            a512.push(userQuestions[i]);
        }else if(userQuestions[i].Section===521){
            a521.push(userQuestions[i]);
        }else if(userQuestions[i].Section===522){
            a522.push(userQuestions[i]);
        }else if(userQuestions[i].Section===523){
            a523.push(userQuestions[i]);
        }else if(userQuestions[i].Section===531){
            a531.push(userQuestions[i]);
        }else if(userQuestions[i].Section===532){
            a532.push(userQuestions[i]);
        }else if(userQuestions[i].Section===611){
            a611.push(userQuestions[i]);
        }else if(userQuestions[i].Section===612){
            a612.push(userQuestions[i]);
        }else if(userQuestions[i].Section===621){
            a621.push(userQuestions[i]);
        }else if(userQuestions[i].Section===622){
            a622.push(userQuestions[i]);
        }else if(userQuestions[i].Section===631){
            a631.push(userQuestions[i]);
        }else if(userQuestions[i].Section===632){
            a632.push(userQuestions[i]);
        }
    }
    let sortList ={
        1 : {
            11 : {
                111 : a111,
                112 : a112,
                113 : a113,
            },
            12 : {
                121 : a121,
                122 : a122,
                123 : a123
            },
            13 : {
                131 : a131,
                132 : a132,
                133 : a133,
                134 : a134
            },
            14 : {
                141 : a141,
                142 : a142
            },
            15 : {
                151 : a151,
                152 : a152
            },
            16 : {
                161 : a161,
                162 : a162
            }
        },
        2 : {
            21 : {
                211 : a211,
                212 : a212
            },
            22 : {
                221 : a221
            },
            23 : {
                231 : a231,
                232 : a232,
                233 : a233
            }
        },
        3 : {
            31 : {
                311 : a311,
                312 : a312
            },
            32 : {
                321 : a321,
                322 : a322,
                323 : a323
            },
            33 : {
                331 : a331,
                332 : a332,
                333 : a333
            }
        },
        4 : {
            41 : {
                411 : a411,
                412 : a412
            },
            42 : {
                421 : a421,
                422 : a422
            },
            43 : {
                431 : a431,
                432 : a432
            }
        },
        5 : {
            51 : {
                511 : a511,
                512 : a512
            },
            52 : {
                521 : a521,
                522 : a522,
                523 : a523
            },
            53 : {
                531 : a531,
                532 : a532
            }
        },
        6 : {
            61 : {
                611 : a611,
                612 : a612
            },
            62 : {
                621 : a621,
                622 : a622
            },
            63 : {
                631 : a631,
                632 : a632
            }
        }       
    }
    return sortList
};