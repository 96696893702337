import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { QuestionSection } from 'components/function/SortQuestions';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionReview } from 'components/user/question';
import { useSnackbar } from 'notistack';
import { ReviewInfo } from 'components/info';

const Review = () => {
  const [cookies] = useCookies(['LoginKey']);
  const { enqueueSnackbar } = useSnackbar();

  const [reviewAll, setReviewAll] = useState({});
  const [reviewWrong, setReviewWrong] = useState({});
  const [reviewSave, setReviewSave] = useState({});
  const [partDisabled, setPartDisabled] = useState([0, 0, 0, 0, 0, 0]);

  const loginKey = cookies.LoginKey;
  /* 유저의 문제풀이정보를 가져오는 함수 */
  const getUserQuestionsInfo = async () => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/question/info/${loginKey}`, config).then((Response) => {
      let solveQuestions = [];  //유저가 풀이한 문제의 정보
      let wrongQuestions = [];  //유저가 틀린 문제의 정보
      let savedQuestions = [];  //유저가 저장한 문제의 정보
      if(!Response.data.Message){
        for (let i = 0; i < Response.data.result.User_id.Questions.length; i++){
          if(Number(Response.data.result.User_id.Questions[i].Status) !== 5){
            solveQuestions.push({
              Question_id: Response.data.result.User_id.Questions[i].Question_id._id,
              QuestionNo: Number(Response.data.result.User_id.Questions[i].Question_id.Question),
              Check: Number(Response.data.result.User_id.Questions[i].Check),
              Status: Number(Response.data.result.User_id.Questions[i].Status),
              Section: Number(Response.data.result.User_id.Questions[i].Question_id.Section),
              Time: Number(Response.data.result.User_id.Questions[i].Time),
              CreatedAt: Response.data.result.User_id.Questions[i].CreatedAt
            });
          }
          if (Number(Response.data.result.User_id.Questions[i].Status) === 5) {
            savedQuestions.push({
              Question_id: Response.data.result.User_id.Questions[i].Question_id._id,
              QuestionNo: Number(Response.data.result.User_id.Questions[i].Question_id.Question),
              Status: Number(Response.data.result.User_id.Questions[i].Status),
              Section: Number(Response.data.result.User_id.Questions[i].Question_id.Section),
              CreatedAt: Response.data.result.User_id.Questions[i].CreatedAt
            });
          } 
        }
      }
      let temp = [...partDisabled];
      for(let i = 1; i < 7; i++){
        for(let j = 0; j < savedQuestions.length; j++){
          if(Math.floor(savedQuestions[j].Section/100) === i){
            temp[i-1] = 1;
            break;
          }
        }
        for(let j = 0; j < solveQuestions.length; j++){
          if(Math.floor(solveQuestions[j].Section/100) === i){
            temp[i-1] = 1;
            break;
          }
        }
      }
      setPartDisabled(temp);

      let solveQuestionsTemp = [];
      solveQuestions.sort(function (a, b) {
        return new Date(b.CreatedAt) - new Date(a.CreatedAt); //문제풀이 정보를 최신순으로 정렬
      });

      solveQuestionsTemp = solveQuestions.filter((item, i) => {
        return (solveQuestions.findIndex((item2, j) => { return item.Question_id === item2.Question_id }) === i)  //유저가 풀이한 문제들의 중복을 없애줌
      });
      
      wrongQuestions = solveQuestionsTemp.filter((item, i) => {
        return item.Check === 2;
      });
      
      
      let tempReviewAll = QuestionSection(solveQuestionsTemp);
      let tempReviewWrong = QuestionSection(wrongQuestions);
      let tempReviewSave = QuestionSection(savedQuestions);
      
      setReviewAll(tempReviewAll);
      setReviewWrong(tempReviewWrong);
      setReviewSave(tempReviewSave);
    })
    .catch((Error) => {
      setReviewAll(QuestionSection([]));
      setReviewWrong(QuestionSection([]));
      setReviewSave(QuestionSection([]));
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(() => {
    getUserQuestionsInfo(); //  처음 접속 시에 문제풀이 정보 저장
  }, []);

  return (
    <Layout>
      {loginKey ? <QuestionReview 
        reviewAll={reviewAll}
        reviewWrong={reviewWrong}
        reviewSave={reviewSave}
        partDisabled={partDisabled}
      /> : <ReviewInfo/>}
    </Layout>
  );
};

export default Review;
