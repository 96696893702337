import React, { useEffect, useState } from 'react';
import { Button, Grid, Link } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import * as ChangeText from 'components/function/ChangeText';

const StudentQuestionInfo = (props) => {
    const [openQuestion, setOpenQuestion] = useState(false);
    const handleClickView = () => {
        setOpenQuestion(!openQuestion);
    };

    return(
        <>
            <Grid container justifyContent='space-between'>
                <Grid>
                    <h3 className='info_title'> · 문제 풀이 정보</h3>
                </Grid>
            </Grid>
            <div className='info_table'>
                <table>
                    <tbody>                    
                        <tr>
                            <th>No.</th><th>정답 여부</th><th>선택한 답</th><th>풀이 시간</th><th>메모 여부</th><th>풀이 유형</th><th>생성일시</th><th>삭제</th>
                        </tr>
                        {props.questions.map((question, i) => (
                            i < 5 || openQuestion ?
                            <tr>
                                <td><Link href={`/admin/question/${question?.Question_id?._id}`}>{question?.Question_id?.Question}</Link></td>
                                <td>{ChangeText.QuestionCheck(question.Check)}</td>
                                <td>{question.Select}</td>
                                <td>{ChangeText.QuestionMinute(question.Time)}</td>
                                <td>{question.Memo ? '보기' : '-'}</td>
                                <td>{ChangeText.QuestionStatus(question.Status)}</td>
                                <td>{ChangeText.YyyyMmDdHhMmSs(question.CreatedAt)}</td>
                                <td className='info_table_button_cell'><Button variant='contained' color='error' onClick={() => props.handleClickOpenDeleteQuestion(i)}>삭제</Button></td>
                            </tr>
                            : null
                        ))}
                        {props.questions.length > 5 ? 
                            !openQuestion ? 
                                <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>전체 보기 <ExpandMore fontSize='small'/></td></tr>
                            :   <tr><td colSpan={8} className='info_table_all_line click_option' onClick={handleClickView}>닫기 <ExpandLess fontSize='small'/></td></tr>
                        :   props.questions.length === 0 ?
                                <tr><td colSpan={8} className='info_table_all_line'>문제 풀이 정보가 없습니다.</td></tr>
                            : null}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default StudentQuestionInfo;