import { Button,Avatar ,Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow } from "@mui/material";


const TeacherListView = (props) => {

  return (
    <Grid item={true}  lg={11.8} xl={12}>
     <TableContainer>
      <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
        <TableHead className="academy_table_head">
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell align="left">프로필</TableCell>
            <TableCell align="center">이름</TableCell>
            <TableCell align="center">전화번호</TableCell>
            <TableCell align="center">이메일</TableCell>
            <TableCell align="center" >생년월일</TableCell>
            <TableCell align="center" width={'50px'}>수정/삭제</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="academy_table">
          {props.rows.map((row, i) => (
            <TableRow key={i} sx={{marginBottom: '10px'  }}>
              <TableCell component="th" scope="row">
                {i + 1}
              </TableCell>
              <TableCell align="center">
                <Avatar src={row.profile} onClick={() => {props.handleClickCell(row.userNo) }} sx={{cursor:"pointer"}}/>
              </TableCell>
              <TableCell align="center" onClick={() => { props.handleClickCell(row.userNo) }} sx={{cursor:"pointer",color:"#1976d2"}}>{row.name}</TableCell>
              <TableCell align="center">{row.phone}</TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center">{row.birth}</TableCell>
              <TableCell align="right" width={'50px'}>
                <Button
                 onClick={() => props.getUpdateData(row.userNo)} 
                 style={{ marginLeft: 16,width:"90px",height:"34px",color:"#F05D38",border:"1px solid #F05D38",borderRadius: "6px",background:"#FFFFFF",fontSize: "15px",'&:hover': { background:"#FFFFFF"} }}>
                  수정
                </Button>
                <Button variant="contained" disableElevation size="small" style={{marginLeft: 16,width:"90px",height:"34px",color:"#FFFFFF",background:"#F05D38",borderRadius: "6px",fontSize: "15px" }} onClick={props.handleClickOpenDeleteStudent}>
                  삭제
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
     </TableContainer>
    </Grid>
  );
};

export default TeacherListView;
