import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {  CircularProgress } from '@mui/material';
import{ HomeworkManageInfo } from 'components/info'
import { Layout } from 'components/teacher/layout';
import { TeacherHomeworkManage } from 'components/teacher/class/homework';

const HomeworkManage = () => {
  const [cookies] = useCookies(['LoginKey']);
  const LoginKey = cookies.LoginKey;
  const navigate = useNavigate();
  const { ClassNo } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [homeworkDatas, setHomeworkDatas] = useState([{
    ClassNo: '',
    HomeworkNo: '',
    DueDate: '',
    Notice: '',
    Difficulty: '',
    Section: '',
    StartDate: '',
    Question_ids: [],
    Students: [{
      Name: '',
      Questions: [],
      MemoPictures: []
    }]    
  }]);
  const [deleting, setDeleting] = useState(false);
  const [studentDatas, setStudentDatas] = useState([]);

  useEffect(() => {
    const loadHomeworkDatas = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/teacher/homework/${ClassNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          if(Response.data.Homeworks.length > 0){
            let loadHomeworks = Response.data.Homeworks;
            loadHomeworks.sort(function (a, b) {
              return new Date(a.DueDate) - new Date(b.DueDate);
            });
            for(let i = 0; i < loadHomeworks.length; i++){
              const completedCnt = loadHomeworks[i].Students.filter((item) => { return item.Questions.length === loadHomeworks[i].QuestionsCnt }).length;
              loadHomeworks[i].CompletedCnt = completedCnt;
            }
            setHomeworkDatas(loadHomeworks);
          }
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }
    loadHomeworkDatas();
  }, []);

  const handleClickHomeworkDelete = async(HNO) => {
    setDeleting(true);
    await axios({
      method: 'delete',
      url: `/api/homework`,
      data: {
        classNo: ClassNo,
        homeworkNo: HNO,
        loginKey: LoginKey,
      }
    })
    .then((Response) => {
      if(!Response.data.ErrorMessage){
        let deleteHomeworkDatas = [...homeworkDatas];
        deleteHomeworkDatas = deleteHomeworkDatas.filter((item) => { return item.HomeworkNo !== HNO });
        setHomeworkDatas(deleteHomeworkDatas);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });  
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  }
  useEffect(() => {
    const loadDashboardData = async () => {
      const config = { headers: { "Content-type": "application/json" } };
      await axios
        .get(`/api/teacher/dashboard/${ClassNo}/${LoginKey}`, config)
        .then((Response) => {
          if (!Response.data.ErrorMessage) {
          let loadStudents = Response.data.ClassInfo.Students
            setStudentDatas(loadStudents);
          } else {
            enqueueSnackbar(`${Response.data.ErrorMessage}`, {
              variant: "error",
            });
            navigate(-1);
          }
        })
        .catch((Error) => {
          enqueueSnackbar(`Network Error`, { variant: "error" });
        });
    };
    loadDashboardData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, [500]);
  }, []);
  return (
    <Layout>
      <div className='question_front_cover' style={{backgroundColor: loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',display:loading?"block":"none"}}>{ loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
      {studentDatas && homeworkDatas[0] ?      
      <TeacherHomeworkManage
      homeworkDatas={homeworkDatas}
      deleting={deleting}
      handleClickHomeworkDelete={handleClickHomeworkDelete}
      setDeleting={setDeleting}
      />
      :
      <HomeworkManageInfo homeworkDatas={homeworkDatas}/>
    }
     
    </Layout>
  )
};

export default HomeworkManage;