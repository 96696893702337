import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate,useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { TeacherInfoUpdateView } from "components/Academy/teacher";
import { Layout } from "components/Academy/Layout";

const TeacherInfoUpdate = () => {
  const [ cookies ] = useCookies(['LoginKey']);
  const loginKey = cookies.LoginKey;
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [teacherInfo, setTeacherInfo] = useState({
    name: "",
    phone: "",
    email: "",
    birth: "",
    UserNo:""
  });

  const handleTeacherData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/teacher/${id}/${loginKey}`, config).then((Response)=>{
      const teacherData=Response.data.Teahcer
      setTeacherInfo({
        name: teacherData.Name,
        phone: teacherData.Phone,
        email: teacherData.Email,
        birth: teacherData.Birth,
        UserNo:teacherData.UserNo
      })
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    })
  };

  useEffect(()=>{
    handleTeacherData()
  },[]);
  
  const handleChange = (event) => {
    let changeUserInfo = { ...teacherInfo };
    changeUserInfo[event.target.name] = event.target.value;
    setTeacherInfo(changeUserInfo);
  };

  const condition =
    teacherInfo.phone.length !== 0 &&
    teacherInfo.phone !== undefined ;

  const handleUpdate = async () => {
      if (condition) {
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({phone:teacherInfo.phone,teacherNo:teacherInfo.UserNo,loginKey:loginKey})
        await axios.put('/api/academy/teacher', body, config).then((Response) => {
          navigate(-1)
          enqueueSnackbar(`수정이 완료돠었습니다`, { variant: 'info' });
        }).catch((err)=>{
          enqueueSnackbar(`Network Error`, { variant: 'error' });
        })
      } else {
          enqueueSnackbar(`내용을 입력해 주세요.`, { variant: 'error' });
      }
  };



  return (
    <Layout >
      <TeacherInfoUpdateView
        handleChange={handleChange}
        handleUpdate={handleUpdate}
        teacherInfo={teacherInfo}
      />
    </Layout>
  );
};

export default TeacherInfoUpdate;
