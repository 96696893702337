import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import ReactGA4 from 'react-ga4';
import axios from 'axios';

import { Main, Admin, Register, Login, User, Question, Event, Teacher, Parent, MockExam, Academy, Evaluation } from './pages';
import NotFound from './NotFound';
import { Term, Privacy, Notice, ChangePassword, PayTerm, Restore, EnterPassword } from 'components/etc';


const MainRoutes = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['LoginKey']);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  // useEffect(() => {
  //   const unlisten = props.history.listen(() => {
  //       window.scrollTo(0, 0);
  //   });
  //   return () => {
  //       unlisten();
  //   };
  // }, [props.history]);
 
  
  useEffect(() => {
    if(window.location.hostname === 'localhost'){
      ReactGA4.send({
        hitType: 'pageview',
        path: location.pathname,
        location: location.pathname,
        title: 'Local: '+location.pathname
      });
    }else if(window.location.hostname === 'test.woorotest.com'){
      ReactGA4.send({
        hitType: 'pageview',
        path: location.pathname,
        location: location.pathname,
        title: 'Test: '+location.pathname
      });
    }else{
      ReactGA4.send({
        hitType: 'pageview',
        path: location.pathname,
        location: location.pathname,
        title: 'Live: '+location.pathname
      }); 
    }
  }, [location]);

  const RedirectURL = (url, message, variant) => {
    if(message){
      enqueueSnackbar(`${message}`, { variant: variant });
    }else{
      enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error'});
    }
    navigate(url);
  }; 
 

  useEffect(() => {
    const CheckStatus = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      const loginKey = location.search.indexOf('loginKey') === -1 ? cookies.LoginKey : location.search.split('loginKey=')[1];
      await axios.get(`/api/login/${loginKey}`, config).then((Response) => {
        const status = Response.data.Status;
        const state = Response.data.State;
        if(state === 1){
          if(status === 1 && (location.pathname.indexOf('/teacher') > -1 || location.pathname.indexOf('/parent') > -1)){
            RedirectURL('/', '관리자로 가입해야 사용이 가능합니다.', 'error');
          }else if(status === 2 && location.pathname.indexOf('/parent') === -1){
            RedirectURL('/parent', '학부모용 페이지로 이동합니다.', 'info');
          }else if(status === 3 && (location.pathname.indexOf('/teacher') === -1 && location.pathname.indexOf('/academy') === -1)){
            RedirectURL('/teacher', '교사용 페이지로 이동합니다.', 'info');
          }
        }else if(state === 2){
          RedirectURL('/restore', '탈퇴한 계정입니다.', 'info');
        }else{
          removeCookie('LoginKey', { path: '/'});
          removeCookie('Name', { path: '/'});
          removeCookie('UserNo', { path: '/'});
          removeCookie('Profile', { path: '/'});
          RedirectURL('/', '잘못된 경로입니다.', 'error');
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };
    
    if(location.pathname === '/term' || location.pathname === '/privacy' || location.pathname === '/notice' || location.pathname === '/payterm'){
      return false;
    }else if((location.pathname === '/login' || location.pathname === '/register') && cookies.LoginKey){
      RedirectURL('/');
    }else if(location.pathname === '/restore'){
      RedirectURL('/');
    }else if(location.pathname.indexOf('/user/price') > -1){
      if(!cookies.LoginKey && location.search.indexOf('loginKey') === -1){
        RedirectURL('/');
      }else if(location.search.indexOf('loginKey') > -1){
        let date = new Date();
        date.setDate(date.getDate()+365); 
        setCookie('LoginKey', location.search.split('loginKey=')[1], { path: '/', expires: date });
        CheckStatus();
      }else{
        CheckStatus();
      }
    }else if(location.pathname.indexOf('/teacher/price') > -1){
        if(!cookies.LoginKey && location.search.indexOf('loginKey') === -1){
          RedirectURL('/');
        }else if(location.search.indexOf('loginKey') > -1){
          let date = new Date();
          date.setDate(date.getDate()+365); 
          setCookie('LoginKey', location.search.split('loginKey=')[1], { path: '/', expires: date });
          CheckStatus();
        }else{
          CheckStatus();
        }
    }else if((location.pathname.indexOf('/teacher') > -1 || location.pathname.indexOf('/parent') > -1 || location.pathname.indexOf('/user') > -1) && !cookies.LoginKey){
      RedirectURL('/');
    }else if(cookies.LoginKey){
      CheckStatus();
    }
  }, []);

  return (
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/register' element={<Register/>} />
        <Route path='/login' element={<Login/>}/>
        <Route path='/user/*' element={<User/>}/>
        <Route path='/question/*' element={<Question/>}/>
        <Route path='/mockexam/*' element={<MockExam/>}/>
        <Route path='/evaluation/*' element={<Evaluation/>}/>
        <Route path='/teacher/*' element={<Teacher/>} />
        <Route path='/parent/*' element={<Parent/>} />
        <Route path='/academy/*' element={<Academy/>}/>
        <Route path='/admin/*' element={<Admin/>} />
        <Route path='/term' element={<Term/>}/>
        <Route path='/payterm' element={<PayTerm/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
        <Route path='/notice' element={<Notice/>}/>
        <Route path='/changepassword/:id' element={<ChangePassword/>}/>
        <Route path='/enterpassword/:tumpCode' element={<EnterPassword/>} />
        <Route path='/*' element={<NotFound />} />
        {/* <Route path='/event/*' element={<Event/>}/> */}
      </Routes>
  );
};

export default MainRoutes;