import React, { useEffect } from 'react';
import { Grid, Button, Avatar } from '@mui/material';

import WoorotestLogoOnly from 'img/woorotest_logo_only.png'
import * as ChangeText from 'components/function/ChangeText';

const StudentProposeList = (props) => {
    
    return(
        <div className='content_section_question'>
            <Grid container justifyContent='center'>
                <Grid item xs={6} md={5} className='diagnosis_result_title'>
                    <h2>클래스</h2>
                </Grid>
                <Grid item xs={6} md={5} sx={{ textAlign: 'right', paddingTop: '50px' }}>
                    <Button variant='outlined' color='warning' onClick={props.handleClickOpenSearchClass}>클래스 검색</Button>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='student_propose_list'>
                        <table>
                            {props.classList.map((classItem, i) => (
                            <tr>
                                <td>
                                    {classItem.Connect === 1 ?
                                    <Button size='small' variant='outlined' color='warning' onClick={() => props.handleClickClassCancel(i)}>취소</Button>:
                                    classItem.Connect === 2 ?
                                    <Button size='small' variant='outlined' color='error' onClick={() => props.handleClickClassExit(i)}>삭제</Button>:
                                    <Button size='small' variant='outlined' disabled={true}>-</Button>}
                                </td>
                                <td>
                                    <img class='student_class_logo' src={classItem.Class_id.ClassImage ? classItem.Class_id.ClassImage : WoorotestLogoOnly} alt={`class image${i}`}/>
                                </td>
                                <td>
                                    <p>{classItem.Class_id.ClassName}</p>
                                    <span>{classItem.Class_id.ManagerName} 선생님 · {classItem.Class_id.ManagerEmail}</span>
                                </td>
                                <td>{classItem.ProposedAt ? ChangeText.ApplyTime(classItem.ProposedAt) : '-'}</td>
                                <td>
                                    {classItem.Connect === 1 ?
                                    '수업 대기':
                                    classItem.Connect === 2 ?
                                    <b>수업 진행</b>:
                                    classItem.Connect === 3 ?
                                    <span style={{ padding: 0 }}>수업 중단</span>:
                                    classItem.Connect === 4 ?
                                    <span style={{ padding: 0 }}>수업 종료</span>:
                                    <span style={{ padding: 0 }}>-</span>
                                    }
                                </td>
                            </tr> 
                            ))}
                        </table>
                    </div>
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>학부모</h2>
                    <div className='student_propose_list'>
                        <table>
                            {props.parentList.map((parentItem, i) => (
                            <tr>
                                <td>
                                    {parentItem.Connect === 1 || parentItem.Connect === 3 ?
                                    <>
                                    <Button size='small' variant='outlined' color='primary' onClick={() => props.handleClickParentPermit(i)}>수락</Button>
                                    <Button size='small' variant='outlined' color='warning' onClick={() => props.handleClickParentCancel(i)}>취소</Button>
                                    </>:
                                    parentItem.Connect === 2 ?
                                    <Button size='small' variant='outlined' color='error' onClick={() => props.handleClickParentCancel(i)}>삭제</Button>:
                                    <Button size='small' variant='outlined' disabled={true}>-</Button>}
                                </td>
                                <td>
                                    <img class='student_class_logo' src={parentItem.Parent_id.Profile ? parentItem.Parent_id.Profile : WoorotestLogoOnly} alt={`class image${i}`}/>
                                </td>
                                <td>
                                    <p>{parentItem.Parent_id.Name}</p>
                                    <span>{parentItem.Parent_id.Email}</span>
                                </td>
                                <td>{parentItem.ProposedAt ? ChangeText.ApplyTime(parentItem.ProposedAt) : '-'}</td>
                                <td>
                                    {parentItem.Connect === 1 || parentItem.Connect === 3 ?
                                    '수락 대기':
                                    parentItem.Connect === 2 ?
                                    '수락':
                                    '-'
                                    }
                                </td>
                            </tr>
                            ))}
                        </table>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default StudentProposeList;