import React, { useEffect, useState } from 'react';
import { Grid, Link, CircularProgress } from '@mui/material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import * as ChangeText from 'components/function/ChangeText';
import { CommentaryModal } from 'components/modal';
import { QuestionMemoReview } from 'components/user/question';

const TeacherQuestionViewDetail = (props) => {
   const question = props.questionData;
   const [loading, setLoading] = useState(false);
   const [cookies] = useCookies(['ClassName']);
   const { enqueueSnackbar } = useSnackbar();
   // const config = {
   //    "fast-preview": {disabled: true},
   //    loader: { load: ["[tex]/html"] },
   //    tex: {
   //      packages: { "[+]": ["html"]},
   //      inlineMath: [
   //        ["$", "$"],
   //        ["\\(", "\\)"]
   //      ],
   //      displayMath: [
   //        ["$$", "$$"],
   //        ["\\[", "\\]"]
   //      ],
   //    },
   //    options: {
   //      enableMenu: false,
   //    }
   // };
   const config = {
      "fast-preview": { disabled: true },
      loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
      tex: {
          packages: { "[+]": ["html", "img"] },
          inlineMath: [
              ["$", "$"],
              ["\\(", "\\)"]
        ],
        displayMath: [
              ["$$", "$$"],
              ["\\[", "\\]"]
        ],
      },
      options: {
          enableMenu: false,
      }
  };
  
   const [open, setOpen] = useState(false);
   const [openMemoReview, setOpenMemoReview] = useState(false);
   const [memoReview, setMemoReview] = useState('');
   
   const handleClickMemo = (memo) => {
      setMemoReview(memo);
      setOpenMemoReview(true);
   };
   useEffect(() => {
      setLoading(true);
      setTimeout(() => {
         setLoading(false);
      }, [500]);
   }, []);

   const handleClickCommentary = () => {
      if(question.Commentary.length > 0){
         setOpen(true);
      }else{
         enqueueSnackbar(`해설이 없습니다. 빠른 시일 내 추가하겠습니다.`, { variant: 'warning'});
      }
   };
   return(
      <>
         <div className='content_section'>
            <Grid container justifyContent='center'>
               <Grid item xs={12} md={10} className='diagnosis_result_title'>
                  <h2>문제 {question ? question.Question-question.Section*10000 : null}</h2>
               </Grid>
               <Grid item xs={12} md={10} className='question_content'>
                  <div className='question_front_cover' style={{backgroundColor: loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>{ loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
                     <MathJaxContext version={3} config={config}>
                        {question.Content.map((content) => (
                        <MathJax 
                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                        text={
                           content.replace('`\\(','').replace('\\)`','')
                        }
                        dynamic
                        className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                     />
                        ))}
                     </MathJaxContext>
                  {/* <MathJaxContext version={3} config={config}>
                        {question?.Content.map((content) => (
                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                           content.replace('`\\(','').replace('\\)`','')
                           } dynamic hideUntilTypeset={"first"}
                        />
                        ))}
                  </MathJaxContext> */}
                  {/* <div style={{textAlign: 'center'}}>
                        {question?.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                  </div> */}
                  <div className='diagnosis_questions_answer'>
                     {question && typeof(question.Answer)  !== 'string' &&
                     question?.NewChoices.map((choice, i) => (
                        question?.Answer === i+1 ?
                        <div>
                           <RadioButtonChecked className='homework_question_answer'/>
                           <MathJaxContext version={3} config={config}>
                              <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                 choice.replace('`\\(','').replace('\\)`','')
                                 } inline dynamic/>
                           </MathJaxContext>
                        </div>:
                        <div>
                           <RadioButtonUnchecked/>
                           <MathJaxContext version={3} config={config}>
                              <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                 choice.replace('`\\(','').replace('\\)`','')
                                 } inline dynamic/>
                           </MathJaxContext>
                        </div>
                     ))}
                  </div>
               </Grid>
               <Grid item xs={12} md={10}>
                  <div className={question.Commentary && question.Commentary.length > 0 ? 'teacher_homework_commentary' : 'teacher_homework_commentary teacher_homework_none_commentary'} onClick={handleClickCommentary}>해설보기</div>
               </Grid>
               <Grid item xs={12} md={10}>
                  <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                     <table>
                        <tr>
                           <th colSpan={2}>{cookies.ClassName} 학생</th><th colSpan={2}>우로매쓰 학생</th>
                        </tr>
                        <tr>
                           <th>정답률</th><th>평균풀이시간</th><th>정답률</th><th>평균풀이시간</th>
                        </tr>
                        <tr>
                           <td>{question?.InfoClass.Counting ? ChangeText.Percent(question?.InfoClass.Correct/question?.InfoClass.Counting) : '0%'}</td>
                           <td>{ChangeText.QuestionMinute(question?.InfoClass.Time/question?.InfoClass.Correct)}</td>
                           <td>{question?.Info.Counting ? ChangeText.Percent(question?.Info.Correct/question?.Info.Counting) : '0%'}</td>
                           <td>{ChangeText.QuestionMinute(question?.Info.Time/question?.Info.Correct)}</td>
                        </tr>
                     </table>
                     <table>
                        <tr>
                           <th>No.</th>
                           <th>이름</th>
                           <th>정답여부</th>
                           <th>선택한답</th>
                           <th>풀이시간</th>
                           <th>풀이유형</th>
                           <th>풀이일시</th>
                           <th>메모여부</th>
                        </tr> 
                        {props.studentDatas.length > 0 ?
                        props.studentDatas.map((student, i) => (
                        <tr>
                           <td>{(i+1)}</td>
                           <td>{student.Name}</td>
                           <td>{ChangeText.QuestionCheck(student.Check)}</td>
                           {student.Select > 0 ?
                               <td>{student.Select}번</td> : <td> - </td>
                           }
                           <td>{ChangeText.QuestionMinute(student.Time)}</td>
                           <td>{ChangeText.QuestionStatus(student.Status)}</td>
                           <td>{ChangeText.YyyyMmDd(student.CreatedAt)}</td>
                           <td>{student.Memo ? <Link href='javascript:;' onClick={() => handleClickMemo(student.Memo)}>O</Link> : 'X'}</td>
                        </tr> 
                        )):
                        <tr>
                           <td colSpan={8}><b>{cookies.ClassName}</b> 클래스에 해당 문제를 풀이한 학생이 없습니다.</td>
                        </tr>
                        }
                     </table>
                  </div>
               </Grid>
            </Grid>
         </div>
         <CommentaryModal open={open} setOpen={setOpen} Commentary={question.Commentary} config={config}/>
         <QuestionMemoReview 
            open={openMemoReview}
            setOpen={setOpenMemoReview}
            memoReview={memoReview}
         />
      </>
   );
};

export default TeacherQuestionViewDetail;