import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const SubmitModal = (props) => {
    return(
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-describedby='alert-dialog-slide-description-delete'
                fullWidth={true}
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    onClick={props.handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color='secondary'>취소</Button>
                <LoadingButton
                    onClick={() => props.handleClickSubmit()}
                    variant='contained'
                    disableElevation
                    disabled={props.disabledOption === 0}
                    color='warning'
                    loading={props.updating}
                >
                    확인 
                </LoadingButton>
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default SubmitModal;