import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import * as ChangeText from 'components/function/ChangeText';

const Notice = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [notices, setNotices] = useState([{
        _id: '',
        Title: '',
        Content: '',
        CreatedAt: ''
    }]);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const loadNotices = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/etc/notice`, config).then((Response) => {
                setNotices(Response.data);
            }).catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        };
        loadNotices();
    }, []);
    return (
        <div style={{paddingTop: '2vh', backgroundColor: '#F8F9FA', minHeight: '98vh', paddingLeft: '10px', paddingRight: '10px'}}>
            <h3>우로매쓰 공지사항</h3>
            {notices.map((notice) => (
            <Accordion expanded={expanded === notice._id} onChange={handleChange(notice._id)}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '70%', flexShrink: 0, overflow: 'hidden', fontWeight: 'bold'}}>
                        {notice.Title}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', textAlign: 'right', width: '30%'}}>{ChangeText.YyyyMmDd(notice.CreatedAt)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div style={{fontSize: '14px', color: '#525252'}} dangerouslySetInnerHTML={{ __html: notice.Content }}></div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            ))}
        </div>
    );
};

export default Notice;