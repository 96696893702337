import React from "react";
import { Routes, Route } from "react-router-dom";
import { UserUpdate} from "domain/Academy/user";
import NotFound from "routes/NotFound";

const BoardPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<UserUpdate />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default BoardPresenter;
