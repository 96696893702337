import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, RadioGroup, Radio, FormControlLabel, CircularProgress, OutlinedInput, InputAdornment, IconButton, Chip } from '@mui/material';
import { AddCircle, Clear } from '@mui/icons-material';

import * as AddValue from 'components/function/AddValue';
import * as ChangeText from 'components/function/ChangeText';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const QuestionDetailCreate = (props) => {
    const question = props.question;
    const PartList = AddValue.AllPartsDashboard();
    const difficulty = [1,2,3,4,5];
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };

    const navigate = useNavigate();
    const handleKeyDownChoice = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddChoice();
        }
    };
    const handleKeyDownNewChoice = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddNewChoice();
        }
    };
    const handleKeyDownContent = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddContent();
        }
    };
    const handleKeyDownCommentary = (event) => {
        if(event.key === 'Enter'){
            props.handleClickAddCommentary();
        }
    };
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>문제 등록</h3>
                    <FormControl fullWidth>
                        <InputLabel id='part-label'>과목</InputLabel>
                        <Select
                            labelId='part-label'
                            name='Part'
                            label='과목'
                            value={question.Part}
                            onChange={props.handleChangePart}
                        >
                            {PartList.map((part, i) => (
                            <MenuItem value={i+1}>{part.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='middlepart-label'>대단원</InputLabel>
                        <Select
                            labelId='middlepart-label'
                            name='MiddlePart'
                            label='대단원'
                            value={question.MiddlePart}
                            onChange={props.handleChangeMiddlePart}
                        >

                            {question.Part ?
                            PartList[question.Part-1].subSections.map((middlePart, i) => (
                            <MenuItem value={i+1}>{middlePart.name}</MenuItem>
                            )):
                            <MenuItem value='' disabled>과목을 선택해 주세요.</MenuItem>}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='section-label'>소단원</InputLabel>
                        <Select
                            labelId='section-label'
                            name='Section'
                            label='소단원'
                            value={question.Section}
                            onChange={props.handleChange}
                        >

                            {question.MiddlePart ?
                            PartList[question.Part-1].subSections[question.MiddlePart-1].sections.map((section, i) => (
                            <MenuItem value={section.section}>{section.name}</MenuItem>
                            )):
                            <MenuItem value='' disabled>대단원을 선택해 주세요.</MenuItem>}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>문제</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='Content'
                            label='문제'
                            value={props.content}
                            onChange={props.handleChangeContent}
                            onKeyDown={handleKeyDownContent}
                            multiline={true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddContent}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        문제 미리보기<hr/>
                        {question.Content.map((content, i) => (
                        <Grid container justifyContent='space-between' style={{marginBottom: '10px', borderBottom: '1px #A4A4A4 solid', paddingBottom: '10px'}}>
                            <p style={{fontSize: '18px'}}>{content}</p>
                            <Grid item xs={11}>
                                <MathJaxContext>
                                    <MathJax>
                                        {content.replaceAll('`','')}
                                    </MathJax>
                                </MathJaxContext>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton style={{float: 'right'}}>
                                    <Clear onClick={() => props.handleDeleteContent(i)}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    {/* <FormControl fullWidth className='update_profile'>
                        <InputLabel id='profile-label' shrink>추가 도형 및 그래프 등</InputLabel>
                        <Grid container justifyContent='space-between'>
                            <Grid xs={6} >
                                {props.imgBase64 ? 
                                <img alt='question pictures' src={props.imgBase64} /> :
                                null}
                            </Grid>
                            <Grid xs={3} textAlign='right'>
                            <Button color='inherit' onClick={props.handleClickDeletePicture}>
                                삭제
                            </Button>
                            <label htmlFor='Profile' className='upload_profile'>
                                <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangePicture}/>
                                <Button component='span'>
                                    등록
                                </Button>
                            </label>
                            </Grid>
                        </Grid>
                    </FormControl> */}
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>보기</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='newChoice'
                            label='보기'
                            value={props.newChoice}
                            onChange={props.handleChangeNewChoice}
                            onKeyDown={handleKeyDownNewChoice}
                            disabled={question.NewChoices?.length < 5 ? false : true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    {question.NewChoices?.length < 5 ?
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddNewChoice}
                                    >
                                        <AddCircle />
                                    </IconButton>:
                                    null}
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    { question.NewChoices.length > 0 ?
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        {question.NewChoices.map((choice, i) => (
                        <Grid container style={{marginBottom: '10px', borderBottom: '1px solid rgba(164, 164, 164, 0.3)', paddingBottom: '10px'}}>
                            <Grid item xs={1}>
                                <IconButton onClick={() => props.handleDeleteNewChoice(i)}>
                                    <Clear/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        `\\scriptsize^{\\grave{}}~\\backslash\\text{(}\\text{${choice.replace('`\\(','').replace('\\)`','').replaceAll('\\\\','\\\\\\\\').replaceAll('  ','')}}\\backslash\\text{)}~^{\\grave{}}`
                                        } inline dynamic
                                    />
                                    <br/><br/>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                        choice.replace('`\\(','').replace('\\)`','')
                                        } inline dynamic
                                    />
                                </MathJaxContext>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    :
                    ""
                   }
                    {/* {question.Choices.map((choice, i) => (
                        <Chip style={{marginRight: '8px'}} label={choice} variant="outlined" onDelete={() => props.handleDeleteChoice(i)}/>
                    ))} */}
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='answer-label' shrink>정답</InputLabel>
                        <RadioGroup
                            row
                            labelId='answer-label'
                            value={question.Answer}
                            name='Answer'
                            onChange={props.handleChange}
                        >
                            {question.Choices.map((choice, i) => (
                                <FormControlLabel value={i+1} control={<Radio />} label={
                                    <MathJaxContext version={3} config={config}>
                                        <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                            choice.replace('`\\(','').replace('\\)`','')
                                            } inline dynamic
                                        />
                                    </MathJaxContext>
                                } />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id='choice-label'>해설</InputLabel>
                        <OutlinedInput
                            labelId='choice-label'
                            name='Commentary'
                            label='해설'
                            value={props.commentary}
                            onChange={props.handleChangeCommentary}
                            onKeyDown={handleKeyDownCommentary}
                            multiline={true}
                            endAdornment={
                                <InputAdornment position='end'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        edge='end'
                                        onClick={props.handleClickAddCommentary}
                                    >
                                        <AddCircle />
                                    </IconButton>
                                </InputAdornment>
                              }
                        /> 
                    </FormControl>
                    <div style={{backgroundColor: '#F8F9FA', borderRadius: '4px', padding: '10px'}}>
                        해설 미리보기<hr/>
                        {question.Commentary.map((commentary, i) => (
                        <Grid container justifyContent='space-between' style={{marginBottom: '10px', borderBottom: '1px #A4A4A4 solid', paddingBottom: '10px'}}>
                            <Grid item xs={11}>
                                <MathJaxContext>
                                    <MathJax>
                                        {commentary.replaceAll('`','')}
                                    </MathJax>
                                </MathJaxContext>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton style={{float: 'right'}}>
                                    <Clear onClick={() => props.handleDeleteCommentary(i)}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        ))}
                    </div>
                    <FormControl fullWidth>
                        <InputLabel id='difficulty-label'>난이도</InputLabel>
                        <Select
                            labelId='difficulty-label'
                            name='Difficulty'
                            label='난이도'
                            value={question.Difficulty}
                            onChange={props.handleChange}
                        >
                            {difficulty.map((dif) => (
                            <MenuItem value={dif}>{ChangeText.Difficulty(dif)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        name='WDiscrimality'
                        label='변별도(W)'
                        value={question.WDiscrimality}
                        onChange={props.handleChange}
                        fullWidth={true}
                        type='number'
                    />
                    <TextField
                        name='WDifficulty'
                        label='난이도(W)'
                        value={question.WDifficulty}
                        onChange={props.handleChange}
                        fullWidth={true}
                        type='number'
                    />
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='state-label' shrink>문제 상태</InputLabel>
                        <RadioGroup
                            row
                            labelId='state-label'
                            value={question.State}
                            name='State'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={1} control={<Radio />} label='출제' />
                            <FormControlLabel value={2} control={<Radio />} label='미출제' />
                        </RadioGroup>
                    </FormControl>
                    
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='success' onClick={props.handleClickCreate} disabled={props.creating}>등록</Button>
                    </Grid>
                    {props.creating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='success'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default QuestionDetailCreate;