import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'components/parent/layout';
import { MockExamView } from 'components/parent/child/mockExam';

const MockExam = () => {
    const [cookies] = useCookies(['ChildNo', 'LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [mockExams, setMockExams] = useState([{
        MockExamNo: 10002,
        Name: '',
        StartDate: '1900-01-01',
        Part: 0,
        State: 0
    }]);

    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/parent/mockexam/${cookies.ChildNo}/${cookies.LoginKey}`, config).then((res) => {
            if(!res.data.ErrorMessage){
                setMockExams(res.data.mockExamDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                navigate(-1);
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };
    
    useEffect(() => {
        loadDatas();
    }, []);

    return (
        <Layout>
            <MockExamView
                mockExams={mockExams}
            />
        </Layout>
    );
};

export default MockExam;