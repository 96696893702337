import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress, Button, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/admin/layout';
import { SubmitModal } from 'components/modal';
import { YyyyMmDdHhMmSs, YyyyMmDd } from 'components/function/ChangeText';

const CouponList = () => {
    const pages = [
        { title: '쿠폰 관리'},
        { title: '쿠폰 목록'}
    ];
    const title = { main: '쿠폰 목록', sub: 'Coupon'};
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const columns = [
        { field: 'id', headerName: 'No.', width: 70 },
        { field: 'Title', headerName: '이름', width: 150 },
        { field: 'CouponNumber', headerName: '쿠폰번호', width: 200 },
        { field: 'CreatedAt', headerName: '생성일시', width: 200 },
        { field: 'ExpiryAt', headerName: '만료일시', width: 200 },
        { field: 'UsedAt', headerName: '사용일시', width: 200 },
        { field: 'StudentName', headerName: '학생이름', width: 120 }
    ];
    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState([{
        id: '',
        ExpiryAt: '',
        CreatedAt: '',
        Title: '',
        CouponNumber: '',
        UsedAt: '',
        StudentName: ''
    }]);
    const [open, setOpen] = useState(false);
    const [coupon, setCoupon] = useState({
        Title: '',
        Count: '',
        ExpiryAt: ' ',
        loginKey: cookies.LoginKey
    });
    const [creating, setCreating] = useState(false);

    const loadDatas = async() => {
        setLoading(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/admin/coupon/${cookies.LoginKey}`, config).then((res) => {
            res.data.Coupons.sort((a, b) => (new Date(b.CreatedAt) - new Date(a.CreatedAt)));
            let loadCoupons = [];
            for(let i = 0; i < res.data.Coupons.length; i++){
                loadCoupons.push({
                    id: res.data.Coupons.length - i,
                    ExpiryAt: YyyyMmDd(res.data.Coupons[i].ExpiryAt),
                    CreatedAt: YyyyMmDdHhMmSs(res.data.Coupons[i].CreatedAt),
                    Title: res.data.Coupons[i].Title,
                    CouponNumber: res.data.Coupons[i].CouponNumber,
                    UsedAt: res.data.Coupons[i].UsedAt ? YyyyMmDdHhMmSs(res.data.Coupons[i].UsedAt) : '',
                    StudentName: res.data.Coupons[i].Student_id ? res.data.Coupons[i].Student_id.Name : '',
                    _id: res.data.Coupons[i]._id
                });
            }
            setCoupons(loadCoupons);
            setTimeout(() => {
                setLoading(false);
            }, [1500]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    const handleClose = () => {
        setOpen(false);
        setCoupon({
            Title: '',
            Count: '',
            ExpiryAt: ' ',
            loginKey: cookies.LoginKey
        });
    };

    const handleClickCreateCoupon = async() => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify(coupon);
        await axios.post(`/api/admin/coupon`, data, config).then((res) => {
            let addCoupons = [...coupons];
            for(let i = 0; i < res.data.Coupons.length; i++){
                addCoupons.push({
                    id: addCoupons.length + 1,
                    ExpiryAt: YyyyMmDd(res.data.Coupons[i].ExpiryAt),
                    CreatedAt: YyyyMmDdHhMmSs(res.data.Coupons[i].CreatedAt),
                    Title: res.data.Coupons[i].Title,
                    CouponNumber: res.data.Coupons[i].CouponNumber,
                    UsedAt: '',
                    StudentName: '',
                    _id: res.data.Coupons[i]._id,
                });
            }
            setCoupons(addCoupons)
            setTimeout(() => {
                setCreating(false);
                handleClose();
            }, [1500]);
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleClickCell = (event) => {
        // if(event.field === 'StudentName'){
        //     navigate(`/admin/student/${managers[event.row.id-1].Link}`);
        // }else{
        //     return false;
        // }
    };

    useEffect(() => {
        loadDatas();
    }, []);

    return(
        <Layout pages={pages} title={title}>
            <Grid container justifyContent="space-between">
                <Grid xs={4}></Grid>
                <Grid xs={8} textAlign={'right'} style={{ paddingRight: 0 }}><Button onClick={() => setOpen(true)} variant='contained' color='success'>쿠폰 생성</Button></Grid>
                <Grid xs={12} className="list_table">
                    <DataGrid
                        rows={coupons}
                        columns={columns}
                        pageSize={15}
                        onCellClick={handleClickCell}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc'}]
                            }
                        }}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                    />
                </Grid>
            </Grid>
            <SubmitModal
                open={open}
                handleClose={handleClose}
                title={'쿠폰 생성'}
                updating={creating}
                handleClickSubmit={handleClickCreateCoupon}
            >
                <TextField 
                    label='쿠폰 이름'
                    variant='outlined'
                    fullWidth
                    color='warning'
                    type={'text'}
                    value={coupon.Title}
                    onChange={(e) => setCoupon({ ...coupon, Title: e.target.value })}
                    style={{ marginBottom: '16px', marginTop: '8px' }}
                />
                <TextField
                    label='발행 개수'
                    variant='outlined'
                    fullWidth
                    color='warning'
                    type={'number'}
                    value={coupon.Count}
                    onChange={(e) => setCoupon({ ...coupon, Count: e.target.value })}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    label='만료일시'
                    variant='outlined'
                    fullWidth
                    color='warning'
                    type={'date'}
                    value={coupon.ExpiryAt}
                    onChange={(e) => new Date(e.target.value) > new Date() ? setCoupon({ ...coupon, ExpiryAt: e.target.value }): enqueueSnackbar('오늘 이전 날짜는 선택할 수 없습니다.', { variant: 'error' })}
                />
            </SubmitModal>
        </Layout>
    );
};

export default CouponList;