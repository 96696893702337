import {  Grid,Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as ChangeText from 'components/function/ChangeText';

const StudentDetailDiagnosis = (props) => {
  const navigate=useNavigate()
  const handlePartData=(section)=>{
    navigate(`${section}`)
  }
  return (
  <Grid container justifyContent="center">
    <Grid container lg={12} xl={8} >
      {props?.part[0]?.subSections.map((list,i)=>(
        list?.sections.map((sub,i)=>(
      <Grid item lg={3.55} xl={3.55}  pb={3} sx={{margin:"10px ",background:"#FFFFFF",borderRadius:"16px"}} key={i}>
        <div style={{height:"150px",textAlign:"center",display:"flex",flexFlow:"column",justifyContent:"center"}}>
            <p style={{fontSize:"20px",fontWeight:"500"}}>
              {sub.percent ? 
              <b>{`상위 ${ChangeText.IntPercent(sub.percent)}`}</b>:
              '진단 결과 없음'}
            </p>
          <p style={{fontSize:"16px",fontWeight:"500"}}>{list.name} {">"} {sub.name}</p>
          <div>
            <Button variant="contained"sx={{background:"#F05D38",borderRadius:"6px",color:"#FFFFFF",width:"40%",height:"35px",'&:hover':{ background:"#F05D38" }}} disableElevation size='small' disabled={sub.percent ? false : true} onClick={()=>{handlePartData(sub.section)}}>
              진단 결과 보기
            </Button>
          </div>
        </div>
    </Grid>
        ))
      ))}
  </Grid>
</Grid>
   );
}
 
export default StudentDetailDiagnosis ;