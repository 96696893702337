import React from "react";
import { ResponsiveBar } from "@nivo/bar";
const DashboardBarChart = (props) => {
  return (
    <ResponsiveBar
        data={props.data}
        onClick={(e)=>{props.monthDiagnosis(e)}}
       // data={[
        //     {
        //       'country': 'AD',
        //       'hot dog': 99,
        //       'hot dogColor': 'hsl(273, 70%, 50%)',
        //       'burger': 161,
        //       'burgerColor': 'hsl(296, 70%, 50%)',
        //       'sandwich': 117,
        //       'sandwichColor': 'hsl(29, 70%, 50%)',
        //       'kebab': 125,
        //       'kebabColor': 'hsl(281, 70%, 50%)',
        //       'fries': 132,
        //       'friesColor': 'hsl(233, 70%, 50%)',
        //       'donut': 52,
        //       'donutColor': 'hsl(177, 70%, 50%)'
        //     },
        //     {
        //       'country': 'AE',
        //       'hot dog': 23,
        //       'hot dogColor': 'hsl(172, 70%, 50%)',
        //       'burger': 59,
        //       'burgerColor': 'hsl(344, 70%, 50%)',
        //       'sandwich': 196,
        //       'sandwichColor': 'hsl(300, 70%, 50%)',
        //       'kebab': 100,
        //       'kebabColor': 'hsl(118, 70%, 50%)',
        //       'fries': 23,
        //       'friesColor': 'hsl(224, 70%, 50%)',
        //       'donut': 183,
        //       'donutColor': 'hsl(15, 70%, 50%)'
        //     },
        //     {
        //       'country': 'AF',
        //       'hot dog': 180,
        //       'hot dogColor': 'hsl(82, 70%, 50%)',
        //       'burger': 54,
        //       'burgerColor': 'hsl(186, 70%, 50%)',
        //       'sandwich': 131,
        //       'sandwichColor': 'hsl(343, 70%, 50%)',
        //       'kebab': 90,
        //       'kebabColor': 'hsl(231, 70%, 50%)',
        //       'fries': 17,
        //       'friesColor': 'hsl(90, 70%, 50%)',
        //       'donut': 128,
        //       'donutColor': 'hsl(281, 70%, 50%)'
        //     },
        //     {
        //       'country': 'AG',
        //       'hot dog': 37,
        //       'hot dogColor': 'hsl(259, 70%, 50%)',
        //       'burger': 116,
        //       'burgerColor': 'hsl(52, 70%, 50%)',
        //       'sandwich': 10,
        //       'sandwichColor': 'hsl(359, 70%, 50%)',
        //       'kebab': 22,
        //       'kebabColor': 'hsl(53, 70%, 50%)',
        //       'fries': 177,
        //       'friesColor': 'hsl(328, 70%, 50%)',
        //       'donut': 38,
        //       'donutColor': 'hsl(52, 70%, 50%)'
        //     },
        //     {
        //       'country': 'AI',
        //       'hot dog': 116,
        //       'hot dogColor': 'hsl(73, 70%, 50%)',
        //       'burger': 92,
        //       'burgerColor': 'hsl(355, 70%, 50%)',
        //       'sandwich': 117,
        //       'sandwichColor': 'hsl(260, 70%, 50%)',
        //       'kebab': 79,
        //       'kebabColor': 'hsl(83, 70%, 50%)',
        //       'fries': 130,
        //       'friesColor': 'hsl(130, 70%, 50%)',
        //       'donut': 84,
        //       'donutColor': 'hsl(225, 70%, 50%)'
        //     },
        //     {
        //       'country': 'AL',
        //       'hot dog': 61,
        //       'hot dogColor': 'hsl(109, 70%, 50%)',
        //       'burger': 103,
        //       'burgerColor': 'hsl(115, 70%, 50%)',
        //       'sandwich': 25,
        //       'sandwichColor': 'hsl(91, 70%, 50%)',
        //       'kebab': 13,
        //       'kebabColor': 'hsl(24, 70%, 50%)',
        //       'fries': 56,
        //       'friesColor': 'hsl(73, 70%, 50%)',
        //       'donut': 148,
        //       'donutColor': 'hsl(108, 70%, 50%)'
        //     },
        //     {
        //       'country': 'AM',
        //       'hot dog': 107,
        //       'hot dogColor': 'hsl(217, 70%, 50%)',
        //       'burger': 180,
        //       'burgerColor': 'hsl(356, 70%, 50%)',
        //       'sandwich': 7,
        //       'sandwichColor': 'hsl(149, 70%, 50%)',
        //       'kebab': 172,
        //       'kebabColor': 'hsl(44, 70%, 50%)',
        //       'fries': 100,
        //       'friesColor': 'hsl(351, 70%, 50%)',
        //       'donut': 74,
        //       'donutColor': 'hsl(258, 70%, 50%)'
        //     }
        // ]}
        keys={[props.y]}
        // keys={[
        //     'hot dog',
        //     'burger',
        //     'sandwich',
        //     'kebab',
        //     'fries',
        //     'donut'
        // ]}
        indexBy={props.x}
        // margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        margin={{ top: 5, right: 5, bottom: 30, left: 50 }}
        // padding={0.3}
        padding={0}
        // markers={[
        //     {
        //         axis: 'y',
        //         value: 300,
        //         lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
        //         legend: 'y marker at 300',
        //         legendOrientation: 'vertical',
        //     },
        // ]}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'pastel1' }}
        colorBy={'indexValue'}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            // legend: 'country',
            // legendPosition: 'middle',
            // legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: props.ylabel,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        // legends={[
        //     {
        //         dataFrom: 'keys',
        //         anchor: 'bottom-right',
        //         direction: 'column',
        //         justify: false,
        //         translateX: 120,
        //         translateY: 0,
        //         itemsSpacing: 2,
        //         itemWidth: 100,
        //         itemHeight: 20,
        //         itemDirection: 'left-to-right',
        //         itemOpacity: 0.85,
        //         symbolSize: 20,
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemOpacity: 1
        //                 }
        //             }
        //         ]
        //     }
        // ]}
        role='application'
        // ariaLabel='Nivo bar chart demo'
        // barAriaLabel={function(e){return e.id+': '+e.formattedValue+' in country: '+e.indexValue}}
    />
  );
};

export default DashboardBarChart;
