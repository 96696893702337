import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, RadioGroup, Radio, FormControlLabel, CircularProgress } from '@mui/material';

import * as AddValue from 'components/function/AddValue';

const SchoolDetailCreate = (props) => {
    const school = props.school;
    const localList = AddValue.LocalList();
    const navigate = useNavigate();
    
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid xs={8} className='update_form'>
                    <h3>학교 등록</h3>
                    <TextField
                        name='NEIS_code'
                        label='NEIS 코드'
                        value={school.NEIS_code}
                        onChange={props.handleChange}
                        fullWidth={true}
                    />
                    <TextField
                        name='Name'
                        label='이름'
                        value={school.Name}
                        onChange={props.handleChange}
                        fullWidth={true}
                    />
                    <FormControl fullWidth>
                        <InputLabel id='local-label'>소재지</InputLabel>
                        <Select
                            labelId='local-label'
                            name='Local'
                            label='소재지'
                            value={school.Local}
                            onChange={props.handleChange}
                        >
                            {localList.map((local) => (
                            <MenuItem value={local}>{local}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        name='County'
                        label='주소'
                        value={school.County}
                        onChange={props.handleChange}
                        fullWidth={true}
                        placeholder='ex) 포항시'
                    />
                    <TextField
                        name='WooroRank'
                        label='순위'
                        value={school.WooroRank}
                        onChange={props.handleChange}
                        fullWidth={true}
                    />
                    <TextField
                        name='WooroZscore'
                        label='점수'
                        value={school.WooroZscore}
                        onChange={props.handleChange}
                        fullWidth={true}
                    />
                    <FormControl fullWidth className='update_radio_group'>
                        <InputLabel id='state-label' shrink>학교 상태</InputLabel>
                        <RadioGroup
                            row
                            labelId='state-label'
                            value={school.State}
                            name='State'
                            onChange={props.handleChange}
                        >
                            <FormControlLabel value={1} control={<Radio />} label='운영' />
                            <FormControlLabel value={2} control={<Radio />} label='폐교' />
                        </RadioGroup>
                    </FormControl>
                    <Grid className='update_button_group'>
                        <Button variant='contained' color='inherit' onClick={() => navigate(-1)}>취소</Button>
                        <Button variant='contained' color='success' onClick={props.handleClickCreate} disabled={props.creating}>등록</Button>
                    </Grid>
                    {props.creating ?
                    <Grid className='update_button_group'>
                        <CircularProgress color='success'/>
                    </Grid>:
                    null}
                </Grid>
            </Grid>
        </>
    );
};

export default SchoolDetailCreate;