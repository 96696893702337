import React from 'react';
import { Grid } from '@mui/material';

const AdminDashboard = (props) => {
    return(
        <>
            <Grid container className='admin_dashboard_user'>
                <Grid xs={4}>
                    <div></div>
                </Grid>
                <Grid xs={4}>
                    <div></div>
                </Grid>
                <Grid xs={4}>
                    <div></div>
                </Grid>
            </Grid>
        </>
    );
};

export default AdminDashboard;