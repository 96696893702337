import React, { useEffect, useState } from 'react';
import { Grid, FormControl, Select, MenuItem, Link, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useSnackbar } from 'notistack'; 

import * as ChangeText from 'components/function/ChangeText';
import { CommentaryModal } from 'components/modal';
import { QuestionMemoReview } from 'components/user/question'

const TeacherHomeworkDetailQuestion = (props) => {
   const navigate = useNavigate();
   const { ClassNo, HomeworkNo, QuestionNo } = useParams();
   const [loading, setLoading] = useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const [open, setOpen] = useState(false);
   const [filterQuestion, setFilterQuestion] = useState({
      Answer: '',
      Choices: ['','','','',''],
      Content: [' '],
      Section: '',
      Question: '',
      Info: { Counting: '', Correct: '', Time: '' },
      Picture: '',
      Commentary: ''
   });
   const [students, setStudents] = useState([{
      StudentNo: '',
      Name: '',
      Check: '',
      Time: '',
      Memo: ''
   }]);
   const [openMemoReview, setOpenMemoReview] = useState(false);
   const [memoReview, setMemoReview] = useState('');
   
//    const config = {
//       "fast-preview": {disabled: true},
//       loader: { load: ["[tex]/html"] },
//       tex: {
//         packages: { "[+]": ["html"]},
//         inlineMath: [
//           ["$", "$"],
//           ["\\(", "\\)"]
//         ],
//         displayMath: [
//           ["$$", "$$"],
//           ["\\[", "\\]"]
//         ],
//       },
//       options: {
//         enableMenu: false,
//       }
//   };
   const config = {
      "fast-preview": { disabled: true },
      loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
      tex: {
         packages: { "[+]": ["html", "img"] },
         inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
      ],
      displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
      ],
      },
      options: {
         enableMenu: false,
      }
   };
   const handleClickCommentary = () => {
      if(filterQuestion?.Commentary.length > 0){
         setOpen(true);
      }else{
         enqueueSnackbar(`해설이 없습니다. 빠른 시일 내 추가하겠습니다.`, { variant: 'warning'});
      }
   };
   const handleClickMemo = (memo) => {
      setMemoReview(memo);
      setOpenMemoReview(true);
   };
   useEffect(() => {
      setLoading(true);
      const tempFilterQuestion = props.homeworkData.Question_ids.filter((item) => { return item.Question === Number(QuestionNo) })[0];
      let tempStudent = [];
      if(tempFilterQuestion){
         for(let i = 0; i < props.homeworkData.Students.length; i++){
            let tempNo = props.homeworkData.Students[i].StudentNo;
            let tempName = props.homeworkData.Students[i].Student_id.Name;
            let tempCheck = 0;
            let tempTime = 0;
            let tempMemo = '';
            for(let j = 0; j < props.homeworkData.Students[i].Questions.length; j++){
               const tempQuestion = props.homeworkData.Students[i].Questions[j];
               if(tempQuestion.Question_id === tempFilterQuestion._id){
                  tempCheck = tempQuestion.Check;
                  tempTime = tempQuestion.Time;
                  tempMemo = tempQuestion.Memo ? tempQuestion.Memo : ''
               }
            }
            tempStudent.push({ StudentNo: tempNo, Name: tempName, Check: tempCheck, Time: tempTime, Memo: tempMemo });
         }
      }
      setTimeout(() => {
         setLoading(false);
      }, [500]);
      setFilterQuestion(tempFilterQuestion);
      setStudents(tempStudent);
   }, [props.homeworkData, QuestionNo]);

   return(
      <>
         <div className='content_section'>
            <Grid container justifyContent='center'>
               <Grid item xs={12} md={10} className='diagnosis_result_title'>
                  <h2>문제 {filterQuestion ? filterQuestion.Question-filterQuestion.Section*10000 : null}</h2>
                  <FormControl variant='standard' sx={{ minWidth: 80, textAlign: 'left', marginTop: '50px', marginBottom: '30px', float: 'right' }}>
                     <Select
                        value={QuestionNo}
                        onChange={(e) => navigate(`/teacher/${ClassNo}/homework/${HomeworkNo}/question/${e.target.value}`, { replace: true })}
                     >
                     {props.homeworkData.Question_ids.map((question) => (
                        <MenuItem value={question.Question}>{question.Question-(question.Section*10000)}</MenuItem>
                     ))}
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={12} md={10} className='question_content'>
                  <div className='question_front_cover' style={{backgroundColor: loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>{ loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
                  <MathJaxContext version={3} config={config}>
                        {filterQuestion?.Content.map((content) => (
                        <MathJax 
                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                        text={
                           content.replace('`\\(','').replace('\\)`','')
                        }
                        dynamic
                        className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                     />
                        ))}
                  </MathJaxContext>
                  <div className='diagnosis_questions_answer'>
                     {filterQuestion && typeof(filterQuestion?.Answer) !== 'string'  &&
                        filterQuestion?.NewChoices.map((choice, i) => (
                        filterQuestion?.Answer === i+1 ?
                        <div>
                           <RadioButtonChecked className='homework_question_answer'/>
                           <MathJaxContext version={3} config={config}>
                              <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                 choice.replace('`\\(','').replace('\\)`','')
                                 } inline dynamic/>
                           </MathJaxContext>
                        </div>
                        :
                        <div>
                           <RadioButtonUnchecked/>
                           <MathJaxContext version={3} config={config}>
                              <MathJax renderMode={'pre'} typesettingOptions={{fn:"tex2chtml"}} text={
                                 choice.replace('`\\(','').replace('\\)`','')
                                 } inline dynamic/>
                           </MathJaxContext>
                        </div>
                     ))}
                  </div>
               </Grid>
               <Grid item xs={12} md={10}>
                  <div className={filterQuestion?.Commentary && filterQuestion.Commentary.length > 0? 'teacher_homework_commentary' : 'teacher_homework_commentary teacher_homework_none_commentary'} onClick={handleClickCommentary}>해설보기</div>
               </Grid>
               <Grid item xs={12} md={10}>
                  <div className='teacher_dashboard_diagnosis_table' style={{ marginTop: '25px'}}>
                     <table >
                        <tr>
                           <th>전체정답률</th><th>평균풀이시간</th>
                        </tr>
                        <tr>
                           <td>{ChangeText.Percent(filterQuestion?.Info.Correct/filterQuestion?.Info.Counting)}</td>
                           <td>{ChangeText.QuestionMinute(filterQuestion?.Info.Time/filterQuestion?.Info.Correct)}</td>
                        </tr>
                     </table>
                     <table >
                        <tr>
                              <th>No.</th>
                              <th>이름</th>
                              <th>정답여부</th>
                              <th>풀이시간</th>
                              <th>메모여부</th>
                              <th></th>
                        </tr> 
                        {students.map((student, i) => (
                        <tr>
                           <td>{(i+1)}</td>
                           <td>{student.Name}</td>
                           <td>{ChangeText.QuestionCheck(student.Check)}</td>
                           <td>{ChangeText.QuestionMinute(student.Time)}</td>
                           <td>{student.Memo ? <Link href='javascript:;' onClick={() => handleClickMemo(student.Memo)}>O</Link> : 'X'}</td>
                           <td><Link href={`/teacher/${ClassNo}/homework/${HomeworkNo}/student/${student.StudentNo}`}>상세보기</Link></td>
                        </tr> 
                        ))}
                     </table>
                  </div>
               </Grid>
            </Grid>
         </div>
         {filterQuestion?.Commentary.length > 0 ? 
         <CommentaryModal open={open} setOpen={setOpen} Commentary={filterQuestion?.Commentary} config={config}/> : ""
         }
         <QuestionMemoReview 
            open={openMemoReview}
            setOpen={setOpenMemoReview}
            memoReview={memoReview}
         />
      </>
   );
};

export default TeacherHomeworkDetailQuestion;