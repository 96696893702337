import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from 'routes/NotFound';
import { Class, Price } from '.';
import { ClassSelect, ClassCreate, ClassEdit } from 'domain/teacher/class';
import { TeacherEdit } from 'domain/teacher';
const TeacherPresenter = () => {
  return (
    <Routes>
        <Route path='' element={<ClassSelect/>}/>
        <Route path='price/*' element={<Price/>}/>
        <Route path='edit' element={<TeacherEdit />}/>
        <Route path='create' element={<ClassCreate/>}/>
        <Route path=':ClassNo/edit' element={<ClassEdit/>}/>
        <Route path=':ClassNo/*' element={<Class/>}/>
        <Route path='/*' element={<NotFound/>} />
    </Routes>
  );
};

export default TeacherPresenter;