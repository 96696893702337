import React, { useState } from 'react';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Grid, Link, Button, ButtonGroup, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { AccessAlarm, Edit, KeyboardDoubleArrowRight, Stop, ArrowRight } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { QuestionOnlyMinute, MockExamQuestionKind } from 'components/function/ChangeText';
import { QuestionMemo } from 'components/user/question';

const MockExamDetailView = (props) => {
    const { kind } = useParams();
    const question = props.question;
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };

    const [openMemo, setOpenMemo] = useState(false);
    return(
        <div className='content_section_question'>
            <Grid container justifyContent='center' className='question_detail'>
                <Grid item xs={12} md={10} className='question_title'>
                    <div>{props.mockExamName}: {MockExamQuestionKind(kind)}</div>
                    {/* <div/> */}
                    <div>
                        <div><AccessAlarm /></div>
                        <div>{QuestionOnlyMinute(props.questionTimer)}</div>
                    </div>
                </Grid>
                <Grid container item xs={12} md={10} className='question_error_comment'>
                    <div>· 문제 또는 정답에 오류가 있을 경우 페이지 하단의 <b>채널톡</b> 또는 <b>이메일</b>(<Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link>)으로 문의주시면 감사하겠습니다.</div>
                </Grid>
                <Grid container item xs={12} md={10}>
                    <Grid item xs={12} className='question_content_info'>
                        <div>
                            <ButtonGroup variant='outlined' color='warning' aria-label='outlined button group'>
                                <Button onClick={() => setOpenMemo(true)}><Edit /></Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                    <Grid item xs={12} className='question_content'>
                        <div className='question_front_cover' style={{backgroundColor: props.loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>{ props.loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
                        <p>문제 {props.questionNumber} <span style={{ color: '#6F6F6F' }}>[{props.question.Point}점]</span></p>
                        <MathJaxContext version={3} config={config}>
                        {question.Content.map((content) => (
                            <MathJax 
                                renderMode={"pre"}
                                typesettingOptions={{fn:"tex2chtml"}}
                                text={content.replace('`\\(','').replace('\\)`','')}
                                hideUntilTypeset={'first'}
                                dynamic
                                className={
                                    content.indexOf('begin{array}') > -1 ?
                                        'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' :
                                        'mathjax_problem'
                                }
                            />
                        ))}
                        </MathJaxContext>
                    </Grid>
                    {!props.question.SingleAnswer ?
                    <Grid item xs={12} className='question_checks'>
                        {props.loading ? null :
                        <FormControl>
                            <RadioGroup
                                row
                                name='row-radio-buttons-group'
                                onChange={(event) => props.setChecked(event.target.value)}
                                value={props.checked}
                            >
                                {question && typeof(question.Answer) !== 'string' &&
                                question.NewChoices.map((choice, i) => (
                                <FormControlLabel value={(i+1)} control={<Radio color='warning'/>} label={
                                  <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                       choice.replace('`\\(','').replace('\\)`','')
                                       } inline dynamic/>
                                  </MathJaxContext>
                                } />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        }
                    </Grid>:
                    <Grid xs={12} className='mock_exam_single_answer_input'>
                        <TextField color='warning' placeholder='정답을 입력해주세요.' fullWidth type={'number'} value={props.singleAnswer} onChange={(e) => props.setSingleAnswer(e.target.value)} />   
                    </Grid>}
                </Grid>
                <Grid container item xs={12} md={10} className='question_button_group'>
                    <Grid item xs={8}>
                        {/* <Button 
                            onClick={props.handleClickNext} 
                            endIcon={<KeyboardDoubleArrowRight/>} 
                            color='warning' 
                            variant='outlined'
                        >
                            건너뛰기
                        </Button> */}
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            disabled={!props.checked && !props.singleAnswer}
                            onClick={props.submitQuestion}
                            endIcon={<ArrowRight/>}
                            color='warning'
                            variant='contained'
                            disableElevation
                        >
                            다음문제
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <QuestionMemo 
                open={openMemo}
                setOpen={setOpenMemo}
                canvasRef={props.canvasRef}
                mode={props.mode}
                setMode={props.setMode}
                ClearDraw={props.ClearDraw}
            />
        </div>
    );
};

export default MockExamDetailView;