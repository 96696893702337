import React from 'react';
import { ResponsiveLine } from '@nivo/line'

const DashboardLineChart = (props) => {
    return(
    <ResponsiveLine
        curve="monotoneX"
        data={props.data}
        margin={{ top: 50, right: 15, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        // yScale={{
        //     type: 'linear',
        //     min: 'auto',
        //     max: 'auto',
        //     // stacked: true,
        //     stacked: false,
        //     reverse: false
        // }}
        // yFormat=" >-.2f"
        yScale={{
            type: 'linear',
            stacked: false,
            min: props.min,
            max: props.max,
            reverse: true
        }}
        enableSlices={'x'}
        yFormat={(value) => {
            return Number(value) === 10 ? '-' : Number(value)+'등급';
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '학생 등급 추이',
            legendOffset: 36,
            legendPosition: 'middle',
            tickRotation: 45,
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '등급',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={10}
        // pointColor={{ theme: 'background' }}
        pointBorderWidth={0}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        onClick={(point, event) => console.log(point)}
        // tooltip={({ point }) => {
        //     return (
        //         <div
        //             style={{
        //                 background: 'white',
        //                 padding: '9px 12px',
        //                 border: '1px solid #ccc',
        //             }}
        //         >
        //             <div>{point.serieId}: <b>{point.data.y === 10 ? '-' : point.data.y+'등급'}</b> ({point.data.x})</div>
        //         </div>
        //     )
        // }}
        // legends={[
        //     {
        //         anchor: 'bottom-right',
        //         direction: 'column',
        //         justify: false,
        //         translateX: 100,
        //         translateY: 0,
        //         itemsSpacing: 0,
        //         itemDirection: 'left-to-right',
        //         itemWidth: 80,
        //         itemHeight: 20,
        //         itemOpacity: 0.75,
        //         symbolSize: 12,
        //         symbolShape: 'circle',
        //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemBackground: 'rgba(0, 0, 0, .03)',
        //                     itemOpacity: 1
        //                 }
        //             }
        //         ]
        //     }
        // ]}
    />
    );
};

export default DashboardLineChart;