import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { Layout } from 'components/admin/layout';
import { SearchForm } from 'components/form';
import * as ChangeText from 'components/function/ChangeText';

const SchoolList = () => {
    const pages = [
        { title: '학교 관리'},
        { title: '학교 목록'}
    ];
    const title = { main: '학교 목록', sub: 'school'};
    const [schools, setSchools] = useState([
        {
            id: '',
            Name: '',
            Address: ''
        }
    ]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);

    // 컬럼
    const columns = [
        { field: 'id', headerName: 'No.', width: 120 },
        { field: 'Name', headerName: '이름', width: 200, renderCell: (params)=>(<div className='list_click'>{params.row.Name}</div>) },
        { field: 'Address', headerName: '주소', width: 150 },
        { field: 'WooroRank', headerName: '순위', width: 110 },
        { field: 'WooroZscore', headerName: '점수', width: 110 },
        { field: 'State', headerName: '학교상태', width: 100 },
        { field: 'CreatedAt', headerName: '생성일시', width: 150 }
    ];

    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    // 필터
    const filterSchools = (datas) => {
        datas.sort();
        datas = datas.filter((data) => {
            return data.Name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    data.Address.toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
        });
        return datas
    };

    const handleClickCell = (event) => {
        if(event.field === 'Name'){
            navigate(`/admin/school/${event.row.Link}`);
        }else{
            return false;
        }
    };

    // 데이터 로드
    useEffect(() => {
        const loadSchools = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/school/${cookies.LoginKey}`, config).then((Response) => {
                const loadData = Response.data;
                let schoolData = [];
                for(let i = 0; i < loadData.length; i++){
                    schoolData.push(
                        { 
                            id: loadData[i].NEIS_code,
                            Name: loadData[i].Name,
                            Address: `${loadData[i].Local} ${loadData[i].County}`,
                            WooroRank: loadData[i].WooroRank,
                            WooroZscore: loadData[i].WooroZscore,
                            State: ChangeText.SchoolState(loadData[i].State),
                            CreatedAt: loadData[i].CreatedAt,
                            Link: loadData[i]._id
                        }
                    )
                }
                setLoading(false);
                setSchools(schoolData); 
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadSchools();
    },[]);
    
    return (
        <Layout pages={pages} title={title}>
            <Grid container justifyContent="space-between">
                <Grid xs={4}>
                    <SearchForm search={search} handleChange={handleChangeSearch} searchGuide='이름 / 주소로 검색' />
                </Grid>
                <Grid xs={8} textAlign={'right'}></Grid>
                <Grid xs={12} className="list_table">
                    <DataGrid
                        rows={filterSchools(schools)}
                        columns={columns}
                        pageSize={15}
                        onCellClick={handleClickCell}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc'}]
                            }
                        }}
                        components={{
                            LoadingOverlay: LinearProgress,
                        }}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
};

export default SchoolList;