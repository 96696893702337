import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { StudentUpdate } from 'components/user/student';
import { SearchSchoolModal } from 'components/modal';
import { SearchFormFind } from 'components/form';
import { StudentSchoolList } from 'components/user/student';

const UserEdit = ()=> {
  const [cookies, setCookie] = useCookies(['LoginKey', 'Profile', 'UserNo', 'Name']);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [student, setStudent] = useState({
    School_id: { Name: '' },
    Attending: '',
    Grade: '',
    Profile: '',
    Phone: '',
    Name: '',
    Gender: '',
    Email: '',
    Login: 1,
    _id: ''
  });
  const [beforeImage, setBeforeImage] = useState('');
  const [searchSchool, setSearchSchool] = useState('');
  const [openSearchSchool, setOpenSearchSchool] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [schools, setSchools] = useState([]);
  const [firstSearch, setFirstSearch] = useState(false);
  const [attendingDisabled, setAttendingDisabled] = useState(false);
  const [imgBase64, setImgBase64] = useState();
  const [imgFile, setImgFile] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleClickDeleteProfile = () => {
      let DeleteProfile = { ...student };
      DeleteProfile.Profile = '';
      setStudent(DeleteProfile);
      setImgBase64('');
      setImgFile('');
  };

  const handleChangeProfile = (event) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      // 2. 읽기가 완료되면 아래코드가 실행됩니다.
      const base64 = reader.result;
      if (base64) {
        setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
      }
    }
    if(event.target.files[0]){
      reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
      setImgFile(event.target.files[0]); // 파일 상태 업데이트
    }
  };

  const handleChange = (event) => {
    let changeStudent = { ...student };
    changeStudent[event.target.name] = event.target.value;
    setStudent(changeStudent);
  };

  const handleClickOpenSearchSchool = () => {
    setOpenSearchSchool(true);
  }
  const handleClickCloseSearchSchool = () => {
    setOpenSearchSchool(false);
  };
  const handleChangeSearchSchool = (event) => {
    setSearchSchool(event.target.value);
  };

  const handleClickSchool = (event) => {
    if(event.Name === '외국고'){
      setStudent({ ...student, School_id: event, Attending: 3 });
      setAttendingDisabled(true);
    // }else if(event.Name === '중학생'){
    //   setStudent({ ...student, School_id: event, Attending: 4 });
    //   setAttendingDisabled(true);
    }else if(event.Name === '검정고시'){
      setStudent({ ...student, School_id: event, Attending: 5 });
      setAttendingDisabled(true);
    }else if(event.Name === '기타'){
      setStudent({ ...student, School_id: event, Attending: 6 });
      setAttendingDisabled(true);
    }else{
      setStudent({ ...student, School_id: event });
      setAttendingDisabled(false);
    }
    setOpenSearchSchool(false);
    setSearchSchool('');
    setSchools([]);
    setFirstSearch(false);
  };

  const handleChangeAttending = (event) => {
    const value = event.target.value;
    let changeStudent = { ...student };
    changeStudent.Attending = value;
    if(value === '2'){
      changeStudent.Grade = '';
    }else if(value === '3'){
      changeStudent.School = { _id: '61ac75a330a84ae5abd659d0', Name: '외국고', Local: '기타', County: '' };
    }else if(value === '4'){
      changeStudent.School = { _id: '61ac75a330a84ae5abd65b59', Name: '중학생', Local: '기타', County: '' };
    }else if(value === '5'){
      changeStudent.Grade = '';
      changeStudent.School = { _id: '61ac75a330a84ae5abd6535e', Name: '검정고시', Local: '기타', County: '' };
    }else if(value === '6'){
      changeStudent.Grade = '';
      changeStudent.School = { _id: '61ac75a330a84ae5abd654b4', Name: '기타', Local: '기타', County: '' };
    }
    setStudent(changeStudent);
  };

  const handleClickSearchSchool = async() => {
    if(searchSchool){
      setLoadingSearch(true);
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/school/${searchSchool}`, config).then((Response) => {
        setSchools(Response.data);
        setFirstSearch(true);
        setLoadingSearch(false);
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      return false;
    }
  };

  const getStudentInfo = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/student/${cookies.LoginKey}`, config).then((Response) => {
      setStudent(Response.data.student);
      setBeforeImage(Response.data.student.Profile);
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  useEffect(() => {
    if(cookies.LoginKey){
      getStudentInfo();
    }else{
      enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error' });
      navigate(`/login`);
    }
  }, []);
  
  const handleClickUpdate = async() => {
    setUpdating(true);
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      Attending: student.Attending,
      School_id: student.School_id._id,
      Grade: student.Grade,
      Phone: student.Phone,
      Name: student.Name,
      loginKey: cookies.LoginKey,
      Profile: student.Profile,
    });
    await axios.put(`/api/student`, body, config).then((Response) => {
      enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
      let date = new Date();
      date.setDate(date.getDate()+365);
      if (imgFile) {
        uploadProfile();
      }else{
        setCookie('Profile', student.Profile, { path: '/', expires: date });
      }
      setCookie('Name', student.Name, { path: '/', expires: date });

      setTimeout(()=> {
        setUpdating(false);
      }, [1000]);
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const uploadProfile = async() => {
    if(imgFile){
      const formData = new FormData();
      formData.append('loginKey', cookies.LoginKey);
      formData.append('imgFile', imgFile);
      formData.append('beforeImage', beforeImage);
      const config = { headers: { 'content-type': 'multipart/form-data' }};
      await axios.put(`/api/student/profile`, formData, config).then((Response) => {
        let date = new Date();
        date.setDate(date.getDate()+365);
        setCookie('Profile', Response.data.Profile, { path: '/', expires: date });
        setStudent({ ...student, Profile: Response.data.Profile });
        setImgBase64();
        setImgFile(null);
        setBeforeImage(Response.data.Profile);
      }).catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    }else{
      return false
    }
  };

  const handleClickDelete = () => {
    if(student.Login === 1){
      UserDelete();
    }else if(student.Login === 2){
      kakaoLogin();
    }else if(student.Login === 3){
      NaverAccessKeyRemove();
    }else{
      UserDelete();
    }
  };

  const UserDelete = async() => {
    setDeleting(true);
    await axios({
      method: 'DELETE',
      url: `/api/register`,
      data: {
        loginKey: cookies.LoginKey
      }
    })
    .then((Response) => {
      if(!Response.data.ErrorMessage){
        setTimeout(() => {
          enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
          navigate('/restore', { replace: true });
        }, [2500]);
      }else{
        enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const kakaoLogin = () => {
    const kakao = {
      clientID: '119188e529f2e3e1386856c951f9fb11',
      redirectUri: 'https://wooromath.com/user/edit'
    };
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${kakao.clientID}&redirect_uri=${kakao.redirectUri}`;
  };

  const KakaoAccessKeyRemove = async(code) => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      kakaoCode: code
    });
    await axios.post(`/api/register/kakao/remove`, body, config).then((Response) => {
      UserDelete();
    }).catch((Error) => {
      console.log(Error);
    });
  };

  const NaverAccessKeyRemove = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({
      loginKey: cookies.LoginKey
    });
    await axios.post(`/api/register/naver/remove`, body, config).then((Response) => {
      UserDelete();
    }).catch((Error) => {
      console.log(Error);
    });
  };

  useEffect(() => {
    if(location.search && location.search.indexOf('code') > -1){
      KakaoAccessKeyRemove(location.search.split('=')[1]);
    }
  }, [location]);
  
  return (
    <Layout>
      <StudentUpdate 
        student={student}
        attendingDisabled={attendingDisabled}
        imgBase64={imgBase64}
        updating={updating}
        deleting={deleting}
        setDeleting={setDeleting}
        handleChange={handleChange}
        handleChangeAttending={handleChangeAttending}
        handleClickOpenSearchSchool={handleClickOpenSearchSchool}
        handleClickDeleteProfile={handleClickDeleteProfile}
        handleChangeProfile={handleChangeProfile}
        handleClickUpdate={handleClickUpdate}
        handleClickDelete={handleClickDelete}
      />
      <SearchSchoolModal 
          openSearch={openSearchSchool} 
          handleClickCloseSearch={handleClickCloseSearchSchool}
          title='학교 검색'
      >
          <SearchFormFind 
            search={searchSchool}
            handleChange={handleChangeSearchSchool}
            searchGuide='학교 이름으로 검색'
            loading={loadingSearch} 
            handleClick={handleClickSearchSchool}
          />
          <StudentSchoolList 
            schools={schools} 
            loading={loadingSearch} 
            search={searchSchool} 
            handleClickSchool={handleClickSchool}
            firstSearch={firstSearch}
          />
      </SearchSchoolModal>
    </Layout>
  );
};

export default UserEdit;