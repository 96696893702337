import React from "react";
import { Routes, Route } from "react-router-dom";
import { BoardCreate,BoardList,BoardDetail,BoardUpdate} from "domain/Academy/board";
import NotFound from "routes/NotFound";

const BoardPresenter = () => {
  return (
    <Routes>
      <Route path="" element={<BoardList />} />
      <Route path="create" element={<BoardCreate />} />
      <Route path="detail/:noticesNo" element={<BoardDetail />} />
      <Route path="update/:noticesNo" element={<BoardUpdate />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default BoardPresenter;
