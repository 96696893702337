import { Button,Avatar ,Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow ,Box} from "@mui/material";


const StudentListView = (props) => {

  return (
    <Grid item={true}  lg={12} xl={12}>
      {props.rows.length > 0 ? 
        <TableContainer>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <TableHead className="academy_table_head">
              <TableRow>
                <TableCell width={'60px'}>No.</TableCell>
                <TableCell align="left" width={'80px'}>프로필</TableCell>
                <TableCell align="center" width={'130px'}>이름</TableCell>
                <TableCell align="center" width={'100px'}>이메일</TableCell>
                <TableCell align="center" width={'50px'}>등급</TableCell>
                <TableCell align="center" >학교</TableCell>
                <TableCell align="center" >학년</TableCell>
                <TableCell align="center" >전화번호</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props.rows.map((row, i) => (
                <TableRow key={i} sx={{ 'th': {  },'td':{},marginBottom: '10px'  }}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">
                    <Avatar src={row.Profile} onClick={() => {props.handleClickCell(row.StudentNo) }} sx={{cursor:"pointer"}}/>
                  </TableCell>
                  <TableCell align="center" onClick={() => { props.handleClickCell(row.StudentNo) }} sx={{cursor:"pointer",color:"#1976d2"}}>{row.name}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.rating}</TableCell>
                  <TableCell align="center">{row.school}</TableCell>
                  {row.Grade?
                  <TableCell align="center">{row.Grade}학년</TableCell>
                  :
                  <TableCell align="center"></TableCell>
                  }
                  <TableCell align="center">{row.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        :
        <h1 style={{textAlign:"center",marginTop:"50px"}}>참여한 학생이 없습니다.</h1>
      }
    </Grid>
  );
}
 
export default StudentListView;