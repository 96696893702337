import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const CommentaryModal = (props) => {
    
    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogContent>
                <MathJaxContext version={3} config={props.config} >
                    {props.Commentary?.map((item) => (
                    <MathJax
                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                        text={
                            item.replace('`\\(','').replace('\\)`','')
                        }
                        dynamic
                        className={item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                    />
                    ))}
                </MathJaxContext>
            </DialogContent>
            {/* <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {props.Commentary?.map((commentary) => (
                    <img
                        alt='Commentary Picture'
                        src={commentary?.includes('https://') ?
                            commentary :
                            `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${commentary}`
                        }
                        style={{ maxWidth: '100%', display: 'block' }}
                    />
                    ))}
                </DialogContentText>
            </DialogContent> */}
            <DialogActions>
                <Button onClick={() => props.setOpen(false)}>닫기</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CommentaryModal;