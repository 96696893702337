import React, { useState,useEffect } from 'react';
import { Grid, Button, FormControl, Select, MenuItem,FormControlLabel, Checkbox, Link} from '@mui/material';
import NormalDistribution from 'normal-distribution'; 
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import NormalDistributionImage from 'img/normal_distribution.png'
import * as ChangeText from 'components/function/ChangeText';
import { DashboardBarChart,DashboardDetailLineChart } from './';

const TeacherDashboardDetail = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { ClassNo } = useParams();
    const student = props.studentData;
    const { enqueueSnackbar } = useSnackbar();
    
    const [selectPart, setSelectPart] = useState(false);
    const [lineChartDatas, setLineChartDatas] = useState([]);
    const [lineChartMonth, setLineChartMonth] = useState([]);
    const nowDate = new Date(new Date().setDate(1));
    const [nowMonth,setNowMonth]=useState(`${nowDate.getMonth()+1}월`);
    const normDist = new NormalDistribution(0, 1);
    const schoolDist = new NormalDistribution(student.Student_id.School_id.WooroZscore, 1);
    let allZscore = 0;
    let schoolZscore = 0;
    for(let i = 0; i < student.DiagnosisResult.length; i++){
        allZscore += normDist.cdf(student.DiagnosisResult[i].ZScore);
        schoolZscore += schoolDist.cdf(student.DiagnosisResult[i].ZScore);
    }
    const allGrade = student.DiagnosisResult.length ? 1 - allZscore / student.DiagnosisResult.length : 1;
    const schoolGrade = student.DiagnosisResult.length ? 1 - schoolZscore / student.DiagnosisResult.length : 1;
    const allMean = 0.5;
    const schoolMean = student.Student_id.School_id.WooroZscore ? normDist.cdf(student.Student_id.School_id.WooroZscore) : 0;
    
    const schoolBar = (0.15+((schoolMean)*0.7))*100 < 85 ? (0.15+((schoolMean)*0.7))*100 : 85;
    const studentBar = (0.155+((1-allGrade)*0.7))*100 < 79.45 ? (0.155+((1-allGrade)*0.7))*100 : 79.45;
    const diagnosisNowMonth = `${new Date().getFullYear()}${new Date().getMonth()+1 < 10 ? `0${new Date().getMonth()+1}` : new Date().getMonth()+1}`;
    const handleClickPart = (part) => {
      setSelectPart(part);
      setTimeout(() => {
        window.location.href='#partTitle'
      }, [100]);
    };    

    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    const lineChartData = []; 
    useEffect(()=>{
        for (let i = 0; i < 12; i++) {
            const nowDate = new Date(new Date().setDate(1));
            const nowYear = nowDate.getFullYear(); 
            const month = new Date(nowDate.setMonth(nowDate.getMonth() - 11 + i)).getMonth() + 1;
            const year = nowYear - Math.floor((month + 11 - i) / 12)

            lineChartData.push({'id':`${year}년 ${month}월` , data:[]})
            const lastDay = new Date(nowDate.getFullYear(), parseInt(lineChartData[i]?.id.slice(6)), 0).getDate();
            for (let j = 1; j  <= lastDay; j++) {
                lineChartData[i].data.push({ 'x': `${j}일`,'y':[]});                
            };
        };
        for (let i = 0; i < student.MonthZscoreSection.length; i++) {
            const data = student.MonthZscoreSection[i];
            for (let j = 0; j < data.length; j++) {
                const item = data[j];
                const date = new Date(item.CreatedAt);
                const monthIndex = date.getMonth() + 1; 
                const day = date.getDate(); 
                const monthObj = lineChartData.find(m => m.id.slice(6) === `${monthIndex}월`);
                if (monthObj) {
                  const dayObj = monthObj.data.find(d => d.x === `${day}일`);
                  if (dayObj) {
                    dayObj.y.push({ZScore:item.ZScore});
                  }
                }
                
            }
        }

        for (let i = 0; i < lineChartData.length; i++) {
            for (let j = 0; j < lineChartData[i].data.length; j++) {
                let tumpSum = 0;
                if (lineChartData[i].data[j].y.length > 0) { //값이 있을때
                    for (let k = 0; k < lineChartData[i].data[j].y.length; k++) {
                        tumpSum += normDist.cdf(lineChartData[i].data[j].y[k].ZScore);        
                    }
                    tumpSum = tumpSum/lineChartData[i].data[j].y.length
                    lineChartData[i].data[j].y = ChangeText.StaNine(tumpSum);
                }else{  //값이 없을때
                    if (i === 0 && j === 0) { // 11개월 전, 1일
                        lineChartData[i].data[j].y = 9;
                    }else{
                        if (j === 0) { // 1일     
                            lineChartData[i].data[j].y = lineChartData[i-1].data[lineChartData[i-1].data.length-1].y;
                        }else{  // 1일 이외
                            lineChartData[i].data[j].y = lineChartData[i].data[j-1].y;
                        }
                    }
                }
            }
        };


        let monthData = lineChartData.filter((item)=> item.id.slice(6) === nowMonth)
        setLineChartDatas(monthData)
        setLineChartMonth(lineChartData)
        
    },[student,nowMonth]);

    const handleChangeMonth= (month) => {
        setNowMonth(month.slice(6));
    };
    const monthDiagnosis = (e) =>{
        navigate(`${location.pathname}/month/${e.data.Link}`)
    }

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>{student.Student_id.Name} 학생의 리포트</h2>
                    <FormControl variant='standard' sx={{ minWidth: 80, textAlign: 'left', marginTop: '50px', marginBottom: '30px', float: 'right' }}>
                        <Select
                        id='studentsSort'
                        value={student.StudentNo}
                        onChange={(e) => navigate(`/teacher/${ClassNo}/dashboard/${e.target.value}`, { replace: true })}
                        >
                            {props.studentList.map((student) => (
                                <MenuItem value={student.StudentNo}>{student.Name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5} className='dashboard_graph'>
                    <div>
                        <p>예상 석차 분석</p>
                        <img src={NormalDistributionImage} alt='normal distribution' />
                        <span>전국 상위 {ChangeText.IntPercent(allGrade)}<br/>학교 상위 {ChangeText.IntPercent(schoolGrade)}</span>
                        <div className='dashboard_graph_legend'>
                            <div className='school_avg'><p/>학교평균</div>
                            <div className='all_avg'><p/>전국평균</div>
                            <div className='my_grade'><p/>학생 성적</div>
                        </div>
                        <div className='dashboard_graph_bar'>
                            <div className='school_avg' style={{left: `${schoolBar}%`}}/>
                            <div className='all_avg' style={{left: `${(0.15+((allMean)*0.7))*100}%`}}/>
                            <div className='my_grade' style={{left: `${studentBar}%`}}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={5} className='dashboard_myreport'>
                    <div>
                    <p>월별 진단 횟수 <Link className='parent_month_diagnosis' onClick={() => navigate(`${location.pathname}/month/${diagnosisNowMonth}`)}>월별 진단 결과 상세보기 &gt;</Link></p>
                        <Grid style={{ padding: '16px !important'}}>
                        <div style={{ height: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }} className='parent_month_diagnosis_count'>
                                <DashboardBarChart monthDiagnosis={monthDiagnosis} data={student.MonthZscoreCount.reverse()} x={'Month'} y={'진단 횟수'} ylabel={'진단 횟수(회)'} min={10} max={1}/>
                            </div>
                        </Grid>
                    </div>
                </Grid>
                
                <Grid item xs={12} md={10}>
                    <span>· 데이터가 부족하여 결과가 정확하지 않을 수 있습니다.<br/>· 학교 성적은 우로매쓰에 가입한 해당 학교 학생들의 분석 결과로 출력됩니다.</span>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div style={{ height: '300px', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            {lineChartDatas[0] ? 
                            <DashboardDetailLineChart data={lineChartDatas} />:
                            <p style={{textAlign: 'center', paddingTop: '30px', margin: '0px'}}>선택된 데이터가 없습니다.</p>}
                    </div>

                </Grid>
                <Grid item xs={12} md={10} sx={{textAlign:"center"}}>
                    {
                        lineChartMonth.map((month,i)=>(
                            <FormControlLabel
                            key={i}
                            label={month.id.slice(6)}
                            control={<Checkbox color='warning'onChange={() => handleChangeMonth(month.id)} checked={nowMonth === month.id.slice(6)} />}
                        />
                        ))
                    }
                </Grid>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>진단 결과 상세보기</h2>
                </Grid>
                <Grid container item xs={12} md={10} className='dashboard_parts'>
                    {props.parts.map((part, i) => (
                    <Grid item xs={12} sm={6} md={4} onClick={() => handleClickPart(i)}>
                        <div>
                        <p>{part.name} <b>상위 {part.percent ? ChangeText.IntPercent(part.percent) : '100%'}</b></p>
                        </div>
                    </Grid>
                    ))}
                    <Grid id='partTitle'></Grid>
                </Grid>
                {selectPart !== false  ? 
                <>
                <Grid item xs={12} md={10} className='diagnosis_result_title'>
                    <h2>{selectPart !== false ? props.parts[selectPart].name : null}</h2>
                </Grid>
                <Grid container item xs={12} md={10} className='dashboard_part_sections'>
                    <Grid container item xs={12}>
                        {props.parts[selectPart].subSections.map((subSection) => (
                            subSection.sections.map((section) => (
                            <Grid item xs={6} sm={6} md={4} lg={3}>
                                <div style={{ textAlign: 'center' }}>
                                    <p>
                                        {section.percent ? 
                                        <b>{`상위 ${ChangeText.IntPercent(section.percent)}`}</b>:
                                        '진단 결과 없음'}
                                    </p>
                                    <p className='dashboard_part_sections_name'>{subSection.name}<div>&nbsp;&gt;&nbsp;<b>{section.name}</b></div></p>
                                    <Button variant='outlined' color='warning' size='small' disabled={section.percent ? false : true} onClick={() => navigate(`${location.pathname}/${section.section}`)}>진단 결과 보기</Button>
                                </div>
                            </Grid>
                            ))
                        ))}
                    </Grid>
                </Grid>
                </>:null}
            </Grid>
        </div>
    );
};

export default TeacherDashboardDetail;