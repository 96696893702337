import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/teacher/layout';
import { EvaluationDetailView } from 'components/teacher/class/evaluation';

const EvaluationDetail = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { ClassNo, EvaluationNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const [evaluation, setEvaluation] = useState({
        EvaluationElement: [],
        Students: [],
        Score: {
            Labels: [],
            Scores: [],
            LabelsScores: []
        }
    });
    const [updating, setUpdating] = useState(false);

    const handleClickStateBtn = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify(evaluation);
        await axios.patch(`/api/teacher/evaluation/${ClassNo}/${EvaluationNo}/${cookies.LoginKey}`, body, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setEvaluation({ ...evaluation, State: evaluation.State === 1 ? 2 : 1 });
                setTimeout(() => {
                    setUpdating(false);
                    enqueueSnackbar(res.data.Message, { variant: 'info' });
                }, [800]);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    const loadData = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/teacher/evaluation/${ClassNo}/${EvaluationNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setEvaluation(res.data.EvaluationData);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [enqueueSnackbar]);

    useEffect(() => {
        loadData();
    }, []);

    return(
        <Layout>
            <EvaluationDetailView 
                evaluation={evaluation}
                handleClickStateBtn={handleClickStateBtn}
                updating={updating}
            />
        </Layout>
    );
};

export default EvaluationDetail;