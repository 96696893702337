import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import NormalDistribution from 'normal-distribution'; 

import * as ChangeText from 'components/function/ChangeText';

const TeacherDashboardSection = (props) => {
    const { Section } = useParams();
    const normDist = new NormalDistribution(0, 1);
    const schoolDist = new NormalDistribution(props.studentData.School_id.WooroZscore, 1);

    return(
        <div className='content_section'>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={6}>
                    <div className='teacher_dashboard_diagnosis'>
                        <h2>[진단 결과]</h2>
                        <p>학생 : <b>{props.studentData.Name}</b></p>
                        <p>학교 : <b>{props.studentData.School_id.Name}</b> · <b>{props.studentData.Attending === 1 ? ChangeText.Grade(props.studentData.Grade) : ''} {ChangeText.Attending(props.studentData.Attending)}</b></p>
                        <hr/>
                        <p>단원 : <b>{ChangeText.Part(Section)}</b> &gt; <b>{ChangeText.MiddlePart(Section)}</b> &gt; <b>{ChangeText.Section(Section)}</b></p>
                        <p>진단 횟수 : <b>{props.diagnosisDatas.length}회</b></p>
                        <p>전국 성적 : <b>{ChangeText.Percent(1-normDist.cdf(props.diagnosisDatas[0].ZScore))}</b></p>
                        <p>학교 성적 : <b>{ChangeText.Percent(1-schoolDist.cdf(props.diagnosisDatas[0].ZScore))}</b></p>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='teacher_dashboard_diagnosis_table'>
                        <table>
                            <tr>
                                <th>진단일</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th colSpan={2}>전국 상위</th>
                                <th colSpan={2}>학교 상위</th>
                            </tr>
                            {props.diagnosisDatas.map((diagnosis, i) => (
                                <tr>
                                    <td>{ChangeText.YyyyMmDd(diagnosis.createdAt)}</td>
                                    {diagnosis.Questions.map((Question) => (
                                    <td>{Question.Check === 1 ? 'O' : 'X'}</td>
                                    ))}
                                    <td>{ChangeText.Percent(1-normDist.cdf(diagnosis.ZScore))}</td>
                                    <td>
                                        {i === props.diagnosisDatas.length-1 ?
                                        <span style={{ color: 'green' }}>New</span> :
                                        (normDist.cdf(diagnosis.ZScore) - normDist.cdf(props.diagnosisDatas[(i+1)].ZScore)) >= 0?
                                        <span style={{ color: 'red' }}>↑ {ChangeText.Percent(normDist.cdf(diagnosis.ZScore) - normDist.cdf(props.diagnosisDatas[(i+1)].ZScore))}p</span>:
                                        <span style={{ color: 'blue' }}>↓ {ChangeText.Percent(normDist.cdf(props.diagnosisDatas[(i+1)].ZScore) - normDist.cdf(diagnosis.ZScore))}p</span>
                                        }
                                    </td>
                                    <td>{ChangeText.Percent(1-schoolDist.cdf(diagnosis.ZScore))}</td>
                                    <td>
                                        {i === props.diagnosisDatas.length-1 ?
                                        <span style={{ color: 'green' }}>New</span> :
                                        (schoolDist.cdf(diagnosis.ZScore) - schoolDist.cdf(props.diagnosisDatas[(i+1)].ZScore)) >= 0?
                                        <span style={{ color: 'red' }}>↑ {ChangeText.Percent(schoolDist.cdf(diagnosis.ZScore) - schoolDist.cdf(props.diagnosisDatas[(i+1)].ZScore))}p</span>:
                                        <span style={{ color: 'blue' }}>↓ {ChangeText.Percent(schoolDist.cdf(props.diagnosisDatas[(i+1)].ZScore) - schoolDist.cdf(diagnosis.ZScore))}p</span>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default TeacherDashboardSection;