import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { Layout } from 'components/teacher/layout';
import { EvaluationListView } from 'components/teacher/class/evaluation';

const EvaluationList = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { ClassNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const [open, setOpen] = useState(false);
    const [evaluations, setEvaluations] = useState([{

    }]);
    const [selectEvaluation, setSelectEvaluation] = useState({});

    const loadDatas = useCallback(async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/teacher/evaluation/${ClassNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                setEvaluations(res.data.EvaluationDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    }, [enqueueSnackbar]);

    useEffect(() => {
        loadDatas();
    }, []);

    const handleClickDeleteBtn = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.delete(`/api/teacher/evaluation/${ClassNo}/${selectEvaluation.EvaluationNo}/${cookies.LoginKey}`, config)
        .then((res) => {
            if(!res.data.ErrorMessage){
                loadDatas();
                enqueueSnackbar(res.data.Message, { variant: 'info' });
                setOpen(false);
                setSelectEvaluation({});
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
            }
        })
        .catch((err) => {
            enqueueSnackbar('Network Error!', { variant: 'error' });
        });
    };

    return(
        <Layout>
            <EvaluationListView 
                evaluations={evaluations}
                setSelectEvaluation={setSelectEvaluation}
                open={open}
                setOpen={setOpen}
                handleClickDeleteBtn={handleClickDeleteBtn}
            />
        </Layout>
    );
};

export default EvaluationList;