import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { MockExam, MockExamResult } from 'domain/parent/mockExam';

const MockExamPresenter = () => {
  return (
    <Routes>
      <Route path='' element={<MockExam/>}/>
      <Route path=':MockExamNo/:Kind' element={<MockExamResult />}/>
      <Route path='*' element={<NotFound/>} />
    </Routes>
  );
};

export default MockExamPresenter;