import React from 'react'
import { Routes, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';
import { Dashboard, DashboardDetail, DashboardDetailMonth } from 'domain/parent/dashboard';

const DashboardPresenter = () => {
  return (
    <Routes>
        <Route path='' element={<Dashboard/>} />
        <Route path=':Section' element={<DashboardDetail/>} />
        <Route path='month/:Month' element={<DashboardDetailMonth/>} />
        <Route path='/*' element={<NotFound/>} />
    </Routes>
  );
};

export default DashboardPresenter;