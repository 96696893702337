import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/teacher/layout';
import { TeacherDashboardDetailMonth } from 'components/teacher/class/dashboard';
const DashboardMonth = () => {
  const [cookies] = useCookies(['LoginKey']);
  const { ClassNo, StudentNo ,Month } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  
  const [studentData,setStudentData] = useState();
  const [diagnosisDatas, setDiagnosisDatas] = useState([{
    ZScore: '',
    Questions: [],
    createdAt: ''
}]);

useEffect(() => {
  const loadStudentData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/teacher/dashboard/${ClassNo}/${StudentNo}/${cookies.LoginKey}`, config).then((Response) => {
      if(!Response.data.ErrorMessage){
          let loadDiagnosisList = Response.data.Diagnosis;  
          for(let i = 0; i < loadDiagnosisList.length; i++){
              let tempDiagnosis = loadDiagnosisList.filter((item) => {
                  return item.createdAt <= loadDiagnosisList[i].createdAt })
              tempDiagnosis.sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
              });
              tempDiagnosis = tempDiagnosis.filter((item, i) => {
                  return (tempDiagnosis.findIndex((item2, j) => {     
                      return item.Section === item2.Section;
                  }) === i);
              });       
       
              if(tempDiagnosis.length > 1){
                  let tempAllGrade = 0;
                  for(let j = 0; j < tempDiagnosis.length; j++){
                      tempAllGrade += tempDiagnosis[j].ZScore;
                  }
                  loadDiagnosisList[i].AllGrade = tempAllGrade/tempDiagnosis.length;
              }else if(tempDiagnosis.length === 1){
                  loadDiagnosisList[i].AllGrade = loadDiagnosisList[i].ZScore;
              }else{
                  loadDiagnosisList[i].AllGrade = null;
              }
          }
          // 최신 날짜 순 정렬
          loadDiagnosisList.sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
          });
          setDiagnosisDatas(loadDiagnosisList);
          setStudentData(Response.data.Student.Student_id)
      }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
          navigate(-1);    
      }
    })
    .catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });      
  }
  loadStudentData();
}, []);

const filterDiagnosisDatas = (datas) => {
  const standardDate = new Date(`${Month.substring(0, Month.length-2)}-${Month.substring(Month.length, 4)}-01`);
  let maxDate = new Date(standardDate.setMonth(standardDate.getMonth()+1));
  maxDate.setDate(1);
  maxDate.setHours(0);
  maxDate.setMinutes(0);
  maxDate.setSeconds(0);
  let minDate = new Date(`${Month.substring(0, Month.length-2)}-${Month.substring(Month.length, 4)}-01`);
  minDate.setDate(1);
  minDate.setHours(0);
  minDate.setMinutes(0);
  minDate.setSeconds(0);
  const returnDatas = datas.filter((item) => { return new Date(item.createdAt) >= minDate && new Date(item.createdAt) < maxDate });
  if(returnDatas[0]){
      return returnDatas;
  }else{
      return [{
          ZScore: '',
          Questions: [],
          createdAt: '',
          AllGrade: ''
      }];
  }
};

const lastDiagnosisData = (datas) => {
  let minDate = new Date(`${Month.substring(0, Month.length-2)}-${Month.substring(Month.length, 4)}-01`);
  minDate.setDate(1);
  minDate.setHours(0);
  minDate.setMinutes(0);
  minDate.setSeconds(0);
  const returnDatas = datas.filter((item) => { return new Date(item.createdAt) < minDate });
  if(returnDatas[0]){
      return returnDatas[0];
  }else{
      return {
          ZScore: '',
          Questions: [],
          createdAt: '',
          AllGrade: ''
      };
  }
};
  return ( 
    <Layout>
      <TeacherDashboardDetailMonth studentData={studentData} diagnosisDatas={filterDiagnosisDatas(diagnosisDatas)} lastDiagnosisData={lastDiagnosisData(diagnosisDatas)}/>
    </Layout>
   );
}
 
export default DashboardMonth;