import React, { useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalActivityOutlined } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import tossKey from 'config/toss';

const StudentTicketPriceView = (props) => {
    const clientKey = tossKey;
    const [cookies] = useCookies(['LoginKey']);
    const [creating, setCreating] = useState(false);
    const tossPayments = window.TossPayments(clientKey) // 클라이언트 키로 초기화하기

    const handleClickPrice = async(key) => {
        setCreating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify({
            price: props.ticketPrice[key].Price,
            count: props.ticketPrice[key].Count,
            orderName: `모의고사 응시권 ${props.ticketPrice[key].Count}개`,
            loginKey: cookies.LoginKey
        });
        await axios.post(`/api/student/ticket`, data, config).then((res) => {
            tossPayments.requestPayment('카드', { // 결제 수단 파라미터
                // 결제 정보 파라미터
                amount: res.data.result.Price,
                orderId: res.data.result._id,
                orderName: res.data.result.OrderName,
                customerName: res.data.userName,
                successUrl: `${window.location.href}/success`,
                failUrl: `${window.location.href}/fail`,
            })
            .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                    console.log('결제창 닫음');
                    // 결제 고객이 결제창을 닫았을 때 에러 처리
                } else if (error.code === 'INVALID_CARD_COMPANY') {
                    console.log('유효하지 않은 카드');
                    // 유효하지 않은 카드 코드에 대한 에러 처리
                }
                console.log(error)
            });
        }).catch((err) => {
            console.log(err)
        });
    };

    return(
        <>
        {props.ticketPrice.map((item, i) => (
        <Grid container justifyContent='center' alignItems='center' style={{ marginBottom: '16px' }}>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <LocalActivityOutlined/>
            </Grid>
            <Grid item xs={7}>응시권 <b>{item.Count}</b>개</Grid>
            <Grid item xs={3}>
                <LoadingButton loading={creating} disableElevation color='warning' variant='contained' fullWidth size='small' onClick={() => handleClickPrice(i)}>{item.Price.toLocaleString('ko-KR')}원</LoadingButton>
            </Grid>
        </Grid>
        ))}
        <hr/>
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={2} style={{ textAlign: 'center', padding: 0 }}>쿠폰 번호</Grid>
            <Grid item xs={7}>
                <TextField
                    size='small'
                    color='warning'
                    fullWidth
                    className='cupon_input'
                    value={props.coupon}
                    onChange={(e) => props.setCoupon(e.target.value.toUpperCase())}
                />
            </Grid>
            <Grid item xs={3}>
                <LoadingButton loading={creating} disableElevation color='warning' variant='contained' fullWidth size='small' onClick={props.handleClickCoupon}>확인</LoadingButton>
            </Grid>
        </Grid>
        </>
    );
};

export default StudentTicketPriceView;