import * as ChangeText from 'components/function/ChangeText';
import { useParams,useNavigate } from "react-router-dom";
import { Grid, Avatar, Select, MenuItem, Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Button } from '@mui/material';

const TeacherHomeworkDetailStudentView = (props) => {
  
  const {id,homeworkNo,ClassNo,}=useParams()
  const teacherNo=id
  const navigate = useNavigate();

  return ( 
    <Grid container justifyContent={"center"}>
      <Grid container justifyContent={"center"} lg={11} xl={11}>
        <Grid item lg={6} xl={3.5}  sx={{ background:"#FFFFFF",borderRadius:"20px",padding:"30px 0",display:"flex",flexFlow:"column",justifyContent:"center",alignItems:"center"}}>
           <Avatar  sx={{ width:"179px",height:"179px",marginTop:"30px" }} src={props.filterStudent?.Student_id.Profile}/>
            <h1 style={{fontSize:"32px",marginTop:"40px"}}>{props.filterStudent?.Student_id.Name}</h1>
        </Grid>
      </Grid>
      <Grid container lg={11} xl={8} mt={10} pb={4} pt={2} justifyContent={"center"} sx={{background:"#FFFFFF",borderRadius:"20.9306px"}}>
        <Grid item lg={12} xl={12} > 
        <TableContainer >
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px',width:"100%"  }} aria-label="simple table">
            <TableHead className="academy_table_head">
             <TableRow >
              <TableCell sx={{fontSize:"16px",fontWeight:"700"}}>No</TableCell>
              <TableCell align="center" sx={{fontSize:"16px",fontWeight:"700"}}>정답여부</TableCell>
              <TableCell align="center" sx={{fontSize:"16px",fontWeight:"700"}}>풀이시간</TableCell>
              <TableCell align="center" sx={{fontSize:"16px",fontWeight:"700"}}>메모여부</TableCell>
              <TableCell align="right" sx={{fontSize:"16px",fontWeight:"700"}}></TableCell>
             </TableRow>
            </TableHead>
            <TableBody className="academy_table">
              {props.homeworkList?.map((homework, i) => (
             <TableRow key={i}>
              <TableCell component="th" scope="row" sx={{fontWeight:"700"}}>{homework.Question-(homework.Section*10000)}</TableCell>
              <TableCell align="center" sx={{fontWeight:"700"}}>{ChangeText.QuestionCheck(homework.InfoStudent.Check)}</TableCell>
              <TableCell align="center" sx={{fontWeight:"700"}}>{ChangeText.QuestionMinute(homework.InfoStudent.Time)}</TableCell>
              <TableCell align="center" sx={{fontWeight:"700"}}>{homework.InfoStudent.Memo? 'O' : 'X'}</TableCell>
              <TableCell align="right" sx={{fontWeight:"700"}}><Button onClick={()=>{navigate(`/academy/teacher/${teacherNo}/${ClassNo}/homework/${homeworkNo}/${homework.Question}`)}}>상세보기</Button></TableCell>
             </TableRow>
              ))}
            </TableBody>
          </Table>
       </TableContainer>
        </Grid>
      </Grid>
    </Grid>

  );
  
};
 
export default TeacherHomeworkDetailStudentView;