import { Button, Grid,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Pagination,CircularProgress } from "@mui/material";
import { useState } from "react";

import * as ChangeText from 'components/function/ChangeText';

const AcademyPriceListView = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  
  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = props.rows?.slice(startIndex, endIndex);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return ( 
  <Grid container justifyContent={"center"}>
    <Grid container lg={11.8} xl={8}  justifyContent={"space-between"}>
      <Grid item={true} lg={5.5} xl={5.5} sx={{background:"#FFFFFF",borderRadius:"21px",padding:"30px 0",height:"352px"}}>
          { props.priceList &&
          <>
            <div style={{display:"flex",marginTop:"20px"}}>
                <p style={{fontSize:"24px", fontWeight:"700",marginBottom:"21px"}}>요금제  &nbsp;</p>
                <p style={{fontSize:"24px", fontWeight:"500",marginBottom:"21px"}}>{props.priceList[0].data?.orderName} </p>
            </div>
            <div style={{display:"flex",marginTop:"20px"}}>
                <p style={{fontSize:"22px", fontWeight:"500",marginBottom:"61px"}}>다음 결제일  &nbsp;</p>
                <p style={{fontSize:"22px", fontWeight:"500",marginBottom:"61px"}}>{ChangeText.YyyyMmDd(props.priceList[0]?.Order_id.NextOrder)} </p>
            </div>
            <div style={{textAlign:"center"}}>
              {props.priceList && props.priceList[0].Order_id?.OrderCancel ?
                <Button sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",marginRight:"20px",background:"#FFFFFF",border:"1px solid #F05D38",color:"#F05D38" ,'&:hover': { background:"#FFFFFF" },}} onClick={()=>{props.setOpen(true)}}>해지 취소</Button>:
                <Button sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",marginRight:"20px",background:"#FFFFFF",border:"1px solid #F05D38",color:"#F05D38",'&:hover': { background:"#FFFFFF" }, }} onClick={()=>{props.setOpen(true)}}>해지</Button>
              }
              <Button disableElevation color='warning' sx={{ width:"155px",fontSize:"15px",borderRadius:"10px",'&:hover': { background:"#EF4C23" }, }} variant='contained' onClick={ ()=>{props.setOrderModelOpen(true)}}>요금제 업그레이드</Button>
            </div>
          </>
          }
      </Grid>
      <Grid item={true} lg={5.5} xl={6} sx={{background:"#FFFFFF",borderRadius:"21px",padding:"30px 0",height:"352px"}}>
          { props.priceList &&
          <>
            <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}>
              <p style={{fontSize:"24px", fontWeight:"700"}}>학생수</p>
              <p style={{fontSize:"24px", fontWeight:"500"}}>{props.studentList?.length}명 / {props.priceList[0].Order_id?.MaxStudent}명까지 수강가능</p>
            </div>
            <div className="academy_ticket" style={{height:"130px"}}>
              <CircularProgress variant="determinate" value={props.studentList?.length}  sx={{color:"#F05D38",width:"190px !important",height:"190px !important",border:"1px solid black",borderRadius:"100%"}}/>
              <p 
                style={{
                  fontSize:"36px",
                  fontWeight:"700",
                  position:"absolute" 
                }}>{props.studentList?.length}명</p>
            </div>
          </>
          }
      </Grid>
 
      <Grid item={true}  lg={12} xl={12} mt={10} sx={{background:"#FFFFFF",padding:"20px",borderRadius: "15px"}}>
        <TableContainer>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell >
                <TableCell align="center">요금제</TableCell>
                <TableCell align="center">결제일자</TableCell>
                <TableCell align="center">결제 요금</TableCell>
                <TableCell align="center">이용기간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="academy_table">
            {visibleData.map((row,i) => (
              <TableRow
                key={i}
                sx={{ ' td,th': {  } }}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">{row.orderName}</TableCell>
                  <TableCell align="center">{row.createdAt}</TableCell>
                  <TableCell align="center">{row.amount}</TableCell>
                  <TableCell align="center">{row.period}</TableCell>
              </TableRow>
              ))}
          </TableBody>
          </Table>
        </TableContainer>
        <Pagination
        count={Math.ceil(props.rows?.length / pageSize)} // 전체 페이지 수
        page={currentPage} // 현재 페이지
        onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
        color='warning'
        sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
      />
        </Grid>
    </Grid>
  </Grid> 
  );
}
 
export default AcademyPriceListView;