import ClassContainer from './ClassContainer';

export default ClassContainer;

export { default as Homework } from './Homework';
export { default as Main } from './Main';
export { default as Question } from './Question';
export { default as Dashboard } from './Dashboard';
export { default as MockExam } from './MockExam';
export { default as Ticket } from './Ticket';
export { default as Order } from './Order';
export { default as Evaluation } from './Evaluation';
