import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { TeacherHomeworkScoringView } from "components/teacher/class/homework";
import { Layout } from 'components/teacher/layout';

const TeacherHomeworkScoring = () => {
  const [cookies] = useCookies(['LoginKey']);
  const { enqueueSnackbar } = useSnackbar();
  const { ClassNo,HomeworkNo } = useParams();

  const [selectedHomework, setSelectedHomework] = useState();
  const [questionData, setQuestionData] = useState();
  const [studentData, setStudentData] = useState();

  useEffect(() => {
    const loadHomework = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/teacher/homework/detail/${HomeworkNo}/${cookies.LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          setSelectedHomework(Response.data.Homework);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };
    loadHomework();
  }, []);


  const handleQuestionData = (question) => {
    const Standard = selectedHomework.Standard.filter((item)=>{ return item.Link === question.Question})
    setQuestionData({...question ,standard:Standard[0].Content})
    if (studentData) {
      if (studentData.Questions.length > 0) {
      const filterStudentQuestionInfo  = studentData.Questions.filter((item)=>{ return item.Question_id === question._id })
      studentData.QuestionInfo = filterStudentQuestionInfo[0];
      };
    };
  };

  const handleStudentData = (student) => {
    let QuestionInfo = {}
    if (student.Questions.length > 0) {
      for (let i = 0; i < selectedHomework.Question_ids.length; i++) {
        const filterQuestion = selectedHomework.Question_ids.filter((item)=>{return item._id === student?.Questions[i]?.Question_id})
        if (filterQuestion.length > 0) {
          student.Questions[i] = {...student.Questions[i], Section:filterQuestion[0]?.Question}
        }
      };
    };
    if (questionData) {
      const filterStudentQuestionInfo = student.Questions.filter((item)=>{ return item.Question_id === questionData._id })
      QuestionInfo = filterStudentQuestionInfo[0]
    };
    setStudentData({...student ,QuestionInfo })
  };

  const handleChangeScore = (event)=>{
    let changeStudent = { ...studentData };
    changeStudent.QuestionInfo[event.target.name] = parseInt(event.target.value) 
    setStudentData(changeStudent);
  };


  // const handleClickScore = ()=>{
  //   let studentInfoData = selectedHomework;
  //   for (let i = 0; i < studentInfoData.Students.length; i++) {
  //       if (studentInfoData.Students[i].Student_id._id === studentData.Student_id._id) {
  //         for (let j = 0; j < studentInfoData.Students[i].Questions.length; j++) {
  //             if (studentInfoData.Students[i].Questions[j].Question_id === questionData._id) {
  //               studentInfoData.Students[i].Questions[j].Score = studentData.QuestionInfo.Score
  //             }
  //         }
  //       }      
  //   }
  //   setSelectedHomework(studentInfoData)
  //   enqueueSnackbar(`등록되었습니다.`, { variant: 'info' });
  // };
 
  const handleUpdateScore = async() =>{
    const config = { headers: { 'Content-type': 'application/json' }};
    const body = JSON.stringify({ homeworkNo:HomeworkNo, loginKey:cookies.LoginKey, studentNo:studentData.StudentNo, question_id:questionData._id, score:studentData.QuestionInfo.Score });
      await axios.put('/api/teacher/homework', body, config).then((Response) => {
        enqueueSnackbar(`등록되었습니다.`, { variant: 'info' });
      }).catch((err)=>{
        console.log(err)
      })
  };

  return ( 
    <Layout>
      <TeacherHomeworkScoringView
        selectedHomework={selectedHomework}
        handleStudentData={handleStudentData}
        handleQuestionData={handleQuestionData}
        studentData={studentData}
        questionData={questionData}
        handleChangeScore={handleChangeScore}
        handleUpdateScore={handleUpdateScore}
      />
  </Layout>
   );
}
 
export default TeacherHomeworkScoring;