import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/user/layout';
import { MockExamResultView } from 'components/user/mockExam';

const MockExamResult = () => {
    const { mockExamNo, kind } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [mockExamData, setMockExamData] = useState({
        Name: '',
        Point: 0,
        StartDate: '2099-12-31',
        Time: 0,
        PointSum: 0,
        PointFreq: 1,
        PointMean: 0,
    });
    const [questionDatas, setQuestionDatas] = useState([{
        Answer: 0,
        Choices: ['', '', '', '', ''],
        Content: [' '],
        Commentary: [' '],
        Kind: 1,
        Point: 2,
        SingleAnswer: null,
        SolveInfo: { 
            Check: 1,
            Select: 2,
            Time: 1
        }
    }]);

    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/mockexam/result/${mockExamNo}/${kind}/${cookies.LoginKey}`, config).then((res) => {            
            if(!res.data.ErrorMessage){
                setMockExamData(res.data.mockExamData);
                setQuestionDatas(res.data.mockExamQuestionDatas);
            }else{
                enqueueSnackbar(res.data.ErrorMessage, { variant: 'error' });
                navigate(-1);
            }
        }).catch((err) => {
            enqueueSnackbar('Network Error', { variant: 'error' });
        });
    };

    useEffect(() => {
        loadDatas();
    }, []);

    return (
        <Layout>
            <MockExamResultView
                mockExamData={mockExamData}
                questionDatas={questionDatas}
            />
        </Layout>
    )
};

export default MockExamResult;