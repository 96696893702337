import { Grid, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { SearchForm } from 'components/form';

const AchieveListView = (props) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid xs={4}>
          <SearchForm search={props.search} handleChange={props.handleChangeSearch} searchGuide='문제번호 / 업적이름 /' />
      </Grid>
      <Grid xs={8} textAlign={'right'}></Grid>
      <Grid xs={12} className="list_table">
          {/* <DataGrid
              rows={props.filterQuestions(props.achieves)}
              columns={props.columns}
              pageSize={15}
              // onCellClick={handleClickCell}
              initialState={{
                  sorting: {
                      sortModel: [{ field: 'id', sort: 'asc'}]
                  }
              }}
          /> */}
      </Grid>
    </Grid>
  );
}
 
export default AchieveListView;