import React from "react";
import { Menu, Nav } from "./";

const Layout = (props) => {
  return (
    <>
      <div className="academy_layout">
        <Menu />
        <Nav pages={props.pages} />
        <div className="academy_layout_content">
          <div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
