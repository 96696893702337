import { ResponsiveBar } from "@nivo/bar";

const TeacherDetailBarChart = (props) => {
 
 const data = props.data.map(({ country, data }) => {
  const result = { country };
  data.forEach(({ name, period,date,difficulty,section, ...rest }) => {
    Object.entries(rest).forEach(([name, value]) => {
      if (result[name]) {
        result[name] += value;
      } else {
        result[name] = value;
      }
    });
  });
  return result;
});

  const homeworkData=(e)=>{
    const filt =props.data.filter((ag)=>ag.country===e.country)
    props.setHomework(filt)
    props.setHomeWorkKey(true)
  }

  return (
    <ResponsiveBar
      data={data}
      keys={["수학","수학1","수학2","확률과 통계","미적분","기하"]}
      onMouseEnter={(data, event) => {event.target.style.cursor = 'pointer';}}      
      indexBy="country"
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      // axisLeft={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "country",
        // legendPosition: "middle",
        // legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "food",
        // legendPosition: "middle",
        // legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      onClick={(data) => {
        homeworkData(data.data)
      }}
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default TeacherDetailBarChart;
