import { Pagination,Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";
import { useSnackbar } from 'notistack';

import { AcademySearchForm } from "components/form";
import * as ChangeText from 'components/function/ChangeText';
import { Layout } from "components/Academy/Layout";
import { StudentListView } from "components/Academy/student";
import { CircularProgress } from "@mui/material";

const StudentList = () => {
  const [ cookies] = useCookies(['LoginKey']);
  const loginKey=cookies.LoginKey;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState("");
  const [studentList,setStudentList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const loadStudentsData= async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/student/${loginKey}`, config).then((Response)=>{
      setStudentList(Response.data.Students)
    }).catch((err)=>{
      enqueueSnackbar(`Network Error`, { variant: 'error' });

    })
  };

  useEffect(()=>{
    setLoading(true);
    setTimeout(() => {
       setLoading(false);
    }, [500]);

    loadStudentsData();
  },[]);
  
  const rows = [];
  for (let i = 0; i < studentList?.length; i++) {
    rows.push({
      id: i + 1,
      name: studentList[i].Name,
      phone: studentList[i].Phone,
      email: studentList[i].Email,
      Profile:studentList[i].Profile,
      StudentNo:studentList[i].StudentNo,
      school: studentList[i].SchoolName,
      Grade: studentList[i].Grade,
      
      rating: ChangeText.StaNine(studentList[i].CDFMean),
    });
  };
  

  const pageSize = 10; // 한 페이지에 보여줄 데이터 개수
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const visibleData = rows?.slice(startIndex, endIndex);

  // 학생 디테일 뷰
  const handleClickCell = (studentNo) => {
      navigate(`/academy/student/${studentNo}`);
  };

  //검색 함수
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const filterStudent = (datas) => {
    datas.sort();
    datas = datas.filter((data) => {
      return (
        data.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        data.email.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    });
    return datas;
  };


  return (    
  <Layout >
    {
      loading ? 
      <div style={{textAlign: 'center',marginTop:"400px"}}>
        <CircularProgress color='warning'/>
      </div>
        :
      <>
        <h1 style={{width:"91.8%",margin:"0px auto 45px auto",fontSize:"32px",color:"#000000"}}>학생 목록</h1>
        <Grid container justifyContent={"center"}>
          <Grid container lg={11} xl={11}  sx={{  background:"#FFFFFF",borderRadius:'15px',padding:"30px" }}>
            <div>
            <AcademySearchForm
              search={search}
              handleChange={handleChangeSearch}
              searchGuide="이름 / 이메일로 검색"
            />
            </div>
            <StudentListView
              rows={filterStudent(visibleData)} 
              handleClickCell={handleClickCell}
              search={search}
              handleChange={handleChangeSearch}
            />
            <Pagination
              count={Math.ceil(rows?.length / pageSize)} // 전체 페이지 수
              page={currentPage} // 현재 페이지
              onChange={handlePageChange} // 페이지 변경 이벤트 핸들러
              color='warning'
              sx={{width:"100%",'ul':{justifyContent:"right"},marginTop:"10px"}}
            /> 
          </Grid>
        </Grid>
       </>
      }
  </Layout>
   );
}
 
export default StudentList;