import { Avatar } from "@mui/material";
import tossKey from 'config/toss';
import { useSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import { useEffect,useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { AcademyInfoView } from "components/Academy/main";

const AcademyInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cookies] = useCookies(['LoginKey']);
  const navigate = useNavigate();

  const [academy,setAcademy] = useState();
  const [orderData, setOrderData] = useState({
    State: 1,
    MaxStudent: 15,
    createdAt: ''
  });
  const clientKey = tossKey;
  const tossPayments = window.TossPayments(clientKey);
  const rows = [
    { Profile:<Avatar/>,name:"정명석",phone:'010-1111-1111',email:"abcd@naver.com",birth:"1993-03-12",class:2 },
    { Profile:<Avatar/>,name:"우미호",phone:'010-2222-1111',email:"abdc@naver.com",birth:"1990-12-22",class:3 },
    { Profile:<Avatar/>,name:"팽수",phone:'010-3333-1111',email:"bacd@naver.com",birth:"1995-07-19",class:1 },
  ];
  const students=[
    { name:"김우로",email:"wooro0101@naver.com" },
    { name:"김우로",email:"wooro0101@naver.com" },
    { name:"김우로",email:"wooro0101@naver.com" },
    { name:"김우로",email:"wooro0101@naver.com" },
    { name:"김우로",email:"wooro0101@naver.com" },
  ];
      
  const loadAcademyData = async()=>{
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
      setAcademy(Response.data.Academy);
    }).catch((err) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };
  const loadOrderData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/order/${cookies.LoginKey}`, config).then((Response) => {
      if(Response.data.Order){
        setOrderData(Response.data.Order);
      }
    }).catch((Error) => {
      // console.log(Error);
    });
  };

  useEffect(()=>{
    loadAcademyData();
    loadOrderData();
    if (academy && orderData.State === 3 ) {
      navigate(`/teacher`)
      enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error' });
    };
  },[academy]);

  const handleClickPrice = () => {
    tossPayments.requestBillingAuth('카드', { // 결제 수단 파라미터
        // 빌링키 발급 요청을 위한 파라미터
        customerKey: cookies.LoginKey,
        successUrl: `${window.location.href}/price/success`,
        failUrl: `${window.location.href}/price/fail`,
    })
    .catch(function (error) {
        if (error.code === 'USER_CANCEL') {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
            enqueueSnackbar('결제에 실패하였습니다.', { variant: 'error'});
        } else if (error.code === 'INVALID_CARD_COMPANY') {
            // 유효하지 않은 카드 코드에 대한 에러 처리
            enqueueSnackbar('유효하지 않은 카드입니다.', { variant: 'error'});
        }
    })
};

  const scrollToBottom = () => {
    window.scrollTo(0, document.documentElement.scrollHeight);
  };
  
  // 버튼 클릭 이벤트 핸들러
  const handleButtonClick = () => {
    scrollToBottom();
  };
  
  return (
    <AcademyInfoView 
    rows={rows} 
    students={students}
     handleButtonClick={handleButtonClick}
     handleClickPrice={handleClickPrice}
     />
  )
};

export default AcademyInfo;
