import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

import { Layout } from 'components/admin/layout';
import { MockExamDetailInfo, MockExamQuestionInfo } from 'components/admin/mockExam';
import { DeleteModal, QuestionModal } from 'components/modal';

const MockExamInfo = () => {
    const pages = [
        { title: '모의고사 관리' },
        { title: '모의고사 목록' },
        { title: '모의고사 상세 정보' }
    ];
    const title = { main: '모의고사 상세 정보', sub: 'mock exam' };
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    const navigate = useNavigate();
    const [cookies] = useCookies(['LoginKey']);
    const [mockExam, setMockExam] = useState({
        _id: '',
        MockExamNo: '',
        Name: '',
        StartDate: '',
        createdAt: '',
        Question_ids: [{
            Question: '',
            Kind: '',
            Point: ''
        }]
    });
    const [updating, setUpdating] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
    const [questionOpen, setQuestionOpen] = useState(false);
    const [selectQuestion, setSelectQuestion] = useState({
        Content: [' '],
        Commentary: [' '],
        Choices: ['']
    });
    const [questionDeleting, setQuestionDeleting] = useState(false);
    const [questionDeleteNumber, setQuestionDeleteNumber] = useState(null);

    const handleClickOpenDeleteMockExam = () => {
        setOpenDelete(true);
    };
    const handleClickCloseDeleteMockExam = () => {
        setOpenDelete(false);
    };
    const handleClickOpenDeleteQuestion = (key) => {
        setQuestionDeleteNumber(key);
        setOpenDeleteQuestion(true);
    };
    const handleClickCloseDeleteQuestion = (key) => {
        setQuestionDeleteNumber(null);
        setOpenDeleteQuestion(false);
    };
    const { id } = useParams();
    // 데이터 로드
    useEffect(() => {
        const loadQuestion = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/mockexam/${id}/${cookies.LoginKey}`, config).then((Response) => {
                setMockExam(Response.data);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadQuestion();
    }, [id]);

    // 문제 상태 변경
    const handleChangeState = async () => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            MockExamId: mockExam._id,
            State: mockExam.State === 1 ? 2 : 1,
            loginKey: cookies.LoginKey
        });
        await axios.patch(`/api/admin/mockexam`, body, config).then((Response) => {
            setTimeout(() => {
                setMockExam({ ...mockExam, State: mockExam.State === 1 ? 2 : 1 });
                setUpdating(false);
            }, 1000);
        }).catch((Error) => {
            console.log(Error);
        });
    };


    // // 모의고사 삭ㅈㅔ
    const handleClickDeleteMockExam = async () => {
        setDeleting(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = {
            MockExamId: mockExam._id,
            loginKey: cookies.LoginKey
        };
        await axios.post(`/api/admin/mockexam/delete`, body, config).then((Response) => {
            setTimeout(() => {
                navigate(`/admin/mockexam`);
            }, 1000);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    const handleClickQuestionModal = (key) => {
        setSelectQuestion(mockExam.Question_ids[key]);
        setQuestionOpen(true);
    };

    const handleClickDeleteQuestion = async() => {
        setQuestionDeleting(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = {
            QuestionId: mockExam.Question_ids[questionDeleteNumber]._id,
            loginKey: cookies.LoginKey    
        };
        await axios.post(`/api/admin/mockexam/question/delete`, body, config).then((Response) => {
            let deleteQuestions = { ...mockExam };
            deleteQuestions.Question_ids.splice(questionDeleteNumber, 1);
            setMockExam(deleteQuestions);
            setTimeout(() => {
                setOpenDeleteQuestion(false);
                setQuestionDeleting(false);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    return (
        <>
            <Layout pages={pages} title={title}>
                <MockExamDetailInfo
                    mockExam={mockExam}
                    handleChangeState={handleChangeState}
                    updating={updating}
                    handleClickOpenDelete={handleClickOpenDeleteMockExam}
                />
                <MockExamQuestionInfo
                    questions={mockExam.Question_ids}
                    questionDeleting={questionDeleting}
                    handleClickQuestionModal={handleClickQuestionModal}
                    handleClickOpenDeleteQuestion={handleClickOpenDeleteQuestion}
                />
            </Layout>
            <DeleteModal
                openDelete={openDelete}
                handleClickCloseDelete={handleClickCloseDeleteMockExam}
                handleClickDelete={handleClickDeleteMockExam}
                deleting={deleting}
                title='모의고사 정보 삭제'
            >
                모의고사 정보가 삭제됩니다.<br />삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까?
            </DeleteModal>
            <DeleteModal
                openDelete={openDeleteQuestion}
                handleClickCloseDelete={handleClickCloseDeleteQuestion}
                handleClickDelete={handleClickDeleteQuestion}
                deleting={questionDeleting}
                title='모의고사 문제 삭제'
            >
                문제가 삭제됩니다.<br />삭제 시 복구가 불가능합니다. 정말 삭제 하시겠습니까? 
            </DeleteModal>
            <QuestionModal
                open={questionOpen}
                handleClickClose={() => setQuestionOpen(false)}
                title={`문제 및 해설`}
            >
                <h4>문제</h4>
                <MathJaxContext version={3} config={config}>
                    {selectQuestion.Content.map((item) => (
                    <MathJax 
                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                        text={
                            item.replace('`\\(','').replace('\\)`','')
                        }
                        dynamic
                        className={item.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                    />
                    ))}
                </MathJaxContext>
                <div className='diagnosis_questions_answer'>
                    {selectQuestion && selectQuestion.Answer &&
                     selectQuestion.NewChoices.map((choice, i) => (
                    selectQuestion.Answer === i+1 ?
                    <div>
                      <RadioButtonChecked className='homework_question_answer'/>
                      <MathJaxContext version={3} config={config}>
                         <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                     </MathJaxContext>
                    </div>:
                    <div>
                     <RadioButtonUnchecked/>
                     <MathJaxContext version={3} config={config}>
                         <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                            choice.replace('`\\(','').replace('\\)`','')
                            } inline dynamic/>
                     </MathJaxContext>
                    </div>
                    ))}
                </div>
                <hr/>
                <h4>해설</h4>
                
                <MathJaxContext version={3} config={config}>
                    {selectQuestion.Commentary.map((item) => (
                    <MathJax
                        renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                        text={
                            item.replace('`\\(','').replace('\\)`','')
                        }
                        dynamic
                        className={item.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_commentary'}
                    />
                    ))}
                </MathJaxContext>
                
            </QuestionModal>
        </>
    )
};

export default MockExamInfo;