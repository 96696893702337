import React, { useState } from 'react';
import { Grid, ButtonGroup, Button, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress, Link } from '@mui/material';
import { AccessAlarm, Edit, StarBorder, Star, KeyboardDoubleArrowRight, Stop, ArrowRight } from '@mui/icons-material';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useNavigate } from 'react-router-dom';

import * as ChangeText from 'components/function/ChangeText';
import { useSnackbar } from 'notistack';
import QuestionMemo from './QuestionMemo';
import QuestionMemoReview from './QuestionMemoReview';

const QuestionHomeworkDetail = (props) => {
    const question = props.question;
    const config = {
        "fast-preview": { disabled: true },
        loader: { load: ["[tex]/html", "[img]/img.min.js"], paths: {img: 'https://cdn.jsdelivr.net/npm/mathjax-img@3'} },
        tex: {
            packages: { "[+]": ["html", "img"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
          ],
          displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
          ],
        },
        options: {
            enableMenu: false,
        }
    };
    // const config = {
    //     "fast-preview": {disabled: true},
    //     loader: { load: ["[tex]/html"] },
    //     tex: {
    //       packages: { "[+]": ["html"]},
    //       inlineMath: [
    //         ["$", "$"],
    //         ["\\(", "\\)"]
    //       ],
    //       displayMath: [
    //         ["$$", "$$"],
    //         ["\\[", "\\]"]
    //       ],
    //     },
    //     options: {
    //       enableMenu: false,
    //     }
    // };
    const navigate = useNavigate();
    const [openMemo, setOpenMemo] = useState(false);
    const [openMemoReview, setOpenMemoReview] = useState(false);
    const [memoReview, setMemoReview] = useState('');

    const { enqueueSnackbar } = useSnackbar();
    const NotReady = () => {
        enqueueSnackbar(`준비중 입니다.`, { variant: 'warning'});
    };
    const handleClickMemoReview = (memo) => {
        if(memo){
            setMemoReview(memo);
            setOpenMemoReview(true);
        }else{
            return false;
        }
    };
    return(
        <div className='content_section_question'>
            <Grid container justifyContent='center' className='question_detail'>
                <Grid item xs={12} md={10} className='question_title'>
                    <div>{ChangeText.Part(question?.Section)}&nbsp;&gt;&nbsp;{ChangeText.MiddlePart(question?.Section)}&nbsp;&gt;&nbsp;{ChangeText.Section(question?.Section)}</div>
                    <div>
                        <div><AccessAlarm /></div>
                        <div>{ChangeText.QuestionOnlyMinute(props.questionTimer)}</div>
                    </div>
                    {/* <div /> */}
                </Grid>
                <Grid container item xs={12} md={10} className='question_error_comment'>
                    <div>· 문제 또는 정답에 오류가 있을 경우 페이지 하단의 <b>채널톡</b> 또는 <b>이메일</b>(<Link href='mailto:info@woorotest.com' target='_blank'>info@woorotest.com</Link>)으로 문의주시면 감사하겠습니다.</div>
                </Grid>
                <Grid container item xs={12} md={10}>
                    <Grid item xs={12} className='question_content_info'>
                        <div>
                            <ButtonGroup variant='outlined' color='warning' aria-label='outlined button group'>
                                <Button onClick={() => setOpenMemo(true)}><Edit /></Button>
                                <Button onClick={props.saveQuestion}>{question?.IsSaved ? <Star /> : <StarBorder />}</Button>
                            </ButtonGroup>
                        </div>
                    </Grid>
                    <Grid item xs={12} className='question_content'>
                        <div className='question_front_cover' style={{backgroundColor: props.loading ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'}}>{ props.loading ? <div style={{textAlign: 'center'}}><CircularProgress color='warning'/></div> : null}</div>
                        <p>문제 {props.question?.QuestionNo ? props.question.QuestionNo-props.question.Section*10000 : ''}</p>
                        {props.question.Standard ?
                            <p style={{fontWeight:"500"}}>성취 기준: <b>{props.question.Standard}</b></p> : ""
                         }
                        {/* <MathJaxContext version={3} config={config}>
                            {question?.Content.map((content) => (
                            <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                content.replace('`\\(','').replace('\\)`','')
                                } dynamic hideUntilTypeset={"first"}
                            />
                            ))}
                        </MathJaxContext> */}
                    <MathJaxContext version={3} config={config}>
                        {question.Content.map((content) => (
                            <MathJax 
                            renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}}
                            text={
                            content.replace('`\\(','').replace('\\)`','')
                            }
                            dynamic
                            className={content.indexOf('begin{array}') > -1 ? 'mathjax_problem_box' : content.indexOf('\img') > -1 ? 'mathjax_image' : 'mathjax_problem'}
                            />
                        ))}
                     </MathJaxContext>
                        {/* <div style={{textAlign: 'center'}}>
                            {question?.Picture ? <img alt='question pictures' src={question.Picture.includes('https://') ?  question.Picture : `https://s3.ap-northeast-2.amazonaws.com/store.woorotest.com/${question.Picture}`}/> : null}
                        </div> */}
                    </Grid>
                    <Grid item xs={12} className='question_checks'>
                        {props.loading ? null :
                        <FormControl>
                            <RadioGroup
                                row
                                name='row-radio-buttons-group'
                                onChange={(event) => props.setChecked(event.target.value)}
                                value={props.checked}
                            >
                                {question?.NewChoices.map((choice, i) => (
                                <FormControlLabel value={(i+1)} control={<Radio color='warning'/>} label={
                                  <MathJaxContext version={3} config={config}>
                                    <MathJax renderMode={"pre"} typesettingOptions={{fn:"tex2chtml"}} text={
                                       choice.replace('`\\(','').replace('\\)`','')
                                       } inline dynamic/>
                                  </MathJaxContext>
                                } />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={10} className='question_button_group'>
                    <Grid item xs={8}>
                        <Button onClick={props.NextQuestion} endIcon={<KeyboardDoubleArrowRight/>} color='warning' variant='outlined'>건너뛰기</Button>
                        <Button onClick={() => navigate(-1)} endIcon={<Stop/>} color='warning' variant='outlined'>그만풀기</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button disabled={props.checked === 0 ? true : false || props.loading} onClick={props.submitQuestion} endIcon={<ArrowRight/>} color='warning' variant='contained' disableElevation>{props.homeworkEndTime > 0 ? "다음문제" : "제출하기"}</Button>
                    </Grid>
                </Grid>
            </Grid>
            <QuestionMemo 
                open={openMemo}
                setOpen={setOpenMemo}
                canvasRef={props.canvasRef}
                mode={props.mode}
                setMode={props.setMode}
                ClearDraw={props.ClearDraw}
            />
             <QuestionMemoReview
                open={openMemoReview}
                setOpen={setOpenMemoReview}
                memoReview={memoReview}
            />
        </div>
    );
};

export default QuestionHomeworkDetail;