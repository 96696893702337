export const Gender = (gender) => {
    switch(gender){
        case 1:
            return '남'
        case 2:
            return '여'
        default:
            return undefined
    }
};

export const Grade = (grade) => {
    if(grade){
        return grade+'학년'
    }else{
        return undefined
    }
}

export const Login = (status) => {
    switch(status){
        case 1:
            return '우로매쓰'
        case 2:
            return '카카오'
        case 3:
            return '네이버'
        case 4:
            return '이전'
        default:
            return '탈퇴'
    }
};

export const Status = (status) => {
    switch(status){
        case 1:
            return '학생'
        case 2:
            return '학부모'
        case 3:
            return '교사 / 강사'
        case 4:
            return '학교 / 학원'
        case 5:
            return '아르바이트'
        case 6:
            return 'Admin계정'
        case 7:
            return 'Root계정'
        default:
            return 'Error'
    }
};

export const Attending = (attending) => {
    switch(attending){
        case 1:
            return '재학'
        case 2:
            return '졸업'
        case 3:
            return '외국고'
        case 4:
            return '중학생'
        case 5:
            return '검정고시'
        case 6:
            return '기타'
        default:
            return '기타'
    }
};

export const State = (state) => {
    switch(state){
        case 1:
            return '회원'
        case 2:
            return '정지'
        case 3:
            return '탈퇴'
        default:
            return 'Error'
    }
};

export const YyyyMmDd = (date) => {
    if(date){
        const nDate = new Date(date);
        const yyyy = nDate.getFullYear();
        const mm = nDate.getMonth()+1 > 9 ? nDate.getMonth()+1 : '0'+(nDate.getMonth()+1);
        const dd = nDate.getDate() > 9 ? nDate.getDate() : '0'+(nDate.getDate());
        if(yyyy !== 1900 && yyyy !== 2099){
            return `${yyyy}-${mm}-${dd}`
        }else{
            return null
        }
    }else{
        return null
    }
};

export const YyMmDd = (date) => {
    if(date){
        const nDate = new Date(date);
        const yy = nDate.getFullYear()-2000;
        const mm = nDate.getMonth()+1 > 9 ? nDate.getMonth()+1 : '0'+(nDate.getMonth()+1);
        const dd = nDate.getDate() > 9 ? nDate.getDate() : '0'+(nDate.getDate());
        if(yy > 0){
            return `${yy}. ${mm}. ${dd}`
        }else{
            return null
        }
    }else{
        return null
    }
};

export const YyyyMmDdHkr = (date) => {
    if(date){
        const nDate = new Date(date);
        const yyyy = nDate.getFullYear();
        const mm = nDate.getMonth()+1 > 9 ? nDate.getMonth()+1 : '0'+(nDate.getMonth()+1);
        const dd = nDate.getDate() > 9 ? nDate.getDate() : '0'+(nDate.getDate());
        const h = nDate.getHours();
        if(yyyy !== 1900){
            return `${yyyy}-${mm}-${dd} ${h}시`
        }else{
            return null
        }
    }else{
        return null
    }
};

export const YyyyMmDdHhMmSs = (date) => {
    if(date){
        const nDate = new Date(date);
        const yyyy = nDate.getFullYear();
        const mm = nDate.getMonth()+1 > 9 ? nDate.getMonth()+1 : '0'+(nDate.getMonth()+1);
        const dd = nDate.getDate() > 9 ? nDate.getDate() : '0'+(nDate.getDate());
        const hh = nDate.getHours() > 9 ? nDate.getHours() : '0'+(nDate.getHours());
        const mnmn = nDate.getMinutes() > 9 ? nDate.getMinutes() : '0'+(nDate.getMinutes());
        const ss = nDate.getSeconds() > 9 ? nDate.getSeconds() : '0'+(nDate.getSeconds());
        if(yyyy !== 1900){
            return `${yyyy}-${mm}-${dd} ${hh}:${mnmn}:${ss}`
        }else{
            return null
        }
    }else{
        return null
    }
};

export const MmDd = (date) => {
    if(date){
        const nDate = new Date(date);
        const yyyy = nDate.getFullYear();
        const mm = nDate.getMonth()+1 > 9 ? nDate.getMonth()+1 : '0'+(nDate.getMonth()+1);
        const dd = nDate.getDate() > 9 ? nDate.getDate() : '0'+(nDate.getDate());
        if(yyyy !== 1900){
            return `${mm} / ${dd}`;
        }else{
            return null
        }
    }else{
        return null
    }
};

export const DDay = (endDate) => {
    return Math.ceil((new Date(endDate) - new Date())/(1000*60*60*24));
};

export const QuestionTime = (questionTime) => {
    if(questionTime){
        const questionTimeT = Math.floor(questionTime/3600);
        const questionTimeM = Math.floor(questionTime/60) - questionTimeT*60;
        const questionTimeS = questionTime - questionTimeT*60 - questionTimeM*60;
        return `${questionTimeT}시간 ${questionTimeM}분 ${questionTimeS}초`
    }else{
        return '-'
    }
};

export const QuestionOnlyMinute = (questionTime) => {
    if(questionTime){
        let questionTimeM = Math.floor(questionTime/60);
        let questionTimeS = questionTime - questionTimeM*60;
        questionTimeM = questionTimeM < 10 ? '0'+questionTimeM : questionTimeM;
        questionTimeS = questionTimeS < 10 ? '0'+questionTimeS : questionTimeS;
        return `${questionTimeM} : ${questionTimeS}`
    }else{
        return '-'
    }
};

export const ApplyTime = (applyTime) => {
    let textTime
    if(applyTime){
        const diffTime = new Date() - new Date(applyTime);
        if(diffTime < 60*1000){
            textTime = '방금 전';
        }else if(diffTime < 60*60*1000){
            textTime = `${Math.floor(diffTime/(60*1000))}분 전`;
        }else if(diffTime < 24*60*60*1000){
            textTime = `${Math.floor(diffTime/(60*60*1000))}시간 전`;
        }else if(diffTime < 30*24*60*60*1000){
            textTime = `${Math.floor(diffTime/(24*60*60*1000))}일 전`;
        }else{
            textTime = `30일 이상`;
        }
    }else{
        textTime = '-';    
    }
    return textTime;
};

export const SolveTimeColor = (solveTime) => {
    let textColoe
    if(solveTime){
        const diffTime = new Date() - new Date(solveTime);
        if(diffTime < 7*24*60*60*1000){
            textColoe = `#FDEFA4`;
        }else if(diffTime < 30*24*60*60*1000){
            textColoe = `#FAC5B8`;
        }else{
            textColoe = '#DADADA';
        }
    }else{
        textColoe = '#DADADA';
    }
    return textColoe;
};

export const QuestionMinute = (qT) => {
    let questionTime = Math.ceil(qT);
    if(questionTime > 60){
        const questionTimeM = Math.floor(questionTime/60);
        let questionTimeS = questionTime - questionTimeM*60;
        questionTimeS = questionTimeS < 10 ? `0${questionTimeS}` : questionTimeS;
        return `${questionTimeM}분 ${questionTimeS}초`
    }else if(questionTime > 0){
        return `${questionTime}초`
    }else{
        return '-'
    }
};

export const QuestionTimeAll = (questionTime) => {
    if(questionTime > 3600){
        const questionTimeT = Math.floor(questionTime/3600);
        let questionTimeM = Math.floor(questionTime/60) - questionTimeT*60;
        let questionTimeS = questionTime  - questionTimeT*3600 - questionTimeM*60;
        questionTimeM = questionTimeM < 10 ? `0${questionTimeM}` : questionTimeM;
        questionTimeS = questionTimeS < 10 ? `0${questionTimeS}` : questionTimeS;
        return `${questionTimeT}시간 ${questionTimeM}분 ${questionTimeS}초`
    }else if(questionTime > 60){
        const questionTimeM = Math.floor(questionTime/60);
        let questionTimeS = questionTime - questionTimeM*60;
        questionTimeS = questionTimeS < 10 ? `0${questionTimeS}` : questionTimeS;
        return `${questionTimeM}분 ${questionTimeS}초`
    }else if(questionTime > 0){
        return `${questionTime}초`
    }else{
        return '-'
    }
};

export const QuestionStatus = (status) => {
    switch(status){
        case 1:
            return '일반'
        case 2:
            return '숙제'
        case 3:
            return '진단'
        case 4:
            return '복습'
        case 5:
            return '복습'
        case 6:
            return '복습'
        case 7:
            return '시험'
        default:
            return undefined
    }
};

export const QuestionCheck = (check) => {
    switch(check){
        case 1:
            return '정답'
        case 2:
            return '오답'
        default:
            return '-'
    }
};

export const Part = (section) => {
    const part = Number(section?.toString().substr(0,1));
    switch(part){
        case 1:
            return '수학'
        case 2:
            return '수학1'
        case 3:
            return '수학2'
        case 4:
            return '확률과 통계'
        case 5:
            return '미적분'
        case 6:
            return '기하'
        default:
            return '-'
    }
};

export const MiddlePart = (section) => {
    const middlePart = Number(section?.toString().substr(0,2));
    switch(middlePart){
        case 11:
            return '다항식'
        case 12:
            return '방정식과 부등식'
        case 13:
            return '도형의 방정식'
        case 14:
            return '집합과 명제'
        case 15:
            return '함수'
        case 16:
            return '경우의 수'
        case 21:
            return '지수함수와 로그함수'
        case 22:
            return '삼각함수'
        case 23:
            return '수열'
        case 31:
            return '함수의 극한과 연속'
        case 32:
            return '미분'
        case 33:
            return '적분'
        case 41:
            return '경우의 수'       
        case 42:
            return '확률'
        case 43:
            return '통계'
        case 51:
            return '수열의 극한'
        case 52:
            return '미분법'
        case 53:
            return '적분법'
        case 61:
            return '이차곡선'
        case 62:
            return '평면벡터'
        case 63:
            return '공간도형과 공간좌표'
        default:
            return '-'
    }
};

export const Section = (section) => {
    switch(Number(section)){
        case 111:
            return '다항식의 연산'
        case 112:
            return '나머지 정리'
        case 113:
            return '인수분해'
        case 121:
            return '복소수와 이차방정식'
        case 122:
            return '이차방정식과 이차함수'
        case 123:
            return '여러가지 방정식과 부등식'
        case 131:
            return '평면 좌표'
        case 132:
            return '직선의 방정식'
        case 133:
            return '원의 방정식'
        case 134:
            return '도형의 방정식(이동)'
        case 141:
            return '집합'
        case 142:
            return '명제'
        case 151:
            return '함수'
        case 152:
            return '유리함수와 무리함수'
        case 161:
            return '경우의 수'
        case 162:
            return '순열과 조합'
        case 211:
            return '지수와 로그'
        case 212:
            return '지수함수와 로그함수'
        case 221:
            return '삼각함수'
        case 231:
            return '등차수열과 등비수열'
        case 232:
            return '수열의 합'
        case 233:
            return '수학적 귀납법'
        case 311:
            return '함수의 극한'
        case 312:
            return '함수의 연속'
        case 321:
            return '미분계수'
        case 322:
            return '도함수'
        case 323:
            return '도함수의 활용'
        case 331:
            return '부정적분'
        case 332:
            return '정적분'
        case 333:
            return '정적분의 활용'
        case 411:
            return '순열과 조합'
        case 412:
            return '이항정리'
        case 421:
            return '확률의 뜻과 활용'
        case 422:
            return '조건부 확률'
        case 431:
            return '확률분포'
        case 432:
            return '통계적 추정'
        case 511:
            return '수열의 극한'
        case 512:
            return '급수'
        case 521:
            return '여러가지 함수의 미분'
        case 522:
            return '여러가지 미분법'
        case 523:
            return '도함수의 활용'
        case 531:
            return '여러가지 적분법'
        case 532:
            return '정적분의 활용'
        case 611:
            return '이차곡선'
        case 612:
            return '이차곡선과 직선'
        case 621:
            return '벡터의 연산'
        case 622:
            return '평면벡터의 성분과 내적'
        case 631:
            return '공간도형'
        case 632:
            return '공간좌표'
        default:
            return '-'
    }
};

export const QuestionState = (state) => {
    switch(state){
        case 1:
            return '출제'
        case 2:
            return '미출제'
        default:
            return '-'
    }
};

export const Difficulty = (difficulty) => {
    switch(difficulty){
        case 1:
            return '★'
        case 2:
            return '★★'
        case 3:
            return '★★★'
        case 4:
            return '★★★★'
        case 5:
            return '★★★★★'
        default:
            return null
    }
};

export const NotError = (notError) => {
    switch(notError){
        case 1:
            return '정답'
        case 2:
            return '오답'
        default:
            return null
    }
};

export const Percent = (percent) => {
    if(typeof percent === 'number'){
        return Math.round(percent*10000)/100+'%';
    }else{
        return '0%'
    }
};

export const IntPercent = (percent) => {
    if(percent !== '-'){
        return Math.round(percent*100)+'%';
    }else{
        return '-'
    }
};

export const SchoolState = (state) => {
    switch(state){
        case 1:
            return '운영'
        case 2:
            return '폐교'
        default:
            return null
    }
};

export const PartQuestions = (part) => {
    switch(part){
        case 1:
            return 100+100+100+100+100+100+100+100+100+100+100+100+100+150+200+150+200
        case 2:
            return 100+100+100+100+100+100
        case 3:
            return 200+200+200+200+200+200+200+200
        case 4:
            return 50+50+50+50+50+50
        case 5:
            return 50+50+50+50+50+50+50
        case 6:
            return 50+75+50+45+50+45
        default:
            return part
    }
};

/* 각 단원별 문제수 */
export const SectionQuestions = (section) => {
    switch(section){
        case 111:
            return 100  // 다항식의 연산
        case 112:
            return 100  // 나머지 정리
        case 113:
            return 100  //  인수분해
        case 121:
            return 100  //  복소수와 이차방정식
        case 122:
            return 100  //  이차방정식과 이차함수
        case 123:
            return 100  //  여러가지 방정식과 부등식
        case 131:
            return 100  //  평면 좌표
        case 132:
            return 100  //  직선의 방정식
        case 133:
            return 100  //  원의 방정식
        case 134:
            return 100  //  도형의 방정식(이동)
        case 141:
            return 100  //  집합
        case 142:
            return 100   //  명제
        case 151:
            return 150   //  함수
        case 152:
            return 200  //  유리함수와 무리함수
        case 161:
            return 150  //  경우의 수
        case 162:
            return 200  //  순열과 조합
        case 211:
            return 100  //  지수와 로그
        case 212:
            return 100  //  지수함수와 로그함수
        case 221:
            return 100  //  삼각함수
        case 231:
            return 100  //  등차수열과 등비수열
        case 232:
            return 100  //  수열의 합
        case 233:
            return 100  //  수학적 귀납법
        case 311:
            return 200  //  함수의 극한
        case 312:
            return 200   //  함수의 연속
        case 321:
            return 200   //  미분계수
        case 322:
            return 200   //  도함수
        case 323:
            return 200  //  도함수의 활용
        case 331:
            return 200  //  부정적분
        case 332:
            return 150  //  정적분
        case 333:
            return 200  //  정적분의 활용
        case 411:
            return 50  //  순열과 조합  
        case 412:
            return 50  //  이항정리 
        case 421:
            return 50  //  확률의 뜻과 활용  
        case 422:
            return 50  //  조건부 확률
        case 431:
            return 50  //  확률분포 
        case 432:
            return 50   //  통계적 추정  
        case 511:
            return 50   //  수열의 극한  
        case 512:
            return 50   //  급수 
        case 521:
            return 50  //  여러가지 함수의 미분
        case 522:
            return 50  //  여러가지 미분법  
        case 523:
            return 50  //  도함수의 활용  
        case 531:
            return 50  //  여러가지 적분법  
        case 532:
            return 50  //  정적분의 활용  
        case 611:
            return 50  //  이차곡선  
        case 612:
            return 75   //  이차곡선과 직선  
        case 621:
            return 50  //  벡터의 연산
        case 622:
            return 45  //  면벡터의 성분과 내적
        case 631:
            return 50  //  공간도형
        case 632:
            return 45  //  공간좌표
        default:
            return section
    }
};

export const ReviewType = (type) => {
    switch(type){
        case 1:
            return 'review'
        case 2:
            return 'wrong'
        case 3:
            return 'save'
        default:
            return 'reveiw'
    }
};

export const QuestionStatusKR = (Status) => {
    switch(Status){
        case 1:
            return '문제풀기'
        case 2:
            return '숙제하기'
        case 3:
            return '진단하기'
        case 4:
            return '복습하기'
        case 5:
            return '복습하기'
        case 6:
            return '복습하기'
        default:
            return '문제풀기'
    }
};

export const StaNine = (percent) => {
    // percent는 백분위(상위 x, 하위 o)
    if(percent < 0.04){
        return 9;
    }else if(percent < 0.10){
        return 8;
    }else if(percent < 0.22){
        return 7;
    }else if(percent < 0.39){
        return 6;
    }else if(percent < 0.59){
        return 5;
    }else if(percent < 0.76){
        return 4;
    }else if(percent < 0.88){
        return 3;
    }else if(percent < 0.95){
        return 2;
    }else if(percent <= 1.00){
        return 1;
    }else{
        return 10;
    }
};

export const BlindPhoneNumber = (phoneNumber) => {
    const firstChar = phoneNumber.charAt(phoneNumber.length-4);
    const secondeChar = phoneNumber.charAt(phoneNumber.length-3);
    const lastChar = phoneNumber.charAt(phoneNumber.length-1);
    return firstChar+' '+secondeChar+' * '+lastChar;
};

export const OrderState = (state) => {
    switch(state){
        case 1:
            return 'Free';
        case 2:
            return 'Teacher Basic';
        case 3:
            return 'Academy Basic';
        case 3:
            return 'Academy Premium';
        case 9:
            return '무료 체험';
        default:
            return 'Free';
    }
};

export const MockExamQuestionKind = (state) => {
    switch(Number(state)){
        case 1:
            return '공통';
        case 2:
            return '확률과 통계';
        case 3:
            return '미적분';
        case 4:
            return '기하';
        default:
            return '공통';
    }
};