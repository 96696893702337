import AcademyContainer from "./AcademyContainer";

export default AcademyContainer;

export { default as Teacher } from "./Teacher";
export { default as Main } from "./Main";
export { default as Class } from "./Class";
export { default as Student } from "./Student";
export { default as Board } from "./Board";
export { default as MockExam } from "./MockExam";
export { default as User } from "./User";

