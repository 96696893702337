import { Button, Box, IconButton} from "@mui/material";
import Modal from '@mui/material/Modal';

import CheckCircle from 'img/CheckCircle.png';

const AcademyOrderUpdateModal = (props) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    borderRadius:"10px",
    boxShadow: 24,
    display:"flex",
    p: 2,
    alignItems:"center"
  };
  return ( 
    <Modal
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
        <img src={CheckCircle} alt="CheckCircle" width={"22px"} height={"22px"} style={{marginRight:"20px"}}/>
        <div>
          <p style={{fontSize:"18px"}}>학생이 100명 이상이신가요?</p>
          <p style={{fontSize:"18px"}}>PREMIUM 요금제 업그레이드를 위해 아래 <b style={{color:"#F05D38"}}>챗봇</b>  이나 <b style={{color:"#F05D38"}}>info@woorotest.com</b>으로 문의를 주셔야 합니다. </p>
          <p style={{fontSize:"18px"}}>감사합니다 {":)"}</p>
        </div>
    </Box>
  </Modal>
   );
}
 
export default AcademyOrderUpdateModal;