import { Routes, Route,useNavigate, useLocation } from "react-router-dom";
import { Main, Teacher, Class,Student,Board,MockExam,User } from ".";
import { useSnackbar } from 'notistack';
import { useEffect } from "react";
import { useCookies } from 'react-cookie';
import axios from "axios";

import NotFound from "routes/NotFound";

const AcademyPresenter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [ cookie ] = useCookies(['LoginKey']);


  const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobileOrTablet) {
    enqueueSnackbar('교사용 페이지로 이동합니다.', { variant: 'error' });
    setTimeout(()=>{navigate('/teacher');
    },[500]);
  };

  const loadOrderData = async() => {
    const config = { headers: { 'Content-type': 'application/json' }};
    await axios.get(`/api/order/${cookie.LoginKey}`, config).then((Response) => {
      if(!Response.data.Order){
        if(location.pathname !== '/academy/create' && location.pathname !== '/academy'){
          RedirectURL('/', '잘못된 경로입니다.', 'error');
        }
      }
    }).catch((Error) => {
      enqueueSnackbar(`Network Error`, { variant: 'error' });
    });
  };

  const RedirectURL = (url, message, variant) => {
    if(message){
      enqueueSnackbar(`${message}`, { variant: variant });
    }else{
      enqueueSnackbar(`잘못된 경로입니다.`, { variant: 'error'});
    }
    navigate(url);
  }; 

 useEffect(()=>{
   loadOrderData();
  },[]);


  return (
    <Routes>
      <Route path="/*" element={<Main />} />
      <Route path="teacher/*" element={<Teacher />} />
      <Route path="user/*" element={<User />} />
      <Route path="class/*" element={<Class />} />
      <Route path="student/*" element={<Student />} />
      <Route path="board/*" element={<Board />} />
      <Route path="mockExam/*" element={<MockExam />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AcademyPresenter;
