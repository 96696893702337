import React, { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const AcademyPriceFail = (props) => {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
   
    useEffect(() => {
        enqueueSnackbar(`결제에 실패하였습니다.`, { variant: 'error' });
        navigate(`/academy`);
    }, []);
    
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
            <div style={{ position: 'absolute', top: '55%', fontSize: '17px'}}>결제 진행중 입니다.</div>
        </Backdrop>
    );
};

export default AcademyPriceFail;