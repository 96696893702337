import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { OrderView } from 'components/teacher/class/order';
import { SubmitModal } from 'components/modal';
import { YyyyMmDdHhMmSs } from 'components/function/ChangeText';


const Order = () => {
    const [cookies] = useCookies(['LoginKey']);
    const { enqueueSnackbar } = useSnackbar();
    const [prices, setPrices] = useState([{
        Order_id: {
            State: 0,
            createdAt: '1900-01-01',
            NextOrder: '1900-01-01',
            OrderCancel: false
        },
        PriceNo: 0,
        data: {
            totalAmount: 0,
            orderName: '',
            method: '',
            approvedAt: '1900-01-01'
        }
    }]);
    const [open, setOpen] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [cancelText, setCancelText] = useState('');
    const [academy,setAcademy] = useState();

    const loadDatas = async() => {
        const config = { headers: { 'Content-type': 'application/json' }};
        await axios.get(`/api/price/${cookies.LoginKey}`, config).then((res) => {
            let loadPriceDatas = res.data.Prices;
            loadPriceDatas.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
            if(loadPriceDatas[0].Order_id.OrderCancel){
                setCancelText(`서비스 해지 신청을 취소합니다.`);
            }else{
                setCancelText(`${loadPriceDatas[0].data.orderName}을 해지합니다. ${YyyyMmDdHhMmSs(new Date(loadPriceDatas[0].Order_id.NextOrder).setHours(new Date(loadPriceDatas[0].Order_id.NextOrder).getHours(loadPriceDatas[0].Order_id.NextOrder)+1, 0, 0))}까지 사용가능하며, 이후 자동 결제되지 않습니다.`);
            }
            setPrices(loadPriceDatas);
        }).catch((err) => {
            console.log(err);
        })
    };


    const loadAcademyData = async()=>{
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/academy/${cookies.LoginKey}`, config).then((Response) => {
        setAcademy(Response.data.Academy);
      }).catch((err) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };

    const handleClickCancel = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const data = JSON.stringify({
            orderId: prices[0].Order_id._id,
            orderCancel: prices[0].Order_id.OrderCancel ? false : true,
            loginKey: cookies.LoginKey
        });
        await axios.put(`/api/order`, data, config).then((res) => {
            setTimeout(() => {
                const changePrices = [...prices];
                changePrices[0].Order_id.OrderCancel = res.data.OrderCancel;
                setPrices(changePrices);
                setOpen(false);
                setUpdating(false);
                setTimeout(() => {
                    if(res.data.OrderCancel){
                        setCancelText(`서비스 해지 신청을 취소합니다.`);
                    }else{
                        setCancelText(`${changePrices[0].data.orderName}을 해지합니다. ${YyyyMmDdHhMmSs(new Date(changePrices[0].Order_id.NextOrder).setHours(new Date(changePrices[0].Order_id.NextOrder).getHours(changePrices[0].Order_id.NextOrder)+1, 0, 0))}까지 사용가능하며, 이후 자동 결제되지 않습니다.`);
                    }
                }, [1000]);
            }, [1500]);
            if(res.data.orderCancel){
                enqueueSnackbar('서비스 해지 신청이 취소되었습니다.', { variant: 'info' });
            }else{
                enqueueSnackbar('서비스 해지 신청이 완료되었습니다.', { variant: 'info' });
            }
        }).catch((err) => {
            console.log(err);
        });
    };
    
    useEffect(() => {
        loadDatas();
        loadAcademyData();
    }, []);
   
    return(
        <Layout>
            <OrderView 
                prices={prices}
                academy={academy}
                setOpen={setOpen}
            />
            <SubmitModal
                open={open}
                handleClose={() => setOpen(false)}
                title={'서비스 해지'}
                handleClickSubmit={handleClickCancel}
                updating={updating}
            >
                {cancelText}
            </SubmitModal>
        </Layout>
    );
};

export default Order;