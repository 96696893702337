import React from 'react';
import AcademyTeacherPresenter from './AcademyTeacherPresenter';

const AcademyTeacherContainer = () => {
  return (
    <AcademyTeacherPresenter />
  );
};

export default AcademyTeacherContainer;
