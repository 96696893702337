import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const FullNormalModal = (props) => {
    return(
        <div>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => props.setOpen(false)}
                aria-describedby='alert-dialog-slide-description-delete'
                fullWidth={true}
                maxWidth='lg'
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    onClick={() => props.setOpen(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.setOpen(false)}>닫기</Button>
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default FullNormalModal;