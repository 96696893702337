import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, FormControl, InputLabel, OutlinedInput, IconButton, Button, CircularProgress } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import WooroTestLogoOnly from 'img/woorotest_logo_only.png'

const TeacherClassUpdate = (props) => {
    const navigate = useNavigate();
    return (
        <Grid container justifyContent='center'>
            <Grid xs={12} sm={9} md={6} lg={4.8} xl={3.5} className='teacher_class_create'>
                <div 
                    className='teacher_class_create_image'
                    style={{ backgroundImage:
                        props.imgBase64 ? `url('${props.imgBase64}')`: 
                            props.classData.ClassImage ? `url('${props.classData.ClassImage}')` : `url('${WooroTestLogoOnly}')`
                    }}
                >
                    <div>
                        <IconButton color='error' onClick={props.handleClickDeleteProfile}>
                            <Remove />
                        </IconButton>
                        <label htmlFor='Profile' className='upload_profile'>
                            <input accept='image/png, image/jpeg, image/jpg' name='Profile' id='Profile' type='file' onChange={props.handleChangeProfile}/>
                            <IconButton color='primary' component='span'>
                                <Add />
                            </IconButton>
                        </label>
                    </div>
                </div>
                <div className='teacher_class_create_name'>
                    <FormControl>
                        <InputLabel htmlFor='class_name' color='warning'>클래스 이름 *</InputLabel>
                        <OutlinedInput
                            id='class_name'
                            name='ClassName'
                            color='warning'
                            type='text'
                            value={props.classData.ClassName}
                            onChange={props.handleChangeClass}
                            label='클래스 이름 *'
                            placeholder='클래스 이름을 입력해 주세요.'
                        />
                    </FormControl>
                </div>
                <div className='teacher_class_button_group'>
                    <Button variant='outlined' color='error' style={{ float: 'left' }} onClick={props.handleClickDelete} disabled={props.updating}>삭제</Button>
                    <Button color='secondary' onClick={() => navigate(-1)}>취소</Button>
                    <Button variant='outlined' color='warning' onClick={props.handleClickUpdate} disabled={props.updating}>수정</Button>
                </div>
                {props.updating ?
                <div className='teacher_class_button_group'>
                    <CircularProgress color='warning'/>
                </div> : null}
            </Grid>
        </Grid>
    );
};

export default TeacherClassUpdate;