import {  Box } from "@mui/material";
import Modal from '@mui/material/Modal';

import * as ChangeText from 'components/function/ChangeText';

const AcademyBoardModal = (props) => {

  return ( 
    <Modal 
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
   >
     <Box className={"academy_board_container"}>
        <Box style={{borderBottom:"1px solid #A4A4A4",marginTop:"30px",marginBottom:"48px",display:"flex",justifyContent:"space-between"}}>
          <h1 style={{fontSize:"32px"}}>{props.boardDetail?.Title}</h1>
          <h3 style={{padding:"10px 0",fontSize:"20px"}}>{ChangeText.YyyyMmDd(props.boardDetail?.createdAt)}</h3>
        </Box>
        <Box item={true}  lg={5} xl={12}>
          <p style={{whiteSpace:"pre-wrap",fontSize:"20px",fontWeight:"500",}}className={'academy_board_box'}>{props.boardDetail?.Content}</p>
        </Box>
     </Box>
   </Modal>
   );
}
 
export default AcademyBoardModal;