import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Layout } from 'components/teacher/layout';
import { TeacherHomeworkDetailQuestion } from 'components/teacher/class/homework';

const HomeworkDetailQuestion = () => {
  const [cookies] = useCookies(['LoginKey']);
  const { enqueueSnackbar } = useSnackbar();
  const LoginKey = cookies.LoginKey;
  const { HomeworkNo } = useParams();
  const [homeworkData, setHomeworkData] = useState({
    Question_ids: [{
      Answer: '',
      Choice: ['','','','',''],
      Info: { Counting: '', Correct: '', Time: '' },
      Question: '',
      Content: [''],
      CommentaryPicture: []
    }],
    StudentNo: '',
    Students: [{
      Student_id: { Name: '' },
      MemoPictures: [],
      Questions: [{
        Check: '',
        Memo: '',
        Memo: '',
      }],
    }]
  });

  useEffect(() => {
    const loadHomework = async() => {
      const config = { headers: { 'Content-type': 'application/json' }};
      await axios.get(`/api/teacher/homework/detail/${HomeworkNo}/${LoginKey}`, config).then((Response) => {
        if(!Response.data.ErrorMessage){
          setHomeworkData(Response.data.Homework);
        }else{
          enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
        }
      })
      .catch((Error) => {
        enqueueSnackbar(`Network Error`, { variant: 'error' });
      });
    };
    loadHomework();
  }, []);

  return (
    <Layout>
      <TeacherHomeworkDetailQuestion
        homeworkData={homeworkData}
      />
    </Layout>
  );
};

export default HomeworkDetailQuestion;