import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Layout } from 'components/user/layout';
import { QuestionHomeworkResult } from 'components/user/question';

const HomeworkDetail = () => {
    const { homeworkNo } = useParams();
    const [cookies] = useCookies(['LoginKey']);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const LoginKey = cookies.LoginKey;
    const [currentNo, setCurrentNo] = useState(0);
    const [loading, setLoading] = useState(true);
    const [homeworkData, setHomeworkData] = useState([{
        Answer: '',
        Choices: ['', '', '', '', ''],
        Content: [''],
        QuestionNo: '',
        Question_id: '',
        Section: '',
        IsSaved: '',
        Check: '',
        Time: '',
        Select: '',
        Memo: '',
        DueDate: '2100-12-31'
    }]);

    const saveQuestion = async () => {
        if(LoginKey){
            let obj = {
                Question_id: homeworkData[currentNo].Question_id,
                loginKey: LoginKey,
                Status: 5,
                Section: homeworkData[currentNo].Section,
                IsSaved: homeworkData[currentNo].IsSaved 
            };
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify(obj);
            await axios.post(`/api/question`, body, config).then((Response) => {
                let temp = { ...homeworkData };
                temp[currentNo].IsSaved = Response.data.isSaved;
                setHomeworkData(temp);
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            });
        }else{
          enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error'});
        }
    };

    const BeforeQuestion = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, [500]);
        setCurrentNo(currentNo=>currentNo-1);
    };

    const NextQuestion = () => {
        setLoading(true);
        if(currentNo+1 === homeworkData.length){
            enqueueSnackbar(`모든 문제를 풀었습니다.`, { variant: 'info'});
            setTimeout(() => {
                navigate(-1);
            }, [1500]); 
        }else{
            setTimeout(() => {
                setLoading(false);
            }, [500]);
            setCurrentNo(currentNo=>currentNo+1);
        }
    };

    useEffect(() => {
        const loadHomeworks = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/student/homework/result/${homeworkNo}/${LoginKey}`, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    let loadHomework = Response.data.Homework;
                    setTimeout(() => {
                        setLoading(false);
                    }, [500]);
                    setHomeworkData(loadHomework);
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    navigate(-1);        
                }
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
            })
        }
        if(LoginKey){
            loadHomeworks(); 
        }else{
            enqueueSnackbar(`로그인이 필요합니다.`, { variant: 'error' });
            navigate(`/login`);
        }
    }, []);
    
    return(
        <Layout>
            <QuestionHomeworkResult
                question={homeworkData[currentNo]}
                currentNo={currentNo}
                BeforeQuestion={BeforeQuestion}
                NextQuestion={NextQuestion}
                loading={loading}
                saveQuestion={saveQuestion}
            />
        </Layout>
    )
};

export default HomeworkDetail;