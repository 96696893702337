import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton, FormControl, Input, CircularProgress, FormControlLabel, Radio } from '@mui/material';
import { Close } from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });

const PasswordFindModal = (props) => {
    const [loading, setLoading] = useState(false);
    const  { enqueueSnackbar } = useSnackbar();
    
    const handleClickSubmit = async() => {
        const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
        if(reg_email.test(props.emailSearch)){
            setLoading(true);
            const config = { headers: { 'Content-type': 'application/json' }};
            const body = JSON.stringify({ Email : props.emailSearch });
            await axios.post(`/api/emailAuth/password`, body, config).then((Response) => {
                if(!Response.data.ErrorMessage){
                    enqueueSnackbar(`${Response.data.Message}`, { variant: 'info' });
                    handleClose();
                }else{
                    enqueueSnackbar(`${Response.data.ErrorMessage}`, { variant: 'error' });
                    props.setEmailSearch('');
                    setLoading(false);
                }                
            })
            .catch((Error) => {
                enqueueSnackbar(`Network Error`, { variant: 'error' });
                setLoading(false);
            });
        }else{
            if(props.emailSearch){
                enqueueSnackbar(`이메일 형식이 올바르지 않습니다.`, { variant: 'error' });
                props.setEmailSearch('');   
            }else{
                enqueueSnackbar(`이메일을 입력해주세요.`, { variant: 'error' });
            }
        }
    };

    const handleKeyDown = (event) => {
        if(event.code === 'Enter'){
            handleClickSubmit();
        }else{
            return false;
        }
    };
    const handleClose = () => {
        if(!loading){
            props.setOpen(false);
            props.setEmailSearch('');
            setLoading(false);   
        }else{
            return false;
        }
    };
    return(
        <div>
            <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby='alert-dialog-slide-description-delete'
            fullWidth={true}
            >
            <DialogTitle>
                {props.title}
                <IconButton
                    aria-label='close'
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    onClick={handleClose}
                    >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-slide-description'>
                    <FormControl variant='standard' fullWidth >
                        <Input
                            type='text'
                            value={props.emailSearch}
                            onChange={(e) => props.setEmailSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder={'가입하신 이메일을 입력해주세요.'}
                            name='emailSearch'
                            color='warning'
                            disabled={loading}
                        />
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {!loading ? 
                <>
                <Button onClick={handleClose}>취소</Button>
                <Button onClick={handleClickSubmit} color='warning' variant='contained' disableElevation disabled={loading}>확인</Button>
                </>:
                <div style={{ paddingRight: '20px' }}>
                    <CircularProgress color='warning'/>
                </div>}
            </DialogActions>
            </Dialog>
        </div>
    );
};

export default PasswordFindModal;