import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';

import { Layout } from 'components/admin/layout';
import { StudentDetailUpdate, StudentSchoolList } from 'components/admin/student';
import { SearchSchoolModal } from 'components/modal';
import { SearchForm } from 'components/form';

const StudentUpdate = () => {
    const pages = [
        { title: '회원 관리'},
        { title: '학생 목록', link: '/admin/student' },
        { title: '학생 정보 수정'},
    ];
    const title = { main: '학생 정보 수정', sub: 'user'};
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [student, setStudent] = useState({
        Name: '',
        Login: '',
        Gender: '',
        Birth: '',
        Status: '',
        Attending: '',
        School_id: {Name: ''},
        Grade: '',
        Email: '',
        Phone: '',
        State: ''
    });
    const { id } = useParams();
    const [cookies] = useCookies(['LoginKey']);

    // 데이터 로드
    useEffect(() => {
        const loadStudent = async() => {
            const config = { headers: { 'Content-type': 'application/json' }};
            await axios.get(`/api/admin/student/${id}/${cookies.LoginKey}`, config).then((Response) => {
                setTimeout(() => {
                    setLoading(false);
                    setStudent(Response.data);
                },[1000]);
            }).catch((Error) => {
                console.log(Error);
            });
        };
        loadStudent();
        
    },[id]);

    const [imgBase64, setImgBase64] = useState();
    const [imgFile, setImgFile] = useState(null);

    const handleChange = (event) => {
        let changeStudent = {...student};
        changeStudent[event.target.name] = event.target.value;
        setStudent(changeStudent);
    };

    const handleClickDeleteProfile = () => {
        let DeleteProfile = {...student};
        DeleteProfile.Profile = '';
        setStudent(DeleteProfile);
        setImgBase64('');
        setImgFile('');
    };

    const handleChangeProfile = (event) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            // 2. 읽기가 완료되면 아래코드가 실행됩니다.
            const base64 = reader.result;
            if (base64) {
                setImgBase64(base64.toString()); // 파일 base64 상태 업데이트
            }
        }
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]); // 1. 파일을 읽어 버퍼에 저장합니다.
            setImgFile(event.target.files[0]); // 파일 상태 업데이트
        }
    };
    const [updating, setUpdating] = useState(false);
    const handleClickUpdate = async() => {
        setUpdating(true);
        const config = { headers: { 'Content-type': 'application/json' }};
        const body = JSON.stringify({
            StudentId: student._id,
            Name: student.Name,
            Birth: student.Birth,
            Email: student.Email,
            Phone: student.Phone,
            Grade: student.Grade,
            State: student.State,
            Attending: student.Attending,
            Status: student.Status,
            School_id: student.School_id._id,
            loginKey: cookies.LoginKey
        });
        await axios.put(`/api/admin/student`, body, config).then((Response) => {
            uploadProfile();
            setTimeout(()=> {
                navigate(`/admin/student/${id}`);
            }, [1000]);
        }).catch((Error) => {
            console.log(Error);
        });
    };

    const uploadProfile = async() => {
        if(imgFile){
            const formData = new FormData();
            formData.append('StudentId', student._id);
            formData.append('imgFile', imgFile);
            formData.append('loginKey', cookies.LoginKey);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            await axios.put(`/api/admin/student/profile`, formData, config).then((Response) => {
                // console.log(Response);
            }).catch((Error) => {
                console.log(Error);
            });
        }else{
            return false
        }
    };

    const [search, setSearch] = useState('');
    const [schools, setSchools] = useState([
        {
            Name: '',
            Local: '',
            County: ''
        }
    ]);
    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    };
    const handleClickSchool = (school) => {
        let changeStudent = {...student};
        changeStudent['School_id'] = school;
        setStudent(changeStudent);
        handleClickCloseSearchSchool();
    }
    const [openSearchSchool, setOpenSearchSchool] = useState(false);
    const [schoolsLoading, setSchoolsLoading] = useState(false);
    const handleClickOpenSearchSchool = () => {
        setOpenSearchSchool(true);
        if(!schools[0].Name){
            setSchoolsLoading(true);
            const loadSchools = async() => {
                let res = await fetch(`/api/admin/school`, {
                    method: "GET"
                });
                await res.json().then((Response) => {
                    setTimeout(() => {
                        setSchoolsLoading(false);
                        setSchools(Response);
                    },[800]);
                }).catch((Error) => {
                    console.log(Error);
                });
            }
            loadSchools();
        }
    };
    const handleClickCloseSearchSchool = () => {
        setOpenSearchSchool(false);
    };
    return(
        <Layout pages={pages} title={title}>
            <Backdrop
                sx={{ color: '#fff', zIndex: 4 }}
                open={loading}        
            >
                <CircularProgress color='inherit' />
            </Backdrop>
            <StudentDetailUpdate 
                student={student} 
                setStudent={setStudent}
                handleChange={handleChange}
                handleChangeProfile={handleChangeProfile}
                handleClickDeleteProfile={handleClickDeleteProfile}
                imgBase64={imgBase64}
                handleClickUpdate={handleClickUpdate}
                handleClickOpenSearch={handleClickOpenSearchSchool} 
                updating={updating}
                uploadProfile={uploadProfile}
            />
            <SearchSchoolModal 
                openSearch={openSearchSchool} 
                handleClickCloseSearch={handleClickCloseSearchSchool}
                title='학교 검색'
            >
                <SearchForm search={search} handleChange={handleChangeSearch} searchGuide='학교 이름으로 검색' loading={schoolsLoading} />
                <StudentSchoolList schools={schools} loading={schoolsLoading} search={search} handleClickSchool={handleClickSchool} />
            </SearchSchoolModal>
        </Layout>
    );
}

export default StudentUpdate;